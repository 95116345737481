<template>
  <v-dialog :max-width="580" v-model="dialog">
    <div class="type-ieo-popup-server-error white-popup popup-form order-popup">
      <div class="form-main-content">
        <div class="popup-header">
          <h3 class="form-title" v-text="errorTitle ? errorTitle : 'Error!'"></h3>
        </div>
        <div class="popup-content">
          <div class="popup-content-container">
            <div class="error-message server-error" v-html="errorMessage"></div>
            <div class="delete-image-btn-wrap">
              <button type="button"
                      class="submit_form_sell btn btn-hover"
                      @click="confirmAction"
                      v-html="errorConfirmBtn ? errorConfirmBtn : 'Ok'"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "ServerErrorPopup",
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    errorTitle: {
      type: String,
      required: false,
    },
    errorMessage: {
      type: String,
      required: false,
    },
    errorConfirmBtn: {
      type: String,
      required: false,
    },
  },
  methods: {
    openPopups() {
      this.dialog = true;
    },
    confirmAction() {
      this.$emit('confirmErrorAction')
      this.$store.dispatch('alert/clear');
      this.dialog = false
    }
  },
};
</script>

<style>

</style>