<template>
  <v-dialog v-model="dialog">
    <div id="type-ieo-popup-confirm"
         class="white-popup popup-form order-popup confirm-popup">
      <div class="form-main-content">
        <div class="close-dialog" @click="dialog = false">
          <img src="../../assets/img/close.svg" alt="close-icon">
        </div>
        <div class="popup-header">
          <h3 class="form-title">You are buying <span class="buy-pm-ipr-count" v-text="songDeltaToFormat"></span> {{ currencyName }} of:</h3>
        </div>
        <div class="popup-content">
          <div class="popup-content-container">
            <div class="confirm-image-descr">
              <div class="buy-pm-wrapper">
                <div class="buy-pm-image-wrap">
                  <img v-if="song.songAlbumUrlImage" :src="song.songAlbumUrlImage" alt="Artist image"
                       class="buy-pm-image" onerror="this.onerror=null;this.src='/img/profile-default-image.png';">
                  <img v-else src="../../assets/img/default-song-image.jpg"
                       alt="Artist image" class="buy-pm-image buy-pm-image-default">
                </div>
                <div class="buy-pm-info">
                  <span class="buy-pm-title" v-text="song.songTitle"></span>
                  <span class="buy-pm-descr" v-text="singerName"></span>
                </div>
              </div>
              <div class="buy-pm-total-wrap">
                <span class="buy-pm-total-title">Total:</span>
                <div class="buy-pm-total-value">
                  <span class="buy-pm-total-ipr"><span class="buy-pm-total-ipr-val">{{ amount }}</span>{{ currencyName }}</span>
                  *
                  <span class="buy-pm-total-price">€<span class="buy-pm-total-price-val">{{ price }}</span></span>
                  =
                  <span class="buy-pm-total-result">€<span class="buy-pm-total-result-val">{{ total }}</span></span>
                </div>
              </div>
            </div>
            <div class="delete-image-btn-wrap">
              <button type="button" id="sellButtonStopLimit"
                      class="submit_form_sell btn btn-hover"
                      @click.prevent="confirmAction">
                Confirm
              </button>
              <a href="javascript:void(0);"
                 class="btn btn-transparent cancel-btn"
                 @click.prevent="dialog = false">Cancel</a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </v-dialog>
</template>

<script>
import numberFormattingDecimal from "../../mixins/numberCurrencyConverter";
import numberFormattingInteger from "../../mixins/numberConverter";

export default {
  name: "ConfirmPopupForPM",
  data() {
    return {
      dialog: false,
      currencyName: process.env.VUE_APP_CURRENCY_NAME_SHORT
    };
  },
  props: {
    song: Object,
    userBid: Object,
    userBidTotal: Number,
    songDelta: Number,
    singerName: String,
  },
  mixins: [numberFormattingDecimal, numberFormattingInteger],
  computed: {
    songDeltaToFormat() {
      return this.numberFormattingInteger(this.songDelta)
    },
    amount() {
        return this.numberFormattingInteger(this.userBid.amount)
    },
    price() {
      return this.numberFormattingDecimal(this.userBid.price)
    },
    total() {
      return this.numberFormattingDecimal(this.userBidTotal)
    },
  },
  methods: {
    openPopups () {
      this.dialog = true;
    },
    confirmAction() {
      this.$emit('confirmAction')
      this.dialog = false
    }
  },
};
</script>

<style>

</style>