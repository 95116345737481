
export default function(instance) {
    return {
        follow(ieoId) {
            return instance.post(`/api/v2/follow/${ieoId}`)
        },
        unfollow(ieoId) {
            return instance.post(`/api/v2/unfollowieo/${ieoId}`)
        },
        followAll(singerId) {
            return instance.post(`/api/v2/followall/${singerId}/`)
        },
        unfollowAll(singerId) {
            return instance.post(`/api/v2/unfollowall/${singerId}`)
        }
    }
}