<template>
  <div class="audio-player-wrap">
    <div class="rewind-time">{{ currentTime }}</div>
    <div class="rewind-input" :style="{width: width}">
      <input
          type="range"
          name="audio-range"
          :id="`audio-range-${identifier}-${index}`"
          min="0"
          max="100"
          v-model="range"
          @change="songRangeHandler"
          @mousedown="rangeMouseDownHandler"
          @mouseup="rangeMouseUpHandler"
          @touchstart="rangeMouseDownHandler"
          @touchend="rangeMouseUpHandler"
          ref="audioPlayer"
      >
    </div>
    <div class="rewind-time">{{ songDuration }}</div>
  </div>
</template>

<script>
export default {
  name: "AudioPlayer",
  props: {
    src: {
      type: String,
      default: ''
    },
    togglePlayerFlag: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '150'
    },
    index: {
      type: Number
    },
    identifier: {
      type: String
    }
  },
  data() {
    return {
      player: new Audio(),
      range: 0,
      currentTime: '00:00',
      songDuration: '00:00',
      flag: false,
      mountedTimer: null
    }
  },
  methods: {
    songRangeHandler(event) {
      let player = this.player
      let target = event.target
      let value = target.value
      const min = target.min
      const max = target.max
      player.currentTime = Math.round(player.duration * (value / 100))
      let audioTime = Math.round(player.currentTime);
      this.currentTime = this.convertTo(player.currentTime)
      let audioLength = Math.round(player.duration)
      this.songDuration = this.convertTo(player.duration)
      this.range = (audioTime * 100) / audioLength;
      target.style.backgroundSize = value * 100 / (max - min) + '% 100%'
    },
    rangeMouseDownHandler() {
      this.$emit('audioPause')
      this.player.pause()
      clearInterval(this.playEvent)
    },
    rangeMouseUpHandler() {
      if (!this.togglePlayerFlag) {
        this.toggleMusic()
      }
    },
    toggleMusic() {
      if (this.togglePlayerFlag) {
        this.player.play()
        let target = document.getElementById(`audio-range-${this.identifier}-${this.index}`)
        const min = target.min
        const max = target.max
        this.playEvent = setInterval(() => {
          let audioTime = Math.round(this.player.currentTime);
          this.currentTime = this.convertTo(audioTime)
          let audioLength = Math.round(this.player.duration)
          this.songDuration = this.convertTo(audioLength)
          this.range = Math.trunc((audioTime * 100) / audioLength);

          target.style.backgroundSize = this.range * 100 / (max - min) + '% 100%'
        }, 10)
      } else {
        this.player.pause()
        clearInterval(this.playEvent)
      }
    },
    convertTo(val) {
      let res
      let time = val
      if (time <= 60) {
        if (time < 10) {
          res = `00:0` + Math.trunc(val)
        } else {
          res = `00:` + Math.trunc(val)
        }
      } else {
        let minutes = Math.floor(time / 60);
        if (minutes < 10) {
          minutes = `0${Math.floor(time / 60)}`
        }
        let seconds = Math.trunc(time - minutes * 60);
        if (seconds < 10) {
          seconds = `0${seconds}`
        }
        res = `${minutes}:${seconds}`
      }
      return res
    },
    refreshSong() {
      this.player.currentTime = 0
      this.player.pause()
    },
    tick() {
      let audioLength = Math.round(this.player.duration)
      let convertedAudioLength = this.convertTo(audioLength)
      if(this.player.duration) {
        this.songDuration = convertedAudioLength
      } else  {
        this.songDuration = '00:00'
        this.mountedTimer = setTimeout(this.tick, 1000)
      }
    }
  },
  computed: {},
  watch: {
    range(newVal) {
      let target = document.getElementById(`audio-range-${this.identifier}-${this.index}`)
      let min = target.min
      let max = target.max
      target.style.backgroundSize = newVal * 100 / (max - min) + '% 100%'
      let player = this.player
      let countCurrentTime = Math.round(player.duration * (newVal / 100))
      this.currentTime = this.convertTo(countCurrentTime)
    },
  },
  created() {
    this.player.src = this.src
  },
  mounted() {
    this.mountedTimer = setTimeout(this.tick, 1000)
  },
  destroyed() {
    this.player.pause()
    this.player.currentTime = 0
    clearInterval(this.playEvent)
  }
}
</script>

<style scoped>
.audio-player-wrap {
  display: flex;
  align-items: center;
  width: 100%;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;
  border: 0.5px solid #ff0090;
  background-image: linear-gradient(#ff0090, #ff0090);
  background-size: 0% 100%;
  background-repeat: no-repeat;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ff0090;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background .3s ease-in-out;
}

input[type=range]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.rewind-time {
  min-width: 35px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.rewind-input {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 8px;
}
</style>