<template>
    <div class="team-list-parent">
        <h2 class="team-list-parent-title" v-text="teamCategory.categoryName"></h2>
        <ul class="team-list-child">
            <TeamCategoryItemList
                v-for="(teamElement, index) in teamCategory.memberDatumResponses"
                :key="index"
                :teamElement="teamElement"
            />
        </ul>
    </div>
</template>

<script>
    import TeamCategoryItemList from "./TeamCategoryItemList";
    export default {
        name: "TeamCategoryList",
        components: {TeamCategoryItemList},
        props: {
            teamCategory: {
                type: Object,
                default: () => {}
            }
        }
    }
</script>

<style scoped>

</style>