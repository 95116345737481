<template>
  <div id="my-content">
    <Header/>
    <main id="root">
      <section class="section">
        <div class="page-payment">
          <div class="container-small">

            <div class="success-page" v-if="paymentStatus === 'HOLD'">
              <svg width="203" height="203" viewBox="0 0 203 203" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M132.366 32.1784C117.428 25.5223 100.738 23.8734 84.7865 27.4775C68.8346 31.0816 54.475 39.7457 43.8495 52.1776C33.2239 64.6095 26.9017 80.1431 25.8256 96.4617C24.7496 112.78 28.9774 129.01 37.8786 142.729C46.7797 156.448 59.8773 166.923 75.2178 172.591C90.5584 178.258 107.32 178.815 123.003 174.178C138.686 169.541 152.45 159.959 162.242 146.86C172.034 133.762 177.329 117.849 177.339 101.495V93.7183C177.339 88.8888 181.254 84.9737 186.083 84.9737C190.913 84.9737 194.828 88.8888 194.828 93.7183V101.505C194.816 121.63 188.3 141.213 176.249 157.332C164.199 173.451 147.261 185.243 127.962 190.95C108.662 196.656 88.0352 195.971 69.1569 188.996C50.2786 182.022 34.1606 169.131 23.2068 152.248C12.2529 135.365 7.05011 115.393 8.3743 95.3109C9.69849 75.2291 17.4787 56.1133 30.5547 40.8145C43.6306 25.5157 61.3016 14.8536 80.9322 10.4183C100.563 5.983 121.101 8.0122 139.484 16.2033C143.896 18.1689 145.879 23.3385 143.913 27.7499C141.947 32.1613 136.778 34.144 132.366 32.1784Z" stroke="#FE0190" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M192.264 27.6469C195.68 31.0601 195.683 36.5969 192.27 40.0136L107.686 124.682C106.047 126.323 103.822 127.245 101.502 127.246C99.1822 127.246 96.9571 126.325 95.3166 124.685L69.9416 99.3096C66.5266 95.8946 66.5266 90.3579 69.9416 86.9429C73.3566 83.5279 78.8934 83.5279 82.3084 86.9429L101.497 106.131L179.897 27.653C183.31 24.2364 188.847 24.2336 192.264 27.6469Z" stroke="#FE0190" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <div class="success-text">
                <p class="oops">Success!</p>
                <div class="take-me-back">Thanks you for using DjookyX.</div>
                <button class="btn btn-hover" @click="successRedirect">Continue</button>
              </div>
            </div>
            <div class="declined-page" v-if="paymentStatus === 'FAILED'">
                <svg width="203" height="203" viewBox="0 0 203 203" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M101.5 25.4167C59.4803 25.4167 25.4166 59.4804 25.4166 101.5C25.4166 143.52 59.4803 177.583 101.5 177.583C143.52 177.583 177.583 143.52 177.583 101.5C177.583 59.4804 143.52 25.4167 101.5 25.4167ZM8.41663 101.5C8.41663 50.0916 50.0915 8.41675 101.5 8.41675C152.908 8.41675 194.583 50.0916 194.583 101.5C194.583 152.909 152.908 194.583 101.5 194.583C50.0915 194.583 8.41663 152.909 8.41663 101.5Z" stroke="#FE0190" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M132.885 82.1354C136.205 78.816 136.205 73.434 132.885 70.1146C129.566 66.7951 124.184 66.7951 120.865 70.1146L101.5 89.4792L82.1354 70.1146C78.816 66.7951 73.434 66.7951 70.1146 70.1146C66.7951 73.434 66.7951 78.816 70.1146 82.1354L89.4792 101.5L70.1146 120.865C66.7951 124.184 66.7951 129.566 70.1146 132.885C73.434 136.205 78.816 136.205 82.1354 132.885L101.5 113.521L120.865 132.885C124.184 136.205 129.566 136.205 132.885 132.885C136.205 129.566 136.205 124.184 132.885 120.865L113.521 101.5L132.885 82.1354Z" stroke="#FE0190" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <div class="declined-text">
                  <p class="oops">Payment declined</p>
                  <div class="take-me-back">Sorry for inconvenience.</div>
                </div>
                <div class="declined-btn__wrapper">
                  <button class="declined-btn return" @click="declinedRedirect">Return</button>
                  <a class="declined-btn contact" href="mailto:support@djookyx.com">Contact support</a>
                </div>
              </div>

          </div>
        </div>
      </section>
    </main>
    <Footer/>
  </div>
</template>

<script>
import Header from "../header/Header";
import Footer from "../footer/Footer";
import getQueryParams from "../../mixins/getQueryParams";

export default {
  name: "InternalPaymentHandler",
  mixins: [getQueryParams],
  data() {
    return {
      paymentStatus: '',
      queryRedirect: '',
      songUuid: this.$route.params.id,
    }
  },
  methods: {
    successRedirect() {
      if (this.queryRedirect === 'initialSale') {
        this.$router.push({path: `/song/${this.songUuid}`})
      } else if (this.queryRedirect === 'createIeo') {
        this.$router.push({path: `/auction`})
      } else {
        this.$router.push({path: `/auction`})
      }
    },
    declinedRedirect() {
      if (this.queryRedirect === 'initialSale') {
        this.$router.push({path: `/song/${this.songUuid}`})
      } else if (this.queryRedirect === 'createIeo') {
        this.$router.push({path: `/profile/ieo-creating/${this.songUuid}`, query: {status:  'PAY_AWAITING'}})
      } else {
        this.$router.push({path: `/auction`})
      }
    },
  },
  mounted(){
    document.title = 'Internal Payment'

    this.queryRedirect = this.getQuery(this.$route.fullPath, 'redirectFrom')
    this.paymentStatus = this.getQuery(this.$route.fullPath, 'status')
  },
  components: {
    Footer,
    Header,
  },
}
</script>

<style scoped>

</style>