<template>
  <div id="my-content">
    <Header/>
    <main id="root">
      <article class="section" v-if="loadFlag">
        <div class="container-small">
          <h1 class="title page-title separator" v-text="article.title"></h1>
          <div class="news-header">
            <time class="main-news-item-date" v-text="article.publishedAt"></time>
          </div>
          <div class="news-content">
            <img :src="article.logo" alt="News image" loading="lazy">
            <div class="news-content-sharings-wrap">

              <ul class="main-news-category">

                <!--                                <li class="main-news-category-item">dffgg df</li>-->

                <!--                                <li class="main-news-category-item">news tag1</li>-->

                <!--                                <li class="main-news-category-item">news tag2</li>-->

                <!--                                <li class="main-news-category-item">news tag3</li>-->

              </ul>


              <div class="news-sharing-wrap">
                <div class="middle">
                  <div @click="openSocial = !openSocial" class="news-sharing-container social">
                    <svg class="show-btn" xmlns="http://www.w3.org/2000/svg" width="33.124" height="33.124"
                         viewBox="0 0 33.124 33.124">
                      <path class="a" d="M0,0H33.124V33.124H0Z"></path>
                      <path class="b"
                            d="M23.7,21.433A4.019,4.019,0,0,0,21,22.5l-9.841-5.728a4.518,4.518,0,0,0,.124-.966,4.518,4.518,0,0,0-.124-.966l9.73-5.672A4.131,4.131,0,1,0,19.562,6.14a4.518,4.518,0,0,0,.124.966l-9.73,5.672a4.141,4.141,0,1,0,0,6.045l9.827,5.741a3.894,3.894,0,0,0-.11.9,4.03,4.03,0,1,0,4.03-4.03Z"
                            transform="translate(1.14 0.76)"></path>
                    </svg>
                    <div class="news-sharing-menu" :class="openSocial ? 'open': ''">
                      <a th:href="@{'http://www.facebook.com/sharer.php?u=' + 'https://dev-sm.djooky.fun/market/article/' + ${article.body.title}}"
                         target="_blank">
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                             viewBox="0 0 155.139 155.139" style="enable-background:new 0 0 155.139 155.139;"
                             xml:space="preserve">
                            <g>
                                <path id="f_1_" d="M89.584,155.139V84.378h23.742l3.562-27.585H89.584V39.184
                                    c0-7.984,2.208-13.425,13.67-13.425l14.595-0.006V1.08C115.325,0.752,106.661,0,96.577,0C75.52,0,61.104,12.853,61.104,36.452
                                    v20.341H37.29v27.585h23.814v70.761H89.584z"></path>
                                    </g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                            </svg>
                      </a>
                      <a th:href="@{'https://twitter.com/share?url=' + ${#httpServletRequest.requestURI} + 'https://dev-sm.djooky.fun/market/article/' + ${article.body.title}}"
                         target="_blank">
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                             viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                            <g>
                                <g>
                                    <path d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016
                                        c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992
                                        c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056
                                        c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152
                                        c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792
                                        c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44
                                        C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568
                                        C480.224,136.96,497.728,118.496,512,97.248z"></path>
                                </g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                            </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="news-wrapper">
              <div v-html="article.fullDescription"></div>
            </div>
          </div>
          <div class="news-btn-back-wrap">
            <router-link :to="{ path: '/news' }" class="btn news-btn-back btn-hover">Back to News</router-link>
          </div>

        </div>
      </article>
      <div class="preloader-wrapper" v-else>
        <v-progress-circular
            :size="75"
            :width="8"
            color="#FF0090"
            indeterminate
        ></v-progress-circular>
      </div>
    </main>
    <Footer/>
  </div>
</template>

<script>
import Header from "../header/Header";
import Footer from "../footer/Footer";

export default {
  name: "NewsPage",
  data() {
    return {
      article: null,
      openSocial: false,
      loadFlag: false
    }
  },
  mounted() {
    document.title = 'Article'
    this.$load(async () => {
      this.loadFlag = false
      this.article = (await this.$api.newsPage.get(this.$route.params.id)).data.body
      this.loadFlag = true
    })
  },
  components: {Footer, Header},
}
</script>

<style scoped>

</style>