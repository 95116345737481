
import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router'
import { alert } from './alert.module';
import { authentication } from './authentication.module';
import { users } from './users.module';
import { intervals } from './setIntervals';
import { charts } from  './chart-config';
import { user }  from './user.module';
import { money } from './money.module';
import { staticLinks } from './static-links.module';
import { userTabs } from './user-tabs.module';

Vue.use(VueRouter)
    .use(Vuex);


export default new Vuex.Store({
  modules: {
    alert,
    authentication,
    users,
    intervals,
    charts,
    user,
    money,
    staticLinks,
    userTabs,
  }
});

