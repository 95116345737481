<template>
  <div id="artist-list-template" class="artist-list-wrap">

    <div class="artist-list">

      <button
          class="artist-list-item associated_artists__add-block"
          v-if="addBtnFlag"
          @click="$emit('addArtist')"
      >
        <span class="associated_artists__add-block-text">Add artist</span>
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 38C29.4934 38 38 29.4934 38 19C38 8.50659 29.4934 0 19 0C8.50659 0 0 8.50659 0 19C0 29.4934 8.50659 38 19 38Z" fill="#7AC385"/>
          <path d="M20.984 25.998H17.656V21.032H12.716V17.756H17.656V12.764H20.984V17.756H25.95V21.032H20.984V25.998Z" fill="white"/>
        </svg>
      </button>

      <Artist
          v-for="(artist, index) in artists"
          :key="index"
          :user-card="artist"
          :artist-type="artistType"
          @editArtist="$emit('editArtist', $event)"
      />

    </div>

  </div>
</template>

<script>

import Artist from "@/components/artist/Artist";

export default {
  name: "CompanyAssociatedArtistsList",
  props: {
    addBtnFlag: Boolean,
    artists: {
      type: Array,
      default: () => []
    },
    loadFlag: {
      type: Boolean,
      default: () => true
    },
    artistType: {
      type: String,
    },
  },
  data() {
    return {

    }
  },
  methods: {
    // addArtist(event) {
    //   this.$emit('addArtist', event)
    // },
  },
  components: {
    Artist,
  }
}
</script>

<style scoped>

.associated_artists__add-block {
  padding: 134px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border: none;
  background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' rx='4.5' fill='none' stroke='darkgrey' stroke-width='2' stroke-dasharray='15, 15'/></svg>");
  border-radius: 5px;
}


.associated_artists__add-block-text {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #A4A4A4;
  margin-bottom: 22px;
}

@media only screen and (max-width: 1400px) {
  .artist-list-item {
    width: calc(50% - 18px);
  }

  .artist-list-item:nth-child(2n) {
    margin-right: 0;
  }

  .artist-list-item:nth-child(3n) {
    margin-right: 35px;
  }

  .artist-list-item {
    margin-right: 35px;
  }

  .artist-list-item:nth-child(even) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .artist-list-item {
    width: calc(50% - 11px);
    margin-right: 22px;
  }

  .artist-list-item:nth-child(3n) {
    margin-right: 22px;
  }

  .artist-list-item:nth-child(even) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 992px) {
  .artist-list-item {
    width: calc(50% - 10px);
    margin-right: 20px;
  }

  .artist-list-item:nth-child(3n) {
    margin-right: 20px;
  }

  .artist-list-item:nth-child(even) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 768px) {
  .artist-list-item {
    width: 100%;
  }

  .artist-list-item,
  .artist-list-item:nth-child(3n) {
    margin-right: 0;
  }
}

 /* min - width */

@media only screen and (min-width: 768px) {
  .associated_artists__add-block:hover {
    background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' rx='4.5' fill='none' stroke='deeppink' stroke-width='3' stroke-dasharray='0'/></svg>");
  }

  .associated_artists__add-block:hover svg,
  .associated_artists__add-block:hover .associated_artists__add-block-text {
    transform: scale(1.15);
  }
}

</style>