<template>
  <div id="my-content" ref="top">
    <Header/>
    <main id="root">

      <div class="profile">
        <Sidebar/>


        <div class="profile-content" v-if="loadFlag">
          <h1 class="title page-title separator">Adding funds</h1>
          <div class="payment-methods-wrapper">
            <div class="payment-methods-title">
              choose a payment method
            </div>
            <div class="payment-methods-options" v-if="stepFlag !== 2">
              <div class="payment-methods-options__card"
                   @click="paymentMethod = 'card'"
                   :style="{'border-color': activePaymentMethodColorCard,
                            background: paymentMethod === 'card' ? '#FFF7FC' : '#FFFFFF',
                            color: activePaymentMethodColorCard
                   }"
              >
                <svg width="66" height="44" viewBox="0 0 66 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1.5" y="1.5" width="63" height="41" rx="6.5" :stroke="activePaymentMethodColorCard" stroke-width="3"/>
                  <rect x="1.5" y="10.5" width="63" height="9.03403" :stroke="activePaymentMethodColorCard" stroke-width="3"/>
                  <rect x="45" y="27.5" width="12" height="7" rx="2" :stroke="activePaymentMethodColorCard" stroke-width="3"/>
                </svg>
                Card
              </div>
              <div class="payment-methods-options__transfer"
                   @click="paymentMethodTrasfer"
                   :style="{'border-color': activePaymentMethodColorTransfer,
                            background: paymentMethod === 'transfer' ? '#FFF7FC' : '#FFFFFF',
                            color: activePaymentMethodColorTransfer
                   }"
              >
                <svg width="67" height="56" viewBox="0 0 67 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="2.11349" y="32.4194" width="39.6048" height="26.5867" rx="6.5" transform="rotate(-49.9259 2.11349 32.4194)" :stroke="activePaymentMethodColorTransfer" stroke-width="3"/>
                  <rect x="9.884" y="23.9162" width="39.6048" height="26.5867" rx="6.5" transform="rotate(-30 9.884 23.9162)" fill="white" :stroke="activePaymentMethodColorTransfer" stroke-width="3"/>
                  <rect x="4.28125" y="16.5" width="56" height="38" rx="6.5" fill="white" :stroke="activePaymentMethodColorTransfer" stroke-width="3"/>
                  <rect x="48.2812" y="30.5" width="17" height="10" rx="5" fill="white" :stroke="activePaymentMethodColorTransfer" stroke-width="3"/>
                </svg>
                Transfer
              </div>
            </div>
          </div>
          <div class="money-input-wrapper__card" v-if="paymentMethod === 'card'">
            <div class="step-progress">
              <div class="step-progress-wrap">
                <div class="step-progress-container">
                  <div class="step-progress-blocks first-step"
                       :style="{justifyContent: stepFlag === 1 ? 'flex-start' : 'flex-end'}">
                    <div class="step-progress-number" v-if="stepFlag === 1" :class="{active: stepFlag === 1}"></div>
                    <svg v-else viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d)">
                        <circle cx="27.5" cy="23.5" r="17.5" fill="#FF0090"/>
                      </g>
                      <path
                          d="M23.0392 22.3889C22.4419 21.815 21.4923 21.834 20.9183 22.4313C20.3444 23.0287 20.3634 23.9783 20.9608 24.5522L23.0392 22.3889ZM26.7143 28L25.675 29.0817C26.2556 29.6394 27.173 29.6394 27.7535 29.0817L26.7143 28ZM35.0392 22.0817C35.6366 21.5077 35.6556 20.5581 35.0817 19.9608C34.5077 19.3634 33.5581 19.3444 32.9608 19.9183L35.0392 22.0817ZM20.9608 24.5522L25.675 29.0817L27.7535 26.9183L23.0392 22.3889L20.9608 24.5522ZM27.7535 29.0817L35.0392 22.0817L32.9608 19.9183L25.675 26.9183L27.7535 29.0817Z"
                          fill="#F9F9F9"/>
                      <defs>
                        <filter id="filter0_d" x="0" y="0" width="55" height="55" filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                         result="hardAlpha"/>
                          <feOffset dy="4"/>
                          <feGaussianBlur stdDeviation="5"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0 0 0 0 0 0.564706 0 0 0 0.1 0"/>
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                        </filter>
                      </defs>
                    </svg>
                    <div class="step-progress-text" v-if="stepStyle === 1"
                         :style="{color: stepFlag === 1 ? '#464646' : '#CCCCCC'}">Transfer amount
                    </div>
                  </div>
                  <div class="step-progress-blocks second-step"
                       :style="{justifyContent: stepFlag === 2 ? 'flex-start' : 'flex-end'}">
                    <div class="step-progress-number" v-if="stepFlag === 2 || stepFlag === 1"
                         :class="{active: stepFlag === 2}"></div>
                    <svg width="55" height="55" v-else viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d)">
                        <circle cx="27.5" cy="23.5" r="17.5" fill="#FF0090"/>
                      </g>
                      <path
                          d="M23.0392 22.3889C22.4419 21.815 21.4923 21.834 20.9183 22.4313C20.3444 23.0287 20.3634 23.9783 20.9608 24.5522L23.0392 22.3889ZM26.7143 28L25.675 29.0817C26.2556 29.6394 27.173 29.6394 27.7535 29.0817L26.7143 28ZM35.0392 22.0817C35.6366 21.5077 35.6556 20.5581 35.0817 19.9608C34.5077 19.3634 33.5581 19.3444 32.9608 19.9183L35.0392 22.0817ZM20.9608 24.5522L25.675 29.0817L27.7535 26.9183L23.0392 22.3889L20.9608 24.5522ZM27.7535 29.0817L35.0392 22.0817L32.9608 19.9183L25.675 26.9183L27.7535 29.0817Z"
                          fill="#F9F9F9"/>
                      <defs>
                        <filter id="filter0_d" x="0" y="0" width="55" height="55" filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                         result="hardAlpha"/>
                          <feOffset dy="4"/>
                          <feGaussianBlur stdDeviation="5"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0 0 0 0 0 0.564706 0 0 0 0.1 0"/>
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                        </filter>
                      </defs>
                    </svg>
                    <div class="step-progress-text" v-if="stepStyle === 1"
                         :style="{color: stepFlag === 2 ? '#464646' : '#CCCCCC'}">Credit card
                    </div>
                  </div>
                  <div class="progress-line"></div>
                  <div class="progress-line-active"
                       :style="{width: stepFlag === 2 ? 'calc(100% - 190px)' : '0%'}"></div>
                </div>
              </div>
              <div class="step-progress-container-mobile" v-if="stepStyle === 0">
                <ul class="step-progress-list">
                  <li class="step-progress-item" :style="{color: stepFlag === 1 ? '#464646' : '#CCCCCC'}">
                    <svg viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d)">
                        <circle cx="27.5" cy="23.5" r="17.5" :fill="stepFlag === 1 ? '#FF0090' : 'orange'"/>
                      </g>
                      <path
                          d="M23.0392 22.3889C22.4419 21.815 21.4923 21.834 20.9183 22.4313C20.3444 23.0287 20.3634 23.9783 20.9608 24.5522L23.0392 22.3889ZM26.7143 28L25.675 29.0817C26.2556 29.6394 27.173 29.6394 27.7535 29.0817L26.7143 28ZM35.0392 22.0817C35.6366 21.5077 35.6556 20.5581 35.0817 19.9608C34.5077 19.3634 33.5581 19.3444 32.9608 19.9183L35.0392 22.0817ZM20.9608 24.5522L25.675 29.0817L27.7535 26.9183L23.0392 22.3889L20.9608 24.5522ZM27.7535 29.0817L35.0392 22.0817L32.9608 19.9183L25.675 26.9183L27.7535 29.0817Z"
                          fill="#F9F9F9"/>
                      <defs>
                        <filter id="filter0_d" x="0" y="0" width="55" height="55" filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                         result="hardAlpha"/>
                          <feOffset dy="4"/>
                          <feGaussianBlur stdDeviation="5"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0 0 0 0 0 0.564706 0 0 0 0.1 0"/>
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                        </filter>
                      </defs>
                    </svg>
                    <span>Transfer amount</span>
                  </li>
                  <li class="step-progress-item" :style="{color: stepFlag === 2 ? '#464646' : '#CCCCCC'}">
                    <svg viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d)">
                        <circle cx="27.5" cy="23.5" r="17.5"
                                :fill="stepFlag === 1 ? '#F0F0F0' : stepFlag === 2 ? '#FF0090': 'orange'"/>
                      </g>
                      <path
                          d="M23.0392 22.3889C22.4419 21.815 21.4923 21.834 20.9183 22.4313C20.3444 23.0287 20.3634 23.9783 20.9608 24.5522L23.0392 22.3889ZM26.7143 28L25.675 29.0817C26.2556 29.6394 27.173 29.6394 27.7535 29.0817L26.7143 28ZM35.0392 22.0817C35.6366 21.5077 35.6556 20.5581 35.0817 19.9608C34.5077 19.3634 33.5581 19.3444 32.9608 19.9183L35.0392 22.0817ZM20.9608 24.5522L25.675 29.0817L27.7535 26.9183L23.0392 22.3889L20.9608 24.5522ZM27.7535 29.0817L35.0392 22.0817L32.9608 19.9183L25.675 26.9183L27.7535 29.0817Z"
                          fill="#F9F9F9"/>
                      <defs>
                        <filter id="filter0_d" x="0" y="0" width="55" height="55" filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                         result="hardAlpha"/>
                          <feOffset dy="4"/>
                          <feGaussianBlur stdDeviation="5"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0 0 0 0 0 0.564706 0 0 0 0.1 0"/>
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                        </filter>
                      </defs>
                    </svg>
                    <span>Credit card</span>
                  </li>
                </ul>
              </div>

            </div>
            <div class="trasnfer-amount-wrapper" v-if="stepFlag === 1">
              <span>Enter the amount you want to fund your DjookyX wallet</span>
              <div class="trasnfer-amount-input-block">
                <div class="trasnfer-amount-header">Amount</div>
                <div class="trasnfer-amount-input-wrap">
                  <VueAutoNum
                      v-model="paymentData.price"
                      class="transfer-amount-input"
                      name="amount"
                      placeholder="€ 0.00"
                      @blur.native="$v.paymentData.price.$touch()"
                      :options="numParams"
                  ></VueAutoNum>
                </div>
                <div class="trasnfer-amount-commission">Commission is 1,8% + 0,18€</div>
                <p class="error-message"
                   v-if="$v.paymentData.price.$dirty && !$v.paymentData.price.required"
                >Field is required</p>
                <p class="error-message"
                   v-if="!$v.paymentData.price.integer"
                >Must be integer only</p>
                <p class="error-message"
                   v-if="$v.paymentData.price.$dirty && !$v.paymentData.price.maxValue"
                >You can’t add more than {{'€' + numberFormattingDecimal($v.paymentData.price.$params.maxValue.max) }}</p>
                <p class="error-message"
                   v-if="$v.paymentData.price.$dirty && !$v.paymentData.price.minValue"
                >You cannot transfer less then {{'€' + numberFormattingDecimal($v.paymentData.price.$params.minValue.min) }}</p>
              </div>
              <button class="btn trasnfer-amount-input-button"
                      :class="{'disabled-btn': disabledBtn}"
                      :disabled="disabledBtn"
                      @click="submitHandler"
              >Next</button>
            </div>
            <div class="trasnfer-amount-wrapper" v-else>
              <span>Please enter your credit card info</span>
              <div
                  v-if="cardIframe"
                  id="PaylineWidget"
                  :data-token="userToken"
                  data-template="column"
                  data-event-didshowstate="OnLoad"
                  ref="PaylineWidgetRef"
              ></div>
            </div>
          </div>
          <div class="money-input-wrapper__transfer" v-if="paymentMethod === 'transfer' && bankingAliasStatus">
            <div class="transfer-wrap transfer-iban">
              <div class="transfer-item" id="transfer-iban">
                {{ ibanData.IBAN }}
              </div>
              <div @click="copyIban()" class="transfer-copy">Copy</div>

              <transition name="fade" tag="span">
                <span class="transfer-copy-message" v-if="copyIbanMessage">Copied to clipboard</span>
              </transition>

            </div>
            <div class="transfer-wrap transfer-bic">
              <div class="transfer-item" id="transfer-bic">
                {{ ibanData.BIC }}
              </div>
              <div @click="copyBic()" class="transfer-copy">Copy</div>

              <transition name="fade" tag="span">
                <span class="transfer-copy-message" v-if="copyBicMessage">Copied to clipboard</span>
              </transition>

            </div>
            <div class="transfer-wrap transfer-country">
              <div class="transfer-item"  id="transfer-country">
                {{ ibanData.country }}
              </div>
              <div @click="copyCountry()" class="transfer-copy">Copy</div>

              <transition name="fade" tag="span">
                <span class="transfer-copy-message" v-if="copyCountryMessage">Copied to clipboard</span>
              </transition>

            </div>
            <div class="transfer-wrap transfer-text">
              <p>You can use your personal virtual IBAN (International bank account number) indicated above to transfer funds from any bank directly to your DjookyX wallet. Please <span class="transfer-contact-us" @click="$router.push({path: '/contact'})">contact us</span> in case if any questions.</p>
            </div>
          </div>
        </div>
        <div class="preloader-wrapper profile-preloader" v-else>
          <v-progress-circular
              :size="75"
              :width="8"
              color="#FF0090"
              indeterminate
          ></v-progress-circular>
        </div>
      </div>
      <MangopayCallErrorPopup ref="mangopayCallErrorPopup"/>
      <ServerErrorPopup ref="ServerErrorPopup" :errorMessage="error.message" @confirmErrorAction="confirmErrorAction"/>
      <CreateVirtualIBANPopup ref="CreateVirtualIBANPopup" @createIBAN="createBankingAliasRequest"/>
    </main>
    <Footer/>
  </div>
</template>

<script>

import Footer from "../footer/Footer";
import Header from "../header/Header";
import Sidebar from "./Sidebar";
import ServerErrorPopup from "../popups/ServerErrorPopup";
import MangopayCallErrorPopup from "../popups/MangopayCallErrorPopup";
import CreateVirtualIBANPopup from "../popups/CreateVirtualIBANPopup";
import {required, integer, maxValue, minValue} from "vuelidate/lib/validators";
import numberFormattingDecimal from "../../mixins/numberCurrencyConverter";
import copyToClipboard from "../../mixins/copyToClipboard";


export default {
  name: "WalletMoneyInput",
  data() {
    return {
      loadFlag: true,
      bankingAliasStatus: false,
      copyIbanMessage: false,
      copyBicMessage: false,
      copyCountryMessage: false,
      stepStyle: 0,
      stepFlag: 1,
      paymentMethod: 'card',
      disableSubmitButton: false,
      paymentData: {
        price: 0,
        paymentType: 'CARD',
        paymentGoal: 'WALLET_PAY_IN'
      },
      numParams: {
        modifyValueOnWheel: false, // turn off wheel mode
        digitGroupSeparator: '.',
        decimalCharacter: ',',
        decimalPlaces: 0,
        currencySymbolPlacement: 'p', // prefix || suffix
        currencySymbol: '€\u00a0',
        overrideMinMaxLimits: null,
        minimumValue: '0',
        maximumValue: '10000000000',
      },
      userToken: '',
      cardIframe: false,
      error: {
        message: null
      },
      ibanData: null
    }
  },
  validations() {
    return {
      paymentData: {
        price: {
          required,
          integer,
          maxValue: maxValue(2450),
          minValue: minValue(1),
        },
      },
    }
  },
  mixins: [numberFormattingDecimal, copyToClipboard],
  computed: {
    disabledBtn() {
      return this.$v.$invalid || this.disableSubmitButton
    },
    activePaymentMethodColorCard() {
      return this.paymentMethod === 'card' ? '#FF0090' : '#000000'
    },
    activePaymentMethodColorTransfer() {
      return this.paymentMethod === 'transfer' ? '#FF0090' : '#000000'
    }
  },
  methods: {
    createBankingAliasRequest() {
      this.$load(async () => {
        this.loadFlag = false
        await this.$api.payment.createBankingAlias()
        this.paymentMethod = 'transfer'
        this.loadFlag = true
        await this.getBankingAliasRequest()
      }, error => {
        this.error.message = error.data.errors[0].message
        this.$refs.ServerErrorPopup.openPopups()
      })
    },
    getBankingAliasRequest() {
      this.$load(async () => {
        let res = (await this.$api.payment.getBankingAlias()).data.body
        this.ibanData = res
        this.bankingAliasStatus = true

      })
    },
    lengthWatcher() {
      window.addEventListener("resize", () => {
        if (window.innerWidth > 440) {
          this.stepStyle = 1
        } else if (window.innerWidth <= 440) {
          this.stepStyle = 0
        }
      }, false);
    },
    goTo(refName) {
      let element = this.$refs[refName]
      element.scrollIntoView({behavior: 'smooth'})
    },
    addScript(src, callback) {
      let s = document.createElement('script');
      s.setAttribute('src', src);
      s.onload = callback;
      document.body.appendChild(s);
    },
    getQuery(urlString, query) {
      let uri = urlString.split('?');
      if (uri.length === 2) {
        let vars = uri[1].split('&');
        let getVars = {};
        let tmp = '';
        vars.forEach(function (v) {
          tmp = v.split('=');
          if (tmp.length === 2)
            getVars[tmp[0]] = tmp[1];
        });
        if (query === 'transactionId') {
          return getVars.transactionId
        }
        if (query === 'token') {
          return getVars.token
        }
      }
    },
    submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        this.$load(async () => {
          this.disableSubmitButton = true
          let res = await this.$api.payment.getPayment(this.paymentData)
          let url = res.data.body.redirectUrl
          this.userToken = this.getQuery(url, 'token')
          this.stepFlag = 2
          if (process.env.VUE_APP_ENV_NAME === 'prod') {
            this.addScript('https://payment.payline.com/scripts/widget-min.js')
          } else {
            this.addScript("https://homologation-payment.payline.com/scripts/widget-min.js")
          }
          // this.addScript("https://homologation-payment.payline.com/scripts/widget-min.js")
          this.cardIframe = true
          this.disableSubmitButton = false
        }, error => {
          this.disableSubmitButton = true
          if (error.data.errors[0].id === "MANGOPAY_INITIAL_EXCEPTION" &&
              error.data.errors[0].message === 'Rate limit exceeded. Please contact support for more assistance') {
            this.$refs.mangopayCallErrorPopup.openPopup()
          }
          this.error.message = error.data.errors[0].message
          this.$refs.ServerErrorPopup.openPopups()
          this.disableSubmitButton = false

        })
      }
    },
    paymentMethodTrasfer() {
      this.paymentMethod = 'transfer'
      if(!this.bankingAliasStatus) {
        this.$refs.CreateVirtualIBANPopup.openPopups()
      }
    },
    confirmErrorAction() {
      this.paymentMethod = 'card'
      this.loadFlag = true
    },
    copyIban() {
      this.copyToClipboard('transfer-iban')

      if (this.copyBicMessage || this.copyCountryMessage) {
        this.copyBicMessage = false
        this.copyCountryMessage = false

        this.copyIbanMessage = true
        setTimeout(() => {
          this.copyIbanMessage = false
        }, 2000)
      } else {
        this.copyIbanMessage = true
        setTimeout(() => {
          this.copyIbanMessage = false
        }, 2000)
      }
    },
    copyBic() {
      this.copyToClipboard('transfer-bic')

      if (this.copyIbanMessage || this.copyCountryMessage) {
        this.copyIbanMessage = false
        this.copyCountryMessage = false

        this.copyBicMessage = true
        let time = setTimeout(() => {
          this.copyBicMessage = false
        }, 2000)
      } else {
        this.copyBicMessage = true
        setTimeout(() => {
          this.copyBicMessage = false
        }, 2000)
      }
    },
    copyCountry() {
      this.copyToClipboard('transfer-country')

      if (this.copyIbanMessage || this.copyBicMessage) {
        this.copyIbanMessage = false
        this.copyBicMessage = false

        this.copyCountryMessage = true
        setTimeout(() => {
          this.copyCountryMessage = false
        }, 2000)
      } else {
        this.copyCountryMessage = true
        setTimeout(() => {
          this.copyCountryMessage = false
        }, 2000)
      }
    },
  },
  mounted() {
    document.title = 'Money Input'
    this.lengthWatcher()
    this.getBankingAliasRequest()
    if (window.innerWidth > 440) {
      this.stepStyle = 1
    } else if (window.innerWidth <= 440) {
      this.stepStyle = 0
    }
    this.goTo('top')
  },
  components: {
    Footer,
    Header,
    Sidebar,
    ServerErrorPopup,
    MangopayCallErrorPopup,
    CreateVirtualIBANPopup,
  },
}
</script>

<style scoped>

/* animation  */

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* end animation  */

h1.title {
  text-transform: uppercase;
}

.step-progress {
  width: 100%;
}

.step-progress-wrap {
  width: 100%;
  background: #F9F9F9;
  border-radius: 15px;
  margin-bottom: 38px;
}

.step-progress-container {
  max-width: 410px;
  width: 100%;
  margin: 0 auto;
  padding: 30px 0;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.step-progress-blocks svg {
  width: 55px;
  height: 55px;
}

.progress-line {
  position: absolute;
  top: 52px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 170px);
  height: 13px;
  background: rgba(234, 234, 234, 0.6);
  z-index: 0;
}

.progress-line-active {
  position: absolute;
  top: 52px;
  left: 50%;
  transform: translateX(-50%);
  height: 13px;
  background: #FF0090;
  z-index: 9;
}

.step-progress-number {
  position: relative;
  width: 35px;
  height: 35px;
  background: #F0F0F0;
  border-radius: 50%;
  margin-bottom: 15px;
}

.step-progress-blocks {
  width: 50%;
  z-index: 10;
}

.step-progress-blocks.first-step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-progress-blocks.second-step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-progress-blocks.second-step .step-progress-number:after {
  content: '2';
  position: absolute;
  top: 5px;
  left: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
}


.step-progress-blocks.first-step .step-progress-number {
  margin-left: 20px;
}

.step-progress-blocks.third-step .step-progress-number {
  margin-right: 20px;
}

.step-progress-number.active {
  position: relative;
  width: 51px;
  height: 51px;
  border: 1px solid #FF0090;
  border-radius: 50%;
  margin-left: 20px;
  margin-bottom: 10px;
}

.step-progress-blocks.first-step .step-progress-number.active:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #FF0090;
}

.step-progress-blocks.first-step .step-progress-number.active:after {
  content: '1';
  position: absolute;
  top: 8px;
  left: 19px;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #FFFFFF;
  z-index: 10;
}

.step-progress-blocks.second-step .step-progress-number.active:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #FF0090;
}

.step-progress-blocks.second-step .step-progress-number.active:after {
  content: '2';
  position: absolute;
  top: 8px;
  left: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #FFFFFF;
  z-index: 10;
}

.step-progress-blocks.third-step .step-progress-number.active:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #FF0090;
}

.step-progress-blocks.third-step .step-progress-number.active:after {
  content: '3';
  position: absolute;
  top: 8px;
  left: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #FFFFFF;
  z-index: 10;
}

.step-progress-text {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.step-progress-blocks.second-step .step-progress-text {

}

.step-progress-blocks.third-step .step-progress-number:after {
  content: '3';
  position: absolute;
  top: 5px;
  left: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
}

.step-progress-container-mobile {
  width: 100%;
  margin-bottom: 40px;
}

.step-progress-list {

}

.step-progress-item {
  display: flex;
  list-style: none;
}

.step-progress-item svg {
  width: 30px;
  height: 30px;
}

.step-progress-item svg path {

}

.trasnfer-amount-wrapper {
  max-width: 580px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #747474;
}

.trasnfer-amount-input-block {
  /*display: flex;*/
  /*justify-content: flex-start;*/
  /*flex-wrap: wrap;*/
  width: 100%;
  padding: 30px;
  background: #F9F9F9;
  border: 1px solid #EAEAEA;
  border-radius: 15px;
  margin-top: 20px;
}

.trasnfer-amount-header {
  margin-bottom: 10px;
  text-align: start;
  width: 100%;
}

.trasnfer-amount-input-wrap {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

/*.trasnfer-amount-input-wrap::before {*/
/*  content: '€';*/
/*  position: absolute;*/
/*  left: 30px;*/
/*  top: 50%;*/
/*  transform: translateY(-50%);*/
/*  font-style: normal;*/
/*  font-weight: normal;*/
/*  font-size: 20px;*/
/*  line-height: 23px;*/
/*  color: #131415;*/
/*}*/

.transfer-amount-input {
  width: calc(100% - 95px);
  /*padding: 13px 50px;*/
  padding: 13px 50px 13px 20px;
  background: #FFFFFF;
  border: 1px solid #C6C6C6;
  border-radius: 5px;
  margin-right: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #131415;
}

.trasnfer-amount-input-button {
  margin-top: 50px;
}

.error-message {
  color: red;
  text-align: left;
  margin: 8px 0 0 0;
  /*position: absolute;*/
  /*bottom: -42px;*/
  /*left: 0;*/
}

.trasnfer-amount-commission {
  text-align: left;
  margin-top: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #A6A6A6;
}

.payment-methods-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.payment-methods-title {
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 24px;
  text-transform: uppercase;
  color: #464646;
  margin-bottom: 25px;
}

.payment-methods-options {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.payment-methods-options svg {
  margin-bottom: 20px;
}

.payment-methods-options__card, .payment-methods-options__transfer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 215px;
  height: 160px;
  border: 2px solid;
  border-radius: 18px;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
}

.payment-methods-options__card {
  margin-right: 25px;
}

.money-input-wrapper__transfer {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.transfer-wrap {
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
}

.transfer-copy {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #FF0090;
  cursor: pointer;
}

.transfer-copy-message {
  position: absolute;
  right: -40%;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #2D2B2B;
  transition: .5s ease;
}

.transfer-item {
  width: 100%;
  border: 1px solid #C6C6C6;
  border-radius: 5px;
  padding: 17px 24px;
  margin-right: 30px;
}

.transfer-wrap.transfer-text {
  text-align: center;
}

.transfer-contact-us {
  color: #FF0090;
}

@media only screen and (max-width: 1300px) {
  .transfer-copy-message {
    top: unset;
    transform: unset;
    right: unset;
    bottom: -18px;
    left: 10px;
    font-size: 15px;
    line-height: 16px;
  }
}

@media only screen and (max-width: 1200px) {
  .step-progress-text {
    font-size: 18px;
  }
}

@media only screen and (max-width: 768px) {

  .transfer-item {
    margin-right: 0;
  }

  .transfer-copy {
    display: none;
  }

  .transfer-copy-message {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  .step-progress-number.active {
    width: 29px;
    height: 29px;
    margin-bottom: 0;
  }

  .step-progress-text {
    font-size: 10px;
  }

  .step-progress-number {
    width: 19px;
    height: 19px;
    margin-bottom: 6px;
  }

  .step-progress-blocks.first-step .step-progress-number.active:before, .step-progress-blocks.second-step .step-progress-number.active:before, .step-progress-blocks.third-step .step-progress-number.active:before {
    width: 25px;
    height: 25px;
    top: 1px;
    left: 1px;
  }

  .step-progress-blocks.first-step .step-progress-number.active:after {
    top: -5px;
    left: 10px;
    font-size: 18px;
  }

  .step-progress-blocks.second-step .step-progress-number.active:after, .step-progress-blocks.third-step .step-progress-number.active:after {
    top: -5px;
    left: 8px;
    font-size: 18px;
  }

  .step-progress-blocks.second-step .step-progress-number:after, .step-progress-blocks.third-step .step-progress-number:after {
    font-size: 12px;
    top: -2px;
    left: 7px;
  }

  .step-progress-blocks.second-step .step-progress-number.active {
    margin-left: 8px;
  }

  .step-progress-blocks.first-step .step-progress-number.active {
    margin-left: 0;
  }

  .progress-line, .progress-line-active {
    height: 5px;
    top: 42px;
  }

  .step-progress-blocks svg {
    width: 30px;
    height: 30px;
  }

  .step-progress-blocks.third-step .step-progress-text {
    margin-right: 15px;
  }

  .progress-line {
    width: 50%;
  }

  .transfer-amount-input {
     width: 100%;
     margin-right: 0;
  }
}

@media only screen and (max-width: 500px) {
  .trasnfer-amount-input-button {
    padding: 10px 50px;
  }

  .trasnfer-amount-input-block {
    padding: 30px 10px;
  }

  .trasnfer-amount-input-wrap {
    justify-content: space-between;
  }

  /*.trasnfer-amount-input-wrap::before {*/
  /*  left: 10px;*/
  /*}*/

  .transfer-amount-input {
    /*padding: 13px 0 13px 25px;*/
    margin-right: 0;
  }

  .payment-methods-options__card {
    margin-right: 17px;
    height: 115px;
  }

  .payment-methods-options svg {
    margin-bottom: 14px;
    width: 48px;
    height: auto;
  }

  .payment-methods-options__transfer {
    height: 115px;
  }

}

@media only screen and (max-width: 440px) {
  .step-progress-blocks.second-step .step-progress-number.active {
    margin-left: 0;
  }
}
</style>