<template>
  <div id="my-content">
    <Header ref="header" @logoutEvent="logoutEvent"/>
    <main id="root">

      <section class="section">
        <div class="container-small">
          <div class="form-wrap log-in-form-wrap">
            <div class="log-in-form">
              <div class="form-logo-wrap">
                <img src="@/assets/img/Union.svg" alt="Form logo" class="form-logo" loading="lazy">
              </div>
              <span class="form-subtitle">Welcome to DjookyX!</span>
              <span class="form-descr">The world's first music investment and online trading platform that matches best undiscovered music with untapped financial opportunities.</span>
              <form class="login" @submit.prevent="onSubmit">
                <div class="login-field-wrap">
                  <input type="text"
                         name="email"
                         id="email"
                         v-model.trim="email"
                         placeholder="E-mail*"
                         :class="{ 'is-invalid': submitted && !email }"
                         @blur="$v.email.$touch()"
                  >
                  <p class="error-message absolute"
                     v-if="$v.email.$dirty && !$v.email.required">
                    Field is required</p>
                </div>
                <div class="password-field-wrap">
                  <input :type="passwordType"
                         name="password"
                         id="password"
                         v-model.trim="password"
                         placeholder="Password*"
                         :class="{ 'is-invalid': submitted && !password }"
                         @blur="$v.password.$touch()"
                  >
                  <a href="javascript:void(0);" class="password-show" @click="togglePassword">
                    <svg class="show" :style="{display: passwordType === 'password' ? 'none' : 'block'}"
                         xmlns="http://www.w3.org/2000/svg" width="22.389" height="16.792"
                         viewBox="0 0 22.389 16.792">
                      <g transform="translate(9 -2)">
                        <g transform="translate(-9 2)">
                          <path class="a"
                                d="M11.278,2C4.2,2,0,10.4,0,10.4s4.2,8.4,11.278,8.4c6.913,0,11.11-8.4,11.11-8.4S18.191,2,11.278,2Zm-.084,13.993a5.6,5.6,0,1,1,5.6-5.6A5.6,5.6,0,0,1,11.194,15.993Zm2.8-5.6a2.8,2.8,0,1,1-2.8-2.8A2.789,2.789,0,0,1,13.993,10.4Z"
                                transform="translate(0 -2)"></path>
                        </g>
                      </g>
                    </svg>
                    <svg class="hide" :style="{display: passwordType === 'text' ? 'none' : 'block'}"
                         xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" viewBox="0 0 16 16">
                      <path
                          d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
                      <path
                          d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
                    </svg>
                  </a>
                  <p class="error-message absolute"
                     v-if="$v.password.$dirty && !$v.password.required">
                    Field is required</p>
                </div>
                <!--                                  <p class="password-field-wrap" v-else>-->
                <!--                                    <input type="text" name="password" id="passwordShow" v-model="password" placeholder="Password*"  :class="{ 'is-invalid': submitted && !password }">-->

                <!--                                    <a href="javascript:void(0);" class="password-show" @click="passwordFlag = !passwordFlag">-->
                <!--                                      <svg class="show" xmlns="http://www.w3.org/2000/svg" width="22.389" height="16.792" viewBox="0 0 22.389 16.792"><defs></defs><g transform="translate(9 -2)"><g transform="translate(-9 2)"><path class="a" d="M11.278,2C4.2,2,0,10.4,0,10.4s4.2,8.4,11.278,8.4c6.913,0,11.11-8.4,11.11-8.4S18.191,2,11.278,2Zm-.084,13.993a5.6,5.6,0,1,1,5.6-5.6A5.6,5.6,0,0,1,11.194,15.993Zm2.8-5.6a2.8,2.8,0,1,1-2.8-2.8A2.789,2.789,0,0,1,13.993,10.4Z" transform="translate(0 -2)"/></g></g></svg>-->
                <!--                                      <svg class="hide" xmlns="http://www.w3.org/2000/svg" width="20.955" height="20.955" viewBox="0 0 20.955 20.955"><defs></defs><g transform="translate(0 0)"><g transform="translate(0 0)"><g transform="translate(0)"><path class="a" d="M20.826,19.592,1.362.128a.437.437,0,0,0-.617,0L.128.745a.436.436,0,0,0,0,.617l3.61,3.61A11.219,11.219,0,0,0,.021,10.344a.439.439,0,0,0,0,.267,11,11,0,0,0,10.456,7.725,10.789,10.789,0,0,0,5.261-1.362l3.854,3.854a.437.437,0,0,0,.617,0l.617-.617A.436.436,0,0,0,20.826,19.592Zm-10.349-3a6.1,6.1,0,0,1-4.89-9.767l1.9,1.9a3.421,3.421,0,0,0-.5,1.757,3.5,3.5,0,0,0,3.492,3.492,3.421,3.421,0,0,0,1.757-.5l1.9,1.9A6.1,6.1,0,0,1,10.477,16.589Z" transform="translate(0 0)"></path><path class="a" d="M246.2,171.013a.43.43,0,0,0,.092.47l2.97,2.97a.429.429,0,0,0,.733-.309,3.413,3.413,0,0,0-3.395-3.395A.469.469,0,0,0,246.2,171.013Z" transform="translate(-236.056 -163.736)"></path><path class="a" d="M156.616,66.225a.43.43,0,0,0,.489.084,5.95,5.95,0,0,1,2.587-.592,6.021,6.021,0,0,1,6.014,6.014,5.95,5.95,0,0,1-.592,2.587.43.43,0,0,0,.084.489l1.489,1.489a.429.429,0,0,0,.3.126h0a.432.432,0,0,0,.3-.127,11.194,11.194,0,0,0,2.685-4.432.432.432,0,0,0,0-.263A10.826,10.826,0,0,0,159.692,64a10.7,10.7,0,0,0-3.762.681.429.429,0,0,0-.153.706Z" transform="translate(-149.047 -61.422)"></path></g></g></g></svg>-->
                <!--                                    </a>-->
                <!--                                  </p>-->
                <div class="forgot-pass-btn-wrap">
                  <router-link :to="{path: '/password/reset'}" class="forgot-pass-btn">Forgot Password?</router-link>
                </div>
                <button type="submit" class="btn btn-hover" :disabled="signInDisabled">Sign In</button>
              </form>

              <!--              <div class="socialaccount_ballot">-->
              <!--                <div class="login-or">or sign in with:</div>-->
              <!--                <ul class="socialaccount_providers">-->
              <!--                  <ul class="socialaccount_providers">-->
              <!--                    <li>-->
              <!--                      <a title="Facebook" class="socialaccount_provider facebook"-->
              <!--                         href="https://static.djookyawards.com/accounts/facebook/login/?process=login" target="_blank">Facebook</a>-->
              <!--                    </li>-->
              <!--                    <li>-->
              <!--                      <a title="Google" class="socialaccount_provider google"-->
              <!--                         href="https://djookyawards.com/accounts/google/login/?process=login" target="_blank">Google</a>-->
              <!--                    </li>-->
              <!--                  </ul>-->
              <!--                </ul>-->
              <!--              </div>-->
              <div class="log-in-register-wrap">
                <span class="log-in-register-text">Don’t have an account? </span>
                <div class="log-in-register-type-wrap">
                  <router-link :to="{path: `/register`}" class="log-in-register-link">
                    Create new account
                  </router-link>
                  <span class="form-separator">or</span>
                  <router-link :to="{path: `/register/?djookyawards=1`}" class="log-in-register-link">
                    Register with Djooky Music Awards
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ServerErrorPopup ref="serverErrorPopup" :errorMessage="error.dataError"/>
    </main>
    <Footer/>
  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import ServerErrorPopup from "../components/popups/ServerErrorPopup";
import {required} from "vuelidate/lib/validators";
import scrollToElement from "@/mixins/scrollToElement";

export default {
  name: "Login",
  data() {
    return {
      email: '',
      password: '',
      passwordType: "password",
      submitted: false,
      signInDisabled: false,
      error: {
        dataError: ''
      },
    }
  },
  validations: {
    email: {
      required
    },
    password: {
      required
    },
  },
  mixins: [scrollToElement],
  computed: {
    ...mapGetters({
      isLogged: 'authentication/isLogged'
    }),
  },
  watch: {
    '$store.state.alert.message'(newVal) {
      if (newVal === null) {
        return false
      }
      this.signInDisabled = false
      this.error.dataError = newVal
      this.$refs.serverErrorPopup.openPopups()
    },
  },
  methods: {
    ...mapActions({
      logout: 'authentication/clearState',
      setMoney: 'money/setMoney',
      clearMoney: 'money/clearMoney',
      setTabs: 'userTabs/setTabs',
    }),
    async onSubmit() {
      this.signInDisabled = true
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.signInDisabled = false
        return
      } else {
        this.submitted = true;
        const {email, password} = this;
        const {dispatch} = this.$store;
        if (email && password) {
          await dispatch('authentication/login', {email, password})

          setTimeout(async () => {
            if (this.isLogged) {
              await this.setTabs()
              await this.setMoney()
            }
          }, 1000)
        }
      }
    },
    logoutEvent() {
      this.signInDisabled = true
      setTimeout(() => {
        this.signInDisabled = false
      }, 5000)
    },
    togglePassword() {
      this.passwordType = this.passwordType === "password" ? "text" : "password";
    },
  },
  mounted() {
    document.title = 'Login'
    this.clearMoney()
    this.logout()
    this.scrollToId('my-content')

    // if (!this.isLogged) {
    //   this.signInDisabled = true
    //   setTimeout(() => {
    //     this.signInDisabled = false
    //   }, 5000)
    // }
  },
  components: {
    Footer,
    Header,
    ServerErrorPopup,
  },
};
</script>

<style scoped>
/*.socialaccount_providers li:nth-child(2) a {*/
/*  !*background-image: url("../assets/img/google.svg") !important;*!*/
/*  !*background-color: #E44E3B;*!*/
/*  !*background-position: 50% 50%;*!*/
/*  !*background-size: 30px;*!*/
/*  !*background-repeat: no-repeat;*!*/
/*}*/

.error-message {
  color: red;
}

.login-field-wrap {
  margin-bottom: 20px;
}

</style>