var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"type-ieo-popup-server-error white-popup popup-form crop-image-popup confirm-popup"},[_c('div',{staticClass:"form-main-content"},[_c('div',{staticClass:"close-dialog",on:{"click":function($event){_vm.dialog = false}}},[_c('img',{attrs:{"src":require("../../assets/img/close.svg"),"alt":"close-icon"}})]),_c('div',{staticClass:"popup-header"},[_c('h3',{staticClass:"form-title"},[_vm._v("Changing a photo:")])]),_c('div',{staticClass:"popup-content"},[_c('div',{staticClass:"popup-content-container"},[_c('cropper',{ref:"cropper",staticClass:"cropper",staticStyle:{"height":"400px"},attrs:{"src":_vm.image.src || _vm.defaultImage,"auto-zoom":true,"image-class":"cropper__image","stencil-props":{
        aspectRatio: _vm.aspectRatio,
        class: 'cropper-stencil',
        previewClass: 'cropper-stencil__preview',
        draggingClass: 'cropper-stencil--dragging',
        handlersClasses: {
          default: 'cropper-handler',
          eastNorth: 'cropper-handler--east-north',
          westNorth: 'cropper-handler--west-north',
          eastSouth: 'cropper-handler--east-south',
          westSouth: 'cropper-handler--west-south',
        },
        handlersWrappersClasses: {
          default: 'cropper-handler-wrapper',
          eastNorth: 'cropper-handler-wrapper--east-north',
          westNorth: 'cropper-handler-wrapper--west-north',
          eastSouth: 'cropper-handler-wrapper--east-south',
          westSouth: 'cropper-handler-wrapper--west-south',
        },
      }}}),_c('div',{staticClass:"crop-image-btn-wrap"},[_c('button',{staticClass:"btn btn-hover profile-btn btn-transparent",on:{"click":function($event){return _vm.$refs.file.click()}}},[_c('input',{ref:"file",attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){return _vm.uploadImage($event)}}}),_vm._v(" Upload ")]),_c('button',{staticClass:"btn btn-hover profile-btn",on:{"click":_vm.cropImage}},[_vm._v("Crop")])])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }