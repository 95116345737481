export default function (instance) {
  return {
    getAssociatedArtists(params) {
      return instance.get('/api/v2/companies/artists-association', {params})
    },
    createAssociatedArtist(payload) {
      return instance.post('/api/v2/companies/artists-association', payload)
    },
    getAccountData(id) {
      return instance.get(`/api/v2/companies/artists-association/${id}/profiles`)
    },
    updateAccountData(id, payload) {
      return instance.patch(`/api/v2/companies/artists-association/${id}/profiles`, payload)
    },
  }
}