

export default function(instance) {

    return {
        contactUs(body) {
            return instance.post(`/api/v2/support/contact`, body)
        },

    }
}