<template>
  <div class="my-song-list-item">
    <div class="pending-ieo-list-item-header">

      <div class="pm-label pm-label-sm">
        <div class="pending-ieo-label " v-if="song.totalPlanned">
          <span class="pending-ieo-label-title">Minimum Funding Target</span>
          <span class="pending-ieo-label-price"
                v-text="'€ ' + totalPlaned"></span>
        </div>
        <div class="progress-bar" v-if="song.status === 'OPEN'">

          <div class="holder-progress-bar sold">
            <span class="progress-title">{{ currencyName }} bid for</span>
            <span class="progress-count" v-text="soldAmount"></span>
          </div>

          <div class="animated-progress">
            <span :style="{width: song.progressBarAmountRounded + '%'}"></span>
          </div>
          <div class="holder-progress-bar ipr-bid">
            <span class="progress-title">{{ currencyName }} offered</span>
            <span class="progress-count">
              {{availableAmount}}
              <span v-text="'(' + amountPercentForShow + '%' + ')'"></span>
            </span>
          </div>
        </div>
      </div>


      <ul class="profile-my-music-genre-list">
        <li class="profile-my-music-genre-list-item genre"
            v-text="song.songGenre"></li>
      </ul>

      <img v-if="song.songAlbumUrlImage" :src="song.songAlbumUrlImage" alt="Author image"
           class="pending-ieo-list-item-image" @click="$router.push({path: `/song/${song.songId}`})" loading="lazy">
      <img v-else src="@/assets/img/default-song-image.jpg" alt="Author image"
           class="pending-ieo-list-item-image" @click="$router.push({path: `/song/${song.songId}`})" loading="lazy">
      <div class="pending-ieo-list-item__play-wrap" >
        <div :class="togglePlayerFlag ?
          'hitparad_button-play hitparad_button-pause'
          : 'hitparad_button-play hitparad_button-play-active'" @click="toggleMusic">
          <div class="hitparad_button-play-progress">
            <canvas width="70" height="70"></canvas>
            <canvas width="70" height="70"></canvas>
          </div>
        </div>
      </div>
      <div class="audio-player-wrapper">
        <AudioPlayer
            :src="playerSrc"
            :togglePlayerFlag="togglePlayerFlag"
            :width="'100%'"
            v-if="playerSrc"
            :ref="`audioPlayer${identifier}${index}`"
            @audioPause="togglePlayerFlag = true"
            :identifier="identifier"
            :index="index"
        />
      </div>
    </div>
    <div class="pending-ieo-list-item-body">
      <div class="pending-ieo-list-item-body-top">
        <div class="song-author-image-wrap">
          <img
              v-if="authorPhotoUrl"
              :src="authorPhotoUrl"
              alt="song author image"
              class="song-author-image"
              @error="imgOnError"
              loading="lazy"
          >
          <svg v-else width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="51" height="51" rx="12" fill="#F5F5F5"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M25.5006 26.8683C28.6247 26.8683 31.1572 23.035 31.1572 19.8749C31.1572 16.7148 28.6247 14.1531 25.5006 14.1531C22.3766 14.1531 19.8441 16.7148 19.8441 19.8749C19.8441 23.035 22.3766 26.8683 25.5006 26.8683ZM21.6671 28.5146C18.3316 27.869 15.2344 30.4242 15.2344 33.8217V34.0166C15.2344 34.7236 15.7127 35.341 16.3973 35.5177C22.3683 37.0586 28.6329 37.0586 34.604 35.5177C35.2885 35.341 35.7668 34.7236 35.7668 34.0166V33.8217C35.7668 30.4242 32.6697 27.869 29.3341 28.5146L26.8308 28.9991C25.9521 29.1691 25.0491 29.1691 24.1705 28.9991L21.6671 28.5146Z" fill="#D8D8D8"/>
          </svg>

        </div>
        <div class="song-author-info-top">
          <router-link :to="{path: `/song/${song.songId}`}"
             class="pending-ieo-list-item-name-song-link">
                                                    <span class="pending-ieo-list-item-name-song">
                                                        <pre v-text="song.songTitle"></pre>
                                                    </span>
          </router-link>
          <div class="pending-ieo-list-item-info">
            <router-link :to="{path: `/artist/${song.singerUsername}`}"
               class="pending-ieo-list-item-name-artist-link">
                                                        <span class="pending-ieo-list-item-name-artist"
                                                              v-text="singerName"></span>
            </router-link>
          </div>
        </div>
        <div class="my-song-ieo-edit-btn-wrap">
<!--          <div class="pending-ieo-subscribers-wrap" v-if="song.subscribedIeo === true">-->
<!--            <svg width="22" height="14" viewBox="0 0 22 14"-->
<!--                 fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path fill-rule="evenodd" clip-rule="evenodd"-->
<!--                    d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"-->
<!--                    fill="#FF0090"></path>-->
<!--            </svg>-->
<!--            <span class="pending-ieo-subscribers-title"-->
<!--                  v-text="song.subscribers ? song.subscribers : '0'"></span>-->
<!--          </div>-->
<!--          <div class="pending-ieo-subscribers-wrap" v-if="song.subscribedIeo === false">-->
<!--            <svg width="22" height="14" viewBox="0 0 22 14"-->
<!--                 fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path fill-rule="evenodd" clip-rule="evenodd"-->
<!--                    d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"-->
<!--                    fill="#D0D0D0"></path>-->
<!--            </svg>-->
<!--            <span class="pending-ieo-subscribers-title"-->
<!--                  v-text="song.subscribers ? song.subscribers : '0'"></span>-->
<!--          </div>-->
          <div class="ieo-open-btn-wrap">
            <button class="btn ieo-open-btn"
                    @click="$router.push({path: `/song/${song.songId}`})"
            >Open</button>
          </div>
        </div>

      </div>
      <div v-if="song.ieo === true">
        <div>
          <div class="pending-ieo-list-item-body-bottom edit-ieo"
               v-if="song.status === 'NOT_STARTED'">
            <div class="pending-ieo-countdown-wrap"
                 v-if="timeIeoStart">
              <span class="pending-ieo-countdown-title">Time to the Initial Sale start:</span>
              <flip-countdown :deadline="timeIeoStart" :showSeconds="false"></flip-countdown>

            </div>
          </div>
          <div class="pending-ieo-list-item-body-bottom failed-ieo"
               v-if="song.status === 'FAILED'">
            <div class="failed-ieo-price-wrap">
              <div class="collected-ieo-price-title">
                <svg width="24" height="24" viewBox="0 0 24 24"
                     fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M11.5789 0C5.18733 0 0 5.18733 0 11.5789C0 17.9704 5.18733 23.1577 11.5789 23.1577C17.9704 23.1577 23.1577 17.9704 23.1577 11.5789C23.1577 5.18733 17.9704 0 11.5789 0ZM11.5789 12.7367C10.942 12.7367 10.421 12.2157 10.421 11.5789V6.94732C10.421 6.31048 10.942 5.78943 11.5789 5.78943C12.2157 5.78943 12.7367 6.31048 12.7367 6.94732V11.5789C12.7367 12.2157 12.2157 12.7367 11.5789 12.7367ZM12.7367 17.3683H10.421V15.0525H12.7367V17.3683Z"
                        fill="#FFB817"/>
                </svg>
                <span class="winners-music-list-item-body-ieo-info">Initial Sale failed:</span>
              </div>
              <span class="collected-ieo-price">€ 0</span>
            </div>
            <div v-if="isLogged === true" class="pending-ieo-btn-wrap">
              <a @click="followIeo"
                 class="btn btn-hover song-subscribe-all-btn" v-if="song.subscribedIeo === false">Subscribe</a>
              <a @click="unfollowIeo"
                 class="btn btn-hover song-subscribe-all-btn" v-if="song.subscribedIeo === true">Unsubscribe</a>
            </div>
          </div>
          <div class="pending-ieo-list-item-body-bottom collected-ieo"
               v-if="song.status === 'SUCCESS'">
            <div class="collected-ieo-price-wrap">
              <div class="collected-ieo-price-title">
                <svg xmlns="http://www.w3.org/2000/svg" width="23"
                     height="23" viewBox="0 0 50 50">
                  <path class="a"
                        d="M25.084,0a25,25,0,1,0,25,25A25,25,0,0,0,25.084,0ZM36.665,18.084,24.69,36.387s-.009.006-.009.009a2.279,2.279,0,0,1-.175.287,2.246,2.246,0,0,1-.375.272c-.031.022-.063.047-.1.069a2.025,2.025,0,0,1-.613.256c-.072.019-.137.037-.212.05a2.11,2.11,0,0,1-.525.006,2.069,2.069,0,0,1-.366-.056,2.212,2.212,0,0,1-.359-.144,2.5,2.5,0,0,1-.3-.119,1.381,1.381,0,0,1-.166-.166c-.025-.022-.056-.028-.081-.05L14.44,30.356a2.187,2.187,0,1,1,2.969-3.213l5.047,4.669L33,15.691a2.188,2.188,0,0,1,3.663,2.394Z"
                        transform="translate(-0.084)"></path>
                </svg>
                <span class="winners-music-list-item-body-ieo-info">Successful:</span>
              </div>
              <span class="collected-ieo-price"
                    v-text="'€ ' + totalCollected"></span>
            </div>
            <div v-if="isLogged === true" class="pending-ieo-btn-wrap">
              <a @click="followIeo"
                 class="btn btn-hover song-subscribe-all-btn" v-if="song.subscribedIeo === false">Subscribe</a>
              <a @click="unfollowIeo"
                 class="btn btn-hover song-subscribe-all-btn" v-if="song.subscribedIeo === true">Unsubscribe</a>
            </div>

          </div>
          <div class="new-open-ieo bid-present" v-if="song.bidActivity.bidIsPresent === true">
            <div class="pending-ieo-list-item-body-bottom collected-ieo open-ieo" v-if="song.status === 'OPEN'">
              <div class="collected-ieo-price-wrap" v-if="song.bidActivity.bidIsPresent === true">
                <div class="open-ieo-info">
                  <div class="open-ieo-info-bidding">
                    <span class="open-ieo-info-bidding-title">Bidding Activity:</span>
                    <div class="open-ieo-info-bidding-list">
                      <div class="open-ieo-info-bidding-list-item open-ieo-info-bidding-percent">
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M0.558385 4.22676C0.294976 4.47066 0.279158 4.88191 0.523056 5.14532C0.766953 5.40873 1.17821 5.42455 1.44162 5.18065L0.558385 4.22676ZM5 1L5.44162 0.523056C5.19242 0.292315 4.80758 0.292315 4.55838 0.523056L5 1ZM8.55838 5.18065C8.82179 5.42455 9.23305 5.40873 9.47694 5.14532C9.72084 4.88191 9.70502 4.47066 9.44162 4.22676L8.55838 5.18065ZM4.35 10C4.35 10.359 4.64101 10.65 5 10.65C5.35898 10.65 5.65 10.359 5.65 10H4.35ZM1.44162 5.18065L5.44162 1.47694L4.55838 0.523056L0.558385 4.22676L1.44162 5.18065ZM4.55838 1.47694L8.55838 5.18065L9.44162 4.22676L5.44162 0.523056L4.55838 1.47694ZM4.35 1V10H5.65V1H4.35Z" fill="#6FAD84"/>
                        </svg>
                        <span class="open-ieo-info-bidding-percent-val" v-text="song.bidActivity.percent + '%'"></span>
                      </div>
                      <div class="open-ieo-info-bidding-list-item open-ieo-info-bidding__time__maxprice-wrapper">
                        <div class="open-ieo-info-bidding-time">
                          <span class="open-ieo-info-bidding-time-value" v-text="song.bidActivity.timeFirstMaxBid"></span>
                        </div>
                        <div class="open-ieo-info-bidding-maxprice">
                        <span class="open-ieo-info-bidding-maxprice-value" v-text="'€' + maximumPricePerOne"></span>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div class="open-ieo-info-bid">
                    <div class="open-ieo-info-bid-item">
                      <span class="open-ieo-info-bid-item-title">Current min bid per {{ currencyName }}:</span>
                      <span class="open-ieo-info-bid-item-value" v-text="'€' + minimumPricePerOne"></span>
                    </div>
                    <div class="open-ieo-info-bid-item">
                      <span class="open-ieo-info-bid-item-title">Current max bid per {{ currencyName }}:</span>
                      <span class="open-ieo-info-bid-item-value" v-text="'€' + maximumPricePerOne"></span>
                    </div>
                    <div class="open-ieo-info-current-bit">
                      <span class="open-ieo-info-current-bit-title">Current bid Amount:</span>
                      <span class="open-ieo-info-current-bit-value" v-text="'€' + totalCollected"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="collected-ieo-open-wrap" v-if="song.bidActivity.bidIsPresent === false">
                <div class="collected-ieo-price-wrap">
                  <div class="collected-ieo-price-title">
                    <svg xmlns="http://www.w3.org/2000/svg" width="23"
                         height="23" viewBox="0 0 50 50">
                      <path class="a"
                            d="M25.084,0a25,25,0,1,0,25,25A25,25,0,0,0,25.084,0ZM36.665,18.084,24.69,36.387s-.009.006-.009.009a2.279,2.279,0,0,1-.175.287,2.246,2.246,0,0,1-.375.272c-.031.022-.063.047-.1.069a2.025,2.025,0,0,1-.613.256c-.072.019-.137.037-.212.05a2.11,2.11,0,0,1-.525.006,2.069,2.069,0,0,1-.366-.056,2.212,2.212,0,0,1-.359-.144,2.5,2.5,0,0,1-.3-.119,1.381,1.381,0,0,1-.166-.166c-.025-.022-.056-.028-.081-.05L14.44,30.356a2.187,2.187,0,1,1,2.969-3.213l5.047,4.669L33,15.691a2.188,2.188,0,0,1,3.663,2.394Z"
                            transform="translate(-0.084)"></path>
                    </svg>
                    <span class="winners-music-list-item-body-ieo-info">Initial Sale in progress:</span>
                  </div>
                  <span class="collected-ieo-price" v-text="'€ ' + totalCollected"></span>
                </div>
                <div v-if="isLogged === true" class="pending-ieo-btn-wrap">
                  <a @click="followIeo"
                     class="btn btn-hover song-subscribe-all-btn" v-if="song.subscribedIeo === false">Subscribe</a>
                  <a @click="unfollowIeo"
                     class="btn btn-hover song-subscribe-all-btn" v-if="song.subscribedIeo === true">Unsubscribe</a>
                </div>
              </div>
            </div>
          </div>
          <div class="new-open-old" v-if="song.bidActivity.bidIsPresent === false">
            <div class="pending-ieo-list-item-body-bottom collected-ieo open-ieo" v-if="song.status === 'OPEN'">
              <div class="collected-ieo-price-wrap" v-if="song.bidActivity.bidIsPresent === true">
                <div class="open-ieo-info">
                  <div class="open-ieo-info-bidding">
                    <span class="open-ieo-info-bidding-title">Bidding Activity:</span>
                    <div class="open-ieo-info-bidding-list">
                      <div class="open-ieo-info-bidding-list-item open-ieo-info-bidding-percent">
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.558385 4.22676C0.294976 4.47066 0.279158 4.88191 0.523056 5.14532C0.766953 5.40873 1.17821 5.42455 1.44162 5.18065L0.558385 4.22676ZM5 1L5.44162 0.523056C5.19242 0.292315 4.80758 0.292315 4.55838 0.523056L5 1ZM8.55838 5.18065C8.82179 5.42455 9.23305 5.40873 9.47694 5.14532C9.72084 4.88191 9.70502 4.47066 9.44162 4.22676L8.55838 5.18065ZM4.35 10C4.35 10.359 4.64101 10.65 5 10.65C5.35898 10.65 5.65 10.359 5.65 10H4.35ZM1.44162 5.18065L5.44162 1.47694L4.55838 0.523056L0.558385 4.22676L1.44162 5.18065ZM4.55838 1.47694L8.55838 5.18065L9.44162 4.22676L5.44162 0.523056L4.55838 1.47694ZM4.35 1V10H5.65V1H4.35Z" fill="#6FAD84"/>
                        </svg>
                        <span class="open-ieo-info-bidding-percent-val" v-text="song.bidActivity.percent"></span>
                      </div>
                      <div class=" open-ieo-info-bidding-list-item open-ieo-info-bidding__time__maxprice-wrapper">
                      <div class="open-ieo-info-bidding-time">
                        <span class="open-ieo-info-bidding-time-value" v-text="song.bidActivity.timeFirstMaxBid"></span>
                      </div>
                      <div class="open-ieo-info-bidding-maxprice">
                        <span class="open-ieo-info-bidding-maxprice-value" v-text="'€' + maximumPricePerOne"></span>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div class="open-ieo-info-bid">
                    <div class="open-ieo-info-bid-item">
                      <span class="open-ieo-info-bid-item-title">Current min bid per {{ currencyName }}:</span>
                      <span class="open-ieo-info-bid-item-value" v-text="'€' + minimumPricePerOne"></span>
                    </div>
                    <div class="open-ieo-info-bid-item">
                      <span class="open-ieo-info-bid-item-title">Current max bid per {{ currencyName }}:</span>
                      <span class="open-ieo-info-bid-item-value" v-text="'€' + maximumPricePerOne"></span>
                    </div>
                    <div class="open-ieo-info-current-bit">
                      <span class="open-ieo-info-current-bit-title">Current bid Amount:</span>
                      <span class="open-ieo-info-current-bit-value" v-text="'€' + totalCollected"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="collected-ieo-open-wrap" v-if="song.bidActivity.bidIsPresent === false">
                <div class="collected-ieo-price-wrap">
                  <div class="collected-ieo-price-title">
                    <svg xmlns="http://www.w3.org/2000/svg" width="23"
                         height="23" viewBox="0 0 50 50">
                      <path class="a"
                            d="M25.084,0a25,25,0,1,0,25,25A25,25,0,0,0,25.084,0ZM36.665,18.084,24.69,36.387s-.009.006-.009.009a2.279,2.279,0,0,1-.175.287,2.246,2.246,0,0,1-.375.272c-.031.022-.063.047-.1.069a2.025,2.025,0,0,1-.613.256c-.072.019-.137.037-.212.05a2.11,2.11,0,0,1-.525.006,2.069,2.069,0,0,1-.366-.056,2.212,2.212,0,0,1-.359-.144,2.5,2.5,0,0,1-.3-.119,1.381,1.381,0,0,1-.166-.166c-.025-.022-.056-.028-.081-.05L14.44,30.356a2.187,2.187,0,1,1,2.969-3.213l5.047,4.669L33,15.691a2.188,2.188,0,0,1,3.663,2.394Z"
                            transform="translate(-0.084)"></path>
                    </svg>
                    <span class="winners-music-list-item-body-ieo-info">Initial Sale in progress:</span>
                  </div>
                  <span class="collected-ieo-price" v-text="'€ ' + totalCollected"></span>
                </div>
                <div v-if="isLogged === true" class="pending-ieo-btn-wrap">
                  <a @click="followIeo"
                     class="btn btn-hover song-subscribe-all-btn" v-if="song.subscribedIeo === false">Subscribe</a>
                  <a @click="unfollowIeo"
                     class="btn btn-hover song-subscribe-all-btn" v-if="song.subscribedIeo === true">Unsubscribe</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="song.ieo === false">
        <div class="pending-ieo-list-item-body-bottom create-ieo">
          <router-link :to="{path: `/profile/ieo-creating/${song.songId}`}"
             class="create-ieo-btn btn btn-hover">Create Initial Sale</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FlipCountdown from 'vue2-flip-countdown';
import moment from "moment";
import numberFormattingDecimal from "../../mixins/numberCurrencyConverter";
import numberFormattingInteger from "../../mixins/numberConverter";
import nameHandler from "@/mixins/nameHandler";
import AudioPlayer from "@/components/AudioPlayer";

export default {
  name: "PrimaryMarketList",
  props: {
    song: {
      type: Object,
      default: () => {}
    },
    current: {
      type: String
    },
    index: {
      type: Number
    },
    identifier: {
      type: String
    }
  },
  data() {
    return {
      isLogged: this.$store.state.authentication.status.loggedIn,
      currencyName: process.env.VUE_APP_CURRENCY_NAME_SHORT,
      countdownEndsFlag: true,
      togglePlayerFlag: true,
      authorPhotoUrl: null,
      timeIeoStart: '',
      mp3: '',
      player: new Audio(),
      singerName: '',
      playerSrc: null,
    }
  },
  mixins: [numberFormattingDecimal, numberFormattingInteger, nameHandler],
  computed: {
    soldAmount() {
      return this.numberFormattingInteger(this.song.soldAmount)
    },
    totalPlaned() {
      return this.numberFormattingDecimal(this.song.totalPlanned)
    },
    totalCollected() {
      return this.numberFormattingDecimal(this.song.money)
    },
    minimumPricePerOne() {
      return this.numberFormattingDecimal(this.song.minPricePerOne)
    },
    maximumPricePerOne() {
      return this.numberFormattingDecimal(this.song.maxPricePerOne)
    },
    availableAmount() {
      return this.numberFormattingInteger(this.song.availableAmount)
    },
    amountPercentForShow() {
      return this.numberFormattingDecimal(this.song.availableAmount / 1000)
    },
    // availableAmountPercent() {
    //   return this.numberFormattingDecimal(this.song.availableAmount / 1000)
    // },
  },
  methods: {
    imgOnError() {
      this.authorPhotoUrl = false
    },
    followIeo() {
      this.$load(async () => {
        await this.$api.followUnfollow.follow(this.song.ieoId)
        this.$emit('followIeo')
      }, error => {
        this.$emit('serverError', error.data.errors[0].message)
      })

    },
    unfollowIeo() {
      this.$load(async () => {
        await this.$api.followUnfollow.unfollow(this.song.ieoId)
        this.$emit('unfollowIeo')
      }, error => {
        this.$emit('serverError', error.data.errors[0].message)
      })
    },
    // timeElapsedHandler() {
    //   this.countdownEndsFlag = false
    // },
    toggleMusic() {
      if(this.togglePlayerFlag) {
        this.togglePlayerFlag = false
        this.$emit('playingMusic', this.mp3)
        this.$refs[`audioPlayer${this.identifier}${this.index}`].toggleMusic()
      } else {
        this.togglePlayerFlag = true
        this.$refs[`audioPlayer${this.identifier}${this.index}`].toggleMusic()
      }

    }
  },
  watch: {
    'current'(newVal) {
      if (newVal !== this.mp3) {
        this.$refs[`audioPlayer${this.identifier}${this.index}`].refreshSong()
        this.togglePlayerFlag = true
      }
    },
  },
  mounted() {
    this.authorPhotoUrl = this.song.authorPhotoUrl
    this.mp3 = this.song.songUrlFile
    this.playerSrc = this.mp3
    this.singerName = this.getNameDefault(this.song.companyName, this.song.stageName, this.song.singerFirstName, this.song.singerLastName)

    let convertTimeToLocal = moment.utc(this.song.timeIeoStart).local()
    let resultTimeDate = moment(String(convertTimeToLocal)).format('yyyy-MM-DD HH:mm')
    this.timeIeoStart = resultTimeDate
  },
  beforeDestroy() {
    this.player.currentTime = 0
    this.togglePlayerFlag = true
    this.player.pause()
  },
  components: {
    FlipCountdown,
    AudioPlayer
  }
}
</script>

<style scoped>
.profile-my-music-genre-list {
  position: absolute;
  right: 16px;
  top: 16px;
}
.profile-my-music-genre-list-item.genre {
  background: rgba(67,67,67,.3);
  border: .5px solid #fff;
  border-radius: 35px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  padding: 4px 11px;
}
.holder-progress-bar.ipr-bid .progress-title {
  color: #FFFFFF;
}

.holder-progress-bar:last-child {
  margin-top: 5px;
}

.holder-progress-bar.sold .progress-count {
  color: #FF0090;
}

.pending-ieo-list-item-image {
  cursor: pointer;
}

.audio-player-wrapper {
  height: 45px;
  color: #FFFFFF;
}
</style>
