<template>
  <div id="artist-list-template" class="artist-list-wrap">

    <div class="artist-list">
      <Artist v-for="(artist, index) in artists"
              :key="index"
              :user-card="artist"
      />
    </div>

  </div>
</template>

<script>
import Artist from "./Artist";

export default {
  name: "ArtistListTemplate",
  props: {
    artists: {
      type: Array,
      default: () => []
    },
  },
  components: {
    Artist,
  }
}
</script>

<style scoped>

.artist-list-block {
  width: 100%;
}

</style>