<template>
  <div class="withdrawal-card-wrapper"
       :class="{'card-disabled': cardDisabled,
                'select-card': cardSelected
               }"
       @click="selectItem"
  >

    <div class="withdrawal-card-radio">
      <div class="card-disabled-radio-icon">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.05029 7.05029L16.9498 16.9498" stroke="#747474" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7.05029 16.9497L16.9498 7.05021" stroke="#747474" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div class="withdrawal-card-radio-active" v-if="cardSelected"></div>
    </div>

    <div class="withdrawal-card-info">
      <div class="card-info-name">{{ item.bankName }}</div>
      <div class="card-info-number">{{ `**** **** **** ${item.accountNumber}` }}</div>
    </div>

    <p class="card-disabled-message">Incorrect data</p>

    <div class="card-settings-btn-wrap">
<!--      <button class="card-settings-btn card-edit-btn">-->
<!--        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path d="M8.5 14.1665H14.875" stroke="#6494BF" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--          <path d="M11.6875 2.47916C11.9693 2.19737 12.3515 2.03906 12.75 2.03906C12.9473 2.03906 13.1427 2.07793 13.325 2.15344C13.5073 2.22895 13.673 2.33963 13.8125 2.47916C13.952 2.61869 14.0627 2.78434 14.1382 2.96664C14.2137 3.14895 14.2526 3.34434 14.2526 3.54166C14.2526 3.73899 14.2137 3.93438 14.1382 4.11669C14.0627 4.29899 13.952 4.46464 13.8125 4.60416L4.95833 13.4583L2.125 14.1667L2.83333 11.3333L11.6875 2.47916Z" stroke="#6494BF" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--        </svg>-->
<!--      </button>-->
      <button class="card-settings-btn card-delete-btn" @click="deleteItem">
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.125 4.25H3.54167H14.875" stroke="#BF6464" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M5.6665 4.24984V2.83317C5.6665 2.45745 5.81576 2.09711 6.08144 1.83144C6.34711 1.56576 6.70745 1.4165 7.08317 1.4165H9.9165C10.2922 1.4165 10.6526 1.56576 10.9182 1.83144C11.1839 2.09711 11.3332 2.45745 11.3332 2.83317V4.24984M13.4582 4.24984V14.1665C13.4582 14.5422 13.3089 14.9026 13.0432 15.1682C12.7776 15.4339 12.4172 15.5832 12.0415 15.5832H4.95817C4.58245 15.5832 4.22211 15.4339 3.95644 15.1682C3.69076 14.9026 3.5415 14.5422 3.5415 14.1665V4.24984H13.4582Z" stroke="#BF6464" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7.0835 7.7915V12.0415" stroke="#BF6464" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M9.9165 7.7915V12.0415" stroke="#BF6464" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>

  </div>
</template>

<script>
export default {
  name: "WalletCashWithdrawalCard",
  props: {
    selectedCard: Number,
    item: Object
  },
  methods: {
    selectItem() {
      return this.cardDisabled ? null : this.$emit('selectedCardEvent', this.item.id)
    },
    deleteItem() {
      if (this.item.id) {
        this.$emit('deleteCardEvent', this.item.id)
      }
    },
  },
  computed: {
    cardDisabled() {
      return !this.item.active
    },
    cardSelected() {
      return this.selectedCard === this.item.id
    },
  },
}
</script>

<style scoped>
.withdrawal-card-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(50% - 10px);
  padding: 23px 30px;
  position: relative;
  /*padding: 14px 18px 14px 30px;*/
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.04);
  background: #F9F9F9;
  border: 2px solid #EAEAEA;
  box-sizing: border-box;
  border-radius: 18px;
  margin-bottom: 20px;
  transition: .5s ease;
  cursor: pointer;
}

.withdrawal-card-wrapper:hover {
  border: 2px solid #FF0090;
}

.withdrawal-card-info {
  width: calc(100% - 60px);
}

.card-info-name {
  overflow-y: hidden;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #131415;
  margin-bottom: 10px;
}

.card-info-number {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #747474;
}

.withdrawal-card-radio {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 29px;
  height: 29px;
  background: #FFFFFF;
  border: 0.5px solid #C6C6C6;
  border-radius: 50%;
}

.withdrawal-card-radio-active {
  width: 17px;
  height: 17px;
  background: #FF0090;
  border-radius: 50%;
}

.card-disabled-radio-icon {
  display: none;
}

.card-disabled-radio-icon svg {
  width: 24px;
  height: 24px;
}

.card-settings-btn-wrap {
  position: absolute;
  top: 13px;
  right: 17px;
  display: flex;
  align-items: flex-start;
}

.card-settings-btn {
  margin-right: 16px;
  transition: .5s ease;
}

.card-settings-btn:hover {
  transform: scale(1.3);
}

.card-settings-btn:last-child {
  margin-right: 0;
}

/*.card-settings-btn.card-edit-btn {*/

/*}*/

/*.card-settings-btn.card-delete-btn {*/

/*}*/

.card-disabled-message {
  display: none;
  align-self: flex-end;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #717273;
  margin-bottom: 0;
}

.card-disabled .withdrawal-card-info {
  width: calc(100% - 195px);
}

.card-disabled .card-info-name,
.card-disabled .card-info-number {
  color: #717273;
}

.card-disabled .card-disabled-message {
  display: block;
}

.withdrawal-card-wrapper.card-disabled:hover {
  border: 2px solid #EAEAEA;
  cursor: default;
}

.card-disabled .card-disabled-radio-icon {
  display: block;
}

.withdrawal-card-wrapper.select-card {
  border: 2px solid #FF0090;
}

@media only screen and (max-width: 1300px) {
  .withdrawal-card-wrapper {
    width: 100%;
    max-width: 600px;
  }
}

@media only screen and (max-width: 375px) {

  .withdrawal-card-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background: #F9F9F9;
    border: 1px solid #EAEAEA;
    box-sizing: border-box;
    border-radius: 18px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .withdrawal-card-info {
    width: calc(100% - 35px);
  }

  .card-info-name {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  .card-info-number {
    font-size: 15px;
    line-height: 18px;
  }

  .withdrawal-card-radio {
    width: 23px;
    height: 23px;
  }

  .withdrawal-card-radio-active {
    width: 15px;
    height: 15px;
  }

}
</style>