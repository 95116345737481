export default function(instance) {
    return {
        getViewed() {
            return instance.get('/api/v2/notifications')
        },
        getNotViewed() {
            return instance.get('/api/v2/notifications/notViewed')
        },
        changeStatus(notificationId) {
            return instance.post(`/api/v2/notifications/${notificationId}/viewed`)
        },
        clearAll() {
            return instance.delete(`/api/v2/notifications`)
        },
        sendData(potentialArtistId, songId) {
            return instance.post(`/api/v2/notifications/potential-artist/${potentialArtistId}/${songId}`)
        },
    }
}

