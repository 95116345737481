<template>

  <div id="my-content">
    <Header/>
    <main id="root">
      <section class="section primary-market">
        <div class="container-small">
          <div class="music-header">
            <h1 class="ieo-title separator">Auctions</h1>
            <a href="javascript:void(0)" class="cd-filter-trigger cd-filter-trigger-top"
               @click="filterToggleFlag = !filterToggleFlag"></a>
          </div>
          <div class="ieo-page-descr">Here you can view songs up for auction and buy their royalty rights</div>
          <form class="ieo-fliter-form" onsubmit="return false">
            <div class="artist-header">
              <div id="cover" class="search-block">
                <div class="search-input">
                  <input type="text"
                         id="id_search"
                         name="searchedText"
                         v-model.trim="dataObj.searchedText"
                         placeholder="Search..."
                         class="search-news">
                </div>
              </div>
              <div class="sort-block">
                <GeneralSelect class="sort-select"
                               :options="sortISParams"
                               :identifier="'sort-is'"
                               :current="sortISParams[1]"
                               :placeholder="'Sort params'"
                               @changed="setSortParams"
                />
              </div>
            </div>
            <div class="ieo-wrapper">
              <PrimaryMarketFilter @filterData="filterDataRequest" v-if="filterDeskOrMobile === 'desktop'"
                                   :logged="isLogged"/>

              <div class="cd-filter" ref="filter" :class="{'filter-is-visible': filterToggleFlag}">
                <div class="aside-title-wrapper">
                  <h1 class="title ieo-title separator">Filter</h1>
                </div>
                <aside id="secondary" class="filter-sidebar">
                  <div class="sidebar-wrap">
                    <div class="music-charts-filtering-wrap">
                      <PrimaryMarketFilter @filterData="filterDataRequest" ref="filterMobile"
                                           v-if="filterDeskOrMobile === 'mobile'" :logged="isLogged"/>

                      <!--                                            <button type="submit" class="btn btn-hover filter-apply-btn">apply</button>-->
                    </div>
                  </div>
                </aside><!-- #secondary -->
                <a href="javascript:void(0)" class="cd-close"></a>
              </div>
              <a href="javascript:void(0)" class="cd-filter-trigger" @click="filterToggleFlag = !filterToggleFlag"></a>
              <div class="ieo-content">
                <div class="ieo-main-wrapper pm-ieo">
                  <div class="filter-default-text" v-if="!songs.length">No results...</div>
                  <PrimaryMarketTemplate :songs="songs" @followIeo="checkName" @unfollowIeo="checkName"
                                         @serverError="serverErrorFunc" v-if="loadFlag"/>
                  <div class="preloader-wrapper" v-else>
                    <v-progress-circular
                        :size="75"
                        :width="8"
                        color="#FF0090"
                        indeterminate
                    ></v-progress-circular>
                  </div>
                </div>

              </div>
            </div>
          </form>
          <b-pagination-nav v-if="songs.length"
                            class="pagination"
                            v-model="page"
                            :total-rows="count"
                            :per-page="pageSize"
                            :link-gen="linkGen"
                            :number-of-pages="count"
                            prev-text="«"
                            next-text="»"
                            first-number
                            last-number
                            use-router
          ></b-pagination-nav>
        </div>
      </section>
      <server-error-popup ref="serverErrorPopup" :errorMessage="error.serverError"/>
    </main>
    <Footer/>


  </div>
</template>

<script>
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import PrimaryMarketTemplate from "../components/pm/PrimaryMarketTemplate";
import {debounce} from "lodash";
import PrimaryMarketFilter from "../components/pm/PrimaryMarketFilter";
import ServerErrorPopup from "../components/popups/ServerErrorPopup";
import GeneralSelect from "../components/inputs/GeneralSelect";
import {mapGetters} from "vuex";
import scrollToElement from "@/mixins/scrollToElement";

export default {
  name: "PrimaryMarket",
  data() {
    return {
      filterToggleFlag: false,
      // openStatus: false,
      // pendingStatus: false,
      // finishedStatus: false,
      // failedStatus: false,
      // investmentsStatus: false,
      loadFlag: false,
      filterDeskOrMobile: '',
      songs: [],
      page: 1,
      count: null,
      pageSize: 4,
      error: {
        serverError: ''
      },
      dataObj: {
        searchedText: '',
      },
      sortISParams: [
        {
          value: 'BID_VOLUME_UP',
          name: 'Bid volume increase',
        },
        {
          value: 'BID_VOLUME_DOWN',
          name: 'Bid volume decrease',
        },
        {
          value: 'RECENT_UP',
          name: 'Recent increase',
        },
        {
          value: 'RECENT_DOWN',
          name: 'Recent decrease',
        },
      ],
    }
  },
  mixins: [scrollToElement],
  computed: {
    ...mapGetters({
      isLogged: 'authentication/isLogged',
    }),
  },
  methods: {
    serverErrorFunc(data) {
      this.error.serverError = data
      this.$refs.serverErrorPopup.openPopups()
    },
    filterToggleFlagFunc(event) {
      if (!event.target.closest('.cd-filter') &&
          $('.cd-filter').hasClass('filter-is-visible') &&
          !event.target.closest('.cd-filter-trigger-top') &&
          !event.target.closest('.cd-filter-trigger') &&
          !event.target.closest('.mx-datepicker-main')) {
        this.filterToggleFlag = false
      }
    },
    lengthWatcher() {
      window.addEventListener("resize", () => {
        if (window.innerWidth > 1201) {
          this.filterToggleFlag = false
          this.filterDeskOrMobile = 'desktop'
        }
        if (window.innerWidth <= 1200) {
          this.filterDeskOrMobile = 'mobile'
        }
      }, false);

    },
    filterDataRequest(data) {
      this.dataObj = Object.assign(this.dataObj, data)
      if (this.dataObj.hasOwnProperty('fundingVolumeTo') && this.dataObj.fundingVolumeTo === 0) {
        this.dataObj.fundingVolumeTo = null
      }
      this.page = 1
      if (this.$route.query.page === '1') {
        this.checkName(this.page)
        this.scrollToId('my-content')
      } else {
        this.$router.push(this.linkGen(1))
      }

      if (this.$refs.filter) {
        this.filterToggleFlag = false
      }
    },
    linkGen(pageNum) {
      return {
        path: '/auction',
        query: {page: pageNum}
      }
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }

      return params;
    },
    checkName(page) {
      const params = this.getRequestParams(
          page || this.page,
          this.pageSize
      );

      if (this.isLogged) {
        this.$load(async () => {
          this.loadFlag = false
          const res = (await this.$api.pm.filter(this.dataObj, params)).data.body
          this.songs = res.elements
          this.count = res.totalPages
          if (page) {
            // this.$router.push({path: `/is`, query: {page: page}})
            this.page = page
          }
          this.loadFlag = true
        }, error => {
          this.error.serverError = error.data.errors[0].message
          this.$refs.serverErrorPopup.openPopups()
        })
      } else {

        this.$load(async () => {
          this.loadFlag = false
          const res = (await this.$api.pm.filterUnAuth(this.dataObj, params)).data.body
          this.songs = res.elements
          this.count = res.totalPages
          this.loadFlag = true
        }, error => {
          this.error.serverError = error.data.errors[0].message
          this.$refs.serverErrorPopup.openPopups()
        })
      }

    },
    setSortParams(data) {
      this.dataObj.sortBy = data
      this.debounceName();
    },
  },
  watch: {
    'dataObj.searchedText'() {
      this.page = 1
      this.debounceName();
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.page = newVal
          this.debounceName()
          if (this.count < newVal) {
            this.page = 1
          }
          this.scrollToId('my-content')
        } else {
          this.page = 1
        }
      }
    }
  },
  mounted() {
    if (this.isLogged) {
      let {username, photo, user_type} = JSON.parse(localStorage.getItem('user'))
      this.$load( async () => {await this.$store.dispatch('user/setUser', {
        userPhoto: photo,
        userName: username,
        userType: user_type
      })
      })
    }

    document.title = 'Auctions'
    this.page = this.$route.query.page || 1
    this.lengthWatcher()
    this.debounceName = debounce(this.checkName, 300);
    document.addEventListener('click', this.filterToggleFlagFunc);

    if (window.innerWidth > 1201) {
      this.filterDeskOrMobile = 'desktop'
    }
    if (window.innerWidth <= 1200) {
      this.filterDeskOrMobile = 'mobile'
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onClick);
  },
  components: {
    PrimaryMarketFilter,
    PrimaryMarketTemplate,
    Footer,
    Header,
    ServerErrorPopup,
    GeneralSelect
  }
}
</script>

<style>

.filter-default-text {
  display: block;
  text-align: center;
  font-size: 30px;
  width: 100%;
  margin-top: 50px;
}

.sort-block {
  max-width: 290px;
  width: 100%;
  position: relative;
}

.sort-select.general_select {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #272727;
}

.sort-select .general_select-selected {
  border: 0.5px solid #272727;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #272727;
}

.sort-select .general_select-list {
  border-bottom: 0.5px solid #272727;
  border-right: 0.5px solid #272727;
  border-left: 0.5px solid #272727;
}

</style>
