<template>
    <tr>
      <td data-label="Name" class="left" v-text="invite.username"></td>
      <td data-label="Date" class="left" v-text="invite.dispatchDate"></td>
      <td data-label="Name" class="left" v-text="invite.confirmationDate"></td>
      <td data-label="Amount" class="left" v-text="invite.rewardAmount"></td>
<!--      <td data-label="Type" class="left" v-text="invite.inviteStatusType"></td>-->
<!--      <td data-label="Type" class="left" v-text="inviteLevelTypeComputed"></td>-->
    </tr>
</template>

<script>
export default {
  name: "InviteItem",
  props: {
    invite: {
      type: Object,
    }
  },
  computed: {
    inviteLevelTypeComputed() {
      return this.invite.inviteLevelType.replaceAll('_', ' ')
    }
  }
}
</script>

<style scoped>

</style>