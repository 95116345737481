<template>
<div class="ieo-list-wrapper">
  <div class="wrapper-investors" v-if="screenWidthFlag === 'desktop'">
    <div class="wrapper-contracts-border">
      <div class="contracts-head-song-wrapper">
        <div class="contracts-head-song-photo">
          <img v-if="item.albumCover" :src="item.albumCover" alt="artist photo">
          <img v-else src="@/assets/img/default-song-image.jpg" alt="artist photo">
        </div>
        <div class="contracts-head-song-title">
          <div class="contracts-song-title-item">{{ item.songTitle }}</div>
          <div class="contracts-song-title-item-into">{{ item.authorName }}</div>
        </div>
        <div class="contracts-head-song-mpa-agreement">
          <div class="song-mpa-agreement__text">Publish Agreement</div>
          <div class="song-mpa-agreement__print" v-if="item.artistContractLink">
            <a :href="item.artistContractLink" target="_blank">
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.6562 4.53125H14.2188V2.84375C14.2188 1.55141 13.1673 0.5 11.875 0.5H6.125C4.83266 0.5 3.78125 1.55141 3.78125 2.84375V4.53125H3.34375C2.05141 4.53125 1 5.58266 1 6.875V10.625C1 11.9173 2.05141 12.9688 3.34375 12.9688H3.78125V15.0938C3.78125 15.8692 4.41209 16.5 5.1875 16.5H12.8125C13.5879 16.5 14.2188 15.8692 14.2188 15.0938V12.9688H14.6562C15.9486 12.9688 17 11.9173 17 10.625V6.875C17 5.58266 15.9486 4.53125 14.6562 4.53125ZM4.71875 2.84375C4.71875 2.06834 5.34959 1.4375 6.125 1.4375H11.875C12.6504 1.4375 13.2812 2.06834 13.2812 2.84375V4.53125H4.71875V2.84375ZM13.2812 15.0938C13.2812 15.3522 13.071 15.5625 12.8125 15.5625H5.1875C4.92903 15.5625 4.71875 15.3522 4.71875 15.0938V9.46875H13.2812V15.0938ZM16.0625 10.625C16.0625 11.4004 15.4317 12.0312 14.6562 12.0312H14.2188V9.46875H14.5C14.7589 9.46875 14.9688 9.25887 14.9688 9C14.9688 8.74113 14.7589 8.53125 14.5 8.53125H3.5C3.24113 8.53125 3.03125 8.74113 3.03125 9C3.03125 9.25887 3.24113 9.46875 3.5 9.46875H3.78125V12.0312H3.34375C2.56834 12.0312 1.9375 11.4004 1.9375 10.625V6.875C1.9375 6.09959 2.56834 5.46875 3.34375 5.46875H14.6562C15.4317 5.46875 16.0625 6.09959 16.0625 6.875V10.625Z" fill="#FF0090" stroke="#FF0090" stroke-width="0.3"/>
              <path d="M10.25 12.4181H7.75C7.49112 12.4181 7.28125 12.628 7.28125 12.8868C7.28125 13.1457 7.49112 13.3556 7.75 13.3556H10.25C10.5089 13.3556 10.7188 13.1457 10.7188 12.8868C10.7188 12.628 10.5089 12.4181 10.25 12.4181Z" fill="#FF0090" stroke="#FF0090" stroke-width="0.3"/>
            </svg>
            </a>
          </div>
          <div class="song-mpa-agreement__download">
            <a :href="item.artistContractLink" target="_blank">
            <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 6.5L10 10.5M10 10.5L6 6.5M10 10.5L10 1.5" stroke="#FF0090" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.3409 12.25C15.9267 12.25 15.5909 11.9142 15.5909 11.5C15.5909 11.0858 15.9267 10.75 16.3409 10.75L16.3409 12.25ZM3.65909 10.75C4.0733 10.75 4.40909 11.0858 4.40909 11.5C4.40909 11.9142 4.0733 12.25 3.65909 12.25L3.65909 10.75ZM3 14.75L17 14.75L17 16.25L3 16.25L3 14.75ZM17 12.25L16.3409 12.25L16.3409 10.75L17 10.75L17 12.25ZM3.65909 12.25L3 12.25L3 10.75L3.65909 10.75L3.65909 12.25ZM1.75 13.5C1.75 14.1904 2.30964 14.75 3 14.75L3 16.25C1.48122 16.25 0.25 15.0188 0.25 13.5L1.75 13.5ZM0.25 13.5C0.25 11.9812 1.48122 10.75 3 10.75L3 12.25C2.30964 12.25 1.75 12.8096 1.75 13.5L0.25 13.5ZM18.25 13.5C18.25 12.8096 17.6904 12.25 17 12.25L17 10.75C18.5188 10.75 19.75 11.9812 19.75 13.5L18.25 13.5ZM19.75 13.5C19.75 15.0188 18.5188 16.25 17 16.25L17 14.75C17.6904 14.75 18.25 14.1904 18.25 13.5L19.75 13.5Z" fill="#FF0090"/>
            </svg>
            </a>
          </div>
        </div>
      </div>
      <table>
        <thead>
        <tr>
          <th class="header-contracts-item header-contracts-item-name left">
            <span class="contracts-item-title">username</span>
          </th>
          <th class="header-contracts-item header-contracts-item-date center">
            <span class="contracts-item-title">date</span>
          </th>
          <th class="header-contracts-item header-contracts-item-amount center">
            <span class="contracts-item-title">amount</span>
          </th>
          <th class="header-contracts-item header-contracts-item-price center">
            <span class="contracts-item-title">Closed by price</span>
          </th>
          <th class="header-contracts-item header-contracts-item-print center">
            <span class="contracts-item-title">print</span>
          </th>
          <th class="header-contracts-item header-contracts-item-download center">
            <span class="contracts-item-title">download</span>
          </th>
        </tr>
        </thead>
        <tbody>

        <ContractsList v-for="(item, index) in itemsList"
                       :key="index"
                       :item="item"
        />

        </tbody>
      </table>

      <div class="contracts-all-btn-wrapper" v-if="item.contracts.length > 0">
        <button class="contracts-all-btn"
                v-if="list === 'hidden'"
                @click="longLengthList"
        >
          <div class="contracts-all-btn-text">All users</div>
          <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.14062 0.567444L4.24333 3.43254L7.34604 0.567444" stroke="#FF0090" stroke-width="1.1"
                  stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
        <button class="contracts-all-btn"
                v-if="list === 'open'"
                @click="shortLengthList(5)"
        >
          <div class="contracts-all-btn-text">All users</div>
          <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg"
               style="transform: rotate(180deg)">
            <path d="M1.14062 0.567444L4.24333 3.43254L7.34604 0.567444" stroke="#FF0090" stroke-width="1.1"
                  stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>

    </div>

  </div>

  <div class="wrapper-mobile" v-if="screenWidthFlag === 'mobile'">

    <div class="card-contract">
      <div class="card-contract-header">
        <div class="contracts-head-song-photo">
          <img v-if="item.albumCover" :src="item.albumCover" alt="artist photo">
          <img v-else src="@/assets/img/default-song-image.jpg" alt="artist photo">
        </div>
        <div class="card-contract-header-name-wrap">
          <div class="card-contract-header-name_song">{{ item.songTitle }}</div>
          <div class="card-contract-header-name_artist">{{ item.authorName }}</div>
        </div>
        <div class="contracts-head-song-mpa-agreement">
          <div class="song-mpa-agreement__text">Publish Agreement</div>
          <div class="song-mpa-agreement__print">
            <a :href="item.artistContractLink" target="_blank">
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.6562 4.53125H14.2188V2.84375C14.2188 1.55141 13.1673 0.5 11.875 0.5H6.125C4.83266 0.5 3.78125 1.55141 3.78125 2.84375V4.53125H3.34375C2.05141 4.53125 1 5.58266 1 6.875V10.625C1 11.9173 2.05141 12.9688 3.34375 12.9688H3.78125V15.0938C3.78125 15.8692 4.41209 16.5 5.1875 16.5H12.8125C13.5879 16.5 14.2188 15.8692 14.2188 15.0938V12.9688H14.6562C15.9486 12.9688 17 11.9173 17 10.625V6.875C17 5.58266 15.9486 4.53125 14.6562 4.53125ZM4.71875 2.84375C4.71875 2.06834 5.34959 1.4375 6.125 1.4375H11.875C12.6504 1.4375 13.2812 2.06834 13.2812 2.84375V4.53125H4.71875V2.84375ZM13.2812 15.0938C13.2812 15.3522 13.071 15.5625 12.8125 15.5625H5.1875C4.92903 15.5625 4.71875 15.3522 4.71875 15.0938V9.46875H13.2812V15.0938ZM16.0625 10.625C16.0625 11.4004 15.4317 12.0312 14.6562 12.0312H14.2188V9.46875H14.5C14.7589 9.46875 14.9688 9.25887 14.9688 9C14.9688 8.74113 14.7589 8.53125 14.5 8.53125H3.5C3.24113 8.53125 3.03125 8.74113 3.03125 9C3.03125 9.25887 3.24113 9.46875 3.5 9.46875H3.78125V12.0312H3.34375C2.56834 12.0312 1.9375 11.4004 1.9375 10.625V6.875C1.9375 6.09959 2.56834 5.46875 3.34375 5.46875H14.6562C15.4317 5.46875 16.0625 6.09959 16.0625 6.875V10.625Z" fill="#FF0090" stroke="#FF0090" stroke-width="0.3"/>
              <path d="M10.25 12.4181H7.75C7.49112 12.4181 7.28125 12.628 7.28125 12.8868C7.28125 13.1457 7.49112 13.3556 7.75 13.3556H10.25C10.5089 13.3556 10.7188 13.1457 10.7188 12.8868C10.7188 12.628 10.5089 12.4181 10.25 12.4181Z" fill="#FF0090" stroke="#FF0090" stroke-width="0.3"/>
            </svg>
            </a>
          </div>
          <div class="song-mpa-agreement__download">
            <a :href="item.artistContractLink" target="_blank">
            <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 6.5L10 10.5M10 10.5L6 6.5M10 10.5L10 1.5" stroke="#FF0090" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.3409 12.25C15.9267 12.25 15.5909 11.9142 15.5909 11.5C15.5909 11.0858 15.9267 10.75 16.3409 10.75L16.3409 12.25ZM3.65909 10.75C4.0733 10.75 4.40909 11.0858 4.40909 11.5C4.40909 11.9142 4.0733 12.25 3.65909 12.25L3.65909 10.75ZM3 14.75L17 14.75L17 16.25L3 16.25L3 14.75ZM17 12.25L16.3409 12.25L16.3409 10.75L17 10.75L17 12.25ZM3.65909 12.25L3 12.25L3 10.75L3.65909 10.75L3.65909 12.25ZM1.75 13.5C1.75 14.1904 2.30964 14.75 3 14.75L3 16.25C1.48122 16.25 0.25 15.0188 0.25 13.5L1.75 13.5ZM0.25 13.5C0.25 11.9812 1.48122 10.75 3 10.75L3 12.25C2.30964 12.25 1.75 12.8096 1.75 13.5L0.25 13.5ZM18.25 13.5C18.25 12.8096 17.6904 12.25 17 12.25L17 10.75C18.5188 10.75 19.75 11.9812 19.75 13.5L18.25 13.5ZM19.75 13.5C19.75 15.0188 18.5188 16.25 17 16.25L17 14.75C17.6904 14.75 18.25 14.1904 18.25 13.5L19.75 13.5Z" fill="#FF0090"/>
            </svg>
            </a>
          </div>
        </div>
      </div>

      <div class="card-contract-main">
        <transition-group name="fade" tag="div">
          <ContractsMobileList v-for="(item, index) in itemsList"
                               :key="index"
                               :item="item"
          />
        </transition-group>
      </div>


      <div class="card-contract-footer">
        <button class="btn-contract"
                v-if="checkContractsOnMobile && list === 'hidden'"
                @click="longLengthList"
        >
          <span class="btn-contract-text">See all investors ({{ item.contracts.length }})</span>
          <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.2864 0.620431C1.07677 0.440682 0.761118 0.464905 0.581368 0.674535C0.401619 0.884166 0.425843 1.19982 0.635473 1.37957L1.2864 0.620431ZM4.45965 4L4.13419 4.37957C4.32145 4.54014 4.59785 4.54014 4.78512 4.37957L4.45965 4ZM8.28383 1.37957C8.49346 1.19982 8.51768 0.884166 8.33793 0.674535C8.15818 0.464905 7.84253 0.440682 7.6329 0.620431L8.28383 1.37957ZM0.635473 1.37957L4.13419 4.37957L4.78512 3.62043L1.2864 0.620431L0.635473 1.37957ZM4.78512 4.37957L8.28383 1.37957L7.6329 0.620431L4.13419 3.62043L4.78512 4.37957Z"
                />
          </svg>
        </button>
        <button class="btn-contract"
                v-if="checkContractsOnMobile && list === 'open'"
                @click="shortLengthList(1)"
        >
          <span class="btn-contract-text">Close</span>
          <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg"
               style="transform: rotate(180deg)"
          >
            <path
                d="M1.2864 0.620431C1.07677 0.440682 0.761118 0.464905 0.581368 0.674535C0.401619 0.884166 0.425843 1.19982 0.635473 1.37957L1.2864 0.620431ZM4.45965 4L4.13419 4.37957C4.32145 4.54014 4.59785 4.54014 4.78512 4.37957L4.45965 4ZM8.28383 1.37957C8.49346 1.19982 8.51768 0.884166 8.33793 0.674535C8.15818 0.464905 7.84253 0.440682 7.6329 0.620431L8.28383 1.37957ZM0.635473 1.37957L4.13419 4.37957L4.78512 3.62043L1.2864 0.620431L0.635473 1.37957ZM4.78512 4.37957L8.28383 1.37957L7.6329 0.620431L4.13419 3.62043L4.78512 4.37957Z"
                />
          </svg>
        </button>
      </div>

    </div>

  </div>

</div>
</template>

<script>

import ContractsList from "./ContractsList";
import ContractsMobileList from "./ContractsMobileList";

export default {
  name: "ContractIeoList",
  props: {
    item: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      list: '',
      screenWidthFlag: 'desktop',
      itemsList: [],
    }
  },
  computed: {
    checkContractsOnMobile() {
      return this.item.contracts.length > 1
    },
  },
  methods: {
    lengthWatcher() {
      window.addEventListener("resize", () => {
        if (window.innerWidth >= 768) {
          this.screenWidthFlag = 'desktop'
          return
        }
        if (window.innerWidth < 768) {
          this.screenWidthFlag = 'mobile'
        }
      }, false);
    },
    checkScreen() {
      if (window.innerWidth >= 768) {
        this.screenWidthFlag = 'desktop'
          this.shortLengthList(5)
      } else if (window.innerWidth < 768) {
        this.screenWidthFlag = 'mobile'
          this.shortLengthList(1)
      }
    },
    shortLengthList(toNum) {
      this.itemsList = this.item.contracts.slice(0, toNum)
      this.list = 'hidden'
    },
    longLengthList() {
      this.itemsList = this.item.contracts.slice(0, 1000)
      this.list = 'open'
    },
  },
  watch: {

  },
  mounted() {
    this.lengthWatcher()
    this.checkScreen()

    // if (this.screenWidthFlag === 'desktop') {
    //   this.shortLengthList(5)
    // } else if (this.screenWidthFlag === 'mobile') {
    //   this.shortLengthList(1)
    // }
  },
  components: {
    ContractsList,
    ContractsMobileList,
  }
}
</script>

<style scoped>

/* animation  */

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* end animation  */

.ieo-list-wrapper {
  width: 100%;
  margin-bottom: 40px;
}
.wrapper-contracts-border {
  padding: 20px 20px 0 20px;
  border: 1px solid #EFEFEF;
  border-radius: 5px;
}

.wrapper-investors table,
.wrapper-investors table thead,
.wrapper-investors table tr {
  width: 100%;
}

th.header-contracts-item {
  border-top: 1px solid #EFEFEF;
  padding-top: 10px;
  border-bottom: 1px solid #EFEFEF;
  padding-bottom: 10px;
}

.header-contracts-item-name {
  width: 300px;
}

.header-contracts-item-download {
  width: 85px;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.contracts-item-title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #747474;
}

.header-contracts-item-date {
  min-width: 100px;
}

.header-contracts-item-print {
  min-width: 50px;
}

.header-contracts-item-price {
  min-width: 75px;
}

.header-contracts-item-amount {
  min-width: 70px;
}

.contracts-head-song-photo {
  margin-right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 13px;
}

.contracts-head-song-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.contracts-head-song-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 13px;
}

.contracts-head-song-title {
  display: flex;
  flex-direction: column;
}

.contracts-song-title-item {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #FF0090;
}

.contracts-song-title-item-into {
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #747474;
}

.contracts-all-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px 0;
  margin-top: 20px;
  border-top: 1px solid #EFEFEF;
}

.contracts-all-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.contracts-all-btn svg {
  margin-left: 9px;
}

.contracts-all-btn-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #FF0090;
}

.contracts-all-btn:hover {
  transform: scale(1.1);
}


/*mobile version*/


.card-contract {
  border: 1px solid #EFEFEF;
}

.card-contract-header {
  display: flex;
  align-items: center;
  /*flex-wrap: wrap;*/
  padding: 12px;
  background: #F9F9F9;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid #F2F2F2;
}

/*.card-contract-header-photo {*/
/*  width: 48px;*/
/*  height: 48px;*/
/*  object-fit: cover;*/
/*  margin-right: 20px;*/
/*  border-radius: 12px;*/
/*}*/

.card-contract-header-photo img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.card-contract-header-name-wrap {
  width: calc(100% - 71px);
}

.card-contract-header-name_song {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 6px;
}

.card-contract-header-name_artist {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #747474;
}

.card-contract-main {

}

.card-contract-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background: #F9F9F9;
  border-top: 1px solid #F2F2F2;
  border-radius: 0 0 5px 5px;
}

.btn-contract {
  min-width: 255px;
  background: #FF0090;
  border-radius: 5px;
  padding: 9px 60px;
}

.btn-contract svg {
  margin-left: 5px;
  fill: white;
  transition: .5s ease;
}

.btn-contract-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
}

.btn-contract:hover .btn-contract-text {
  color: #FF0090;
}

.btn-contract:hover svg {
  fill: #FF0090;
}

.contracts-head-song-mpa-agreement {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  padding: 15px 20px;
  background: rgba(196, 196, 196, 0.1);
  border-radius: 5px;
  max-width: 365px;
  width: 100%;
}

@media only screen and (max-width: 1400px) {
  .header-contracts-item-name {
    width: auto;
  }
}

@media only screen and (max-width: 1200px) {


}

@media only screen and (max-width: 850px) {

  .header-contracts-item-date {
    min-width: 90px;
  }

  .header-contracts-item-print {
    min-width: 40px;
  }

  .header-contracts-item-price {
    min-width: 65px;
  }

  .header-contracts-item-amount {
    min-width: 60px;
  }

  .contracts-item-title {
    font-size: 13px;
    line-height: 15px;
  }
}

@media only screen and (max-width: 688px) {
  .wrapper-mobile {
    display: block;
  }
  .card-contract-header {
    flex-wrap: wrap;
  }
  .contracts-head-song-mpa-agreement {
    margin-left: unset;
  }
}

@media only screen and (max-width: 375px) {
  .btn-contract {
    padding: 9px 30px;
  }
  .contracts-head-song-mpa-agreement {
    padding: 15px 10px;
  }
}
</style>