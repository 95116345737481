<template>
  <button type="button"
          class="pair-item"
          :id="id"
          :class="{active: selectActiveClass}"
          @click="selectPair"
  >

    <span class="col-left pair-currency-name">

      <svg v-if="isLogged"
           class="pair-favorite"
           @click.prevent.stop="toggleFavorite"
           :class="pair.isFavorite ? 'pair-favorite-true' : 'pair-favorite-false'"
           width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.7738 15.3331C12.6474 15.3336 12.5228 15.3038 12.4103 15.2464L8.38074 13.1416L4.35115 15.2464C4.0842 15.3864 3.76061 15.3626 3.51713 15.185C3.27364 15.0074 3.15273 14.707 3.20548 14.4108L3.9956 9.9726L0.740323 6.81939C0.533357 6.61332 0.45737 6.31007 0.542793 6.03108C0.63622 5.74526 0.884458 5.53734 1.18279 5.49503L5.68645 4.84074L7.66963 0.796741C7.80165 0.524784 8.07787 0.352051 8.38074 0.352051C8.68361 0.352051 8.95982 0.524784 9.09184 0.796741L11.0987 4.83286L15.6024 5.48715C15.9007 5.52946 16.149 5.73738 16.2424 6.0232C16.3278 6.30219 16.2518 6.60544 16.0449 6.8115L12.7896 9.96472L13.5797 14.4029C13.6373 14.7046 13.5139 15.0123 13.2636 15.1912C13.1206 15.2912 12.9483 15.3411 12.7738 15.3331H12.7738Z"/>
      </svg>

              <span class="pair-name"
                    :data-songid-pair="pair.songUuid"
                    v-text="`${pair.currencyName}/${moneyCurrency.symbol}`"
                    @click="selectPair"
              ></span>
      <!--      <span class="pair-name" :data-songid-pair="pair.songUuid" v-text="pair.currencyName"></span>-->

    </span>

    <span class="col-center pair-market_price" v-text="pair.marketPrice.toFixed(2)"></span>
    <span class="col-right pair-price_changes" v-text="reformatChangedNum" :style="{'color': colorNum}"></span>

  </button>
</template>

<script>
export default {
  name: "TradingMarketPairs",
  props: {
    pair: {
      type: Object,
      default: () => {
      }
    },
    selectActiveClass: {
      type: Boolean
    },
    moneyCurrency: {
      type: Object,
      default: () => {
      }
    },
    currentCurrency: String,
    isLogged: Boolean,
    id: String,
  },
  data() {
    return {

    }
  },
  computed: {
    reformatChangedNum() {
      let num = this.pair.changePrice24h
      return num ? num > 0 ?  `+${num.toFixed(2)}%` : `${num.toFixed(2)}%` : '0.00%'
    },
    colorNum() {
      let num = this.pair.changePrice24h
      return num === 0 ? '#898989' : num > 0 ? '#4C9F4A' : '#D03737'
    },
  },
  methods: {
    selectPair() {
      if (localStorage.getItem('tradingSongId') === this.pair.songUuid) {
        return
      }
      let lowercaseName = this.pair.currencyName.toLowerCase()
      localStorage.setItem('tradingSongId', this.pair.songUuid)
      localStorage.setItem('tradingSongName', this.pair.currencyName)
      this.$router.push({path: `/trading/${lowercaseName}`})
      this.$emit('selectPair')
    },
    toggleFavorite() {
      if (this.pair.isFavorite) {
        this.$api.trading.setUnFavorite(this.pair.songUuid)
        this.$emit('toggleFavorite')
      } else {
        this.$api.trading.setFavorite(this.pair.songUuid)
        this.$emit('toggleFavorite')
      }
      // this.$api.trading.setFavorite(this.pair.songUuid)
      // this.$emit('toggleFavorite')
    },
    compareOfPair() {
      if (!this.currentCurrency) {
        return
      }
      if (this.currentCurrency === this.pair.currencyName) {
        this.$emit('currentId', this.id)
      }
    },
  },
  watch: {
    // currentCurrency() {
    //   this.compareOfPair()
    // },
  },
  mounted() {
    this.compareOfPair()
  }
}
</script>

<style scoped>

</style>