export default {
  fullChartFlag: false,
  currentWidth: null,
  currentHeight: null,
  candleTimeFrame: '15m',
  requestTimeFrame: 'FIFTEEN_MINUTE',
  orderTablesFlag: 'open',
  sortByType: 'name',
  pairsByNameCounter: 0,
  pairsByPriceCounter: 0,
  pairsByChanges24hCounter: 0,
  coinBalance: null,

  defaultRange: [],
  marketPrices: [],
  marketPricesBody: {
    isOnlyFavorite: false,
    searchText: '',
    isSortNameAsc: true,
  },
  candles: [],
  lastCandle: {},
  notEnoughDataFlag: false,

  songPrice: {
    lastPrice: 0,
    priceChanges: 0,
    // high24h: 0,
    // low24h: 0,
    // volume24h: 0,
    // djookyVolume: 0
  },
  newsList: [],
  orderBookTrue: [],
  orderBookFalse: [],
  songHistory: [],
  songOrders: {
    openOrders: [],
    twentyFourHOrder: []
  },

  toggleForms: 'limit',
  limitAskFlag: null,
  createBuyLimit: {
    amount: null,
    price: null,
  },
  createSellLimit: {
    amount: null,
    price: null,
  },
  totalBuyLimit: null,
  totalSellLimit: null,

  marketAskFlag: null,
  createBuyMarket: {
    amount: null,
  },
  createSellMarket: {
    amount: null,
  },
  totalBuyMarket: null,
  totalSellMarket: null,

  // stopLimitFlag: null,
  // totalBuyStopLimit: '',
  // totalSellStopLimit: '',
  // createBuyStopLimit: {
  //     amount: null,
  //     price: null,
  //     stopPrice: null,
  // },
  // createSellStopLimit: {
  //     amount: null,
  //     price: null,
  //     stopPrice: null,
  // },
}