
export default function(instance) {
    return {
        // get(id) {
        //   return instance.get(`films/${id}`)
        // },
        get(params) {
            return instance.get('/api/v2/tradeHistory/user', { params })
        }
    }
}