export default {
  methods: {
    numberFormattingInteger (num) {
      if (num !== null && num !== '') {
        let numStr = num.toString()

        if (numStr.includes('-')) {
          let numWithoutMinus = numStr.split('-')[1];

          const remainder = numWithoutMinus.length % 3;
          let transformString =  (numWithoutMinus.substring(0, remainder) + numWithoutMinus.substring(remainder).replace(/(\d{3})/g, '.$1')).trim();
          return transformString[0] === '.' ? '-' + transformString.slice(1, transformString.length) : '-' + transformString;
        } else if (numStr.includes('+')) {
          let numWithoutPlus = numStr.split('+')[1];

          const remainder = numWithoutPlus.length % 3;
          let transformString =  (numWithoutPlus.substring(0, remainder) + numWithoutPlus.substring(remainder).replace(/(\d{3})/g, '.$1')).trim();
          return transformString[0] === '.' ? '+' + transformString.slice(1, transformString.length) : '+' + transformString;
        } else {
          const remainder = numStr.length % 3;
          let transformString = (numStr.substring(0, remainder) + numStr.substring(remainder).replace(/(\d{3})/g, '.$1')).trim();
          return transformString[0] === '.' ? transformString.slice(1, transformString.length) : transformString;
        }
      }
      return ''
    }
  },
}