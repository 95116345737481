import instance from "../api/instance";



export const staticLinks = {
    namespaced: true,
    state: {
        links: {},
    },
    getters: {
        getLinks(state) {
            return state.links
        },
    },
    mutations: {
        SET_LINK(state, payload) {
            state.links[payload.linkName] = payload.data
        },
    },
    actions: {
        async setLinks({commit}) {

            let copyrightFooterResponse = (await instance.get(`/api/v2/setting/copyrightFooter`)).data.body.value
            commit('SET_LINK', {data: copyrightFooterResponse, linkName: 'copyrightFooter'})
            let supportEmailResponse = (await instance.get(`/api/v2/setting/supportEmail`)).data.body.value
            commit('SET_LINK', {data: supportEmailResponse, linkName: 'supportEmail'})
            let facebookLinkResponse = (await instance.get(`/api/v2/setting/facebookLink`)).data.body.value
            commit('SET_LINK', {data: facebookLinkResponse, linkName: 'facebookLink'})
            let instagramLinkResponse = (await instance.get(`/api/v2/setting/instagramLink`)).data.body.value
            commit('SET_LINK', {data: instagramLinkResponse, linkName: 'instagramLink'})
            let tikTokLinkResponse = (await instance.get(`/api/v2/setting/tikTokLink`)).data.body.value
            commit('SET_LINK', {data: tikTokLinkResponse, linkName: 'tikTokLink'})
            let appStoreLinkResponse = (await instance.get(`/api/v2/setting/appStoreLink`)).data.body.value
            commit('SET_LINK', {data: appStoreLinkResponse, linkName: 'appStoreLink'})
            let twitterLinkResponse = (await instance.get(`/api/v2/setting/twitterLink`)).data.body.value
            commit('SET_LINK', {data: twitterLinkResponse, linkName: 'twitterLink'})
            let googlePlayLinkResponse = (await instance.get(`/api/v2/setting/googlePlayLink`)).data.body.value
            commit('SET_LINK', {data: googlePlayLinkResponse, linkName: 'googlePlayLink'})

        },
        deleteUser({commit}) {
            commit('DELETE_USER')
        }
    }
}