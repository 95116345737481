<template>
  <div :id="`opened-ieo-preview-item-${index + 1}`" class="my-song-list-item">
    <div class="pending-ieo-list-item-header">

      <div class="pm-label pm-label-sm">
        <div class="pending-ieo-label " v-if="song.totalPlanned">
          <span class="pending-ieo-label-title">Minimum Funding Target</span>
          <span class="pending-ieo-label-price"
                v-text="'€ ' + totalPlanned"></span>
        </div>
        <div class="progress-bar">

          <div class="holder-progress-bar sold">
            <span class="progress-title">{{ currencyName }} bid for</span>
            <span class="progress-count">{{ soldAmount }}</span>
          </div>

          <div class="animated-progress">
            <span :style="{width: song.progressBarAmountRounded + '%'}"></span>
          </div>
          <div class="holder-progress-bar ipr-bid">
            <span class="progress-title">{{ currencyName }} offered</span>
            <span class="progress-count">
              {{availableAmount}}
              <span>({{availableAmountPercent + '%'}})</span>
            </span>
          </div>
        </div>
      </div>

      <div class="pending-ieo-list-item-image">
        <img v-if="song.songAlbumUrlImage" :src="song.songAlbumUrlImage" alt="Author image"
             @click="$router.push({path: `/song/${song.songId}`})" loading="lazy">
        <img v-else src="@/assets/img/default-song-image.jpg" alt="Author image"
             @click="$router.push({path: `/song/${song.songId}`})" loading="lazy">
        <div class="pending-ieo-list-item__play-wrap">
          <div :class="togglePlayerFlag ?
          'hitparad_button-play hitparad_button-pause'
          : 'hitparad_button-play hitparad_button-play-active'" @click="toggleMusic">
            <div class="hitparad_button-play-progress">
              <canvas width="70" height="70"></canvas>
              <canvas width="70" height="70"></canvas>
            </div>
          </div>
        </div>
        <div class="audio-player-wrapper">
          <AudioPlayer
              :src="playerSrc"
              :togglePlayerFlag="togglePlayerFlag"
              :width="'100%'"
              v-if="playerSrc"
              :ref="`audioPlayer${identifier}${index}`"
              @audioPause="togglePlayerFlag = true"
              :identifier="identifier"
              :index="index"
          />
        </div>
      </div>

<!--      <img v-if="song.songAlbumUrlImage" :src="song.songAlbumUrlImage" alt="Author image"-->
<!--           class="pending-ieo-list-item-image" @click="$router.push({path: `/song/${song.songId}`})">-->
<!--      <img v-else src="@/assets/img/default-song-image.jpg" alt="Author image"-->
<!--           class="pending-ieo-list-item-image" @click="$router.push({path: `/song/${song.songId}`})">-->
<!--      <div class="pending-ieo-list-item__play-wrap" >-->
<!--        <div :class="togglePlayerFlag ?-->
<!--          'hitparad_button-play hitparad_button-pause'-->
<!--          : 'hitparad_button-play hitparad_button-play-active'" @click="toggleMusic">-->
<!--          <div class="hitparad_button-play-progress">-->
<!--            <canvas width="70" height="70"></canvas>-->
<!--            <canvas width="70" height="70"></canvas>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="pending-ieo-list-item-body">
      <div class="pending-ieo-list-item-body-top">
<!--        <div class="song-author-image-wrap">-->
<!--          <img-->
<!--              v-if="authorPhotoUrl"-->
<!--              :src="authorPhotoUrl"-->
<!--              alt="song author image"-->
<!--              class="song-author-image"-->
<!--              @error="imgOnError"-->
<!--          >-->
<!--          <svg v-else width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <rect width="51" height="51" rx="12" fill="#F5F5F5"/>-->
<!--            <path fill-rule="evenodd" clip-rule="evenodd" d="M25.5006 26.8683C28.6247 26.8683 31.1572 23.035 31.1572 19.8749C31.1572 16.7148 28.6247 14.1531 25.5006 14.1531C22.3766 14.1531 19.8441 16.7148 19.8441 19.8749C19.8441 23.035 22.3766 26.8683 25.5006 26.8683ZM21.6671 28.5146C18.3316 27.869 15.2344 30.4242 15.2344 33.8217V34.0166C15.2344 34.7236 15.7127 35.341 16.3973 35.5177C22.3683 37.0586 28.6329 37.0586 34.604 35.5177C35.2885 35.341 35.7668 34.7236 35.7668 34.0166V33.8217C35.7668 30.4242 32.6697 27.869 29.3341 28.5146L26.8308 28.9991C25.9521 29.1691 25.0491 29.1691 24.1705 28.9991L21.6671 28.5146Z" fill="#D8D8D8"/>-->
<!--          </svg>-->

<!--        </div>-->
        <div class="song-author-info-top">
          <router-link :to="{path: `/song/${song.songId}`}"
                       class="pending-ieo-list-item-name-song-link">
                                                    <span class="pending-ieo-list-item-name-song">
                                                        <pre v-text="song.songTitle"></pre>
                                                    </span>
          </router-link>
          <div class="pending-ieo-list-item-info">
            <router-link :to="{path: `/artist/${song.singerUsername}`}"
                         class="pending-ieo-list-item-name-artist-link">
                                                        <span class="pending-ieo-list-item-name-artist"
                                                              v-text="song.stageName"></span>
            </router-link>
          </div>
        </div>
        <div class="my-song-ieo-edit-btn-wrap">
          <div class="ieo-open-btn-wrap">
            <button class="btn ieo-open-btn"
                    @click="$router.push({path: `/song/${song.songId}`})"
            >Open</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import AudioPlayer from "@/components/AudioPlayer";
import {mapGetters} from "vuex";
import numberFormattingDecimal from "../../mixins/numberCurrencyConverter";
import numberFormattingInteger from "../../mixins/numberConverter";

export default {
  name: "OpenedIeoPreview",
  props: {
    song: {
      type: Object,
      default: () => {}
    },
    current: {
      type: String
    },
    index: {
      type: Number
    },
    identifier: {
      type: String
    }
  },
  data() {
    return {
      currencyName: process.env.VUE_APP_CURRENCY_NAME_SHORT,
      togglePlayerFlag: true,
      mp3: '',
      playerSrc: null,
    }
  },
  mixins: [numberFormattingDecimal, numberFormattingInteger],
  computed: {
    ...mapGetters({
      isLogged: 'authentication/isLogged',
    }),
    soldAmount() {
      return this.numberFormattingInteger(this.song.soldAmount)
    },
    availableAmount() {
      return this.numberFormattingInteger(this.song.availableAmount)
    },
    availableAmountPercent() {
      return this.numberFormattingDecimal(+this.song.availableAmount / 1000)
    },
    totalPlanned() {
      return this.numberFormattingDecimal(this.song.totalPlanned)
    },
  },
  methods: {
    toggleMusic() {
      if(this.togglePlayerFlag) {
        this.togglePlayerFlag = false
        localStorage.setItem('currentSongPlaying', this.mp3)
        this.$emit('playingMusic', this.mp3)
        this.$refs[`audioPlayer${this.identifier}${this.index}`].toggleMusic()
      } else {
        this.togglePlayerFlag = true
        this.$refs[`audioPlayer${this.identifier}${this.index}`].toggleMusic()
      }
    },
  },
  watch: {
    'current'(newVal) {
      if (newVal !== this.mp3) {
        this.$refs[`audioPlayer${this.identifier}${this.index}`].refreshSong()
        this.togglePlayerFlag = true
      }
    },
  },
  mounted() {
    this.mp3 = this.song.songUrlFile
    this.playerSrc = this.mp3
  },
  beforeDestroy() {
    this.togglePlayerFlag = true
  },
  components: {AudioPlayer},
}
</script>

<style scoped>

.my-song-list-item {
  width: 100%;
  max-width: 322px;
  border: none;
  border-radius: 10px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0;
}

.pending-ieo-list-item-header {
  height: auto;
}

.pm-label .pending-ieo-label {
  border-radius: 10px 0 0 0;
}

.pending-ieo-list-item-image {
  width: 100%;
  height: 120px;
  border-radius: 0 0 0 0;
  object-fit: none;
  position: relative;
}

.pending-ieo-list-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pm-label {
  display: flex;
  position: static;
  width: 100%;
  max-width: 100%;
  max-height: 67px;
}

.pending-ieo-label {
  width: 50%;
}

.progress-bar {
  width: 50%;
  border-radius: 0 10px 0 0;
  /*padding: 10px 15px;*/
  padding: 10px;
  background: #404049;
}

.progress-title {
  color: #FFFFFF;
}

.pending-ieo-label-price {
  font-size: 18px;
  line-height: 22px;
}

.animated-progress {
  width: 100%;
  /*max-width: 132px;*/
  max-width: 141px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.pending-ieo-list-item-body-top {
  border-bottom: none;
}

.pending-ieo-list-item-body {
  width: 100%;
  background: #FFFFFF;
  border-radius: 0 0 10px 10px;
}

.pending-ieo-list-item-body-top {
  padding: 10px 14px;
}

.pending-ieo-list-item-name-song pre {
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 0;
}

.pending-ieo-list-item-name-artist-link {
  font-size: 14px;
  line-height: 16px;
}

.song-author-info-top {
  margin-left: 0;
}

.pending-ieo-list-item__play-wrap {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.my-song-list-item .pm-label-sm {
  min-width: 100%;
}

.audio-player-wrapper {
  height: 25px;
  color: #FFFFFF;
}

@media only screen and (max-width: 992px) {
  .pm-label {
    max-height: 55px;
  }

  .pending-ieo-label-price {
    font-size: 15px;
    line-height: 18px;
  }

  .holder-progress-bar.ipr-bid {
    /*display: none;*/
    display: flex;
  }

  .progress-bar {
    padding: 5px;
    align-items: center;
  }

  .animated-progress {
    margin-top: 0;
    margin-bottom: 0;
  }

  .animated-progress {
    height: 2px;
  }

  .progress-bar {
    flex-wrap: unset;
  }

  .progress-title {
    font-size: 10px;
    line-height: 12px;
  }

  .progress-count {
    font-size: 10px;
    line-height: 11px;
  }

  #opened .progress-count span {
    font-size: 10px;
  }

}

@media only screen and (max-width: 375px) {
  .my-song-list-item {
    max-width: 280px;
  }

  .pending-ieo-label-title {
    font-size: 9px;
    line-height: 11px;
  }

  .my-song-list-item .pm-label .pending-ieo-label {
    padding: 2px 10px 7px 10px;
  }

  .progress-bar {
    padding: 10px 10px;
  }
}


</style>