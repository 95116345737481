<template>
  <div id="my-content">
    <Header/>
    <main id="root">

      <section class="section" v-if="loadFlag">
        <div class="container-small">
          <h1 class="page-title separator">Team</h1>
          <div v-html="title"></div>
          <TeamTemplate :teamMembers="teamMembers" />
        </div>
      </section>
      <div class="preloader-wrapper" v-else>
        <v-progress-circular
            :size="75"
            :width="8"
            color="#FF0090"
            indeterminate
        ></v-progress-circular>
      </div>
    </main>
    <Footer/>
  </div>
</template>

<script>

import TeamTemplate from "../components/team/TeamTemplate";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

export default {
  name: "Team",
  components: {Footer, Header, TeamTemplate},
  data() {
    return {
      teamMembers: [],
      title: null,
      loadFlag: false,
    }
  },
  created() {
    document.title = 'Team'
    this.$load(async () => {
      this.loadFlag = false
      let response = (await this.$api.team.getAll())
      this.teamMembers = response.data.body.elements

      let titleResponse = (await this.$api.content.getHTML('team'))
      this.title = titleResponse.data.body.value
      this.loadFlag = true
    })
  },
}
</script>

<style scoped>

</style>