<template>
  <tr>
    <td data-label="Username"><v-tooltip bottom max-width="300">
      <template v-slot:activator="{on}">
        <button
            v-on="on"
        >
          <span class="song-investor-name" v-text="investorCutName"></span>
        </button>
      </template>
      <span v-text="investorFullName"></span>
    </v-tooltip>
    </td>
    <td data-label="Price per RR (€)">{{ pricePerIPR }}</td>
    <td data-label="Amount of RR">{{ betAmount }}</td>
    <td data-label="Closed buy price">{{ closedByPrice }}</td>
    <td data-label="Purchased RR">{{ buyIPR }}</td>
    <td data-label="Total sum (€)">{{ total }}</td>
  </tr>
</template>

<script>
import numberFormattingDecimal from "../../mixins/numberCurrencyConverter";
import numberFormattingInteger from "../../mixins/numberConverter";

export default {
  name: "InitialSaleHistoryUsersList",
  props: {
    investor: Object
  },
  data() {
    return {
      investorFullName: '',
      investorCutName: '',
    }
  },
  mixins: [numberFormattingDecimal, numberFormattingInteger],
  methods : {
    checkFirstName() {
      let firstName = this.investor.firstName
      let lastName = this.investor.lastName

      if (firstName.length > 8) {
        if (lastName) {
          this.investorCutName = firstName.slice(0, 8) + '...' + ' ' + lastName.slice(0, 1) + '.'
        } else {
          this.investorCutName = firstName.slice(0, 8)
        }
      } else if (lastName) {
        this.investorCutName = firstName + ' ' + lastName.slice(0, 1) + '.'
      } else {
        this.investorCutName = firstName
      }
    },
    createFullName() {
      this.investorFullName = this.investor.firstName + ' ' + this.investor.lastName
    },
  },
  computed: {
    pricePerIPR() {
      return this.numberFormattingDecimal(this.investor.pricePerIPR)
    },
    betAmount() {
      return this.numberFormattingInteger(this.investor.betAmount)
    },
    closedByPrice() {
      return this.numberFormattingDecimal(this.investor.closedByPrice)
    },
    buyIPR() {
      return this.numberFormattingInteger(this.investor.buyIPR)
    },
    total() {
      return this.numberFormattingDecimal(this.investor.total)
    },
  },
  mounted() {
    this.checkFirstName()
    this.createFullName()
  },
}
</script>

<style scoped>
.table tbody tr td {
  padding: 0;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  text-align: center;
}

.table tbody tr td:first-child {
  padding-left: 20px;
  text-align: start;
}

.table tbody tr td:last-child {
  padding-right: 20px;
  text-align: end;
}

@media only screen and (max-width: 768px) {
  .table tbody tr td {
    position: relative;
    text-align: end;
    padding-right: 12px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
  }

  .table tbody tr td:before {
    content: attr('data-lebel');
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #909090;
  }

  .table tbody tr td:last-child:before {
    color: #000000;
  }

  .table tbody tr td:first-child {
    padding-left: 0;
    padding-right: 12px;
    margin-top: 8px;
    text-align: end;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }

  .table tbody tr td:last-child {
    padding-right: 12px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
}

/*tr td {*/
/*  padding: 0;*/
/*  border: none;*/
/*  font-style: normal;*/
/*  font-weight: 600;*/
/*  font-size: 14px;*/
/*  line-height: 17px;*/
/*  color: #000000;*/
/*  text-align: center;*/
/*}*/
/*tr td:first-child {*/
/*  padding-left: 20px;*/
/*  text-align: start;*/
/*}*/
/*tr td:last-child {*/
/*  padding-right: 20px;*/
/*  text-align: end;*/
/*}*/
</style>