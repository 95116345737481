export default function(instance) {
    return {
        getAll(params) {
            return instance.get('/api/v2/profile/songs', {params})
        },
        changeSongImage(songId, params) {
            return instance.post(`/api/v2/update-photo/${songId}`, params)
        },
        checkUploadSong() {
            return instance.get('/api/v2/profile/songs/access-on-upload', {})
        },
    }
}