<template>
  <div id="my-content">
    <Header/>
    <main id="root">
      <div class="section home-page">
        <section class="home-section hero-section">
          <div class="hero-wrap" :style="bgMainFirstSliderImage">
            <div class="container-small hero-wrap-slider-wrap">
              <div class="hero-content">
                <h1 class="hero-title separator white">Welcome to DjookyX <br><span class="hero-title-highlight">Join the Revolution</span></h1>
                <div class="hero-descr">
                  <p>The world’s first music investment platform for everyone.</p>
                </div>
              </div>
              <div class="hero-preview-wrapper"
                   v-if="homeOpenedIeoPreview"
                   id="opened-ieo-preview"
              >
                <carousel
                    :nav="false"
                    :items="sliderItemCount"
                    v-if="openedPreviewFlag"
                    :margin="26"
                    :dots="true"
                    :resize="false"
                >
                  <OpenedIeoPreview
                      v-for="(openedIeoPreview, index) in homeOpenedIeoPreview"
                      :key="index"
                      :index="index"
                      :identifier="'opened-ieo'"
                      :song="openedIeoPreview"
                      :current="current"
                      @playingMusic="compareSongs"
                  />
                </carousel>
              </div>
            </div>
          </div>
          <!--        <div class="hero-slider">-->
          <!--          <carousel :autoplay="false" :nav="false" :items="1">-->
          <!--            <div class="hero-wrap" :style="bgMainSecondSliderImage">-->
          <!--              <div class="container-small hero-wrap-slider-wrap">-->
          <!--                <div class="hero-content">-->
          <!--                  <h1 class="hero-title separator white">Welcome to Djooky Marketplace2</h1>-->
          <!--                  <div class="hero-descr">-->
          <!--                    <p>The first truly global song contest. Musicians and music lovers of 150+ nations-->
          <!--                      coming together to create a fairer world of music.</p>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="hero-wrap" :style="bgMainThirdSliderImage">-->
          <!--              <div class="container-small hero-wrap-slider-wrap">-->
          <!--                <div class="hero-content">-->
          <!--                  <h1 class="hero-title separator white">Welcome to Djooky Marketplace3iiii</h1>-->
          <!--                  <div class="hero-descr">-->
          <!--                    <p>The first truly global song contest. Musicians and music lovers of 150+ nations-->
          <!--                      coming together to create a fairer world of music.</p>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->

          <!--          </carousel>-->
          <!--        </div>-->
          <div class="music-brand">
            <div class="container-small">
              <ul class="music-brand-list">

              </ul>
            </div>
          </div>
        </section>
        <section class="home-section how-works-section animation-element animation_swim-top animation animated">
          <div class="container-small">
            <h2 class="title-black separator">Why should I sign up?</h2>
            <div class="why-sign-up-list">
              <div class="why-sign-up-list-item">
                <span class="why-sign-up-list-item-title">DjookyX  for Artists</span>
                <ul class="why-sign-up-list-item-el-wrap">
                  <li class="why-sign-up-list-item-el">
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M10.5 0C4.70137 0 0 4.70137 0 10.5C0 16.2986 4.70137 21 10.5 21C16.2986 21 21 16.2986 21 10.5C21 4.70137 16.2986 0 10.5 0ZM15.3638 7.5955L10.3343 15.2828C10.333 15.2841 10.3303 15.2854 10.3303 15.2867C10.3041 15.3261 10.2897 15.3707 10.2568 15.4075C10.2109 15.4574 10.1518 15.4836 10.0994 15.5217C10.0862 15.5309 10.0731 15.5414 10.0587 15.5506C9.97729 15.6017 9.89329 15.6359 9.80141 15.6582C9.77122 15.6661 9.74366 15.6739 9.71216 15.6792C9.63906 15.6892 9.56499 15.6901 9.49166 15.6818C9.43987 15.6785 9.38848 15.6706 9.3381 15.6582C9.28612 15.6425 9.23561 15.6223 9.18716 15.5978C9.14516 15.5781 9.10054 15.5729 9.06116 15.5479C9.03229 15.5296 9.01654 15.5007 8.9916 15.4784C8.9811 15.4692 8.96797 15.4666 8.95747 15.4574L6.02929 12.7497C5.65652 12.4054 5.6336 11.8242 5.97798 11.4515C6.32236 11.0789 6.90362 11.0559 7.27628 11.4003L9.39585 13.3613L13.8255 6.59012C14.1032 6.16535 14.6726 6.04606 15.0973 6.32369C15.5221 6.60131 15.6414 7.17072 15.3638 7.5955V7.5955Z"
                            fill="#FF0090"></path>
                    </svg>
                    <span
                        class="why-sign-up-list-item-el-text">Gain access to a global investor community of music fans</span>
                  </li>
                  <li class="why-sign-up-list-item-el">
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M10.5 0C4.70137 0 0 4.70137 0 10.5C0 16.2986 4.70137 21 10.5 21C16.2986 21 21 16.2986 21 10.5C21 4.70137 16.2986 0 10.5 0ZM15.3638 7.5955L10.3343 15.2828C10.333 15.2841 10.3303 15.2854 10.3303 15.2867C10.3041 15.3261 10.2897 15.3707 10.2568 15.4075C10.2109 15.4574 10.1518 15.4836 10.0994 15.5217C10.0862 15.5309 10.0731 15.5414 10.0587 15.5506C9.97729 15.6017 9.89329 15.6359 9.80141 15.6582C9.77122 15.6661 9.74366 15.6739 9.71216 15.6792C9.63906 15.6892 9.56499 15.6901 9.49166 15.6818C9.43987 15.6785 9.38848 15.6706 9.3381 15.6582C9.28612 15.6425 9.23561 15.6223 9.18716 15.5978C9.14516 15.5781 9.10054 15.5729 9.06116 15.5479C9.03229 15.5296 9.01654 15.5007 8.9916 15.4784C8.9811 15.4692 8.96797 15.4666 8.95747 15.4574L6.02929 12.7497C5.65652 12.4054 5.6336 11.8242 5.97798 11.4515C6.32236 11.0789 6.90362 11.0559 7.27628 11.4003L9.39585 13.3613L13.8255 6.59012C14.1032 6.16535 14.6726 6.04606 15.0973 6.32369C15.5221 6.60131 15.6414 7.17072 15.3638 7.5955V7.5955Z"
                            fill="#FF0090"></path>
                    </svg>
                    <span class="why-sign-up-list-item-el-text">Raise money to further develop, enhance and market your music</span>
                  </li>
                  <li class="why-sign-up-list-item-el">
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M10.5 0C4.70137 0 0 4.70137 0 10.5C0 16.2986 4.70137 21 10.5 21C16.2986 21 21 16.2986 21 10.5C21 4.70137 16.2986 0 10.5 0ZM15.3638 7.5955L10.3343 15.2828C10.333 15.2841 10.3303 15.2854 10.3303 15.2867C10.3041 15.3261 10.2897 15.3707 10.2568 15.4075C10.2109 15.4574 10.1518 15.4836 10.0994 15.5217C10.0862 15.5309 10.0731 15.5414 10.0587 15.5506C9.97729 15.6017 9.89329 15.6359 9.80141 15.6582C9.77122 15.6661 9.74366 15.6739 9.71216 15.6792C9.63906 15.6892 9.56499 15.6901 9.49166 15.6818C9.43987 15.6785 9.38848 15.6706 9.3381 15.6582C9.28612 15.6425 9.23561 15.6223 9.18716 15.5978C9.14516 15.5781 9.10054 15.5729 9.06116 15.5479C9.03229 15.5296 9.01654 15.5007 8.9916 15.4784C8.9811 15.4692 8.96797 15.4666 8.95747 15.4574L6.02929 12.7497C5.65652 12.4054 5.6336 11.8242 5.97798 11.4515C6.32236 11.0789 6.90362 11.0559 7.27628 11.4003L9.39585 13.3613L13.8255 6.59012C14.1032 6.16535 14.6726 6.04606 15.0973 6.32369C15.5221 6.60131 15.6414 7.17072 15.3638 7.5955V7.5955Z"
                            fill="#FF0090"></path>
                    </svg>
                    <span class="why-sign-up-list-item-el-text">Retain your artistic freedom, while sharing royalties with your fans and supporters</span>
                  </li>
                  <li class="why-sign-up-list-item-el">
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M10.5 0C4.70137 0 0 4.70137 0 10.5C0 16.2986 4.70137 21 10.5 21C16.2986 21 21 16.2986 21 10.5C21 4.70137 16.2986 0 10.5 0ZM15.3638 7.5955L10.3343 15.2828C10.333 15.2841 10.3303 15.2854 10.3303 15.2867C10.3041 15.3261 10.2897 15.3707 10.2568 15.4075C10.2109 15.4574 10.1518 15.4836 10.0994 15.5217C10.0862 15.5309 10.0731 15.5414 10.0587 15.5506C9.97729 15.6017 9.89329 15.6359 9.80141 15.6582C9.77122 15.6661 9.74366 15.6739 9.71216 15.6792C9.63906 15.6892 9.56499 15.6901 9.49166 15.6818C9.43987 15.6785 9.38848 15.6706 9.3381 15.6582C9.28612 15.6425 9.23561 15.6223 9.18716 15.5978C9.14516 15.5781 9.10054 15.5729 9.06116 15.5479C9.03229 15.5296 9.01654 15.5007 8.9916 15.4784C8.9811 15.4692 8.96797 15.4666 8.95747 15.4574L6.02929 12.7497C5.65652 12.4054 5.6336 11.8242 5.97798 11.4515C6.32236 11.0789 6.90362 11.0559 7.27628 11.4003L9.39585 13.3613L13.8255 6.59012C14.1032 6.16535 14.6726 6.04606 15.0973 6.32369C15.5221 6.60131 15.6414 7.17072 15.3638 7.5955V7.5955Z"
                            fill="#FF0090"></path>
                    </svg>
                    <span class="why-sign-up-list-item-el-text">Strengthen your fan base by sharing your success with them</span>
                  </li>
                </ul>
              </div>
              <div class="why-sign-up-list-item">
                <span class="why-sign-up-list-item-title">DjookyX for Investors</span>
                <ul class="why-sign-up-list-item-el-wrap">
                  <li class="why-sign-up-list-item-el">
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M10.5 0C4.70137 0 0 4.70137 0 10.5C0 16.2986 4.70137 21 10.5 21C16.2986 21 21 16.2986 21 10.5C21 4.70137 16.2986 0 10.5 0ZM15.3638 7.5955L10.3343 15.2828C10.333 15.2841 10.3303 15.2854 10.3303 15.2867C10.3041 15.3261 10.2897 15.3707 10.2568 15.4075C10.2109 15.4574 10.1518 15.4836 10.0994 15.5217C10.0862 15.5309 10.0731 15.5414 10.0587 15.5506C9.97729 15.6017 9.89329 15.6359 9.80141 15.6582C9.77122 15.6661 9.74366 15.6739 9.71216 15.6792C9.63906 15.6892 9.56499 15.6901 9.49166 15.6818C9.43987 15.6785 9.38848 15.6706 9.3381 15.6582C9.28612 15.6425 9.23561 15.6223 9.18716 15.5978C9.14516 15.5781 9.10054 15.5729 9.06116 15.5479C9.03229 15.5296 9.01654 15.5007 8.9916 15.4784C8.9811 15.4692 8.96797 15.4666 8.95747 15.4574L6.02929 12.7497C5.65652 12.4054 5.6336 11.8242 5.97798 11.4515C6.32236 11.0789 6.90362 11.0559 7.27628 11.4003L9.39585 13.3613L13.8255 6.59012C14.1032 6.16535 14.6726 6.04606 15.0973 6.32369C15.5221 6.60131 15.6414 7.17072 15.3638 7.5955V7.5955Z"
                            fill="#FF0090"></path>
                    </svg>
                    <span class="why-sign-up-list-item-el-text">Fund your favourite artist and own part of their music</span>
                  </li>
                  <li class="why-sign-up-list-item-el">
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M10.5 0C4.70137 0 0 4.70137 0 10.5C0 16.2986 4.70137 21 10.5 21C16.2986 21 21 16.2986 21 10.5C21 4.70137 16.2986 0 10.5 0ZM15.3638 7.5955L10.3343 15.2828C10.333 15.2841 10.3303 15.2854 10.3303 15.2867C10.3041 15.3261 10.2897 15.3707 10.2568 15.4075C10.2109 15.4574 10.1518 15.4836 10.0994 15.5217C10.0862 15.5309 10.0731 15.5414 10.0587 15.5506C9.97729 15.6017 9.89329 15.6359 9.80141 15.6582C9.77122 15.6661 9.74366 15.6739 9.71216 15.6792C9.63906 15.6892 9.56499 15.6901 9.49166 15.6818C9.43987 15.6785 9.38848 15.6706 9.3381 15.6582C9.28612 15.6425 9.23561 15.6223 9.18716 15.5978C9.14516 15.5781 9.10054 15.5729 9.06116 15.5479C9.03229 15.5296 9.01654 15.5007 8.9916 15.4784C8.9811 15.4692 8.96797 15.4666 8.95747 15.4574L6.02929 12.7497C5.65652 12.4054 5.6336 11.8242 5.97798 11.4515C6.32236 11.0789 6.90362 11.0559 7.27628 11.4003L9.39585 13.3613L13.8255 6.59012C14.1032 6.16535 14.6726 6.04606 15.0973 6.32369C15.5221 6.60131 15.6414 7.17072 15.3638 7.5955V7.5955Z"
                            fill="#FF0090"></path>
                    </svg>
                    <span class="why-sign-up-list-item-el-text">Benefit from royalty income and potential price appreciation of the rights</span>
                  </li>
                  <li class="why-sign-up-list-item-el">
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M10.5 0C4.70137 0 0 4.70137 0 10.5C0 16.2986 4.70137 21 10.5 21C16.2986 21 21 16.2986 21 10.5C21 4.70137 16.2986 0 10.5 0ZM15.3638 7.5955L10.3343 15.2828C10.333 15.2841 10.3303 15.2854 10.3303 15.2867C10.3041 15.3261 10.2897 15.3707 10.2568 15.4075C10.2109 15.4574 10.1518 15.4836 10.0994 15.5217C10.0862 15.5309 10.0731 15.5414 10.0587 15.5506C9.97729 15.6017 9.89329 15.6359 9.80141 15.6582C9.77122 15.6661 9.74366 15.6739 9.71216 15.6792C9.63906 15.6892 9.56499 15.6901 9.49166 15.6818C9.43987 15.6785 9.38848 15.6706 9.3381 15.6582C9.28612 15.6425 9.23561 15.6223 9.18716 15.5978C9.14516 15.5781 9.10054 15.5729 9.06116 15.5479C9.03229 15.5296 9.01654 15.5007 8.9916 15.4784C8.9811 15.4692 8.96797 15.4666 8.95747 15.4574L6.02929 12.7497C5.65652 12.4054 5.6336 11.8242 5.97798 11.4515C6.32236 11.0789 6.90362 11.0559 7.27628 11.4003L9.39585 13.3613L13.8255 6.59012C14.1032 6.16535 14.6726 6.04606 15.0973 6.32369C15.5221 6.60131 15.6414 7.17072 15.3638 7.5955V7.5955Z"
                            fill="#FF0090"></path>
                    </svg>
                    <span class="why-sign-up-list-item-el-text">Be a part of the artist journey and share in their success</span>
                  </li>
                  <li class="why-sign-up-list-item-el">
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M10.5 0C4.70137 0 0 4.70137 0 10.5C0 16.2986 4.70137 21 10.5 21C16.2986 21 21 16.2986 21 10.5C21 4.70137 16.2986 0 10.5 0ZM15.3638 7.5955L10.3343 15.2828C10.333 15.2841 10.3303 15.2854 10.3303 15.2867C10.3041 15.3261 10.2897 15.3707 10.2568 15.4075C10.2109 15.4574 10.1518 15.4836 10.0994 15.5217C10.0862 15.5309 10.0731 15.5414 10.0587 15.5506C9.97729 15.6017 9.89329 15.6359 9.80141 15.6582C9.77122 15.6661 9.74366 15.6739 9.71216 15.6792C9.63906 15.6892 9.56499 15.6901 9.49166 15.6818C9.43987 15.6785 9.38848 15.6706 9.3381 15.6582C9.28612 15.6425 9.23561 15.6223 9.18716 15.5978C9.14516 15.5781 9.10054 15.5729 9.06116 15.5479C9.03229 15.5296 9.01654 15.5007 8.9916 15.4784C8.9811 15.4692 8.96797 15.4666 8.95747 15.4574L6.02929 12.7497C5.65652 12.4054 5.6336 11.8242 5.97798 11.4515C6.32236 11.0789 6.90362 11.0559 7.27628 11.4003L9.39585 13.3613L13.8255 6.59012C14.1032 6.16535 14.6726 6.04606 15.0973 6.32369C15.5221 6.60131 15.6414 7.17072 15.3638 7.5955V7.5955Z"
                            fill="#FF0090"></path>
                    </svg>
                    <span class="why-sign-up-list-item-el-text">Support a growing global community of artists and songwriters</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="why-sign-up-btn-wrap">
              <router-link :to="{path: '/register'}" class="why-sign-up-btn btn btn-scroll btn btn-hover"
                           id="registration-from-home">Register
              </router-link>
            </div>
          </div>
        </section>
        <!--      <section-->
        <!--          class="section why-sign-up-section section-bg-img animation-element animation_swim-top animation animated"-->
        <!--          style="background: black; background-image:url('https://wp.djooky.fun/wp-content/uploads/2021/05/image-1-scaled.jpg');">-->
        <section class="home-section home-how-works-background section-bg-img animation-element animation_swim-top animation animated">
          <div class="container-small">
            <h2 class="title-white separator">How does it work?</h2>
            <div class="how-works-list">
              <div class="how-works-list-item">
                <span class="how-works-list-item-title">Artists</span>
                <ol class="how-works-list-item-el-wrap">
                  <li class="how-works-list-item-el">
                    <span class="how-works-list-item-el-text">Upload your song(s) to DjookyX</span>
                  </li>
                  <li class="how-works-list-item-el">
                    <span class="how-works-list-item-el-text">Decide how many royalty rights you would like to sell</span>
                  </li>
                  <li class="how-works-list-item-el">
                    <span class="how-works-list-item-el-text">Set the minimum price per royalty right </span>
                  </li>
                  <li class="how-works-list-item-el">
                    <span class="how-works-list-item-el-text">Set a start date for the countdown</span>
                  </li>
                  <li class="how-works-list-item-el">
                    <span class="how-works-list-item-el-text">Agree to the Terms & Conditions and start the initial sale of royalty rights</span>
                  </li>
                </ol>
              </div>
              <div class="how-works-list-item">
                <span class="how-works-list-item-title">Investors</span>
                <ol class="how-works-list-item-el-wrap">
                  <li class="how-works-list-item-el">
                    <span class="how-works-list-item-el-text">Register with a few clicks and verify your identity</span>
                  </li>
                  <li class="how-works-list-item-el">
                    <span class="how-works-list-item-el-text">Buy royalty rights in your favourite songs to support up-and-coming artists</span>
                  </li>
                  <li class="how-works-list-item-el">
                    <span class="how-works-list-item-el-text">Receive your share of the royalty income on a quarterly basis</span>
                  </li>
                  <li class="how-works-list-item-el">
                    <span class="how-works-list-item-el-text">Buy and sell royalty rights at any time (coming soon!)</span>
                  </li>
                  <li class="how-works-list-item-el">
                    <span class="how-works-list-item-el-text">Enjoy easy access to a world of opportunities</span>
                  </li>
                </ol>
              </div>
            </div>
            <div class="how-works-read-faq">
              <router-link :to="{path: '/faq/search'}" target="_blank">Read FAQ</router-link>
            </div>
          </div>
        </section>
        <section class="home-section pending-ieo-section" v-if="pendingFlag" style="display: none" id="pending-ieo_section-id">
          <div class="container-small">
            <h2 class="title pending-ieo-title separator">Pending primary market</h2>

            <carousel
                :margin="15"
                :dots="true"
                :nav="false"
                :items="sliderItemCount"
                v-if="pendingFlag"

            >
              <NotStartedIeo
                  v-for="(notStartedIeo, index) in homeNotStartedIeo"
                  :key="index"
                  :notStartedIeo="notStartedIeo"
                  :isLogged="isLogged"
                  :current="current"
                  :index="index"
                  :identifier="'not-started-ieo-list'"
                  @playingMusic="compareSongs"
                  @followIeo="follow"
                  @unfollowIeo="unfollow"
              />
            </carousel>

          </div>
        </section>
        <section class="home-section section-winners black" v-if="winnerFlag" id="winner-section-id">
          <audio src="" id="hitplayer" :autoplay="false"></audio>
          <div class="container-small">

            <h2 class="title winners-title separator">Successful Auctions</h2>

            <carousel
                v-if="winnerFlag"
                :nav="false"
                :items="sliderItemCount"
                :margin="50"
            >
              <winners-ieo
                  v-for="(winnerIeoItem, index) in homeWinnersIeo"
                  :key="index"
                  :winnerIeoItem="winnerIeoItem"
                  :current="current"
                  :index="index"
                  :identifier="'winner-ieo-list'"
                  @playingMusic="compareSongs"
              />
            </carousel>


            <!--                    <div class="winners-btn-wrap">-->
            <!--                        <a href="/register/" class="btn winners-btn btn-hover">Register</a>-->
            <!--                    </div>-->
          </div>
        </section>
        <section class="home-section ecosystem-section animation-element animation_swim-top animation animated">
          <div class="container-small">
            <h2 class="title title-black separator">DjookyX Ecosystem</h2>
            <div class="ecosystem-list">
              <div class="ecosystem-item">
                <div class="ecosystem-item-content">
                  <div class="ecosystem-item-image-wrap">
                    <img src="../assets/img/djooky-music-awards.svg"
                         class="ecosystem-item-image" alt="Djooky Music Awards">
                  </div>
                  <div class="ecosystem-item-title">Djooky Music Awards</div>
                  <p class="ecosystem-item-descr">The largest global online song contest</p>
                </div>
                <div class="ecosystem-details">
                  <!--                <router-link to="/" class="ecosystem-details__text">Details</router-link>-->
                  <a class="ecosystem-details__text" href="https://djookyawards.com/">Details</a>
                  <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M2.00172 0.442529C1.69384 0.165435 1.21962 0.190394 0.942529 0.498276C0.665435 0.806159 0.690394 1.28038 0.998276 1.55747L2.00172 0.442529ZM6.5 5.5L7.00172 6.05747C7.15976 5.91524 7.25 5.71262 7.25 5.5C7.25 5.28738 7.15976 5.08476 7.00172 4.94253L6.5 5.5ZM0.998276 9.44253C0.690394 9.71962 0.665435 10.1938 0.942529 10.5017C1.21962 10.8096 1.69384 10.8346 2.00172 10.5575L0.998276 9.44253ZM0.998276 1.55747L5.99828 6.05747L7.00172 4.94253L2.00172 0.442529L0.998276 1.55747ZM5.99828 4.94253L0.998276 9.44253L2.00172 10.5575L7.00172 6.05747L5.99828 4.94253Z"
                        fill="#FB3099"/>
                  </svg>
                </div>
              </div>
              <div class="ecosystem-item">
                <div class="ecosystem-item-content">
                  <div class="ecosystem-item-image-wrap">
                    <img src="../assets/img/ecosystem_djookyx.svg"
                         class="ecosystem-item-image" alt="DjookyX">
                  </div>
                  <div class="ecosystem-item-title">DjookyX</div>
                  <p class="ecosystem-item-descr">The first global music marketplace</p>
                </div>
                <div class="ecosystem-details">
                  <!--                <router-link to="/" class="ecosystem-details__text">Details</router-link>-->
                  <a class="ecosystem-details__text" href="https://djookyx.com/">Details</a>
                  <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M2.00172 0.442529C1.69384 0.165435 1.21962 0.190394 0.942529 0.498276C0.665435 0.806159 0.690394 1.28038 0.998276 1.55747L2.00172 0.442529ZM6.5 5.5L7.00172 6.05747C7.15976 5.91524 7.25 5.71262 7.25 5.5C7.25 5.28738 7.15976 5.08476 7.00172 4.94253L6.5 5.5ZM0.998276 9.44253C0.690394 9.71962 0.665435 10.1938 0.942529 10.5017C1.21962 10.8096 1.69384 10.8346 2.00172 10.5575L0.998276 9.44253ZM0.998276 1.55747L5.99828 6.05747L7.00172 4.94253L2.00172 0.442529L0.998276 1.55747ZM5.99828 4.94253L0.998276 9.44253L2.00172 10.5575L7.00172 6.05747L5.99828 4.94253Z"
                        fill="#FB3099"/>
                  </svg>

                </div>
              </div>
              <div class="ecosystem-item">
                <div class="ecosystem-item-content">
                  <div class="ecosystem-item-image-wrap">
                    <img src="../assets/img/djooky-records.svg"
                         class="ecosystem-item-image" alt="Djooky Recordst">
                  </div>
                  <div class="ecosystem-item-title">Djooky Records</div>
                  <p class="ecosystem-item-descr">The next generation label services</p>
                </div>
                <div class="ecosystem-details">
                  <span class="ecosystem-details__text">Coming soon!</span>
                </div>
                <!--              <div class="ecosystem-details">-->
                <!--                <router-link to="/" class="ecosystem-details__text">Details</router-link>-->
                <!--                <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                <!--                  <path-->
                <!--                      d="M2.00172 0.442529C1.69384 0.165435 1.21962 0.190394 0.942529 0.498276C0.665435 0.806159 0.690394 1.28038 0.998276 1.55747L2.00172 0.442529ZM6.5 5.5L7.00172 6.05747C7.15976 5.91524 7.25 5.71262 7.25 5.5C7.25 5.28738 7.15976 5.08476 7.00172 4.94253L6.5 5.5ZM0.998276 9.44253C0.690394 9.71962 0.665435 10.1938 0.942529 10.5017C1.21962 10.8096 1.69384 10.8346 2.00172 10.5575L0.998276 9.44253ZM0.998276 1.55747L5.99828 6.05747L7.00172 4.94253L2.00172 0.442529L0.998276 1.55747ZM5.99828 4.94253L0.998276 9.44253L2.00172 10.5575L7.00172 6.05747L5.99828 4.94253Z"-->
                <!--                      fill="#FB3099"/>-->
                <!--                </svg>-->

                <!--              </div>-->
              </div>
              <!--            <div class="ecosystem-item">-->
              <!--              <div class="ecosystem-item-content">-->
              <!--                <div class="ecosystem-item-image-wrap">-->
              <!--                  <img src="../assets/img/djooky-summit.svg"-->
              <!--                       class="ecosystem-item-image" alt="Girl in a jacket">-->
              <!--                </div>-->
              <!--                <div class="ecosystem-item-title">Djooky Summit</div>-->
              <!--                <p class="ecosystem-item-descr">The first global online song contest</p>-->
              <!--              </div>-->
              <!--&lt;!&ndash;              <div class="ecosystem-details">&ndash;&gt;-->
              <!--&lt;!&ndash;                <router-link to="/" class="ecosystem-details__text">Details</router-link>&ndash;&gt;-->
              <!--&lt;!&ndash;                <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">&ndash;&gt;-->
              <!--&lt;!&ndash;                  <path&ndash;&gt;-->
              <!--&lt;!&ndash;                      d="M2.00172 0.442529C1.69384 0.165435 1.21962 0.190394 0.942529 0.498276C0.665435 0.806159 0.690394 1.28038 0.998276 1.55747L2.00172 0.442529ZM6.5 5.5L7.00172 6.05747C7.15976 5.91524 7.25 5.71262 7.25 5.5C7.25 5.28738 7.15976 5.08476 7.00172 4.94253L6.5 5.5ZM0.998276 9.44253C0.690394 9.71962 0.665435 10.1938 0.942529 10.5017C1.21962 10.8096 1.69384 10.8346 2.00172 10.5575L0.998276 9.44253ZM0.998276 1.55747L5.99828 6.05747L7.00172 4.94253L2.00172 0.442529L0.998276 1.55747ZM5.99828 4.94253L0.998276 9.44253L2.00172 10.5575L7.00172 6.05747L5.99828 4.94253Z"&ndash;&gt;-->
              <!--&lt;!&ndash;                      fill="#FB3099"/>&ndash;&gt;-->
              <!--&lt;!&ndash;                </svg>&ndash;&gt;-->

              <!--&lt;!&ndash;              </div>&ndash;&gt;-->
              <!--            </div>-->
            </div>
          </div>
        </section>
        <section class="home-section news-section">
          <div class="container-small">
            <h2 class="title title-black separator">News</h2>
            <carousel
                :nav="false"
                :items="sliderItemCount"
                v-if="newsFlag"
                :margin="20"
                :dots="false"
                :resize="false"
            >
              <LastThreeNews
                  v-for="(newsitem, index) in news"
                  :key="index"
                  :newsitem="newsitem"
              />
            </carousel>
            <div class="home-news-btn-wrap">
              <router-link :to="{path: '/news'}" class="btn home-news-btn btn-hover">Go to all News</router-link>
            </div>
          </div>
        </section>
      </div>
      <ServerErrorPopup ref="ServerErrorPopup" :errorMessage="error.message"/>
    </main>
    <Footer/>
  </div>
</template>


<script>
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import {debounce} from "lodash";
import carousel from 'vue-owl-carousel2'
import WinnersIeo from "../components/home/WinnersIeo";
import NotStartedIeo from "../components/home/NotStartedIeo";
import LastThreeNews from "../components/home/LastThreeNews";
import ServerErrorPopup from "../components/popups/ServerErrorPopup";
import OpenedIeoPreview from "../components/home/OpenedIeoPreview";

export default {
  components: {LastThreeNews, NotStartedIeo, Footer, Header, WinnersIeo, carousel, ServerErrorPopup, OpenedIeoPreview,},
  name: "Home",
  data() {
    return {
      homeNotStartedIeo: [],
      homeWinnersIeo: [],
      homeOpenedIeoPreview: [],
      defaultOpenedIeoDataObj: {
        searchedText: '',
        statuses: ['OPEN'],
      },
      defaultParamsForOpenedIeo: {
        page: 0,
        size: 3,
      },
      news: null,
      sliderItemCount: 3,
      resizeWatcher: 3,
      isLogged: this.$store.state.authentication.status.loggedIn,
      current: '',
      bgMainFirstSliderImage: {
        backgroundImage: `url(${require('@/assets/img/home/home-slider-img.jpg')})`
      },
      bgMainSecondSliderImage: {
        backgroundImage: `url(${require('@/assets/img/home/home-slider-img.jpg')})`
      },
      bgMainThirdSliderImage: {
        backgroundImage: `url(${require('@/assets/img/home/home-slider-img.jpg')})`
      },
      winnerFlag: null,
      pendingFlag: null,
      newsFlag: null,
      openedPreviewFlag: null,
      error: {
        message: ''
      }
    }
  },
  watch: {
    'resizeWatcher'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.sliderItemCount = newVal
        this.debounceName()
      }
    },

  },
  methods: {
    follow(id) {
      this.$load(async () => {
        await this.$api.followUnfollow.follow(id)
        this.debounceName()
      }, error => {
        this.error.message = error.data.errors[0].message
        this.$refs.ServerErrorPopup.openPopups()
      })
    },
    unfollow(id) {
      this.$load(async () => {
        await this.$api.followUnfollow.unfollow(id)
        this.debounceName()
      }, error => {
        this.error.message = error.data.errors[0].message
        this.$refs.ServerErrorPopup.openPopups()
      })
    },

    lengthWatcher() {
      window.addEventListener("resize", () => {
        if (innerWidth > 1200) {
          this.resizeWatcher = 3
        }
        if (innerWidth <= 1199) {
          this.resizeWatcher = 2

        }
        if (innerWidth < 779) {
          this.resizeWatcher = 1

        }
      }, false);

    },
    getRequestOpenedPreviewParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page - 1;
      }

      if (pageSize) {
        params["size"] = pageSize;
      }

      return params;
    },
    dataRequest() {
      this.$load(async () => {
        this.winnerFlag = false
        this.pendingFlag = false
        this.newsFlag = false
        this.homeOpenedIeoPreview = (await this.$api.pm.filterUnAuth(this.defaultOpenedIeoDataObj, this.defaultParamsForOpenedIeo)).data.body.elements
        this.homeNotStartedIeo = (await this.$api.homeNotStartedIeo.getAll()).data.body.elements
        this.homeWinnersIeo = (await this.$api.homeWinnersIeo.getAll()).data.body.elements
        this.news = (await this.$api.news.getAll()).data.body.elements.slice(0, 3)
        if (this.homeWinnersIeo.length > 0) {
          this.winnerFlag = true
        }
        if (this.homeNotStartedIeo.length > 0) {
          this.pendingFlag = true
        }
        if (this.news.length > 0) {
          this.newsFlag = true
        }
        if (this.homeOpenedIeoPreview.length > 0) {
          this.openedPreviewFlag = true
        }
      }, error => {
        this.error.message = error.data.errors[0].message
        this.$refs.ServerErrorPopup.openPopups()
      })
    },
    compareSongs(song) {
      this.current = song
    },
  },
  mounted() {
    document.title = 'Home'
    this.dataRequest()
    this.lengthWatcher()
    this.debounceName = debounce(this.dataRequest, 500);
    if (window.innerWidth >= 1200) {
      this.sliderItemCount = 3
      debounce(this.dataRequest, 500);
      return
    }
    if (window.innerWidth >= 780) {
      this.sliderItemCount = 2
      debounce(this.dataRequest, 500);
    }
    if (window.innerWidth < 779) {
      this.sliderItemCount = 1
      debounce(this.dataRequest, 500);
    }
  },
  destroyed() {
    localStorage.removeItem('currentSongPlaying')
  }
}
</script>

<style scoped>

.hero-preview-wrapper {
  margin-top: 40px;
}

.how-works-read-faq {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.how-works-read-faq a {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 21px;
  text-decoration: underline;
  color: #FB3099;
}

.ecosystem-details {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.ecosystem-details__text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #FB3099;
  margin-right: 7px;
}

.ecosystem-item-content p {
  margin: 0;
}

/*.ecosystem-item-content {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  justify-content: space-between;*/
/*  align-items: center;*/
/*  height: 100%;*/
/*}*/

@media only screen and (max-width: 992px) {
  .hero-wrap {
    height: auto;
    padding: 25px 0 40px 0;
  }

  .hero-title {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px;
    padding-bottom: 15px;
  }

  .hero-title br {
    /*display: none;*/
  }

  .hero-descr {
    font-size: 15px;
    line-height: 21px;
  }

  .hero-preview-wrapper {
    margin-top: 20px;
  }
}


</style>
