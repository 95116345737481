export default function(instance) {
    return {
        get(params) {
            return instance.get('/api/v2/ieo/favorite', { params })
        },
        favorite(songId) {
            return instance.post(`/api/v2/favorite/${songId}`)
        },
        unfavorite(songId) {
            return instance.delete(`/api/v2/favorite/${songId}`)
        }
    }
}