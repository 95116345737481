<template>
  <div id="my-content">
    <Header/>
    <main id="root">
      <section class="section">
        <div class="container-small">
          <div class="form-wrap feedback-form-wrap">
            <div class="form-logo-wrap">
              <img src="@/assets/img/Union.svg" alt="Form logo" class="form-logo">
            </div>
            <span class="form-subtitle">Reset password</span>
            <form @submit.prevent="onSubmit" class="contact-us-form" novalidate>
              <div class="registration-form-item">
                <div class="registration-field-wrap reset-password-field-wrap">
                  <div class="password-field">
                    <ValidationProvider name="password" rules="required" v-slot="{ errors }">

                      <input
                          :type="passwordType1"
                          v-model="password"
                          name="password"
                          id="id_password1"
                          class="form-password"
                          placeholder="Password"
                          autocomplete="new-password"
                          @focus="focus = true"
                          @blur="focus = false"
                      >
                      <span class="error">{{ errors[0] }}</span>

                    </ValidationProvider>
                    <a href="javascript:void(0);" class="password-show" @click="togglePassword1">
                      <svg class="show" xmlns="http://www.w3.org/2000/svg" width="22.389" height="16.792"
                           viewBox="0 0 22.389 16.792">
                        <g transform="translate(9 -2)">
                          <g transform="translate(-9 2)">
                            <path class="a"
                                  d="M11.278,2C4.2,2,0,10.4,0,10.4s4.2,8.4,11.278,8.4c6.913,0,11.11-8.4,11.11-8.4S18.191,2,11.278,2Zm-.084,13.993a5.6,5.6,0,1,1,5.6-5.6A5.6,5.6,0,0,1,11.194,15.993Zm2.8-5.6a2.8,2.8,0,1,1-2.8-2.8A2.789,2.789,0,0,1,13.993,10.4Z"
                                  transform="translate(0 -2)"></path>
                          </g>
                        </g>
                      </svg>
                      <svg class="hide" xmlns="http://www.w3.org/2000/svg" width="20.955" height="20.955"
                           viewBox="0 0 20.955 20.955">
                        <g transform="translate(0 0)">
                          <g transform="translate(0 0)">
                            <g transform="translate(0)">
                              <path class="a"
                                    d="M20.826,19.592,1.362.128a.437.437,0,0,0-.617,0L.128.745a.436.436,0,0,0,0,.617l3.61,3.61A11.219,11.219,0,0,0,.021,10.344a.439.439,0,0,0,0,.267,11,11,0,0,0,10.456,7.725,10.789,10.789,0,0,0,5.261-1.362l3.854,3.854a.437.437,0,0,0,.617,0l.617-.617A.436.436,0,0,0,20.826,19.592Zm-10.349-3a6.1,6.1,0,0,1-4.89-9.767l1.9,1.9a3.421,3.421,0,0,0-.5,1.757,3.5,3.5,0,0,0,3.492,3.492,3.421,3.421,0,0,0,1.757-.5l1.9,1.9A6.1,6.1,0,0,1,10.477,16.589Z"
                                    transform="translate(0 0)"></path>
                              <path class="a"
                                    d="M246.2,171.013a.43.43,0,0,0,.092.47l2.97,2.97a.429.429,0,0,0,.733-.309,3.413,3.413,0,0,0-3.395-3.395A.469.469,0,0,0,246.2,171.013Z"
                                    transform="translate(-236.056 -163.736)"></path>
                              <path class="a"
                                    d="M156.616,66.225a.43.43,0,0,0,.489.084,5.95,5.95,0,0,1,2.587-.592,6.021,6.021,0,0,1,6.014,6.014,5.95,5.95,0,0,1-.592,2.587.43.43,0,0,0,.084.489l1.489,1.489a.429.429,0,0,0,.3.126h0a.432.432,0,0,0,.3-.127,11.194,11.194,0,0,0,2.685-4.432.432.432,0,0,0,0-.263A10.826,10.826,0,0,0,159.692,64a10.7,10.7,0,0,0-3.762.681.429.429,0,0,0-.153.706Z"
                                    transform="translate(-149.047 -61.422)"></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </a>
                    <div v-show="focus" class="password-tooltip registration-password-tooltip">
                      <p class="register-new-acc-text">Password must contain:</p>
                      <div class="holder-conditions">
                        <div class="group-conditions">
                          <div class="group-conditions-wrap">
                            <div class="condition-item" :class="hasMinimumLength ? 'has-required' : ''">
                              <svg :class="hasMinimumLength ? 'has-required' : ''"
                                   width="11" height="8" viewBox="0 0 11 8" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                                    fill="#8692A6"/>
                              </svg>
                              <p>8 or more characters</p>
                            </div>
                            <div class="condition-item" :class="hasLowercase ? 'has-required' : ''">
                              <svg :class="hasLowercase ? 'has-required' : ''"
                                   width="11" height="8" viewBox="0 0 11 8" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                                    fill="#8692A6"/>
                              </svg>
                              <p>Lowercase letter</p>
                            </div>
                            <div class="condition-item" :class="hasSpecial ? 'has-required' : ''">
                              <svg :class="hasSpecial ? 'has-required' : ''"
                                   width="11" height="8" viewBox="0 0 11 8" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                                    fill="#8692A6"/>
                              </svg>
                              <p>Symbols</p>
                            </div>
                            <div class="condition-item" :class="hasUppercase ? 'has-required' : ''">
                              <svg :class="hasUppercase ? 'has-required' : ''"
                                   width="11" height="8" viewBox="0 0 11 8" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                                    fill="#8692A6"/>
                              </svg>
                              <p>Uppercase letter</p>
                            </div>
                            <div class="condition-item" :class="hasNumber ? 'has-required' : ''">
                              <svg :class="hasNumber ? 'has-required' : ''"
                                   width="11" height="8" viewBox="0 0 11 8" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                                    fill="#8692A6"/>
                              </svg>
                              <p>Numbers</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="registration-field-wrap reset-password-field-wrap">
                  <div class="password-field">
                    <ValidationProvider name="confirmPassword" rules="required" v-slot="{ errors }">
                      <input
                          :type="passwordType2"
                          v-model="passwordConfirm"
                          name="confirmPassword"
                          id="id_password2"
                          class="form-password"
                          placeholder="Re-Enter Password"
                          autocomplete="new-password"
                      >
                      <span class="error">{{ errors[0] }}</span>

                    </ValidationProvider>
                    <a href="javascript:void(0);" class="password-show" @click="togglePasswrod2">
                      <svg class="show" xmlns="http://www.w3.org/2000/svg" width="22.389" height="16.792"
                           viewBox="0 0 22.389 16.792">
                        <g transform="translate(9 -2)">
                          <g transform="translate(-9 2)">
                            <path class="a"
                                  d="M11.278,2C4.2,2,0,10.4,0,10.4s4.2,8.4,11.278,8.4c6.913,0,11.11-8.4,11.11-8.4S18.191,2,11.278,2Zm-.084,13.993a5.6,5.6,0,1,1,5.6-5.6A5.6,5.6,0,0,1,11.194,15.993Zm2.8-5.6a2.8,2.8,0,1,1-2.8-2.8A2.789,2.789,0,0,1,13.993,10.4Z"
                                  transform="translate(0 -2)"></path>
                          </g>
                        </g>
                      </svg>
                      <svg class="hide" xmlns="http://www.w3.org/2000/svg" width="20.955" height="20.955"
                           viewBox="0 0 20.955 20.955">
                        <g transform="translate(0 0)">
                          <g transform="translate(0 0)">
                            <g transform="translate(0)">
                              <path class="a"
                                    d="M20.826,19.592,1.362.128a.437.437,0,0,0-.617,0L.128.745a.436.436,0,0,0,0,.617l3.61,3.61A11.219,11.219,0,0,0,.021,10.344a.439.439,0,0,0,0,.267,11,11,0,0,0,10.456,7.725,10.789,10.789,0,0,0,5.261-1.362l3.854,3.854a.437.437,0,0,0,.617,0l.617-.617A.436.436,0,0,0,20.826,19.592Zm-10.349-3a6.1,6.1,0,0,1-4.89-9.767l1.9,1.9a3.421,3.421,0,0,0-.5,1.757,3.5,3.5,0,0,0,3.492,3.492,3.421,3.421,0,0,0,1.757-.5l1.9,1.9A6.1,6.1,0,0,1,10.477,16.589Z"
                                    transform="translate(0 0)"></path>
                              <path class="a"
                                    d="M246.2,171.013a.43.43,0,0,0,.092.47l2.97,2.97a.429.429,0,0,0,.733-.309,3.413,3.413,0,0,0-3.395-3.395A.469.469,0,0,0,246.2,171.013Z"
                                    transform="translate(-236.056 -163.736)"></path>
                              <path class="a"
                                    d="M156.616,66.225a.43.43,0,0,0,.489.084,5.95,5.95,0,0,1,2.587-.592,6.021,6.021,0,0,1,6.014,6.014,5.95,5.95,0,0,1-.592,2.587.43.43,0,0,0,.084.489l1.489,1.489a.429.429,0,0,0,.3.126h0a.432.432,0,0,0,.3-.127,11.194,11.194,0,0,0,2.685-4.432.432.432,0,0,0,0-.263A10.826,10.826,0,0,0,159.692,64a10.7,10.7,0,0,0-3.762.681.429.429,0,0,0-.153.706Z"
                                    transform="translate(-149.047 -61.422)"></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </a>

                  </div>
                </div>
                <div class="pass-field-error" v-if="compareFailed">
                  <div class="phone-field-error-msg pass-field-error-msg">Repeat the password must be repeated
                    exactly.
                  </div>
                </div>
              </div>

              <button type="submit" class="btn btn-hover">Send</button>
            </form>
          </div>
        </div>
      </section>
      <success-popup ref="serverSuccessPopup" :errorMessage="errors.name"/>
    </main>
    <Footer/>
  </div>
</template>

<script>
import Header from "../header/Header";
import Footer from "../footer/Footer";
import SuccessPopup from "../popups/SuccessPopup";

export default {
  name: 'ContactUs',
  components: {Footer, Header, SuccessPopup},
  data() {
    return {
      errors: {
        name: null
      },
      focus: true,
      password: '',
      passwordConfirm: '',
      passwordType1: "password",
      passwordType2: "password",
      compareFailed: false,
      requireRulesForPassword: false,
      hasMinimumLength: false,
      hasNumber: false,
      hasLowercase: false,
      hasUppercase: false,
      hasSpecial: false,
      token: '',
      dataObj: {
        password: ''
      }
    }
  },
  methods: {
    onSubmit() {
      if(this.comparePass()) {
        this.$load(async () => {
          this.$api.passwordReset.confirmNewPass(this.token, this.dataObj)
        }).then(() => {
          this.$router.push({path: '/login'})
        })
      }
    },
    togglePassword1() {
      this.passwordType1 = this.passwordType1 === "password" ? "text" : "password";
    },
    togglePasswrod2() {
      this.passwordType2 = this.passwordType2 === "password" ? "text" : "password";
    },
    comparePass() {
      if (this.password === this.passwordConfirm && this.requireRulesForPassword) {
        return true
      } else {
        this.compareFailed = true
      }
    },
  },
  computed: {
    requiredFieldsIsTrue() {
      return this.confirmPhoneNumber && this.termsAndConditionsAgreed && this.privacyPolicyAgreed && this.disclaimerConfirm
    }
  },
  watch: {
    password(newVal) {
      this.hasMinimumLength = (newVal.length >= 8);
      this.hasNumber = /\d/.test(newVal);
      this.hasLowercase = /[a-z]/.test(newVal);
      this.hasUppercase = /[A-Z]/.test(newVal);
      this.hasSpecial = /[#?!@$%/^()}{><.&|:;`',*~"-]/.test(newVal);
      if (this.hasMinimumLength && this.hasNumber && this.hasLowercase && this.hasUppercase && this.hasSpecial) {
        this.dataObj.password = newVal
        this.requireRulesForPassword = true;
      } else {
        this.requireRulesForPassword = false;
      }
    },
  },
  mounted() {
    this.token = this.$route.query.token
  }
}
</script>

<style scoped>

.reset-password-field-wrap .password-field {
  width: 100%;
}

.reset-password-field-wrap .password-show {
  top: 15px;
}

.reset-password-field-wrap .condition-item svg {
  display: block;
}

</style>