<template>
  <tbody>
  <InviteItem
      v-for="(invite, index) in invites"
      :key="index"
      :invite="invite"
  />
  </tbody>
</template>

<script>
import InviteItem from "./InviteItem";

export default {
  name: "InviteList",
  props: {
    invites: {
      type: Array,
      default: () => []
    }
  },
  components: {InviteItem},
  data() {
    return {}
  },
}
</script>

<style scoped>

</style>