<template>
  <div class="table-body__row" :tabindex="index + 1" @click="openFullOperations">
    <div class="table-body__col large-col token">
      <div class="img-wrap">
        <img v-if="item.albumCover" :src="item.albumCover" alt="Album cover">
        <img v-else src="@/assets/img/default-song-image.jpg" alt="Default image">
      </div>
      <div class="token-name-wrap">
        <router-link
            class="token-name"
            @click.prevent.stop
            :to="{path: `/trading/${redirectLink}`}"
            v-text="item.fullCurrencyName"
        ></router-link>
      </div>
    </div>

    <div class="table-body__col large-col song">
      <p class="user-name" v-text="item.authorName"></p>
      <p class="song-name" v-text="item.songTitle"></p>
    </div>

    <div class="table-body__col large-col date">
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="2.5" width="14" height="12" rx="2.5" stroke="#C6C6C6"/>
        <path d="M1.5 7.5H13.5" stroke="#C6C6C6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4 1V5" stroke="#C6C6C6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11 1V5" stroke="#C6C6C6" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <p class="text text--black">
        <span v-text="localTimeDateFrom"></span>
        <span v-if="localTimeDateTo">
          <br>
          <span v-text="'- ' + localTimeDateTo"></span>
        </span>
      </p>
    </div>

    <div class="table-body__col medium-col operation">
      <span class="text text--red" v-if="item.isAsk">Sell</span>
      <span class="text text--green" v-else>Buy</span>
    </div>

    <div class="table-body__col medium-col amount">
      <span class="text text--grey" v-text="currencyName"></span>
      <span class="text text--black" v-text="amountForShow"></span>
    </div>

    <div class="table-body__col medium-col price">
      <span class="text text--grey">€</span>
      <span class="text text--black" v-text="priceForShow"></span>
    </div>

    <div class="table-body__col medium-col details">
      <button
          class="details-btn"
          type="button"
          @click="openFullOperations"
      >Details</button>
    </div>

  </div>
</template>

<script>


import moment from "moment";
import numberCurrencyConverter from "@/mixins/numberCurrencyConverter";
import numberConverter from "@/mixins/numberConverter";

export default {
  name: "AgreementsSMListItem",
  props: {
    currencyName: String,
    item: {
      type: Object,
      default: () => {}
    },
    index: Number,
  },
  data() {
    return {

    }
  },
  mixins: [numberCurrencyConverter, numberConverter],
  methods: {
    openFullOperations() {
      this.$emit('openContractList', this.item)
    },
  },
  computed: {
    localTimeDateFrom() {
      let timeUtc = moment.utc(this.item.dateCompleteFrom).local()
      return timeUtc ? moment(String(timeUtc)).format('yyyy-MM-DD HH:mm') : this.item.dateCompleteFrom
    },
    localTimeDateTo() {
      if (this.item.dateCompleteFrom === this.item.dateCompleteTo) {
        return null
      }
      let timeUtc = moment.utc(this.item.dateCompleteTo).local()
      return timeUtc ? moment(String(timeUtc)).format('yyyy-MM-DD HH:mm') : this.item.dateCompleteTo
    },
    priceForShow() {
      return this.numberFormattingDecimal(this.item.sumTotalPrice)
    },
    amountForShow() {
      return this.numberFormattingInteger(this.item.sumAmount)
    },
    redirectLink() {
      return this.item.fullCurrencyName ? this.item.fullCurrencyName.toLowerCase() : ''
    },
  },
}
</script>

<style scoped>

</style>