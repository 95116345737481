<template>
  <div id="my-content">
    <Header/>
    <main id="root">
      <section class="section">
        <div class="container-small">
          <div class="form-wrap pwsr-reset-form-wrap">
            <div class="log-in-form">
              <div class="form-logo-wrap">
                <img src="@/assets/img/Union.svg" alt="Form logo" class="form-logo">
              </div>
              <span class="form-subtitle">Password Reset</span>
              <span class="form-descr">Forgot your password?<br> Enter your e-mail address below. We will send you a password reset link.</span>
              <ValidationObserver v-slot="{ handleSubmit }">
                <form class="password_reset" novalidate="novalidate" @submit.prevent="handleSubmit(sendPass)">
                  <ValidationProvider name="email" rules="email|required" v-slot="{ errors }">
                    <label for="id_email">E-mail:</label>
                    <input v-model="email"
                           type="email"
                           name="email"
                           size="30"
                           placeholder="E-mail address"
                           id="id_email"
                           aria-required="true"
                           class="valid"
                           aria-invalid="false"
                           @input="errorMessage = ''"
                    >
                    <span class="error reset-password-error">{{ errors[0] }}</span>
                    <span class="error reset-password-error">{{ errorMessage }}</span>
                  </ValidationProvider>
                  <button type="submit" class="btn btn-hover">Reset My Password</button>
                </form>
              </ValidationObserver>

              <p class="password-reset-support">Please contact us if you have any trouble resetting your password.</p>
            </div>
          </div>
        </div>
      </section>
    </main>
    <Footer/>
  </div>
</template>

<script>
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

export default {
  name: "PasswordReset",
  data() {
    return {
      email: '',
      errorMessage: ''
    }
  },
  methods: {
    sendPass() {
      if (this.email.length !== 0) {
        this.$load(async () => {
          await this.$api.passwordReset.sendNewPass({'email': this.email}).then(() => {
            this.$router.push({path: '/login'})
          });
        }, error => {
          this.errorMessage = error.data.errors[0].message
        })
      }
    }
  },
  components: {
    Header,
    Footer
  },
  mounted() {
    document.title = 'Password reset'
  }
}
</script>

<style scoped>

</style>