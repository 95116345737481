<template>
  <v-dialog v-model="dialog">
    <div class="type-ieo-popup-server-error white-popup popup-form order-popup success">
      <div class="form-main-content">
        <div class="popup-header popup-header-verify">
          <div class="close-dialog" @click="dialog = false">
            <img src="../../assets/img/close.svg" alt="close-icon">
          </div>
          <h3 class="form-title">Create Virtual IBAN</h3>
        </div>
        <div class="popup-content">
          <div class="popup-content-containerr">
            You can create a personal virtual IBAN (International bank account number) which will be connected to your DjookyX wallet. By creating your own IBAN you will be able to transfer funds from any bank or financial institution directly to your DjookyX wallet.
          </div>
          <button class="btn btn-hover" @click="confirmAction">Create</button>
        </div>

      </div>
    </div>

  </v-dialog>
</template>

<script>
export default {
  name: "CreateVirtualIBANPopup",
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    openPopups() {
      this.dialog = true;
    },
    confirmAction() {
      this.$emit('createIBAN')
      this.dialog = false
    }
  },
};
</script>

<style scoped>
.popup-content {
  padding: 32px 45px;
}

.popup-content-containerr {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #272727;
}

.popup-content button {
  margin-top: 30px;
}

</style>