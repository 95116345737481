<template>
  <div id="my-content" ref="top">
    <Header/>
    <main id="root">

      <div class="profile">
        <Sidebar/>
        <div class="profile-content" v-if="loadFlag">
          <div class="notifications-clear-all-header">
            <div class="notifications-clear-all-wrap">
              <a @click="clearNotes" class="notifications-clear-all" style="cursor: pointer">
                <div class="notifications-clear-all-icon">
                  <svg width="15" height="18" viewBox="0 0 15 18" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M1.47248 15.711C1.47248 16.7885 2.35589 17.6699 3.43583 17.6699H11.2891C12.369 17.6699 13.2524 16.7885 13.2524 15.711V4.41747H1.47248V15.711ZM14.7249 1.4725H11.0437L9.81115 0H4.9138L3.68122 1.4725H0V2.94498H14.7249V1.4725Z"
                          fill="#B2B2B2"/>
                  </svg>
                </div>
                <span class="notifications-clear-all-text">Clear all</span>
              </a>
            </div>
          </div>
          <div class="new-notifications">
            <h2 class="notifications-title">new notifications</h2>
            <ul class="notifications-list new-notifications-list" v-if="notificationFalseList" :class="{'empty': notificationFalseList.length < 3}">
              <vuescroll :ops="ops" style="padding-right: 25px; margin-top: 20px;">
                <NotificationsFalseList
                    v-for="(element, index) in notificationFalseList"
                    :key="index"
                    :element="element"
                />
              </vuescroll>
            </ul>
            <ul v-else class="notifications-list new-notifications-list empty">
              <li class="notifications-list-item empty">You don't have new notifications</li>
            </ul>
          </div>
          <div class="viewed-notifications">
            <h2 class="notifications-title">viewed notifications</h2>
            <ul class="notifications-list new-notifications-list" v-if="notificationTrueList" :class="{'empty': notificationTrueList.length < 3}">
              <vuescroll :ops="ops" style="padding-right: 25px; margin-top: 20px;">
                <NotificationsTrueList
                    v-for="(element, index) in notificationTrueList"
                    :key="index"
                    :element="element"
                />
              </vuescroll>
            </ul>
            <ul v-else class="notifications-list new-notifications-list empty">
              <li class="notifications-list-item">You don't have viewed notifications</li>
            </ul>
          </div>

        </div>
        <div class="preloader-wrapper profile-preloader" v-else>
          <v-progress-circular
              :size="75"
              :width="8"
              color="#FF0090"
              indeterminate
          ></v-progress-circular>
        </div>
      </div>

    </main>
    <Footer/>
  </div>
</template>

<script>
import Sidebar from "./Sidebar";
import NotificationsFalseList from "./NotificationFalseList";
import NotificationsTrueList from "./NotificationsTrueList";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import scrollToElement from "@/mixins/scrollToElement";
import vuescroll from "vuescroll";


export default {
  Name: "Notifications",
  data() {
    return {
      loadFlag: false,
      notificationFalseList: null,
      notificationTrueList: null,
      ops: {
        rail: {
          background: 'rgba(234, 234, 234)',
          opacity: 0.5,
          size: '6px',
          gutterOfSide: '2px',
        },
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: true,
          background: '#FF0090',
          opacity: 1,
          minSize: 0.3,
          size: '6px',
        }
      },
    }
  },
  mixins: [scrollToElement],
  methods: {
    clearNotes() {
      this.$load(async () => {
        this.loadFLag = false
        await this.$api.notifications.clearAll()
        this.notificationFalseList = (await this.$api.notifications.getViewed()).data.body.elements.false
        this.notificationTrueList = (await this.$api.notifications.getViewed()).data.body.elements.true
        this.loadFlag = true
      })
    },
  },
  mounted() {
    document.title = 'Notifications'
    this.$load(async () => {
      this.loadFlag = false
      // let res = await this.$api.notifications.getViewed().data.body
      // console.log(res)
      this.notificationFalseList = (await this.$api.notifications.getViewed()).data.body.elements.false
      this.notificationTrueList = (await this.$api.notifications.getViewed()).data.body.elements.true
      this.loadFlag = true
    })

    setTimeout(() => {
      this.scrollToId('my-content')
    }, 500)
  },
  components: {
    Footer,
    Header,
    NotificationsFalseList,
    Sidebar,
    NotificationsTrueList,
    vuescroll,
  }
}
</script>

<style scoped>

</style>