<template>
  <div id="my-content">
    <Header/>
    <main id="root">
      <section class="section">
        <div id="artists-page" class="container-small">
          <div class="music-header">
            <h1 class="page-title separator" ref="top">Artists</h1>
            <a href="javascript:void(0)" class="cd-filter-trigger cd-filter-trigger-top"
               @click="filterToggleFlag = !filterToggleFlag"></a>
          </div>
          <form class="artist-header">
              <div id="cover" class="search-block">
                  <div class="search-input"
                       @click="toggleSearchBlock"
                       :style="{width: statusSearchBlock ? '100%' : '50px',
                               border: statusSearchBlock ? 'none' : '0.5px solid #272727',
                               borderRadius: statusSearchBlock ? 'none' : '3px',
                               marginBottom: statusSearchBlock ? '10px' : '0'
                               }"
                  >
                    <input
                        @click.prevent.stop
                        v-model.trim="dataObj.searchedText"
                        type="text"
                        name="searchedText"
                        id="id_search"
                        placeholder="Search..."
                        class="search-news"
                        @change="page = 1"
                        v-show="statusSearchBlock"
                        :style="{padding: statusSearchBlock ? '15px 70px 15px 27px' : '0'}"
                    >
                    <div class="search-input-item"
                         :style="{left: statusSearchBlock ? 'unset' : '50%',
                                  transform: statusSearchBlock ? 'translateY(-50%)' : 'translate(-50%, -50%)',
                                  right: statusSearchBlock ? '26px' : 'unset',
                                  }">
                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.71 16.29L14.31 12.9C15.407 11.5025 16.0022 9.77665 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C9.77665 16.0022 11.5025 15.407 12.9 14.31L16.29 17.71C16.4778 17.8993 16.7334 18.0058 17 18.0058C17.2666 18.0058 17.5222 17.8993 17.71 17.71C17.8993 17.5222 18.0058 17.2666 18.0058 17C18.0058 16.7334 17.8993 16.4778 17.71 16.29ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8V8Z" fill="black"/>
                      </svg>
                    </div>
                  </div>

<!--                  <div class="sort-block">-->
<!--                    <GeneralSelect-->
<!--                        id="id_sort_select"-->
<!--                        :options="sortISParams"-->
<!--                        :identifier="'sort-artists'"-->
<!--                        :placeholder="sortISParams[1].name"-->
<!--                        class="select"-->
<!--                        @changed="setSortParams"-->
<!--                        @openStatus="toggleSortSelect"-->
<!--                    />-->
<!--                    <div class="sort-block-item">-->
<!--                      <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg"-->
<!--                           :style="{transform: openSortSelect ? 'rotate(180deg)' : 'rotate(0deg)'}"-->
<!--                      >-->
<!--                        <path d="M10.6975 0.5L6.00024 5.19727L1.30298 0.5" stroke="#272727" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                      </svg>-->
<!--                    </div>-->
<!--                  </div>-->
              </div>
            </form>
          <div class="artist-container">
            <ArtistFilter  @filterData="filterDataRequest" v-if="filterDeskOrMobile === 'desktop'"/>
            <div class="cd-filter" ref="filter" :class="{'filter-is-visible': filterToggleFlag}">
              <div class="aside-title-wrapper">
                <h1 class="title ieo-title separator">Filter</h1>
              </div>
              <aside id="secondary" class="filter-sidebar">
                <div class="sidebar-wrap">
                  <div class="music-charts-filtering-wrap">
                    <ArtistFilter @filterData="filterDataRequest" ref="filterMobile" v-if="filterDeskOrMobile === 'mobile'"/>
                  </div>
                </div>
              </aside>
              <a href="javascript:void(0)" class="cd-close" @click="filterToggleFlag = false"></a>
            </div>
            <a href="javascript:void(0)" class="cd-filter-trigger" v-if="hidePanelFilter" @click="filterToggleFlag = !filterToggleFlag"></a>
            <div class="artist-list-container">
              <div class="artist-list-btn-wrap">
                <button class="artist-list-btn-item"
                        @click="toDjkXArtists"
                        :style="{background: $route.query.artist === 'djk-x' ? '#FF0090' : '#FFF5FB',
                                 color: $route.query.artist === 'djk-x' ? '#FFFFFF' : '#FF0090'
                        }"
                >DjookyX</button>
                <button class="artist-list-btn-item"
                        @click="toDjkAwardsArtists"
                        :style="{background: $route.query.artist === 'djk-awards' ? '#FF0090' : '#FFF5FB',
                                 color: $route.query.artist === 'djk-awards' ? '#FFFFFF' : '#FF0090'
                        }"
                >Djooky Awards</button>
              </div>
              <a href="javascript:void(0)" class="cd-filter-trigger" @click="filterToggleFlag = !filterToggleFlag"></a>

              <div class="filter-default-text" v-if="!artists.length">No results...</div>
              <ArtistListTemplate :artists="artists" v-if="loadFlag"/>
              <div class="preloader-wrapper" v-else>
                <v-progress-circular
                    :size="75"
                    :width="8"
                    color="#FF0090"
                    indeterminate
                ></v-progress-circular>
              </div>
              <b-pagination-nav v-if="checkArtistLength"
                                class="pagination"
                                v-model="page"
                                :total-rows="count"
                                :per-page="pageSize"
                                :link-gen="linkGen"
                                :number-of-pages="count"
                                prev-text="«"
                                next-text="»"
                                first-number
                                last-number
                                use-router
              ></b-pagination-nav>
<!--              <span class="result-empty" v-else>No results were found</span>-->
            </div>

            <div class="preloaderss">
              <div class="main-circle">
                <div class="green-circle">
                  <div class="brown-circle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>

      <server-error-popup ref="serverErrorPopup" :errorMessage="error.serverError"/>
    </main>
    <Footer/>
  </div>
</template>

<script>

// import GeneralSelect from "../components/inputs/GeneralSelect";

import ArtistListTemplate from "../components/artist/ArtistListTemplate";
import {debounce} from "lodash"
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import ArtistFilter from "../components/artist/ArtistFilter";
import ServerErrorPopup from "../components/popups/ServerErrorPopup";
import scrollToElement from "@/mixins/scrollToElement";


const customLabels = {
  first: '<<',
  last: '>>',
  previous: '<',
  next: '>'
};
export default {
  name: "ArtistList",
  data: () => ({
    filterToggleFlag: false,
    // isLogged: this.$store.state.authentication.status.loggedIn,
    filterDeskOrMobile: '',
    changeArtistType: 'djk-x',
    artists: [],
    customLabels,
    flag: false,
    page: 1,
    count: 0,
    pageSize: 9,
    loadFlag: false,
    statusSearchBlock: true,
    error: {
      serverError: ''
    },
    dataObj: {
      searchedText: '',
    },
    // sortISParams: [
    //   {
    //     value: true,
    //     name: 'Sort By: Most Recent',
    //   },
    //   {
    //     value: false,
    //     name: 'Sort By: Most Ne Recent',
    //   },
    // ],
    // openSortSelect: Boolean,
  }),
  mixins: [scrollToElement],
  methods: {
    lengthWatcher() {
      window.addEventListener("resize", () => {
        if (window.innerWidth > 1201) {
          this.filterToggleFlag = false
          this.filterDeskOrMobile = 'desktop'
        }
        if (window.innerWidth <= 1200) {
          this.filterDeskOrMobile = 'mobile'
        }
        if (window.innerWidth <= 540) {
          this.filterToggleFlag = false
        }
      }, false);

    },
    filterDataRequest(data) {
      this.dataObj = Object.assign(this.dataObj, data)
      this.page = 1
      if (this.$route.query.page === '1') {
        this.checkName(this.page)
      } else {
        this.$router.push(this.linkGen(1))
      }

      if (this.$refs.filter) {
        this.filterToggleFlag = false
      }
    },
    linkGen(pageNum) {
      // return pageNum === 1 ? '?' : `?page=${pageNum}`
      return {
        path: '/artists',
        query: {page: pageNum, artist: this.$route.query.artist}
      }
    },
    // linkGenArtistType(pageNum, artistType) {
    //   return {
    //     path: '/artists',
    //     query: {page: pageNum, artist: artistType}
    //   }
    // },
    checkName() {
      const params = this.getRequestParams(
          this.page,
          this.pageSize
      );
      if (this.$route.query.artist === 'djk-x') {
        this.$load(async () => {
          this.loadFlag = false
          let res = (await this.$api.artists.getFilteredArtists(this.dataObj, params)).data.body
          this.artists = res.elements
          this.count = res.totalPages
          this.loadFlag = true
        }, error => {
          this.error.serverError = error.data.errors[0].message
          this.$refs.serverErrorPopup.openPopups()
        })
      } else {
        this.$load(async () => {
          this.loadFlag = false
          let res = (await this.$api.artists.getFilteredArtistsDjk(this.dataObj, params)).data.body
          this.artists = res.elements
          this.count = res.totalPages
          this.loadFlag = true
        }, error => {
          this.error.serverError = error.data.errors[0].message
          this.$refs.serverErrorPopup.openPopups()
        })
      }
    },
    getRequestParams(page, pageSize) {
      let params = {};
      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }

      return params;
    },
    filterToggleFlagFunc(event) {
      if (!event.target.closest('.cd-filter') &&
          $('.cd-filter').hasClass('filter-is-visible') &&
          !event.target.closest('.cd-filter-trigger-top') &&
          !event.target.closest('.cd-filter-trigger') &&
          !event.target.closest('.mx-datepicker-main')) {
        this.filterToggleFlag = false
      }
    },
    // setSortParams(data) {
    //   this.dataObj.sortDateBy = data
    //   // console.log(data)
    //   this.debounceName()
    // },
    // toggleSortSelect(data) {
    //   this.openSortSelect = data
    // },
    toDjkXArtists() {
      this.$router.push({path: '/artists', query: {page: '1', artist: 'djk-x'}})
    },
    toDjkAwardsArtists() {
      this.$router.push({path: '/artists', query: {page: '1', artist: 'djk-awards'}})
    },
    toggleSearchBlock() {
      if (window.innerWidth <= 540) {
        this.statusSearchBlock = !this.statusSearchBlock
      } else {
        return
      }
    },
  },
  computed: {
    checkArtistLength() {
      return this.artists.length > 0
    },
    hidePanelFilter() {
      return window.innerWidth > 540
    },
  },
  watch: {
    'dataObj.searchedText'() {
      this.page = 1
      this.debounceName();
    },
    // changeArtistType() {
    //   this.checkName()
    //
    //   // if (this.changeArtistType) {
    //   //   this.$router.push(this.linkGenArtistType(1, this.changeArtistType))
    //   // }
    // },
    // '$route.query.page': {
    //   deep: true,
    //   handler(newVal) {
    //     if(newVal) {
    //       this.page = newVal
    //       this.checkName()
    //     } else {
    //       this.page = 1
    //     }
    //   }
    // },
    // '$route.query.artist': {
    //   deep: true,
    //   handler(newVal) {
    //     console.log('query: ', newVal)
    //     if (newVal) {
    //       console.log('query artist : ', newVal)
    //       this.changeArtistType = newVal
    //     }
    //   },
    // },
      '$route.query.page': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.page = newVal
          this.debounceName()
          if (this.count < newVal) {
            this.page = 1
          }
          this.scrollToId('my-content')
        } else {
          this.page = 1
        }
      }
    },
  },
  mounted() {
    document.title = 'Artists'
    this.page = this.$route.query.page || 1

    document.getElementById('id_search').addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    })

    this.checkName()
    this.debounceName = debounce(this.checkName, 500);
    document.addEventListener('click', this.filterToggleFlagFunc);

    if (window.innerWidth > 1201) {
      this.filterDeskOrMobile = 'desktop'
    }
    if (window.innerWidth <= 1200) {
      this.filterDeskOrMobile = 'mobile'
    }
    if (window.innerWidth <= 540) {
      this.statusSearchBlock = false
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onClick);
  },
  components: {
    Footer,
    Header,
    ArtistListTemplate,
    // GeneralSelect,
    ArtistFilter,
    ServerErrorPopup,
  },
}
</script>

<style>

#artists-page .search-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

#artists-page .search-input {
  position: relative;
}

#artists-page .sort-block {

}

#artists-page .sort-block-item {

}

#artists-page .artist-header {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #EAEAEA;
}

#artists-page .search-input-item {
  position: absolute;
  right: 26px;
  top: 50%;
  transform: translateY(-50%);
}

#artists-page .artist-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

#artists-page .artist-list-container {
  position: relative;
  width: calc(100% - 250px);
}

#artists-page .artist-list-btn-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 29px;
}

#artists-page .artist-list-btn-item {
  padding: 19px 30px 20px 30px;
  width: 50%;
  text-align: center;
  background: #FFF5FB;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  color: #FF0090;
  border-radius: 10px 10px 0 0;
  border-bottom: 2px solid #FF0090;
  transition: .5s ease;
  cursor: pointer;
}

#artists-page .artist-list-btn-item:hover {
  background: #FF0090;
  color: #FFFFFF;
}

#artists-page .artist-list {
  margin-top: 0;
}

#artists-page .artist-list-item {
  width: calc(33.3% - 25px);
}

#artists-page .artist-list-item-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

#artists-page .artist-list-item-body-top {
  padding: 17px 16px 11px 16px;
}

#artists-page .artist-list-item-body-bottom {
  padding: 18px 16px 20px 16px;
}

#artists-page .pagination {
  margin-top: 30px;
}

@media only screen and (max-width: 1200px) {
  #artists-page .artist-list-container {
    width: 100%;
  }
}

@media only screen and (max-width: 992px) {
  #artists-page .search-input {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }

  #artists-page .sort-block {
    /*width: 100%;*/
  }

  #artists-page .artist-list-item {
    width: calc(50% - 25px);
  }

  #artists-page .artist-list {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 768px) {
  #artists-page .artist-list-item {
    width: 100%;
  }

  #artists-page .artist-list-item {
    margin-right: 0;
  }
}

@media only screen and (max-width: 540px) {
  #artists-page .search-input {
    cursor: pointer;
    transition: .5s ease;
  }

  #artists-page .sort-block {
    width: calc(100% - 65px);
    margin-top: 0;
    transition: .5s ease;
  }

  #artists-page .artist-header {
    border: none;
    padding: 0;
    margin-bottom: 20px;
  }

  #artists-page .artist-list-btn-item {
    font-size: 20px;
    line-height: 20px;
    padding: 14px;
  }

  #artists-page .artist-list-btn-wrap {
    margin-bottom: 20px;
  }

  #artists-page .artist-list-item {
    margin-bottom: 20px;
  }

  #artists-page .pagination a,
  #artists-page .pagination span.active {
    min-width: 30px;
    text-align: center;
  }

  #artists-page .pagination {
    margin-top: 10px;
  }

  #artists-page .general_select {
    padding-right: 50px;
  }

}

@media only screen and (max-width: 375px) {
  #artists-page .artist-list-btn-item {
    font-size: 16px;
    line-height: 16px;
    padding: 14px;
  }
}





</style>