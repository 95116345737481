<template>
  <div class="pending-ieo-list-item artist-ieo edit-song" :id="`${identifier}-${index}`">
    <div class="pending-ieo-list-item-header">
      <div class="pending-ieo-label" v-if="ieo.totalPlanned">
        <span class="pending-ieo-label-title">Total Planned:</span>
        <span class="pending-ieo-label-price"
              v-text="`€ ${totalPlanedWithSeparator}`"></span>
      </div>
      <img v-if="ieo.songAlbumUrlImage" :src="ieo.songAlbumUrlImage"
           @error='addDefaultSrc'
           alt="Author image"
           class="winners-music-list-item-image lazy"
           id="songAlbumUrl"
           loading="lazy"
      >
      <img v-else class="winners-music-list-item-image lazy"
           src="../../assets/img/default-song-image.jpg"
           alt="Author image"
           loading="lazy"
      >
      <div class="pending-ieo-list-item__play-wrap">
        <div :class="togglePlayerFlag ?
                                  'hitparad_button-play hitparad_button-pause'
                                  : 'hitparad_button-play hitparad_button-play-active'" @click="toggleMusic">
          <div class="hitparad_button-play-progress">
            <canvas width="70" height="70"></canvas>
          </div>
        </div>
      </div>
      <div class="audio-player-wrapper">
        <AudioPlayer
            :src="playerSrc"
            :togglePlayerFlag="togglePlayerFlag"
            :width="'100%'"
            v-if="playerSrc"
            :ref="`audioPlayer${identifier}${index}`"
            @audioPause="togglePlayerFlag = true"
            :identifier="identifier"
            :index="index"
        />
      </div>
    </div>
    <div class="pending-ieo-list-item-body">
      <div class="pending-ieo-list-item-body-top">
        <router-link :to="{path: '/song/' + ieo.songId}"
                     class="pending-ieo-list-item-name-song-link">
                    <span class="pending-ieo-list-item-name-song"
                          v-text="ieo.songTitle"></span>
        </router-link>
        <div class="pending-ieo-list-item-info">
          <router-link :to="{path: '/artist/'+ ieo.singerUsername}"
                       class="pending-ieo-list-item-name-artist-link">
                        <span class="pending-ieo-list-item-name-artist"
                              v-text="artistFullName"></span>
          </router-link>
          <ul class="profile-my-music-genre-list">
            <li class="profile-my-music-genre-list-item genre"
                v-text="ieo.songGenre"></li>
          </ul>
        </div>
      </div>


      <div>
        <div v-if="ieo.status === 'NOT_STARTED'" class="pending-ieo-list-item-body-middle">
          <div class="pending-ieo-countdown-wrap">
            <span class="pending-ieo-countdown-title">Time to the PM start:</span>
            <div class="flip-wrap">
              <flip-countdown :deadline="timeIeoStart" v-if="timeIeoStart" :showSeconds="false"></flip-countdown>
            </div>
            <!--            <div class="countdown pending-ieo-countdown"-->
            <!--                 :countdown='ieo.timeIeoStart'></div>-->
          </div>
        </div>

        <div v-else-if="ieo.status === 'OPEN'" class="pending-ieo-list-item-body-middle">
          <div class="pending-ieo-countdown-wrap">
            <span class="pending-ieo-countdown-title">Time to the IS finish:</span>
            <div class="flip-wrap">
              <flip-countdown :deadline="ieo.timeIeoFinish" v-if="ieo.timeIeoFinish"
                              :showSeconds="false"></flip-countdown>
            </div>
          </div>
        </div>
      </div>

      <div class="pending-ieo-list-item-body-bottom" v-if="ieo.status === 'OPEN'">
        <div class="pending-ieo-subscribers-wrap"
             v-if="ieo.subscribed == false">
          <svg width="22" height="14" viewBox="0 0 22 14" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"
                  fill="#D0D0D0"/>
          </svg>
          <span class="pending-ieo-subscribers-title"
                v-text="ieo.subscribers ? ieo.subscribers : '0' + ' ' + 'subscribers'"></span>
        </div>
        <div class="pending-ieo-subscribers-wrap" v-else-if="ieo.subscribed == true">
          <svg width="22" height="14" viewBox="0 0 22 14" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"
                  fill="#FF0090"/>
          </svg>
          <span class="pending-ieo-subscribers-title"
                v-text="ieo.subscribers ? ieo.subscribers : '0' + ' ' + 'subscribers'"></span>
        </div>

        <div v-if="isLogged === true" class="pending-ieo-btn-wrap">
          <a @click.prevent="followIeo"
             :class="ieo.subscribedIeo === true ? 'pending-ieo-btn btn btn-hover follow' : 'pending-ieo-btn btn btn-hover'"
             v-if="ieo.subscribedIeo === false">Subscribe</a>
          <a @click.prevent="unfollowIeo"
             :class="ieo.subscribedIeo === true ? 'pending-ieo-btn btn btn-hover follow' : 'pending-ieo-btn btn btn-hover'"
             v-else>Unsubscribe</a>
          <!--                    <a th:href="@{'/market/follow-ieo/' + ${ieo.ieoId} + '/' + ${ieo.songId}}"-->
          <!--                       :class="ieo.subscribed == true ? 'pending-ieo-btn btn btn-hover follow' : 'pending-ieo-btn btn btn-hover'" v-if="ieo.subscribed == false">Subscribe</a>-->
          <!--                    <a th:href="@{'/market/unfollow-ieo/' + ${ieo.ieoId} + '/' + ${ieo.songId}}"-->
          <!--                       :class="ieo.subscribed == true ? 'pending-ieo-btn btn btn-hover follow' : 'pending-ieo-btn btn btn-hover'"-->
          <!--                       v-else>Unsubscribe</a>-->
        </div>
        <div v-else class="pending-ieo-btn-wrap">
          <router-link :to="{path: '/login'}" class="pending-ieo-btn-not-auth btn btn-hover">Login
            or Register to subscribe
          </router-link>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import FlipCountdown from 'vue2-flip-countdown'
import moment from "moment";
import img from "../../assets/img/default-user-image.png";
import numberFormattingDecimal from "../../mixins/numberCurrencyConverter";
import nameHandler from "@/mixins/nameHandler";
import AudioPlayer from "@/components/AudioPlayer";

export default {
  name: "ArtistProfileList",
  props: {
    ieo: {
      type: Object,
      default: () => {
      }
    },
    isLogged: {
      type: Boolean
    },
    current: String,
    identifier: {
      type: String,
      default: 'id',
      required: true,
    },
    index: {
      type: Number,
      default: 1,
      required: true,
    },
  },
  data() {
    return {
      togglePlayerFlag: true,
      mp3: '',
      player: new Audio(),
      timeIeoFinish: null,
      timeIeoStart: null,
      totalPlanedWithSeparator: null,
      artistFullName: '',
      playerSrc: null
    }
  },
  mixins: [numberFormattingDecimal, nameHandler],
  methods: {
    addDefaultSrc(ev) {
      ev.target.src = img
    },
    followIeo() {
      this.$load(async () => {
        await this.$api.followUnfollow.follow(this.ieo.ieoId)
        this.$emit('followIeo', this.ieo.ieoId)
      }, error => {
        this.$emit('serverError', error.data.errors[0].message)
      })

    },
    unfollowIeo() {
      this.$load(async () => {
        await this.$api.followUnfollow.unfollow(this.ieo.ieoId)
        this.$emit('unfollowIeo', this.ieo.ieoId)
      }, error => {
        this.$emit('serverError', error.data.errors[0].message)
      })

    },
    toggleMusic() {
      if (this.togglePlayerFlag) {
        this.togglePlayerFlag = false
        localStorage.setItem('currentSongPlaying', this.mp3)
        this.$emit('playingMusic')
        this.$refs[`audioPlayer${this.identifier}${this.index}`].toggleMusic()
      } else {
        this.togglePlayerFlag = true
        this.$refs[`audioPlayer${this.identifier}${this.index}`].toggleMusic()
      }
    },

  },
  watch: {
    'current'(newVal) {
      if (newVal !== this.mp3) {
        this.$refs[`audioPlayer${this.identifier}${this.index}`].refreshSong()
        this.togglePlayerFlag = true
      }
    }
  },
  mounted() {
    this.mp3 = this.ieo.songUrlFile
    this.playerSrc = this.mp3

    this.artistFullName = this.getNameDefault(
        this.ieo.companyName,
        this.ieo.stageName,
        this.ieo.singerFirstName,
        this.ieo.singerLastName
    );

    this.totalPlanedWithSeparator = this.numberFormattingDecimal(this.ieo.totalPlanned)

    if (this.ieo.timeIeoStart) {
      let convertTimeToLocal = moment.utc(this.ieo.timeIeoStart).local()
      let resultTimeDate = moment(String(convertTimeToLocal)).format('yyyy-MM-DD HH:mm')
      this.timeIeoStart = resultTimeDate
    }
    if (this.ieo.timeToFinish) {
      let convertTimeToLocal = moment.utc(this.ieo.timeToFinish).local()
      let resultTimeDate = moment(String(convertTimeToLocal)).format('yyyy-MM-DD HH:mm')
      this.timeToFinish = resultTimeDate
    }
  },
  beforeDestroy() {
    this.togglePlayerFlag = true
  },
  components: {FlipCountdown, AudioPlayer}
}
</script>

<style scoped>
.audio-player-wrapper {
  height: 45px;
  color: #FFFFFF;
}
</style>
