<template>
  <div class="winners-music-list-item">
    <div class="winners-music-list-item-header">
      <img v-if="winnerIeoItem.songAlbumUrlImage" :src="winnerIeoItem.songAlbumUrlImage"
           @error='addDefaultSrc'
           alt="Author image"
           class="winners-music-list-item-image lazy"
           id="songAlbumUrl"
           loading="lazy"
      >
      <img v-else class="winners-music-list-item-image lazy"
           src="../../assets/img/default-song-image.jpg"
           alt="Author image"
           loading="lazy"
      >
      <div class="winners-music-list-item__play-wrap">
        <div :class="togglePlayerFlag ?
                                  'hitparad_button-play hitparad_button-pause'
                                  : 'hitparad_button-play hitparad_button-play-active'" @click="toggleMusic">
          <div class="hitparad_button-play-progress">
            <canvas width="70" height="70"></canvas>
          </div>
        </div>
      </div>
      <div class="audio-player-wrapper">
        <AudioPlayer
            :src="playerSrc"
            :togglePlayerFlag="togglePlayerFlag"
            :width="'100%'"
            v-if="playerSrc"
            :ref="`audioPlayer${identifier}${index}`"
            @audioPause="togglePlayerFlag = true"
            :identifier="identifier"
            :index="index"
        />
      </div>
    </div>
    <div class="winners-music-list-item-body">
      <div class="winners-music-list-item-body-top">
        <router-link :to="{path: `/song/${winnerIeoItem.songId}`}"
                     class="pending-ieo-list-item-name-song-link">
          <span class="pending-ieo-list-item-name-song" v-text="winnerIeoItem.songTitle"></span>
        </router-link>
        <div class="winners-music-list-item-info">
          <router-link :to="{path: `/artist/${winnerIeoItem.singerUsername}`}"
                       class="pending-ieo-list-item-name-artist-link">
            <span class="pending-ieo-list-item-name-artist" v-text="winnerIeoItem.singerUsername"></span>
          </router-link>
          <ul class="profile-my-music-genre-list winners-genre-label">
            <li class="profile-my-music-genre-list-item genre"
                v-text="winnerIeoItem.songGenre"></li>
          </ul>
        </div>
      </div>
      <div class="winners-music-list-item-body-bottom">
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 50 50">
          <path class="a"
                d="M25.084,0a25,25,0,1,0,25,25A25,25,0,0,0,25.084,0ZM36.665,18.084,24.69,36.387s-.009.006-.009.009a2.279,2.279,0,0,1-.175.287,2.246,2.246,0,0,1-.375.272c-.031.022-.063.047-.1.069a2.025,2.025,0,0,1-.613.256c-.072.019-.137.037-.212.05a2.11,2.11,0,0,1-.525.006,2.069,2.069,0,0,1-.366-.056,2.212,2.212,0,0,1-.359-.144,2.5,2.5,0,0,1-.3-.119,1.381,1.381,0,0,1-.166-.166c-.025-.022-.056-.028-.081-.05L14.44,30.356a2.187,2.187,0,1,1,2.969-3.213l5.047,4.669L33,15.691a2.188,2.188,0,0,1,3.663,2.394Z"
                transform="translate(-0.084)"/>
        </svg>
        <span class="winners-music-list-item-body-ieo-info"
              v-text="`Successful`"></span>
      </div>
    </div>
    <div class="winners-music-list-item-footer">
      <span class="winners-music-list-item-price-title">Сollected:</span>
      <span class="winners-music-list-item-price"
            v-text="`€ ${totalCollected}`"></span>
    </div>
  </div>
</template>

<script>

import img from '../../assets/img/default-user-image.png'
import numberFormattingInteger from "../../mixins/numberConverter";
import numberFormattingDecimal from "../../mixins/numberCurrencyConverter";
import AudioPlayer from "@/components/AudioPlayer";

export default {
  data() {
    return {
      img: this.winnerIeoItem.songAlbumUrlImage,
      togglePlayerFlag: true,
      mp3: '',
      playerSrc: null,
    }
  },
  components: {
    AudioPlayer
  },
  props: {
    winnerIeoItem: {
      type: Object
    },
    current: {
      type: String
    },
    index: {
      type: Number
    },
    identifier: {
      type: String
    }
  },
  mixins: [numberFormattingInteger, numberFormattingDecimal],
  computed: {
    totalCollected() {
      return this.numberFormattingDecimal(this.winnerIeoItem.collectedMoney)
    },
  },
  methods: {
    addDefaultSrc(ev) {
      ev.target.src = img
    },
    toggleMusic() {
      if (this.togglePlayerFlag) {
        this.togglePlayerFlag = false
        localStorage.setItem('currentSongPlaying', this.mp3)
        this.$emit('playingMusic', this.mp3)
        this.$refs[`audioPlayer${this.identifier}${this.index}`].toggleMusic()
      } else {
        this.togglePlayerFlag = true
        this.$refs[`audioPlayer${this.identifier}${this.index}`].toggleMusic()
      }
    },
  },
  watch: {
    'current'(newVal) {
      if (newVal !== this.mp3) {
        this.$refs[`audioPlayer${this.identifier}${this.index}`].refreshSong()
        this.togglePlayerFlag = true
      }
    }
  },
  mounted() {
    this.mp3 = this.winnerIeoItem.songUrlFile
    this.playerSrc = this.mp3
  },
  beforeDestroy() {
    this.togglePlayerFlag = true
  },

}
</script>

<style scoped>
.audio-player-wrapper {
  height: 45px;
  color: #FFFFFF;
}
</style>