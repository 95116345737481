<template>
    <tr>
        <td data-label="Date" class="left" v-text="history.createdAt"></td>
        <td data-label="Pair" class="left" v-text="history.songName"></td>
<!--        <td class="left" v-text="history.createdAt">Limit</td>-->
        <td data-label="Side" class="left buy" v-if="history.ask == true">Buy</td>
        <td data-label="Side" class="left sell" v-else>Sell</td>
        <td data-label="Price" class="left" v-text="history.price"></td>
        <td data-label="Amount" class="left" v-text="history.amount"></td>
        <td data-label="Fee" class="left" v-text="history.systemFee"></td>
    </tr>
</template>

<script>
    export default {
        name: "TradingHistoryTableItems",
        props: {
            history: {
                type: Object,
                default: () => {}
            }
        },
    }
</script>

<style scoped>

</style>