<template>
  <v-dialog v-model="dialog">
    <div class="mangopay_error-popup-container"
         @click="closePopup"
    >
      <div class="mangopay_error-popup-wrapper" @click.prevent.stop >
        <div class="mangopay_error-popup-header">
          <span class="mangopay_error-popup-header-title">We will be back soon</span>
          <button class="mangopay_error-popup-close-btn"
                  @click="closePopup"
          >
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5656 9.43466C20.2839 9.1507 19.9005 8.99097 19.5005 8.99097C19.1006 8.99097 18.7172 9.1507 18.4355 9.43466L15.0004 12.8846L11.5653 9.43466C10.9771 8.84649 10.0234 8.84649 9.43523 9.43466C8.84702 10.0228 8.84702 10.9765 9.43523 11.5646L12.8853 14.9996L9.43523 18.4345C9.15125 18.7162 8.99152 19.0995 8.99152 19.4995C8.99152 19.8994 9.15125 20.2828 9.43523 20.5645C9.71689 20.8484 10.1003 21.0082 10.5003 21.0082C10.9002 21.0082 11.2836 20.8484 11.5653 20.5645L15.0004 17.1145L18.4355 20.5645C18.7172 20.8484 19.1006 21.0082 19.5005 21.0082C19.9005 21.0082 20.2839 20.8484 20.5656 20.5645C20.8495 20.2828 21.0093 19.8994 21.0093 19.4995C21.0093 19.0995 20.8495 18.7162 20.5656 18.4345L17.1155 14.9996L20.5656 11.5646C20.8495 11.283 21.0093 10.8996 21.0093 10.4996C21.0093 10.0997 20.8495 9.71631 20.5656 9.43466ZM25.6057 4.39473C19.7493 -1.46464 10.2515 -1.46464 4.39351 4.39313C-1.46451 10.2509 -1.4645 19.7482 4.39352 25.606C10.2507 31.4645 19.7485 31.4645 25.6065 25.6068C31.4645 19.749 31.4645 10.2517 25.6065 4.39392L25.6057 4.39473ZM23.4906 23.4894C19.4448 27.5395 13.1014 28.168 8.3395 24.9905C3.57756 21.8131 1.72323 15.7146 3.91047 10.4243C6.0977 5.13406 11.7174 2.12535 17.333 3.23806C22.9486 4.35077 26.9962 9.27503 27.0007 14.9996C27.0115 18.1847 25.7476 21.2418 23.4906 23.4894Z"/>
            </svg>
          </button>
        </div>
        <div class="mangopay_error-popup-main">
          <div class="mangopay_error-popup-content">
            <div class="mangopay_error-popup-image">
              <img src="https://cdn.djooky.fun/2021-12-13-14-58-58-mangopay-call-error.png" alt="error image">
            </div>
            <div class="mangopay_error-popup-description">DjookyX is undergoing maintenance, we’ll be back soon!</div>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "MangopayCallErrorPopup",
  props: {

  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    openPopup() {
      this.dialog = true
    },
    closePopup() {
      this.dialog = false
    },
  },
}
</script>

<style scoped>

.mangopay_error-popup-container {
  width: 100%;
  height: 100%;
}

.mangopay_error-popup-wrapper {
  max-width: 750px;
  width: 90%;
  height: 90%;
  margin: 0 auto;
  border-radius: 5px;
}

.mangopay_error-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 16px;
  background: #FF0090;
  border-radius: 5px 5px 0 0;
}

.mangopay_error-popup-header-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
}

.mangopay_error-popup-close-btn {

}

.mangopay_error-popup-close-btn svg {
  fill: white;
  transition: .5s ease;
}

.mangopay_error-popup-close-btn:hover svg {
  fill: #272727;
}

.mangopay_error-popup-main {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 180px;
  background: #FFFFFF;
  border-radius: 0 0 5px 5px;
}

.mangopay_error-popup-image {
  margin: 0 auto 40px auto;
  max-width: 312px;
  max-height: 261px;
}

.mangopay_error-popup-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.mangopay_error-popup-description {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #272727;
}

@media only screen and (max-width: 992px) {
  .mangopay_error-popup-wrapper {
    /*max-width: 650px;*/
  }
  .mangopay_error-popup-main {
    padding: 60px 90px;
  }
}

@media only screen and (max-width: 768px) {
  .mangopay_error-popup-header {
    padding: 13px 12px;
  }
  .mangopay_error-popup-main {
    padding: 45px 60px;
  }
  .mangopay_error-popup-header-title {
    font-size: 18px;
    line-height: 20px;
  }
  .mangopay_error-popup-close-btn svg {
    width: 27px;
  }
  .mangopay_error-popup-description {
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 375px) {
  .mangopay_error-popup-header-title {
    font-size: 15px;
    line-height: 18px;
  }
  .mangopay_error-popup-close-btn svg {
    width: 22px;
  }
  .mangopay_error-popup-main {
    padding: 25px 30px;
  }
}

@media only screen and (max-height: 600px) and (orientation: landscape) {
  .mangopay_error-popup-main {
    padding: 35px 60px;
  }
}

@media only screen and (max-height: 500px) and (orientation: landscape) {
  .mangopay_error-popup-main {
    padding: 30px 60px;
  }
  .mangopay_error-popup-image {
    margin: 0 auto 15px auto;
    width: 50%;
  }
}

@media only screen and (max-height: 425px) and (orientation: landscape) {
  .mangopay_error-popup-main {
    padding: 25px 35px;
  }
  .mangopay_error-popup-header {
    padding: 14px 12px;
  }
  .mangopay_error-popup-close-btn svg {
    width: 25px;
  }
  .mangopay_error-popup-content {
    display: flex;
    justify-content: space-between;
  }
  .mangopay_error-popup-description {
    align-self: center;
    margin-left: 30px;
  }
}



</style>