<template>
  <tr>
    <td data-label="Name" class="left" v-text="item.authorName"></td>
    <td data-label="Amount" class="left" v-text="'€' + price"></td>
    <td data-label="Event type" class="left event-type-val" v-text="financialEventType"></td>
  </tr>
</template>

<script>
import numberFormattingInteger from "../../mixins/numberConverter";

export default {
  name: "ProviderList",
  props: {
    item: Object
  },
  data() {
    return {

    }
  },
  mixins: [numberFormattingInteger],
  computed: {
    financialEventType() {
      return this.item.packageLevel.replaceAll('_', ' ')
    },
    price() {
      return this.numberFormattingInteger(this.item.amount)
    },
  },
  methods: {

  },
  mounted() {

  }
}
</script>

<style scoped>
tr td:first-child {
  width: 60%;
}

tr td {
  border-right: none !important;
}
@media only screen and (max-width: 768px) {
  tr td:first-child {
    width: 100%;
  }
}
</style>