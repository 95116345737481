<template>
  <tbody>
  <FinancialOperationsList
      v-for="(operation, index) in operations"
      :key="index"
      :operation="operation"
  />
  </tbody>
</template>

<script>
import FinancialOperationsList from "./FinancialOperationsList";

export default {
  name: "FinancialOperationsTemplate",
  props: {
    operations: {
      type: Array,
      default: () => []
    }
  },
  components: {FinancialOperationsList}
}
</script>

<style scoped>

</style>