<template>
  <div id="my-content">
    <Header />
    <main id="root">
      <div class="profile">
        <Sidebar />
        <div class="profile-content royalty-section">
          <div
            class="royalty-section__back"
            v-if="getTab === 'create-report'"
            @click="backToRoyaltyPage"
          >
            <svg
              width="27"
              height="10"
              viewBox="0 0 27 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.9277 5.51286H2.17402L5.74799 9.08683L4.94494 9.88988L0 4.94494L4.94494 0L5.74799 0.803047L2.17402 4.37701H26.9277V5.51286Z"
                fill="#272727"
              />
            </svg>
            <p>Back</p>
          </div>

          <div class="royalty-section__header">
            <h1 class="page-title separator">
              {{ getTab !== "create-report" ? "Royalty" : "Royalty report" }}
            </h1>
            <div
              class="royalty__main-btn-wrap"
              v-if="getTab !== 'create-report'"
            >
              <button
                class="btn royalty__main-btn"
                v-if="getTab === 'royalty-reports'"
                @click="openCreateRoyaltyPage"
              >
                Create report
              </button>
              <button
                class="btn royalty__main-btn"
                @click="openRoyaltyPopup"
                v-if="getTab === 'contracts'"
              >
                Add IBAN
              </button>
            </div>
          </div>
          <div
            class="royalty-section-wrapper"
            v-if="getTab !== 'create-report'"
          >
            <div class="royalty-section__tabs">
              <div
                class="contract-tab"
                :style="{
                  color: getTab === 'contracts' ? '#FE0190' : '#5A5A5A',
                  'border-color':
                    getTab === 'contracts' ? '#FE0190' : '#5A5A5A',
                }"
                @click="tabHandler"
              >
                Contracts
              </div>
              <div
                class="royalty-reports-tab"
                :style="{
                  color: getTab === 'royalty-reports' ? '#FE0190' : '#5A5A5A',
                  'border-color':
                    getTab === 'royalty-reports' ? '#FE0190' : '#5A5A5A',
                }"
                @click="tabHandler"
              >
                Royalty reports
              </div>
            </div>
            <div class="royalty-wrapper" v-if="loadFlag">
              <v-simple-table
                v-if="getTab === 'contracts'"
                class="royalty-table"
              >
                <template v-slot:default>
                  <thead class="royalty-table__header">
                    <tr>
                      <th class="text-left">
                        Author
                      </th>
                      <th class="text-left">
                        Song
                      </th>
                      <th class="text-left">
                        Company
                      </th>
                      <th class="text-left">
                        IBAN
                      </th>
                      <th class="text-left">
                        BIC
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="royaltyList.length">
                    <tr v-for="(item, index) in royaltyList" :key="index">
                      <td>{{ item.authorPublicName }}</td>
                      <td>{{ item.songName }}</td>
                      <td>{{ item.rentingCompanyName }}</td>
                      <td :id="`royalty__iban-copied-${index}`">
                        {{ item.bankingAlias.IBAN }}
                        <button
                          class="copy-icon"
                          @click="copyIban('iban', index)"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_10734_16900)">
                              <path
                                d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
                                stroke="#FE0190"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M3.33337 9.99998H2.66671C2.31309 9.99998 1.97395 9.8595 1.7239 9.60946C1.47385 9.35941 1.33337 9.02027 1.33337 8.66665V2.66665C1.33337 2.31302 1.47385 1.97389 1.7239 1.72384C1.97395 1.47379 2.31309 1.33331 2.66671 1.33331H8.66671C9.02033 1.33331 9.35947 1.47379 9.60952 1.72384C9.85956 1.97389 10 2.31302 10 2.66665V3.33331"
                                stroke="#FE0190"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_10734_16900">
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </button>
                      </td>
                      <td :id="`royalty__bic-copied-${index}`">
                        {{ item.bankingAlias.BIC }}
                        <button
                          class="copy-icon"
                          @click="copyIban('bic', index)"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_10734_16900)">
                              <path
                                d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
                                stroke="#FE0190"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M3.33337 9.99998H2.66671C2.31309 9.99998 1.97395 9.8595 1.7239 9.60946C1.47385 9.35941 1.33337 9.02027 1.33337 8.66665V2.66665C1.33337 2.31302 1.47385 1.97389 1.7239 1.72384C1.97395 1.47379 2.31309 1.33331 2.66671 1.33331H8.66671C9.02033 1.33331 9.35947 1.47379 9.60952 1.72384C9.85956 1.97389 10 2.31302 10 2.66665V3.33331"
                                stroke="#FE0190"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_10734_16900">
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody class="royalty-table__tbody" v-else>
                    <div class="royalty-table__v-else">
                      There are currently no contracts
                    </div>
                  </tbody>
                </template>
              </v-simple-table>
              <v-simple-table
                v-if="getTab === 'royalty-reports'"
                class="royalty-table"
              >
                <template v-slot:default>
                  <thead class="royalty-table__header">
                    <tr>
                      <th class="text-left">
                        Name
                      </th>
                      <th class="text-left">
                        Date
                      </th>
                      <th class="text-left">
                        Artist
                      </th>
                      <th class="text-left">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="royaltyReportsList.length">
                    <tr
                      v-for="(item, index) in royaltyReportsList"
                      :key="index"
                      class="royalty-item"
                    >
                      <td>{{ item.reportName }}</td>
                      <td>
                        {{
                          `${item.royaltyPeriodFrom}-${item.royaltyPeriodTo}`
                        }}
                      </td>
                      <td>{{ item.publicNames[0] }}</td>
                      <td class="status-column">
                        <!--                      <img src="../../../assets/img/" alt="">-->
                        <div class="">
                          <img
                            class="status-column__state"
                            :src="
                              require(`../../../assets/img/${
                                item.status !== 'PROCESSING'
                                  ? 'success-simple-pink.svg'
                                  : 'processing-pink.svg'
                              }`)
                            "
                            alt="djookyx"
                          />
                          <p class="status-column__text">{{ item.status }}</p>
                        </div>
                        <div class="">
                          <a
                            class="status-column__print"
                            :href="item.linkReport"
                            target="_blank"
                          >
                            <img
                              src="../../../assets/img/print.svg"
                              alt="djookyx"
                            />
                          </a>
                          <img
                            class="status-column__delete"
                            src="../../../assets/img/trash-2.svg"
                            alt="djookyx"
                            @click="deleteReport(item.reportId)"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody class="royalty-table__tbody" v-else>
                    <div class="royalty-table__v-else">
                      There are currently no reports
                    </div>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
            <div class="preloader-wrapper" v-else>
              <v-progress-circular
                :size="75"
                :width="8"
                color="#FF0090"
                indeterminate
              ></v-progress-circular>
            </div>
            <b-pagination-nav
              v-if="true"
              class="pagination"
              v-model="paginationPage"
              :total-rows="count"
              :per-page="pageSize"
              :link-gen="linkGen"
              :number-of-pages="count"
              prev-text="«"
              next-text="»"
              first-number
              last-number
              use-router
            ></b-pagination-nav>
          </div>
          <RoyaltyReport
            v-if="getTab === 'create-report'"
            @error="reportError"
          />

          <transition name="fade" tag="span">
            <span v-if="copyMessage" class="copied-message">Copied</span>
          </transition>
        </div>
      </div>

      <RoyaltyAddNewContract
        ref="royaltyAddNewContract"
        :author-list="formatAuthorList"
        :song-list="formatSongList"
        :company-list="formatCompanyList"
        @setChanges="setContractData"
        @confirmAction="createNewIban"
        @changeDialogStatus="changeDialogStatus"
      />

      <ServerErrorPopup
        ref="serverErrorPopup"
        :errorMessage="error.errorMessage"
        :errorTitle="error.errorTitle"
      />
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/Header";
import Sidebar from "@/components/profile/Sidebar";
import Footer from "@/components/footer/Footer";
import ServerErrorPopup from "@/components/popups/ServerErrorPopup";
// import RoyaltyAddNewCompany from "@/components/popups/RoyaltyAddNewCompany";
import RoyaltyAddNewContract from "@/components/popups/RoyaltyAddNewContract";
import RoyaltyReport from "@/components/profile/royalty/components/RoyaltyReport";

export default {
  name: "RoyaltyPage",
  components: {
    Header,
    Sidebar,
    Footer,
    ServerErrorPopup,
    // RoyaltyAddNewCompany,
    RoyaltyAddNewContract,
    RoyaltyReport,
  },
  data() {
    return {
      loadFlag: true,
      copyMessage: false,
      royaltyList: [],
      royaltyReportsList: [],
      qwerty: 0,
      paginationPage: 1,
      count: 1,
      pageSize: 10,
      popupLists: {
        authorList: [],
        songList: [],
        companyList: [],
      },
      error: {
        errorMessage: "",
        errorTitle: "",
      },
      tab: "contracts",
      page: "royalty",
    };
  },
  computed: {
    getTab() {
      return this.$route.query.royaltyTab;
    },
    formatAuthorList() {
      const income = this.popupLists.authorList;
      return (
        income.map((item) => {
          return {
            name: item.name,
            value: item.value,
          };
        }) || []
      );
    },
    formatSongList() {
      const income = this.popupLists.songList;
      return (
        income.map((item) => {
          return {
            name: item.name,
            value: item.value,
          };
        }) || []
      );
    },
    formatCompanyList() {
      const income = this.popupLists.companyList;
      return (
        income.map((item) => {
          return {
            name: item.name,
            value: item.id,
          };
        }) || []
      );
    },
  },
  methods: {
    deleteReport(id) {
      return this.$load(
        async () => {
          await this.$api.royalty.deleteReport(id);
          await this.requestContractsAndReportsList();
        },
        (error) => {
          this.error.errorMessage = error.data.errors[0].message;
          this.$refs.serverErrorPopup.openPopups();
        }
      );
    },
    backToRoyaltyPage() {
      this.$router.push({
        path: "/profile/royalty",
        query: { paginationPage: "1", royaltyTab: "contracts" },
      });
    },
    openCreateRoyaltyPage() {
      this.$router.push({
        path: "/profile/royalty",
        query: { paginationPage: "1", royaltyTab: "create-report" },
      });
    },
    reportError(data) {
      this.error.errorMessage = data;
      this.$refs.serverErrorPopup.openPopups();
    },
    linkGen(pageNum) {
      // return pageNum === 1 ? '?' : `?page=${pageNum}`
      return {
        path: "/profile/royalty",
        query: {
          paginationPage: pageNum,
          royaltyTab: this.$route.query.royaltyTab,
        },
      };
    },
    changeDialogStatus(data) {
      if (!data && this.$refs) {
        this.popupLists.authorList = [];
        this.popupLists.songList = [];
        this.popupLists.companyList = [];
        const refItem = this.$refs["royaltyAddNewContract"];
        refItem.$v.$reset();
        refItem.$refs["ibanPopupAuthorList"].resetSelect();
        refItem.$refs["ibanPopupSongList"].resetSelect();
        refItem.$refs["ibanPopupCompanyList"].resetSelect();
      }
    },
    setContractData(data, type) {
      if (data && type === "author") {
        this.requestSongList(data);
      }
    },
    copyIban(type, index) {
      const copiedText = document.getElementById(
        `royalty__${type}-copied-${index}`
      ).textContent;
      navigator.clipboard.writeText(copiedText).then(() => {
        if (this.copyMessage) {
          return;
        }
        this.copyMessage = true;
        let timeId = setTimeout(() => {
          this.copyMessage = false;
        }, 1500);
      });
    },
    copyBic(index) {
      let copiedText = document.getElementById(`royalty__bic-copied-${index}`)
        .textContent;
      navigator.clipboard.writeText(copiedText).then(() => {
        if (this.copyMessage) {
          return;
        }
        this.copyMessage = true;
        let timeId = setTimeout(() => {
          this.copyMessage = false;
        }, 1500);
      });
    },
    async openRoyaltyPopup() {
      await this.requestAuthorList();
      await this.requestCompanyList();
      this.$refs.royaltyAddNewContract.openPopup();
    },
    createNewIban(data, id) {
      this.$load(
        async () => {
          let res = (await this.$api.royalty.createNewRoyalty(data)).data.body;
        },
        (error) => {
          this.error.errorMessage = error.data.errors[0].message;
          this.$refs.serverErrorPopup.openPopups();
        }
      );
    },
    requestAuthorList() {
      return this.$load(
        async () => {
          let res = (await this.$api.royalty.getAuthorList()).data.body;
          let arr = [];
          for (const resKey in res.elements) {
            let result = {};
            result.name = res.elements[resKey];
            result.value = resKey;
            arr.push(result);
          }
          this.popupLists.authorList = arr;
        },
        (error) => {
          this.error.errorMessage = error.data.errors[0].message;
          this.$refs.serverErrorPopup.openPopups();
        }
      );
    },
    requestSongList(authorId) {
      return this.$load(
        async () => {
          let res = (await this.$api.royalty.getSongList(authorId)).data.body;
          let arr = [];
          for (const resKey in res.elements) {
            let result = {};
            result.name = res.elements[resKey];
            result.value = resKey;
            arr.push(result);
          }
          this.popupLists.songList = arr;
        },
        (error) => {
          this.error.errorMessage = error.data.errors[0].message;
          this.$refs.serverErrorPopup.openPopups();
        }
      );
    },
    requestCompanyList() {
      return this.$load(
        async () => {
          let res = (await this.$api.royalty.getCompanyList()).data.body;
          this.popupLists.companyList = res.elements;
        },
        (error) => {
          this.error.errorMessage = error.data.errors[0].message;
          this.$refs.serverErrorPopup.openPopups();
        }
      );
    },
    requestContractsAndReportsList() {
      const params = this.getRequestParams(this.paginationPage, this.pageSize);
      if (this.$route.query.royaltyTab === "royalty-reports") {
        return this.$load(
          async () => {
            this.loadFlag = false;
            let res = (await this.$api.royalty.getRoyaltiesReports(params)).data
              .body;
            this.count = res.totalPages;
            this.royaltyReportsList = res.elements;
            this.loadFlag = true;
          },
          (error) => {
            this.error.errorMessage = error.data.errors[0].message;
            this.$refs.serverErrorPopup.openPopups();
          }
        );
      } else {
        this.$load(
          async () => {
            this.loadFlag = false;
            let res = (await this.$api.royalty.getRoyaltyList(params)).data
              .body;
            this.count = res.totalPages;
            this.royaltyList = res.elements;
            this.loadFlag = true;
          },
          (error) => {
            this.error.errorMessage = error.data.errors[0].message;
            this.$refs.serverErrorPopup.openPopups();
          }
        );
      }
    },
    tabHandler() {
      if (this.getTab === "contracts") {
        this.$router.push({
          path: "/profile/royalty",
          query: { paginationPage: "1", royaltyTab: "royalty-reports" },
        });
      } else {
        this.$router.push({
          path: "/profile/royalty",
          query: { paginationPage: "1", royaltyTab: "contracts" },
        });
      }
    },
    getRequestParams(page, pageSize) {
      let params = {};
      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }

      return params;
    },
  },
  watch: {
    "$route.query.paginationPage": {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.paginationPage = newVal;
          this.requestContractsAndReportsList();
          if (this.count < newVal) {
            this.paginationPage = 1;
          }
        } else {
          this.paginationPage = 1;
        }
      },
    },
  },
  mounted() {
    this.paginationPage = this.$route.query.paginationPage || 1;
    this.requestContractsAndReportsList();
  },
};
</script>

<style scoped>
.royalty-section__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.royalty-table__header {
  background: #f5f5f5;
}

.royalty-table__header tr th {
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 17px;
  color: #272727 !important;
}

.royalty-section__back {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  cursor: pointer;
}

.royalty-section__back p {
  margin-bottom: 0;
}

.royalty-section__back svg {
  margin-right: 10px;
}

.royalty-section__tabs {
  display: flex;
  width: 100%;
  margin-bottom: 35px;
}

.contract-tab,
.royalty-reports-tab {
  flex: 1;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 5.4px;
  text-transform: uppercase;
  color: #5a5a5a;
  padding-bottom: 10px;
  border-bottom: 1px solid #5a5a5a;
  cursor: pointer;
}

.no_data-stub {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-right: 1px solid #eaeaea;
  border-left: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  border-radius: 0 0 5px 5px;
  padding: 40px 0 70px 0;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #272727;
  width: 100%;
}

.royalty-section .page-title {
  margin-bottom: 0;
}

.royalty__main-btn-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.royalty__main-btn {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  transition: 0.3s ease;
}

.royalty__main-btn:first-child {
  margin-right: 22px;
}

.btn.royalty__main-btn {
  border-radius: 6px;
  padding: 9px 20px;
}

/*.royalty__main-btn.simple {*/
/*  color: #FF0090;*/
/*}*/

/*.royalty__main-btn.simple:hover {*/
/*  opacity: 0.6;*/
/*}*/

.royalty__table {
  width: 100%;
  overflow-x: scroll;
}

.royalty__table.no_data .royalty__table-header {
  border-radius: 5px 5px 0 0;
}

.royalty__table-header {
  width: 100%;
  min-width: 1200px;
  display: flex;
  align-items: center;
  padding: 17px 0;
  background: #f5f5f5;
  border-radius: 5px;
}

/* .royalty__table-col {
} */

.royalty__table-col.left {
  text-align: left;
}

.royalty__table-col.small-col {
  width: 15%;
}

.royalty__table-col.medium-col {
  width: 20%;
}

.royalty__table-col.large-col {
  width: 30%;
}

.royalty__table {
  margin-bottom: 50px;
}

.royalty__table-col .header-text {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 17px;
  color: #272727;
}

.royalty__table-body {
  width: 100%;
  min-width: 1200px;
}

/* .royalty__table-list {
} */

.royalty__table-list-item {
  display: flex;
  align-items: flex-start;
  padding: 20px 0;
  border-bottom: 1px solid #dedede;
}

.royalty__table-col {
  padding: 0 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.royalty__table-list-item .text {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #272727;
  width: 100%;
}

.royalty__table-list-item .copy-icon {
  transition: 0.3s ease;
  margin-left: 6px;
}

.royalty__table-list-item .copy-icon:hover {
  opacity: 0.6;
}

.copied-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  padding: 10px 20px;
  opacity: 0.7;
  background-color: #272727;
  border-radius: 6px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  color: #fff;
}

.royalty-table__tbody {
  position: relative;
  height: 100px;
  border-radius: 0 0 5px 5px;
}

.royalty-table__v-else {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 500;
  font-size: 16px;
}

.status-column {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 50px !important;
}

.status-text-wrapper img {
  margin-right: 10px;
}

.svgs-wrapper a {
  margin-right: 20px;
}

.royalty-item {
  position: relative;
}

.royalty-item:after {
  position: absolute;
  content: "";
  height: 1px;
  width: calc(100% - 50px);
  background: #dedede;
  bottom: 0;
  left: 0;
}

.status-column__state {
  margin-right: 10px;
}

.status-column__text {
  margin-bottom: 0;
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #272727;
}

.status-column__delete {
  margin-left: 20px;
  cursor: pointer;
}

/* animation  */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: none !important;
}

/* end animation  */

@media only screen and (max-width: 768px) {
  .royalty__main-btn-wrap {
    justify-content: flex-start;
  }
}
</style>
