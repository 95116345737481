<template>
  <div id="my-content" ref="top">
    <Header/>
    <main id="root">

      <div class="profile">
        <Sidebar/>

        <div class="profile-content" v-if="preloadFlag">

          <div class="profile-my-music-header" :class="songs.length? 'songs-not-empty': 'songs-empty'">
            <span v-if="songs.length === 0" class="my-songs-no-data empty">No data available at the moment</span>
            <div class="profile-my-music-add-song-wrap">

              <div class="profile-my-music-add-song" @click="checkUploadSongAction">
                <div class="profile-my-music-add-song-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68">
                    <path id="button"
                          d="M34,0A34,34,0,1,0,68,34,34.038,34.038,0,0,0,34,0ZM48.875,36.833H36.833V48.875a2.833,2.833,0,1,1-5.666,0V36.833H19.125a2.833,2.833,0,0,1,0-5.666H31.167V19.125a2.833,2.833,0,1,1,5.666,0V31.167H48.875a2.833,2.833,0,0,1,0,5.666Zm0,0"
                          fill="#7ac385"></path>
                  </svg>
                </div>
                <span class="profile-my-music-add-song-text">Add New Song</span>
              </div>
            </div>
          </div>

          <MySongTemplate
              :songs="songs"
              :preloadFlag="preloadFlag"
              :status="KYCStatus"
              @statusFailed="statusFailedFunc"
              @updateSongImage="checkSongs"
          />

          <b-pagination-nav
              v-if="songs.length !== 0"
              class="pagination"
              v-model="page"
              :total-rows="count"
              :per-page="pageSize"
              :link-gen="linkGen"
              :number-of-pages="count"
              prev-text="«"
              next-text="»"
              first-number
              last-number
              use-router
          ></b-pagination-nav>

        </div>
        <div class="preloader-wrapper profile-preloader" v-else>
          <v-progress-circular
              :size="75"
              :width="8"
              color="#FF0090"
              indeterminate
          ></v-progress-circular>
        </div>

      </div>
      <verification-message-popup ref="verificationPopup"/>
      <ServerErrorPopup ref="serverErrorPopup"
                        :errorMessage="error.errorMessage"
                        :errorTitle="error.errorTitle"
      />
    </main>
    <Footer/>
  </div>
</template>

<script>
import {debounce} from "lodash";
import Sidebar from "./Sidebar";
import MySongTemplate from "./MySongTemplate";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import VerificationMessagePopup from "../popups/VerificationMessagePopup";
import ServerErrorPopup from "../popups/ServerErrorPopup";
import scrollToElement from "@/mixins/scrollToElement";

export default {
  name: "MySong",
  data: () => ({
    // environment: process.env.VUE_APP_BASE_URL,
    // localHost: false,
    songs: [],
    page: 1,
    count: null,
    pageSize: 4,
    preloadFlag: false,
    KYCStatus: '',
    error: {
      errorMessage: '',
      errorTitle: '',
    },
    checkUploadSong: {},
  }),
  mixins: [scrollToElement],
  methods: {
    statusFailedFunc() {
      this.$refs.verificationPopup.openPopups()
    },
    linkGen(pageNum) {
      return {
        path: '/profile/my-songs',
        query: {page: pageNum}
      }
    },
    getRequestParams(page, pageSize) {
      let params = {};


      if (page) {
        params["page"] = page - 1;
      }

      if (pageSize) {
        params["size"] = pageSize;
      }

      return params;
    },
    checkSongs() {
      const params = this.getRequestParams(
          this.page,
          this.pageSize
      );

      this.$load(async () => {
        this.preloadFlag = false
        const res = (await this.$api.profileSongs.getAll(params)).data.body
        this.KYCStatus = (await this.$api.kyc.getAuthStatus()).data.body
        this.songs = res.elements
        this.count = res.totalPages
        this.preloadFlag = true
      })

    },
    checkUploadSongInfo() {
      this.$load(async () => {
        await this.$api.profileSongs.checkUploadSong().then(res => {
          this.checkUploadSong = res.data.body
        })
      }, error => {
        this.error.errorMessage = error.data.errors[0].message
        this.$refs.serverErrorPopup.openPopups()
      })
    },
    checkUploadSongAction() {
      if (this.checkUploadSong.accessForUploadSong) {
        this.$router.push({path: '/profile/create-song'}).catch(()=>{})
        this.scrollToId('my-content')
      } else {
        this.error.errorTitle = 'Oops'
        // this.error.errorMessage = `${this.checkUploadSong.errorMessage} <br> go to <a href="${this.addQueryForRedirectUrl('profile', 'socialRef')}">your profile</a>`
        let url = this.checkUploadSong.redirectUrl
        if (url.includes('associated')) {
          this.error.errorMessage = `${this.checkUploadSong.errorMessage} <br> <a href="${url}">to the page</a>`
          this.$refs.serverErrorPopup.openPopups()
          return
        }

        this.error.errorMessage = `${this.checkUploadSong.errorMessage} <br> <a href="${this.redirectUrlWithQuery(url, 'socialRef')}">to the page</a>`
        this.$refs.serverErrorPopup.openPopups()
        // this.error.errorTitle = 'Oops'
        // this.error.errorMessage = `${this.checkUploadSong.errorMessage} <br> go to <a href="${this.addQueryForRedirectUrl('profile', 'socialRef')}">your profile</a>`
        // this.$refs.serverErrorPopup.openPopups()
      }
    },
    redirectUrlWithQuery(url, scrollTo) {
      if (url && scrollTo) {
        return url + '?' + scrollTo
      }
    },
    // addQueryForRedirectUrl(page, goTo) {
    //   let url = this.checkUploadSong.redirectUrl
    //   let pageStr = page.toString()
    //   if (url.includes(pageStr)) {
    //     // for local debugging, link from backend does not contain a local link
    //     let urlEnding = '?' + goTo
    //     let urlMain = url + urlEnding
    //     if (this.localHost) {
    //       let local = this.environment
    //       let localToHttp = local.replace(/^(https:)/g, 'http:')
    //       let localUrl = localToHttp + pageStr + '/' + urlEnding
    //       return localUrl
    //     } else {
    //       return urlMain
    //     }
    //   }
    // },
    // checkLocalHost() {
    //   this.environment.includes('localhost') ? this.localHost = true : this.localHost = false
    // },
  },
  watch: {
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if(newVal) {
          this.page = newVal
          this.debounceName()
          this.scrollToId('my-content')
        } else {
          this.page = 1
        }
      }
    }
  },
  mounted() {
    document.title = 'My songs'
    this.page = this.$route.query.page || 1
    this.checkSongs()
    this.checkUploadSongInfo()
    this.debounceName = debounce(this.checkSongs, 300);
    // this.checkLocalHost()

    setTimeout(() => {
      this.scrollToId('my-content')
    }, 500)
  },
  components: {
    Footer,
    Header,
    MySongTemplate,
    Sidebar,
    VerificationMessagePopup,
    ServerErrorPopup,
  }
}
</script>

<style scoped>

</style>