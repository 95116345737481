<template>
  <div class="ieo-sidebar-filter">

    <div class="ieo-sidebar-filter-item">
      <span class="ieo-sidebar-filter-title">number of songs</span>
      <div class="filter-price-wrap">
        <input
            type="number"
            id="filter-songs-num-from"
            class="filter-price-input"
            v-model="numOfSongsFromModel"
            name="songsNumFrom"
            placeholder="From..."
            @blur="$v.numOfSongsFromModel.$touch()"
            @keyup.enter="applyData"
        >
        <input
            type="number"
            id="filter-songs-num-to"
            class="filter-price-input"
            v-model="numOfSongsToModel"
            name="songsNumTo"
            placeholder="To..."
            @blur="$v.numOfSongsToModel.$touch()"
            @keyup.enter="applyData"
        >
      </div>
      <div class="error-filter" v-if="$v.numOfSongsFromModel.$dirty && !$v.numOfSongsFromModel.between">
        Must be between {{$v.numOfSongsFromModel.$params.between.min}} and {{$v.numOfSongsFromModel.$params.between.max}}
      </div>
      <div class="error-filter" v-else-if="$v.numOfSongsToModel.$dirty && !$v.numOfSongsToModel.between">
        Must be between {{$v.numOfSongsToModel.$params.between.min}} and {{$v.numOfSongsToModel.$params.between.max}}
      </div>
    </div>

    <div class="ieo-sidebar-filter-item">
      <span class="ieo-sidebar-filter-title">subscribers</span>
      <div class="filter-price-wrap">
        <input
            type="number"
            id="filter-subscribers-from"
            class="filter-price-input"
            v-model="subscribersFromModel"
            name="subscribersNumFrom"
            placeholder="From..."
            @blur="$v.subscribersFromModel.$touch()"
            @keyup.enter="applyData"
        >
        <input
            type="number"
            id="filter-subscribers-to"
            class="filter-price-input"
            v-model="subscribersToModel"
            name="subscribersNumTo"
            placeholder="To..."
            @blur="$v.subscribersToModel.$touch()"
            @keyup.enter="applyData"
        >
      </div>
      <div class="error-filter" v-if="$v.subscribersFromModel.$dirty && !$v.subscribersFromModel.between">
        Must be between {{$v.subscribersFromModel.$params.between.min}} and {{$v.subscribersFromModel.$params.between.max}}
      </div>
      <div class="error-filter" v-else-if="$v.subscribersToModel.$dirty && !$v.subscribersToModel.between">
        Must be between {{$v.subscribersToModel.$params.between.min}} and {{$v.subscribersToModel.$params.between.max}}
      </div>
    </div>

    <div class="ieo-sidebar-filter-item">
      <span class="ieo-sidebar-filter-title">genres</span>
      <vuescroll :ops="ops">
        <ul class="ieo-sidebar-genre-list">
          <li
              v-for="(genre, index) in songGenre"
              :key="index"
              class="custom-checkbox-group">
            <div class="checkbox-wrap">
              <input
                  type="checkbox"
                  :id="`checkbox_${index}`"
                  v-model="selectGenres"
                  :value="genre"
                  class="input-checkbox">
              <label :for="`checkbox_${index}`" class="label-checkbox"><span>{{genre}}</span></label>
            </div>
          </li>
        </ul>
      </vuescroll>
    </div>
    <div class="ieo-sidebar-filter-item registration-field-wrap">
      <span class="ieo-sidebar-filter-title">Country</span>
      <CountrySelect class="sidebar-filter__select"
                     :options="searchedCountries"
                     :placeholder="'Search'"
                     :search="searchSelect"
                     :countryDjkAwards="countryModel"
                     @changed="setCountry"
                     @searchCheck="searchCheck"
                     name="countryId"
                     ref="AristsFilterCountrySelect"
                     :identifier="'artist-filter-select'"
      />
    </div>
    <button @click="applyData" type="button" class="btn btn-hover filter-apply-btn">Apply</button>
    <div @click="resetData" class="btn btn-hover btn-transparent btn-reset-filter">Reset</div>
  </div>

</template>

<script>
import vuescroll from "vuescroll";
import CountrySelect from "../inputs/CountrySelect";
import {between} from "vuelidate/lib/validators";
import {debounce} from "lodash";


export default {
  name: "ArtistFilter",
  props: {
    // logged: Boolean,
  },
  data() {
    return {
      // status: '',
      numOfSongsFromModel: '',
      numOfSongsToModel: '',
      subscribersFromModel: '',
      subscribersToModel: '',
      countryModel: null,
      songGenre: [],
      selectGenres: [],
      countriesList: [],
      focus: false,
      searchSelect: '',
      dataObj: {
        songsFrom: null,
        songsTo: null,
        subscribersFrom: '',
        subscribersTo: '',
        genres: [],
        countryId: null,
        // sortDateBy: false,
      },
      ops: {
        vuescroll: {},
        scrollPanel: {
          initialScrollY: false,
        },
        rail: {
          background: 'rgba(234, 234, 234)',
          opacity: 0.5,
          size: '6px',
          specifyBorderRadius: false,
          gutterOfEnds: null,
          gutterOfSide: '2px',
          keepShow: true
        },
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: true,
          keepShow: false,
          background: '#FF0090',
          opacity: 1,
          hoverStyle: false,
          specifyBorderRadius: false,
          minSize: 0.3,
          size: '6px',
          disable: false
        }
      },
    }
  },
  validations() {
    return {
      numOfSongsFromModel: {
        between: between(0, 10000),
      },
      numOfSongsToModel: {
        between: between(0, 10000),
      },
      subscribersFromModel: {
        between: between(0, 1000000000),
      },
      subscribersToModel: {
        between: between(0, 1000000000),
      },
    }
  },
  computed: {
    searchedCountries() {
      return this.countriesList.filter(option => option.name.toLowerCase().includes(this.searchSelect.toLowerCase()))
    },
  },
  methods: {
    log() {
      alert('enter')
    },
    resetData() {
      this.numOfSongsFromModel = ''
      this.numOfSongsToModel = ''
      this.subscribersFromModel = ''
      this.subscribersToModel = ''
      this.countryModel = null
      this.selectGenres = []
      this.applyData()
    },
    applyData() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      } else {
        this.dataObj.songsFrom = this.numOfSongsFromModel
        this.dataObj.songsTo = this.numOfSongsToModel
        this.dataObj.subscribersFrom = this.subscribersFromModel
        this.dataObj.subscribersTo = this.subscribersToModel
        this.dataObj.genres = this.selectGenres
        this.dataObj.countryId = this.countryModel
      }
    },
    getGenres() {
      this.$load(async () => {
        const res = (await this.$api.createSong.getGenres()).data.body.elements;
        this.songGenre = res;
      })
    },
    searchCheck(data) {
      this.searchSelect = data
    },
    setCountry(data) {
      this.countryModel = data.name
    },
    countryClickOutside(event) {
      if (!event.target.closest('.general_select')) {
        this.$refs.AristsFilterCountrySelect.closeList()
      }
    },
  },
  watch: {
    dataObj: {
      deep: true,
      handler(newVal) {
        this.$emit('filterData', newVal)
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.countryClickOutside)
    setTimeout(() => {
      this.getGenres()
      this.$load(async () => {
        this.countriesList = (await this.$api.countriesLists.getCountries()).data.body.elements
      })
    }, 500)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.countryClickOutside)
  },
  components: {
    vuescroll,
    CountrySelect,
  }
}
</script>

<style>

.error-filter {
  color: red;
  font-size: 15px;
}

.ieo-sidebar-genre-list {
  max-height: 300px;
}

.custom-checkbox-group .label-checkbox {
  cursor: pointer;
  position: relative;
  width: 24px;
  height: 24px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease;
  border: 1px solid #707070;
  border-radius: 1px;
}

.custom-checkbox-group .input-checkbox {
  display: none;
}

.custom-checkbox-group .label-checkbox span {
  position: absolute;
  left: 40px;
  width: 170px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #5A5A5A;
}

.custom-checkbox-group .label-checkbox:after {
  content: '';
  background: #FF0090;
  width: 16px;
  height: 16px;
  border-radius: 1px;
  opacity: 0;
  transition: all .3s ease;
}

.custom-checkbox-group .input-checkbox:checked + .label-checkbox:after {
  opacity: 1;
}

</style>
