<template>
  <div id="my-content" ref="top">
    <Header/>
    <main id="root">
      <section class="section" v-if="loadFlag">
        <div class="container-small">
          <div class="title-container">
            <h1 class="title page-title separator">Privacy Policy</h1>
            <div class="title-time-post"
                 v-if="changePrivacy === 'web'"
            >14 of July, 2020</div>
            <div class="title-time-post"
                 v-if="changePrivacy === 'cookie'"
            >21 of December, 2020</div>
          </div>
          <div class="header-btn-wrap">
            <button class="header-btn-item"
                    @click="changePrivacy = 'web'"
                    :class="{'active-btn': changePrivacy === 'web'}"
            >Website privacy Policy</button>
            <button class="header-btn-item"
                    @click="changePrivacy = 'cookie'"
                    :class="{'active-btn': changePrivacy === 'cookie'}"
            >Cookie Privacy Policy</button>
          </div>
          <div class="static-page-text-wrap"
               v-if="changePrivacy === 'web'"
               v-html="privacyPolicy"
          ></div>
          <div class="static-page-text-wrap"
               v-if="changePrivacy === 'cookie'"
               v-html="cookiePrivacyPolicy"
          >
          </div>
        </div>
      </section>
      <div class="preloader-wrapper" v-else>
        <v-progress-circular
            :size="75"
            :width="8"
            color="#FF0090"
            indeterminate
        ></v-progress-circular>
      </div>
    </main>
    <Footer/>
  </div>
</template>

<script>
import Header from "../header/Header";
import Footer from "../footer/Footer";

export default {
  name: "Policy",
  data() {
    return {
      cookiePrivacyPolicy: null,
      privacyPolicy: null,
      loadFlag: false,
      changePrivacy: 'cookie',
    }
  },
  methods: {
    goTo(refName) {
      let element = this.$refs[refName]
      element.scrollIntoView({behavior: 'smooth'})
    },
  },
  mounted() {
    document.title = 'Privacy policy'
    this.goTo('top')
    this.$load(async () => {
      this.loadFlag = false
      let webResponse = (await this.$api.content.getHTML('privacyPolicy'))
      this.privacyPolicy = webResponse.data.body.value

      let cookieResponse = (await this.$api.content.getHTML('cookiePrivacyPolicy'))
      this.cookiePrivacyPolicy = cookieResponse.data.body.value
      this.loadFlag = true
    })
  },
  components: {Footer, Header}
}
</script>

<style scoped>

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-time-post {
  align-self: flex-start;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #B9B9B9;
}

</style>