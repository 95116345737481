export default function(instance) {
    return {
        getMarket(body, params) {
            return instance.post(`/api/v2/market/page`, body, {params})
        },
        getCandles(body) {
            return instance.post(`/api/v2/candles`, body)
        },
        getLastCandles(body) {
            return instance.post(`/api/v2/candles/last`, body)
        },
        getMarketPricesUnAuth(data) {
            return instance.post(`/api/v2/marketPrices`, data)
        },
        getMarketPricesAuth(data) {
            return instance.post(`/api/v2/marketPrices/authorized`, data)
        },
        getCurrentSongMarketPrice(songId) {
            return instance.get(`/api/v2/marketPrices/${songId}`)
        },
        getCurrentSongPriceChanges(songUuid) {
            return instance.get(`/api/v2/pricesChange24h/${songUuid}`)
        },
        getVolume24h(songUuid) {
            return instance.get(`/api/v2/volume24h/${songUuid}`)
        },
        getHigh24h(songUuid) {
            return instance.get(`/api/v2/marketPrices/high24h/${songUuid}`)
        },
        getLow24h(songUuid) {
            return instance.get(`/api/v2/marketPrices/low24h/${songUuid}`)
        },
        getDjooky24hVolume() {
            return instance.get(`/api/v2/djookyVolume24h`)
        },
        getOrderBook(isAsk, songUuid) {
            return instance.get(`/api/v2/orderBook/${isAsk}/${songUuid}`)
        },
        getHistory(songUuid) {
            return instance.get(`/api/v2/tradeHistory/${songUuid}`)
        },
        getOpenOrders(songUuid) {
            return instance.get(`/api/v2/orders/open/${songUuid}`)
        },
        getSong24hOrders(songUuid) {
            return instance.get(`/api/v2/orders/24h/${songUuid}`)
        },
        getTradeSongInfo(songUuid) {
            return instance.get(`/api/v2/trade/songs/${songUuid}`)
        },
        createLimit(body) {
            return instance.post(`/api/v2/order/create/limit`, body)
        },
        createMarket(body) {
            return instance.post(`/api/v2/order/create/market`, body)
        },
        createStopLimit(body) {
            return instance.post(`/api/v2/order/create/stopLimit`, body)
        },
        setFavorite(songIdPair) {
            return instance.post(`/api/v2/trades/pairs/favorites/${songIdPair}`)
        },
        setUnFavorite(songIdPair) {
            return instance.delete(`/api/v2/trades/pairs/favorites/${songIdPair}`)
        },
        cancelOne(body) {
            return instance.put(`/api/v2/order/cancel`, body)
        },
        cancelAll(songUuid) {
            return instance.put(`/api/v2/order/cancelAll/${songUuid}`)
        },
    }
}

