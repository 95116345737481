<template>
  <v-dialog v-model="dialog">
    <div id="disclaimer-popup-id" class="white-popup popup-form order-popup">
      <div class="form-main-content disclaimer-popup">
        <div class="close-dialog" @click="closeEvent">
          <img src="../../assets/img/close.svg" alt="close-icon">
        </div>
        <div class="popup-header">
          <h3 class="form-title">Disclaimer</h3>
        </div>
        <div class="popup-content ">
          <div class="popup-content-container">

            <div class="popup-content-text">
              <vuescroll
                  :ops="ops"
                  ref="vs"
                  @handle-scroll="handleScroll"
              >
                <p>
                  Nothing contained in this website constitutes an offer to sell or the solicitation of an offer to buy
                  any intellectual property rights (IPR) or any royalty rights (RR) deriving from any IPR, including
                  copyrights, in any jurisdiction where or to any person to whom it is unlawful to make the offer or
                  solicitation in such jurisdiction. The distribution of the RR and the offer or sale of the RR may be
                  restricted by law in certain jurisdictions. Djooky GmbH does not represent that this website and the
                  contents therein may be lawfully made available, or that any RR may be lawfully offered, in compliance
                  with any applicable registration or other requirements in any such jurisdiction, or pursuant to an
                  exemption available thereunder, or assume any responsibility for facilitating any such distribution or
                  offering of RR or any other instrument, right, interest or other asset. In particular, no action has
                  been taken by Djooky GmbH to permit a public offering of any RR or make this website or its contents
                  available, in any jurisdiction where this is not allowed or where this it would requires prior
                  registration or any other action. Accordingly, no RR may be offered or sold, directly or indirectly,
                  and neither this website nor any advertisement or other offering material may be made available,
                  distributed or published in any jurisdiction, except under circumstances that will result in
                  compliance with any applicable laws and regulations. You and any other persons accessing this website
                  must inform yourself/themselves about, and observe, any such restrictions on the distribution of any
                  information contained herein and the offering and sale of any RR. In particular, without limitation,
                  there may be restrictions on making available the information contained in this website and the offer
                  or sale of RR in the United States, the European Economic Area, and the United Kingdom. This website
                  is not intended to offer or to promote the offer or sale of securities in the United States or to U.S.
                  persons, as such terms are defined in Regulation S under the U.S. Securities Act of 1933. Prospective
                  investors should inform themselves as to any applicable legal requirements and taxation and exchange
                  control regulations in the countries of their citizenship, residence, or domicile, whichever might be
                  relevant. In particular, and without limitation, tThis website is not directed at persons in the
                  United Kingdom or United States. Any communications contained within this website must not be acted
                  upon by persons in the United Kingdom or United States. This website is not directed at any person in
                  any jurisdiction where such direction would be unlawful or subject this website to regulation,
                  including registration or licensing. Any communications contained within this website must not be
                  acted upon by persons in any such jurisdiction. By your accessing this website, you represent and
                  warrant that, you will not use any of the information for the purpose of offering or selling or
                  soliciting the sale or offer to purchase any RR any other instrument, right, interest or other asset
                  (or for the purpose of assisting, directly or indirectly, or participating, directly or indirectly, in
                  any such undertaking) to or from any person that would violate the securities or other laws of any
                  jurisdiction. You further represent that you have initiated your use of this website without
                  solicitation from Djooky GmbH. Djooky GmbH does not accept any responsibility whatsoever to
                  individuals or entities providing false information regarding their country of residence and cannot be
                  held responsible for any such false information provided.
                </p>
              </vuescroll>
            </div>

          </div>
        </div>
        <div class="button-wrapper">
          <button type="button" id="sellButtonStopLimit2"
                  class="btn btn-hover"

                  @click="confirmAction"
                  :disabled="!checkBox"
                  :class="{'disabled-btn': !checkBox}"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>
    </div>

  </v-dialog>
</template>

<script>
import vuescroll from 'vuescroll';

export default {
  name: "DisclaimerPopup",
  components: {vuescroll},
  data() {
    return {
      dialog: false,
      checkBox: false,
      scrollFlag: true,
      buttonText: 'Scroll down to accept',
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {
          background: '#FB3099',
          size: '3px'
        }
      }
      // settings: {
      //   suppressScrollY: false,
      //   suppressScrollX: false,
      //   wheelPropagation: true
      // }
    };
  },
  methods: {
    handleScroll(vertical, horizontal, nativeEvent) {
      if (vertical.process >= 0.98) {
        this.checkBox = true
        this.buttonText = 'I have read and agree to the initial sale agreement'
      }
    },
    openPopups() {
      this.dialog = true;
    },
    resetState() {
      this.checkBox = false
      this.buttonText = 'Scroll down to accept'
      this.$refs["vs"].scrollTo(
          {
            y: 0
          },
          0,
          "easeInQuad"
      );
    },
    closeEvent() {
      this.dialog = false
      this.resetState()
    },
    confirmAction() {
      this.$emit('confirmDisclaimer')
      this.resetState()
      this.dialog = false
    },

  }
};
</script>

<style>
.popup-content-text {
  height: 300px;
  margin-bottom: 20px;
  text-align: start;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 31px;
  color: #A6A6A6;
}

/*.popup-content-text:before {*/
/*  content: '';*/
/*  width: 100%;*/
/*  height: 1px;*/
/*  background: #EAEAEA;*/
/*  position: absolute;*/
/*  top: 0;*/
/*  left: 0;*/
/*}.popup-content-text:after {*/
/*  content: '';*/
/*  width: 100%;*/
/*  height: 1px;*/
/*  background: #EAEAEA;*/
/*  position: absolute;*/
/*  bottom: 0;*/
/*  left: 0;*/
/*}*/
.popup-header {
  margin-bottom: 70px;
}

.popup-checkbox {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 50px;
}

.popup-checkbox input {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.button-wrapper {
  padding-bottom: 50px;
}
</style>