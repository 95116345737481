<template>
  <div id="my-content" ref="top">
    <Header/>
    <main id="root">

      <section class="section" v-if="loadFlag">
        <div class="container-small">
          <div class="terms-header-wrapper">
            <h1 class="title page-title separator page-title-pos">Marketplace Terms of Use</h1>
            <span v-text="date"></span>
          </div>

          <div class="header-btn-wrap">
            <button class="header-btn-item"
                    @click="tabFlag = 1"
                    :class="{'active-btn': tabFlag === 1}"
            >DjookyX Terms & Conditions</button>
            <button class="header-btn-item"
                    @click="tabFlag = 2"
                    :class="{'active-btn': tabFlag === 2}"
            >Mangopay Terms & Conditions</button>
          </div>

          <div class="static-page-text-wrap" v-html="termsConditions" v-if="tabFlag === 1"></div>
          <PaymentServiceProviderTermsAndConditions v-else/>
        </div>
      </section>
      <div class="preloader-wrapper" v-else>
        <v-progress-circular
            :size="75"
            :width="8"
            color="#FF0090"
            indeterminate
        ></v-progress-circular>
      </div>

    </main>
    <Footer/>
  </div>
</template>

<script>
import Header from "../header/Header";
import Footer from "../footer/Footer";
import PaymentServiceProviderTermsAndConditions from "./PaymentServiceProviderTermsAndConditions";
import scrollToElement from "@/mixins/scrollToElement";

export default {
  name: "TermsAndConditions",
  data() {
    return {
      termsConditions: null,
      loadFlag: false,
      date: '08 of July, 2021',
      tabFlag: 1
    }
  },
  mixins: [scrollToElement],
  methods: {

  },
  watch: {
    tabFlag(newVal) {
      if(newVal === 1) {
        this.date = '08 of July, 2021'
      } else {
        this.date = '27 of November, 2021'
      }
    }
  },
  mounted() {
    document.title = 'Terms and conditions'
    this.scrollToId('my-content')
    this.$load(async () => {
      this.loadFlag = false
      let response = (await this.$api.content.getHTML('termsConditions'))
      this.termsConditions = response.data.body.value
      this.loadFlag = true
    })
  },
  components: {
    Footer,
    Header,
    PaymentServiceProviderTermsAndConditions,
  }
}
</script>

<style scoped>

.terms-header-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.terms-header-wrapper span {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #B9B9B9;
}

.static-page-text-wrap p, ul li, ol li {
  color: #272727;
}

@media only screen and (max-width: 540px) {
  .page-title-pos {
    width: calc(50% - 8px);
  }

  .terms-header-wrapper span {
    width: calc(50% - 8px);
    text-align: left;
  }
}

</style>