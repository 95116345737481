<template>
  <v-dialog v-model="dialog">
    <div class="type-ieo-popup-server-error white-popup popup-form order-popup success">
      <div class="form-main-content">
        <div class="popup-header popup-header-verify">
          <div class="close-dialog" @click="dialog = false">
            <img src="../../assets/img/close.svg" alt="close-icon">
          </div>
          <h3 class="form-title">Verification issue</h3>
        </div>
        <div class="popup-content">
          <div class="popup-content-container">
            <div class="error-message server-error">You need to <router-link class="complete-verify" :to="{path: '/profile/kyc'}">complete verification</router-link></div>
            <div class="delete-image-btn-wrap">
              <button type="button"
                      class="submit_form_sell btn btn-hover"
                      @click="confirmAction">
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </v-dialog>
</template>

<script>
export default {
  name: "SuccessPopup",
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    errorMessage: String,
  },
  methods: {
    openPopups() {
      this.dialog = true;
    },
    confirmAction() {
      // this.$store.dispatch('alert/clear');
      this.dialog = false
    }
  },
};
</script>

<style>
.complete-verify {
  color: #ff0090;
  font-weight: bold;
}
</style>