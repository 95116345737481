<template>
  <li class="song-winners-item" :style="{color: ieo.winner ? '#FF0090' : '#747474'}">
    <div class="song-winners-item-left">
      <v-tooltip bottom max-width="300">
        <template v-slot:activator="{on}">
          <button
              v-on="on"
          >
            <span class="song-investor-name" v-text="userCutName"></span>
          </button>
        </template>
        <span v-text="userFullName"></span>
      </v-tooltip>
    </div>
    <div class="song-winners-item-right">
      <div class="song-winners-item-right__bid" v-text="'€' + ieoBidIpr"></div>
      <div class="song-winners-item-right__amount" v-text="amount"></div>
    </div>
  </li>
</template>

<script>
import numberFormattingDecimal from "../../mixins/numberCurrencyConverter";
import numberFormattingInteger from "../../mixins/numberConverter";

export default {
  name: "SongWinnersList",
  props: {
    ieo: Object
  },
  data() {
    return {
      // ieoBidIpr: null,
      userFullName: '',
      userCutName: '',
    }
  },
  mixins: [numberFormattingDecimal, numberFormattingInteger],
  computed: {
    ieoBidIpr() {
      return this.numberFormattingDecimal(this.ieo.bidPerIpr)
    },
    amount() {
      return this.numberFormattingInteger(this.ieo.amount)
    }
  },
  methods: {
    checkFirstName() {
      let firstName = this.ieo.firstName
      let lastName = this.ieo.lastName

      if (firstName.length > 8) {
        if (lastName) {
          this.userCutName = firstName.slice(0, 8) + '...' + ' ' + lastName.slice(0, 1) + '.'
        } else {
          this.userCutName = firstName.slice(0, 8)
        }
      } else if (lastName) {
        this.userCutName = firstName + ' ' + lastName.slice(0, 1) + '.'
      } else {
        this.userCutName = firstName
      }
    },
    createFullName() {
      this.userFullName = this.ieo.firstName + ' ' + this.ieo.lastName
    },
  },
  mounted() {
    this.checkFirstName()
    this.createFullName()
  },
}
</script>

<style scoped>
.song-winners-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}
.song-winners-item-right, .song-winners-item-left {
  width: 50%;
}
.song-winners-item-right {
  display: flex;
  justify-content: space-between;
}
.song-winners-item-right__bid, .song-winners-item-right__amount {
  display: flex;
  width: 50%;
}
.song-winners-item-right__bid {
  justify-content: center;
}
.song-winners-item-right__amount {
  justify-content: flex-end;
}
</style>