<template>
  <div id="my-content">
    <Header/>
    <main id="root">
      <div class="profile">
        <Sidebar/>
        <div class="profile-content">

          <h1 class="title page-title separator">ARTISTS</h1>

          <div class="associated_artists__content" v-if="loadFlag">
            <CompanyAssociatedArtistsList
                :artists="artists"
                :loadFlag="loadFlag"
                :artistType="artistType"
                :addBtnFlag="page === 1"
                @addArtist="addArtist"
                @editArtist="editArtist"
            />
            <b-pagination-nav
                v-if="count > 1"
                class="pagination"
                v-model="page"
                :total-rows="count"
                :per-page="pageSize"
                :link-gen="linkGen"
                :number-of-pages="count"
                prev-text="«"
                next-text="»"
                first-number
                last-number
                use-router
            ></b-pagination-nav>
          </div>

          <div class="preloader-wrapper" v-else>
            <v-progress-circular
                :size="75"
                :width="8"
                color="#FF0090"
                indeterminate
            ></v-progress-circular>
          </div>

        </div>
      </div>

      <verification-message-popup ref="KYCPopup"/>

      <ServerErrorPopup
          ref="serverErrorPopup"
          :errorMessage="error.errorMessage"
          :errorTitle="error.errorTitle"
      />

    </main>
    <Footer/>
  </div>
</template>

<script>

import Header from "@/components/header/Header";
import Sidebar from "@/components/profile/Sidebar";
import Footer from "@/components/footer/Footer";
import ServerErrorPopup from "@/components/popups/ServerErrorPopup";
import verificationMessagePopup from "@/components/popups/VerificationMessagePopup";
import {debounce} from "lodash";
import scrollToElement from "@/mixins/scrollToElement";
import CompanyAssociatedArtistsList from "@/components/company/CompanyAssociatedArtistsList";


export default {
  name: "CompanyAssociatedArtists",
  data() {
    return {
      artistType: 'associated-artist',
      artists: [],
      KYCStatus: '',
      page: 1,
      count: null,
      pageSize: 8,
      loadFlag: false,
      error: {
        errorMessage: '',
        errorTitle: '',
      },
    }
  },
  mixins: [scrollToElement],
  computed: {
    checkArtistLength() {
      return this.artists.length > 0
    },
  },
  methods: {
    linkGen(pageNum) {
      return {
        path: '/profile/associated-artists',
        query: {page: pageNum}
      }
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }

      return params;
    },
    getArtists() {
      const params = this.getRequestParams(
          this.page,
          this.pageSize
      );
      this.$load(async () => {
        this.loadFlag = false
        const res = await this.$api.associatedArtists.getAssociatedArtists(params)
        this.KYCStatus = (await this.$api.kyc.getAuthStatus()).data.body
        this.artists = res.data.body.elements
        this.count = res.data.body.totalPages
        this.loadFlag = true
      }, error => {
        this.error.serverError = error.data.errors[0].message
        this.$refs.serverErrorPopup.openPopups()
      })
    },
    addArtist() {
      if (this.KYCStatus === "APPROVED") {
        this.$router.push({path: '/profile/associated-artists/create-artist'}).catch(()=>{})
        return
      }

      this.$refs.KYCPopup.openPopups()
    },
    editArtist(uuid) {
      if (this.KYCStatus === "APPROVED") {
        this.$router.push({path: `/profile/associated-artists/edit-artist/${uuid}`}).catch(()=>{})
        return
      }

      this.$refs.KYCPopup.openPopups()
    },
  },
  watch: {
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.loadFlag = true
          const val = Number(newVal)
          this.page = val

          if (val === 1) {
            this.pageSize = 8
          } else {
            this.pageSize = 9
          }

          this.debounceName()
          this.loadFlag = false
          this.scrollToId('my-content')
        } else {
          this.page = 1
        }
      }
    },
  },
  mounted() {
    document.title = 'Associated Artists'

    const pageNum = Number(this.$route.query.page)
    if (pageNum !== 1) {
      this.pageSize = 9
    }
    this.page = pageNum || 1

    this.getArtists()
    this.debounceName = debounce(this.getArtists, 300)

    setTimeout(() => {
      this.scrollToId('my-content')
    }, 500)
  },
  components: {
    Header,
    Sidebar,
    Footer,
    ServerErrorPopup,
    verificationMessagePopup,
    CompanyAssociatedArtistsList,
  },

}
</script>

<style scoped>

</style>