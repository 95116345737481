import instance from "../api/instance";

export const userTabs = {
    namespaced: true,
    state: {
        tabs: {},
    },
    getters: {
        getTabs(state) {
            return state.tabs
        },
    },
    mutations: {
        SET_TABS(state, payload) {
            state.tabs = payload
        },
        DELETE_TABS(state) {
            state.tabs = null
        }
    },
    actions: {
        async setTabs({commit}) {
            let res = await instance.get('/api/v2/view/tabs')
            let tabs = res.data.body
            localStorage.setItem('tabs', JSON.stringify(tabs))
            commit('SET_TABS', tabs)
        },
        deleteTabs({commit}) {
            commit('DELETE_TABS')
        }
    }
}