<template>
  <div id="my-content" ref="top">
    <Header/>
    <main id="root">
      <div class="profile">
        <Sidebar/>
        <div class="profile-content agreements">

          <button class="back-btn"
                  @click="btnBack"
                  v-if="contractListFlag"
          >
            <svg width="28" height="10" viewBox="0 0 28 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M27.1699 5.51286H2.41621L5.99017 9.08683L5.18712 9.88988L0.242188 4.94494L5.18712 0L5.99017 0.803047L2.41621 4.37701H27.1699V5.51286Z"/>
            </svg>
            <span class="back-btn-text">Back</span>
          </button>

          <h1 class="title page-title separator title-investors">Agreements</h1>

          <div class="list-container" v-if="!contractListFlag">
            <div class="tab-wrapper">

              <button class="tab-content"
                      @click="toggleAgreementType('initial')"
                      :class="{'active': agreementTabFlag === 'initial'}"
              >Initial sale
              </button>

              <button class="tab-content"
                      @click="toggleAgreementType('secondary')"
                      :class="{'active': agreementTabFlag === 'secondary'}"
              >Secondary market
              </button>

            </div>

            <div class="content-wrapper" v-if="loadFlag">

              <div class="agreements-tab" v-if="agreementTabFlag === 'initial'">
                <div class="agreements__user_type-wrap">
                  <button class="agreements__user_type-btn"
                          type="button"
                          @click="togglePrimaryType('investor')"
                          :class="{'active': tabFlag === 'investor'}"
                  >Investor
                  </button>

                  <button class="agreements__user_type-btn"
                          type="button"
                          @click="togglePrimaryType('artist')"
                          :class="{'active': tabFlag === 'artist'}"
                  >Artist
                  </button>
                </div>
                <!--              @documentAccepted="documentAccepted"-->
                <Investor
                    :ieos="ieosInvestor"
                    v-if="tabFlag === 'investor'"
                />
                <Contract
                    :ieosArtist="ieosArtist"
                    v-if="tabFlag === 'artist'"
                />
              </div>

              <div class="agreements-tab" v-if="agreementTabFlag === 'secondary'">

                <div class="agreements__secondary-table" v-if="screenWidthFlag === 'desktop'">
                  <div class="table-header">
                    <div
                        class="table-header__col large-col"
                        @click="sortBy('sortedByToken', 'sortedByTokenToggle', 'fullCurrencyName', 'token','string')"
                    >
                      <span class="table-header--text">Token</span>
                      <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_10984_18097)">
                          <path
                              d="M2.35818 0.884691L0.417134 4.12511C0.377114 4.19441 0.355939 4.27299 0.355715 4.35302C0.355491 4.43305 0.376226 4.51174 0.415857 4.58127C0.455488 4.6508 0.512633 4.70874 0.581609 4.74932C0.650584 4.78991 0.728984 4.81173 0.809009 4.81261H4.69109C4.77112 4.81173 4.84952 4.78991 4.91849 4.74932C4.98747 4.70874 5.04461 4.6508 5.08424 4.58127C5.12388 4.51174 5.14461 4.43305 5.14439 4.35302C5.14416 4.27299 5.12299 4.19441 5.08297 4.12511L3.14193 0.884691C3.10107 0.817341 3.04355 0.761656 2.97491 0.723011C2.90627 0.684365 2.82882 0.664062 2.75005 0.664062C2.67128 0.664063 2.59383 0.684365 2.52519 0.723011C2.45655 0.761656 2.39903 0.817341 2.35818 0.884691Z"
                              :fill="sortData.sortedByToken && sortData.sortedByTokenToggle ? '#FE0190' : '#868686'"
                          />
                        </g>
                        <g clip-path="url(#clip1_10984_18097)">
                          <path
                              d="M3.14182 10.1153L5.08287 6.87489C5.12289 6.80559 5.14406 6.72701 5.14429 6.64698C5.14451 6.56695 5.12377 6.48826 5.08414 6.41873C5.04451 6.3492 4.98737 6.29126 4.91839 6.25068C4.84942 6.21009 4.77102 6.18827 4.69099 6.18739L0.808908 6.18739C0.728883 6.18827 0.650483 6.21009 0.581508 6.25068C0.512533 6.29126 0.455387 6.3492 0.415756 6.41873C0.376125 6.48826 0.35539 6.56695 0.355614 6.64698C0.355838 6.72701 0.377014 6.80559 0.417033 6.87489L2.35807 10.1153C2.39893 10.1827 2.45645 10.2383 2.52509 10.277C2.59373 10.3156 2.67118 10.3359 2.74995 10.3359C2.82872 10.3359 2.90617 10.3156 2.97481 10.277C3.04345 10.2383 3.10097 10.1827 3.14182 10.1153Z"
                              :fill="sortData.sortedByToken && !sortData.sortedByTokenToggle ? '#FE0190' : '#868686'"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_10984_18097">
                            <rect width="5.5" height="5.5" fill="white"/>
                          </clipPath>
                          <clipPath id="clip1_10984_18097">
                            <rect width="5.5" height="5.5" fill="white" transform="translate(5.5 11) rotate(-180)"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div
                        class="table-header__col large-col"
                        @click="sortBy('sortedBySong', 'sortedBySongToggle', 'songTitle', 'song','string')"
                    >
                      <span class="table-header--text">Song</span>
                      <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_10984_18097)">
                          <path
                              d="M2.35818 0.884691L0.417134 4.12511C0.377114 4.19441 0.355939 4.27299 0.355715 4.35302C0.355491 4.43305 0.376226 4.51174 0.415857 4.58127C0.455488 4.6508 0.512633 4.70874 0.581609 4.74932C0.650584 4.78991 0.728984 4.81173 0.809009 4.81261H4.69109C4.77112 4.81173 4.84952 4.78991 4.91849 4.74932C4.98747 4.70874 5.04461 4.6508 5.08424 4.58127C5.12388 4.51174 5.14461 4.43305 5.14439 4.35302C5.14416 4.27299 5.12299 4.19441 5.08297 4.12511L3.14193 0.884691C3.10107 0.817341 3.04355 0.761656 2.97491 0.723011C2.90627 0.684365 2.82882 0.664062 2.75005 0.664062C2.67128 0.664063 2.59383 0.684365 2.52519 0.723011C2.45655 0.761656 2.39903 0.817341 2.35818 0.884691Z"
                              :fill="sortData.sortedBySong && sortData.sortedBySongToggle ? '#FE0190' : '#868686'"
                          />
                        </g>
                        <g clip-path="url(#clip1_10984_18097)">
                          <path
                              d="M3.14182 10.1153L5.08287 6.87489C5.12289 6.80559 5.14406 6.72701 5.14429 6.64698C5.14451 6.56695 5.12377 6.48826 5.08414 6.41873C5.04451 6.3492 4.98737 6.29126 4.91839 6.25068C4.84942 6.21009 4.77102 6.18827 4.69099 6.18739L0.808908 6.18739C0.728883 6.18827 0.650483 6.21009 0.581508 6.25068C0.512533 6.29126 0.455387 6.3492 0.415756 6.41873C0.376125 6.48826 0.35539 6.56695 0.355614 6.64698C0.355838 6.72701 0.377014 6.80559 0.417033 6.87489L2.35807 10.1153C2.39893 10.1827 2.45645 10.2383 2.52509 10.277C2.59373 10.3156 2.67118 10.3359 2.74995 10.3359C2.82872 10.3359 2.90617 10.3156 2.97481 10.277C3.04345 10.2383 3.10097 10.1827 3.14182 10.1153Z"
                              :fill="sortData.sortedBySong && !sortData.sortedBySongToggle ? '#FE0190' : '#868686'"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_10984_18097">
                            <rect width="5.5" height="5.5" fill="white"/>
                          </clipPath>
                          <clipPath id="clip1_10984_18097">
                            <rect width="5.5" height="5.5" fill="white" transform="translate(5.5 11) rotate(-180)"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div
                        class="table-header__col large-col"
                        @click="sortBy('sortedByDate', 'sortedByDateToggle', 'dateCompleteFrom', 'date','string')"
                    >
                      <span class="table-header--text">date</span>
                      <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_10984_18097)">
                          <path
                              d="M2.35818 0.884691L0.417134 4.12511C0.377114 4.19441 0.355939 4.27299 0.355715 4.35302C0.355491 4.43305 0.376226 4.51174 0.415857 4.58127C0.455488 4.6508 0.512633 4.70874 0.581609 4.74932C0.650584 4.78991 0.728984 4.81173 0.809009 4.81261H4.69109C4.77112 4.81173 4.84952 4.78991 4.91849 4.74932C4.98747 4.70874 5.04461 4.6508 5.08424 4.58127C5.12388 4.51174 5.14461 4.43305 5.14439 4.35302C5.14416 4.27299 5.12299 4.19441 5.08297 4.12511L3.14193 0.884691C3.10107 0.817341 3.04355 0.761656 2.97491 0.723011C2.90627 0.684365 2.82882 0.664062 2.75005 0.664062C2.67128 0.664063 2.59383 0.684365 2.52519 0.723011C2.45655 0.761656 2.39903 0.817341 2.35818 0.884691Z"
                              :fill="sortData.sortedByDate && sortData.sortedByDateToggle ? '#FE0190' : '#868686'"
                          />
                        </g>
                        <g clip-path="url(#clip1_10984_18097)">
                          <path
                              d="M3.14182 10.1153L5.08287 6.87489C5.12289 6.80559 5.14406 6.72701 5.14429 6.64698C5.14451 6.56695 5.12377 6.48826 5.08414 6.41873C5.04451 6.3492 4.98737 6.29126 4.91839 6.25068C4.84942 6.21009 4.77102 6.18827 4.69099 6.18739L0.808908 6.18739C0.728883 6.18827 0.650483 6.21009 0.581508 6.25068C0.512533 6.29126 0.455387 6.3492 0.415756 6.41873C0.376125 6.48826 0.35539 6.56695 0.355614 6.64698C0.355838 6.72701 0.377014 6.80559 0.417033 6.87489L2.35807 10.1153C2.39893 10.1827 2.45645 10.2383 2.52509 10.277C2.59373 10.3156 2.67118 10.3359 2.74995 10.3359C2.82872 10.3359 2.90617 10.3156 2.97481 10.277C3.04345 10.2383 3.10097 10.1827 3.14182 10.1153Z"
                              :fill="sortData.sortedByDate && !sortData.sortedByDateToggle ? '#FE0190' : '#868686'"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_10984_18097">
                            <rect width="5.5" height="5.5" fill="white"/>
                          </clipPath>
                          <clipPath id="clip1_10984_18097">
                            <rect width="5.5" height="5.5" fill="white" transform="translate(5.5 11) rotate(-180)"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div
                        class="table-header__col medium-col"
                        @click="sortBy('sortedByOperation', 'sortedByOperationToggle', 'isAsk', 'operation','number')"
                    >
                      <span class="table-header--text">Operation</span>
                      <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_10984_18097)">
                          <path
                              d="M2.35818 0.884691L0.417134 4.12511C0.377114 4.19441 0.355939 4.27299 0.355715 4.35302C0.355491 4.43305 0.376226 4.51174 0.415857 4.58127C0.455488 4.6508 0.512633 4.70874 0.581609 4.74932C0.650584 4.78991 0.728984 4.81173 0.809009 4.81261H4.69109C4.77112 4.81173 4.84952 4.78991 4.91849 4.74932C4.98747 4.70874 5.04461 4.6508 5.08424 4.58127C5.12388 4.51174 5.14461 4.43305 5.14439 4.35302C5.14416 4.27299 5.12299 4.19441 5.08297 4.12511L3.14193 0.884691C3.10107 0.817341 3.04355 0.761656 2.97491 0.723011C2.90627 0.684365 2.82882 0.664062 2.75005 0.664062C2.67128 0.664063 2.59383 0.684365 2.52519 0.723011C2.45655 0.761656 2.39903 0.817341 2.35818 0.884691Z"
                              :fill="sortData.sortedByOperation && sortData.sortedByOperationToggle ? '#FE0190' : '#868686'"
                          />
                        </g>
                        <g clip-path="url(#clip1_10984_18097)">
                          <path
                              d="M3.14182 10.1153L5.08287 6.87489C5.12289 6.80559 5.14406 6.72701 5.14429 6.64698C5.14451 6.56695 5.12377 6.48826 5.08414 6.41873C5.04451 6.3492 4.98737 6.29126 4.91839 6.25068C4.84942 6.21009 4.77102 6.18827 4.69099 6.18739L0.808908 6.18739C0.728883 6.18827 0.650483 6.21009 0.581508 6.25068C0.512533 6.29126 0.455387 6.3492 0.415756 6.41873C0.376125 6.48826 0.35539 6.56695 0.355614 6.64698C0.355838 6.72701 0.377014 6.80559 0.417033 6.87489L2.35807 10.1153C2.39893 10.1827 2.45645 10.2383 2.52509 10.277C2.59373 10.3156 2.67118 10.3359 2.74995 10.3359C2.82872 10.3359 2.90617 10.3156 2.97481 10.277C3.04345 10.2383 3.10097 10.1827 3.14182 10.1153Z"
                              :fill="sortData.sortedByOperation && !sortData.sortedByOperationToggle ? '#FE0190' : '#868686'"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_10984_18097">
                            <rect width="5.5" height="5.5" fill="white"/>
                          </clipPath>
                          <clipPath id="clip1_10984_18097">
                            <rect width="5.5" height="5.5" fill="white" transform="translate(5.5 11) rotate(-180)"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div
                        class="table-header__col medium-col"
                        @click="sortBy('sortedByAmount', 'sortedByAmountToggle', 'sumAmount', 'amount','number')"
                    >
                      <span class="table-header--text">amount</span>
                      <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_10984_18097)">
                          <path
                              d="M2.35818 0.884691L0.417134 4.12511C0.377114 4.19441 0.355939 4.27299 0.355715 4.35302C0.355491 4.43305 0.376226 4.51174 0.415857 4.58127C0.455488 4.6508 0.512633 4.70874 0.581609 4.74932C0.650584 4.78991 0.728984 4.81173 0.809009 4.81261H4.69109C4.77112 4.81173 4.84952 4.78991 4.91849 4.74932C4.98747 4.70874 5.04461 4.6508 5.08424 4.58127C5.12388 4.51174 5.14461 4.43305 5.14439 4.35302C5.14416 4.27299 5.12299 4.19441 5.08297 4.12511L3.14193 0.884691C3.10107 0.817341 3.04355 0.761656 2.97491 0.723011C2.90627 0.684365 2.82882 0.664062 2.75005 0.664062C2.67128 0.664063 2.59383 0.684365 2.52519 0.723011C2.45655 0.761656 2.39903 0.817341 2.35818 0.884691Z"
                              :fill="sortData.sortedByAmount && sortData.sortedByAmountToggle ? '#FE0190' : '#868686'"
                          />
                        </g>
                        <g clip-path="url(#clip1_10984_18097)">
                          <path
                              d="M3.14182 10.1153L5.08287 6.87489C5.12289 6.80559 5.14406 6.72701 5.14429 6.64698C5.14451 6.56695 5.12377 6.48826 5.08414 6.41873C5.04451 6.3492 4.98737 6.29126 4.91839 6.25068C4.84942 6.21009 4.77102 6.18827 4.69099 6.18739L0.808908 6.18739C0.728883 6.18827 0.650483 6.21009 0.581508 6.25068C0.512533 6.29126 0.455387 6.3492 0.415756 6.41873C0.376125 6.48826 0.35539 6.56695 0.355614 6.64698C0.355838 6.72701 0.377014 6.80559 0.417033 6.87489L2.35807 10.1153C2.39893 10.1827 2.45645 10.2383 2.52509 10.277C2.59373 10.3156 2.67118 10.3359 2.74995 10.3359C2.82872 10.3359 2.90617 10.3156 2.97481 10.277C3.04345 10.2383 3.10097 10.1827 3.14182 10.1153Z"
                              :fill="sortData.sortedByAmount && !sortData.sortedByAmountToggle ? '#FE0190' : '#868686'"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_10984_18097">
                            <rect width="5.5" height="5.5" fill="white"/>
                          </clipPath>
                          <clipPath id="clip1_10984_18097">
                            <rect width="5.5" height="5.5" fill="white" transform="translate(5.5 11) rotate(-180)"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div
                        class="table-header__col medium-col"
                        @click="sortBy('sortedByPrice', 'sortedByPriceToggle', 'sumTotalPrice', 'price','number')"
                    >
                      <span class="table-header--text">price</span>
                      <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_10984_18097)">
                          <path
                              d="M2.35818 0.884691L0.417134 4.12511C0.377114 4.19441 0.355939 4.27299 0.355715 4.35302C0.355491 4.43305 0.376226 4.51174 0.415857 4.58127C0.455488 4.6508 0.512633 4.70874 0.581609 4.74932C0.650584 4.78991 0.728984 4.81173 0.809009 4.81261H4.69109C4.77112 4.81173 4.84952 4.78991 4.91849 4.74932C4.98747 4.70874 5.04461 4.6508 5.08424 4.58127C5.12388 4.51174 5.14461 4.43305 5.14439 4.35302C5.14416 4.27299 5.12299 4.19441 5.08297 4.12511L3.14193 0.884691C3.10107 0.817341 3.04355 0.761656 2.97491 0.723011C2.90627 0.684365 2.82882 0.664062 2.75005 0.664062C2.67128 0.664063 2.59383 0.684365 2.52519 0.723011C2.45655 0.761656 2.39903 0.817341 2.35818 0.884691Z"
                              :fill="sortData.sortedByPrice && sortData.sortedByPriceToggle ? '#FE0190' : '#868686'"
                          />
                        </g>
                        <g clip-path="url(#clip1_10984_18097)">
                          <path
                              d="M3.14182 10.1153L5.08287 6.87489C5.12289 6.80559 5.14406 6.72701 5.14429 6.64698C5.14451 6.56695 5.12377 6.48826 5.08414 6.41873C5.04451 6.3492 4.98737 6.29126 4.91839 6.25068C4.84942 6.21009 4.77102 6.18827 4.69099 6.18739L0.808908 6.18739C0.728883 6.18827 0.650483 6.21009 0.581508 6.25068C0.512533 6.29126 0.455387 6.3492 0.415756 6.41873C0.376125 6.48826 0.35539 6.56695 0.355614 6.64698C0.355838 6.72701 0.377014 6.80559 0.417033 6.87489L2.35807 10.1153C2.39893 10.1827 2.45645 10.2383 2.52509 10.277C2.59373 10.3156 2.67118 10.3359 2.74995 10.3359C2.82872 10.3359 2.90617 10.3156 2.97481 10.277C3.04345 10.2383 3.10097 10.1827 3.14182 10.1153Z"
                              :fill="sortData.sortedByPrice && !sortData.sortedByPriceToggle ? '#FE0190' : '#868686'"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_10984_18097">
                            <rect width="5.5" height="5.5" fill="white"/>
                          </clipPath>
                          <clipPath id="clip1_10984_18097">
                            <rect width="5.5" height="5.5" fill="white" transform="translate(5.5 11) rotate(-180)"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div class="table-header__col medium-col">

                    </div>
                  </div>
                  <div class="table-body" v-if="secondaryListForShow.length">
                    <AgreementsSMListItem
                        v-for="(el, index) in secondaryListForShow"
                        :key="index"
                        :index="index"
                        :item="el"
                        :currencyName="mainCurrencyName"
                        @openContractList="openContractList"
                    />
                  </div>
                  <div class="no-data-wrapper" v-else>
                    <p>You dont have any agreements yet</p>
                    <router-link :to="{path: '/market'}" class="link">Go to Secondary market</router-link>
                  </div>
                </div>
                <div class="agreements__secondary-table mobile" v-if="screenWidthFlag === 'mobile'">

                  <div class="table-header">
                    <div
                        class="table-header__col large-col token"
                        @click="sortBy('sortedByToken', 'sortedByTokenToggle', 'fullCurrencyName', 'token','string')"
                    >
                      <span class="table-header--text">Token</span>
                      <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_10984_18097)">
                          <path
                              d="M2.35818 0.884691L0.417134 4.12511C0.377114 4.19441 0.355939 4.27299 0.355715 4.35302C0.355491 4.43305 0.376226 4.51174 0.415857 4.58127C0.455488 4.6508 0.512633 4.70874 0.581609 4.74932C0.650584 4.78991 0.728984 4.81173 0.809009 4.81261H4.69109C4.77112 4.81173 4.84952 4.78991 4.91849 4.74932C4.98747 4.70874 5.04461 4.6508 5.08424 4.58127C5.12388 4.51174 5.14461 4.43305 5.14439 4.35302C5.14416 4.27299 5.12299 4.19441 5.08297 4.12511L3.14193 0.884691C3.10107 0.817341 3.04355 0.761656 2.97491 0.723011C2.90627 0.684365 2.82882 0.664062 2.75005 0.664062C2.67128 0.664063 2.59383 0.684365 2.52519 0.723011C2.45655 0.761656 2.39903 0.817341 2.35818 0.884691Z"
                              :fill="sortData.sortedByToken && sortData.sortedByTokenToggle ? '#FE0190' : '#868686'"
                          />
                        </g>
                        <g clip-path="url(#clip1_10984_18097)">
                          <path
                              d="M3.14182 10.1153L5.08287 6.87489C5.12289 6.80559 5.14406 6.72701 5.14429 6.64698C5.14451 6.56695 5.12377 6.48826 5.08414 6.41873C5.04451 6.3492 4.98737 6.29126 4.91839 6.25068C4.84942 6.21009 4.77102 6.18827 4.69099 6.18739L0.808908 6.18739C0.728883 6.18827 0.650483 6.21009 0.581508 6.25068C0.512533 6.29126 0.455387 6.3492 0.415756 6.41873C0.376125 6.48826 0.35539 6.56695 0.355614 6.64698C0.355838 6.72701 0.377014 6.80559 0.417033 6.87489L2.35807 10.1153C2.39893 10.1827 2.45645 10.2383 2.52509 10.277C2.59373 10.3156 2.67118 10.3359 2.74995 10.3359C2.82872 10.3359 2.90617 10.3156 2.97481 10.277C3.04345 10.2383 3.10097 10.1827 3.14182 10.1153Z"
                              :fill="sortData.sortedByToken && !sortData.sortedByTokenToggle ? '#FE0190' : '#868686'"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_10984_18097">
                            <rect width="5.5" height="5.5" fill="white"/>
                          </clipPath>
                          <clipPath id="clip1_10984_18097">
                            <rect width="5.5" height="5.5" fill="white" transform="translate(5.5 11) rotate(-180)"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div
                        class="table-header__col large-col song"
                        @click="sortBy('sortedBySong', 'sortedBySongToggle', 'songTitle', 'song','string')"
                    >
                      <span class="table-header--text">Song</span>
                      <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_10984_18097)">
                          <path
                              d="M2.35818 0.884691L0.417134 4.12511C0.377114 4.19441 0.355939 4.27299 0.355715 4.35302C0.355491 4.43305 0.376226 4.51174 0.415857 4.58127C0.455488 4.6508 0.512633 4.70874 0.581609 4.74932C0.650584 4.78991 0.728984 4.81173 0.809009 4.81261H4.69109C4.77112 4.81173 4.84952 4.78991 4.91849 4.74932C4.98747 4.70874 5.04461 4.6508 5.08424 4.58127C5.12388 4.51174 5.14461 4.43305 5.14439 4.35302C5.14416 4.27299 5.12299 4.19441 5.08297 4.12511L3.14193 0.884691C3.10107 0.817341 3.04355 0.761656 2.97491 0.723011C2.90627 0.684365 2.82882 0.664062 2.75005 0.664062C2.67128 0.664063 2.59383 0.684365 2.52519 0.723011C2.45655 0.761656 2.39903 0.817341 2.35818 0.884691Z"
                              :fill="sortData.sortedBySong && sortData.sortedBySongToggle ? '#FE0190' : '#868686'"
                          />
                        </g>
                        <g clip-path="url(#clip1_10984_18097)">
                          <path
                              d="M3.14182 10.1153L5.08287 6.87489C5.12289 6.80559 5.14406 6.72701 5.14429 6.64698C5.14451 6.56695 5.12377 6.48826 5.08414 6.41873C5.04451 6.3492 4.98737 6.29126 4.91839 6.25068C4.84942 6.21009 4.77102 6.18827 4.69099 6.18739L0.808908 6.18739C0.728883 6.18827 0.650483 6.21009 0.581508 6.25068C0.512533 6.29126 0.455387 6.3492 0.415756 6.41873C0.376125 6.48826 0.35539 6.56695 0.355614 6.64698C0.355838 6.72701 0.377014 6.80559 0.417033 6.87489L2.35807 10.1153C2.39893 10.1827 2.45645 10.2383 2.52509 10.277C2.59373 10.3156 2.67118 10.3359 2.74995 10.3359C2.82872 10.3359 2.90617 10.3156 2.97481 10.277C3.04345 10.2383 3.10097 10.1827 3.14182 10.1153Z"
                              :fill="sortData.sortedBySong && !sortData.sortedBySongToggle ? '#FE0190' : '#868686'"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_10984_18097">
                            <rect width="5.5" height="5.5" fill="white"/>
                          </clipPath>
                          <clipPath id="clip1_10984_18097">
                            <rect width="5.5" height="5.5" fill="white" transform="translate(5.5 11) rotate(-180)"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div
                        class="table-header__col large-col"
                        @click="sortBy('sortedByDate', 'sortedByDateToggle', 'dateCompleteFrom', 'date','string')"
                    >
                      <span class="table-header--text">date</span>
                      <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_10984_18097)">
                          <path
                              d="M2.35818 0.884691L0.417134 4.12511C0.377114 4.19441 0.355939 4.27299 0.355715 4.35302C0.355491 4.43305 0.376226 4.51174 0.415857 4.58127C0.455488 4.6508 0.512633 4.70874 0.581609 4.74932C0.650584 4.78991 0.728984 4.81173 0.809009 4.81261H4.69109C4.77112 4.81173 4.84952 4.78991 4.91849 4.74932C4.98747 4.70874 5.04461 4.6508 5.08424 4.58127C5.12388 4.51174 5.14461 4.43305 5.14439 4.35302C5.14416 4.27299 5.12299 4.19441 5.08297 4.12511L3.14193 0.884691C3.10107 0.817341 3.04355 0.761656 2.97491 0.723011C2.90627 0.684365 2.82882 0.664062 2.75005 0.664062C2.67128 0.664063 2.59383 0.684365 2.52519 0.723011C2.45655 0.761656 2.39903 0.817341 2.35818 0.884691Z"
                              :fill="sortData.sortedByDate && sortData.sortedByDateToggle ? '#FE0190' : '#868686'"
                          />
                        </g>
                        <g clip-path="url(#clip1_10984_18097)">
                          <path
                              d="M3.14182 10.1153L5.08287 6.87489C5.12289 6.80559 5.14406 6.72701 5.14429 6.64698C5.14451 6.56695 5.12377 6.48826 5.08414 6.41873C5.04451 6.3492 4.98737 6.29126 4.91839 6.25068C4.84942 6.21009 4.77102 6.18827 4.69099 6.18739L0.808908 6.18739C0.728883 6.18827 0.650483 6.21009 0.581508 6.25068C0.512533 6.29126 0.455387 6.3492 0.415756 6.41873C0.376125 6.48826 0.35539 6.56695 0.355614 6.64698C0.355838 6.72701 0.377014 6.80559 0.417033 6.87489L2.35807 10.1153C2.39893 10.1827 2.45645 10.2383 2.52509 10.277C2.59373 10.3156 2.67118 10.3359 2.74995 10.3359C2.82872 10.3359 2.90617 10.3156 2.97481 10.277C3.04345 10.2383 3.10097 10.1827 3.14182 10.1153Z"
                              :fill="sortData.sortedByDate && !sortData.sortedByDateToggle ? '#FE0190' : '#868686'"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_10984_18097">
                            <rect width="5.5" height="5.5" fill="white"/>
                          </clipPath>
                          <clipPath id="clip1_10984_18097">
                            <rect width="5.5" height="5.5" fill="white" transform="translate(5.5 11) rotate(-180)"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div
                        class="table-header__col medium-col"
                        @click="sortBy('sortedByOperation', 'sortedByOperationToggle', 'isAsk', 'operation','number')"
                    >
                      <span class="table-header--text">Operation</span>
                      <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_10984_18097)">
                          <path
                              d="M2.35818 0.884691L0.417134 4.12511C0.377114 4.19441 0.355939 4.27299 0.355715 4.35302C0.355491 4.43305 0.376226 4.51174 0.415857 4.58127C0.455488 4.6508 0.512633 4.70874 0.581609 4.74932C0.650584 4.78991 0.728984 4.81173 0.809009 4.81261H4.69109C4.77112 4.81173 4.84952 4.78991 4.91849 4.74932C4.98747 4.70874 5.04461 4.6508 5.08424 4.58127C5.12388 4.51174 5.14461 4.43305 5.14439 4.35302C5.14416 4.27299 5.12299 4.19441 5.08297 4.12511L3.14193 0.884691C3.10107 0.817341 3.04355 0.761656 2.97491 0.723011C2.90627 0.684365 2.82882 0.664062 2.75005 0.664062C2.67128 0.664063 2.59383 0.684365 2.52519 0.723011C2.45655 0.761656 2.39903 0.817341 2.35818 0.884691Z"
                              :fill="sortData.sortedByOperation && sortData.sortedByOperationToggle ? '#FE0190' : '#868686'"
                          />
                        </g>
                        <g clip-path="url(#clip1_10984_18097)">
                          <path
                              d="M3.14182 10.1153L5.08287 6.87489C5.12289 6.80559 5.14406 6.72701 5.14429 6.64698C5.14451 6.56695 5.12377 6.48826 5.08414 6.41873C5.04451 6.3492 4.98737 6.29126 4.91839 6.25068C4.84942 6.21009 4.77102 6.18827 4.69099 6.18739L0.808908 6.18739C0.728883 6.18827 0.650483 6.21009 0.581508 6.25068C0.512533 6.29126 0.455387 6.3492 0.415756 6.41873C0.376125 6.48826 0.35539 6.56695 0.355614 6.64698C0.355838 6.72701 0.377014 6.80559 0.417033 6.87489L2.35807 10.1153C2.39893 10.1827 2.45645 10.2383 2.52509 10.277C2.59373 10.3156 2.67118 10.3359 2.74995 10.3359C2.82872 10.3359 2.90617 10.3156 2.97481 10.277C3.04345 10.2383 3.10097 10.1827 3.14182 10.1153Z"
                              :fill="sortData.sortedByOperation && !sortData.sortedByOperationToggle ? '#FE0190' : '#868686'"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_10984_18097">
                            <rect width="5.5" height="5.5" fill="white"/>
                          </clipPath>
                          <clipPath id="clip1_10984_18097">
                            <rect width="5.5" height="5.5" fill="white" transform="translate(5.5 11) rotate(-180)"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div
                        class="table-header__col medium-col"
                        @click="sortBy('sortedByAmount', 'sortedByAmountToggle', 'sumAmount', 'amount','number')"
                    >
                      <span class="table-header--text">amount</span>
                      <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_10984_18097)">
                          <path
                              d="M2.35818 0.884691L0.417134 4.12511C0.377114 4.19441 0.355939 4.27299 0.355715 4.35302C0.355491 4.43305 0.376226 4.51174 0.415857 4.58127C0.455488 4.6508 0.512633 4.70874 0.581609 4.74932C0.650584 4.78991 0.728984 4.81173 0.809009 4.81261H4.69109C4.77112 4.81173 4.84952 4.78991 4.91849 4.74932C4.98747 4.70874 5.04461 4.6508 5.08424 4.58127C5.12388 4.51174 5.14461 4.43305 5.14439 4.35302C5.14416 4.27299 5.12299 4.19441 5.08297 4.12511L3.14193 0.884691C3.10107 0.817341 3.04355 0.761656 2.97491 0.723011C2.90627 0.684365 2.82882 0.664062 2.75005 0.664062C2.67128 0.664063 2.59383 0.684365 2.52519 0.723011C2.45655 0.761656 2.39903 0.817341 2.35818 0.884691Z"
                              :fill="sortData.sortedByAmount && sortData.sortedByAmountToggle ? '#FE0190' : '#868686'"
                          />
                        </g>
                        <g clip-path="url(#clip1_10984_18097)">
                          <path
                              d="M3.14182 10.1153L5.08287 6.87489C5.12289 6.80559 5.14406 6.72701 5.14429 6.64698C5.14451 6.56695 5.12377 6.48826 5.08414 6.41873C5.04451 6.3492 4.98737 6.29126 4.91839 6.25068C4.84942 6.21009 4.77102 6.18827 4.69099 6.18739L0.808908 6.18739C0.728883 6.18827 0.650483 6.21009 0.581508 6.25068C0.512533 6.29126 0.455387 6.3492 0.415756 6.41873C0.376125 6.48826 0.35539 6.56695 0.355614 6.64698C0.355838 6.72701 0.377014 6.80559 0.417033 6.87489L2.35807 10.1153C2.39893 10.1827 2.45645 10.2383 2.52509 10.277C2.59373 10.3156 2.67118 10.3359 2.74995 10.3359C2.82872 10.3359 2.90617 10.3156 2.97481 10.277C3.04345 10.2383 3.10097 10.1827 3.14182 10.1153Z"
                              :fill="sortData.sortedByAmount && !sortData.sortedByAmountToggle ? '#FE0190' : '#868686'"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_10984_18097">
                            <rect width="5.5" height="5.5" fill="white"/>
                          </clipPath>
                          <clipPath id="clip1_10984_18097">
                            <rect width="5.5" height="5.5" fill="white" transform="translate(5.5 11) rotate(-180)"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div
                        class="table-header__col medium-col"
                        @click="sortBy('sortedByPrice', 'sortedByPriceToggle', 'sumTotalPrice', 'price','number')"
                    >
                      <span class="table-header--text">price</span>
                      <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_10984_18097)">
                          <path
                              d="M2.35818 0.884691L0.417134 4.12511C0.377114 4.19441 0.355939 4.27299 0.355715 4.35302C0.355491 4.43305 0.376226 4.51174 0.415857 4.58127C0.455488 4.6508 0.512633 4.70874 0.581609 4.74932C0.650584 4.78991 0.728984 4.81173 0.809009 4.81261H4.69109C4.77112 4.81173 4.84952 4.78991 4.91849 4.74932C4.98747 4.70874 5.04461 4.6508 5.08424 4.58127C5.12388 4.51174 5.14461 4.43305 5.14439 4.35302C5.14416 4.27299 5.12299 4.19441 5.08297 4.12511L3.14193 0.884691C3.10107 0.817341 3.04355 0.761656 2.97491 0.723011C2.90627 0.684365 2.82882 0.664062 2.75005 0.664062C2.67128 0.664063 2.59383 0.684365 2.52519 0.723011C2.45655 0.761656 2.39903 0.817341 2.35818 0.884691Z"
                              :fill="sortData.sortedByPrice && sortData.sortedByPriceToggle ? '#FE0190' : '#868686'"
                          />
                        </g>
                        <g clip-path="url(#clip1_10984_18097)">
                          <path
                              d="M3.14182 10.1153L5.08287 6.87489C5.12289 6.80559 5.14406 6.72701 5.14429 6.64698C5.14451 6.56695 5.12377 6.48826 5.08414 6.41873C5.04451 6.3492 4.98737 6.29126 4.91839 6.25068C4.84942 6.21009 4.77102 6.18827 4.69099 6.18739L0.808908 6.18739C0.728883 6.18827 0.650483 6.21009 0.581508 6.25068C0.512533 6.29126 0.455387 6.3492 0.415756 6.41873C0.376125 6.48826 0.35539 6.56695 0.355614 6.64698C0.355838 6.72701 0.377014 6.80559 0.417033 6.87489L2.35807 10.1153C2.39893 10.1827 2.45645 10.2383 2.52509 10.277C2.59373 10.3156 2.67118 10.3359 2.74995 10.3359C2.82872 10.3359 2.90617 10.3156 2.97481 10.277C3.04345 10.2383 3.10097 10.1827 3.14182 10.1153Z"
                              :fill="sortData.sortedByPrice && !sortData.sortedByPriceToggle ? '#FE0190' : '#868686'"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_10984_18097">
                            <rect width="5.5" height="5.5" fill="white"/>
                          </clipPath>
                          <clipPath id="clip1_10984_18097">
                            <rect width="5.5" height="5.5" fill="white" transform="translate(5.5 11) rotate(-180)"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div class="table-header__col medium-col">

                    </div>
                  </div>

                  <div class="table-body" v-if="secondaryListForShow.length">
                    <AgreementsSMListItem
                        v-for="(el, index) in secondaryListForShow"
                        :key="index"
                        :index="index"
                        :item="el"
                        @openContractList="openContractList"
                    />
                  </div>

                </div>
              </div>

              <b-pagination-nav
                  v-if="count > 1"
                  class="pagination"
                  v-model="page"
                  :total-rows="count"
                  :per-page="pageSize"
                  :link-gen="linkGen"
                  :number-of-pages="count"
                  prev-text="«"
                  next-text="»"
                  first-number
                  last-number
                  use-router
              ></b-pagination-nav>

            </div>

            <div class="preloader-wrapper" v-else>
              <v-progress-circular
                  :size="75"
                  :width="8"
                  color="#FF0090"
                  indeterminate
              ></v-progress-circular>
            </div>

          </div>

          <div class="list-container" v-else>
            <AgreementsSMContractsTemplate
                :item="currentSMItem"
            />
          </div>

        </div>
      </div>

      <!--      @contractAccepted="contractAcceptedFunc"-->
      <initial-sale-agreement-popup
          ref="initialSaleAgreementPopup"
          :documentData="documentDataObj"
          v-if="documentDataObj"
      />

    </main>
    <Footer/>
  </div>
</template>


<script>
import Sidebar from "../profile/Sidebar";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Investor from "./Investor";
import Contract from "./Contract";
import InitialSaleAgreementPopup from "../popups/InitialSaleAgreementPopup";
import scrollToElement from "@/mixins/scrollToElement";
import AgreementsSMListItem from "@/components/agreements/AgreementsSMListItem";
import AgreementsSMContractsTemplate from "@/components/agreements/AgreementsSMContractsTemplate";

export default {
  name: "Agreements",
  data() {
    return {
      mainCurrencyName: process.env.VUE_APP_CURRENCY_NAME_SHORT,
      page: 1,
      count: null,
      loadFlag: false,
      screenWidthFlag: 'desktop',
      tableHeaderFixed: false,
      ieosInvestor: null,
      ieosArtist: null,
      secondaryList: [],
      secondaryListForShow: [],
      tabFlag: 'investor', // investor || artist
      agreementTabFlag: 'initial', // initial || secondary
      contractListFlag: false,
      currentSMItem: {},
      documentDataObj: null,
      ops: {
        rail: {
          size: '3px',
          gutterOfEnds: '20px',
          gutterOfSide: '0px',
        },
        bar: {
          keepShow: true,
          showDelay: 500,
          background: '#747474',
          opacity: 1,
          minSize: 0.3,
          size: '3px',
        }
      },
      sortData: {
        sortedByToken: false,
        sortedByTokenToggle: false,
        sortedBySong: false,
        sortedBySongToggle: false,
        sortedByDate: false,
        sortedByDateToggle: false,
        sortedByOperation: false,
        sortedByOperationToggle: false,
        sortedByAmount: false,
        sortedByAmountToggle: false,
        sortedByPrice: false,
        sortedByPriceToggle: false,
      }

    }
  },
  mixins: [scrollToElement],
  methods: {
    linkGen(pageNum) {
      return {
        path: '/profile/agreements',
        query: {page: pageNum}
      }
    },
    getRequestParamsPrimary(page, pageSize, owner) {
      let params = {};
      params['isOwner'] = owner

      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }

      return params;
    },
    getRequestParamsSecondary(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }

      return params;
    },
    lengthWatcher() {
      window.addEventListener("resize", () => {
        if (window.innerWidth >= 1200) {
          this.screenWidthFlag = 'desktop'
          return
        }
        if (window.innerWidth < 1200) {
          this.screenWidthFlag = 'mobile'
        }
      }, false);
    },
    dataRequestInvestor() {
      this.$load(async () => {
        this.loadFlag = false
        let params
        if (this.tabFlag === 'artist') {
          params = this.getRequestParamsPrimary(
              1,
              10,
              false
          );
        } else {
          params = this.getRequestParamsPrimary(
              this.page,
              10,
              false
          );
        }
        let res = await this.$api.contract.getPrimary(params)
        this.ieosInvestor = res.data.body.elements
        let totalPages = res.data.body.totalPages
        if (totalPages === 0) {
          this.count = 1
        } else {
          this.count = totalPages
        }
        // this.count = res.data.body.totalPages
        this.loadFlag = true
      })
    },
    dataRequestArtist() {
      this.$load(async () => {
        this.loadFlag = false
        let params
        if (this.tabFlag === 'investor') {
          params = this.getRequestParamsPrimary(
              1,
              3,
              true
          );
        } else {
          params = this.getRequestParamsPrimary(
              this.page,
              3,
              true
          );
        }

        let res = await this.$api.contract.getPrimary(params)
        this.ieosArtist = res.data.body.elements
        let totalPages = res.data.body.totalPages
        if (totalPages === 0) {
          this.count = 1
        } else {
          this.count = totalPages
        }
        this.loadFlag = true
      })
    },
    requestSecondaryMarket() {
      const params = this.getRequestParamsSecondary(
          this.page,
          10
      );

      this.$load(async () => {
        this.loadFlag = false
        let res = await this.$api.contract.getSecondary(params)
        this.secondaryListForShow = res.data.body.elements
        this.secondaryList = res.data.body.elements
        let totalPages = res.data.body.totalPages
        if (totalPages === 0) {
          this.count = 1
        } else {
          this.count = totalPages
        }
        this.loadFlag = true
      })
    },
    togglePrimaryType(type) {
      if (type === this.tabFlag) {
        return
      }

      if (type === 'investor') {
        this.dataRequestInvestor()
      }
      if (type === 'artist') {
        this.dataRequestArtist()
      }

      this.$router.push({path: '/profile/agreements', query: {page: '1'}}).catch(() => {
      })
      this.tabFlag = type
    },
    toggleAgreementType(tab) {
      if (tab === this.agreementTabFlag) {
        return
      }

      this.$router.push({path: '/profile/agreements', query: {page: '1'}}).catch(() => {
      })
      this.agreementTabFlag = tab
    },
    openContractList(data) {
      this.currentSMItem = data
      this.contractListFlag = true
    },
    btnBack() {
      this.contractListFlag = false
    },
    sortBy(key, toggleKey, dataKey, removeAllInsteadOf, type) {
      let result
      let arr = this.secondaryListForShow
      this.removeSortsExcept(removeAllInsteadOf)
      if (type === 'string') {
        if (!this.sortData[key]) {
          this.sortData[key] = true
          this.sortData[toggleKey] = true
          result = arr.sort((a, b) => a[dataKey].localeCompare(b[dataKey]))
        } else {
          if (this.sortData[toggleKey]) {
            this.sortData[toggleKey] = false
            result = arr.sort((a, b) => b[dataKey].localeCompare(a[dataKey]))
          } else {
            this.sortData[toggleKey] = true
            result = arr.sort((a, b) => a[dataKey].localeCompare(b[dataKey]))
          }
        }
      } else if (type === 'number') {
        if (!this.sortData[key]) {
          this.sortData[key] = true
          this.sortData[toggleKey] = true
          result = arr.sort((a, b) => a[dataKey] - b[dataKey])
        } else {
          if (this.sortData[toggleKey]) {
            this.sortData[toggleKey] = false
            result = arr.sort((a, b) => b[dataKey] - a[dataKey])
          } else {
            this.sortData[toggleKey] = true
            result = arr.sort((a, b) => a[dataKey] - b[dataKey])
          }
        }
      }

      this.secondaryListForShow = result
    },
    removeSortsExcept(key) {
      if (key === "token") {
        this.sortData.sortedBySong = false
        this.sortData.sortedBySongToggle = false
        this.sortData.sortedByDate = false
        this.sortData.sortedByDateToggle = false
        this.sortData.sortedByOperation = false
        this.sortData.sortedByOperationToggle = false
        this.sortData.sortedByAmount = false
        this.sortData.sortedByAmountToggle = false
        this.sortData.sortedByPrice = false
        this.sortData.sortedByPriceToggle = false
      } else if (key === 'song') {
        this.sortData.sortedByToken = false
        this.sortData.sortedByTokenToggle = false
        this.sortData.sortedByDate = false
        this.sortData.sortedByDateToggle = false
        this.sortData.sortedByOperation = false
        this.sortData.sortedByOperationToggle = false
        this.sortData.sortedByAmount = false
        this.sortData.sortedByAmountToggle = false
        this.sortData.sortedByPrice = false
        this.sortData.sortedByPriceToggle = false
      } else if (key === 'date') {
        this.sortData.sortedBySong = false
        this.sortData.sortedBySongToggle = false
        this.sortData.sortedByToken = false
        this.sortData.sortedByTokenToggle = false
        this.sortData.sortedByOperation = false
        this.sortData.sortedByOperationToggle = false
        this.sortData.sortedByAmount = false
        this.sortData.sortedByAmountToggle = false
        this.sortData.sortedByPrice = false
        this.sortData.sortedByPriceToggle = false
      } else if (key === 'operation') {
        this.sortData.sortedBySong = false
        this.sortData.sortedBySongToggle = false
        this.sortData.sortedByDate = false
        this.sortData.sortedByDateToggle = false
        this.sortData.sortedByToken = false
        this.sortData.sortedByTokenToggle = false
        this.sortData.sortedByAmount = false
        this.sortData.sortedByAmountToggle = false
        this.sortData.sortedByPrice = false
        this.sortData.sortedByPriceToggle = false
      } else if (key === 'amount') {
        this.sortData.sortedBySong = false
        this.sortData.sortedBySongToggle = false
        this.sortData.sortedByDate = false
        this.sortData.sortedByDateToggle = false
        this.sortData.sortedByToken = false
        this.sortData.sortedByTokenToggle = false
        this.sortData.sortedByOperation = false
        this.sortData.sortedByOperationToggle = false
        this.sortData.sortedByPrice = false
        this.sortData.sortedByPriceToggle = false
      } else if (key === 'price') {
        this.sortData.sortedBySong = false
        this.sortData.sortedBySongToggle = false
        this.sortData.sortedByDate = false
        this.sortData.sortedByDateToggle = false
        this.sortData.sortedByToken = false
        this.sortData.sortedByTokenToggle = false
        this.sortData.sortedByOperation = false
        this.sortData.sortedByOperationToggle = false
        this.sortData.sortedByAmount = false
        this.sortData.sortedByAmountToggle = false
      }
    },
  },
  computed: {
    pageSize() {
      return this.tabFlag === 'investor' ? 10 : 3
    }
  },
  watch: {
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if (newVal && newVal !== this.page.toString()) {

          if (this.agreementTabFlag === 'secondary') {
            this.page = newVal
            this.scrollToId('my-content')
            this.requestSecondaryMarket()
            return
          }

          if (this.tabFlag === 'investor') {
            this.page = newVal
            this.dataRequestInvestor()
            this.scrollToId('my-content')
          } else {
            this.page = newVal
            this.dataRequestArtist()
            this.scrollToId('my-content')
          }
        } else {
          this.page = 1
        }
      }
    },
    agreementTabFlag() {
      if (this.agreementTabFlag === 'secondary') {
        this.requestSecondaryMarket()
      }
    },
  },
  async mounted() {
    document.title = 'Agreements'
    await this.dataRequestInvestor()
    // this.requestSecondaryMarket()

    this.lengthWatcher()
    if (window.innerWidth >= 1200) {
      this.screenWidthFlag = 'desktop'
      return
    }
    if (window.innerWidth < 1200) {
      this.screenWidthFlag = 'mobile'
    }

    setTimeout(() => {
      this.scrollToId('my-content')
    }, 500)
  },
  components: {
    AgreementsSMContractsTemplate,
    Sidebar,
    Header,
    Footer,
    Investor,
    InitialSaleAgreementPopup,
    Contract,
    AgreementsSMListItem,
  },
}
</script>

<style scoped>
.table-header__col {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.table-header__col span {
  margin-right: 3px;
}
</style>
