<template>
  <v-dialog v-model="dialog">
    <div class="type-ieo-popup-server-error white-popup popup-form order-popup success">
      <div class="form-main-content">
        <div class="popup-header">
          <h3 class="form-title">Success!</h3>
        </div>
        <div class="popup-content">
          <div class="popup-content-container">
            <div class="main-popup__content-description server-error" v-text="errorMessage"></div>
            <div class="delete-image-btn-wrap">
              <button type="button"
                      class="submit_form_sell btn btn-hover"
                      @click="confirmAction">
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </v-dialog>
</template>

<script>
export default {
  name: "SuccessPopup",
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    errorMessage: String,
  },
  methods: {
    openPopups() {
      this.dialog = true;
    },
    confirmAction() {
      this.$emit('confirmAction')
      this.dialog = false
    }
  },
};
</script>

<style scoped>

.main-popup__content-description {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000000;
  margin: 30px 0 40px 0;
}

</style>