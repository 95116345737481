<template>
  <v-dialog v-model="dialog">
    <div class="contract-container"
         @click="closeContractPopup"
    >
      <div class="contract-popup-wrapper"
           @click.stop
      >
        <div class="contract-popup-header">
          <div class="popup-header-title">INITIAL SALE AGREEMENT</div>
          <div class="popup-header-close-btn"
               @click="closeContractPopup"
          >
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5656 9.43464C20.2839 9.15068 19.9005 8.99095
                19.5005 8.99095C19.1006 8.99095 18.7172 9.15068 18.4355 9.43464L15.0004 12.8846L11.5653 9.43464C10.9771
                8.84647 10.0235 8.84647 9.43525 9.43464C8.84705 10.0228 8.84705 10.9764 9.43525 11.5646L12.8853
                14.9995L9.43525 18.4345C9.15128 18.7161 8.99154 19.0995 8.99154 19.4995C8.99154 19.8994 9.15128
                20.2828 9.43525 20.5645C9.71691 20.8484 10.1003 21.0081 10.5003 21.0081C10.9002 21.0081 11.2837
                20.8484 11.5653 20.5645L15.0004 17.1145L18.4355 20.5645C18.7172 20.8484 19.1006 21.0081 19.5005
                21.0081C19.9005 21.0081 20.2839 20.8484 20.5656 20.5645C20.8495 20.2828 21.0093 19.8994 21.0093
                19.4995C21.0093 19.0995 20.8495 18.7161 20.5656 18.4345L17.1155 14.9995L20.5656 11.5646C20.8495
                11.283 21.0093 10.8996 21.0093 10.4996C21.0093 10.0997 20.8495 9.71629 20.5656 9.43464ZM25.6057
                4.39473C19.7493 -1.46464 10.2515 -1.46464 4.39351 4.39313C-1.46451 10.2509 -1.4645 19.7482 4.39352
                25.606C10.2507 31.4645 19.7485 31.4645 25.6065 25.6068C31.4645 19.749 31.4645 10.2517 25.6065
                4.39392L25.6057 4.39473ZM23.4906 23.4894C19.4448 27.5395 13.1014 28.168 8.33951 24.9905C3.57757
                21.8131 1.72325 15.7146 3.91048 10.4243C6.0977 5.13405 11.7174 2.12535 17.333 3.23806C22.9486
                4.35077 26.9962 9.27502 27.0007 14.9996C27.0115 18.1847 25.7476 21.2418 23.4906 23.4894Z"/>
            </svg>
          </div>
        </div>
        <div class="popup-main">
          <div class="scroll-main-wrap">
            <vuescroll :ops="ops" @handle-scroll="handleScroll" ref="vs">
              <div class="scroll-wrap">
                <div class="contract-main">

                  <div class="contract-head-title">INITIAL SALE AGREEMENT # 1</div>

                  <p class="paragraph-item bold">
                    WHEREAS, the Djooky Inc. a Delaware corporation is creating a music fundraising and trading platform,
                    which includes music competitions, known as “Djooky” located at <a href="">www.djooky.com</a> (“Platform”);
                  </p>

                  <p class="paragraph-item bold">
                    WHEREAS, the Artist is the creator (or co-creator) of original music composition (and/or underlying
                    sound recording) and the owner (or co-owner) of the relevant intellectual property rights (or his/her share);
                  </p>

                  <p class="paragraph-item bold">
                    WHEREAS, the Artist is starting the initial sale of the music copyrights and offers an Investor to buy
                    an ownership in his/her music composition and/or sound recording rights to the third parties, including
                    but not limited to the Investor under the terms and conditions of this Agreement (the “Initial Sale”);
                  </p>

                  <p class="paragraph-item bold">
                    WHEREAS, the Initial Sale shall be effected through a Dutch Auction process, subject to the clearing
                    price resulting in aggregate proceeds equal to or greater than a specified minimum threshold within a
                    specified time period within the Agreement;
                  </p>

                  <p class="paragraph-item bold">
                    WHEREAS, the Parties by signing this Agreement warrants that they are binding by this Agreement
                    and subject to Terms of Use of the Platform <a href="https://bit.ly/3tXVnsP">(https://bit.ly/3tXVnsP)</a>;
                  </p>

                  <p class="paragraph-item bold">
                    NOW, THEREFORE, in consideration of the terms and conditions contained in this Initial Sale Agreement
                    # 1, and for other good and valuable consideration, the sufficiency of which are hereby acknowledged,
                    the Artist and the Investor hereby agree as follows:
                  </p>

                  <div class="paragraph-border"></div>

                  <p class="paragraph-title">Auction procedure</p>

                  <p class="paragraph-item">
                    During the Term (as defined in Exhibit A) and after successful achieving the Threshold (as defined in
                    the Exhibit A) within the Platform the percentage interest of the Rights set out in Exhibit A is
                    considered assigned to the Investor (subject to clearing of funds).
                  </p>

                  <p class="paragraph-item">
                    Detailed explanation of the auction procedure is subject to the <router-link
                        target='_blank'
                        :to="{path: '/terms-conditions'}"

                        style="color: #FF0090"
                    >Terms of Use of the Platform</router-link> at
                    https://bit.ly/3tXVnsP.
                  </p>

                  <p class="paragraph-title">Initial sale offering</p>

                  <p class="paragraph-item">
                    It is expected that Artist will grant to the Investor an undivided fractional share (percentage interest)
                    in the entire exclusive right to use and exploit the music composition set out in the Exhibit A
                    (the “Rights”) in return of funds which the Investor will invest in the Artist music composition.
                  </p>

                  <div class="paragraph-item">
                    The Rights which are transferred and assigned by this Agreement to the Investor constituting:
                    <p class="paragraph-item item-with-point">
                      the right of usage and exploitation relating to Rights and
                    </p>

                    <p class="paragraph-item item-with-point">
                      the right to receive royalties and related payments for Rights.
                    </p>
                  </div>

                  <p class="paragraph-item">
                    The Agreement is considered valid and in effect only in case of successfully raised funds via the
                    Initial Sale.
                  </p>

                  <p class="paragraph-title">Sales proceeds</p>

                  <p class="paragraph-item">
                    By entering in this Agreement, the Artist guarantees that within the Platform he/she has signed the
                    relevant agreements with third parties specified in clause 3.1. of the Agreement and those parties
                    will/may receive the corresponding amounts from the Threshold for their services (as applicable per
                    such side agreements).
                  </p>

                  <p class="paragraph-title">Royalty agent</p>

                  <p class="paragraph-item">
                    The Rights entitlement of the Investor must be coupled with the irrevocable appointment by the Artist
                    of the royalty agent for the purposes of receiving and disbursing royalty and other payments received
                    in respect of the Rights subject to <router-link
                      target='_blank'
                      :to="{path: '/terms-conditions'}"

                      style="color: #FF0090"
                  >Terms of Use of the Platform</router-link> (https://bit.ly/3tXVnsP).
                  </p>

                  <p class="paragraph-title">Escrow agent</p>

                  <p class="paragraph-item">
                    In order to obtain the Rights, the Investor must deposit money with an Escrow Agent subject to <router-link
                      target='_blank'
                      :to="{path: '/terms-conditions'}"

                      style="color: #FF0090"
                  >Terms of Use of the Platform</router-link> (https://bit.ly/3tXVnsP).
                  </p>

                  <p class="paragraph-title">Sales Agreement</p>

                  <p class="paragraph-item">
                    Upon the Threshold being reached within the Term, the Rights shall be transferred to the Investor via
                    a sales contract entered into through the Platform set out in Exhibit B (the “Sales Agreement”);
                    Funds in the Escrow Agent account will be transferred by the Escrow Agent in accordance with the terms
                    of the Sales Agreement. Those transfers will be made to service providers.
                  </p>

                  <p class="paragraph-title">Form of an Agreement</p>

                  <p class="paragraph-item">
                    This Agreement may be signed in counterparts and a facsimile,scanned PDF or electronic version of
                    this Agreement shall be adequate and binding.
                  </p>

                  <div class="paragraph-border"></div>

                  <div class="form-border">
                    <div class="paragraph-uppercase">
                      BY SIGNING THIS AGREEMENT, PARTIES ACKNOWLEDGE THAT:
                      (1) PARTIES HAVE READ ALL PROVISIONS, DISCLAIMERS, AND DISCLOSURES MADE HEREIN AND IN THE <router-link
                        target='_blank'
                        :to="{path: '/terms-conditions'}"

                        style="color: #FF0090"
                    >TERMS OF USE OF THE PLATFORM</router-link>;
                      (2) PARTIES UNDERSTAND ALL SUCH PROVISIONS, DISCLAIMERS, AND DISCLOSURES AND HAVE ENTERED INTO THIS
                      AGREEMENT VOLUNTARILY.
                    </div>
                  </div>

                  <div class="form-border">

                    <div class="form-border-header">
                      <div class="form-border-header-main">
                        <div class="form-border-title">EXHIBIT A</div>
                        <div class="form-border-description">to the Initial Sale Agreement # 1</div>
                      </div>
                    </div>

                    <div class="form-border-item-wrap">
                      <div class="form-border-item-title">Music Composition</div>
                      <div class="form-border-item-value">{{ documentData.songName }}</div>
                    </div>

                    <div class="form-border-item-wrap">
                      <div class="form-border-item-title">Artist</div>
                      <div class="form-border-item-value">{{ documentData.artistName }}</div>
                    </div>

                    <div class="form-border-item-wrap">
                      <div class="form-border-item-title">Co-authors</div>
                      <div class="form-border-item-value">{{ documentData.coAuthors }}</div>
                    </div>

                    <div class="form-border-item-wrap">
                      <div class="form-border-item-title">Share in intellectual property rights for sale</div>
                      <div class="form-border-item-value">{{ documentData.shareForSale }}</div>
                    </div>

                    <div class="form-border-item-wrap">
                      <div class="form-border-item-title">Reserved price per share</div>
                      <div class="form-border-item-value">{{ documentData.pricePerShare }}</div>
                    </div>

                    <div class="form-border-item-wrap">
                      <div class="form-border-item-title">Term of the auction</div>
                      <div class="form-border-item-value">{{ documentData.auctionTerm }}</div>
                    </div>

                    <div class="form-border-item-wrap">
                      <div class="form-border-item-title">Threshold</div>
                      <div class="form-border-item-value">{{ documentData.threshold }}</div>
                    </div>

                  </div>

                  <div class="form-border">

                    <div class="form-border-header-exhibit_b">
                      <div class="form-border-header-main">
                        <div class="form-border-title">EXHIBIT B</div>
                        <div class="form-border-description">to the Initial Sale Agreement # 1</div>
                      </div>
                      <div class="form-border-header-right_side">SALES AGREEMENT</div>
                    </div>

                    <div class="form-border-exhibit_b-wrapper">
                      <div class="form-border-exhibit_b-block">

                        <div class="form-border-exhibit_b-title">
                          INITIATING INVESTOR <span class="title-mark">Seller</span>
                        </div>

                        <div class="form-border-item-wrap">
                          <div class="form-border-item-title">ID:</div>
                          <div class="form-border-item-value">{{ documentData.artistId }}</div>
                        </div>

                        <div class="form-border-item-wrap">
                          <div class="form-border-item-title">Address:</div>
                          <div class="form-border-item-value">XXXXXXXXXXXXXXXXX</div>
                        </div>

<!--                        <div class="form-border-item-wrap">-->
<!--                          <div class="form-border-item-title">Bank details:</div>-->
<!--                          <div class="form-border-item-value">XXXXXXXXXXXXXXXXX</div>-->
<!--                        </div>-->

                      </div>
                      <div class="form-border-exhibit_b-block">

                        <div class="form-border-exhibit_b-title">
                          OFFERING INVESTOR <span class="title-mark">Buyer</span>
                        </div>

                        <div class="form-border-item-wrap">
                          <div class="form-border-item-title">ID:</div>
                          <div class="form-border-item-value">{{ documentData.investorId }}</div>
                        </div>

                        <div class="form-border-item-wrap">
                          <div class="form-border-item-title">Address:</div>
                          <div class="form-border-item-value">XXXXXXXXXXXXXXXXX</div>
                        </div>

<!--                        <div class="form-border-item-wrap">-->
<!--                          <div class="form-border-item-title">Bank details:</div>-->
<!--                          <div class="form-border-item-value">XXXXXXXXXXXXXXXXX</div>-->
<!--                        </div>-->

                      </div>
                    </div>

                    <div class="form-border-exhibit_b-list">

                      <p class="paragraph-item with-num-item">
                        <span class="num-item">1</span>
                        Subject: According to this Sales Agreement (“Agreement”) Seller agrees to sell, and Buyer
                        agrees to buy for the price and on the terms and conditions set forth herein the undivided
                        fractional share in the entire exclusive intellectual property right to use and exploit the
                        music composition (“Right”) identified as lot
                        <strong>#{{ documentData.lotId }}</strong> at the auction held by Djooky Inc.
                      </p>

                      <p class="paragraph-item with-num-item">
                        <span class="num-item">2</span>
                        Right: Set out in the Exhibit A to the Initial Sale agreement # 1.
                      </p>

                      <p class="paragraph-item with-num-item">
                        <span class="num-item">3</span>
                        Bid Price: USD  <strong>{{ documentData.bidPrice }}</strong>  payable as follows: <strong>{{ documentData.payable }}</strong>
                      </p>

                      <p class="paragraph-item with-num-item">
                        <span class="num-item">4</span>
                        Sale Date: <strong>{{ documentData.saleDate }}</strong> (DD/MM/YYYY).
                      </p>

                      <p class="paragraph-item with-num-item">
                        <span class="num-item">5</span>
                        Closing Date: <strong>{{ documentData.closingDate }}</strong> (DD/MM/YYYY).
                      </p>

                      <p class="paragraph-item with-num-item">
                        <span class="num-item">6</span>
                        Deposited Funds: The Buyer represents that he has sufficient funds, which the Buyer previously
                        deposited at the Escrow agent account, available to close this sale on the Closing Date.
                      </p>

                      <p class="paragraph-item with-num-item">
                        <span class="num-item">7</span>
                        Obligations of the Seller: The Seller will assign the Right to the Buyer and if applicable,
                        deliver all necessary title transfer documentation in registerable form to the Buyer through
                        the Djooky Inc. platform no later than the Closing Date in order that the title can register
                        in the name of the Buyer within the Djooky Inc. platform.
                      </p>

                      <p class="paragraph-item with-num-item">
                        <span class="num-item">8</span>
                        Obligations of the Buyer: The Buyer acknowledges that there are no contingencies closing this
                        transaction other than clear title as specified herein and that failure by the Buyer to pay the
                        balance of the purchase price and close on or before the Closing Date shall result in a
                        forfeiture to the Seller of the deposit paid.
                      </p>

                    </div>

                  </div>


                </div>
              </div>
            </vuescroll>
          </div>
          <div class="popup-btn-wrap">
            <button class="btn btn-contract"
                    :disabled="validScroll"
                    :class="{'disabled-btn': validScroll}"
                    @click="getAcceptContract"
            >Scroll down to accept</button>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>

import vuescroll from "vuescroll";

export default {
  name: "InitialSaleAgreementPopup",
  data() {
    return {
      dialog: false,
      scrollProcess: 0,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          background: 'rgba(234, 234, 234)',
          opacity: 0.5,
          size: '20px',
          specifyBorderRadius: false,
          gutterOfEnds: null,
          gutterOfSide: '2px',
          keepShow: false
        },
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: true,
          keepShow: false,
          background: '#FF0090',
          opacity: 1,
          hoverStyle: false,
          specifyBorderRadius: false,
          minSize: 0.3,
          size: '20px',
          disable: false
        }
      },

    }
  },
  props: {
    notAccepted: Boolean,
    documentData: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    openContractPopup() {
      this.dialog = true;
    },

    closeContractPopup() {
      this.$refs["vs"].scrollTo(
          {
            y: 0
          },
          0,
          "easeInQuad"
      );
      this.dialog = false
    },
    handleScroll(vertical, horizontal, nativeEvent) {
      return this.scrollProcess = vertical.process
    },
    getAcceptContract() {
      this.$emit('contractAccepted')
      this.$refs["vs"].scrollTo(
          {
            y: 0
          },
          0,
          "easeInQuad"
      );
      this.dialog = false
    },
  },
  watch: {

  },
  computed: {
    validScroll() {
      return this.scrollProcess < 0.99
    },
  },
  components: {
    vuescroll,
  }
}
</script>

<style>

strong {
  color: #272727;
}

/*.v-application a {*/
/*  color: #FF0090 !important;*/
/*}*/


.contract-popup-wrapper {
  margin: auto;
  max-width: 1165px;
  /*width: 100%;*/
}

.contract-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 16px;
  margin: 0;
  background: #FF0090;
  border-radius: 5px 5px 0 0;
}

.popup-header-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
}

.popup-header-close-btn svg{
  fill: white;
  cursor: pointer;
}

.popup-header-close-btn:hover svg {
  fill: black;
  transition: .5s ease;
}

.popup-main {
  padding: 50px;
  background: #FFFFFF;
  border-radius: 0 0 5px 5px;
}

.scroll-main-wrap {
  padding: 30px 0;
  border-top: 1px solid #EAEAEA;
  border-bottom: 1px solid #EAEAEA;
}

.scroll-wrap {
  max-height: 400px;
  padding-right: 60px;
}



.contract-main {

}

.contract-head-title {
  padding: 20px 0;
  border-bottom: 1px solid #E5E5E5;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  color: #000000;
}

.paragraph-item {
  margin-bottom: 10px;
  font-weight: normal;
  font-size: 18px;
  line-height: 31px;
  color: #A6A6A6;
}

.paragraph-item.bold {
  font-weight: 600;
  color: #272727;
}

.paragraph-border {
  margin: 20px 0;
  height: 1px;
  width: 100%;
  background: #E5E5E5;
}

p.paragraph-title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #272727;
}

p.item-with-point {
  margin-bottom: 0;
  padding-left: 50px;
  position: relative;
}

.item-with-point:before {
  content: '•';
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.form-border {
  margin-bottom: 90px;
  padding-bottom: 20px;
  border-bottom: 6px solid #FF0090;
}

.form-border:last-of-type {
  margin-bottom: 0;
}

.paragraph-uppercase {
  font-weight: 600;
  font-size: 18px;
  line-height: 31px;
  color: #272727;
}

.form-border-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  padding-bottom: 20px;
  border-bottom: 1px solid #E5E5E5;
}

.form-border-header-main {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-border-header-right_side {
  font-weight: 600;
  font-size: 18px;
  line-height: 31px;
  color: #272727;
}

.form-border-title {
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  color: #000000;
}

.form-border-description {
  width: calc(100% - 175px);
  padding-left: 40px;
  border-left: 1px solid #000000;
  font-weight: 600;
  font-size: 18px;
  line-height: 31px;
  color: #272727;
}

.form-border-item-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.form-border-item-wrap:last-child {
  margin-bottom: 0;
}

.form-border-item-title {
  font-weight: normal;
  font-size: 18px;
  line-height: 31px;
  color: #A6A6A6;
}

.form-border-item-value {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #272727;
}

.form-border-header-exhibit_b {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.form-border-exhibit_b-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 25px;
  border-bottom: 1px solid #E5E5E5;
  margin-bottom: 40px;
}

.form-border-exhibit_b-block {
  width: calc(50% - 90px);
}

.form-border-exhibit_b-title {
  padding: 10px 0 8px 0;
  border-top: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
  margin-bottom: 25px;
  font-weight: 600;
  font-size: 20px;
  line-height: 31px;
  text-transform: uppercase;
  color: #272727;
}

.form-border-exhibit_b-title .title-mark {
  margin-left: 5px;
  color: #FF0090;
}

.form-border-exhibit_b-list {

}

.paragraph-item.with-num-item {
  position: relative;
  padding-left: 50px;
}

.num-item {
  position: absolute;
  top: 15px;
  transform: translateY(-50%);
  left: 15px;
}


.popup-btn-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-contract {
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #FFFFFF;
}

/*.btn-contract:disabled {*/
/*  background: #D0D0D0;*/
/*  cursor: default;*/
/*}*/

/*.btn-contract:hover:disabled {*/
/*  background: #D0D0D0;*/
/*  color: #FFFFFF;*/
/*  border: 1px solid #D0D0D0;*/
/*}*/

@media only screen and (max-width: 1440px) {

  .contract-popup-wrapper {
    max-width: 960px;
  }

}

@media only screen and (max-width: 1024px) {

  .contract-popup-wrapper {
    max-width: 80%;
  }

  .popup-main {
    padding: 20px;
  }

  .scroll-main-wrap {
    padding: 20px 0;
  }

  .form-border {
    margin-bottom: 50px;
    padding-bottom: 15px;
  }

  .form-border-exhibit_b-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .scroll-wrap {
    max-height: 350px;
    padding-right: 60px;
  }

  .popup-header-title {
    font-size: 18px;
    line-height: 22px;
  }

  .contract-head-title {
    padding: 0 0 15px 0;
    margin-bottom: 20px;
    font-size: 25px;
    line-height: 35px;
  }

  .paragraph-item {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 26px;
  }

  p.paragraph-title {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 19px;
  }

  .paragraph-uppercase {
    font-size: 16px;
    line-height: 26px;
  }

  .form-border-header-main {
    width: 50%;
    flex-direction: column;
    align-items: flex-start;
  }

  .form-border-header-right_side {
    width: 50%;
    text-align: right;
    font-size: 16px;
    line-height: 26px;
  }

  .form-border-title {
    margin-bottom: 10px;
    font-size: 25px;
    line-height: 35px;
  }

  .form-border-description {
    width: 100%;
    padding-left: 30px;
    font-size: 15px;
    line-height: 24px;
  }

  .form-border-item-title {
    font-size: 14px;
    line-height: 22px;
  }

  .form-border-item-value {
    width: 60%;
    text-align: right;
    font-size: 14px;
    line-height: 18px;
  }

  .form-border-exhibit_b-block {
    width: calc(50% - 25px);
  }

  .form-border-exhibit_b-title {
    display: flex;
    justify-content: center;
    padding: 8px 0 6px 0;
    margin-bottom: 15px;
    font-size: 15px;
    line-height: 20px;
  }

  .popup-main .popup-btn-wrap {
   margin-top: 25px;
  }

  .btn-contract {
    font-size: 16px;
    line-height: 19px;
  }

}

@media only screen and (max-width: 768px) {

  .contract-popup-header {
    padding: 10px;
  }

  .popup-header-close-btn svg {
    width: 20px;
  }

  .popup-main {
    padding: 15px;
  }

  .scroll-main-wrap {
    padding: 15px 0;
  }

  .form-border {
    margin-bottom: 30px;
    padding-bottom: 10px;
  }

  .form-border-exhibit_b-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .scroll-wrap {
    max-height: 310px;
    padding-right: 20px;
  }

  .popup-header-title {
    font-size: 14px;
    line-height: 19px;
  }

  .contract-head-title {
    padding: 0 0 10px 0;
    margin-bottom: 12px;
    font-size: 18px;
    line-height: 22px;
  }

  .paragraph-item {
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 18px;
  }

  p.paragraph-title {
    margin-top: 12px;
    margin-bottom: 8px;
    font-size: 15px;
    line-height: 19px;
  }

  .paragraph-border {
    margin: 10px 0;
  }

  .paragraph-uppercase {
    font-size: 14px;
    line-height: 24px;
  }

  .form-border-header {
    margin-bottom: 12px;
    padding-bottom: 10px;
  }

  .form-border-header-main {
    width: 50%;
    flex-direction: column;
    align-items: flex-start;
  }

  .form-border-header-right_side {
    width: 50%;
    text-align: right;
    font-size: 16px;
    line-height: 26px;
  }

  .form-border-title {
    margin-bottom: 10px;
    font-size: 25px;
    line-height: 35px;
  }

  .form-border-description {
    width: 100%;
    padding-left: 30px;
    font-size: 15px;
    line-height: 24px;
  }

  .form-border-item-title {
    font-size: 10px;
    line-height: 14px;
  }

  .form-border-item-value {
    max-width: calc(100% - 64px);
    font-size: 10px;
    line-height: 14px;
  }

  .form-border-exhibit_b-block {
    width: calc(50% - 5px);
  }

  .form-border-exhibit_b-title {
    margin-bottom: 15px;
    font-size: 13px;
    line-height: 18px;
  }

  .popup-main .popup-btn-wrap {
    margin-top: 25px;
  }

  .btn-contract {
    font-size: 14px;
    line-height: 18px;
  }

  .paragraph-item.with-num-item {
    padding-left: 20px;
  }

  .num-item {
    top: 9px;
    left: 0;
  }

  .__rail-is-vertical {
    width: 10px !important;
  }

  /*.__bar-is-vertical {*/
  /*  width: 10px !important;*/
  /*}*/

}

@media only screen and (max-width: 600px) {

  .form-border-exhibit_b-block {
    width: 100%;
  }

  .form-border-exhibit_b-wrapper {
    flex-direction: column;
  }

  .contract-head-title {
    padding: 0 0 8px 0;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 19px;
  }

  .form-border-exhibit_b-title {
    padding: 7px 0 5px 0;
    margin-bottom: 8px;
  }

  .form-border-title {
    margin-bottom: 7px;
    font-size: 18px;
    line-height: 25px;
  }

  .form-border-description {
    padding-left: 10px;
    font-size: 12px;
    line-height: 18px;
  }

  .form-border-header-main {
    width: 100%;
  }

  .form-border-header-right_side {
    width: 50%;
    text-align: left;
    margin-top: 8px;
    font-size: 12px;
    line-height: 18px;
  }

  .form-border-header-exhibit_b {
    flex-wrap: wrap;
    padding-bottom: 10px;
  }



}

@media only screen and (max-width: 425px) {

  .form-border-item-wrap {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .form-border-item-wrap:last-child {
    margin-bottom: 0;
  }

  .form-border-item-value {
    max-width: 100%;
    width: 100%;
    text-align: left;
  }

}

/*if user rotate his phone, popup height too biggest*/

@media only screen and (max-height: 375px) {

  .contract-popup-wrapper {
    transform: scale(0.8);
  }

  .scroll-wrap {
    max-height: 135px;
  }
}



</style>