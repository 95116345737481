<template>

  <tr>
    <td data-label="Date" class="left" v-text="history.createdAt"></td>
    <td data-label="Pair" class="left" v-text="history.songName"></td>
    <td data-label="Type" class="left" v-if="history.orderType == 1">Market</td>
    <td data-label="Type" class="left" v-else-if="history.orderType == 2">Limit</td>
    <td data-label="Type" class="left" v-else>Stop limit</td>
    <td data-label="Side" class="left buy" v-if="history.ask == true">Buy</td>
    <td data-label="Side" class="left sell" v-else>Sell</td>
    <td data-label="Price" class="left" v-text="price"></td>
    <td data-label="Stop price" class="left" v-if="!history.stopPrice.length">-</td>
    <td data-label="Stop price" class="left" v-else v-text="history.stopPrice"></td>
    <td data-label="Amount" class="left" v-text="amount"></td>
    <td data-label="Total" class="left" v-text="total"></td>
    <td data-label="Status" class="left event-type-val" v-text="history.orderStatusResponse"></td>
  </tr>

</template>

<script>
export default {
  name: "MyOrdersList",
  props: {
    history: Object
  },
  computed: {
    amount() {
      return new Intl.NumberFormat().format(this.history.amount)
    },
    total() {
      return new Intl.NumberFormat().format(this.history.total)
    },
    price() {
      return new Intl.NumberFormat().format(this.history.price)
    },
  }
}
</script>

<style scoped>

</style>