<template>
  <div id="head-button" style="position:relative;display:inline-block;"
       v-click-outside="closeNotes">

    <button type="button"
            class="button-default show-notifications js-show-notifications"
            @click="toggleNotifications"
    >

<!--      <svg xmlns="http://www.w3.org/2000/svg" width="22.29" height="22.27"-->
<!--           viewBox="0 0 39.451 42.82">-->
<!--        <path id="Path_20" data-name="Path 20"-->
<!--              d="M5.186,19.007H38.073a3.584,3.584,0,0,0,2.4-.75,2.483,2.483,0,0,0,.88-1.989,4.531,4.531,0,0,0-1.25-3.032q-1.25-1.424-2.619-2.793a4.577,4.577,0,0,1-1.109-1.826,13.545,13.545,0,0,1-.587-2.456q-.2-1.326-.283-2.652a31.059,31.059,0,0,0-.946-6.977,13.174,13.174,0,0,0-2.5-5.075,9.962,9.962,0,0,0-4.4-3.01,6.7,6.7,0,0,0-2.141-3.423,5.731,5.731,0,0,0-3.88-1.38,5.761,5.761,0,0,0-3.9,1.38A6.7,6.7,0,0,0,15.6-11.554a9.962,9.962,0,0,0-4.4,3.01A13.29,13.29,0,0,0,8.686-3.468a29.89,29.89,0,0,0-.935,6.977q-.109,1.326-.293,2.652a12.972,12.972,0,0,1-.576,2.456,4.577,4.577,0,0,1-1.109,1.826Q4.4,11.813,3.154,13.236A4.531,4.531,0,0,0,1.9,16.269a2.483,2.483,0,0,0,.88,1.989A3.584,3.584,0,0,0,5.186,19.007Zm1.239-3.5v-.261q.261-.283.7-.739T8.1,13.5q.543-.554,1.043-1.163a7.036,7.036,0,0,0,.956-1.456A9.5,9.5,0,0,0,10.784,9a19.058,19.058,0,0,0,.435-2.326q.163-1.272.25-2.75A23.737,23.737,0,0,1,12.4-2.914,8.871,8.871,0,0,1,14.609-6.7a7.44,7.44,0,0,1,3.076-1.728,1.175,1.175,0,0,0,.576-.293,1,1,0,0,0,.25-.619A4.115,4.115,0,0,1,19.4-11.9a2.777,2.777,0,0,1,2.239-.978,2.753,2.753,0,0,1,2.217.978,4.115,4.115,0,0,1,.891,2.565,1,1,0,0,0,.25.619,1.175,1.175,0,0,0,.576.293A7.44,7.44,0,0,1,28.651-6.7a8.79,8.79,0,0,1,2.2,3.782,24.911,24.911,0,0,1,.967,6.836,25.989,25.989,0,0,0,.217,2.75A18.9,18.9,0,0,0,32.487,9a10.517,10.517,0,0,0,.685,1.88,7.248,7.248,0,0,0,.924,1.456q.522.609,1.065,1.163t.967,1.011q.424.456.663.739v.261ZM21.641,26.463a7.679,7.679,0,0,0,4.01-1.043,7.841,7.841,0,0,0,2.771-2.76,7.608,7.608,0,0,0,1.087-3.7H25.9a4.737,4.737,0,0,1-1.282,3.206,4.015,4.015,0,0,1-2.978,1.185,4.042,4.042,0,0,1-3-1.185,4.737,4.737,0,0,1-1.282-3.206H13.75a7.726,7.726,0,0,0,1.109,3.684,7.991,7.991,0,0,0,2.76,2.76A7.6,7.6,0,0,0,21.641,26.463Z"-->
<!--              transform="translate(-1.904 16.357)" :fill="notificationFlag ? 'rgba(255,255,255, .3)' : '#fff'"/>-->
<!--      </svg>-->

      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 0.857422C13.9505 0.857422 15.8211 1.63225 17.2003 3.01144C18.5795 4.39064 19.3543 6.26123 19.3543 8.2117C19.3543 16.3888 22.3372 18.0003 23.1429 18.0003H0.857178C1.68003 18.0003 4.64575 16.3717 4.64575 8.2117C4.64575 6.26123 5.42057 4.39064 6.79977 3.01144C8.17896 1.63225 10.0496 0.857422 12 0.857422V0.857422Z"
               stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              :stroke="notificationFlag ? '#FE0190' : '#fff'"/>
        <path d="M9.42854 21.1377C9.57673 21.7069 9.90957 22.2108 10.3749 22.5705C10.8403 22.9302 11.4118 23.1254 12 23.1254C12.5881 23.1254 13.1597 22.9302 13.625 22.5705C14.0904 22.2108 14.4232 21.7069 14.5714 21.1377"
               stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              :stroke="notificationFlag ? '#FE0190' : '#fff'"/>
      </svg>

      <span class="notifications-count js-count"
           :class="classBell"
           v-text="notifications ? notifications.length : ''"
      ></span>

    </button>
    <div class="notifications js-notifications"
         :style="{ display: displayState, top: topState + 'px', opacity: opacityState }">
      <h3>Notifications</h3>
      <vue-custom-scrollbar class="scroll-area" :settings="scrollSettings">
        <ul class="notifications-list">
          <HeaderNotificationsList
              v-for="(notification, index) in notifications"
              :key="index"
              :notification="notification"
              @changedStatus="changeStatus"
          />
          <li class="item no-data" v-if="classBell === ''">
            You don't have any notifications
          </li>
        </ul>
<!--        <div class="notifications-list-empty" v-else>-->
<!--          <span class="item no-data" :style="{display: displayState}">You don't have notifications</span>-->
<!--        </div>-->
      </vue-custom-scrollbar>

      <router-link :to="{path: '/profile/notifications'}" class="show-all">Show all notifications</router-link>
    </div>

  </div>
</template>

<script>
import HeaderNotificationsList from "./HeaderNotificationsList";

export default {
  name: "HeaderNotificationsTemplate",
  data() {
    return {
      opacityState: null,
      displayState: null,
      topState: null,
      notificationFlag: false,
      classBell: '',
      scrollSettings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false,
        swipeEasing: false
      },
    }
  },
  props: {
    notifications: {
      type: Array,
    },
  },
  watch: {
    'notifications'(newVal) {
      if (newVal.length > 0) {
        this.classBell = 'visible'
      } else {
        this.classBell = ''
      }
    }
  },
  methods: {
    closeNotes() {
      this.notificationFlag = false
      this.opacityState = '0'
      this.displayState = 'none'
      this.topState = '-999'
    },
    toggleNotifications() {
      this.notificationFlag = !this.notificationFlag
      if (this.notificationFlag) {
        this.opacityState = '1'
        this.displayState = 'block'
        this.topState = '50'
      } else {
        this.opacityState = '0'
        this.displayState = 'none'
        this.topState = '-999'
      }
    },
    changeStatus() {
      this.$emit('changedStatus')
    },
  },
  components: {HeaderNotificationsList}
}
</script>

<style scoped>

.show-notifications svg:hover path {
  transition: .5s ease;
}

.show-notifications svg:hover path {
  stroke: #FE0190;
}

</style>