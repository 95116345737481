import instance from "../api/instance";

export const money = {
    namespaced: true,
    state: {
        money: 0,
    },
    getters: {
        getMoney(state) {
            return state.money
        },
        // getInterval(state) {
        //     return state.interval
        // }
    },
    mutations: {
        SET_MONEY(state, {amount}) {
            state.money = amount
        },
        CLEAR_MONEY(state) {
            state.money = 0
            clearInterval(state.interval)
        },
        // UPDATE_MONEY(state, payload) {
        //     state.money = payload
        // }
    },
    actions: {
        async setMoney({commit}) {
            // let amount = (await instance.get('/api/v2/ieo/wallet/djooky')).data.body.amount
            // commit('SET_MONEY', {amount, interval})
            // let interval = setInterval(async () => {
            //     let amount = (await instance.get('/api/v2/ieo/wallet/djooky')).data.body.amount
            //     commit('SET_MONEY', {amount, interval})
            // }, 5000)
            let currency = ''
            let amount = (await instance.get(`/api/v2/wallets/${currency}`)).data.body.totalAmount
            commit('SET_MONEY', {amount})
        },
        // updateMoney({state, dispatch}) {
        //     if (state.interval === null) {
        //         dispatch('setMoney')
        //     }
        // },
        clearMoney({commit}) {
            commit('CLEAR_MONEY')
        }
    }
}