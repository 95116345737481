<template>
  <v-dialog v-model="dialog">
    <div id="song-not-song-not-authorized"
         class="white-popup song-not-authorized">
      <div class="form-main-content">
        <div class="popup-header">
          <h3 class="form-title">The song was not authorized</h3>
          <div class="popup-song-close" @click="confirmAction">
            <img src="../../assets/img/close.svg" alt="icon-close">
          </div>
        </div>
        <div class="popup-content">
          <div class="popup-content-container">
           <div class="popup-descr">
             <p class="popup-descr-text">
               Your track is not approved for Initial Sale creation, check the requirements and try again
             </p>
           </div>

            <div class="popup-song-details">
              <h3 v-if="title" class="popup-song-title">{{title}}</h3>
              <h3 v-else class="popup-song-title">Song title</h3>
              <span v-if="executor" class="popup-song-executor">{{executor}}</span>
              <span v-else class="popup-song-executor">Song executor</span>
            </div>

            <div class="popup-song-support">
              <span class="popup-song-contact">
                Please contact support
                <a href="mailto:support@djookyx.com" class="popup-song-support-link">support@djookyx.com</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </v-dialog>
</template>

<script>
export default {
  name: "SongNotAuthorizedPopup",
  props:['executor', 'title'],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    openPopups () {
      this.dialog = true;
    },
    confirmAction() {
      this.$emit('confirmAction')
      this.dialog = false
    }
  }
};
</script>

<style scoped>

</style>