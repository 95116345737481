export default {
    methods: {
        copyToClipboard(containerid) {
            let range = document.createRange();
            range.selectNode(document.getElementById(containerid));
            window.getSelection().removeAllRanges(); // clear current selection
            window.getSelection().addRange(range); // to select text
            document.execCommand("copy");
            window.getSelection().removeAllRanges();// to deselect
        }
    },
}