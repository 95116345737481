<template>
  <div id="my-content" ref="top">
    <Header/>
    <main id="root">
      <section class="section">
        <div class="container-small register-container">
          <div class="registration-form-header">
            <h1 class="title page-title registration-form-title">Registration</h1>
          </div>

          <div class="registration__type-btn-wrapper">
            <button class="header-btn-item"
                    @click="formData.personType = 'INVESTOR'"
                    :class="{'active-btn': formData.personType === 'INVESTOR'}"
            >Individual</button>
            <button class="header-btn-item"
                    @click="formData.personType = 'COMPANY'"
                    :class="{'active-btn': formData.personType === 'COMPANY'}"
            >Legal entity</button>
          </div>

          <div class="registration-form-wrap" v-if="formData.personType === 'INVESTOR'">

            <div class="registration-with-another-wrapper">
              <button class="registration-with-another-btn"
                      @click="openDjkAwardsForm"
              >
                <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"
                     :style="{transform: djkAwardsForm ? 'rotate(90deg)' : ''}"
                >
                  <path d="M1.15137 1.80273L5.84863 6.5L1.15137 11.1973" stroke="#FB3099" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span>Register with Djooky Awards</span>
              </button>
              <form class="djooky-awards-register-wrap"
                    @submit.prevent
                    id="sing-in-with-djk-awards"
                    v-if="djkAwardsForm"
              >
                <div class="djooky-awards-register-item">
                  <input type="email" placeholder="Email Djooky Awards"
                         v-model="formDataDjkAwards.email"
                         @blur="$v.formDataDjkAwards.email.$touch()"
                  >
                  <div class="error-message"
                       v-if="$v.formDataDjkAwards.email.$dirty && !$v.formDataDjkAwards.email.required"
                  >This field is required</div>
                  <div class="error-message"
                       v-else-if="$v.formDataDjkAwards.email.$dirty && !$v.formDataDjkAwards.email.email"
                  >This field must match the email format</div>
                </div>
                <div class="djooky-awards-register-item">

                  <div class="password-field">
                    <input placeholder="Password Djooky Awards"
                           :type="passwordTypeDjkAwards"
                           v-model="formDataDjkAwards.password"
                           @blur="$v.formDataDjkAwards.password.$touch()"
                    >
                    <button class="password-show"
                            @click="togglePasswordDjkAwards"
                    >
                      <svg class="show" :style="{display: passwordTypeDjkAwards !== 'text' ? 'none' : 'block'}"
                           xmlns="http://www.w3.org/2000/svg" width="22.389" height="16.792"
                           viewBox="0 0 22.389 16.792">
                        <g transform="translate(9 -2)">
                          <g transform="translate(-9 2)">
                            <path class="a"
                                  d="M11.278,2C4.2,2,0,10.4,0,10.4s4.2,8.4,11.278,8.4c6.913,0,11.11-8.4,11.11-8.4S18.191,2,11.278,2Zm-.084,13.993a5.6,5.6,0,1,1,5.6-5.6A5.6,5.6,0,0,1,11.194,15.993Zm2.8-5.6a2.8,2.8,0,1,1-2.8-2.8A2.789,2.789,0,0,1,13.993,10.4Z"
                                  transform="translate(0 -2)"></path>
                          </g>
                        </g>
                      </svg>
                      <svg class="hide" :style="{display: passwordTypeDjkAwards !== 'password' ? 'none' : 'block'}"
                           xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" viewBox="0 0 16 16">
                        <path
                            d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
                        <path
                            d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
                      </svg>
                    </button>
                  </div>

                  <div class="error-message"
                       v-if="$v.formDataDjkAwards.password.$dirty && !$v.formDataDjkAwards.password.required"
                  >This field is required</div>
                </div>
                <button class="btn djooky-awards-register-btn"
                        :class="{disable: checkValidFormDjkAwards}"
                        :disabled="checkValidFormDjkAwards"
                        @click="submitDjkAwards"
                >Confirm</button>
              </form>
            </div>

            <form class="signup" id="signup_form" @submit.prevent>

<!--              <input type="hidden" name="csrfmiddlewaretoken"-->
<!--                     value="A0FPIT11PYpCrOUQ87Sl21VvKfU82lR7VHlW3W0IFZKpGB9vCiNBhWUkLBeOR5rf">-->

              <h2 class="registration-form-sub-title">
                Public information
                <span class="info hint hint--bottom" data-hint="
                 Your username will be seen by everyone."
                      data-hint-journalist="Your username will be seen by everyone."
                      data-hint-artist="Your username will be seen by everyone."
                      data-hint-musiclower="Your username will be seen by everyone.">
                    <svg height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path
                        d="m277.332031 128c0 11.78125-9.550781 21.332031-21.332031 21.332031s-21.332031-9.550781-21.332031-21.332031 9.550781-21.332031 21.332031-21.332031 21.332031 9.550781 21.332031 21.332031zm0 0"></path><path
                        d="m256 405.332031c-8.832031 0-16-7.167969-16-16v-165.332031h-21.332031c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h37.332031c8.832031 0 16 7.167969 16 16v181.332031c0 8.832031-7.167969 16-16 16zm0 0"></path><path
                        d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path><path
                        d="m304 405.332031h-96c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h96c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"></path></svg>
                </span>
              </h2>
              <div class="registration-form-item">

<!--                <div class="registration-field-wrap">-->
<!--                  <input type="name" name="stageName" id="id_stage_name" placeholder="Stage name"-->
<!--                         v-model="formData.stageName"-->
<!--                         @blur="$v.formData.stageName.$touch()"-->
<!--                  >-->
<!--                  <p class="error-message"-->
<!--                     v-if="$v.formData.stageName.$dirty && !$v.formData.stageName.required">-->
<!--                    Field is required</p>-->
<!--                </div>-->

                <div class="registration-field-wrap">
                  <input type="name" name="firstName" id="id_first_name" placeholder="First name"
                         v-model="formData.firstName"
                         @blur="$v.formData.firstName.$touch()"
                  >
                  <p class="error-message"
                     v-if="$v.formData.firstName.$dirty && !$v.formData.firstName.required">
                    Field is required</p>
                </div>

                <div class="registration-field-wrap">
                  <input type="name" name="lastName" id="id_last_name" placeholder="Last name"
                         v-model="formData.lastName"
                         @blur="$v.formData.lastName.$touch()"
                  >
                  <p class="error-message"
                     v-if="$v.formData.lastName.$dirty && !$v.formData.lastName.required">
                    Field is required</p>
                </div>

                <div class="registration-field-wrap">
                  <!--                    <select v-model="formData.countryId"-->
                  <!--                            name="registration"-->
                  <!--                            id="registration"-->
                  <!--                            class="register-select"-->
                  <!--                            @blur="$v.formData.countryId.$touch()"-->
                  <!--                    >-->
                  <!--                    </select>-->
                  <CountrySelect class="registration__country-select"
                                 id="id_country"
                                 name="countryId"
                                 :options="searchedCountries"
                                 :search="searchSelect"
                                 :search-for-name="formData.countryId"
                                 :placeholder="'Country'"
                                 :identifier="'registration'"
                                 @changed="setCountry"
                                 @searchCheck="searchCheck"
                                 ref="countrySelectRegister"
                  />
                  <p class="error-message" v-if="$v.formData.countryId.$dirty && !$v.formData.countryId.required">Field
                    is
                    required</p>

                </div>
              </div>
              <h2 class="registration-form-sub-title">
                Private information
                <span class="info hint hint--bottom" data-hint="
                 This information will not be displayed anywhere, it's only accessible to Djooky.
                 "
                      data-hint-journalist="This information will not be displayed anywhere, it's only accessible to Djooky."
                      data-hint-artist="This information will not be displayed anywhere, it's only accessible to Djooky."
                      data-hint-musiclower="This information will not be displayed anywhere, it's only accessible to Djooky.">
                    <svg height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path
                        d="m277.332031 128c0 11.78125-9.550781 21.332031-21.332031 21.332031s-21.332031-9.550781-21.332031-21.332031 9.550781-21.332031 21.332031-21.332031 21.332031 9.550781 21.332031 21.332031zm0 0"></path><path
                        d="m256 405.332031c-8.832031 0-16-7.167969-16-16v-165.332031h-21.332031c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h37.332031c8.832031 0 16 7.167969 16 16v181.332031c0 8.832031-7.167969 16-16 16zm0 0"></path><path
                        d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path><path
                        d="m304 405.332031h-96c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h96c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"></path></svg>
                </span>
              </h2>
              <div class="registration-form-item">

<!--                <div class="registration-field-wrap">-->

<!--                  <input type="name" name="firstName" id="id_first_name" placeholder="First name"-->
<!--                         v-model="formData.firstName"-->
<!--                         @blur="$v.formData.firstName.$touch()">-->
<!--                  <p class="error-message"-->
<!--                     v-if="$v.formData.firstName.$dirty && !$v.formData.firstName.required">-->
<!--                    Field is required</p>-->
<!--                </div>-->

                <div class="registration-field-fake-disabled"
                     v-if="djkAwardsFlag"
                     v-text="formData.email"
                ></div>

                <div class="registration-field-wrap"
                     v-else
                >
                  <input type="email" name="email" id="id_email" placeholder="E-mail"
                         v-model="formData.email"
                         @blur="$v.formData.email.$touch()"
                  >
                  <p class="error-message" v-if="$v.formData.email.$dirty && !$v.formData.email.required">Field is
                    required</p>
                  <p class="error-message" v-else-if="$v.formData.email.$dirty && !$v.formData.email.email">This must be
                    an email</p>
                </div>

                <!--                <div class="country-style-block" th:each="country :${countries.elements}" style="display:none;">-->

                <!--                </div>-->
                <div class="registration-field-wrap">
                  <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="date"
                          label="Date of birth"
                          prepend-icon="mdi-calendar"
                          @blur="$v.formData.dateOfBirth.$touch()"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date"
                        :active-picker.sync="activePicker"
                        :max="(new Date(new Date().setFullYear(new Date().getFullYear() - 18))).toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="save"
                    ></v-date-picker>
                  </v-menu>
                  <!--                    <date-picker-->
                  <!--                        v-model="dateOfBirth"-->
                  <!--                        value-type="format"-->
                  <!--                        :disabled-date="disabledBeforeTodayAndAfterAWeek"-->
                  <!--                        class="date-of-birth"-->
                  <!--                        placeholder="date of birth"-->
                  <!--                        @blur="$v.formData.dateOfBirth.$touch()"-->
                  <!--                    ></date-picker>-->
                  <p class="error-message" v-if="$v.formData.dateOfBirth.$dirty && !$v.formData.dateOfBirth.required">
                    Field is
                    required</p>
                </div>
              </div>
              <h2 class="registration-form-sub-title">
                Account security</h2>
              <div class="registration-form-item">
                <div class="registration-field-wrap password-field-wrap">
                  <div class="password-field">
                    <input
                        :type="passwordType1"
                        v-model="password1"
                        name="password"
                        id="id_password1"
                        ref="ref_password1"
                        class="form-password"
                        placeholder="Password"
                        autocomplete="new-password"
                        @focus="focus = true"
                        @blur="focus = false"
                    >

                    <a href="javascript:void(0);" class="password-show" @click="togglePassword1">
                      <svg class="show" :style="{display: passwordType1 !== 'text' ? 'none' : 'block'}"
                           xmlns="http://www.w3.org/2000/svg" width="22.389" height="16.792"
                           viewBox="0 0 22.389 16.792">
                        <g transform="translate(9 -2)">
                          <g transform="translate(-9 2)">
                            <path class="a"
                                  d="M11.278,2C4.2,2,0,10.4,0,10.4s4.2,8.4,11.278,8.4c6.913,0,11.11-8.4,11.11-8.4S18.191,2,11.278,2Zm-.084,13.993a5.6,5.6,0,1,1,5.6-5.6A5.6,5.6,0,0,1,11.194,15.993Zm2.8-5.6a2.8,2.8,0,1,1-2.8-2.8A2.789,2.789,0,0,1,13.993,10.4Z"
                                  transform="translate(0 -2)"></path>
                          </g>
                        </g>
                      </svg>
                      <svg class="hide" :style="{display: passwordType1 !== 'password' ? 'none' : 'block'}"
                           xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" viewBox="0 0 16 16">
                        <path
                            d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
                        <path
                            d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
                      </svg>
                    </a>
                    <p class="error-message"
                       v-if="$v.password1.$dirty && !$v.password1.required">
                      Field is required</p>
                  </div>
                  <div v-show="focus || !requireRulesForPassword"
                       class="password-tooltip registration-password-tooltip">
                    <p class="register-new-acc-text">Password must contain:</p>
                    <div class="holder-conditions">
                      <div class="group-conditions">
                        <div class="group-conditions-wrap">
                          <div class="condition-item" :class="hasMinimumLength ? 'has-required' : ''">
                            <svg :class="hasMinimumLength ? 'has-required' : ''"
                                 width="11" height="8" viewBox="0 0 11 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                                  fill="#8692A6"/>
                            </svg>
                            <p>8 or more characters</p>
                          </div>
                          <div class="condition-item" :class="hasLowercase ? 'has-required' : ''">
                            <svg :class="hasLowercase ? 'has-required' : ''"
                                 width="11" height="8" viewBox="0 0 11 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                                  fill="#8692A6"/>
                            </svg>
                            <p>Lowercase letter</p>
                          </div>
                          <div class="condition-item" :class="hasSpecial ? 'has-required' : ''">
                            <svg :class="hasSpecial ? 'has-required' : ''"
                                 width="11" height="8" viewBox="0 0 11 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                                  fill="#8692A6"/>
                            </svg>
                            <p>Symbols</p>
                          </div>
                          <div class="condition-item" :class="hasUppercase ? 'has-required' : ''">
                            <svg :class="hasUppercase ? 'has-required' : ''"
                                 width="11" height="8" viewBox="0 0 11 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                                  fill="#8692A6"/>
                            </svg>
                            <p>Uppercase letter</p>
                          </div>
                          <div class="condition-item" :class="hasNumber ? 'has-required' : ''">
                            <svg :class="hasNumber ? 'has-required' : ''"
                                 width="11" height="8" viewBox="0 0 11 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                                  fill="#8692A6"/>
                            </svg>
                            <p>Numbers</p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="pass-field-error" v-if="!requireRulesForPassword">
                    <div class="phone-field-error-msg pass-field-error-msg">Your password doesn't fit the requirements</div>
                  </div>

                </div>
                <div class="registration-field-wrap password-field-wrap">
                  <div class="password-field">
                    <input
                        :type="passwordType2"
                        v-model="password2"
                        name="confirmPassword"
                        id="id_password2"
                        class="form-password"
                        placeholder="Re-Enter Password"
                        autocomplete="new-password"
                        @blur="$v.password2.$touch()"
                    >
                    <a href="javascript:void(0);" class="password-show" @click="togglePassword2">
                      <svg class="show" :style="{display: passwordType2 !== 'text' ? 'none' : 'block'}"
                           xmlns="http://www.w3.org/2000/svg" width="22.389" height="16.792"
                           viewBox="0 0 22.389 16.792">
                        <g transform="translate(9 -2)">
                          <g transform="translate(-9 2)">
                            <path class="a"
                                  d="M11.278,2C4.2,2,0,10.4,0,10.4s4.2,8.4,11.278,8.4c6.913,0,11.11-8.4,11.11-8.4S18.191,2,11.278,2Zm-.084,13.993a5.6,5.6,0,1,1,5.6-5.6A5.6,5.6,0,0,1,11.194,15.993Zm2.8-5.6a2.8,2.8,0,1,1-2.8-2.8A2.789,2.789,0,0,1,13.993,10.4Z"
                                  transform="translate(0 -2)"></path>
                          </g>
                        </g>
                      </svg>
                      <svg class="hide" :style="{display: passwordType2 !== 'password' ? 'none' : 'block'}"
                           xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" viewBox="0 0 16 16">
                        <path
                            d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
                        <path
                            d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
                      </svg>
                    </a>
                  </div>

                  <p class="error-message"
                     v-if="$v.password2.$dirty && !$v.password2.required">
                    Field is required</p>
                  <div class="pass-field-error" v-else-if="$v.password2.$dirty && !$v.password2.sameAsPassword">
                    <div class="phone-field-error-msg pass-field-error-msg">The passwords must match</div>
                  </div>

                </div>
              </div>

              <h2 class="registration-form-sub-title">Your Number</h2>
              <div class="registration-form-item">
                <div class="registration-field-wrap phone-field-wrap">
                  <div class="phone-field">

<!--                    <div class="form-phone-wrapper">-->
<!--                      <input type="tel" name="phoneNumber" id="id_phone_number" placeholder="Phone number"-->
<!--                             class="form-phone" :class="{disabled: disablePhoneInput}" oncopy="return false"-->
<!--                             ondrag="return false"-->
<!--                             v-model="phoneNumber"-->
<!--                             :disabled="disablePhoneInput"-->
<!--                             onkeyup="this.value = this.value.replace(/[^\d|\+]/g,'');"-->
<!--                      >-->
<!--                    </div>-->
<!--                    <button type="button" id="form-phone-submit" class="form-phone-submit"-->
<!--                            :class="{disabled: disabledVerifyButtonCheck || phoneNumberValidation}"-->
<!--                            :disabled="disabledVerifyButtonCheck || phoneNumberValidation"-->
<!--                            @click.prevent="openPopupForm"-->
<!--                            v-text="verifyButtonText"-->
<!--                    >-->
<!--                    </button>-->
<!--                    <span class="form-phone-submit-success">-->
<!--                                <svg width="120" height="52" viewBox="0 0 120 52" fill="none"-->
<!--                                     xmlns="http://www.w3.org/2000/svg">-->
<!--                                    <rect x="0.5" y="0.5" width="119" height="51" rx="9.5" fill="#F7FCF3"-->
<!--                                          stroke="#DBE9CE"></rect>-->
<!--                                    <path d="M52 25.0769L58.6111 32L69 20" stroke="#97BE72" stroke-width="2.5"-->
<!--                                          stroke-linecap="round" stroke-linejoin="round"></path>-->
<!--                                </svg>-->
<!--                            </span>-->
<!--                    <p class="error-message" v-if="phoneNumberValidation">Phone number is invalid</p>-->

                    <div class="form-phone-wrapper">
                      <input type="tel" name="phoneNumber" id="id_phone_number" placeholder="Phone number"
                             class="form-phone" oncopy="return false"
                             ondrag="return false"
                             v-model="formData.phoneNumber"
                             @blur="$v.formData.phoneNumber.$touch()"
                      >
                    </div>
                    <p class="error-message"
                       v-if="$v.formData.phoneNumber.$dirty && !$v.formData.phoneNumber.required">
                      Field is required</p>
                    <p class="error-message"
                       v-if="$v.formData.phoneNumber.$dirty && !$v.formData.phoneNumber.integer">
                      Only Numbers</p>
                  </div>

                  <div class="phone-field-error" v-if="incorrectPhoneNumber">
                    <div class="phone-field-error-msg" v-text="error.phoneError"></div>
                  </div>
                </div>
              </div>
              <div class="registration-form-item registration-form-item-checkbox">

                <div class="profile-checkbox-item">
                  <GeneralCustomCheckBox :checkBoxId="'register__terms'"
                                         @checked="agreedTerms"
                                         ref="registerTermsCheckbox"
                  />
                  <p class="profile-checkbox-description">
                    I have read and I agree and accept DjookyX and Mangopay the
                    <router-link :to="{name: 'TermsAndConditions'}" class="registration__link" target='_blank'>Terms and Conditions</router-link>,
                    and by ticking this box I accept to be legally bound to these Terms and Conditions.<span class="asterisk">*</span>
                  </p>
                </div>

                <!--                <div class="registration-form-checkbox ">-->
                <!--                  <CustomCheckbox @checked="agreedTerms" :checkBoxId="'1'"/>-->
                <!--                  <div class="register-checkbox-wrapper">I have read and I agree and accept the-->
                <!--                    <router-link :to="{name: 'TermsAndConditions'}" target='_blank'>Terms and Conditions</router-link>,-->
                <!--                    and by ticking this box I accept to be legally bound to these Terms and Conditions.<span style="color: red;">*</span>-->
                <!--                  </div>-->
                <!--                </div>-->

                <div class="profile-checkbox-item">
                  <GeneralCustomCheckBox :checkBoxId="'register__privacy'"
                                         @checked="agreedPrivacy"
                                         ref="registerPrivacyCheckbox"
                  />
                  <p class="profile-checkbox-description">
                    I confirm that I have read the
                    <router-link :to="{name: 'Policy'}" class="registration__link" target='_blank'>Privacy Policy</router-link>
                    <span class="asterisk">*</span>
                  </p>
                </div>

                <!--                <div class="registration-form-checkbox">-->
                <!--                  <CustomCheckbox @checked="agreedPrivacy" :checkBoxId="'2'"/>-->
                <!--                  <div class="register-checkbox-wrapper">-->
                <!--                    I confirm that I have read the-->
                <!--                    <router-link :to="{name: 'Policy'}" target='_blank'>Privacy Policy</router-link>-->
                <!--                    <span style="color: red;">*</span>-->
                <!--                  </div>-->
                <!--                </div>-->

                <div class="profile-checkbox-item">
                  <GeneralCustomCheckBox :checkBoxId="'register__emails'"
                                         @checked="agreedEmails"
                                         ref="registerEmailsCheckbox"
                  />
                  <p class="profile-checkbox-description">
                    <span v-if="toggleTextSize">By checking this box, you agree to your
                      email... <span class="checkbox-text-more" @click="toggleTextSize = false">read more</span>
                    </span>
                    <span v-else>By checking this box, you agree to your email being
                      stored and used to receive newsletters, notifications (such as information about new initial sales,
                      new artists etc.) and promo materials (such as press releases or social media posts) from us and our
                      partners by Djooky via email. You understand that you can withdraw your consent at any time. Your
                      data will be processed in accordance with our
                      <router-link :to="{name: 'Policy'}" class="registration__link" target='_blank'>Privacy Policy</router-link>.
                      <span class="checkbox-text-more" @click="toggleTextSize = true">hide</span>
                    </span>
                  </p>
                </div>

                <!--                <div class="registration-form-checkbox ">-->
                <!--                  <CustomCheckbox @checked="agreedEmails" :checkBoxId="'3'"/>-->
                <!--                  <div class="register-checkbox-wrapper" v-if="toggleTextSize">By checking this box, you agree to your-->
                <!--                    email... <span class="checkbox-text-more" @click="toggleTextSize = false">Read more</span>-->
                <!--                  </div>-->
                <!--                  <div class="register-checkbox-wrapper" v-else>By checking this box, you agree to your email being-->
                <!--                    stored and used to receive newsletters, notifications (such as information about new initial sales,-->
                <!--                    new artists etc.) and promo materials (such as press releases or social media posts) from us and our-->
                <!--                    partners by Djooky via email. You understand that you can withdraw your consent at any time. Your-->
                <!--                    data will be processed in accordance with our-->
                <!--                    <router-link :to="{name: 'Policy'}" target='_blank'>Privacy Policy</router-link>-->
                <!--                    <span class="checkbox-text-more" @click="toggleTextSize = true">Hide</span>-->
                <!--                  </div>-->
                <!--                </div>-->

              </div>

              <!--              <div class="registration-info">-->
              <!--                <span>By clicking Register you agree with</span>-->
              <!--                <div>-->
              <!--                  <a href="/market/terms-conditions/" target="_blank">Terms of Use</a>-->
              <!--                  and-->
              <!--                  <a href="/market/policy/" target="_blank">Privacy Policy</a></div>-->
              <!--              </div>-->




              <button  class="btn registr-btn submit"
                      :class="{disabled: !requiredFieldsIsTrue}"
                      :disabled="!requiredFieldsIsTrue"
                      @click="preSubmitForm"
              >Complete Registration</button>

            </form>

          </div>

          <div class="registration-form-wrap" v-if="formData.personType === 'COMPANY'">

            <form class="signup" id="signup_form__legal" @submit.prevent>

              <h2 class="registration-form-sub-title">
                Public information
                <span class="info hint hint--bottom" data-hint="
                 Your username will be seen by everyone."
                      data-hint-journalist="Your username will be seen by everyone."
                      data-hint-artist="Your username will be seen by everyone."
                      data-hint-musiclower="Your username will be seen by everyone.">
                    <svg height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path
                        d="m277.332031 128c0 11.78125-9.550781 21.332031-21.332031 21.332031s-21.332031-9.550781-21.332031-21.332031 9.550781-21.332031 21.332031-21.332031 21.332031 9.550781 21.332031 21.332031zm0 0"></path><path
                        d="m256 405.332031c-8.832031 0-16-7.167969-16-16v-165.332031h-21.332031c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h37.332031c8.832031 0 16 7.167969 16 16v181.332031c0 8.832031-7.167969 16-16 16zm0 0"></path><path
                        d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path><path
                        d="m304 405.332031h-96c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h96c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"></path></svg>
                </span>
              </h2>

              <div class="registration-form-item">
                <div class="registration-field-wrap">
                  <input type="text" name="companyName" id="id__company-name" placeholder="Company name"
                         v-model="companyName"
                         @blur="$v.companyName.$touch()"
                  >
                  <p class="error-message"
                     v-if="$v.companyName.$dirty && !$v.companyName.required"
                  >Field is required</p>
                </div>
              </div>

              <h2 class="registration-form-sub-title">
                Privacy information
                <span class="info hint hint--bottom" data-hint="
                 This information will not be displayed anywhere, it's only accessible to Djooky.
                 "
                      data-hint-journalist="This information will not be displayed anywhere, it's only accessible to Djooky."
                      data-hint-artist="This information will not be displayed anywhere, it's only accessible to Djooky."
                      data-hint-musiclower="This information will not be displayed anywhere, it's only accessible to Djooky.">
                    <svg height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path
                        d="m277.332031 128c0 11.78125-9.550781 21.332031-21.332031 21.332031s-21.332031-9.550781-21.332031-21.332031 9.550781-21.332031 21.332031-21.332031 21.332031 9.550781 21.332031 21.332031zm0 0"></path><path
                        d="m256 405.332031c-8.832031 0-16-7.167969-16-16v-165.332031h-21.332031c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h37.332031c8.832031 0 16 7.167969 16 16v181.332031c0 8.832031-7.167969 16-16 16zm0 0"></path><path
                        d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path><path
                        d="m304 405.332031h-96c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h96c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"></path></svg>
                </span>
              </h2>

              <div class="registration-form-item">
                <div class="registration-field-wrap">
                  <input type="text" name="firstName" id="id__legal-first-name" placeholder="First name"
                         v-model="formData.firstName"
                         @blur="$v.formData.firstName.$touch()"
                  >

                  <p class="error-message"
                     v-if="$v.formData.firstName.$dirty && !$v.formData.firstName.required"
                  >Field is required</p>
                </div>

                <div class="registration-field-wrap">
                  <input type="text" name="lastName" id="id__legal-last-name" placeholder="Last name"
                         v-model="formData.lastName"
                         @blur="$v.formData.lastName.$touch()"
                  >

                  <p class="error-message"
                     v-if="$v.formData.lastName.$dirty && !$v.formData.lastName.required"
                  >Field is required</p>
                </div>

                <div class="registration-field-wrap">
                  <input type="email" name="email" id="id__legal-email" placeholder="E-mail"
                         v-model="formData.email"
                         @blur="$v.formData.email.$touch()"
                  >

                  <p class="error-message"
                     v-if="$v.formData.email.$dirty && !$v.formData.email.required"
                  >Field is required</p>
                  <p class="error-message"
                     v-else-if="$v.formData.email.$dirty && !$v.formData.email.email"
                  >This must be an email</p>
                </div>

                <div class="registration-field-wrap">
                  <CountrySelect class="registration__country-select"
                                 id="id__legal-country"
                                 name="countryId"
                                 :options="searchedCountries"
                                 :search="searchSelect"
                                 :placeholder="'Choose your country'"
                                 :identifier="'registration__legal'"
                                 @changed="setCountry"
                                 @searchCheck="searchCheck"
                                 ref="countrySelectRegister"
                  />
                  <p class="error-message"
                     v-if="$v.formData.countryId.$dirty && !$v.formData.countryId.required"
                  >Field is required</p>
                </div>
              </div>

              <h2 class="registration-form-sub-title">Account security</h2>

              <div class="registration-form-item">
                <div class="registration-field-wrap password-field-wrap">
                  <div class="password-field">
                    <input
                        :type="passwordType1"
                        v-model="password1"
                        name="password"
                        id="id__legal-password1"
                        ref="ref_password1"
                        class="form-password"
                        placeholder="Password"
                        autocomplete="new-password"
                        @focus="focus = true"
                        @blur="focus = false"
                    >

                    <a href="javascript:void(0);" class="password-show" @click="togglePassword1">
                      <svg class="show" :style="{display: passwordType1 !== 'text' ? 'none' : 'block'}"
                           xmlns="http://www.w3.org/2000/svg" width="22.389" height="16.792"
                           viewBox="0 0 22.389 16.792">
                        <g transform="translate(9 -2)">
                          <g transform="translate(-9 2)">
                            <path class="a"
                                  d="M11.278,2C4.2,2,0,10.4,0,10.4s4.2,8.4,11.278,8.4c6.913,0,11.11-8.4,11.11-8.4S18.191,2,11.278,2Zm-.084,13.993a5.6,5.6,0,1,1,5.6-5.6A5.6,5.6,0,0,1,11.194,15.993Zm2.8-5.6a2.8,2.8,0,1,1-2.8-2.8A2.789,2.789,0,0,1,13.993,10.4Z"
                                  transform="translate(0 -2)"></path>
                          </g>
                        </g>
                      </svg>
                      <svg class="hide" :style="{display: passwordType1 !== 'password' ? 'none' : 'block'}"
                           xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" viewBox="0 0 16 16">
                        <path
                            d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
                        <path
                            d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
                      </svg>
                    </a>
                    <p class="error-message"
                       v-if="$v.password1.$dirty && !$v.password1.required"
                    >Field is required</p>

                  </div>

                  <div class="password-tooltip registration-password-tooltip"
                       v-show="focus || !requireRulesForPassword"
                  >
                    <p class="register-new-acc-text">Password must contain:</p>
                    <div class="holder-conditions">
                      <div class="group-conditions">
                        <div class="group-conditions-wrap">
                          <div class="condition-item" :class="hasMinimumLength ? 'has-required' : ''">
                            <svg :class="hasMinimumLength ? 'has-required' : ''"
                                 width="11" height="8" viewBox="0 0 11 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                                  fill="#8692A6"/>
                            </svg>
                            <p>8 or more characters</p>
                          </div>
                          <div class="condition-item" :class="hasLowercase ? 'has-required' : ''">
                            <svg :class="hasLowercase ? 'has-required' : ''"
                                 width="11" height="8" viewBox="0 0 11 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                                  fill="#8692A6"/>
                            </svg>
                            <p>Lowercase letter</p>
                          </div>
                          <div class="condition-item" :class="hasSpecial ? 'has-required' : ''">
                            <svg :class="hasSpecial ? 'has-required' : ''"
                                 width="11" height="8" viewBox="0 0 11 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                                  fill="#8692A6"/>
                            </svg>
                            <p>Symbols</p>
                          </div>
                          <div class="condition-item" :class="hasUppercase ? 'has-required' : ''">
                            <svg :class="hasUppercase ? 'has-required' : ''"
                                 width="11" height="8" viewBox="0 0 11 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                                  fill="#8692A6"/>
                            </svg>
                            <p>Uppercase letter</p>
                          </div>
                          <div class="condition-item" :class="hasNumber ? 'has-required' : ''">
                            <svg :class="hasNumber ? 'has-required' : ''"
                                 width="11" height="8" viewBox="0 0 11 8" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M10.7862 0.20917C10.7202 0.142864 10.6419 0.0902593 10.5557 0.0543671C10.4694 0.018475 10.377 0 10.2836 0C10.1903 0 10.0978 0.018475 10.0116 0.0543671C9.92538 0.0902593 9.84705 0.142864 9.78111 0.20917L3.73653 6.28162L1.21321 3.74964C1.07993 3.61591 0.899164 3.54078 0.71068 3.54078C0.522196 3.54078 0.341431 3.61591 0.208153 3.74964C0.0748748 3.88338 0 4.06476 0 4.25389C0 4.34754 0.0183821 4.44027 0.0540971 4.52679C0.0898121 4.61331 0.14216 4.69192 0.208153 4.75814L3.23044 7.79079C3.50843 8.06974 3.9575 8.06974 4.23549 7.79079L10.7862 1.21767C11.0713 0.938722 11.0713 0.488116 10.7862 0.20917Z"
                                  fill="#8692A6"/>
                            </svg>
                            <p>Numbers</p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="pass-field-error" v-if="!requireRulesForPassword">
                    <div class="phone-field-error-msg pass-field-error-msg">Your password doesn't fit the requirements</div>
                  </div>

                </div>
                <div class="registration-field-wrap password-field-wrap">
                  <div class="password-field">
                    <input
                        :type="passwordType2"
                        v-model="password2"
                        name="confirmPassword"
                        id="id__legal-password2"
                        class="form-password"
                        placeholder="Re-Enter Password"
                        autocomplete="new-password"
                        @blur="$v.password2.$touch()"
                    >

                    <a href="javascript:void(0);" class="password-show" @click="togglePassword2">
                      <svg class="show" :style="{display: passwordType2 !== 'text' ? 'none' : 'block'}"
                           xmlns="http://www.w3.org/2000/svg" width="22.389" height="16.792"
                           viewBox="0 0 22.389 16.792">
                        <g transform="translate(9 -2)">
                          <g transform="translate(-9 2)">
                            <path class="a"
                                  d="M11.278,2C4.2,2,0,10.4,0,10.4s4.2,8.4,11.278,8.4c6.913,0,11.11-8.4,11.11-8.4S18.191,2,11.278,2Zm-.084,13.993a5.6,5.6,0,1,1,5.6-5.6A5.6,5.6,0,0,1,11.194,15.993Zm2.8-5.6a2.8,2.8,0,1,1-2.8-2.8A2.789,2.789,0,0,1,13.993,10.4Z"
                                  transform="translate(0 -2)"></path>
                          </g>
                        </g>
                      </svg>
                      <svg class="hide" :style="{display: passwordType2 !== 'password' ? 'none' : 'block'}"
                           xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" viewBox="0 0 16 16">
                        <path
                            d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
                        <path
                            d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
                      </svg>
                    </a>

                  </div>

                  <p class="error-message"
                     v-if="$v.password2.$dirty && !$v.password2.required">
                    Field is required</p>

                  <div class="pass-field-error" v-else-if="$v.password2.$dirty && !$v.password2.sameAsPassword">
                    <div class="phone-field-error-msg pass-field-error-msg">The passwords must match</div>
                  </div>


                </div>
              </div>

              <h2 class="registration-form-sub-title">Your Number</h2>
              <div class="registration-form-item">
                <div class="registration-field-wrap phone-field-wrap">
                  <div class="phone-field">
                    <div class="form-phone-wrapper">
                      <input type="tel" name="phoneNumber" id="id__legal-phone-number" placeholder="Phone number"
                             class="form-phone" oncopy="return false"
                             ondrag="return false"
                             v-model="formData.phoneNumber"
                             @blur="$v.formData.phoneNumber.$touch()"
                      >
                    </div>
                    <p class="error-message"
                       v-if="$v.formData.phoneNumber.$dirty && !$v.formData.phoneNumber.required">
                      Field is required</p>
                    <p class="error-message"
                       v-if="$v.formData.phoneNumber.$dirty && !$v.formData.phoneNumber.integer">
                      Only Numbers</p>
                  </div>

                  <div class="phone-field-error" v-if="incorrectPhoneNumber">
                    <div class="phone-field-error-msg" v-text="error.phoneError"></div>
                  </div>
                </div>
              </div>
              <div class="registration-form-item registration-form-item-checkbox">

                <div class="profile-checkbox-item">
                  <GeneralCustomCheckBox :checkBoxId="'register__legal-terms'"
                                         @checked="agreedTerms"
                                         ref="registerTermsCheckbox"
                  />
                  <p class="profile-checkbox-description">
                    I have read and I agree and accept DjookyX and Mangopay the
                    <router-link :to="{name: 'TermsAndConditions'}" class="registration__link" target='_blank'>Terms and Conditions</router-link>,
                    and by ticking this box I accept to be legally bound to these Terms and Conditions.<span class="asterisk">*</span>
                  </p>
                </div>

                <div class="profile-checkbox-item">
                  <GeneralCustomCheckBox :checkBoxId="'register__legal-privacy'"
                                         @checked="agreedPrivacy"
                                         ref="registerPrivacyCheckbox"
                  />
                  <p class="profile-checkbox-description">
                    I confirm that I have read the
                    <router-link :to="{name: 'Policy'}" class="registration__link" target='_blank'>Privacy Policy</router-link>
                    <span class="asterisk">*</span>
                  </p>
                </div>

                <div class="profile-checkbox-item">
                  <GeneralCustomCheckBox :checkBoxId="'register__legal-emails'"
                                         @checked="agreedEmails"
                                         ref="registerEmailsCheckbox"
                  />
                  <p class="profile-checkbox-description">
                    <span v-if="toggleTextSize">By checking this box, you agree to your
                      email... <span class="checkbox-text-more" @click="toggleTextSize = false">read more</span>
                    </span>
                    <span v-else>By checking this box, you agree to your email being
                      stored and used to receive newsletters, notifications (such as information about new initial sales,
                      new artists etc.) and promo materials (such as press releases or social media posts) from us and our
                      partners by Djooky via email. You understand that you can withdraw your consent at any time. Your
                      data will be processed in accordance with our
                      <router-link :to="{name: 'Policy'}" class="registration__link" target='_blank'>Privacy Policy</router-link>.
                      <span class="checkbox-text-more" @click="toggleTextSize = true">hide</span>
                    </span>
                  </p>
                </div>

              </div>

              <button  class="btn registr-btn registr-btn-verfc submit"
                       style="box-shadow: 0 6px 24px rgba(255, 0, 144, 0.29);"
                       :class="{disabled: !requiredFieldsIsTrue}"
                       :disabled="!requiredFieldsIsTrue"
                       @click="preSubmitForm"
              >
                Complete Registration
              </button>

            </form>

          </div>

          <!--          <PhoneVerifyPopup ref="verifyPopup" :phoneVal="phoneNumber" @confirmAction="confirmPhoneNumberMeth"/>-->
          <ServerErrorPopup ref="serverErrorPopup" :errorMessage="error.serverError"/>

          <DisclaimerPopup ref="disclaimerPopup" @confirmDisclaimer="confirmDisclaimer"/>
        </div>
      </section>
    </main>
    <Footer/>
  </div>
</template>

<script>

// import PhoneVerifyPopup from "../components/popups/PhoneVerifyPopup";
// import CustomCheckbox from "../components/inputs/CustomCheckbox";
// import DatePicker from 'vue2-datepicker';


import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import DisclaimerPopup from "../components/popups/DisclaimerPopup";
import ServerErrorPopup from "../components/popups/ServerErrorPopup";
import {required, email, sameAs, integer} from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import CountrySelect from '../components/inputs/CountrySelect';
import GeneralCustomCheckBox from "@/components/inputs/GeneralCustomCheckBox";
import scrollToElement from "@/mixins/scrollToElement";


export default {
  name: "Register",
  data() {
    return {
      djkAwardsForm: false,
      djkAwardsFlag: false,
      passwordTypeDjkAwards: 'password',
      formDataDjkAwards: {
        email: '',
        password: '',
      },
      toggleTextSize: true,
      termsAndConditionsAgreed: false,
      privacyPolicyAgreed: false,
      receiveEmailsAgreed: false,
      // confirmPhoneNumber: true,
      disclaimerConfirm: false,
      buttonTextFlag: false,
      // verifyButtonText: 'Verify me',
      password1: '',
      password2: '',
      passwordType1: "password",
      passwordType2: "password",
      phoneNumber: '',
      dateOfBirth: '',
      confirmActionFlag: false,
      incorrectPhoneNumber: false,
      countriesList: [],
      // verifyButtonDisabledFlag: true,
      // verifyButtonDisabledFlag2: false,
      // disablePhoneInput: false,
      focus: false,
      searchSelect: '',
      countrySelectOpen: null,
      errorMessage: '',
      companyName: '',
      formData: {
        personType: 'INVESTOR',
        countryId: '',
        email: '',
        password: '',
        phoneNumber: '',
        firstName: '',
        lastName: '',
        dateOfBirth: null,
        token: null,
      },
      error: {
        phoneError: '',
        serverError: ''
      },
      requireRulesForPassword: true,
      hasMinimumLength: false,
      hasNumber: false,
      hasLowercase: false,
      hasUppercase: false,
      hasSpecial: false,
      activePicker: null,
      date: null,
      menu: false,
    }
  },
  validations() {
    return {
      companyName: {
        required: this.formData.personType === 'COMPANY' ? required : ''
      },
      formDataDjkAwards: {
        email: {
          required,
          email
        },
        password: {
          required,
        },
      },
      password1: {
        required
      },
      password2: {
        required,
        sameAsPassword: sameAs(function () {
          return this.password1
        })
      },
      formData: {
        username: {
          required
        },
        firstName: {
          required
        },
        lastName: {
          required
        },
        email: {
          required,
          email
        },
        countryId: {
          required
        },
        dateOfBirth: {
          required: this.formData.personType === 'INVESTOR' ? required : ''
        },
        phoneNumber: {
          required,
          integer
        },
        isSubscribeOnPromoMaterial: {
          required
        }
      }
    }
  },
  mixins: [scrollToElement],
  methods: {
    ...mapActions({
      setTabs: 'userTabs/setTabs',
    }),
    countryClickOutside(event) {
      if (!event.target.closest('.general_select')) {
        this.$refs.countrySelectRegister.closeList()
      }
    },
    setCountry(data) {
      this.formData.countryId = data.name
    },
    searchCheck(data) {
      this.searchSelect = data
    },
    // disabledBeforeTodayAndAfterAWeek(date) {
    //   const today = new Date();
    //   today.setHours(0, 0, 0, 0);
    //   today.setFullYear(today.getFullYear() - 18);
    //   return date > today;
    //   // return date > new Date(today.getTime() - 56 * 7 * 24 * 3600 * 1000);
    // },
    // confirmPhoneNumberMeth() {
    //   this.disablePhoneInput = true
    //   this.confirmPhoneNumber = true
    //   this.confirmActionFlag = true
    //   this.$refs.disclaimerPopup.openPopups()
    // },
    // confirmPhoneNumberMeth() {
    //   this.confirmPhoneNumber = true
    //   // this.confirmActionFlag = true
    //   this.$refs.disclaimerPopup.openPopups()
    // },
    agreedTerms(value) {
      this.termsAndConditionsAgreed = value
    },
    agreedPrivacy(value) {
      this.privacyPolicyAgreed = value
    },
    agreedEmails(value) {
      console.log(value)
      this.formData.isSubscribeOnPromoMaterial = value
    },
    togglePassword1() {
      this.passwordType1 = this.passwordType1 === "password" ? "text" : "password";
    },
    togglePassword2() {
      this.passwordType2 = this.passwordType2 === "password" ? "text" : "password";
    },
    togglePasswordDjkAwards() {
      this.passwordTypeDjkAwards = this.passwordTypeDjkAwards === 'password' ? 'text' : 'password';
    },
    checkQueryDjkAwards() {
      if (this.$route.query.djookyawards) {
        this.djkAwardsForm = true
      }
    },
    clearFieldDjkAwardsForm() {
      this.formDataDjkAwards.email = ''
      this.formDataDjkAwards.password = ''
      this.passwordTypeDjkAwards = 'password'
    },
    openDjkAwardsForm() {
      this.djkAwardsForm = !this.djkAwardsForm
    },
    submitDjkAwards() {
      if (this.checkValidFormDjkAwards) {
        return
      } else {
        this.$load(async () => {
          await this.$api.auth.signInWithDjkAwards(this.formDataDjkAwards).then((res) => {
            let response = res.data.body
            this.formData.countryId = response.country
            this.formData.email = response.email
            this.formData.firstName = response.firstName
            this.formData.lastName = response.lastName
            this.formData.phoneNumber = response.phoneNumber
            this.formData.dateOfBirth = response.dateOfBirth
            // add date in library & variable
            this.date = response.dateOfBirth
            this.djkAwardsForm = false
            this.djookyAwardsRegister()
          })
        }, (error) => {
          if(error) {
            if (error.data.errors) {
              this.error.serverError = error.data.errors[0].message
              this.$refs.serverErrorPopup.openPopups()
            }
          }
        })
      }
    },
    djookyAwardsRegister() {
      this.djkAwardsFlag = true
      this.$v.$touch()
    },
    // openPopupForm() {
    //   if (this.$v.$invalid) {
    //     this.$v.$touch()
    //     this.goTo('top')
    //     return
    //   } else {
    //     this.verifyButtonText = 30
    //     this.verifyButtonDisabledFlag2 = true
    //     const interval = setInterval(() => {
    //       this.verifyButtonText--
    //       if (this.verifyButtonText === 0) {
    //         this.verifyButtonText = 'Resend'
    //         this.verifyButtonDisabledFlag2 = false
    //         clearInterval(interval)
    //       }
    //     }, 1000)
    //     this.$load(async () => {
    //       await this.$api.auth.dispatch({phoneNumber: this.phoneNumber}).then(() => {
    //         this.incorrectPhoneNumber = false
    //         this.$refs.verifyPopup.openPopups()
    //       })
    //     }, (error) => {
    //       this.error.phoneError = error.data.errors[0].message
    //       this.compareFailed = false
    //       this.incorrectPhoneNumber = true
    //     })
    //   }
    // },
    preSubmitForm() {
      if (this.checkValidForm) {
        this.$v.$touch()
        return
      } else {
        this.$refs.disclaimerPopup.openPopups()
      }
    },
    confirmDisclaimer() {
      this.disclaimerConfirm = true
      this.$refs.disclaimerPopup.resetState()
      this.formSubmit()
    },
    formSubmit() {
      if (this.checkValidForm) {
        return
      } else {
        if (this.formData.personType === 'INVESTOR') {
          // console.log(this.formData);
          this.$load(async () => {
            await this.$api.auth.signUp(this.formData).then(() => {
              const {email, password} = this.formData;
              const {dispatch} = this.$store;
              if (email && password) {
                dispatch('authentication/login', {email, password});
                setTimeout(async () => {
                  await this.setTabs()
                }, 1500)
              }
            })
          }, (error) => {
            this.error.serverError = error.data.errors[0].message
            this.$refs.serverErrorPopup.openPopups()
          })
        } else if (this.formData.personType === 'COMPANY') {
          // console.log(this.formData);
          if (this.formData.hasOwnProperty('companyName')) {
            this.formData.companyName = this.companyName
          } else {
            this.formData['companyName'] = this.companyName
          }
          this.$load(async () => {
            await this.$api.auth.signUp(this.formData).then(() => {
              const {email, password} = this.formData;
              const {dispatch} = this.$store;
              if (email && password) {
                dispatch('authentication/login', {email, password});
                setTimeout(async () => {
                  await this.setTabs()
                }, 1500)
              }
            })
          }, (error) => {
            this.error.serverError = error.data.errors[0].message
            this.$refs.serverErrorPopup.openPopups()
          })
        }
      }
    },
    save(date) {
      this.$refs.menu.save(date)
      this.formData.dateOfBirth = date
    },
    resetForm() {
      this.companyName = ''
      this.formData.firstName = ''
      this.formData.lastName = ''
      this.formData.countryId = ''
      this.searchSelect = ''
      this.formData.email = ''
      this.password1 = ''
      this.password2 = ''
      this.passwordType1 = 'password'
      this.passwordType2 = 'password'
      this.date = null
      this.formData.dateOfBirth = null
      this.formData.phoneNumber = ''
      this.$refs.registerTermsCheckbox.checkInFalse()
      this.$refs.registerPrivacyCheckbox.checkInFalse()
      this.$refs.registerEmailsCheckbox.checkInFalse()

      this.focus = false
      this.requireRulesForPassword = true
      this.$v.$reset()
    },
  },
  computed: {
    searchedCountries() {
      return this.countriesList.filter(option => option.name.toLowerCase().includes(this.searchSelect.toLowerCase()))
    },
    requiredFieldsIsTrue() {
      return !this.checkValidForm && this.termsAndConditionsAgreed && this.privacyPolicyAgreed
    },
    checkValidFormDjkAwards() {
      return this.$v.formDataDjkAwards.email.$invalid || this.$v.formDataDjkAwards.password.$invalid
    },
    checkValidForm() {
      // The COMPANY type has the key 'companyName' and has no 'dateOfBirth'
      return this.formData.personType === 'INVESTOR' ? !this.requireRulesForPassword
          || this.$v.password1.$invalid
          || this.$v.password2.$invalid
          || this.$v.formData.firstName.$invalid
          || this.$v.formData.lastName.$invalid
          || this.$v.formData.email.$invalid
          || this.$v.formData.countryId.$invalid
          || this.$v.formData.dateOfBirth.$invalid
          || this.$v.formData.phoneNumber.$invalid
          : !this.requireRulesForPassword
          || this.$v.password1.$invalid
          || this.$v.password2.$invalid
          || this.$v.formData.firstName.$invalid
          || this.$v.formData.lastName.$invalid
          || this.$v.formData.email.$invalid
          || this.$v.formData.countryId.$invalid
          || this.$v.companyName.$invalid
          || this.$v.formData.phoneNumber.$invalid
    },
    // phoneNumberValidation() {
    //   let number = this.phoneNumber
    //   let array = []
    //   for (const numberElement of number) {
    //     if(numberElement === '+') {
    //       array.push(numberElement)
    //     }
    //   }
    //   return array.length > 1
    // },
    // requiredFieldsIsTrue() {
    //   return this.confirmPhoneNumber && this.termsAndConditionsAgreed && this.privacyPolicyAgreed && this.disclaimerConfirm
    // },
    // disabledVerifyButtonCheck() {
    //   return this.verifyButtonDisabledFlag || this.verifyButtonDisabledFlag2
    // },
  },
  watch: {
    djkAwardsForm() {
      if (!this.djkAwardsForm) {
        this.clearFieldDjkAwardsForm()
      }
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    dateOfBirth(newVal) {
      this.formData.dateOfBirth = newVal
    },
    password1(newVal) {
      this.hasMinimumLength = (newVal.length >= 8);
      this.hasNumber = /\d/.test(newVal);
      this.hasLowercase = /[a-z]/.test(newVal);
      this.hasUppercase = /[A-Z]/.test(newVal);
      this.hasSpecial = /[#?!@$%/^()}{><.&|:;`',*~"-]/.test(newVal);
      if (this.hasMinimumLength && this.hasNumber && this.hasLowercase && this.hasUppercase && this.hasSpecial) {
        this.requireRulesForPassword = true;
      } else {
        this.requireRulesForPassword = false;
      }
      this.formData.password = newVal
    },
    'formData.personType'() {
      this.resetForm()
      if (this.formData.personType === 'INVESTOR' && this.formData.hasOwnProperty('companyName')) {
        delete this.formData.companyName
      }
    },
    // 'phoneNumber'(newVal) {
    //   if (newVal[0] !== '+') {
    //     this.phoneNumber = '+' + newVal
    //   }
    //   this.verifyButtonDisabledFlag = false
    //   this.formData.phoneNumber = newVal
    //   // if (this.formData.phoneNumber === '') {
    //   //   this.verifyButtonDisabledFlag = true
    //   //   this.formData.phoneNumber = ''
    //   // }
    // }
  },
  mounted() {
    document.title = 'Register'
    this.checkQueryDjkAwards()
    this.scrollToId('my-content')
    document.addEventListener('click', this.countryClickOutside)
    setTimeout(() => {
      this.$load(async () => {
        this.countriesList = (await this.$api.countriesLists.getCountries()).data.body.elements
      })
    }, 500)
    if (this.$route.query.token) {
      this.formData.token = this.$route.query.token
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.countryClickOutside)
  },
  components: {
    DisclaimerPopup,
    Footer,
    Header,
    ServerErrorPopup,
    CountrySelect,
    GeneralCustomCheckBox,
    // PhoneVerifyPopup,
    // CustomCheckbox,
  }
}
</script>

<style src="../assets/css/newDesign.css">

</style>

