export default {
  methods: {
    numberFormattingDecimal (num) {
      if (num !== null && num !== '') {
        let newNum = Math.round(num * 100) / 100
        let numStr = newNum.toString()

        // check for '-' or '+' is needed because of 'financial transactions', there may come numbers in a similar format
        if (numStr.includes('-')) {

          let numWithoutMinus = numStr.split('-')[1];
          let checkDecimalsWithoutMinus = numWithoutMinus.split('.');

          if (checkDecimalsWithoutMinus.length > 1) {

            let numIntegerWithoutMinus = numWithoutMinus.split('.')[0];
            let numDecimalWithoutMinus = numWithoutMinus.split('.')[1];

            const remainder = numIntegerWithoutMinus.length % 3;
            let transformString =  (numIntegerWithoutMinus.substring(0, remainder) + numIntegerWithoutMinus.substring(remainder).replace(/(\d{3})/g, '.$1')).trim();

            if(numDecimalWithoutMinus.length > 1) {
              return transformString[0] === '.' ? `-${transformString.slice(1, transformString.length)}` + `,${numDecimalWithoutMinus}` : `-${transformString}` + `,${numDecimalWithoutMinus}`;
            } else {
              return transformString[0] === '.' ? `-${transformString.slice(1, transformString.length)}` + `,${numDecimalWithoutMinus}0` : `-${transformString}` + `,${numDecimalWithoutMinus}0`;
            }

          } else {

            const remainder = numWithoutMinus.length % 3;
            let transformString =  (numWithoutMinus.substring(0, remainder) + numWithoutMinus.substring(remainder).replace(/(\d{3})/g, '.$1')).trim();
            return transformString[0] === '.' ? `-${transformString.slice(1, transformString.length)},00` : `-${transformString},00`;

          }
        } else if (numStr.includes('+')) {

          let numWithoutPlus = numStr.split('+')[1];
          let checkDecimalsWithoutPlus = numWithoutPlus.split('.');

          if (checkDecimalsWithoutPlus.length > 1) {

            let numIntegerWithoutPlus = numWithoutPlus.split('.')[0];
            let numDecimalWithoutPlus = numWithoutPlus.split('.')[1];

            const remainder = numIntegerWithoutPlus.length % 3;
            let transformString =  (numIntegerWithoutPlus.substring(0, remainder) + numIntegerWithoutPlus.substring(remainder).replace(/(\d{3})/g, '.$1')).trim();

            if(numDecimalWithoutPlus.length > 1) {
              return transformString[0] === '.' ? `+${transformString.slice(1, transformString.length)}` + `,${numDecimalWithoutPlus}` : `+${transformString}` + `,${numDecimalWithoutPlus}`;
            } else {
              return transformString[0] === '.' ? `+${transformString.slice(1, transformString.length)}` + `,${numDecimalWithoutPlus}0` : `+${transformString}` + `,${numDecimalWithoutPlus}0`;
            }

          } else {

            const remainder = numWithoutPlus.length % 3;
            let transformString =  (numWithoutPlus.substring(0, remainder) + numWithoutPlus.substring(remainder).replace(/(\d{3})/g, '.$1')).trim();
            return transformString[0] === '.' ? `+${transformString.slice(1, transformString.length)},00` : `+${transformString},00`;

          }
        } else {

          let checkForDecimals = numStr.split('.');

          if(checkForDecimals.length > 1) {

            let numInteger = numStr.split('.')[0];
            let numDecimal = numStr.split('.')[1];

            const remainder = numInteger.length % 3;
            let transformString =  (numInteger.substring(0, remainder) + numInteger.substring(remainder).replace(/(\d{3})/g, '.$1')).trim();

            if(numDecimal.length > 1) {
              return transformString[0] === '.' ? transformString.slice(1, transformString.length) + `,${numDecimal}` : transformString + `,${numDecimal}`;
            } else {
              return transformString[0] === '.' ? transformString.slice(1, transformString.length) + `,${numDecimal}0` : transformString + `,${numDecimal}0`;
            }

          } else {

            const remainder = numStr.length % 3;
            let transformString =  (numStr.substring(0, remainder) + numStr.substring(remainder).replace(/(\d{3})/g, '.$1')).trim();
            return transformString[0] === '.' ? transformString.slice(1, transformString.length) + ',00' : transformString + ',00';

          }
        }
      }
      return ''
    }
  },
}