<template>
  <div class="royalty-reports-wrapper">

    <form enctype='multipart/form-data' @submit.prevent="onSubmitAction">
      <div class="royalty-reports__header">
        <div class="header-report-name">
          <p class="report-name__name" @click.prevent="check">Report name</p>
          <input
              type="text"
              class="report-name__input"
              v-model.trim="dataObj.reportName"
              placeholder="Report by your name 2124 what"
          >
        </div>
        <div class="header-report-date">
          <p class="report-date__name">Time period</p>
          <div class="report-date__calender">
            <div class="date__calender-wrap">
              <div class="date__calender-from" @click="toggleDateFrom">{{ dataObj.royaltyPeriodFrom }}</div>
              <div class="date__calender-to" @click="toggleDateTo">{{ dataObj.royaltyPeriodTo }}</div>
            </div>
            <div class="date-picker-wrapper">
              <v-date-picker
                  v-model="dataObj.royaltyPeriodFrom"
                  type="month"
                  class="mt-4 date-picker__from"
                  min="2022-06"
                  :max="dataObj.royaltyPeriodTo"
                  v-show="show1"
              ></v-date-picker>
            </div>
            <div class="date-picker-wrapper2">
              <v-date-picker
                  v-model="dataObj.royaltyPeriodTo"
                  type="month"
                  class="mt-4 date-picker__to"
                  :min="dataObj.royaltyPeriodFrom"
                  :max="currentMonth"
                  v-show="show2"
              ></v-date-picker>
            </div>
          </div>
        </div>
        <div class="header-report-generate-wrapper">
          <button class="btn header-report-generate" :disabled="generateButtonDisable">
            Generate
          </button>
          <small class="miss-song-list-error" v-if="checkForEmptySongList && loadFlag">You need to chose at least 1 song</small>
        </div>
      </div>
      <div class="royalty-reports__search">
        <p>Search</p>
        <div class="reports__search">
          <div class="reports__search-input-wrapper">
            <input
                type="search"
                name="royalty-report-search"
                id="royalty-report-search"
                placeholder="Search..."
                class="royalty-report-search"
                v-model="searchHandler"
            >
            <div class="search-icon">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M17.71 16.29L14.31 12.9C15.407 11.5025 16.0022 9.77665 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C9.77665 16.0022 11.5025 15.407 12.9 14.31L16.29 17.71C16.4778 17.8993 16.7334 18.0058 17 18.0058C17.2666 18.0058 17.5222 17.8993 17.71 17.71C17.8993 17.5222 18.0058 17.2666 18.0058 17C18.0058 16.7334 17.8993 16.4778 17.71 16.29ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8V8Z"
                      fill="black"/>
              </svg>
            </div>
          </div>
          <div class="author-filter">
            <div class="author-filter__button" @click="toggleAuthorFilter">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.6666 2H1.33325L6.66659 8.30667V12.6667L9.33325 14V8.30667L14.6666 2Z" stroke="#FE0190"
                      stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <p>Author Filter </p>
            </div>
          </div>
        </div>
      </div>
      <div class="royalty-reports__teble">
        <div class="reports__table-wrapper">
          <template>

            <v-simple-table
                fixed-header
            >

              <template v-slot:default>

                <thead>
                <tr>
                  <th width="50px" class="header__check-box" @click="selectDeselectFlag = !selectDeselectFlag">
                    <div class="header-check-box">
                      <div class="header-check-box__active"></div>
                    </div>
                    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M5.81834 7.57454L9.87174 1.32577C9.95531 1.19212 9.99953 1.0406 10 0.886267C10.0005 0.731939 9.95716 0.580187 9.8744 0.44611C9.79164 0.312033 9.67231 0.200303 9.52827 0.122037C9.38423 0.0437717 9.22051 0.001697 9.0534 3.94083e-07L0.9466 -3.14636e-07C0.779486 0.00169626 0.615767 0.0437709 0.471729 0.122037C0.327691 0.200302 0.208356 0.312032 0.125596 0.446109C0.0428349 0.580186 -0.000464758 0.731938 3.48293e-06 0.886266C0.00047077 1.04059 0.0446907 1.19212 0.128262 1.32577L4.18166 7.57454C4.26698 7.70442 4.3871 7.8118 4.53044 7.88632C4.67378 7.96085 4.8355 8 5 8C5.1645 8 5.32622 7.96085 5.46956 7.88633C5.6129 7.8118 5.73302 7.70442 5.81834 7.57454Z"
                          fill="#868686"/>
                    </svg>
                  </th>
                  <th class="text-left"
                      @click="sortBy('sortedByArtist', 'sortedByArtistToggle', 'publicName', 'artist')">
                    Artist
                    <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_10984_18097)">
                        <path
                            d="M2.35818 0.884691L0.417134 4.12511C0.377114 4.19441 0.355939 4.27299 0.355715 4.35302C0.355491 4.43305 0.376226 4.51174 0.415857 4.58127C0.455488 4.6508 0.512633 4.70874 0.581609 4.74932C0.650584 4.78991 0.728984 4.81173 0.809009 4.81261H4.69109C4.77112 4.81173 4.84952 4.78991 4.91849 4.74932C4.98747 4.70874 5.04461 4.6508 5.08424 4.58127C5.12388 4.51174 5.14461 4.43305 5.14439 4.35302C5.14416 4.27299 5.12299 4.19441 5.08297 4.12511L3.14193 0.884691C3.10107 0.817341 3.04355 0.761656 2.97491 0.723011C2.90627 0.684365 2.82882 0.664062 2.75005 0.664062C2.67128 0.664063 2.59383 0.684365 2.52519 0.723011C2.45655 0.761656 2.39903 0.817341 2.35818 0.884691Z"
                            :fill="artistFilterUpperArrowColor ? '#FE0190' : '#868686'"
                        />
                      </g>
                      <g clip-path="url(#clip1_10984_18097)">
                        <path
                            d="M3.14182 10.1153L5.08287 6.87489C5.12289 6.80559 5.14406 6.72701 5.14429 6.64698C5.14451 6.56695 5.12377 6.48826 5.08414 6.41873C5.04451 6.3492 4.98737 6.29126 4.91839 6.25068C4.84942 6.21009 4.77102 6.18827 4.69099 6.18739L0.808908 6.18739C0.728883 6.18827 0.650483 6.21009 0.581508 6.25068C0.512533 6.29126 0.455387 6.3492 0.415756 6.41873C0.376125 6.48826 0.35539 6.56695 0.355614 6.64698C0.355838 6.72701 0.377014 6.80559 0.417033 6.87489L2.35807 10.1153C2.39893 10.1827 2.45645 10.2383 2.52509 10.277C2.59373 10.3156 2.67118 10.3359 2.74995 10.3359C2.82872 10.3359 2.90617 10.3156 2.97481 10.277C3.04345 10.2383 3.10097 10.1827 3.14182 10.1153Z"
                            :fill="artistFilterDownArrowColor ? '#FE0190' : '#868686'"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_10984_18097">
                          <rect width="5.5" height="5.5" fill="white"/>
                        </clipPath>
                        <clipPath id="clip1_10984_18097">
                          <rect width="5.5" height="5.5" fill="white" transform="translate(5.5 11) rotate(-180)"/>
                        </clipPath>
                      </defs>
                    </svg>

                  </th>
                  <th class="text-left" @click="sortBy('sortedBySong', 'sortedBySongToggle', 'songTitle', 'song')">
                    Song
                    <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_10984_18097)">
                        <path
                            d="M2.35818 0.884691L0.417134 4.12511C0.377114 4.19441 0.355939 4.27299 0.355715 4.35302C0.355491 4.43305 0.376226 4.51174 0.415857 4.58127C0.455488 4.6508 0.512633 4.70874 0.581609 4.74932C0.650584 4.78991 0.728984 4.81173 0.809009 4.81261H4.69109C4.77112 4.81173 4.84952 4.78991 4.91849 4.74932C4.98747 4.70874 5.04461 4.6508 5.08424 4.58127C5.12388 4.51174 5.14461 4.43305 5.14439 4.35302C5.14416 4.27299 5.12299 4.19441 5.08297 4.12511L3.14193 0.884691C3.10107 0.817341 3.04355 0.761656 2.97491 0.723011C2.90627 0.684365 2.82882 0.664062 2.75005 0.664062C2.67128 0.664063 2.59383 0.684365 2.52519 0.723011C2.45655 0.761656 2.39903 0.817341 2.35818 0.884691Z"
                            :fill="songFilterUpperArrowColor ? '#FE0190' : '#868686'"
                        />
                      </g>
                      <g clip-path="url(#clip1_10984_18097)">
                        <path
                            d="M3.14182 10.1153L5.08287 6.87489C5.12289 6.80559 5.14406 6.72701 5.14429 6.64698C5.14451 6.56695 5.12377 6.48826 5.08414 6.41873C5.04451 6.3492 4.98737 6.29126 4.91839 6.25068C4.84942 6.21009 4.77102 6.18827 4.69099 6.18739L0.808908 6.18739C0.728883 6.18827 0.650483 6.21009 0.581508 6.25068C0.512533 6.29126 0.455387 6.3492 0.415756 6.41873C0.376125 6.48826 0.35539 6.56695 0.355614 6.64698C0.355838 6.72701 0.377014 6.80559 0.417033 6.87489L2.35807 10.1153C2.39893 10.1827 2.45645 10.2383 2.52509 10.277C2.59373 10.3156 2.67118 10.3359 2.74995 10.3359C2.82872 10.3359 2.90617 10.3156 2.97481 10.277C3.04345 10.2383 3.10097 10.1827 3.14182 10.1153Z"
                            :fill="songFilterDownArrowColor ? '#FE0190' : '#868686'"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_10984_18097">
                          <rect width="5.5" height="5.5" fill="white"/>
                        </clipPath>
                        <clipPath id="clip1_10984_18097">
                          <rect width="5.5" height="5.5" fill="white" transform="translate(5.5 11) rotate(-180)"/>
                        </clipPath>
                      </defs>
                    </svg>

                  </th>
                  <th class="text-left" width="150px"
                      @click="sortBy('sortedByTotal', 'sortedByTotalToggle', 'totalAmount', 'total')">
                    Total amount
                    <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_10984_18097)">
                        <path
                            d="M2.35818 0.884691L0.417134 4.12511C0.377114 4.19441 0.355939 4.27299 0.355715 4.35302C0.355491 4.43305 0.376226 4.51174 0.415857 4.58127C0.455488 4.6508 0.512633 4.70874 0.581609 4.74932C0.650584 4.78991 0.728984 4.81173 0.809009 4.81261H4.69109C4.77112 4.81173 4.84952 4.78991 4.91849 4.74932C4.98747 4.70874 5.04461 4.6508 5.08424 4.58127C5.12388 4.51174 5.14461 4.43305 5.14439 4.35302C5.14416 4.27299 5.12299 4.19441 5.08297 4.12511L3.14193 0.884691C3.10107 0.817341 3.04355 0.761656 2.97491 0.723011C2.90627 0.684365 2.82882 0.664062 2.75005 0.664062C2.67128 0.664063 2.59383 0.684365 2.52519 0.723011C2.45655 0.761656 2.39903 0.817341 2.35818 0.884691Z"
                            :fill="totalFilterUpperArrowColor ? '#FE0190' : '#868686'"
                        />
                      </g>
                      <g clip-path="url(#clip1_10984_18097)">
                        <path
                            d="M3.14182 10.1153L5.08287 6.87489C5.12289 6.80559 5.14406 6.72701 5.14429 6.64698C5.14451 6.56695 5.12377 6.48826 5.08414 6.41873C5.04451 6.3492 4.98737 6.29126 4.91839 6.25068C4.84942 6.21009 4.77102 6.18827 4.69099 6.18739L0.808908 6.18739C0.728883 6.18827 0.650483 6.21009 0.581508 6.25068C0.512533 6.29126 0.455387 6.3492 0.415756 6.41873C0.376125 6.48826 0.35539 6.56695 0.355614 6.64698C0.355838 6.72701 0.377014 6.80559 0.417033 6.87489L2.35807 10.1153C2.39893 10.1827 2.45645 10.2383 2.52509 10.277C2.59373 10.3156 2.67118 10.3359 2.74995 10.3359C2.82872 10.3359 2.90617 10.3156 2.97481 10.277C3.04345 10.2383 3.10097 10.1827 3.14182 10.1153Z"
                            :fill="totalFilterDownArrowColor ? '#FE0190' : '#868686'"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_10984_18097">
                          <rect width="5.5" height="5.5" fill="white"/>
                        </clipPath>
                        <clipPath id="clip1_10984_18097">
                          <rect width="5.5" height="5.5" fill="white" transform="translate(5.5 11) rotate(-180)"/>
                        </clipPath>
                      </defs>
                    </svg>

                  </th>
                </tr>
                </thead>
                  <tbody class="relative">
                    <tr
                      v-for="item in dataForTable"
                      :key="item.song"
                  >
                    <td>
                      <div class="body-check-box" @click="item.checked = !item.checked">
                        <div class="body-check-box__active" :style="{display: item.checked ? 'block' : 'none'}"></div>
                      </div>
                    </td>
                    <td>{{ item.publicName }}</td>
                    <td>{{ item.songTitle }}</td>
                    <td>{{ `€${item.totalAmount}` }}</td>
                  </tr>
                    <div class="select-deselect-all" v-show="selectDeselectFlag">
                      <p
                          @click="selectOrDeselectAll(true)"
                      >Select all</p>
                      <p
                          @click="selectOrDeselectAll(false)"
                      >Deselect all</p>
                    </div>

                  </tbody>
              </template>

            </v-simple-table>

          </template>
          <div class="author-filter__list-wrapper" v-show="toggleAuthorFilterFlag">
            <vuescroll :ops="ops">
              <div class="filter-wrap">
                <div
                    class="author-list"
                    v-for="(item, index) in dataForAuthorList"
                    :key="index"
                >
                  <div class="body-check-box" @click="toggleAuthorListCheckBox(item)">
                    <div class="body-check-box__active" :style="{display: item.checked ? 'block' : 'none'}"></div>
                  </div>
                  {{ item.authorName }}
                </div>
              </div>
            </vuescroll>

          </div>

        </div>
      </div>
    </form>

  </div>
</template>

<script>


import moment from "moment";
import vuescroll from "vuescroll";

export default {
  name: 'RoyaltyReport',
  components: {
    vuescroll
  },
  props: {
    reportsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loadFlag: true,
      show1: false,
      show2: false,
      dataObj: {
        reportName: '',
        royaltyPeriodFrom: this.defaultPeriodDates(),
        royaltyPeriodTo: this.defaultPeriodDates(),
        songIds: null
      },
      error: {
        errorMessage: '',
        errorTitle: '',
      },
      ops: {
        scrollPanel: {
          scrollingX: false,
          scrollingY: true,
        },
        rail: {
          background: 'rgba(234, 234, 234)',
          opacity: 1,
          size: '8px',
          gutterOfEnds: '15px',
          gutterOfSide: '0px',
        },
        bar: {
          showDelay: 500,
          background: '#FF0090',
          opacity: 1,
          minSize: 0.3,
          size: '8px',
          specifyBorderRadius: '27px'
        },
      },
      ops2: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {
          background: '#FB3099',
          size: '3px'
        }
      },
      dataForTable: [],
      dataFromRequest: [],
      dataForAuthorList: [],
      sortedByArtist: false,
      sortedByArtistToggle: false,
      sortedBySong: false,
      sortedBySongToggle: false,
      sortedByTotal: false,
      sortedByTotalToggle: false,
      searchHandler: '',
      toggleAuthorFilterFlag: false,
      selectDeselectFlag: false,
      generateButtonDisable: false
    };
  },
  computed: {
    currentMonth() {
      return new moment().format("YYYY-MM")
    },
    artistFilterUpperArrowColor() {
      return this.sortedByArtist && this.sortedByArtistToggle
    },
    artistFilterDownArrowColor() {
      return this.sortedByArtist && !this.sortedByArtistToggle
    },
    songFilterUpperArrowColor() {
      return this.sortedBySong && this.sortedBySongToggle
    },
    songFilterDownArrowColor() {
      return this.sortedBySong && !this.sortedBySongToggle
    },
    totalFilterUpperArrowColor() {
      return this.sortedByTotal && this.sortedByTotalToggle
    },
    totalFilterDownArrowColor() {
      return this.sortedByTotal && !this.sortedByTotalToggle
    },
    checkForEmptySongList() {
      let res = true
      this.dataForTable.forEach(i => {
        if(i.checked) {
          res = false
          return
        }
      })
      return res
    }
  },
  watch: {
    searchHandler(newVal) {
      let result = []
      this.dataFromRequest.forEach(i => {
        if (i.publicName.toLowerCase().includes(newVal.toLowerCase())) {
          result.push(i)
        } else if (i.songTitle.toLowerCase().includes(newVal.toLowerCase())) {
          result.push(i)
        }
      })
      this.dataForTable = result
    }
  },
  methods: {
    check() {
      console.log(this.checkForEmptySongList)
    },
    selectOrDeselectAll(select) {
      this.dataForTable.forEach(i => i.checked = select)
    },
    toggleAuthorListCheckBox(item) {
      item.checked = !item.checked

      let unselectedAuthors = this.dataForAuthorList.filter(i => !i.checked)

      this.dataForTable = this.dataFromRequest.filter(i => {
        return unselectedAuthors.filter(item => {
          return item.authorName == i.publicName
        }).length == 0
      })

    },
    toggleAuthorFilter() {
      this.toggleAuthorFilterFlag = !this.toggleAuthorFilterFlag
    },
    defaultPeriodDates() {
      let today = moment(new Date())
      let lastMonth = moment(today).subtract(1, 'month').format("YYYY-MM")
      return lastMonth
    },
    onSubmitAction() {
      this.generateButtonDisable = true
      let songList = []
      this.dataForTable.forEach(i => {
        if (i.checked) {
          songList.push(i.songId)
        }
      })
      this.dataObj.songIds = songList
      this.$load(async () => {
        await this.$api.royalty.createRoyaltiesReports(this.dataObj)
        await this.$router.push({path: `/profile/royalty`, query: {paginationPage: 1, royaltyTab: 'royalty-reports'}})
        this.generateButtonDisable = false

      }, error => {
        this.generateButtonDisable = false
        this.$emit('error', error.data.errors[0].message)
      })
    },
    toggleDateFrom() {
      this.show2 = false
      this.show1 = !this.show1
    },
    toggleDateTo() {
      this.show1 = false
      this.show2 = !this.show2
    },
    getSongList() {
      this.$load(async () => {
        let res = (await this.$api.royalty.getSongsForCreateReport()).data.body
        this.dataFromRequest = res.elements
        this.dataForTable = res.elements
        let arr = []
        let arrResult = []
        for (const item of this.dataFromRequest) {
          arr.push(item.publicName)
        }
        let arrWithUniqueAuthors = new Set(arr)
        for (const item of arrWithUniqueAuthors) {
          let obj = {}
          obj.checked = true
          obj.authorName = item
          arrResult.push(obj)
        }
        this.dataForAuthorList = arrResult
      }, error => {
        this.$emit('error', error.data.errors[0].message)
      })
    },
    removeSortsExcept(key) {
      if (key === "artist") {
        this.sortedBySong = false
        this.sortedBySongToggle = false
        this.sortedByTotal = false
        this.sortedByTotalToggle = false
      } else if (key === 'song') {
        this.sortedByArtist = false
        this.sortedByArtistToggle = false
        this.sortedByTotal = false
        this.sortedByTotalToggle = false
      } else if (key === 'total') {
        this.sortedBySong = false
        this.sortedBySongToggle = false
        this.sortedByArtist = false
        this.sortedByArtistToggle = false
      } else {
        this.sortedBySong = false
        this.sortedBySongToggle = false
        this.sortedByTotal = false
        this.sortedByTotalToggle = false
        this.sortedByArtist = false
        this.sortedByArtistToggle = false
      }
    },
    sortBy(key, toggleKey, dataKey, removeAllInsteadOf) {
      let result
      let arr = this.dataForTable
      this.removeSortsExcept(removeAllInsteadOf)
      if (dataKey !== 'totalAmount') {
        if (!this[key]) {
          this[key] = true
          this[toggleKey] = true
          result = arr.sort((a, b) => a[dataKey].localeCompare(b[dataKey]))
        } else {
          if (this[toggleKey]) {
            this[toggleKey] = false
            result = arr.sort((a, b) => b[dataKey].localeCompare(a[dataKey]))
          } else {
            this[toggleKey] = true
            result = arr.sort((a, b) => a[dataKey].localeCompare(b[dataKey]))
          }
        }
      } else {
        if (!this[key]) {
          this[key] = true
          this[toggleKey] = true
          result = arr.sort((a, b) => a[dataKey] - b[dataKey])
        } else {
          if (this[toggleKey]) {
            this[toggleKey] = false
            result = arr.sort((a, b) => b[dataKey] - a[dataKey])
          } else {
            this[toggleKey] = true
            result = arr.sort((a, b) => a[dataKey] - b[dataKey])
          }
        }
      }

      this.dataForTable = result
    },
  },
  mounted() {
    this.getSongList()
  },

};
</script>

<style scoped>

.royalty-reports-wrapper {
  margin-top: 30px;
}

.royalty-reports__header {
  display: flex;
}

.royalty-reports__header p, .royalty-reports__search > p {
  margin-bottom: 10px;
}

.report-name__name, .report-date__name {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 100%;
  color: #767575;
}

.header-report-name {
  margin-right: 35px;
}

.report-name__input {
  width: 335px;
  padding: 10px 20px;
  border: 1px solid #AEAEAE;
  border-radius: 5px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 100%;
  color: #767575
}

.date-picker-wrapper {
  position: absolute;
  top: 30px;
  left: -190px;
  z-index: 102;
}

.date-picker-wrapper2 {
  position: absolute;
  top: 30px;
  left: 100px;
  z-index: 102;
}

.report-date__calender {
  position: relative;
}

.date__calender-wrap {
  display: flex;
  border: 1px solid #AEAEAE;
  border-radius: 5px;
  margin-right: 35px;
}

.date__calender-from, .date__calender-to {
  padding: 10px 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #FE0190;
  cursor: pointer;
}

.date__calender-from:hover, .date__calender-to:hover {
  background: rgba(254, 1, 144, 0.1);
}

.date__calender-to {
  border-left: 1px solid #AEAEAE;
}

.header-report-generate-wrapper {
  position: relative;
  align-self: self-end;
}

.header-report-generate {
  padding: 8px 30px;
  height: 43px;
}

.miss-song-list-error {
  position: absolute;
  bottom: -40px;
  left: 0;
  color: red;
}

.royalty-reports__search {
  margin-top: 20px;
}

.reports__search-input-wrapper {
  position: relative;
  max-width: 739px;
  width: 100%;
}

.royalty-report-search {
  padding: 10px 10px 10px 20px;
  border: 1px solid #AEAEAE;
  border-radius: 5px;
}

.reports__search {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

thead tr {
  cursor: pointer;
}

.author-filter {
  position: relative;
  margin-left: 25px;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
}

.author-filter__button {
  display: flex;
  align-items: center;
}

.author-filter svg {
  margin-right: 8px;
}

.author-filter p {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: #FE0190;
}

.author-filter__list-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 320px;
  background: #FFFFFF;
  z-index: 102;
  border: 1px solid #FE0190;
  border-radius: 5px;
}

.royalty-reports__teble {
  margin-top: 20px;
}

.header__check-box {
  position: relative;
}

.header__check-box svg {
  margin-left: 33px;
}

.header-check-box {
  position: absolute;
  left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  background: #FFFFFF;
  border: 0.5px solid #707070;
  cursor: pointer;
}

.body-check-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  background: #FFFFFF;
  border: 0.5px solid #707070;
  margin-left: 4px;
  cursor: pointer;
}

.header-check-box__active, .body-check-box__active {
  width: 15px;
  height: 15px;
  background: #FF0090;
}

.author-list {
  display: flex;
  margin-bottom: 20px;
  overflow-x: clip;
}

.author-list .body-check-box {
  margin-right: 15px;
}

.filter-wrap {
  max-height: 250px;
  padding: 20px;
}

.reports__table-wrapper {
  position: relative;
}

.select-deselect-all {
  padding: 20px 15px;
  position: absolute;
  left: 50px;
  top: 0;
  z-index: 999;
  background: #FFFFFF;
  border: 1px solid #AEAEAE;
  border-radius: 5px;

}

.select-deselect-all p:last-child {
  margin-bottom: 0;
}

.select-deselect-all p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #767575;
  cursor: pointer;
}

.select-deselect-all p:hover {
  color: #FE0190;
}

.relative {
  position: relative;
}

@media only screen and (max-width: 1100px) {
  .royalty-reports__header {
    flex-wrap: wrap;
  }

  .header-report-name {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 993px) {
  .date-picker-wrapper, .date-picker-wrapper2 {
    left: 0px
  }
}

@media only screen and (max-width: 400px) {
  .royalty-reports__header {
    justify-content: space-between;
  }

  .date__calender-wrap {
    margin-right: 0;
  }
}
</style>