<template>
  <div class="trading-news-item">
    <div class="trading-news-header">
      <h4 class="trading-news-title" v-text="news.title"></h4>
      <span class="trading-news-time" v-text="localTimeFormat"></span>
    </div>
    <p class="trading-news-preview" v-text="slicedDescription"></p>
    <router-link
        :to="{path: `/article/${news.id}`}"
        class="trading-news-read-btn"
    >Read source</router-link>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: 'TradingNews',
  props: {
    news: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {

    }
  },
  computed: {
    slicedDescription() {
      return this.news.shortDescription ?
          this.news.shortDescription.length >= 250 ?
              this.news.shortDescription.slice(0, 250) + '..'
              : this.news.shortDescription
          : ''
    },
    localTimeFormat() {
      let timeUtc = moment.utc(this.news.publishedAt).local()
      return moment(String(timeUtc)).format('yyyy-MM-DD HH:mm')
    },
  },
}
</script>

<style scoped>

</style>