<template>
  <div id="my-content">
    <Header/>
    <main id="root">

      <div class="profile">
        <sidebar/>


        <div class="profile-content">
          <div class="profile-content-top">
            <h1 class="ieo-creating-title-top page-title separator">IS CREATING</h1>
          </div>

          <StepProgressBar :stepFlag="stepFlag"/>
          <div class="ieo-creating-payment-wrapper" ref="top">
            <div class="ieo-creating-payment-container">
              <div class="ieo-creating-payment-title">choose a payment method</div>
              <div class="ieo-creating-payment-card">
                <button class="ieo-creating-payment-card-buttons"
                        :style="{
          background: choiceOfPayment === 'wallet' ? '#FFF7FC' : '#FFFFFF',
          border: choiceOfPayment === 'wallet' ? '2px solid #FF0090' : '2px solid #EAEAEA'
          }">
                  <svg width="67" height="56" viewBox="0 0 67 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="2.11349" y="32.4195" width="39.6048" height="26.5867" rx="6.5"
                          transform="rotate(-49.9259 2.11349 32.4195)"
                          :stroke="choiceOfPayment === 'wallet' ? '#FF0090' : '#000000'" stroke-width="3"/>
                    <rect x="9.88302" y="23.9165" width="39.6048" height="26.5867" rx="6.5"
                          transform="rotate(-30 9.88302 23.9165)" fill="#FFF2F9"
                          :stroke="choiceOfPayment === 'wallet' ? '#FF0090' : '#000000'" stroke-width="3"/>
                    <rect x="4.28125" y="16.5001" width="56" height="38" rx="6.5"
                          :fill="choiceOfPayment === 'wallet' ? '#FFF7FC' : '#FFFFFF'"
                          :stroke="choiceOfPayment === 'wallet' ? '#FF0090' : '#000000'" stroke-width="3"/>
                    <rect x="48.2812" y="30.5001" width="17" height="10" rx="5"
                          :fill="choiceOfPayment === 'wallet' ? '#FFF7FC' : '#FFFFFF'"
                          :stroke="choiceOfPayment === 'wallet' ? '#FF0090' : '#000000'" stroke-width="3"/>
                  </svg>
                  <p :style="{color: choiceOfPayment === 'wallet' ? '#FF0090' : '#000000'}">DjookyX wallet</p>
                </button>
                <button class="ieo-creating-payment-card-buttons"
                        :style="{
          background: choiceOfPayment === 'card' ? '#FFF7FC' : '#FFFFFF',
          border: choiceOfPayment === 'card' ? '2px solid #FF0090' : '2px solid #EAEAEA'
          }">
                  <svg width="66" height="44" viewBox="0 0 66 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1.5" y="1.5" width="63" height="41" rx="6.5"
                          :stroke="choiceOfPayment === 'card' ? '#FF0090' : '#000000'" stroke-width="3"/>
                    <rect x="1.5" y="10.5" width="63" height="9.03403"
                          :stroke="choiceOfPayment === 'card' ? '#FF0090' : '#000000'" stroke-width="3"/>
                    <rect x="45" y="27.5" width="12" height="7" rx="2"
                          :stroke="choiceOfPayment === 'card' ? '#FF0090' : '#000000'" stroke-width="3"/>
                  </svg>
                  <p :style="{color: choiceOfPayment === 'card' ? '#FF0090' : '#000000'}">Card</p>
                </button>

              </div>
<!--              <div class="ieo-creating-payment-wallet-title">-->
<!--                A initial fee of <span>{{ 100 }} EUR</span> is charged for posting a song.-->
<!--              </div>-->
              <div class="ieo-creating-payment-success" v-if="paymentStatus === 'COMPLETED' || paymentStatus === 'HOLD'">
                <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.48573 0.602471C7.70528 0.318449 8.11351 0.266184 8.39753 0.485733C8.68155 0.705283 8.73382 1.11351 8.51427 1.39753L7.48573 0.602471ZM3.362 6.99999L3.87627 7.39751C3.76007 7.54784 3.58391 7.63979 3.39415 7.64919C3.20438 7.65859 3.02001 7.58449 2.88952 7.44638L3.362 6.99999ZM0.527526 4.9464C0.280989 4.68546 0.292665 4.27406 0.553605 4.02753C0.814545 3.78099 1.22594 3.79266 1.47247 4.0536L0.527526 4.9464ZM8.51427 1.39753L3.87627 7.39751L2.84773 6.60246L7.48573 0.602471L8.51427 1.39753ZM2.88952 7.44638L0.527526 4.9464L1.47247 4.0536L3.83447 6.55359L2.88952 7.44638Z"
                      fill="#6FAD84"/>
                </svg>
                <span>Payment was successful</span>
              </div>
              <div class="ieo-creating-payment-failed" v-if="paymentStatus === 'FAILED'">
                <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.48573 0.602471C7.70528 0.318449 8.11351 0.266184 8.39753 0.485733C8.68155 0.705283 8.73382 1.11351 8.51427 1.39753L7.48573 0.602471ZM3.362 6.99999L3.87627 7.39751C3.76007 7.54784 3.58391 7.63979 3.39415 7.64919C3.20438 7.65859 3.02001 7.58449 2.88952 7.44638L3.362 6.99999ZM0.527526 4.9464C0.280989 4.68546 0.292665 4.27406 0.553605 4.02753C0.814545 3.78099 1.22594 3.79266 1.47247 4.0536L0.527526 4.9464ZM8.51427 1.39753L3.87627 7.39751L2.84773 6.60246L7.48573 0.602471L8.51427 1.39753ZM2.88952 7.44638L0.527526 4.9464L1.47247 4.0536L3.83447 6.55359L2.88952 7.44638Z"
                      fill="#E25E5E"/>
                </svg>
                <span>Payment failed</span>
              </div>

              <div class="ieo-creating-payment-pending" v-if="paymentStatus === 'PAY_AWAITING'">
                <h3>Payment in progress</h3>
                <div class="ieo-creating-payment-pending-dot" v-if="dotPendingCounter >= 1"></div>
                <div class="ieo-creating-payment-pending-dot" v-if="dotPendingCounter >= 2"></div>
                <div class="ieo-creating-payment-pending-dot" v-if="dotPendingCounter === 3"></div>
              </div>

              <div class="ieo-creating-payment-by-card">
                <div class="ieo-creating-btn-wrap" v-if="paymentStatus === 'COMPLETED' || paymentStatus === 'HOLD'">
                  <button type="button" class="btn btn-hover ieo-creating-btn" @click="$router.push({path: '/success', query: {initialSale: 'true'}})">
                    Start Initial Sale
                  </button>
                </div>
                <div class="ieo-creating-btn-wrap" v-if="paymentStatus === 'PAY_AWAITING'">
                  <button type="button" disabled class="btn btn-hover ieo-creating-btn disabled-btn">
                    Start Initial Sale
                  </button>
                </div>
                <div class="ieo-creating-btn-wrap" v-if="paymentStatus === 'FAILED'">
                  <button type="button" class="btn btn-hover ieo-creating-btn" @click="$router.push({path: `/profile/ieo-creating/${$route.params.id}?status=PAY_AWAITING`})">
                    Try again
                  </button>
                </div>
              </div>

            </div>

          </div>
        </div>

      </div>
    </main>
    <Footer/>
  </div>

</template>

<script>
import Sidebar from "./Sidebar";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import StepProgressBar from "./StepProgressBar";
import getQueryParams from "../../mixins/getQueryParams";

export default {
  name: "IeoCreatingThirdStepRedirect",
  mixins: [getQueryParams],
  data() {
    return {
      stepFlag: 3,
      dotPendingCounter: 0,
      choiceOfPayment: 'card',
      paymentStatus: 'PAY_AWAITING',
      errorMessage: '',
      counterInterval: null,
      paymentStatusResponse: null
    }
  },
  methods: {
    checkStatus() {
      alert('404')

      // this.$load(async () => {
      //   // let res = await this.$api.payment.getPaymentStatus(this.getQuery(this.$route.fullPath, 'transactionId'))
      //   // this.paymentStatus = res.data.body.value

      //   let res = await this.$api.payment.getPayInStatus(this.getQuery(this.$route.fullPath, 'transactionId'))
      //   this.paymentStatus = res.data.body.status
      //   this.errorMessage = res.data.body.errorMessage
      //   console.log(this.paymentStatus)
      // })
      // if (this.paymentStatus === 'SUCCESS') {
      //   console.log('success')
      //   this.$router.push({path: `/success/${this.$route.params.id}`})
      // } else {
      //   if (this.paymentStatus === 'FAILED') {
      //     console.log('failed')
      //     this.$router.push({path: `/declined/${this.$route.params.id}?status=FAILED`})
      //   } else {
      //     if (this.paymentStatus === 'NOT_FOUND') {
      //       this.$router.push({path: `/card-payment-handler/${this.$route.params.id}?status=PAY_AWAITING+3`})
      //     } else if (this.paymentStatus === 'PENDING') {
      //       this.$router.push({path: `/card-payment-handler/${this.$route.params.id}?status=PAY_AWAITING+10`})
      //     }
      //   }
      // }
    },
    clearInterval(name) {
      clearInterval(name)
    },
    startInterval() {
      this.counterInterval = setInterval(() => {
        this.dotPendingCounter++
        if (this.dotPendingCounter === 4) {
          this.dotPendingCounter = 0
        }
      }, 100)
    },
    checkStatusInterval() {
      this.paymentStatusResponse = setInterval(() => {
       this.checkStatus()
      }, 1000)
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.startInterval()
    this.checkStatus()
    // setTimeout(() => {
    //   this.clearInterval()
    //   this.paymentStatus = 'success'
    // }, 5000)
  },
  components: {
    Footer,
    Header,
    Sidebar,
    StepProgressBar,
  }
}
</script>

<style scoped>

.ieo-creating-payment-by-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.ieo-creating-btn-wrap {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.ieo-creating-payment-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 460px;
  width: 100%;
  margin: 0 auto;
}

.ieo-creating-payment-card {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ieo-creating-payment-card-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: calc(50% - 11px);
  height: 153px;
  padding-bottom: 20px;
  /*border: 2px solid #EAEAEA;*/
  border-radius: 18px;
  cursor: pointer;
}

.ieo-creating-payment-card-buttons:hover {
  border-color: #FF0090;

}

.ieo-creating-payment-card-buttons p {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-top: 16px;
}

.ieo-creating-payment-title {
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 23px;
  text-transform: uppercase;
  color: #464646;
  text-align: center;
  width: 100%;
  margin-bottom: 23px;
}

.ieo-creating-payment-text {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  color: #747474;
}

.ieo-creating-payment-wallet-title {
  margin-top: 44px;
  padding: 15px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  border-top: 1px solid rgba(255, 0, 144, 0.2);
  border-bottom: 1px solid rgba(255, 0, 144, 0.2);
}

.ieo-creating-payment-wallet-title span {
  font-weight: bold;
  color: #FF0090;
}

.ieo-creating-payment-success {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 5px;
  padding: 4px 17px;
  background: #EBF6ED;
  border-radius: 50px;
}

.ieo-creating-payment-failed {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  padding: 4px 17px;
  background: #FFF4F4;
  border-radius: 50px;
}

.ieo-creating-payment-success svg, .ieo-creating-payment-failed svg {
  margin-right: 8px;
}

.ieo-creating-payment-success span, .ieo-creating-payment-failed span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #6FAD84;
}

.ieo-creating-payment-failed span {
  color: #E25E5E;
}
.ieo-creating-payment-pending {
  display: flex;
  align-items: flex-end;
  width: 330px;
  margin-top: 20px;
}
.ieo-creating-payment-pending h3 {
  margin: 0 10px 0 0;
  padding: 0;
}

.ieo-creating-payment-pending-dot {
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 50%;
  background: #000000;
  margin-bottom: 6px;
}


@media only screen and (max-width: 700px) {
  .ieo-creating-payment-blocks {
    flex-direction: column-reverse;
  }

  .ieo-creating-payment-block {
    width: 100%;
  }
}
</style>