<template>
  <div id="my-content" ref="top">
    <Header/>
    <main id="root">
      <div class="profile">
        <Sidebar/>
        <div class="profile-content" v-if="loadFlag">
          <div id="profile-my-orders" class="table-wrap " v-if="flag">
            <table class="table orders-table">
              <thead>
              <tr>
                <th class="left">Date</th>
                <th class="left">Pair</th>
                <th class="left">Type</th>
                <th class="left">Side</th>
                <th class="left">Price</th>
                <th class="left">Stop price</th>
                <th class="left">Amount</th>
                <th class="left">Total</th>
                <th class="left">Status</th>
              </tr>
              </thead>
              <vue-custom-scrollbar class="scroll-area" :settings="scrollSettings">
                <MyOrdersTemplate :orders="orders"/>
              </vue-custom-scrollbar>
            </table>
          </div>
          <div class="profile-content profile-my-orders-wrap empty-response" v-else>
          <span>You don't have any Secondary Market orders yet.</span>
          </div>
          <!-- pagination -->
          <b-pagination-nav
              v-if="orders.length > 1"
              class="pagination"
              v-model="page"
              :total-rows="count"
              :per-page="pageSize"
              :link-gen="linkGen"
              :number-of-pages="count"
              prev-text="«"
              next-text="»"
              first-number
              last-number
              use-router
          ></b-pagination-nav>
        </div>
        <div class="preloader-wrapper profile-preloader" v-else>
          <v-progress-circular
              :size="75"
              :width="8"
              color="#FF0090"
              indeterminate
          ></v-progress-circular>
        </div>

      </div>
    </main>
    <Footer/>
  </div>
</template>

<script>
import MyOrdersTemplate from "./MyOrdersTemplate";
import Sidebar from "./Sidebar";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import {debounce} from "lodash";
import saveState from "vue-save-state";

export default {
  name: "MyOrders",
  data() {
    return {
      loadFlag: false,
      orders: [],
      page: 1,
      count: 0,
      pageSize: 20,
      flag: false,
      scrollSettings: {
        wheelPropagation: false
      }
    }
  },
  watch: {
    'orders'() {
      if (this.orders.length > 0) {
        this.flag = true
      } else {
        this.flag = false
      }
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if(newVal) {
          this.page = newVal
          this.debounceName()
          this.goTo('top')
        } else {
          this.page = 1
        }
      }
    }
  },
  methods: {
    linkGen(pageNum) {
      // return pageNum === 1 ? '?' : `?page=${pageNum}`
      return {
        path: '/profile/my-orders',
        query: {page: pageNum}
      }
    },
    getRequestParams(page, pageSize) {
      let params = {};


      if (page) {
        params["page"] = page - 1;
      }

      if (pageSize) {
        params["size"] = 20;
      }

      return params;
    },
    checkOrders() {
      const params = this.getRequestParams(
          this.page,
          this.pageSize
      );

      this.$load(async () => {
        this.loadFlag = false
        const res = (await this.$api.myOrders.get(params)).data.body
        this.orders = res.elements
        this.count = res.totalPages
        this.loadFlag = true
      })

    },
    handlePageChange(value) {
      this.page = value;
      this.debounceName();
    },
    handlePageSizeChange() {
      this.page = 1;
      // router.push({ path: `/news`, query: { page: this.page }})
      this.debounceName();
    },
    goTo(refName) {
      let element = this.$refs[refName]
      element.scrollIntoView({behavior: 'smooth'})
    },
  },
  mounted() {
    document.title = 'My orders'
    this.page = this.$route.query.page || 1
    this.goTo('top')
    this.checkOrders()
    this.debounceName = debounce(this.checkOrders, 1000);

  },
  components: {Footer, Header, Sidebar, MyOrdersTemplate}
}
</script>

<style scoped>
.profile-content .profile-my-orders-wrap.empty-response {
  width: 100%;
}
</style>