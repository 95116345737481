export default function (instance) {
    return {
        getInvite() {
            return instance.get('/api/v2/invite')
        },
        sendInvite(body) {
            return instance.post('/api/v2/invite', body)
        },
        getInviteLink() {
            return instance.get('/api/v2/invite/generate')
        },
    }
}