<template>
  <div id="my-page" ref="top">
    <Header/>
    <div id="my-content">
      <main id="root">

        <div class="profile">
          <Sidebar/>

          <div class="profile-content" v-if="loadFlag">

            <div class="profile-invite-btn-container">
              <div class="profile-invite-btn-wrap">
                <div class="profile-invite-button">
                  <a class="open-popup-delete-account" @click="openPopup">
                    <svg id="Layer_1" enable-background="new 0 0 510 510" height="512" viewBox="0 0 510 510"
                         width="512" xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <path d="m180 75h30v30h-30z"/>
                        <path
                            d="m405 293.561v-293.561h-300v293.561l150 93.75zm-135-248.561h90v30h-90zm0 60h90v30h-90zm-120-60h90v90h-90zm0 120h210v30h-210zm0 60h210v30h-210zm75 60h60v30h-60z"/>
                        <path d="m331.302 375 178.698 111.686v-223.371z"/>
                        <path d="m255 422.689-48-30-187.698 117.311h471.396l-187.698-117.311z"/>
                        <path d="m0 263.314v223.372l178.698-111.686z"/>
                        <path d="m75 199.811-60 37.5 60 37.5z"/>
                        <path d="m435 199.811v75l60-37.5z"/>
                      </g>
                    </svg>
                    <span>Invite</span>
                  </a>
                </div>
                <button class="btn profile-invite-link-button"
                        @click="openGenerateLinkPopup"
                >Generate invite link</button>
              </div>
            </div>

            <div class="table-wrap" v-if="invites.length">
              <table class="table orders-table">
                <thead>
                <tr>
                  <th class="left">Username</th>
                  <th class="left">Dispatch Date</th>
                  <th class="left">Confirmation Date</th>
                  <th class="left">Reward Amount</th>
<!--                  <th class="left">Status</th>-->
<!--                  <th class="left">Level</th>-->
                </tr>
                </thead>
                <InviteList :invites="invites"/>
              </table>
            </div>
            <div class="invite-total-rewards">
              <span v-if="invites.length === 0"
                    class="invite-total-rewards-empty">You haven't invited anyone yet</span>
              <span v-else v-text="'Total rewards: €' + invites[0].totalReward"></span>
            </div>

<!--            <div id="invite-popup-form" class="white-popup mfp-hide popup-form">-->
<!--              <div class="form-main-content">-->
<!--                <div class="popup-header">-->
<!--                  <h3 class="form-title">Invite </h3>-->
<!--                </div>-->
<!--                <div class="popup-content">-->
<!--                  <form class="invite-form" onsubmit="return false">-->
<!--                    <input type="email" name="email" placeholder="Email" class="invite-field" required>-->
<!--                  </form>-->
<!--                  <div class="delete-image-descr">-->
<!--                    <p>-->
<!--                      Are you sure you want to invite this friend?<br> Please-->
<!--                      confirm your action.-->
<!--                    </p>-->
<!--                  </div>-->
<!--                  <div class="delete-image-btn-wrap">-->
<!--                    <a href="javascript:void(0);" class="btn btn-hover invite-submit">Yes,-->
<!--                      invite</a>-->
<!--                    <a href="javascript:void(0);" class="btn btn-transparent cancel-btn"-->
<!--                       onClick="closePopup();">Cancel</a>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div id="invite-popup-success" class="white-popup mfp-hide popup-form">-->
<!--              <div class="form-main-content">-->
<!--                <div class="popup-header">-->
<!--                  <h3 class="form-title">Congratulations!</h3>-->
<!--                </div>-->
<!--                <div class="popup-content">-->
<!--                  <div class="delete-image-descr">-->
<!--                    <p>-->
<!--                      Success! Invitation has been sent.<br>Ask your friend to check mail inbox.-->
<!--                    </p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

          </div>
          <div class="preloader-wrapper profile-preloader" v-else>
            <v-progress-circular
                :size="75"
                :width="8"
                color="#FF0090"
                indeterminate
            ></v-progress-circular>
          </div>
          <InvitePopup ref="invitePopup"/>
          <InviteGenerateLink ref="inviteGenerateLink" :inviteLink="inviteLink"/>
        </div>
      </main>
      <Footer/>
    </div>
  </div>
</template>

<script>
import Sidebar from "./Sidebar";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import InviteList from './InviteList';
// import {debounce} from "lodash";
import InvitePopup from "../popups/InvitePopup";
import InviteGenerateLink from "../popups/InviteGenerateLink";
import scrollToElement from "@/mixins/scrollToElement";

export default {
  name: "Invite",
  data() {
    return {
      invites: [],
      popup: false,
      loadFlag: false,
      inviteLink: '',
    }
  },
  mixins: [scrollToElement],
  methods: {
    openGenerateLinkPopup() {
      this.$refs.inviteGenerateLink.openPopup()
      this.requestInviteLink()
    },
    requestInviteLink() {
      this.$load(async () => {
        const res = (await this.$api.profileInvite.getInviteLink()).data.body
        this.inviteLink = res.value
      })
    },
    getInvites() {
      this.$load(async () => {
        this.loadFlag = false
        const res = (await this.$api.profileInvite.getInvite()).data.body
        this.invites = res.elements
        this.loadFlag = true
      })
    },
    openPopup(){
      this.$refs.invitePopup.openDialog()
    },
  },
  mounted() {
    document.title = 'Invite'
    this.getInvites();
    setTimeout(() => {
      this.scrollToId('my-content')
    }, 500)
  },
  components: {
    Sidebar,
    Header,
    Footer,
    InviteList,
    InvitePopup,
    InviteGenerateLink
  }
}
</script>

<style scoped>

.profile-invite-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.profile-invite-btn-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn.profile-invite-link-button {
  margin-left: 15px;
  line-height: 29px;
  padding: 16px 30px;
  font-size: 18px;
}

@media only screen and (max-width: 768px) {

  .invite-total-rewards-empty {
    font-size: 18px;
  }

}

@media only screen and (max-width: 500px) {

  .btn.profile-invite-link-button {
    margin-left: 15px;
    line-height: 14px;
    padding: 16px 24px;
    font-size: 14px;
    white-space: nowrap;
  }

  .profile-invite-button a {
    padding: 16px 24px;
    font-size: 14px;
    line-height: 14px;
  }

  .profile-invite-button svg {
    margin-right: 10px;
    width: 14px;
    height: auto;
  }

}

@media only screen and (max-width: 425px) {
  .profile-invite-btn-container {
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  .profile-invite-btn-wrap {
    flex-direction: column;
    align-items: flex-start;
  }

  .btn.profile-invite-link-button {
    margin-left: 0;
    margin-top: 10px;
  }
}

</style>