<template>
  <div id="my-page">
    <div id="my-content">
      <Header />
      <main
          id="root"
          class="section market-section"
      >
        <div class="container-small">
          <h1 class="title page-title separator page-title__second-style">Market</h1>
          <form @submit.prevent="requestMarketList" class="market__filter">

            <div class="market__filter--left">
              <vuescroll :ops="ops" ref="filterScroll" @handle-scroll="handleFilterScroll">
                <div class="market__filter-list">
                  <button class="market__filter-list-item" @click="getAllGenresFilter">
                    <span class="filter-name" :class="{'active': !requestBody.songGenres.length}">All songs</span>
                  </button>

                  <button class="market__filter-list-item"
                          v-for="(name, index) in genresList"
                          :key="index"
                  >
                    <input type="checkbox"
                           class="filter-checkbox"
                           :id="`market__filter--id-${index}`"
                           :value="name"
                           v-model="requestBody.songGenres"
                    >
                    <label :for="`market__filter--id-${index}`"
                           class="filter-name"
                           v-text="name"
                    ></label>
                  </button>

                </div>
              </vuescroll>
              <div v-if="hideScrollToRight" class="scroll-arrow-right" @click="horizontalScrollRight">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.5 8.75L18.75 15L12.5 21.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <div v-if="hideScrollToLeft" class="scroll-arrow-left" @click="horizontalScrollLeft">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.5 8.75L18.75 15L12.5 21.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

              </div>
            </div>

            <div class="market__filter--right">
              <div class="pair-search-wrap market__filter-search">
                <input type="text"
                       class="pair-search-input"
                       placeholder="Search..."
                       v-model="requestBody.searchText"
                >
                <svg class="search-icon"
                     width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M13.5325 12.4675L10.9825 9.925C11.8052 8.87684 12.2517 7.58249 12.25 6.25C12.25 2.93629 9.56371 0.25 6.25 0.25C2.93629 0.25 0.25 2.93629 0.25 6.25C0.25 9.56371 2.93629 12.25 6.25 12.25C7.58249 12.2517 8.87684 11.8052 9.925 10.9825L12.4675 13.5325C12.6083 13.6745 12.8 13.7543 13 13.7543C13.2 13.7543 13.3917 13.6745 13.5325 13.5325C13.6745 13.3917 13.7544 13.2 13.7544 13C13.7544 12.8 13.6745 12.6083 13.5325 12.4675ZM1.75 6.25C1.75 3.76472 3.76472 1.75 6.25 1.75C8.73528 1.75 10.75 3.76472 10.75 6.25C10.75 8.73528 8.73528 10.75 6.25 10.75C3.76472 10.75 1.75 8.73528 1.75 6.25V6.25Z"
                        fill="black"/>
                </svg>
              </div>

              <SecondaryMarketFilterDialog
                  ref="marketFilterDialog"
                  :countries-list="countriesList"
                  :searched-countries-list="searchedCountries"
                  :search-country="countrySearch"
                  :current-country="requestBody.country"
                  @setFilters="setFilters"
                  @openStatus="setOpenStatusFilter"
                  @searchCheck="setCountrySearch"
              />

            </div>
          </form>
          <div class="market__table">

            <form class="market__table-header" @submit.prevent="requestMarketList" ref="tableHeader">

              <div class="market__table-col left medium-col token_name-col">
                <button class="market__table-header-btn"
                        :class="{'active': requestBody.sortBy === 'currencyName'}"
                        @click="sortByHeaders('currencyName')"
                >

                  <span class="label">Token name</span>

                  <svg class="button-icon"
                       width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_9497_12077)">
                      <path :class="{'active-high': requestBody.order === 'ASC'
                                                 && requestBody.sortBy === 'currencyName'}"
                            d="M2.35793 0.884691L0.41689 4.12511C0.37687 4.19441 0.355695 4.27299 0.355471 4.35302C0.355246 4.43305 0.375981 4.51174 0.415612 4.58127C0.455244 4.6508 0.512389 4.70874 0.581364 4.74932C0.65034 4.78991 0.72874 4.81173 0.808765 4.81261H4.69085C4.77087 4.81173 4.84927 4.78991 4.91825 4.74932C4.98722 4.70874 5.04437 4.6508 5.084 4.58127C5.12363 4.51174 5.14437 4.43305 5.14414 4.35302C5.14392 4.27299 5.12274 4.19441 5.08272 4.12511L3.14168 0.884691C3.10083 0.817341 3.04331 0.761656 2.97466 0.723011C2.90602 0.684365 2.82858 0.664062 2.74981 0.664062C2.67103 0.664063 2.59359 0.684365 2.52495 0.723011C2.45631 0.761656 2.39879 0.817341 2.35793 0.884691Z"
                            fill="#868686"/>
                    </g>
                    <g clip-path="url(#clip1_9497_12077)">
                      <path :class="{'active-low': requestBody.order === 'DESC'
                                                && requestBody.sortBy === 'currencyName'}"
                            d="M3.14207 10.1153L5.08311 6.87489C5.12313 6.80559 5.14431 6.72701 5.14453 6.64698C5.14475 6.56695 5.12402 6.48826 5.08439 6.41873C5.04476 6.3492 4.98761 6.29126 4.91864 6.25068C4.84966 6.21009 4.77126 6.18827 4.69124 6.18739L0.809152 6.18739C0.729127 6.18827 0.650727 6.21009 0.581752 6.25068C0.512777 6.29126 0.455631 6.3492 0.416 6.41873C0.376369 6.48826 0.355634 6.56695 0.355858 6.64698C0.356082 6.72701 0.377258 6.80559 0.417277 6.87489L2.35832 10.1153C2.39917 10.1827 2.45669 10.2383 2.52534 10.277C2.59398 10.3156 2.67142 10.3359 2.75019 10.3359C2.82897 10.3359 2.90641 10.3156 2.97505 10.277C3.04369 10.2383 3.10121 10.1827 3.14207 10.1153Z"
                            fill="#868686"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_9497_12077">
                        <rect width="5.5" height="5.5" fill="white"/>
                      </clipPath>
                      <clipPath id="clip1_9497_12077">
                        <rect width="5.5" height="5.5" fill="white" transform="translate(5.5 11) rotate(-180)"/>
                      </clipPath>
                    </defs>
                  </svg>

                </button>
              </div>
              <div class="market__table-col left medium-col">
                <button class="market__table-header-btn"
                        :class="{'active': requestBody.sortBy === 'artistName'}"
                        @click="sortByHeaders('artistName')"
                >

                  <span class="label">Artist</span>

                  <svg class="button-icon"
                       width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_9497_12077)">
                      <path :class="{'active-high': requestBody.order === 'ASC'
                                                 && requestBody.sortBy === 'artistName'}"
                            d="M2.35793 0.884691L0.41689 4.12511C0.37687 4.19441 0.355695 4.27299 0.355471 4.35302C0.355246 4.43305 0.375981 4.51174 0.415612 4.58127C0.455244 4.6508 0.512389 4.70874 0.581364 4.74932C0.65034 4.78991 0.72874 4.81173 0.808765 4.81261H4.69085C4.77087 4.81173 4.84927 4.78991 4.91825 4.74932C4.98722 4.70874 5.04437 4.6508 5.084 4.58127C5.12363 4.51174 5.14437 4.43305 5.14414 4.35302C5.14392 4.27299 5.12274 4.19441 5.08272 4.12511L3.14168 0.884691C3.10083 0.817341 3.04331 0.761656 2.97466 0.723011C2.90602 0.684365 2.82858 0.664062 2.74981 0.664062C2.67103 0.664063 2.59359 0.684365 2.52495 0.723011C2.45631 0.761656 2.39879 0.817341 2.35793 0.884691Z"
                            fill="#868686"/>
                    </g>
                    <g clip-path="url(#clip1_9497_12077)">
                      <path :class="{'active-low': requestBody.order === 'DESC'
                                                 && requestBody.sortBy === 'artistName'}"
                            d="M3.14207 10.1153L5.08311 6.87489C5.12313 6.80559 5.14431 6.72701 5.14453 6.64698C5.14475 6.56695 5.12402 6.48826 5.08439 6.41873C5.04476 6.3492 4.98761 6.29126 4.91864 6.25068C4.84966 6.21009 4.77126 6.18827 4.69124 6.18739L0.809152 6.18739C0.729127 6.18827 0.650727 6.21009 0.581752 6.25068C0.512777 6.29126 0.455631 6.3492 0.416 6.41873C0.376369 6.48826 0.355634 6.56695 0.355858 6.64698C0.356082 6.72701 0.377258 6.80559 0.417277 6.87489L2.35832 10.1153C2.39917 10.1827 2.45669 10.2383 2.52534 10.277C2.59398 10.3156 2.67142 10.3359 2.75019 10.3359C2.82897 10.3359 2.90641 10.3156 2.97505 10.277C3.04369 10.2383 3.10121 10.1827 3.14207 10.1153Z"
                            fill="#868686"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_9497_12077">
                        <rect width="5.5" height="5.5" fill="white"/>
                      </clipPath>
                      <clipPath id="clip1_9497_12077">
                        <rect width="5.5" height="5.5" fill="white" transform="translate(5.5 11) rotate(-180)"/>
                      </clipPath>
                    </defs>
                  </svg>

                </button>
              </div>
              <div class="market__table-col left large-col">
                <button class="market__table-header-btn"
                        :class="{'active': requestBody.sortBy === 'songName'}"
                        @click="sortByHeaders('songName')"
                >

                  <span class="label">Song</span>

                  <svg class="button-icon"
                       width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_9497_12077)">
                      <path :class="{'active-high': requestBody.order === 'ASC'
                                                 && requestBody.sortBy === 'songName'}"
                            d="M2.35793 0.884691L0.41689 4.12511C0.37687 4.19441 0.355695 4.27299 0.355471 4.35302C0.355246 4.43305 0.375981 4.51174 0.415612 4.58127C0.455244 4.6508 0.512389 4.70874 0.581364 4.74932C0.65034 4.78991 0.72874 4.81173 0.808765 4.81261H4.69085C4.77087 4.81173 4.84927 4.78991 4.91825 4.74932C4.98722 4.70874 5.04437 4.6508 5.084 4.58127C5.12363 4.51174 5.14437 4.43305 5.14414 4.35302C5.14392 4.27299 5.12274 4.19441 5.08272 4.12511L3.14168 0.884691C3.10083 0.817341 3.04331 0.761656 2.97466 0.723011C2.90602 0.684365 2.82858 0.664062 2.74981 0.664062C2.67103 0.664063 2.59359 0.684365 2.52495 0.723011C2.45631 0.761656 2.39879 0.817341 2.35793 0.884691Z"
                            fill="#868686"/>
                    </g>
                    <g clip-path="url(#clip1_9497_12077)">
                      <path :class="{'active-low': requestBody.order === 'DESC'
                                                 && requestBody.sortBy === 'songName'}"
                            d="M3.14207 10.1153L5.08311 6.87489C5.12313 6.80559 5.14431 6.72701 5.14453 6.64698C5.14475 6.56695 5.12402 6.48826 5.08439 6.41873C5.04476 6.3492 4.98761 6.29126 4.91864 6.25068C4.84966 6.21009 4.77126 6.18827 4.69124 6.18739L0.809152 6.18739C0.729127 6.18827 0.650727 6.21009 0.581752 6.25068C0.512777 6.29126 0.455631 6.3492 0.416 6.41873C0.376369 6.48826 0.355634 6.56695 0.355858 6.64698C0.356082 6.72701 0.377258 6.80559 0.417277 6.87489L2.35832 10.1153C2.39917 10.1827 2.45669 10.2383 2.52534 10.277C2.59398 10.3156 2.67142 10.3359 2.75019 10.3359C2.82897 10.3359 2.90641 10.3156 2.97505 10.277C3.04369 10.2383 3.10121 10.1827 3.14207 10.1153Z"
                            fill="#868686"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_9497_12077">
                        <rect width="5.5" height="5.5" fill="white"/>
                      </clipPath>
                      <clipPath id="clip1_9497_12077">
                        <rect width="5.5" height="5.5" fill="white" transform="translate(5.5 11) rotate(-180)"/>
                      </clipPath>
                    </defs>
                  </svg>

                </button>
              </div>
              <div class="market__table-col left small-col">
                <button class="market__table-header-btn"
                        :class="{'active': requestBody.sortBy === 'marketPrice'}"
                        @click="sortByHeaders('marketPrice')"
                >

                  <span class="label">Price per RR</span>

                  <svg class="button-icon"
                       width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_9497_12077)">
                      <path :class="{'active-high': requestBody.order === 'ASC'
                                                 && requestBody.sortBy === 'marketPrice'}"
                            d="M2.35793 0.884691L0.41689 4.12511C0.37687 4.19441 0.355695 4.27299 0.355471 4.35302C0.355246 4.43305 0.375981 4.51174 0.415612 4.58127C0.455244 4.6508 0.512389 4.70874 0.581364 4.74932C0.65034 4.78991 0.72874 4.81173 0.808765 4.81261H4.69085C4.77087 4.81173 4.84927 4.78991 4.91825 4.74932C4.98722 4.70874 5.04437 4.6508 5.084 4.58127C5.12363 4.51174 5.14437 4.43305 5.14414 4.35302C5.14392 4.27299 5.12274 4.19441 5.08272 4.12511L3.14168 0.884691C3.10083 0.817341 3.04331 0.761656 2.97466 0.723011C2.90602 0.684365 2.82858 0.664062 2.74981 0.664062C2.67103 0.664063 2.59359 0.684365 2.52495 0.723011C2.45631 0.761656 2.39879 0.817341 2.35793 0.884691Z"
                            fill="#868686"/>
                    </g>
                    <g clip-path="url(#clip1_9497_12077)">
                      <path :class="{'active-low': requestBody.order === 'DESC'
                                                 && requestBody.sortBy === 'marketPrice'}"
                            d="M3.14207 10.1153L5.08311 6.87489C5.12313 6.80559 5.14431 6.72701 5.14453 6.64698C5.14475 6.56695 5.12402 6.48826 5.08439 6.41873C5.04476 6.3492 4.98761 6.29126 4.91864 6.25068C4.84966 6.21009 4.77126 6.18827 4.69124 6.18739L0.809152 6.18739C0.729127 6.18827 0.650727 6.21009 0.581752 6.25068C0.512777 6.29126 0.455631 6.3492 0.416 6.41873C0.376369 6.48826 0.355634 6.56695 0.355858 6.64698C0.356082 6.72701 0.377258 6.80559 0.417277 6.87489L2.35832 10.1153C2.39917 10.1827 2.45669 10.2383 2.52534 10.277C2.59398 10.3156 2.67142 10.3359 2.75019 10.3359C2.82897 10.3359 2.90641 10.3156 2.97505 10.277C3.04369 10.2383 3.10121 10.1827 3.14207 10.1153Z"
                            fill="#868686"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_9497_12077">
                        <rect width="5.5" height="5.5" fill="white"/>
                      </clipPath>
                      <clipPath id="clip1_9497_12077">
                        <rect width="5.5" height="5.5" fill="white" transform="translate(5.5 11) rotate(-180)"/>
                      </clipPath>
                    </defs>
                  </svg>

                </button>
              </div>
              <div class="market__table-col left small-col">
                <button class="market__table-header-btn"
                        :class="{'active': requestBody.sortBy === 'changePrice24h'}"
                        @click="sortByHeaders('changePrice24h')"
                >

                  <span class="label">24h</span>

                  <svg class="button-icon"
                       width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_9497_12077)">
                      <path :class="{'active-high': requestBody.order === 'ASC'
                                                 && requestBody.sortBy === 'changePrice24h'}"
                            d="M2.35793 0.884691L0.41689 4.12511C0.37687 4.19441 0.355695 4.27299 0.355471 4.35302C0.355246 4.43305 0.375981 4.51174 0.415612 4.58127C0.455244 4.6508 0.512389 4.70874 0.581364 4.74932C0.65034 4.78991 0.72874 4.81173 0.808765 4.81261H4.69085C4.77087 4.81173 4.84927 4.78991 4.91825 4.74932C4.98722 4.70874 5.04437 4.6508 5.084 4.58127C5.12363 4.51174 5.14437 4.43305 5.14414 4.35302C5.14392 4.27299 5.12274 4.19441 5.08272 4.12511L3.14168 0.884691C3.10083 0.817341 3.04331 0.761656 2.97466 0.723011C2.90602 0.684365 2.82858 0.664062 2.74981 0.664062C2.67103 0.664063 2.59359 0.684365 2.52495 0.723011C2.45631 0.761656 2.39879 0.817341 2.35793 0.884691Z"
                            fill="#868686"/>
                    </g>
                    <g clip-path="url(#clip1_9497_12077)">
                      <path :class="{'active-low': requestBody.order === 'DESC'
                                                 && requestBody.sortBy === 'changePrice24h'}"
                            d="M3.14207 10.1153L5.08311 6.87489C5.12313 6.80559 5.14431 6.72701 5.14453 6.64698C5.14475 6.56695 5.12402 6.48826 5.08439 6.41873C5.04476 6.3492 4.98761 6.29126 4.91864 6.25068C4.84966 6.21009 4.77126 6.18827 4.69124 6.18739L0.809152 6.18739C0.729127 6.18827 0.650727 6.21009 0.581752 6.25068C0.512777 6.29126 0.455631 6.3492 0.416 6.41873C0.376369 6.48826 0.355634 6.56695 0.355858 6.64698C0.356082 6.72701 0.377258 6.80559 0.417277 6.87489L2.35832 10.1153C2.39917 10.1827 2.45669 10.2383 2.52534 10.277C2.59398 10.3156 2.67142 10.3359 2.75019 10.3359C2.82897 10.3359 2.90641 10.3156 2.97505 10.277C3.04369 10.2383 3.10121 10.1827 3.14207 10.1153Z"
                            fill="#868686"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_9497_12077">
                        <rect width="5.5" height="5.5" fill="white"/>
                      </clipPath>
                      <clipPath id="clip1_9497_12077">
                        <rect width="5.5" height="5.5" fill="white" transform="translate(5.5 11) rotate(-180)"/>
                      </clipPath>
                    </defs>
                  </svg>

                </button>
              </div>
              <div class="market__table-col left small-col">
                <button class="market__table-header-btn"
                        :class="{'active': requestBody.sortBy === 'volume'}"
                        @click="sortByHeaders('volume')"
                >

                  <span class="label">Volume</span>

                  <svg class="button-icon"
                       width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_9497_12077)">
                      <path :class="{'active-high': requestBody.order === 'ASC'
                                                 && requestBody.sortBy === 'volume'}"
                            d="M2.35793 0.884691L0.41689 4.12511C0.37687 4.19441 0.355695 4.27299 0.355471 4.35302C0.355246 4.43305 0.375981 4.51174 0.415612 4.58127C0.455244 4.6508 0.512389 4.70874 0.581364 4.74932C0.65034 4.78991 0.72874 4.81173 0.808765 4.81261H4.69085C4.77087 4.81173 4.84927 4.78991 4.91825 4.74932C4.98722 4.70874 5.04437 4.6508 5.084 4.58127C5.12363 4.51174 5.14437 4.43305 5.14414 4.35302C5.14392 4.27299 5.12274 4.19441 5.08272 4.12511L3.14168 0.884691C3.10083 0.817341 3.04331 0.761656 2.97466 0.723011C2.90602 0.684365 2.82858 0.664062 2.74981 0.664062C2.67103 0.664063 2.59359 0.684365 2.52495 0.723011C2.45631 0.761656 2.39879 0.817341 2.35793 0.884691Z"
                            fill="#868686"/>
                    </g>
                    <g clip-path="url(#clip1_9497_12077)">
                      <path :class="{'active-low': requestBody.order === 'DESC'
                                                 && requestBody.sortBy === 'volume'}"
                            d="M3.14207 10.1153L5.08311 6.87489C5.12313 6.80559 5.14431 6.72701 5.14453 6.64698C5.14475 6.56695 5.12402 6.48826 5.08439 6.41873C5.04476 6.3492 4.98761 6.29126 4.91864 6.25068C4.84966 6.21009 4.77126 6.18827 4.69124 6.18739L0.809152 6.18739C0.729127 6.18827 0.650727 6.21009 0.581752 6.25068C0.512777 6.29126 0.455631 6.3492 0.416 6.41873C0.376369 6.48826 0.355634 6.56695 0.355858 6.64698C0.356082 6.72701 0.377258 6.80559 0.417277 6.87489L2.35832 10.1153C2.39917 10.1827 2.45669 10.2383 2.52534 10.277C2.59398 10.3156 2.67142 10.3359 2.75019 10.3359C2.82897 10.3359 2.90641 10.3156 2.97505 10.277C3.04369 10.2383 3.10121 10.1827 3.14207 10.1153Z"
                            fill="#868686"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_9497_12077">
                        <rect width="5.5" height="5.5" fill="white"/>
                      </clipPath>
                      <clipPath id="clip1_9497_12077">
                        <rect width="5.5" height="5.5" fill="white" transform="translate(5.5 11) rotate(-180)"/>
                      </clipPath>
                    </defs>
                  </svg>

                </button>
              </div>

              <!--              <div class="market__table-col center small-col"></div>-->
              <!--              small-col for it-->
              <div class="market__table-col center medium-col"></div>

            </form>
            <div class="market__table-body" v-if="!loadFlag">
                <ul class="market__table-body-wrap" v-if="marketList.length">

                  <li class="market__table-item"
                      @click="goToTrade(item.currencyName, item.songUuid)"
                      v-for="(item, index) in marketList"
                      :key="index"
                  >
                    <div class="market__table-col left medium-col token_name-col">
                      <div class="market__table-item-image-wrap" @click.prevent.stop>
                        <router-link
                            :to="item.companyName
                             ? {path: `/company/${item.userName}`}
                             : {path: `/artist/${item.userName}`}"
                            class="market__table-item-image"
                        >
                          <img v-if="item.authorPhotoUrl" :src="item.authorPhotoUrl" alt="Artist image" loading="lazy">
                          <img v-else src="../assets/img/default-account-image.png" alt="Artist image" loading="lazy">
                        </router-link>
                      </div>
                      <span class="market__table-item-text" v-text="item.currencyName"></span>
                    </div>
                    <div class="market__table-col left medium-col">
                     <span class="market__table-item-text"
                           v-text="getNameDefault(
                            item.companyName,
                            item.stageName,
                            item.artistFirstName,
                            item.artistLastName
                            )"
                     ></span>
                      <!--                    <router-link-->
                      <!--                        :to="item.companyName-->
                      <!--                             ? {path: `/company/${item.userName}`}-->
                      <!--                             : {path: `/artist/${item.userName}`}"-->
                      <!--                        class="market__table-item-text clickable color&#45;&#45;black"-->
                      <!--                        v-text="getNameDefault(-->
                      <!--                            item.companyName,-->
                      <!--                            item.stageName,-->
                      <!--                            item.singerFirstName,-->
                      <!--                            item.singerLastName-->
                      <!--                            )"-->
                      <!--                    ></router-link>-->
                    </div>
                    <div class="market__table-col left large-col">
                      <span class="market__table-item-text" v-text="item.songName"></span>
                      <!--                    <router-link-->
                      <!--                        :to="{path: `/song/${item.songUuid}`}"-->
                      <!--                        class="market__table-item-text clickable"-->
                      <!--                        v-text="item.songName"-->
                      <!--                    ></router-link>-->
                    </div>
                    <div class="market__table-col left small-col">
                    <span class="market__table-item-text"
                          v-text="`${moneyCurrency.symbol} ${numberFormattingDecimal(item.marketPrice)}`"></span>
                    </div>
                    <div class="market__table-col left small-col">
                  <span class="market__table-item-text"
                        :style="{color: numColorHandler(item.changePrice24h)}"
                        v-text="numPositiveHandler(item.changePrice24h)"
                  ></span>
                    </div>
                    <div class="market__table-col left small-col col--border-right">
                    <span class="market__table-item-text color--black"
                          v-text="numberFormattingDecimal(item.volume)"></span>
                    </div>

                    <!--                <div class="market__table-col center small-col">-->
                    <!--                  <button-->
                    <!--                      type="button"-->
                    <!--                      class="market__table-item-btn"-->
                    <!--                      @click="goToTradeSimple(item.currencyName, item.songUuid)"-->
                    <!--                  >Buy fast</button>-->
                    <!--                </div>-->

                    <!--              small-col for it-->
                    <div class="market__table-col center medium-col">
                      <button
                          @click.prevent.stop
                          type="button"
                          class="market__table-item-btn"
                          @click="goToTrade(item.currencyName, item.songUuid)"
                      >Trade
                      </button>
                    </div>

                  </li>

                </ul>

                <div class="market__table-body--no_data" v-else>
                  <p class="no-data-plug">The search has not given any results</p>
                </div>

            </div>
            <div class="preloader-wrapper" v-if="loadFlag">
              <v-progress-circular
                  :size="100"
                  :width="10"
                  color="#FF0090"
                  indeterminate
              ></v-progress-circular>
            </div>

            <b-pagination-nav
                v-if="!loadFlag && marketList.length && count > 1"
                class="pagination"
                v-model="page"
                :total-rows="count"
                :per-page="pageSize"
                :link-gen="linkGenPagination"
                :number-of-pages="count"
                prev-text="«"
                next-text="»"
                first-number
                last-number
                use-router
            ></b-pagination-nav>

          </div>
        </div>
      </main>

      <!--      <main id="root2" v-else>-->
      <!--        <section class="coming-soon-section">-->
      <!--          <div class="coming-soon-img">-->
      <!--            <img src="../assets/img/coming-soon.svg" alt="coming-soon">-->
      <!--          </div>-->
      <!--          <div class="coming-soon-text">-->
      <!--            <h1>Secondary market is coming soon!</h1>-->
      <!--            <p>We are currently working on this service</p>-->
      <!--          </div>-->
      <!--          <div class="coming-soon-line"></div>-->
      <!--          <p class="coming-soon-link">-->
      <!--            Take me back to-->
      <!--            <router-link :to="'/'" style="color: #FF0090">djookyX.com</router-link>-->
      <!--          </p>-->
      <!--        </section>-->
      <!--      </main>-->

      <ServerErrorPopup
          ref="serverErrorPopup"
          :error-title="popups.error.mainErrorTitle"
          :error-message="popups.error.mainErrorMessage"
      />

      <Footer/>
    </div>
  </div>
</template>

<script>

import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import ServerErrorPopup from "@/components/popups/ServerErrorPopup";
import {mapGetters} from "vuex";
import vuescroll from "vuescroll";
import {debounce} from "lodash";
import nameHandler from "@/mixins/nameHandler";
import scrollToElement from "@/mixins/scrollToElement";
import SecondaryMarketFilterDialog from "@/components/trading/SecondaryMarketFilterDialog";
import numberCurrencyConverter from "@/mixins/numberCurrencyConverter";

export default {
  name: 'SecondaryMarket',
  data() {
    return {
      loadFlag: false,
      moneyCurrency: {
        symbol: '€',
        // name: 'EUR'
      },
      popups: {
        // confirmTitle: '',
        // confirmMessage: '',
        // successMessage: 'all ok',
        error: {
          mainErrorTitle: '',
          mainErrorMessage: '',
        },
      },
      // scroll
      ops: {
        scrollPanel: {
          scrollingX: true,
          scrollingY: false,
        },
        rail: {
          background: 'rgba(234, 234, 234)',
          opacity: 1,
          size: '0px',
          gutterOfEnds: '15px',
          gutterOfSide: '0px',
        },
        bar: {
          showDelay: 500,
          background: '#FF0090',
          opacity: 1,
          minSize: 0.3,
          size: '0px',
          specifyBorderRadius: '27px'
        },
      },
      ops2: {
        scrollPanel: {
          scrollingX: true,
          scrollingY: true,
        },
        rail: {
          background: 'rgba(234, 234, 234)',
          opacity: 1,
          size: '3px',
          gutterOfEnds: '15px',
          gutterOfSide: '0px',
        },
        bar: {
          showDelay: 500,
          background: '#FF0090',
          opacity: 1,
          minSize: 0.3,
          size: '3px',
          specifyBorderRadius: '27px'
        },
      },
      // pagination
      page: 1,
      count: 0,
      pageSize: 20,
      // filters
      countriesList: [],
      countrySearch: '',
      // currentCountry: null,
      openStatusFilter: false,
      genresList: [],
      // for main request
      marketList: [],
      requestBody: {
        country: '',
        order: '', // ASC DESC
        searchText: '',
        songGenres: [],
        sortBy: '', // currencyName, artistName, songName, marketPrice, changePrice24h, volume
      },
      scrolled: true,
      hideScrollToRight: true,
      hideScrollToLeft: false
    }
  },
  mixins: [nameHandler, scrollToElement, numberCurrencyConverter],
  methods: {
    horizontalScrollRight() {
      this.$refs["filterScroll"].scrollBy(
          {
            dx: "40%"
          },
          500
      );
    },
    horizontalScrollLeft() {
      this.$refs["filterScroll"].scrollBy(
          {
            dx: "-30%"
          },
          500
      );
    },
    handleFilterScroll(vertical, horizontal, nativeEvent) {
      if(horizontal.process === 0) {
        this.hideScrollToLeft = false
      } else if (horizontal.process > 0 && horizontal.process < 0.99) {
        this.hideScrollToLeft = true
        this.hideScrollToRight = true
      } else {
        this.hideScrollToRight = false
      }


    },
    handleScroll() {
      let obj = this.$refs.tableHeader;
      let {top,bottom} = obj.getBoundingClientRect();
      let height = document.documentElement.clientHeight;
      this.scrolled = top - 88 < height && top - 88 > 0;
    },
    getAllGenresFilter() {
      this.requestBody.songGenres = []
    },
    requestMarketList() {
      let params = this.getRequestParams(
          this.page,
          this.pageSize
      )

      let body = {
        searchText: this.requestBody.searchText,
        order: this.requestBody.order,
        sortBy: this.requestBody.sortBy
      }
      if (this.requestBody.songGenres.length) {
        body['songGenres'] = this.requestBody.songGenres
      }
      if (this.requestBody.country) {
        body['country'] = this.requestBody.country
      }

      this.$load(async () => {
        this.loadFlag = true

        let res = (await this.$api.trading.getMarket(body, params)).data.body
        this.marketList = res.elements
        this.count = res.totalPages

        this.loadFlag = false
      }, error => {
        this.popups.error.mainErrorMessage = error.data.errors[0].message
        this.$refs.serverErrorPopup.openPopups()
      })
    },
    requestCountries() {
      this.$load(async () => {
        this.countriesList = (await this.$api.countriesLists.getCountries()).data.body.elements
      })
    },
    requestGenres() {
      this.$load(async () => {
        this.genresList = (await this.$api.createSong.getGenres()).data.body.elements
      })
    },
    linkGenPagination(pageNum, routeObj) {
      return {
        path: '/market',
        query: {page: pageNum}
      }
    },
    linkGen(pageNum, filters) {
      const obj = {
        path: '/market',
        query: {
          page: pageNum,
          order: filters.order,
          sortBy: filters.sortBy,
          // genres: `${filters.songGenres}`
        }
      };
      if (filters.country) {
        obj.query['country'] = filters.country
      }
      if (filters.searchText) {
        obj.query['search'] = filters.searchText
      }
      if (filters.songGenres.length) {
        obj.query['genres'] = `${filters.songGenres}`
      }

      return obj
    },
    getRequestParams(page, pageSize) {
      let params = {};
      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }
      return params;
    },
    numColorHandler(num) {
      return num === 0 ? '#747474' : num > 0 ? '#4C9F4A' : '#D03737'
    },
    numPositiveHandler(num) {
      return num ? num > 0 ? `+${num.toFixed(2)}%` : `${num.toFixed(2)}%` : '0.00%'
    },
    goToTrade(name, id) {
      localStorage.setItem('tradingSongName', name)
      localStorage.setItem('tradingSongId', id)

      this.$router.push({path: `/trading/${name.toLowerCase()}`})
    },
    // goToTradeSimple(name, id) {
    //   localStorage.setItem('tradingSongName', name)
    //   localStorage.setItem('tradingSongId', id)
    //
    //   this.$router.push({path: `/trading/${name.toLowerCase()}`})
    // },
    sortByHeaders(params) {
      if (this.requestBody.sortBy !== params) {
        this.requestBody.order = 'ASC'
        this.requestBody.sortBy = params
        return
      }

      if (this.requestBody.order === 'ASC') {
        this.requestBody.order = 'DESC'
      } else {
        this.requestBody.order = 'ASC'
      }

      this.requestBody.sortBy = params
    },
    setFilters(data) {
      this.requestBody.country = data.country ? data.country.name : null
      this.requestMarketList()
    },
    setOpenStatusFilter(status) {
      this.openStatusFilter = status
    },
    setCountrySearch(data) {
      this.countrySearch = data
    },
    filterDialogClickOutside(event) {
      if (!event.target.closest('.market__filter-wrap') && this.openStatusFilter) {
        this.$refs.marketFilterDialog.closeDialog()
      }
    },
    setRequestBodyOnMount() {
      this.page = this.$route.query.page || 1
      this.requestBody.order = this.$route.query.order || 'DESC'
      this.requestBody.sortBy = this.$route.query.sortBy || 'volume'
      this.requestBody.searchText = this.$route.query.search || ''
      this.requestBody.country = this.$route.query.country || ''

      let genre = this.$route.query.genres
      this.requestBody.songGenres = genre ? genre.split(',') : []
    },
  },
  watch: {
    'requestBody': {
      deep: true,
      handler() {
        if (this.$route.fullPath !== this.$router.resolve(this.linkGen(this.page, this.requestBody)).route.fullPath) {
          this.page = 1
          this.$router.push(this.linkGen(this.page, this.requestBody))
        }
        this.scrollToId('my-content')

        // if (this.$route.query.page === '1') {
        //   this.scrollToId('my-content')
        //   this.$router.push(this.linkGen(this.page, this.requestBody))
        // } else {
        //   this.$router.push(this.linkGen(1, this.requestBody))
        // }

      }
    },
    'requestBody.searchText'() {
      this.debounceName()
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if (newVal && newVal !== this.page.toString()) {
          this.page = newVal
          this.$router.push(this.linkGen(this.page, this.requestBody))
          this.debounceName()
          this.scrollToId('my-content')
        } else {
          this.page = 1
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      isLogged: 'authentication/isLogged',
    }),
    searchedCountries() {
      return this.countriesList.filter(option => option.name.toLowerCase().includes(this.countrySearch.toLowerCase()))
    },
  },
  mounted() {
    document.title = 'DjookyX Marketplace'
    document.addEventListener('click', this.filterDialogClickOutside)
    document.addEventListener('scroll', this.handleScroll)
    this.handleScroll()
    this.scrollToId('my-content')

    this.requestGenres()
    this.requestCountries()
    this.setRequestBodyOnMount()
    this.requestMarketList()

    this.debounceName = debounce(this.requestMarketList, 1000);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.filterDialogClickOutside)
    document.removeEventListener('scroll', this.handleScroll)
  },
  components: {
    Header,
    Footer,
    ServerErrorPopup,
    vuescroll,
    SecondaryMarketFilterDialog,
  }
}
</script>

<style scoped>
.scroll-arrow-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background: #F5F5F5;
  cursor: pointer;
}

.scroll-arrow-left {
  position: absolute;
  top: 50%;
  left: 0;
  background: #F5F5F5;
  transform: translateY(-50%) rotate(180deg);
  cursor: pointer;
}
</style>