<template>
  <div class="my-song-list" id="my-song-list">
    <MySongList
        v-for="(song, index) in songs"
        :key="index"
        :song="song"
        :index="index"
        :identifier="'my-song'"
        :current="current"
        :favoritesFlag="favoritesFlag"
        :preloadFlag="preloadFlag"
        :status="status"
        @statusFailed="$emit('statusFailed')"
        @playingMusic="compareSongs"
        @updateSongImage="$emit('updateSongImage')"
    />
  </div>
</template>


<script>
import MySongList from "./MySongList";

export default {
  name: "MySongTemplate",
  components: {MySongList},
  props: {
    songs: {
      type: Array,
      default: () => []
    },
    status: String,
    preloadFlag: Boolean,
    favoritesFlag: Boolean,
  },
  data() {
    return {
      current: ''
    }
  },
  methods: {
    compareSongs(song) {
      this.current = song
    }
  },
}
</script>

<style scoped>

</style>

