<template>
  <div class="content-wrapper">
    <div class="market-coin-info">
      <div class="market__song-block">
        <div class="market__song-block-play-btn">
          <button
            :class="
              togglePlayerFlag ? 'song_listen-play' : 'song_listen-play-active'
            "
            @click="toggleMusic"
            type="button"
          ></button>
        </div>
        <div class="market__song-block-info">
          <span class="song-name" v-text="item.songTitle"></span>
          <span class="song-author" v-text="item.authorName"></span>
        </div>
      </div>
      <div class="market__coin-block">
        <div class="market__coin-author_img">
          <img
            v-if="item.albumCover"
            :src="item.albumCover"
            alt="Coin author image"
          />
          <img
            v-else
            src="@/assets/img/default-account-image.png"
            alt="Default coin author image"
          />
        </div>
        <div class="market__coin-name" v-text="`${pairName}/RR`"></div>
      </div>
    </div>

    <div class="contracts-table" tabindex="0">
      <table>
        <thead>
          <tr>
            <th class="token"><span class="text text--header">Token</span></th>
            <th class="date"><span class="text text--header">date</span></th>
            <th class="amount">
              <span class="text text--header">amount</span>
            </th>
            <th class="price_per_one">
              <span class="text text--header">price/RR</span>
            </th>
            <th class="price">
              <span class="text text--header">Total price</span>
            </th>
            <th class="buttons">
              <span class="text text--header">actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <AgreementsSMContractItem
            v-for="(el, index) in item.contracts"
            :key="index"
            :contract="el"
            :ask="item.isAsk"
            :index="index"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import AgreementsSMContractItem from "@/components/agreements/AgreementsSMContractItem";
// import moment from "moment";

export default {
  name: "AgreementsSMContractsTemplate",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mp3: "",
      player: new Audio(),
      togglePlayerFlag: true,
      // mockArr: [
      //   {
      //     contractLink: "https://cdn.djooky.fun/2022-07-12-11-45-49-37b4a27c-2e96-4c82-8f45-826584af22f3-EXHIBIT_B.pdf",
      //     createdAt: "2022-07-12 11:45",
      //     docId: 1179,
      //     fullCurrencyName: "AUTOGEN",
      //     investorName: "af Art02",
      //     iprAmount: 3,
      //     totalPrice: 3.3,
      //   },
      //   {
      //     contractLink: "https://cdn.djooky.fun/2022-07-12-11-45-49-37b4a27c-2e96-4c82-8f45-826584af22f3-EXHIBIT_B.pdf",
      //     createdAt: "2022-07-12 11:45",
      //     docId: 1179,
      //     fullCurrencyName: "AUTOGEN",
      //     investorName: "af Art02",
      //     iprAmount: 3,
      //     totalPrice: 3.3,
      //   },
      //   {
      //     contractLink: "https://cdn.djooky.fun/2022-07-12-11-45-49-37b4a27c-2e96-4c82-8f45-826584af22f3-EXHIBIT_B.pdf",
      //     createdAt: "2022-07-12 11:45",
      //     docId: 1179,
      //     fullCurrencyName: "AUTOGEN",
      //     investorName: "af Art02",
      //     iprAmount: 3,
      //     totalPrice: 3.3,
      //   },
      //   {
      //     contractLink: "https://cdn.djooky.fun/2022-07-12-11-45-49-37b4a27c-2e96-4c82-8f45-826584af22f3-EXHIBIT_B.pdf",
      //     createdAt: "2022-07-12 11:45",
      //     docId: 1179,
      //     fullCurrencyName: "AUTOGEN",
      //     investorName: "af Art02",
      //     iprAmount: 3,
      //     totalPrice: 3.3,
      //   },
      //   {
      //     contractLink: "https://cdn.djooky.fun/2022-07-12-11-45-49-37b4a27c-2e96-4c82-8f45-826584af22f3-EXHIBIT_B.pdf",
      //     createdAt: "2022-07-12 11:45",
      //     docId: 1179,
      //     fullCurrencyName: "AUTOGEN",
      //     investorName: "af Art02",
      //     iprAmount: 3,
      //     totalPrice: 3.3,
      //   },
      //   {
      //     contractLink: "https://cdn.djooky.fun/2022-07-12-11-45-49-37b4a27c-2e96-4c82-8f45-826584af22f3-EXHIBIT_B.pdf",
      //     createdAt: "2022-07-12 11:45",
      //     docId: 1179,
      //     fullCurrencyName: "AUTOGEN",
      //     investorName: "af Art02",
      //     iprAmount: 3,
      //     totalPrice: 3.3,
      //   },
      //   {
      //     contractLink: "https://cdn.djooky.fun/2022-07-12-11-45-49-37b4a27c-2e96-4c82-8f45-826584af22f3-EXHIBIT_B.pdf",
      //     createdAt: "2022-07-12 11:45",
      //     docId: 1179,
      //     fullCurrencyName: "AUTOGEN",
      //     investorName: "af Art02",
      //     iprAmount: 3,
      //     totalPrice: 3.3,
      //   },
      //   {
      //     contractLink: "https://cdn.djooky.fun/2022-07-12-11-45-49-37b4a27c-2e96-4c82-8f45-826584af22f3-EXHIBIT_B.pdf",
      //     createdAt: "2022-07-12 11:45",
      //     docId: 1179,
      //     fullCurrencyName: "AUTOGEN",
      //     investorName: "af Art02",
      //     iprAmount: 3,
      //     totalPrice: 3.3,
      //   },
      //   {
      //     contractLink: "https://cdn.djooky.fun/2022-07-12-11-45-49-37b4a27c-2e96-4c82-8f45-826584af22f3-EXHIBIT_B.pdf",
      //     createdAt: "2022-07-12 11:45",
      //     docId: 1179,
      //     fullCurrencyName: "AUTOGEN",
      //     investorName: "af Art02",
      //     iprAmount: 3,
      //     totalPrice: 3.3,
      //   },
      //   {
      //     contractLink: "https://cdn.djooky.fun/2022-07-12-11-45-49-37b4a27c-2e96-4c82-8f45-826584af22f3-EXHIBIT_B.pdf",
      //     createdAt: "2022-07-12 11:45",
      //     docId: 1179,
      //     fullCurrencyName: "AUTOGEN",
      //     investorName: "af Art02",
      //     iprAmount: 3,
      //     totalPrice: 3.3,
      //   },
      //   {
      //     contractLink: "https://cdn.djooky.fun/2022-07-12-11-45-49-37b4a27c-2e96-4c82-8f45-826584af22f3-EXHIBIT_B.pdf",
      //     createdAt: "2022-07-12 11:45",
      //     docId: 1179,
      //     fullCurrencyName: "AUTOGEN",
      //     investorName: "af Art02",
      //     iprAmount: 3,
      //     totalPrice: 3.3,
      //   },
      //   {
      //     contractLink: "https://cdn.djooky.fun/2022-07-12-11-45-49-37b4a27c-2e96-4c82-8f45-826584af22f3-EXHIBIT_B.pdf",
      //     createdAt: "2022-07-12 11:45",
      //     docId: 1179,
      //     fullCurrencyName: "AUTOGEN",
      //     investorName: "af Art02",
      //     iprAmount: 3,
      //     totalPrice: 3.3,
      //   },
      //   {
      //     contractLink: "https://cdn.djooky.fun/2022-07-12-11-45-49-37b4a27c-2e96-4c82-8f45-826584af22f3-EXHIBIT_B.pdf",
      //     createdAt: "2022-07-12 11:45",
      //     docId: 1179,
      //     fullCurrencyName: "AUTOGEN",
      //     investorName: "f4fweff Art02",
      //     iprAmount: 3,
      //     totalPrice: 3.3,
      //   },
      //   {
      //     contractLink: "https://cdn.djooky.fun/2022-07-12-11-45-49-37b4a27c-2e96-4c82-8f45-826584af22f3-EXHIBIT_B.pdf",
      //     createdAt: "2022-07-12 11:45",
      //     docId: 1179,
      //     fullCurrencyName: "AUTOGEN",
      //     investorName: "wefewwfe Art02",
      //     iprAmount: 3,
      //     totalPrice: 3.3,
      //   },
      //   {
      //     contractLink: "https://cdn.djooky.fun/2022-07-12-11-45-49-37b4a27c-2e96-4c82-8f45-826584af22f3-EXHIBIT_B.pdf",
      //     createdAt: "2022-07-12 11:45",
      //     docId: 1179,
      //     fullCurrencyName: "AUTOGEN",
      //     investorName: "Argwegewgt02 Art02",
      //     iprAmount: 3,
      //     totalPrice: 3.3,
      //   },
      //   {
      //     contractLink: "https://cdn.djooky.fun/2022-07-12-11-45-49-37b4a27c-2e96-4c82-8f45-826584af22f3-EXHIBIT_B.pdf",
      //     createdAt: "2022-07-12 11:45",
      //     docId: 1179,
      //     fullCurrencyName: "AUTOGEN",
      //     investorName: "ewege Art02",
      //     iprAmount: 3,
      //     totalPrice: 3.3,
      //   },
      //   {
      //     contractLink: "https://cdn.djooky.fun/2022-07-12-11-45-49-37b4a27c-2e96-4c82-8f45-826584af22f3-EXHIBIT_B.pdf",
      //     createdAt: "2022-07-12 11:45",
      //     docId: 1179,
      //     fullCurrencyName: "AUTOGEN",
      //     investorName: "gewewg Art02",
      //     iprAmount: 3,
      //     totalPrice: 3.3,
      //   },
      //   {
      //     contractLink: "https://cdn.djooky.fun/2022-07-12-11-45-49-37b4a27c-2e96-4c82-8f45-826584af22f3-EXHIBIT_B.pdf",
      //     createdAt: "2022-07-12 11:45",
      //     docId: 1179,
      //     fullCurrencyName: "AUTOGEN",
      //     investorName: "Art3535353502 Art02",
      //     iprAmount: 3,
      //     totalPrice: 3.3,
      //   },
      //   {
      //     contractLink: "https://cdn.djooky.fun/2022-07-12-11-45-49-37b4a27c-2e96-4c82-8f45-826584af22f3-EXHIBIT_B.pdf",
      //     createdAt: "2022-07-12 11:45",
      //     docId: 1179,
      //     fullCurrencyName: "AUTOGEN",
      //     investorName: "ageegeg Art02",
      //     iprAmount: 3,
      //     totalPrice: 3.3,
      //   },
      //   {
      //     contractLink: "https://cdn.djooky.fun/2022-07-12-11-45-49-37b4a27c-2e96-4c82-8f45-826584af22f3-EXHIBIT_B.pdf",
      //     createdAt: "2022-07-12 11:45",
      //     docId: 1179,
      //     fullCurrencyName: "AUTOGEN",
      //     investorName: "Art0sdsdsf2 Art02",
      //     iprAmount: 3,
      //     totalPrice: 3.3,
      //   },
      //   {
      //     contractLink: "https://cdn.djooky.fun/2022-07-12-11-45-49-37b4a27c-2e96-4c82-8f45-826584af22f3-EXHIBIT_B.pdf",
      //     createdAt: "2022-07-12 11:45",
      //     docId: 1179,
      //     fullCurrencyName: "AUTOGEN",
      //     investorName: "Art04t4t2 Art02",
      //     iprAmount: 3,
      //     totalPrice: 3.3,
      //   },
      //   {
      //     contractLink: "https://cdn.djooky.fun/2022-07-12-11-45-49-37b4a27c-2e96-4c82-8f45-826584af22f3-EXHIBIT_B.pdf",
      //     createdAt: "2022-07-12 11:45",
      //     docId: 1179,
      //     fullCurrencyName: "AUTOGEN",
      //     investorName: "Asdsfrt02 Art02",
      //     iprAmount: 3,
      //     totalPrice: 3.3,
      //   },
      //   {
      //     contractLink: "https://cdn.djooky.fun/2022-07-12-11-45-49-37b4a27c-2e96-4c82-8f45-826584af22f3-EXHIBIT_B.pdf",
      //     createdAt: "2022-07-12 11:45",
      //     docId: 1179,
      //     fullCurrencyName: "AUTOGEN",
      //     investorName: "Artsf4yg02 Art02",
      //     iprAmount: 3,
      //     totalPrice: 3.3,
      //   },
      // ],
    };
  },
  methods: {
    toggleMusic() {
      if (this.togglePlayerFlag) {
        this.togglePlayerFlag = false;
        this.player.play();
      } else {
        this.togglePlayerFlag = true;
        this.player.pause();
      }
    },
  },
  watch: {
    item() {
      if (this.item.songUrlFile) {
        this.mp3 = this.item.songUrlFile;
        this.player.src = this.mp3;
      }
    },
  },
  computed: {
    pairName() {
      return this.item.fullCurrencyName
        ? this.item.fullCurrencyName
        : "PAIR-NAME";
    },
  },
  mounted() {
    this.mp3 = this.item.songUrlFile;
    this.player.src = this.mp3;
  },
  beforeDestroy() {
    this.player.currentTime = 0;
    this.togglePlayerFlag = true;
    this.player.pause();
  },
  components: {
    AgreementsSMContractItem,
  },
};
</script>

<style scoped></style>
