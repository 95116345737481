var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"my-content"}},[_c('Header'),_c('main',{attrs:{"id":"root"}},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"container-small",attrs:{"id":"artists-page"}},[_c('div',{staticClass:"music-header"},[_c('h1',{ref:"top",staticClass:"page-title separator"},[_vm._v("Artists")]),_c('a',{staticClass:"cd-filter-trigger cd-filter-trigger-top",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.filterToggleFlag = !_vm.filterToggleFlag}}})]),_c('form',{staticClass:"artist-header"},[_c('div',{staticClass:"search-block",attrs:{"id":"cover"}},[_c('div',{staticClass:"search-input",style:({width: _vm.statusSearchBlock ? '100%' : '50px',
                               border: _vm.statusSearchBlock ? 'none' : '0.5px solid #272727',
                               borderRadius: _vm.statusSearchBlock ? 'none' : '3px',
                               marginBottom: _vm.statusSearchBlock ? '10px' : '0'
                               }),on:{"click":_vm.toggleSearchBlock}},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.dataObj.searchedText),expression:"dataObj.searchedText",modifiers:{"trim":true}},{name:"show",rawName:"v-show",value:(_vm.statusSearchBlock),expression:"statusSearchBlock"}],staticClass:"search-news",style:({padding: _vm.statusSearchBlock ? '15px 70px 15px 27px' : '0'}),attrs:{"type":"text","name":"searchedText","id":"id_search","placeholder":"Search..."},domProps:{"value":(_vm.dataObj.searchedText)},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();},"change":function($event){_vm.page = 1},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.dataObj, "searchedText", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"search-input-item",style:({left: _vm.statusSearchBlock ? 'unset' : '50%',
                                  transform: _vm.statusSearchBlock ? 'translateY(-50%)' : 'translate(-50%, -50%)',
                                  right: _vm.statusSearchBlock ? '26px' : 'unset',
                                  })},[_c('svg',{attrs:{"width":"19","height":"19","viewBox":"0 0 19 19","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M17.71 16.29L14.31 12.9C15.407 11.5025 16.0022 9.77665 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C9.77665 16.0022 11.5025 15.407 12.9 14.31L16.29 17.71C16.4778 17.8993 16.7334 18.0058 17 18.0058C17.2666 18.0058 17.5222 17.8993 17.71 17.71C17.8993 17.5222 18.0058 17.2666 18.0058 17C18.0058 16.7334 17.8993 16.4778 17.71 16.29ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8V8Z","fill":"black"}})])])])])]),_c('div',{staticClass:"artist-container"},[(_vm.filterDeskOrMobile === 'desktop')?_c('ArtistFilter',{on:{"filterData":_vm.filterDataRequest}}):_vm._e(),_c('div',{ref:"filter",staticClass:"cd-filter",class:{'filter-is-visible': _vm.filterToggleFlag}},[_vm._m(0),_c('aside',{staticClass:"filter-sidebar",attrs:{"id":"secondary"}},[_c('div',{staticClass:"sidebar-wrap"},[_c('div',{staticClass:"music-charts-filtering-wrap"},[(_vm.filterDeskOrMobile === 'mobile')?_c('ArtistFilter',{ref:"filterMobile",on:{"filterData":_vm.filterDataRequest}}):_vm._e()],1)])]),_c('a',{staticClass:"cd-close",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.filterToggleFlag = false}}})]),(_vm.hidePanelFilter)?_c('a',{staticClass:"cd-filter-trigger",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.filterToggleFlag = !_vm.filterToggleFlag}}}):_vm._e(),_c('div',{staticClass:"artist-list-container"},[_c('div',{staticClass:"artist-list-btn-wrap"},[_c('button',{staticClass:"artist-list-btn-item",style:({background: _vm.$route.query.artist === 'djk-x' ? '#FF0090' : '#FFF5FB',
                                 color: _vm.$route.query.artist === 'djk-x' ? '#FFFFFF' : '#FF0090'
                        }),on:{"click":_vm.toDjkXArtists}},[_vm._v("DjookyX")]),_c('button',{staticClass:"artist-list-btn-item",style:({background: _vm.$route.query.artist === 'djk-awards' ? '#FF0090' : '#FFF5FB',
                                 color: _vm.$route.query.artist === 'djk-awards' ? '#FFFFFF' : '#FF0090'
                        }),on:{"click":_vm.toDjkAwardsArtists}},[_vm._v("Djooky Awards")])]),_c('a',{staticClass:"cd-filter-trigger",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.filterToggleFlag = !_vm.filterToggleFlag}}}),(!_vm.artists.length)?_c('div',{staticClass:"filter-default-text"},[_vm._v("No results...")]):_vm._e(),(_vm.loadFlag)?_c('ArtistListTemplate',{attrs:{"artists":_vm.artists}}):_c('div',{staticClass:"preloader-wrapper"},[_c('v-progress-circular',{attrs:{"size":75,"width":8,"color":"#FF0090","indeterminate":""}})],1),(_vm.checkArtistLength)?_c('b-pagination-nav',{staticClass:"pagination",attrs:{"total-rows":_vm.count,"per-page":_vm.pageSize,"link-gen":_vm.linkGen,"number-of-pages":_vm.count,"prev-text":"«","next-text":"»","first-number":"","last-number":"","use-router":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1),_vm._m(1)],1)])]),_c('server-error-popup',{ref:"serverErrorPopup",attrs:{"errorMessage":_vm.error.serverError}})],1),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aside-title-wrapper"},[_c('h1',{staticClass:"title ieo-title separator"},[_vm._v("Filter")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preloaderss"},[_c('div',{staticClass:"main-circle"},[_c('div',{staticClass:"green-circle"},[_c('div',{staticClass:"brown-circle"})])])])
}]

export { render, staticRenderFns }