<template>
  <div id="my-content">
    <Header/>
    <main id="root">
      <section class="section">
        <div class="page-error-wrap">
          <div class="container-small">
            <div class="page-error">
              <img src="@/assets/img/error404.svg" alt="error page image">
              <div class="error-text">
                <h1>404</h1>
                <p class="oops">Oops! Page you are looking for does not exist</p>
                <div class="border-oops"></div>
                <div class="take-me-back">Take me back to <router-link to="/" class="base-url-link">djookyx.com</router-link></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <Footer/>
  </div>
</template>

<script>
import Header from "../header/Header";
import Footer from "../footer/Footer";

export default {
  name: "error-404",
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  components: {Footer, Header},
}
</script>

<style scoped>
img {
  margin-right: 70px;
}
h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 85px;
  color: #F90190;
  margin: 0;
}
.oops {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
}
.border-oops {
  height: 1px;
  margin-bottom: 13px;
  background: rgba(0, 0, 0, 0.1);
  width: 80%;
}
.take-me-back {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.base-url-link {
  color: #F90190;
}

.page-error-wrap {
  padding: 140px 20px;
}

@media only screen and (max-width: 600px) {
  .page-error-wrap {
    padding: 70px 10px;
  }
  .page-error {
    flex-direction: column;
    align-items: center;
  }
  img {
    margin-right: 0;
  }

  h1 {
    font-size: 60px;
  }

  .oops {
    font-size: 20px;
  }

  .take-me-back {
    font-size: 14px;
  }
}
</style>