<template>
  <v-dialog v-model="dialog">
    <div id="artist-video-popup" class="video-popup-container" @click="closePopup">
      <div class="video" @click.prevent>
        <video-embed css="embed-responsive-16by9" :src="linkVideo"></video-embed>
        <button class="video-close-btn" @click="closePopup">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5656 9.43464C20.2839 9.15068 19.9005 8.99095 19.5005 8.99095C19.1006 8.99095 18.7172 9.15068 18.4355 9.43464L15.0004 12.8846L11.5653 9.43464C10.9771 8.84647 10.0235 8.84647 9.43525 9.43464C8.84705 10.0228 8.84705 10.9764 9.43525 11.5646L12.8853 14.9995L9.43525 18.4345C9.15128 18.7161 8.99154 19.0995 8.99154 19.4995C8.99154 19.8994 9.15128 20.2828 9.43525 20.5645C9.71691 20.8484 10.1003 21.0081 10.5003 21.0081C10.9002 21.0081 11.2837 20.8484 11.5653 20.5645L15.0004 17.1145L18.4355 20.5645C18.7172 20.8484 19.1006 21.0081 19.5005 21.0081C19.9005 21.0081 20.2839 20.8484 20.5656 20.5645C20.8495 20.2828 21.0093 19.8994 21.0093 19.4995C21.0093 19.0995 20.8495 18.7161 20.5656 18.4345L17.1155 14.9995L20.5656 11.5646C20.8495 11.283 21.0093 10.8996 21.0093 10.4996C21.0093 10.0997 20.8495 9.71629 20.5656 9.43464ZM25.6057 4.39473C19.7493 -1.46464 10.2515 -1.46464 4.39351 4.39313C-1.46451 10.2509 -1.4645 19.7482 4.39352 25.606C10.2507 31.4645 19.7485 31.4645 25.6065 25.6068C31.4645 19.749 31.4645 10.2517 25.6065 4.39392L25.6057 4.39473ZM23.4906 23.4894C19.4448 27.5395 13.1014 28.168 8.33951 24.9905C3.57757 21.8131 1.72325 15.7146 3.91048 10.4243C6.0977 5.13405 11.7174 2.12535 17.333 3.23806C22.9486 4.35077 26.9962 9.27502 27.0007 14.9996C27.0115 18.1847 25.7476 21.2418 23.4906 23.4894Z"/>
          </svg>
        </button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "VideoPopup",
  data() {
    return {
      dialog: false,
      linkVideo: 'https://www.youtube.com/watch?v=QrmoXH8-9og&list=PLJxb03gvc3ZWt3dsSuVChhkdpvZatcKEu&index=1',
    };
  },
  props: {
    errorMessage: String,
  },
  methods: {
    openPopup() {
      this.dialog = true;
    },
    closePopup() {
      this.dialog = false;
    },
  },
}
</script>

<style scoped>

#artist-video-popup.video-popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

#artist-video-popup .video {
  position: relative;
  background: #C6C6C6;
  width: 870px;
  height: 490px;
}

#artist-video-popup .video .video-close-btn {
  position: absolute;
  top: -19px;
  right: -19px;
}

#artist-video-popup .video .video-close-btn svg {
  fill: white;
  transition: .5s ease;
  width: 24px;
  height: 24px;
}

#artist-video-popup .video .video-close-btn:hover svg {
  fill: #121212;
}



</style>