export default function(instance) {
    return {
        create(data) {
            return instance.post(`/api/v2/ieo`, data)
        },
        updateIeo(data) {
            return instance.put(`/api/v2/ieo`, data)
        },
        getServiceProviders() {
            return instance.get(`/api/v2/ieo/provider-info`)
        },
        getNeedsList() {
            return instance.get(`/api/v2/ieo/privateuse`)
        },
    }
}