<template>
  <div class="mobile-table-items">
    <div class="mobile-table-item__amount">
      <div class="song-name" :style="{color: positiveAndNegativeColors}" v-text="amount"></div>
      <div class="song-price-per-rr">{{ operation.currencyName }}</div>
    </div>
    <div class="mobile-table-item__details">
      <div class="value-rr">
        {{ operation.financialEventType }}
      </div>
      <div class="value-total-rr">
        {{ operation.createdAt }}
      </div>
    </div>
  </div>
</template>

<script>
import numberFormattingDecimal from "../../mixins/numberCurrencyConverter";
import numberFormattingInteger from "../../mixins/numberConverter";

export default {
  name: "WalletPmListMobile",
  props: {
    operation: {
      type: Object,
      default: () => {
      }
    }
  },
  mixins: [numberFormattingDecimal, numberFormattingInteger],
  data() {
    return {

    }
  },
  computed: {
    amount() {
      return this.numberFormattingDecimal(this.operation.amount)
    },
    positiveAndNegativeColors() {
      return this.operation.amount >= 0 ? '#4C9F4A' : '#9F4A4A'
    }
  },
  mounted() {
    // console.log(this.operation)
  }
}
</script>

<style scoped>
.mobile-table-items {
  width: 100%;
  display: flex;
  margin-top: 10px;
  border-bottom: 1px solid #DEDEDE;
  padding-bottom: 10px;
}

.mobile-table-item__amount {
  width: 50%;
}

.song-name, .value-rr {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #272727;
}

.song-price-per-rr, .value-total-rr {
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 10px;
  color: #747474;
}

.value-rr, .song-name {
  margin-bottom: 5px;
}

.mobile-table-item__details {
  width: 50%;
}

</style>