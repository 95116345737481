<template>
  <tr class="wallet-table-item">
    <td data-label="Token" colspan="2" class="left">
      <div class="pair-wrap">
        <div class="img-wrap" v-if="updCheck">
          <img v-if="wallet.albumCover" :src="wallet.albumCover" alt="Album cover">
          <img v-else src="../../assets/img/default-song-image.jpg" alt="Default album cover">
        </div>
        <p>
        {{wallet.currencyFullName}}
        </p>
      </div>
    </td>
    <td data-label="Song" colspan="4" class="left" v-if="updCheck">
      <p class="artist-name" v-text="wallet.authorPublicName"></p>
      <p class="song-name" v-text="wallet.title"></p>
    </td>
    <td data-label="RR" colspan="2" class="left">{{ amount }} <span class="percent-offered" v-text="'(' + wallet.percentOffered + '%' + ')'"></span></td>
    <td data-label="Price per RR" colspan="2" class="left" v-text="'€' + pricePerOnce"></td>
    <td data-label="Total value of RR" colspan="2" class="left" v-text="'€' + totalValue"></td>
    <td data-label="Total value of RR" colspan="1" class="left">
      <router-link
          class="clickable"
          :to="{path: `/trading/${redirectLink}`}"
          v-text="'Trade'"
      >
      </router-link>
    </td>
  </tr>
</template>

<script>
import numberFormattingDecimal from "../../mixins/numberCurrencyConverter";
import numberFormattingInteger from "../../mixins/numberConverter";

export default {
  name: "WalletPmList",
  props: {
    wallet: {
      type: Object,
      default: () => {
      }
    },
    updCheck: Boolean,
  },
  data() {
    return {
    }
  },
  mixins: [numberFormattingDecimal, numberFormattingInteger],
  computed: {
    amount() {
      return this.numberFormattingInteger(this.wallet.amount)
    },
    pricePerOnce() {
      return this.numberFormattingDecimal(this.wallet.pricePerRR)
    },
    totalValue() {
      return this.numberFormattingDecimal(this.wallet.totalValue)
    },
    redirectLink() {
      const arr = this.wallet.currencyFullName ? this.wallet.currencyFullName.split(' ') : null
      return arr ? arr[0].toLowerCase() : ''
    },
    // percentOffered() {
    //   return this.numberFormattingDecimal(this.wallet.percentOffered)
    // },
  }
}
</script>

<style scoped>

tr.wallet-table-item {
  border: none;
}

tr.wallet-table-item td,  tr.wallet-table-item td:last-child{
  padding: 10px 10px;
}

.wallet-table-item .percent-offered {
  color: gray;
}

.wallet-table-item .clickable {
  max-width: calc(100% - 36px);
  color: #FF0090;
  text-decoration: underline;
  transition: .4s ease;
}

.wallet-table-item .clickable:hover {
  color: #FF0090;
}

.wallet-table-item .artist-name {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #292929;
  margin-bottom: 5px;
}

.wallet-table-item .song-name {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #747474;
  margin-bottom: 0;
}

tr.wallet-table-item td {
  vertical-align: middle;
  border-right: none;
}



.wallet-table-item .img-wrap {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 8px;
}

.wallet-table-item .img-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.wallet-table-item .pair-wrap {
  display: flex;
  align-items: center;
  height: auto;
  width: auto;
}

.wallet-table-item .pair-wrap p {
  margin-bottom: 0;
  width: calc(100% - 36px);
}
</style>