<template>
<div class="ieo-creating-payment-wallet">

  <div class="ieo-creating-payment-wallet-body">
    <div class="wallet-body-text">
      <svg width="34" height="26" viewBox="0 0 34 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 5.49725H27.6169V7.46053H0V5.49725Z" fill="black"/>
        <path d="M27.6169 2.94493C27.6169 1.3211 26.2958 0 24.672 0H2.94493C1.3211 0 0 1.3211 0 2.94493V3.53392H27.6169V2.94493Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M27.5515 12.1261C27.5733 12.1261 27.595 12.1267 27.6169 12.1269V9.42419H0V17.4737C0 19.0975 1.3211 20.4186 2.94493 20.4186H19.6419C19.6361 20.2947 19.6328 20.17 19.6328 20.0447C19.6329 15.6783 23.1851 12.1261 27.5515 12.1261ZM4.90831 11.3874H7.36241C7.90454 11.3874 8.34406 11.827 8.34406 12.3691C8.34406 12.9112 7.90454 13.3507 7.36241 13.3507H4.90831C4.36618 13.3507 3.92666 12.9112 3.92666 12.3691C3.92666 11.827 4.36618 11.3874 4.90831 11.3874ZM10.471 16.4919H4.90831C4.36618 16.4919 3.92666 16.0524 3.92666 15.5103C3.92666 14.9682 4.36618 14.5286 4.90831 14.5286H10.471C11.0131 14.5286 11.4526 14.9682 11.4526 15.5103C11.4526 16.0524 11.0131 16.4919 10.471 16.4919Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M27.551 14.0894C24.2672 14.0894 21.5957 16.761 21.5957 20.0447C21.5957 23.3285 24.2673 26 27.551 26C30.8347 26 33.5063 23.3284 33.5063 20.0447C33.5063 16.761 30.8347 14.0894 27.551 14.0894ZM30.4048 19.5393L28.0488 21.8952C27.8572 22.0869 27.6059 22.1828 27.3548 22.1828C27.1036 22.1828 26.8523 22.087 26.6607 21.8952L25.45 20.6845C25.0666 20.3011 25.0666 19.6796 25.45 19.2963C25.8333 18.9129 26.4549 18.9129 26.8382 19.2963L27.3548 19.8129L29.0167 18.151C29.4 17.7677 30.0215 17.7677 30.4049 18.151C30.7881 18.5344 30.7881 19.1559 30.4048 19.5393Z" fill="#FF0090"/>
      </svg>
      <p>Total funds</p>
    </div>
    <div class="wallet-body-amount">
      <span>{{ `-${price}` }}</span><div class="wallet-body-amount__symbol">€</div>
    </div>
  </div>
  <div class="ieo-creating-btn-wrap">
    <button type="button" class="btn btn-hover ieo-creating-btn" @click="$emit('confirmWithWallet', 'TRANSFER')">
      Start Initial Sale
    </button>
  </div>
</div>
</template>

<script>
export default {
  name: "IeoCreatingPaymentWallet",
  props: {
    price: Number
  }
}
</script>

<style scoped>
.ieo-creating-payment-wallet {
  width: 100%;
}

.ieo-creating-payment-wallet-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 25px;
  margin-top: 44px;
  background: #F9F9F9;
  border-radius: 15px;
}
.wallet-body-text, .wallet-body-amount {
  display: flex;
}
.wallet-body-amount {
  /*align-items: flex-end;*/
}
.wallet-body-text svg {
  margin-right: 11px;
}
.wallet-body-text p {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
.wallet-body-amount span:first-child {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  text-transform: uppercase;
  color: #000000;
}
.wallet-body-amount__symbol {
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #000000;
  opacity: 0.5;
}
.ieo-creating-btn-wrap {
  margin-top: 20px;
}
</style>