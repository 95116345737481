<template>
  <v-dialog v-model="dialog">
    <div id="type-ieo-popup-confirm"
         class="white-popup popup-form order-popup confirm-popup">
      <div class="form-main-content">
        <div class="close-dialog" @click="dialog = false">
          <img src="../../assets/img/close.svg" alt="close-icon" loading="lazy">
        </div>
        <div class="popup-header">
          <h3 class="form-title">You are buying <span class="buy-pm-ipr-count" v-text="songDeltaToFormat"></span> {{ currencyName }} of:</h3>
        </div>
        <div class="popup-content">
            <div class="popup-content-container">
              <vuescroll :ops="ops">
                <div class="scroll-wrap">
                  <div class="confirm-image-descr">
                    <div class="buy-pm-wrapper">
                      <div class="buy-pm-image-wrap">
                        <img v-if="song.songAlbumUrlImage" :src="song.songAlbumUrlImage" alt="Artist image"
                             class="buy-pm-image" onerror="this.onerror=null;this.src='/img/profile-default-image.png';" loading="lazy">
                        <img v-else src="../../assets/img/default-song-image.jpg"
                             alt="Artist image" class="buy-pm-image buy-pm-image-default" loading="lazy">
                      </div>
                      <div class="buy-pm-info">
                        <span class="buy-pm-title" v-text="song.songTitle"></span>
                        <span class="buy-pm-descr" v-text="singerName"></span>
                      </div>
                    </div>
                    <div class="button-block-title">Pay with</div>
                    <div class="payment-main-content">
                      <div class="payment-main-item button-block-wrapper">
                        <button type="button"
                                class="payment-btn"
                                @click.prevent="paymentWithWallet"
                                :style="{border: choosePayment === 'wallet' ? '1px solid #FFC2E4' : '1px solid #EDEDED',
                                     background:  choosePayment === 'wallet' ?  '#FFF2F9' : '#FFFFFF'}"
                        >
                          <div class="payment-btn-container">
                            <svg width="42" height="35" viewBox="0 0 42 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.40899" y="19.0241" width="25.0478" height="16.7832" rx="4.07883" transform="rotate(-49.9259 0.40899 19.0241)" stroke-width="2"
                                    :stroke="choosePayment === 'wallet' ? '#FF0090' : '#A6A6A6'"
                                    :fill="choosePayment === 'wallet' ? '#FFF2F9' : '#FFFFFF'"

                              />
                              <rect x="7.20099" y="13.6486" width="25.0478" height="16.7832" rx="4.07883" transform="rotate(-30 7.20099 13.6486)" stroke-width="2"
                                    :stroke="choosePayment === 'wallet' ? '#FF0090' : '#A6A6A6'"
                                    :fill="choosePayment === 'wallet' ? '#FFF2F9' : '#FFFFFF'"
                              />
                              <rect x="1.76562" y="8.97083" width="35.4563" height="24.029" rx="5.94157" stroke-width="2"
                                    :stroke="choosePayment === 'wallet' ? '#FF0090' : '#A6A6A6'"
                                    :fill="choosePayment === 'wallet' ? '#FFF2F9' : '#FFFFFF'"
                              />
                              <rect x="29.7764" y="17.224" width="11.0271" height="7.30505" rx="3.65253" stroke-width="2"
                                    :stroke="choosePayment === 'wallet' ? '#FF0090' : '#A6A6A6'"
                                    :fill="choosePayment === 'wallet' ? '#FFF2F9' : '#FFFFFF'"
                              />
                            </svg>
                            <div class="payment-btn-text-wrap">
                              <div class="payment-btn-title"
                                   :style="{color: choosePayment === 'wallet' ? '#FF0090' : '#A6A6A6'}"
                              >DjookyX wallet</div>
                              <div class="payment-btn-description"
                                   :style="{color: choosePayment === 'wallet' ? '#FF0090' : '#A6A6A6'}"
                              >No commission</div>
                            </div>
                          </div>
                          <div class="payment-checkbox"
                               :style="{border: choosePayment === 'wallet' ? '0.5px solid #FF0090' : '0.5px solid #A6A6A6',}"
                          >
                            <div class="payment-checkbox-fill"
                                 :style="{display: choosePayment === 'wallet' ? 'block' : 'none'}"
                            ></div>
                          </div>
                        </button>
                        <button type="button"
                                class="payment-btn"
                                @click.prevent="paymentWithCard"
                                :style="{border: choosePayment === 'card' ? '1px solid #FFC2E4' : '1px solid #EDEDED',
                                     background:  choosePayment === 'card' ?  '#FFF2F9' : '#FFFFFF'}"
                        >
                          <div class="payment-btn-container">
                            <svg width="41" height="31" viewBox="0 0 41 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="3" y="3" width="35.125" height="25" rx="5.75" stroke-width="2"
                                    :stroke="choosePayment === 'card' ? '#FF0090' : '#A6A6A6'"
                                    :fill="choosePayment === 'card' ? '#FFF2F9' : '#FFFFFF'"

                              />
                              <rect x="3.6875" y="8.75" width="33.75" height="5.0625"
                                    :stroke="choosePayment === 'card' ? '#FF0090' : '#A6A6A6'"
                                    :fill="choosePayment === 'card' ? '#FF0090' : '#A6A6A6'"
                              />
                            </svg>
                            <div class="payment-btn-text-wrap">
                              <div class="payment-btn-title"
                                   :style="{color: choosePayment === 'card' ? '#FF0090' : '#A6A6A6'}"
                              >Payment card</div>
                              <div class="payment-btn-description"
                                   :style="{color: choosePayment === 'card' ? '#FF0090' : '#A6A6A6'}"
                              >Сommission is <span class="bold">1,8% + 0,18€</span></div>
                            </div>
                          </div>
                          <div class="payment-checkbox"
                               :style="{border: choosePayment === 'card' ? '0.5px solid #FF0090' : '0.5px solid #A6A6A6',}"
                          >
                            <div class="payment-checkbox-fill"
                                 :style="{display: choosePayment === 'card' ? 'block' : 'none'}"
                            ></div>
                          </div>
                        </button>
                      </div>
                      <div class="payment-main-item payment-info-wrapper">
                        <div class="payment-info-block">
                          <div class="payment-info-title">Amount:</div>
                          <div class="payment-info-value">
                            <span class="payment-info-value-item">{{ amount }}<span class="payment-info-value-currency">{{ currencyName }}</span></span>
                            *
                            <span class="payment-info-value-item"><span class="payment-info-value-currency">€</span>{{ price }}</span>
                            =
                            <span class="payment-info-value-item"><span class="payment-info-value-currency">€</span>{{ total }}</span>
                          </div>
                        </div>
                        <div class="payment-info-block"
                             v-if="choosePayment === 'card'"
                        >
                          <div class="payment-info-title">Сommission:</div>
                          <div class="payment-info-commission-wrap">
                            <span class="hint hint--left" data-hint="To make sure all payments are safe and transparent, we are using an escrow agent.">
                              <svg class="payment-info-hint" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z" fill="black"/>
                              </svg>
                            </span>
                            <div class="payment-info-value commission-desktop">
                              <span class="payment-info-value-item">1,8<span class="payment-info-value-currency">%</span></span>
                              +
                              <span class="payment-info-value-item"><span class="payment-info-value-currency">€</span>0,18</span>
                              =
                              <span class="payment-info-value-item"><span class="payment-info-value-currency">€</span>{{ paymentFeeToFormat }}</span>
                            </div>
                            <div class="payment-info-value commission-mobile">
                              <span class="payment-info-value-item">{{'€' + paymentFeeToFormat }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="payment-info-block total-block">
                          <div class="payment-info-title">Total:</div>
                          <div class="payment-info-value">
                          <span class="payment-info-value-item value-color"
                                v-if="choosePayment === 'wallet'"
                          ><span class="payment-info-value-currency">€</span>{{ total }}</span>
                            <span class="payment-info-value-item value-color"
                                  v-else
                            ><span class="payment-info-value-currency">€</span>{{ totalWithFee }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="payment-button-wrapper">
                    <button class="payment-button payment-confirm-btn"
                            @click="confirmPayment"
                    >Confirm</button>
                    <button class="payment-button payment-cancel-btn"
                            @click="closePopup"
                    >Cancel</button>
                  </div>
                </div>
              </vuescroll>
            </div>
        </div>
      </div>
    </div>

  </v-dialog>
</template>

<script>

import vuescroll from "vuescroll";
import numberFormattingDecimal from "../../mixins/numberCurrencyConverter";
import numberFormattingInteger from "../../mixins/numberConverter";


export default {
  name: "ConfirmPopupForBuyPM",
  data() {
    return {
      dialog: false,
      // amount: '',
      // price: '',
      // total: '',
      choosePayment: 'wallet',
      currencyName: process.env.VUE_APP_CURRENCY_NAME_SHORT,
      ops: {
        rail: {
          background: 'rgba(234, 234, 234)',
          opacity: 0.5,
          size: '6px',
          specifyBorderRadius: false,
          gutterOfEnds: null,
          gutterOfSide: '2px',
          keepShow: false
        },
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: true,
          keepShow: false,
          background: '#FF0090',
          opacity: 1,
          hoverStyle: false,
          specifyBorderRadius: false,
          minSize: 0.3,
          size: '6px',
          disable: false
        }
      },
    }
  },
  props: {
    song: Object,
    userBid: Object,
    userBidTotal: Number,
    songDelta: Number,
    paymentFee: Number,
    singerName: String,
  },
  mixins: [numberFormattingDecimal, numberFormattingInteger],
  computed: {
    paymentFeeToFormat() {
      return this.numberFormattingDecimal(this.paymentFee)
    },
    totalWithFee() {
      let totalFee = Math.floor((+this.userBidTotal + +this.paymentFee) * 100) / 100
      return this.numberFormattingDecimal(totalFee)
    },
    songDeltaToFormat() {
      return this.numberFormattingInteger(this.songDelta)
    },
    amount() {
      return this.numberFormattingInteger(this.userBid.amount)
    },
    price() {
      return this.numberFormattingDecimal(this.userBid.price)
    },
    total() {
      return this.numberFormattingDecimal(this.userBidTotal)
    },
  },
  methods: {
    openPopups() {
      this.dialog = true
      // this.amount = new Intl.NumberFormat().format(this.userBid.amount)
      // // this.price = new Intl.NumberFormat().format(this.userBid.price)
      // if (this.userBid.price !== null && this.userBid.price !== '') {
      //   this.price = +this.userBid.price
      //   this.price = this.price.toFixed(2)
      // }
      // // this.total = new Intl.NumberFormat().format(+this.userBidTotal)
      // this.total = this.userBidTotal
    },
    closePopup() {
      this.dialog = false
    },
    paymentWithCard() {
      this.choosePayment = 'card'
    },
    paymentWithWallet() {
      this.choosePayment = 'wallet'
    },
    confirmWithCard() {
      this.$emit('confirmWithCard', 'CARD')
    },
    confirmWithWallet() {
      this.$emit('confirmWithWallet', 'TRANSFER')
    },
    confirmPayment() {
      if (this.choosePayment === 'card') {
        this.confirmWithCard()
      } else {
        this.confirmWithWallet()
      }
    },
  },
  components: {
    vuescroll,
  }
};
</script>

<style scoped>

.button-block-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 45px;
}

#type-ieo-popup-confirm .btn {
  width: calc(50% - 10px);
  padding: 19px 77px;
  background: #FFF2F9;
  border: 1px solid #FFC2E4;
  border-radius: 8px;
  box-shadow: unset;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #FF0090;
  margin-bottom: 35px;
}

#type-ieo-popup-confirm .btn:hover {
  background: #FFFFFF;
}

.button-block-title {
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #3B3B3B;
  margin-bottom: 20px;
}

#type-ieo-popup-confirm .cancel-btn {
  margin: 0;
  padding: 0;
  border: none;
}

.payment-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(50% - 10px);
  box-sizing: border-box;
  padding: 16px 25px;
  border-radius: 8px;
  /*margin-bottom: 45px;*/
  transition: .5s ease;
}

.payment-btn-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 32px);
}

.payment-btn-container svg {
  margin-right: 13px;
  transition: .5s ease;
}

.payment-btn-text-wrap {
  text-align: left;
}

.payment-btn-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 7px;
  transition: .5s ease;
}

.payment-btn-description {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  transition: .5s ease;
}

.payment-btn-description .bold {
  font-weight: bold;
}

.payment-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 21px;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 50%;
  transition: .5s ease;
}

.payment-checkbox-fill {
  width: 13px;
  height: 13px;
  background: #FF0090;
  border-radius: 50%;
  transition: .5s ease;
}

.payment-info-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.payment-info-block:last-child {
  margin-bottom: 0;
}

.payment-info-block.total-block {
  padding-top: 16px;
  border-top: 1px solid #EAEAEA;
}

.payment-info-title {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #A6A6A6;
}

.payment-info-commission-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-info-hint {
  margin-right: 13px;
  transition: .5s ease;
  opacity: 0.2;
  cursor: pointer;
}

.payment-info-hint:hover {
  margin-right: 13px;
  opacity: 1;
}

.payment-info-value.commission-mobile {
  display: none;
}

.payment-info-value {
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: right;
  color: #3B3B3B;
}

.payment-info-value-item {
  font-weight: 600;
}

.payment-info-value-item.value-color {
  color: #FF0090;
}

.payment-info-value-currency {
  font-weight: normal;
}

.payment-button-wrapper {
  margin-top: 45px;
}

.payment-button {
  padding: 15px 30px;
  max-width: 215px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  transition: .5s ease;
}

.payment-cancel-btn {
  margin-left: 20px;
  border: 1px solid #EDEDED;
  background: #FFFFFF;
  color: #A6A6A6;
}

.payment-cancel-btn:hover {
  background: #FFFFFF;
  color: #FF0090;
  border: 1px solid #FF0090;
}

.payment-confirm-btn {
  border: 1px solid #FF0090;
  background: #FF0090;
  color: #FFFFFF;
}

.payment-confirm-btn:hover {
  background: #FFFFFF;
  color: #FF0090;
}

#type-ieo-popup-confirm .popup-content-container .scroll-wrap {
  max-height: 100%;
  padding-right: 0;
}


@media only screen and (max-width: 768px) {

  #type-ieo-popup-confirm .popup-content-container .scroll-wrap {
    max-height: 400px;
    padding-right: 20px;
  }

  .button-block-wrapper {
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  .payment-main-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .payment-main-item {
    width: calc(50% - 13px);
  }

  .payment-info-block {
    flex-direction: column;
    align-items: flex-start;
  }

  .payment-info-block.total-block {
    padding-top: 10px;
  }

  .payment-info-title  {
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 6px;
  }

  .payment-info-value  {
    font-size: 17px;
    line-height: 20px;
  }

  .payment-btn {
    width: 100%;
    margin-bottom: 15px;
  }

  .payment-btn:last-child {
    margin-bottom: 0;
  }

  .buy-pm-wrapper {
    padding-bottom: 20px;
    margin-bottom: 15px;
  }

  .button-block-title {
    margin-bottom: 15px;
  }

  .payment-btn-title {
    font-size: 16px;
    line-height: 19px;
  }

  .payment-btn-description {
    font-size: 11px;
    line-height: 14px;
  }

  .payment-button-wrapper {
    margin-top: 30px;
  }

  /*.payment-btn-container {*/
  /*  width: calc(100% - 29px);*/
  /*}*/

  .payment-btn {
    padding: 13px 20px;
  }

  .payment-btn-container svg {
    margin-right: 10px;
    max-width: 32px;
  }

  .payment-checkbox {
    width: 19px;
    height: 19px;
  }
}

@media only screen and (max-width: 600px) {
  .payment-button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .payment-button {
    padding: 15px 20px;
  }

  .payment-cancel-btn {
    margin-left: 0;
    margin-top: 12px;
  }
}

@media only screen and (max-width: 540px) {
  #type-ieo-popup-confirm .popup-content-container {
    padding: 20px 30px 30px 30px;
  }

  .buy-pm-wrapper {
    flex-direction: column;
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 32px;
  }

  .buy-pm-image-wrap {
    order: 2;
    margin-right: 0;
    width: 100%;
    height: 135px;
    align-self: center;
    border-radius: 5px;
  }

  .buy-pm-image-wrap img {
    border-radius: 5px;
  }

  .buy-pm-info {
    margin-bottom: 16px;
  }

  .payment-main-content {
    flex-direction: column;
  }

  .payment-main-item {
    width: 100%;
  }

  .payment-btn {
    margin-bottom: 12px;
  }

  .button-block-wrapper {
    margin-bottom: 32px;
  }

  .payment-info-value.commission-desktop {
    display: none;
  }

  .payment-info-value.commission-mobile {
    display: block;
  }

  .payment-info-hint {
    margin-right: 3px;
  }

  .payment-info-block {
    flex-direction: row;
  }

  /*.payment-button-wrapper {*/
  /*  display: flex;*/
  /*  flex-direction: column;*/
  /*  align-items: center;*/
  /*}*/

  /*.payment-cancel-btn {*/
  /*  margin-left: 0;*/
  /*  margin-bottom: 16px;*/
  /*}*/

  .payment-btn-title {
    font-size: 18px;
    line-height: 21px;
  }

  .payment-btn-description {
    font-size: 13px;
    line-height: 15px;
  }

  .payment-info-title {
    font-size: 16px;
    line-height: 19px;
  }

  .payment-info-value {
    font-size: 16px;
    line-height: 19px;
  }

  .popup-content-container .confirm-image-descr {
    padding: 0;
  }

  .buy-pm-descr {
    font-size: 18px;
    line-height: 21px;
  }

  .button-block-title {
    font-size: 18px;
    line-height: 21px;
  }
}

@media only screen and (max-width: 500px) {

  /*чтобы перебить мейн стили*/
  .popup-content-container .confirm-image-descr {
    padding: 0;
  }
  .buy-pm-descr {
    font-size: 18px;
    line-height: 21px;
  }
  .button-block-title {
    font-size: 18px;
    line-height: 21px;
  }
  /*=======================*/
}

</style>