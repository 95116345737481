export default function(instance) {
  return {
    getNationalities() {
      return instance.get('/api/v2/mangopay/nationalities')
    },
    getBankCountries() {
      return instance.get(`/api/v2/payment/bank-details-countries`)
    },
    getCountries() {
      return instance.get('/api/v2/countries')
    },
  }
}

