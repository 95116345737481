<template>
  <div class="artist-list-item" :class="{'artist-list__carousel': artistType === 'company-public-artists'}">

    <div class="artist-list-item-header" v-if="isDjkAwardsArtist">
      <router-link :to="{path: '/artist-djk-awards/' + artistIdentifier}" v-if="userCard.id">
        <img v-if="artistImage" :src="artistImage" alt="Artist image" class="artist-list-item-image" loading="lazy">
        <img v-else src="../../assets/img/profile-default-image.png" alt="Artist image"
             class="artist-list-item-image artist-list-item-image-default" loading="lazy">
      </router-link>
    </div>

    <div class="artist-list-item-header" v-else>
      <router-link :to="{path: '/artist/' + artistIdentifier}" v-if="userCard.username">
        <img v-if="artistImage" :src="artistImage" alt="Artist image" class="artist-list-item-image" loading="lazy">
        <img v-else src="../../assets/img/profile-default-image.png" alt="Artist image"
             class="artist-list-item-image artist-list-item-image-default" loading="lazy">
      </router-link>

      <button class="artist-edit-btn" v-if="artistType === 'associated-artist'" @click="$emit('editArtist', userCard.uuid)">
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="14" cy="14" r="14"/>
          <path d="M14 18.2H18.725" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16.3629 9.5375C16.5717 9.32864 16.855 9.2113 17.1504 9.2113C17.2966 9.2113 17.4415 9.24011 17.5766 9.29608C17.7117 9.35205 17.8345 9.43408 17.9379 9.5375C18.0413 9.64091 18.1233 9.76369 18.1793 9.8988C18.2353 10.0339 18.2641 10.1787 18.2641 10.325C18.2641 10.4712 18.2353 10.6161 18.1793 10.7512C18.1233 10.8863 18.0413 11.0091 17.9379 11.1125L11.3754 17.675L9.27539 18.2L9.80039 16.1L16.3629 9.5375Z" stroke="#3C5A9A" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>

    </div>

    <div class="artist-list-item-body">
      <div class="artist-list-item-body-top">

        <div class="artist-infowrap" v-if="isDjkAwardsArtist">
          <router-link class="artist-list-item-link"
                       :to="{path: '/artist-djk-awards/' + artistIdentifier}"
                       v-if="userCard.id"
          >
            <span class="artist-list-item-name-song" v-text="artistName"></span>
          </router-link>
        </div>

        <div class="artist-infowrap" v-else>
          <router-link class="artist-list-item-link"
                       :to="{path: '/artist/' + artistIdentifier}"
                       v-if="userCard.username"
          >
            <span class="artist-list-item-name-song" v-text="artistName"></span>
          </router-link>
        </div>

        <div class="artist-list-item-info" v-if="!isDjkAwardsArtist">
            <span class="artist-list-item-name-artist" v-text="artistDescription"></span>
        </div>

      </div>
      <div class="artist-list-item-body-bottom">
        <div class="artist-list-item-info-list">
          <div class="artist-list-item-info-list-item artist-list-item-info-list-item-subscribers" v-if="!isDjkAwardsArtist">
            <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"
                    fill="#FF0090"></path>
            </svg>
            <span v-text="artistNumOfSubscriber()"></span>
          </div>
          <div :style="{width: infoListItemsWidth}" class="artist-list-item-info-list-item artist-list-item-info-list-item-location">
            <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.75 5.76477C0.75 2.58098 3.33098 -9.16285e-07 6.51477 0C9.69856 0 12.2795 2.58098 12.2795 5.76477C12.2795 9.60795 6.51477 15.3727 6.51477 15.3727C6.51477 15.3727 0.75 9.60795 0.75 5.76477ZM4.11278 5.76477C4.11278 7.09135 5.18819 8.16676 6.51477 8.16676C7.84135 8.16676 8.91676 7.09135 8.91676 5.76477C8.91676 4.43819 7.84135 3.36278 6.51477 3.36278C5.18819 3.36278 4.11278 4.43819 4.11278 5.76477H4.11278Z"
                    fill="#FF0090"/>
            </svg>
            <span v-text="artistCountry"></span>
          </div>
          <div :style="{width: infoListItemsWidth}" class="artist-list-item-info-list-item artist-list-item-info-list-item-song-count">
            <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.235 2.82187L6.70572 0.174877C6.43704 -0.0253702 6.07811 -0.0561358 5.77927 0.0954665C5.48256 0.244979 5.295 0.548492 5.29399 0.880743V8.4335C4.76107 8.11273 4.15134 7.942 3.52933 7.9394C1.58013 7.93939 0 9.51953 0 11.4687C0 13.4179 1.58013 14.998 3.52933 14.998C5.47852 14.998 7.05865 13.4179 7.05865 11.4687V2.64541L9.17625 4.2336C9.56609 4.52598 10.1191 4.44697 10.4115 4.05714C10.7039 3.6673 10.6249 3.11425 10.235 2.82187Z"
                    fill="#FF0090"/>
            </svg>
            <span v-text="`${artistNumOfSongs} song`"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import nameHandler from "@/mixins/nameHandler";

export default {
  name: "Artist",
  props: {
    userCard: {
      type: Object
    },
    artistType: {
      type: String,
    },
  },
  data() {
    return {
      artistName: '',
      artistImage: '',
      artistDescription: '',
      artistCountry: '',
      artistNumOfSongs: 0,
      artistIdentifier: '',
    }
  },
  mixins: [nameHandler],
  computed: {
    infoListItemsWidth() {
      let artistType = this.$route.query.artist
      return artistType === 'djk-awards' ? 'calc(50% - 10px)' : 'calc(33% - 20px)'
    },
    isDjkAwardsArtist() {
      return this.$route.query.artist === 'djk-awards'
    },
  },
  methods: {
    artistNumOfSubscriber() {
      let subscribers = this.userCard.numberOfSubscriber
      if (!subscribers) {
        return 0
      }
      if (subscribers < 1000) {
        return subscribers
      }
      if (subscribers < 1000000) {
        let roundSubscribers = Math.round(subscribers / 1000) * 1000
        let arr = roundSubscribers.toString().split('')
        arr.splice(-3, 3, 'K')
        // let res = arr.join('')
        return arr.join('')
      }

      let roundSubscribers = Math.round(subscribers / 1000000) * 1000000
      let arr = roundSubscribers.toString().split('')
      arr.splice(-6, 6, 'KK')
      return arr.join('')
    },
  },
  mounted() {
    this.artistName = this.getNameDefault(null, this.userCard.stageName, this.userCard.firstName, this.userCard.lastName)
    this.artistIdentifier = this.userCard.username || this.userCard.id
    this.artistImage = this.userCard.photo
    this.artistDescription = this.userCard.headline || this.userCard.description
    this.artistCountry = this.userCard.country || this.userCard.countryId
    this.artistNumOfSongs = this.userCard.numberOfSongs
  },
}
</script>

<style scoped>

</style>