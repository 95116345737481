<template>
  <div class="mobile-table-items">
    <div class="mobile-table-item__song">
      <div class="song-name">
        <router-link
            class="clickable"
            :to="{path: `/trading/${redirectLink}`}"
            v-text="wallet.currencyFullName"
        ></router-link>
      </div>
      <div class="song-price-per-rr">{{ `1${currencyName} = ${pricePerOnce}€` }}</div>
    </div>
    <div class="mobile-table-item__value">
      <div class="value-rr">
        {{ `${currencyName} ${amount} (${wallet.percentOffered}%)` }}
      </div>
      <div class="value-total-rr">
        {{ `€ ${totalValue}` }}
      </div>
    </div>
  </div>
</template>

<script>
import numberFormattingDecimal from "../../mixins/numberCurrencyConverter";
import numberFormattingInteger from "../../mixins/numberConverter";

export default {
  name: "WalletPmListMobile",
  props: {
    wallet: {
      type: Object,
      default: () => {
      }
    }
  },
  mixins: [numberFormattingDecimal, numberFormattingInteger],
  data() {
    return {
      currencyName: process.env.VUE_APP_CURRENCY_NAME_SHORT,
    }
  },
  computed: {
    amount() {
      return this.numberFormattingInteger(this.wallet.amount)
    },
    pricePerOnce() {
      return this.numberFormattingDecimal(this.wallet.pricePerRR)
    },
    totalValue() {
      return this.numberFormattingDecimal(this.wallet.totalValue)
    },
    redirectLink() {
      const arr = this.wallet.currencyFullName ? this.wallet.currencyFullName.split(' ') : null
      return arr ? arr[0].toLowerCase() : ''
    },
  }
}
</script>

<style scoped>
.mobile-table-items {
  width: 100%;
  display: flex;
  margin-top: 10px;
  border-bottom: 1px solid #DEDEDE;
}

.mobile-table-item__song {
  width: 65%;
}

.song-name, .value-rr {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #272727;
}

.song-price-per-rr, .value-total-rr {
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 10px;
  color: #747474;
}

.mobile-table-item__value {
  width: 35%;
}

.mobile-table-items .clickable {
  color: #FF0090;
}

.mobile-table-items .clickable:hover {
  color: #FF0090;
}

</style>