export default function (instance) {
  return {
    getPayment(body) {
      return instance.post(`/api/v2/payment/purchase`, body)
    },
    // getPaymentStatus(transferId) {
    //     return instance.get(`/api/v2/payment/purchase/status/${transferId}`)
    // },
    getPayInStatus(transferId) {
      return instance.get(`/api/v2/payment/purchase/status/pay-in/${transferId}`)
    },
    getFinancialSettingAmount() {
      return instance.get(`api/v2/financial-setting-amount`)
    },
    getBankingAlias() {
      return instance.get(`/api/v2/payment/bankingalias`)
    },
    createBankingAlias() {
      return instance.post(`/api/v2/payment/bankingalias`)
    },

  }
}