<template>
    <tbody>
        <MyOrdersList
            v-for="(history, index) in orders"
            :key="index"
            :history="history"
        />
    </tbody>
</template>

<script>
    import MyOrdersList from "./MyOrdersList";
    export default {
        name: "MyOrdersTemplate",
        props: {
          orders: {
              type: Array
          }
        },
        components: {MyOrdersList}
    }
</script>

<style scoped>

</style>