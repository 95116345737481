<template>
  <div id="my-song-list" class="my-song-list">
    <PrimaryMarketList
      v-for="(song, index) in songs"
      :key="index"
      :index="index"
      :identifier="'auction-list'"
      :song="song"
      :current="current"
      @followIeo="$emit('followIeo')"
      @unfollowIeo="$emit('unfollowIeo')"
      @playingMusic="compareSongs"
      @serverError="serverErrorFunc"
    />
  </div>
</template>

<script>
import PrimaryMarketList from "./PrimaryMarketList";
export default {
  name: "PrimaryMarketTemplate",
  data() {
    return {
      current: ''
    }
  },
  props: {
    songs: {
      type: Array,
      default: () => [],

    }
  },
  methods: {
    compareSongs(song) {
      this.current = song
    },
    serverErrorFunc(data) {
      this.$emit('serverError', data)
    }
  },
  components: {PrimaryMarketList}
}
</script>

<style scoped>

</style>