<template>
  <div id="my-content" ref="top">
    <Header/>
    <main id="root">
      <div class="profile">
        <Sidebar/>

        <div class="profile-content" v-if="loadFlag">
          <h1 class="title page-title separator">CASH WITHDRAWAL</h1>
          <div class="border-line"></div>
          <div class="trasnfer-amount-wrapper" v-if="checkCard">
            <span>Enter the amount you want to withdraw from the DjookyX wallet</span>

            <div class="trasnfer-amount-input-block">
              <div class="trasnfer-amount-header">Amount</div>
              <div class="trasnfer-amount-input-wrap amount-pay-field">
                <VueAutoNum
                    v-model="amount"
                    placeholder="€ 0.00"
                    class="transfer-amount-input"
                    name="amount"
                    :options="numParams"
                ></VueAutoNum>
<!--                <money-->
<!--                    v-model="amount"-->
<!--                    v-bind="money"-->
<!--                    class="transfer-amount-input"-->
<!--                    name="amount"-->
<!--                ></money>-->
<!--                <input type="number" name="amount" class="transfer-amount-input" v-model="amount" placeholder="0">-->

              </div>
              <p class="error-message absolute"
                 v-if="amountIsValid">
                Value must be positive integer</p>
            </div>
          </div>

          <div class="bank-details-wrapper" v-if="stepWithdrawal === 1">
            <div class="bank-details-header">
              <div class="bank-details-head-title">Choose your bank account type:</div>

              <div class="bank-details-radio-wrap">

                <div class="bank-details-radio-item" @click="clearFields">
                  <label for="withdrawal-radio-us" class="bank-details-radio-item-border">
                    <div class="bank-details-radio-item-fill"
                         :style="{display: formData.bankAccountType === 'US' ? 'block' : 'none'}"
                    ></div>
                    <input id="withdrawal-radio-us" type="radio" name="document-type" value="US"
                           v-model="formData.bankAccountType"
                    >
                  </label>
                  <label for="withdrawal-radio-us" class="bank-details-radio-item-title">US</label>
                </div>

                <div class="bank-details-radio-item" @click="clearFields">
                  <label for="withdrawal-radio-gb" class="bank-details-radio-item-border">
                    <div class="bank-details-radio-item-fill"
                         :style="{display: formData.bankAccountType === 'GB' ? 'block' : 'none'}"
                    ></div>
                    <input id="withdrawal-radio-gb" type="radio" name="document-type" value="GB"
                           v-model="formData.bankAccountType"

                    >
                  </label>
                  <label for="withdrawal-radio-gb" class="bank-details-radio-item-title">GB</label>
                </div>

                <div class="bank-details-radio-item" @click="clearFields">
                  <label for="withdrawal-radio-iban" class="bank-details-radio-item-border">
                    <div class="bank-details-radio-item-fill"
                         :style="{display: formData.bankAccountType === 'IBAN' ? 'block' : 'none'}"
                    ></div>
                    <input id="withdrawal-radio-iban" type="radio" name="document-type" value="IBAN"
                           v-model="formData.bankAccountType"
                    >
                  </label>
                  <label for="withdrawal-radio-iban" class="bank-details-radio-item-title">IBAN</label>
                </div>

                <div class="bank-details-radio-item" @click="clearFields">
                  <label for="withdrawal-radio-ca" class="bank-details-radio-item-border">
                    <div class="bank-details-radio-item-fill"
                         :style="{display: formData.bankAccountType === 'CA' ? 'block' : 'none'}"
                    ></div>
                    <input id="withdrawal-radio-ca" type="radio" name="document-type" value="CA"
                           v-model="formData.bankAccountType"
                    >
                  </label>
                  <label for="withdrawal-radio-ca" class="bank-details-radio-item-title">CA</label>
                </div>

                <div class="bank-details-radio-item" @click="clearFields">
                  <label for="withdrawal-radio-other" class="bank-details-radio-item-border">
                    <div class="bank-details-radio-item-fill"
                         :style="{display: formData.bankAccountType === 'OTHER' ? 'block' : 'none'}"
                    ></div>
                    <input id="withdrawal-radio-other" type="radio" name="document-type" value="OTHER"
                           v-model="formData.bankAccountType"
                    >
                  </label>
                  <label for="withdrawal-radio-other" class="bank-details-radio-item-title">OTHER</label>
                </div>

              </div>

            </div>
            <form class="bank-details-main"
                  @submit.prevent="createAccount"
            >


              <div class="bank-details-main-ident"></div>

              <div class="withdrawal-input-wrap">
                <label for="withdrawal-input-address1" class="withdrawal-input-label">Bank name</label>
                <div class="withdrawal-input-validation-wrap">
                  <input id="withdrawal-input-bank-name" type="text" placeholder="Bank name"
                         v-model="formData.bankName"
                         @blur="$v.formData.bankName.$touch()"
                  >
                  <p class="error-message"
                     v-if="!$v.formData.bankName.maxLength">
                    Must be letters or numbers only and maximum 35 characters long.</p>
                </div>
              </div>


              <div class="withdrawal-input-wrap">
                <label for="withdrawal-input-address1" class="withdrawal-input-label">Address (Line 1)<span
                    class="required-mark">*</span></label>
                <div class="withdrawal-input-validation-wrap">
                  <input id="withdrawal-input-address1" type="text" placeholder="Address (Line 1)"
                         v-model="formData.mangoPayAddress.addressLine1"
                  >
                  <p class="error-message"
                     v-if="$v.formData.mangoPayAddress.addressLine1.$dirty && !$v.formData.mangoPayAddress.addressLine1.required">
                    Field is required</p>
                </div>
              </div>

              <div class="withdrawal-input-wrap">
                <label for="withdrawal-input-address2" class="withdrawal-input-label">Address (Line 2)</label>
                <div class="withdrawal-input-validation-wrap">

                  <input id="withdrawal-input-address2" type="text" placeholder="Address (Line 2)"
                         v-model="formData.mangoPayAddress.addressLine2"
                  >
                </div>
              </div>

              <div class="withdrawal-input-wrap">
                <label for="withdrawal-input-city" class="withdrawal-input-label">City<span
                    class="required-mark">*</span></label>
                <div class="withdrawal-input-validation-wrap">
                  <input id="withdrawal-input-city" type="text" placeholder="City"
                         v-model="formData.mangoPayAddress.city"
                  >
                  <p class="error-message"
                     v-if="$v.formData.mangoPayAddress.city.$dirty && !$v.formData.mangoPayAddress.city.required">
                    Field is required</p>
                </div>
              </div>

              <div class="withdrawal-input-wrap">
                <label for="withdrawal-input-region" class="withdrawal-input-label">Region<span
                    class="required-mark"
                    v-if="formData.mangoPayAddress.country === 'US' || formData.mangoPayAddress.country === 'CA' || formData.mangoPayAddress.country === 'MX'">*</span></label>
                <div class="withdrawal-input-validation-wrap">
                  <input id="withdrawal-input-region" type="text" placeholder="Region"
                         v-model="formData.mangoPayAddress.region"
                  >
                </div>
              </div>

              <div class="withdrawal-input-wrap">
                <label for="withdrawal-input-postal_code" class="withdrawal-input-label">Postal code<span
                    class="required-mark">*</span></label>
                <div class="withdrawal-input-validation-wrap">
                  <input id="withdrawal-input-postal_code" type="text" placeholder="Postal code"
                         v-model="formData.mangoPayAddress.postalCode"
                  >
                  <p class="error-message"
                     v-if="$v.formData.mangoPayAddress.postalCode.$dirty && !$v.formData.mangoPayAddress.postalCode.required">
                    Field is required</p>
                </div>
              </div>

              <div class="withdrawal-input-wrap withdrawal-input__country-wrap">
                <!--                <v-select :options="countriesList" :reduce="country => country.iso" label="country" />-->
                <label class="withdrawal-input-label">
                  {{ formData.bankAccountType === 'OTHER' ? 'Owner Country' : 'Country' }}
                  <span class="required-mark">*</span></label>
                <div class="withdrawal-input-validation-wrap">

                  <div class="withdrawal-select-container">

                    <CountrySelect class="withdrawal__country-select"
                                   id="id_withdrawal_country"
                                   name="withdrawalCountry"
                                   :options="searchedCountries"
                                   :search="searchSelect"
                                   :placeholder="'Country'"
                                   :identifier="'withdrawal'"
                                   @selectedField="selectedField"
                                   @changed="setCountry"
                                   @searchCheck="searchCheck"
                                   ref="countrySelectWithdrawal"

                    />

<!--                    <div class="withdrawal-select-item"-->
<!--                         :style="{transform: selectStatus ? 'rotate(180deg)' : ''}"-->
<!--                    >-->
<!--                      <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                        <path d="M10.3945 0.974609L5.69727 5.67188L1 0.974609" stroke="#272727" stroke-linecap="round"-->
<!--                              stroke-linejoin="round"/>-->
<!--                      </svg>-->
<!--                    </div>-->
                  </div>
                  <p class="error-message"
                     v-if="$v.formData.mangoPayAddress.country.$dirty && !$v.formData.mangoPayAddress.country.required">
                    Field is required</p>
                </div>
                <!--                <label for="withdrawal-input-country" class="withdrawal-input-label">Country</label>-->
                <!--                <input id="withdrawal-input-country" type="text" placeholder="Country"-->
                <!--                       v-model="formData.mangoPayAddress.country">-->
              </div>

              <div class="withdrawal-input-wrap"
                   v-if="formData.bankAccountType === 'US'"
              >
                <label for="withdrawal-input-account_number_us" class="withdrawal-input-label">
                  Account number<span class="required-mark">*</span>
                </label>
                <div class="withdrawal-input-validation-wrap">
                  <input id="withdrawal-input-account_number_us" type="text" placeholder="Account number"
                         v-model="mangoPayUS.accountNumber"
                         @blur="$v.mangoPayUS.accountNumber.$touch()"
                  >
                  <p class="error-message"
                     v-if="$v.mangoPayUS.accountNumber.$dirty && !$v.mangoPayUS.accountNumber.required">
                    Field is required</p>
                  <p class="error-message" v-else-if="!$v.mangoPayUS.accountNumber.integer">The account number of the
                    bank account. US account numbers must be digits only.</p>
                  <p class="error-message"
                     v-else-if="!$v.mangoPayUS.accountNumber.minLength">
                    Must be minimum 4 characters long.
                  </p>
                </div>
              </div>

              <div class="withdrawal-input-wrap"
                   v-if="formData.bankAccountType === 'GB'"
              >
                <label for="withdrawal-input-account_number_gb" class="withdrawal-input-label">
                  Account number<span class="required-mark">*</span>
                </label>
                <div class="withdrawal-input-validation-wrap">
                  <input id="withdrawal-input-account_number_gb" type="text" placeholder="Account number"
                         v-model="mangoPayGB.accountNumber"
                         @blur="$v.mangoPayGB.accountNumber.$touch()"
                  >
                  <p class="error-message"
                     v-if="$v.mangoPayGB.accountNumber.$dirty && !$v.mangoPayGB.accountNumber.required">
                    Field is required</p>
                  <p class="error-message" ref="mangoPayGBSortCode"
                     v-else-if="!$v.mangoPayGB.accountNumber.minLength || !$v.mangoPayGB.accountNumber.maxLength">
                    Must be numbers only, and 8 digits long</p>
                  <p class="error-message" v-else-if="!$v.mangoPayGB.accountNumber.integer">The account number of the
                    bank account. US account numbers must be digits only.</p>
                </div>
              </div>

              <div class="withdrawal-input-wrap"
                   v-if="formData.bankAccountType === 'OTHER'"
              >
                <label for="withdrawal-input-account_number_gb" class="withdrawal-input-label">
                  Account number<span class="required-mark">*</span>
                </label>
                <div class="withdrawal-input-validation-wrap">
                  <input id="withdrawal-input-account_number_other" type="text" placeholder="Account number"
                         v-model="mangoPayOther.accountNumber"
                         @blur="$v.mangoPayOther.accountNumber.$touch()"
                  >
                  <p class="error-message"
                     v-if="$v.mangoPayOther.accountNumber.$dirty && !$v.mangoPayOther.accountNumber.required">
                    Field is required</p>

                  <p class="error-message"
                     v-else-if="!$v.mangoPayOther.accountNumber.minLength || !$v.mangoPayOther.accountNumber.maxLength || !$v.mangoPayOther.accountNumber.integer">
                    The
                    account number of the
                    bank account. Must be numbers only. 7-35 digits.</p>
                </div>
              </div>

              <div class="bank-details-main-container">


                <div class="withdrawal-input-wrap"
                     v-if="formData.bankAccountType === 'IBAN'"
                >
                  <label for="withdrawal-input-iban" class="withdrawal-input-label">
                    IBAN<span class="required-mark">*</span>
                  </label>
                  <div class="withdrawal-input-validation-wrap">
                    <input id="withdrawal-input-iban" type="text" placeholder="IBAN"
                           v-model="mangoPayIBAN.iban"
                           @blur="$v.mangoPayIBAN.iban.$touch()"
                    >
                    <p class="error-message"
                       v-if="$v.mangoPayIBAN.iban.$dirty && !$v.mangoPayIBAN.iban.required">
                      Field is required</p>
                  </div>
                </div>

              </div>

              <div class="withdrawal-input-wrap"
                   v-if="formData.bankAccountType === 'CA'"
              >
                <label for="withdrawal-input-account_number_ca" class="withdrawal-input-label">
                  Account number<span class="required-mark">*</span>
                </label>
                <div class="withdrawal-input-validation-wrap">
                  <input id="withdrawal-input-account_number_ca" type="text" placeholder="Account number"
                         v-model="mangoPayCA.accountNumber"
                         @blur="$v.mangoPayCA.accountNumber.$touch()"
                  >
                  <p class="error-message"
                     v-if="$v.mangoPayCA.accountNumber.$dirty && !$v.mangoPayCA.accountNumber.required">
                    Field is required</p>
                  <p class="error-message" v-else-if="!$v.mangoPayCA.accountNumber.integer">The account number of the
                    bank account. CA account numbers must be digits only.</p>
                  <p class="error-message"
                     v-else-if="!$v.mangoPayCA.accountNumber.minLength || !$v.mangoPayCA.accountNumber.maxLength">The
                    account number of the
                    bank account. Must be numbers only. 7-35 digits.</p>
                </div>
              </div>

              <div class="bank-details-main-ident"></div>

              <div class="bank-details-main-container"
                   v-if="formData.bankAccountType === 'US'"
              >
                <div class="withdrawal-input-wrap">
                  <label for="withdrawal-input-aba" class="withdrawal-input-label">ABA<span
                      class="required-mark">*</span></label>
                  <div class="withdrawal-input-validation-wrap">
                    <input id="withdrawal-input-aba" type="text" placeholder="ABA"
                           v-model="mangoPayUS.aba"
                           @blur="$v.mangoPayUS.aba.$touch()"
                    >
                    <p class="error-message"
                       v-if="$v.mangoPayUS.aba.$dirty && !$v.mangoPayUS.aba.required">
                      Field is required</p>
                    <p class="error-message" ref="mangoPayUSAba"
                       v-else-if="!$v.mangoPayUS.aba.integer">The
                      ABA of the bank account.
                      Must be numbers only, and 9 digits long</p>
                    <p class="error-message" ref="mangoPayUSAba"
                       v-else-if="!$v.mangoPayUS.aba.minLength || !$v.mangoPayUS.aba.maxLength">The
                      ABA of the bank account.
                      Must be numbers only, and 9 digits long</p>
                  </div>
                </div>

                <div class="withdrawal-input-wrap">
                  <label class="withdrawal-input-label">Deposit account type<span class="required-mark">*</span></label>
                  <div class="withdrawal-input-validation-wrap">
                    <div class="withdrawal-select-container">

<!--                      <select name="depositAccountType" id="deposit-account-type"-->
<!--                              v-model="mangoPayUS.depositAccountType"-->
<!--                              @focusin="selectStatusDeposit = true"-->
<!--                              @focusout="selectStatusDeposit = false"-->
<!--                      >-->
<!--                        <option disabled value="">Deposit account type</option>-->
<!--                        <option value="CHECKING">Checking</option>-->
<!--                        <option value="SAVINGS">Savings</option>-->
<!--                      </select>-->
<!--                      <div class="withdrawal-select-item"-->
<!--                           :style="{transform: selectStatusDeposit ? 'rotate(180deg)' : ''}"-->
<!--                      >-->
<!--                        <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                          <path d="M10.3945 0.974609L5.69727 5.67188L1 0.974609" stroke="#272727" stroke-linecap="round"-->
<!--                                stroke-linejoin="round"/>-->
<!--                        </svg>-->
<!--                      </div>-->

                      <GeneralSelect ref="depositAccountTypeSelect"
                                     :options="depositTypes"
                                     :identifier="'deposit-account-type'"
                                     :placeholder="'Deposit account type'"
                                     @changed="setDepositType"
                      />

                    </div>
                    <p class="error-message"
                       v-if="$v.mangoPayUS.depositAccountType.$dirty && !$v.mangoPayUS.depositAccountType.required">
                      Field is required</p>
                  </div>
                  <!--                  <input id="withdrawal-input-account_type" type="text" placeholder="Checking"-->
                  <!--                         v-model="mangoPayUS.depositAccountType"-->
                  <!--                  >-->
                </div>
              </div>

              <div class="bank-details-main-container"
                   v-if="formData.bankAccountType === 'GB'"
              >
                <div class="withdrawal-input-wrap">
                  <label for="withdrawal-input-sort_code" class="withdrawal-input-label">
                    Sort code<span class="required-mark">*</span>
                  </label>
                  <div class="withdrawal-input-validation-wrap">
                    <input id="withdrawal-input-sort_code" type="text" placeholder="Sort code"
                           v-model="mangoPayGB.sortCode"
                           @blur="$v.mangoPayGB.sortCode.$touch()"
                    >
                    <p class="error-message"
                       v-if="$v.mangoPayGB.sortCode.$dirty && !$v.mangoPayGB.sortCode.required">
                      Field is required</p>
                    <p class="error-message" ref="mangoPayGBSortCode"
                       v-else-if="!$v.mangoPayGB.sortCode.minLength || !$v.mangoPayGB.sortCode.maxLength">The
                      sort code of the bank
                      account.
                      Must be numbers only, and 6 digits long</p>
                    <p class="error-message" ref="mangoPayGBSortCode" v-else-if="!$v.mangoPayGB.sortCode.integer">The
                      sort code of the bank
                      account.
                      Must be numbers only, and 6 digits long</p>
                  </div>
                </div>
              </div>

              <div class="bank-details-main-container"
                   v-if="formData.bankAccountType === 'IBAN'"
              >
                <div class="withdrawal-input-wrap">
                  <label for="withdrawal-input-bic" class="withdrawal-input-label">BIC</label>
                  <div class="withdrawal-input-validation-wrap">
                    <input id="withdrawal-input-bic" type="text" placeholder="BIC"
                           v-model="mangoPayIBAN.bic"
                    >
                  </div>
                </div>
              </div>

              <div class="bank-details-main-container"
                   v-if="formData.bankAccountType === 'CA'"
              >
                <div class="withdrawal-input-wrap">
                  <label for="withdrawal-input-branch_code" class="withdrawal-input-label">
                    Branch code<span class="required-mark">*</span>
                  </label>
                  <div class="withdrawal-input-validation-wrap">
                    <input id="withdrawal-input-branch_code" type="text" placeholder="Branch code"
                           v-model="mangoPayCA.branchCode"
                           @blur="$v.mangoPayCA.branchCode.$touch()"
                    >
                    <p class="error-message"
                       v-if="$v.mangoPayCA.branchCode.$dirty && !$v.mangoPayCA.branchCode.required">
                      Field is required</p>
                    <p class="error-message" ref="mangoPayCABranchCode"
                       v-else-if="!$v.mangoPayCA.branchCode.minLength || !$v.mangoPayCA.branchCode.maxLength">The branch
                      code of the
                      bank account. Must be numbers only, and 5 digits long.</p>
                    <p class="error-message" ref="mangoPayCABranchCode"
                       v-else-if="!$v.mangoPayCA.branchCode.integer">The branch code of the
                      bank account. Must be numbers only, and 5 digits long.</p>
                  </div>
                </div>

                <div class="withdrawal-input-wrap">
                  <label for="withdrawal-input-branch_name" class="withdrawal-input-label">
                    Bank name<span class="required-mark">*</span>
                  </label>
                  <div class="withdrawal-input-validation-wrap">
                    <input id="withdrawal-input-branch_name" type="text" placeholder="Branch name"
                           v-model="mangoPayCA.bankName"
                           @blur="$v.mangoPayCA.bankName.$touch()"
                    >
                    <p class="error-message"
                       v-if="$v.mangoPayCA.bankName.$dirty && !$v.mangoPayCA.bankName.required">
                      Field is required</p>
                    <p class="error-message" v-else-if="!$v.mangoPayCA.bankName.maxLength">The name of the bank where
                      the account is held. Must be letters or numbers only and maximum 50 characters long.</p>
                  </div>
                </div>

                <div class="withdrawal-input-wrap">
                  <label for="withdrawal-input-institution_number" class="withdrawal-input-label">
                    Institution number<span class="required-mark">*</span>
                  </label>
                  <div class="withdrawal-input-validation-wrap">
                    <input id="withdrawal-input-institution_number" type="text" placeholder="Institution number"
                           v-model="mangoPayCA.institutionNumber"
                           @blur="$v.mangoPayCA.institutionNumber.$touch()"
                    >
                    <p class="error-message"
                       v-if="$v.mangoPayCA.institutionNumber.$dirty && !$v.mangoPayCA.institutionNumber.required">
                      Field is required</p>
                    <p class="error-message"
                       v-else-if="!$v.mangoPayCA.institutionNumber.integer">
                      The institution number of the
                      bank account. Must be numbers only, and 3 digits long.</p>
                    <p class="error-message"
                       v-else-if="!$v.mangoPayCA.institutionNumber.minLength || !$v.mangoPayCA.institutionNumber.maxLength">
                      The institution number of the
                      bank account. Must be numbers only, and 3 digits long.</p>
                    <!--                    <p class="error-message" v-else-if="!$v.mangoPayCA.institutionNumbe.integer">The institution number of the-->
                    <!--                      bank account. Must be numbers only, and 3 digits long.</p>-->
                  </div>
                </div>
              </div>


              <div class="bank-details-main-container"
                   v-if="formData.bankAccountType === 'OTHER'"
              >
                <div class="withdrawal-input-wrap">
                  <label for="withdrawal-input-bic_other" class="withdrawal-input-label">
                    BIC<span class="required-mark">*</span>
                  </label>
                  <div class="withdrawal-input-validation-wrap">
                    <input id="withdrawal-input-bic_other" type="text" placeholder="BIC"
                           v-model="mangoPayOther.bic"
                           @blur="$v.mangoPayOther.bic.$touch()"
                    >
                    <p class="error-message"
                       v-if="$v.mangoPayOther.bic.$dirty && !$v.mangoPayOther.bic.required">
                      Field is required</p>
                  </div>
                </div>

                <div class="withdrawal-input-wrap">
                  <label class="withdrawal-input-label">
                    Bank Country<span class="required-mark">*</span>
                  </label>
                  <div class="withdrawal-input-validation-wrap">
                    <!--                    <div class="withdrawal-select-container">-->
                    <!--                      <select name="country" v-model="mangoPayOther.country"-->
                    <!--                              @blur="outCountrySelectOtherFull"-->
                    <!--                              @change="outCountrySelectOther"-->
                    <!--                              @focus="onCountrySelectOther"-->
                    <!--                      >-->
                    <!--                        <option disabled value="">Country</option>-->
                    <!--                        <option v-for="item in countriesList" :key="item.iso" :value="item.iso"-->
                    <!--                                :label="item.name"-->
                    <!--                        ></option>-->
                    <!--                      </select>-->
                    <!--                      <div class="withdrawal-select-item"-->
                    <!--                           :style="{transform: selectStatusOther ? 'rotate(180deg)' : ''}"-->
                    <!--                      >-->
                    <!--                        <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                    <!--                          <path d="M10.3945 0.974609L5.69727 5.67188L1 0.974609" stroke="#272727" stroke-linecap="round"-->
                    <!--                                stroke-linejoin="round"/>-->
                    <!--                        </svg>-->
                    <!--                      </div>-->
                    <!--                    </div>-->

                    <div class="withdrawal-select-container">
                      <!--                      <input id="id_withdrawal_country_other" type="text" placeholder="Coutry"-->
                      <!--                             v-model="mangoPayOther.country"-->
                      <!--                             @blur="$v.mangoPayOther.country.$touch()" />-->
                      <CountrySelect class="withdrawal__country-select"
                                     id="id_withdrawal_country_other"
                                     name="withdrawalCountryOther"
                                     :options="searchedCountries"
                                     :search="searchSelect"
                                     :placeholder="'Country'"
                                     :identifier="'withdrawal-other'"
                                     @selectedField="selectedField"
                                     @changed="setCountryOther"
                                     @searchCheck="searchCheckOther"
                                     ref="countrySelectWithdrawalOther"

                      />

<!--                      <div class="withdrawal-select-item"-->
<!--                           :style="{transform: selectStatusOther ? 'rotate(180deg)' : ''}"-->
<!--                      >-->
<!--                        <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                          <path d="M10.3945 0.974609L5.69727 5.67188L1 0.974609" stroke="#272727" stroke-linecap="round"-->
<!--                                stroke-linejoin="round"/>-->
<!--                        </svg>-->
<!--                      </div>-->
                    </div>

                    <p class="error-message"
                       v-if="$v.mangoPayOther.country.$dirty && !$v.mangoPayOther.country.required">
                      Field is required</p>
                  </div>
                  <!--                  <input id="withdrawal-input-country_other" type="text" placeholder="Country" v-model="mangoPayOther.country">-->
                </div>
              </div>

              <div class="bank-details-main-ident"></div>

              <div class="withdrawal-input-wrap">
                <label for="withdrawal-input-tag" class="withdrawal-input-label">Tag</label>
                <div class="withdrawal-input-validation-wrap">
                  <input id="withdrawal-input-tag" type="text" placeholder="Tag">
                </div>
              </div>

              <div class="btn-withdrawal-wrap">
                <button class="btn btn-withdrawal">Create bank account</button>
              </div>

            </form>
          </div>
          <div class="bank-details-wrapper card-selection" v-else>
            <h3 class="bank-details-title">Choose your card</h3>

            <div class="bank-details-card-list" :style="{justifyContent: checkCard ? 'space-between' : 'center'}">
              <WalletCashWithdrawalCard v-for="(item, index) in cardList" :key="index" :item="item"
                                        :selectedCard="selectedCard"
                                        @selectedCardEvent="selectedCardEvent"
                                        @deleteCardEvent="deleteCard"
              />
              <div class="withdrawal-card-add-new" @click="addNewBankAccount">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 2V16" stroke="#FF0090" stroke-width="2.5" stroke-linecap="round"/>
                  <path d="M16 9L2 9" stroke="#FF0090" stroke-width="2.5" stroke-linecap="round"/>
                </svg>
                <span>Add a new bank account</span>
              </div>
            </div>

          </div>
          <div class="pay-button-wrapper" v-if="stepWithdrawal !== 1 && checkCard">
            <button class="btn registr-btn pay-button"
                    :class="{disabled: !selectedCard}"
                    :disabled="!selectedCard"
                    @click="payoutFunc"
            >Withdrawal</button>
          </div>
        </div>
        <div class="preloader-wrapper profile-preloader" v-else>
          <v-progress-circular
              :size="75"
              :width="8"
              color="#FF0090"
              indeterminate
          ></v-progress-circular>
        </div>
      </div>
      <MangopayCallErrorPopup ref="mangopayCallErrorPopup"/>
      <ServerErrorPopup ref="ServerErrorPopup" :errorMessage="error.message"/>
      <SuccessPopup ref="SuccessPopup" :errorMessage="error.successMessage"/>
    </main>
    <Footer/>
  </div>
</template>

<script>

import Footer from "../footer/Footer";
import Header from "../header/Header";
import Sidebar from "./Sidebar";
import ServerErrorPopup from "../popups/ServerErrorPopup";
import SuccessPopup from "../popups/SuccessPopup";
import MangopayCallErrorPopup from "../popups/MangopayCallErrorPopup";
import WalletCashWithdrawalCard from "./WalletCashWithdrawalCard";
import CountrySelect from "../inputs/CountrySelect";
import {required, integer, minLength, maxLength} from "vuelidate/lib/validators";
// import { Money } from "v-money"
import GeneralSelect from "@/components/inputs/GeneralSelect";
import scrollToElement from "@/mixins/scrollToElement";

export default {

  name: "WalletCashWithdrawal",
  data() {
    return {
      loadFlag: false,
      amount: null,
      amountIsValid: false,
      stepWithdrawal: 0,
      payoutObj: null,
      selectedCard: null,
      cardList: null,
      // selectStatus: false,
      // selectStatusOther: false,
      // selectStatusDeposit: false,
      depositTypes: [{name: 'Checking', value: 'CHECKING'}, {name: 'Savings', value: 'SAVINGS'}],
      defaultCountry: 'Country',
      countriesList: [],
      searchSelect: '',
      // countriesListValue: '',
      // money: {
      //   decimal: ',',
      //   thousands: '.',
      //   precision: 0,
      //   masked: false
      // },
      numParams: {
        modifyValueOnWheel: false, // turn off wheel mode
        digitGroupSeparator: '.',
        decimalCharacter: ',',
        decimalPlaces: 0,
        currencySymbolPlacement: 'p', // prefix || suffix
        currencySymbol: '€\u00a0',
        overrideMinMaxLimits: null,
        minimumValue: '0',
        maximumValue: '10000000000',
      },
      formData: {
        bankName: null,
        bankAccountType: 'US',
        mangoPayAddress: {
          addressLine1: null,
          addressLine2: null, //not required
          city: null,
          region: null, //not required
          postalCode: null,
          country: ''
        },
      },
      mangoPayIBAN: {
        iban: null,
        bic: null, //not required
      },
      mangoPayUS: {
        accountNumber: null,
        aba: null,
        depositAccountType: null,  // CHECKING, SAVINGS
      },
      mangoPayCA: {
        accountNumber: null,
        bankName: null,
        branchCode: null,
        institutionNumber: null
      },
      mangoPayGB: {
        sortCode: null,
        accountNumber: null
      },
      mangoPayOther: {
        accountNumber: null,
        bic: null,
        country: ''
      },
      error: {
        message: '',
        successMessage: 'Payment successful'
      },
    }
  },
  validations() {
    if (this.formData.bankAccountType === 'US') {
      return {
        formData: {
          bankName: {
            maxLength: maxLength(35)
          },
          mangoPayAddress: {
            addressLine1: {
              required,
            },
            country: {
              required,
            },
            city: {
              required
            },
            postalCode: {
              required
            },
          },
        },
        mangoPayUS: {
          accountNumber: {
            required,
            integer,
            minLength: minLength(4),
          },
          aba: {
            required,
            integer,
            minLength: minLength(9),
            maxLength: maxLength(9)
          },
          depositAccountType: {
            required
          }
        },
      }
    } else if (this.formData.bankAccountType === 'GB') {
      return {
        formData: {
          bankName: {
            maxLength: maxLength(35)
          },
          mangoPayAddress: {
            addressLine1: {
              required,
            },
            country: {
              required,
            },
            city: {
              required
            },
            postalCode: {
              required
            },
          },
        },
        mangoPayGB: {
          accountNumber: {
            required,
            integer,
            minLength: minLength(8),
            maxLength: maxLength(8)
          },
          sortCode: {
            required,
            integer,
            minLength: minLength(6),
            maxLength: maxLength(6)
          }
        },
      }
    } else if (this.formData.bankAccountType === 'OTHER') {
      return {
        formData: {
          bankName: {
            maxLength: maxLength(35)
          },
          mangoPayAddress: {
            addressLine1: {
              required,
            },
            country: {
              required,
            },
            city: {
              required
            },
            postalCode: {
              required
            },
          },
        },
        mangoPayOther: {
          accountNumber: {
            required,
            integer,
            minLength: minLength(7),
            maxLength: maxLength(35)
          },
          country: {
            required
          },
          bic: {
            required
          }
        },
      }
    } else if (this.formData.bankAccountType === 'IBAN') {
      return {
        formData: {
          bankName: {
            maxLength: maxLength(35)
          },
          mangoPayAddress: {
            addressLine1: {
              required,
            },
            country: {
              required,
            },
            city: {
              required
            },
            postalCode: {
              required
            },
          },
        },
        mangoPayIBAN: {
          iban: {
            required,
          }
        },
      }
    } else {
      return {
        formData: {
          bankName: {
            maxLength: maxLength(35)
          },
          mangoPayAddress: {
            addressLine1: {
              required,
            },
            country: {
              required,
            },
            city: {
              required
            },
            postalCode: {
              required
            },
          },
        },
        mangoPayCA: {
          accountNumber: {
            required,
            integer,
            minLength: minLength(7),
            maxLength: maxLength(35)
          },
          branchCode: {
            required,
            integer,
            minLength: minLength(5),
            maxLength: maxLength(5)
          },
          bankName: {
            required,
            maxLength: maxLength(50)
          },
          institutionNumber: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(3),
            integer
          }
        },
      }
    }
  },
  mixins: [scrollToElement],
  methods: {
    selectedField(data) {
      if (this.formData.bankAccountType === 'OTHER') {
        if (data === 'withdrawal') {
          this.$refs.countrySelectWithdrawalOther.closeList()
        } else {
          this.$refs.countrySelectWithdrawal.closeList()
        }
      }
    },
    countryClickOutside(event) {
      if (this.stepWithdrawal === 1) {
        if (this.formData.bankAccountType === 'OTHER') {
          if (!event.target.closest('.general_select')) {
            this.$refs.countrySelectWithdrawal.closeList()
            this.$refs.countrySelectWithdrawalOther.closeList()
          }
        } else if (!event.target.closest('.general_select')) {
          this.$refs.countrySelectWithdrawal.closeList()
        }
      }
    },
    // countryOtherClickOutside(event) {
    //   if (this.formData.bankAccountType === 'OTHER') {
    //     if(!event.target.closest('.custom-select')) {
    //       this.$refs.countrySelectWithdrawalOther.closeList()
    //     }
    //   }
    // },
    searchCheck(data) {
      this.searchSelect = data
    },
    searchCheckOther(data) {
      this.searchSelect = data
    },

    // openStatus(data) {
    //   this.selectStatus = data
    //   this.selectStatusOther = false
    // },
    // openStatusOther(data) {
    //   this.selectStatusOther = data
    //   this.selectStatus = false
    // },

    clearFields() {
      this.resetData()
      this.$v.$reset()
    },
    addNewBankAccount() {
      this.stepWithdrawal = 1
    },
    resetData() {
      this.formData.bankName = null
      this.formData.mangoPayAddress.addressLine1 = null
      this.formData.mangoPayAddress.addressLine2 = null
      this.formData.mangoPayAddress.city = null
      this.formData.mangoPayAddress.region = null
      this.formData.mangoPayAddress.postalCode = null
      this.mangoPayOther.bic = null
      this.mangoPayOther.accountNumber = null
      this.mangoPayCA.accountNumber = null
      this.mangoPayCA.bankName = null
      this.mangoPayCA.branchCode = null
      this.mangoPayCA.institutionNumber = null
      this.mangoPayGB.accountNumber = null
      this.mangoPayGB.sortCode = ''
      this.mangoPayIBAN.bic = null
      this.mangoPayIBAN.iban = null
      this.mangoPayUS.aba = null
      this.mangoPayUS.accountNumber = null
      // this.formData.mangoPayAddress.country = ''
      // this.mangoPayOther.country = ''
      // this.mangoPayUS.depositAccountType = ''
      if (this.$refs.countrySelectWithdrawal) {
        this.$refs.countrySelectWithdrawal.clearCountry()
      }
      if (this.$refs.depositAccountTypeSelect && this.formData.bankAccountType === 'US') {
        this.$refs.depositAccountTypeSelect.resetSelect()
      }
      if (this.$refs.countrySelectWithdrawalOther && this.formData.bankAccountType === 'OTHER') {
        this.$refs.countrySelectWithdrawalOther.clearCountry()
      }
    },
    selectedCardEvent(id) {
      this.selectedCard = id
    },
    payoutFunc() {
      if (this.amountIsValid) {
        this.scrollToId('my-content')
      } else {
        let dataObj = {
          userBankDetailId: this.selectedCard,
          amount: +this.amount
        }
        this.$load(async () => {
          await this.$api.bankAccount.payout(dataObj)
          await this.$refs.SuccessPopup.openPopups()
          this.amount = 0
          await this.resetData()
          this.stepWithdrawal = 0
        }, error => {
          if (error.data.errors[0].id === "MANGOPAY_INITIAL_EXCEPTION" &&
              error.data.errors[0].message === 'Rate limit exceeded. Please contact support for more assistance') {
            this.$refs.mangopayCallErrorPopup.openPopup()
          }
          this.error.message = error.data.errors[0].message
          this.$refs.ServerErrorPopup.openPopups()
        })
      }
    },
    dataRequest() {
      this.$load(async () => {
        this.loadFlag = false
        let countries = await this.$api.countriesLists.getBankCountries()
        this.countriesList = countries.data.body.elements
        let res = await this.$api.bankAccount.getBankDetails()
        this.cardList = res.data.body.elements
        this.payoutObj = res.data.body.elements[0]
        this.loadFlag = true
      }, error => {
        this.error.message = error.data.errors[0].message
        this.$refs.ServerErrorPopup.openPopups()
      })
    },
    deleteCard(itemId) {
      this.$load(async () => {
        this.loadFlag = false
        await this.$api.bankAccount.deleteBankAccount(itemId)
        this.loadFlag = true
        this.dataRequest()
      }, error => {
        this.error.message = error.data.errors[0].message
        this.$refs.ServerErrorPopup.openPopups()
      })
    },
    createAccount() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      } else {
        let dataObj
        if (this.formData.bankAccountType === 'US') {
          let obj = {}
          obj.mangoPayUS = this.mangoPayUS
          dataObj = Object.assign(this.formData, obj)
        }
        if (this.formData.bankAccountType === 'GB') {
          let obj = {}
          obj.mangoPayGB = this.mangoPayGB
          dataObj = Object.assign(this.formData, obj)
        }
        if (this.formData.bankAccountType === 'IBAN') {
          let obj = {}
          obj.mangoPayIBAN = this.mangoPayIBAN
          dataObj = Object.assign(this.formData, obj)
        }
        if (this.formData.bankAccountType === 'CA') {
          let obj = {}
          obj.mangoPayCA = this.mangoPayCA
          dataObj = Object.assign(this.formData, obj)
        }
        if (this.formData.bankAccountType === 'OTHER') {
          let obj = {}
          obj.mangoPayOther = this.mangoPayOther
          dataObj = Object.assign(this.formData, obj)
        }
        this.$load(async () => {
          await this.$api.bankAccount.createBankAccount(dataObj)
          this.formData = {
            bankName: null,
            bankAccountType: 'US',
            mangoPayAddress: {
              addressLine1: null,
              addressLine2: null, //not required
              city: null,
              region: null, //not required
              postalCode: null,
              country: ''
            },
          }
          this.clearFields()
          this.stepWithdrawal = 0
          setTimeout(() => {
            this.dataRequest()
          }, 0)
        }, error => {
          this.error.message = error.data.errors[0].message
          this.$refs.ServerErrorPopup.openPopups()
        })
      }
    },
    setCountry(data) {
      if (data) {
        this.formData.mangoPayAddress.country = data.code
      } else {
        this.formData.mangoPayAddress.country = ''
      }
    },
    setCountryOther(data) {
      if (data) {
        this.mangoPayOther.country = data.code
      } else {
        this.mangoPayOther.country = ''
      }
    },
    changeFunc(event) {
      this.amount = event.target.value
    },
    setDepositType(data) {
      this.mangoPayUS.depositAccountType = data
    },
  },
  watch: {
    amount(newVal, oldVal) {
      newVal < 0 ? this.amountIsValid = true : this.amountIsValid = false
      // if (typeof newVal === 'string') {
      //   if (newVal.includes('.')) {
      //     let newValSplit = newVal.split('.')[1]
      //     if (newValSplit.length >= 3) {
      //       this.amount = oldVal
      //     }
      //   } else if (newVal === '') {
      //     newVal = oldVal
      //   }
      // }
    },
    'mangoPayUS.aba'(newVal) {
      if (newVal === '') {
        this.mangoPayUS.aba = null
      }
    },
    'mangoPayCA.branchCode'(newVal) {
      if (newVal === '') {
        this.mangoPayCA.branchCode = null
      }
    },
    'mangoPayCA.institutionNumber'(newVal) {
      if (newVal === '') {
        this.mangoPayCA.institutionNumber = null
      }
    },
    'mangoPayGB.sortCode'(newVal) {
      if (newVal === '') {
        this.mangoPayGB.sortCode = null
      }
    },
  },
  computed: {
    checkCard() {
      return this.cardList.length > 0
    },
    searchedCountries() {
      return this.countriesList.filter(option => option.name.toLowerCase().includes(this.searchSelect.toLowerCase()))
    },
  },
  mounted() {
    this.dataRequest()
    document.title = 'Cash Withdrawal'
    document.addEventListener('click', this.countryClickOutside)
    // document.addEventListener('click', this.countryOtherClickOutside)
    this.scrollToId('my-content')
  },
  beforeDestroy() {
    document.removeEventListener('click', this.countryClickOutside)
  },
  components: {
    Footer,
    Header,
    Sidebar,
    ServerErrorPopup,
    SuccessPopup,
    WalletCashWithdrawalCard,
    CountrySelect,
    GeneralSelect,
    MangopayCallErrorPopup,
    // Money,
  },


}
</script>

<style scoped>

label {
  margin: 0;
}

.error-message {
  color: red;
}

.trasnfer-amount-wrapper {
  max-width: 580px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #747474;
}

.trasnfer-amount-input-block {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  padding: 30px;
  background: #F9F9F9;
  border: 1px solid #EAEAEA;
  border-radius: 15px;
  margin-top: 20px;
}

.trasnfer-amount-header {
  margin-bottom: 10px;
  text-align: start;
  width: 100%;
}

.trasnfer-amount-input-wrap {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

/*.trasnfer-amount-input-wrap.amount-pay-field {*/
/*  flex-wrap: wrap;*/
/*}*/

/*.trasnfer-amount-input-wrap::before {*/
/*  content: '€';*/
/*  position: absolute;*/
/*  left: 30px;*/
/*  top: 50%;*/
/*  transform: translateY(-50%);*/
/*  font-style: normal;*/
/*  font-weight: normal;*/
/*  font-size: 20px;*/
/*  line-height: 23px;*/
/*  color: #131415;*/
/*}*/

.transfer-amount-input {
  width: calc(100% - 95px);
  padding: 13px 50px 13px 20px;
  background: #FFFFFF;
  border: 1px solid #C6C6C6;
  border-radius: 5px;
  margin-right: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #131415;
}

.border-line {
  width: 100%;
  height: 1px;
  background: #DEDEDE;
  margin-bottom: 40px;
}


/*.trasnfer-amount-input-button {*/
/*  margin-top: 50px;*/
/*}*/

/*.bank-details-header {*/
/*  !*width: 100%;*!*/
/*  !*display: flex;*!*/
/*  !*justify-content: space-between;*!*/
/*  !*font-style: normal;*!*/
/*  !*font-weight: normal;*!*/
/*  !*font-size: 16px;*!*/
/*  !*line-height: 19px;*!*/
/*  !*letter-spacing: 4.8px;*!*/
/*  !*text-transform: uppercase;*!*/
/*  !*color: #5A5A5A;*!*/
/*  !*margin-top: 40px;*!*/
/*  !*padding-bottom: 8px;*!*/
/*  !*border-bottom: 1px solid #EAEAEA;*!*/
/*}*/

.bank-details-wrapper {
  margin-top: 67px;
}

.bank-details-wrapper.card-selection {
  padding-top: 30px;
  border-top: 1px solid #EAEAEA;
}

.bank-details-title {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #5A5A5A;
  margin-bottom: 24px;
}

.bank-details-card-list {
  display: flex;
  flex-wrap: wrap;
}

.bank-details-header {
  margin-bottom: 30px;
  padding: 30px 0;
  border-top: 1px solid #EAEAEA;
}

.bank-details-head-title {
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #5A5A5A;
}

.bank-details-radio-wrap {
  margin-top: 29px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bank-details-radio-item {
  display: flex;
  align-items: center;
}

.bank-details-radio-item label {
  cursor: pointer;
}

.bank-details-radio-item-border {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 29px;
  height: 29px;
  margin-right: 15px;
  border-radius: 50%;
  background: #FFFFFF;
  border: 0.5px solid #707070;
}

.bank-details-radio-item-border input {
  width: 0;
  height: 0;
}

.bank-details-radio-item-fill {
  width: 17px;
  height: 17px;
  background: #FF0090;
  border-radius: 50%;
}

.bank-details-radio-item-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #131415;
}

.withdrawal-input-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.withdrawal-input-wrap:last-of-type {
  margin-bottom: 0;
}

.withdrawal-input-label {
  max-width: 160px;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #5A5A5A;
}

.required-mark {
  color: #FF0090;
}

.withdrawal-input-wrap input, .withdrawal-input-wrap select {
  width: 100%;
  padding: 13px 30px;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #131415;
  border: 1px solid #C6C6C6;
  border-radius: 5px;
}

.bank-details-main-ident {
  margin-bottom: 30px;
}

.btn-withdrawal-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 100px;
}

.btn-withdrawal {
  margin: 0 9px;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;
}

.withdrawal-card-add-new {
  width: calc(50% - 10px);
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 13px;
  color: #FF0090;
  background: #FFF7FC;
  border: 1px solid #FFADDC;
  border-radius: 18px;
  margin-bottom: 20px;
  cursor: pointer;
}

.withdrawal-card-add-new svg {
  margin-right: 15px;
}

.pay-button-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.pay-button {
  margin: 0 auto;
}

.withdrawal-input-validation-wrap {
  width: calc(100% - 210px);
}

.withdrawal-select-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.withdrawal-select-container select {
  /*outline: none;*/
}

.withdrawal-input-wrap .withdrawal-select-container {
  z-index: 0;
}

.withdrawal-input__country-wrap .withdrawal-select-container {
  z-index: 1;
}

/*.withdrawal-select-container select {*/
/*  outline: none;*/
/*}*/


/*.withdrawal-select-container .withdrawal-select {*/
/*  !*border: 1px solid #C6C6C6;*!*/
/*  !*font-style: normal;*!*/
/*  !*font-weight: normal;*!*/
/*  !*font-size: 20px;*!*/
/*  !*line-height: 24px;*!*/
/*  !*color: #868686;*!*/
/*}*/

.withdrawal-select-item {
  z-index: 5;
  position: absolute;
  top: calc(50% - 11px);
  /*bottom: 50%;*/
  right: 23px;
  transition: .5s ease;
}

.withdrawal-select-container .general_select {
  height: 52px;
  line-height: 52px;
}


/*.withdrawal-select-item svg {*/

/*}*/

.error-message.absolute {
  position: absolute;
  bottom: -10px;
  left: 30px;
}

@media only screen and (max-width: 992px) {
  .withdrawal-card-add-new {
    font-size: 18px;
  }

  .withdrawal-card-add-new svg {
    width: 16px;
    height: auto;
    margin-right: 12px;
  }
}

@media only screen and (max-width: 768px) {

  .trasnfer-amount-input-wrap svg {
    width: 50px;
  }

  .bank-details-wrapper {
    margin-top: 50px;
  }

  .bank-details-head-title {
    font-size: 18px;
    line-height: 20px;
  }

  .bank-details-radio-item-title {
    font-size: 16px;
    line-height: 22px;
  }

  .bank-details-radio-item-border {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  .bank-details-radio-item-fill {
    width: 15px;
    height: 15px;
  }

  .withdrawal-input-label {
    max-width: 130px;
    font-size: 18px;
    line-height: 21px;
  }

  /*.withdrawal-input-wrap input {*/
  /*  width: calc(100% - 160px);*/
  /*  padding: 11px 26px;*/
  /*  font-size: 18px;*/
  /*  line-height: 21px;*/
  /*}*/

}


@media only screen and (max-width: 700px) {
  .withdrawal-card-add-new {
    width: 100%;
  }
}

@media only screen and (max-width: 550px) {

  .trasnfer-amount-input-block {
    padding: 25px;
    margin-top: 15px;
  }

  .trasnfer-amount-header {
    margin-bottom: 10px;
  }

  .trasnfer-amount-input-wrap svg {
    width: 45px;
  }

  /*.trasnfer-amount-input-wrap::before {*/
  /*  left: 20px;*/
  /*  top: 50%;*/
  /*  font-size: 18px;*/
  /*  line-height: 21px;*/
  /*}*/

  .transfer-amount-input {
    width: calc(100% - 85px);
    padding: 11px 35px 10px 20px;
    margin-right: 15px;
    font-size: 18px;
    line-height: 21px;
  }

  .border-line {
    margin-bottom: 30px;
  }

  .bank-details-wrapper {
    margin-top: 40px;
  }

  .bank-details-header {
    margin-bottom: 10px;
    padding: 20px 0;
  }

  .bank-details-head-title {
    font-size: 16px;
    line-height: 18px;
  }

  .bank-details-radio-wrap {
    margin-top: 15px;
  }

  .bank-details-radio-item-title {
    font-size: 15px;
    line-height: 20px;
  }

  .bank-details-radio-item-border {
    width: 21px;
    height: 21px;
    margin-right: 10px;
  }

  .bank-details-radio-item-fill {
    width: 13px;
    height: 13px;
  }

  .withdrawal-input-label {
    max-width: 130px;
    font-size: 15px;
    line-height: 18px;
  }

  .withdrawal-input-wrap {
    margin-bottom: 10px;
    flex-direction: column;
    align-items: flex-start;
  }

  .bank-details-main-ident {
    margin-bottom: 15px;
  }

  .withdrawal-input-validation-wrap {
    width: 100%;
  }

  .withdrawal-input-label {
    max-width: 100%;
  }
}

@media only screen and (max-width: 460px) {

  .withdrawal-card-add-new {
    font-size: 16px;
  }

  .withdrawal-card-add-new svg {
    width: 15px;
  }

  .trasnfer-amount-wrapper {
    text-align: left;
    font-size: 15px;
    line-height: 18px;
  }

  /*.trasnfer-amount-input-wrap::before {*/
  /*  left: 10px;*/
  /*  font-size: 16px;*/
  /*  line-height: 19px;*/
  /*}*/

  .transfer-amount-input {
    width: calc(100% - 65px);
    padding: 9px 25px 8px 15px;
    margin-right: 10px;
    font-size: 16px;
    line-height: 19px;
  }

  .trasnfer-amount-input-wrap svg {
    width: 35px;
  }

  .trasnfer-amount-input-block {
    width: 100%;
    padding: 15px;
    margin-top: 15px;
  }

  .border-line {
    margin-bottom: 20px;
  }

  .bank-details-wrapper {
    margin-top: 25px;
  }

  .bank-details-header {
    margin-bottom: 0;
    padding: 15px 0;
  }

  .bank-details-radio-wrap {
    margin-top: 15px;
    flex-wrap: wrap;
  }

  .bank-details-radio-item-title {
    font-size: 14px;
    line-height: 16px;
  }

  .bank-details-radio-item-border {
    width: 17px;
    height: 17px;
    margin-right: 5px;
  }

  .bank-details-radio-item-fill {
    width: 11px;
    height: 11px;
  }

  .btn-withdrawal {
    font-size: 14px;
    line-height: 18px;
  }

  .btn-withdrawal-wrap {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .withdrawal-input-wrap {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 375px) {

  .transfer-amount-input {
    width: calc(100% - 35px);
    font-size: 15px;
    line-height: 17px;
  }

  .trasnfer-amount-input-block {
    padding: 10px;
  }

  .trasnfer-amount-header {
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 16px;
  }


  .trasnfer-amount-input-wrap svg {
    width: 30px;
  }


  .bank-details-head-title {
    font-size: 13px;
    line-height: 16px;
  }

  .bank-details-radio-wrap {
    margin-top: 10px;
    flex-direction: column;
    align-items: flex-start;
  }

  .bank-details-radio-item {
    margin-bottom: 8px;
  }

  .bank-details-radio-item:last-of-type {
    margin-bottom: 0;
  }


  .withdrawal-input-wrap label {
    max-width: 100%;
    margin-bottom: 4px;
  }

  .withdrawal-input-wrap input, .withdrawal-input-wrap select {
    width: 100%;
  }

  .withdrawal-card-add-new {
    height: 70px;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .withdrawal-card-add-new svg {
    width: 13px;
  }


}


</style>