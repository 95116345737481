<template>
  <div id="my-content">
    <Header/>
    <main id="root">
      <section class="section" v-if="loadFlag">
        <div class="container-small">
          <h1 class="page-title separator" ref="top">News and Features</h1>
          <div class="artist-header">
            <div id="cover" class="search-block">
              <form method="POST" action="" onsubmit="return false">
                <div class="search-input">
                  <input v-model.trim="keyword"
                         @change="page = 1; handlePageChange();"
                         type="text"
                         name="searchedText"
                         id="id_search"
                         placeholder="Search..."
                         class="search-news">
                </div>
              </form>
            </div>
          </div>
          <h2 class="news-list-subtitle">latest news</h2>
          <div class="artist-container">
            <div class="artist-list-wrap">
              <news-list-template :news="news"/>
              <b-pagination-nav v-if="news.length"
                                class="pagination"
                                v-model="page"
                                :total-rows="count"
                                :per-page="pageSize"
                                :link-gen="linkGen"
                                :number-of-pages="count"
                                prev-text="«"
                                next-text="»"
                                first-number
                                last-number
                                use-router
              ></b-pagination-nav>
            </div>
            <div class="preloaderss">
              <div class="main-circle">
                <div class="green-circle">
                  <div class="brown-circle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
      <div class="preloader-wrapper" v-else>
        <v-progress-circular
            :size="75"
            :width="8"
            color="#FF0090"
            indeterminate
        ></v-progress-circular>
      </div>
    </main>
    <Footer/>
  </div>
</template>

<script>
import NewsListTemplate from "../components/news/NewsListTemplate";
import {debounce} from "lodash";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import scrollToElement from "@/mixins/scrollToElement";

export default {
  name: "NewsList",
  data: () => ({
    keyword: "",
    news: [],
    currentNews: null,
    currentIndex: -1,
    searchTitle: "",
    page: 1,
    count: 0,
    pageSize: 3,
    loadFlag: false,
  }),
  mixins: [scrollToElement],
  methods: {
    linkGen(pageNum) {
      // return pageNum === 1 ? '?' : `?page=${pageNum}`
      return {
        path: '/news',
        query: {page: pageNum}
      }
    },
    getRequestParams(searchTitle, page, pageSize) {
      let params = {};

      params["searchedText"] = searchTitle;

      if (page) {
        params["page"] = page - 1;
      }

      if (pageSize) {
        params["size"] = pageSize;
      }

      return params;
    },

    checkName() {
      const params = this.getRequestParams(
          this.keyword,
          this.page,
          this.pageSize
      );

      this.$load(async () => {
        this.loadFlag = false
        const res = (await this.$api.news.getAll(params)).data.body
        this.news = res.elements
        this.count = res.totalPages
        this.loadFlag = true
      })
    },

    handlePageChange(value) {
      this.page = value;
      this.debounceName();
    },
    handlePageSizeChange() {
      this.page = 1;
      // router.push({ path: `/news`, query: { page: this.page }})
      this.debounceName();
    },
    // getSaveStateConfig() {
    //   return {
    //     'cacheKey': 'NewsListTemplate',
    //   };
    // },
  },
  watch: {
    // keyword() {
    //   this.handlePageSizeChange();
    // },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if(newVal) {
          this.page = newVal
          this.checkName()
          this.scrollToId('root')
        } else {
          this.page = 1
        }
      }
    }
  },
  mounted() {
    document.title = 'News'
    this.page = this.$route.query.page || 1
    this.checkName()
    this.debounceName = debounce(this.checkName, 1000);

    setTimeout(() => {
      this.scrollToId('my-content')
    }, 500)
  },
  components: {
    Footer,
    Header,
    NewsListTemplate,
  }
}
</script>

<style>
.page-item.active .page-link {
  background-color: #ff0090;
  border-color: #ff0090;
  color: #fff;
}

</style>