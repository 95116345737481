export default function(instance) {
    return {
        getToken() {
            return instance.get('/api/v2/kyc/token')
        },
        getAuthStatus() {
            return instance.get('/api/v2/kyc/status')
        },
        uploadMangopayDocs(body) {
            return instance.post('/api/v2/kyc/mangopay/upload', body)
        },
        getFirstRequest() {
            return instance.get('/api/v2/mangopay/legal')
        },
        uploadFirstStep(body) {
            return instance.post('/api/v2/mangopay/legal', body)
        },
        uploadSecondStep(body) {
            return instance.post('/api/v2/kyc/mangopay', body)
        },
        getThirdRequest() {
            return instance.get('/api/v2/mangopay/ubo')
        },
        uploadThirdStep(body) {
            return instance.post('/api/v2/mangopay/ubo', body)
        },
        getStatusOfSteps() {
            return instance.get('/api/v2/mangopay/legal/status')
        },
        // getNationalities() {
        //     return instance.get('/api/v2/mangopay/nationalities')
        // },
        getKYCAidFormUrl(userUuid) {
            return instance.get(`/api/v2/kyc/${userUuid}/form-url`)
        },
    }
}