<template>
    <div class="faq-list">
        <TeamCategoryList
            v-for="(teamCategory, index) in teamMembers"
            :key="index"
            :teamCategory="teamCategory"
        />
    </div>
</template>

<script>
    import TeamCategoryList from "./TeamCategoryList";
    export default {
        name: "TeamTemplate",
        components: {TeamCategoryList},
        props: {
            teamMembers: {
                type: Array,
                default: () => []
            }
        }
    }
</script>

<style scoped>

</style>