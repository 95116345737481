<template>
  <div id="my-content" ref="top">
    <Header/>
    <main id="root">
      <div class="profile">
        <Sidebar/>

        <div class="profile-content" v-if="loadFlag">
          <InitialSaleHistoryList v-for="(operation, index) in operations" :key="index" :operation="operation"/>
          <b-pagination-nav v-if="operations.length"
                            class="pagination"
                            v-model="page"
                            :total-rows="count"
                            :per-page="pageSize"
                            :link-gen="linkGen"
                            :number-of-pages="count"
                            prev-text="«"
                            next-text="»"
                            first-number
                            last-number
                            use-router
          ></b-pagination-nav>
        </div>

        <div class="preloader-wrapper profile-preloader" v-else>
          <v-progress-circular
              :size="75"
              :width="8"
              color="#FF0090"
              indeterminate
          ></v-progress-circular>
        </div>

      </div>
    </main>
    <Footer/>
  </div>
</template>

<script>
import InitialSaleHistoryList from "./InitialSaleHistoryList";
import Sidebar from "./Sidebar";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import {debounce} from "lodash";
import scrollToElement from "@/mixins/scrollToElement";


export default {
  name: "InitialSaleHistory",
  data() {
    return {
      loadFlag: false,
      operations: [],
      page: 1,
      count: null,
      pageSize: 3,
      flag: false,
      scrollSettings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false,
      }
    }
  },
  mixins: [scrollToElement],
  watch: {
    'operations'() {
      if (this.operations.length > 0) {
        this.flag = true
      } else {
        this.flag = false
      }
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.page = newVal
          this.checkOperations()
          this.scrollToId('my-content')
        } else {
          this.page = 1
          this.debounceName()
        }
      }
    }
  },
  methods: {
    linkGen(pageNum) {
      // return pageNum === 1 ? '?' : `?page=${pageNum}`
      return {
        path: '/profile/initial-sale-history',
        query: {page: pageNum}
      }
    },
    getRequestParams(page, pageSize) {
      let params = {};


      if (page) {
        params["page"] = page - 1;
      }

      if (pageSize) {
        params["size"] = pageSize;
      }

      return params;
    },
    checkOperations() {
      const params = this.getRequestParams(
          this.page,
          this.pageSize
      );

      this.$load(async () => {
        this.loadFlag = false
        const res = (await this.$api.financialOperations.getHistory(params))
        this.count = res.data.body.totalPages
        this.operations = res.data.body.elements
        this.loadFlag = true
      })
    },
  },
  mounted() {
    document.title = 'IS'
    this.page = this.$route.query.page || 1
    this.checkOperations()
    this.debounceName = debounce(this.checkOperations, 0);
    setTimeout(() => {
      this.scrollToId('my-content')
    }, 500)
  },
  components: {
    Footer,
    Header,
    Sidebar,
    InitialSaleHistoryList,
  }
}
</script>

<style scoped>

</style>