<template>
  <div class="general_checkbox-form" :id="`general_checkbox-${checkBoxId}`">
    <label class="general_checkbox-item">
      <input
          class="general_checkbox-input"
          type="checkbox"
          @change="onChange"
          v-model="checkBoxValue"
          :disabled="disabledCheckBox"
      >
      <span class="general_checkbox-checkmark"/>
    </label>
  </div>
</template>

<script>
export default {
  name:'GeneralCustomCheckBox',
  props: {
    checkBoxId: {
      type: String,
      required: true,
    },
    currentStatus: {
      type: Boolean,
      required: false,
      default: null,
    },
    disabledCheckBox: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      checkBoxValue: false,
    }
  },
  methods: {
    onChange() {
      this.$emit('checked', this.checkBoxValue);
    },
    checkInFalse() {
      this.checkBoxValue = false
    },
  },
  watch: {
    checkBoxValue() {
      this.onChange()
    },
  },
  mounted() {
    if (this.currentStatus !== null) {
      this.checkBoxValue = this.currentStatus
      // this.onChange()
    }
  },
};
</script>

<style scoped>

.general_checkbox-form {
  display: block;
  box-sizing: border-box;
  background: transparent;
  width: 29px;
  height: 29px;
  border: 0.5px solid #707070;
  border-radius: 2px;
}

.general_checkbox-item {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: transparent;
}

.general_checkbox-input {
  display: none;
  width: 0;
  height: 0;
}

.general_checkbox-checkmark {
  display: block;
  width: 17px;
  height: 17px;
  background: transparent;
  border-radius: 2px;
  transition: .5s ease;
  cursor: pointer;
}

.general_checkbox-input:checked ~ .general_checkbox-checkmark {
  background: #FF0090;
}

/* disabled styles */

.general_checkbox-input[disabled] ~ .general_checkbox-checkmark {
  cursor: default;
}

.general_checkbox-input[disabled] ~ .general_checkbox-checkmark {
  background: #B2B2B2;
}

/* end disabled styles */

@media only screen and (min-width: 1200px) {
  .general_checkbox-form:hover .general_checkbox-input ~ .general_checkbox-checkmark {
    background: #FF0090;
    opacity: 0.5;
  }
  /* disabled styles */
  .general_checkbox-form:hover .general_checkbox-input[disabled]  ~ .general_checkbox-checkmark {
    background: #B2B2B2;
    opacity: 1;
  }
  /* end disabled styles */
}

</style>