<template>
  <div class="contracts">
    <div class="contracts-wrapper">
      <ContractIeoList
          v-for="(item, index) in ieosArtist"
          :key="index"
          :item="item"
      />
    </div>
    <div class="no-data-wrapper" style="height: unset" v-if="!ieosArtist.length">
      <p>You dont have any agreements yet</p>
      <router-link :to="{path: '/profile/my-songs'}" class="link">Upload your first song!</router-link>
    </div>
  </div>
</template>


<script>
import ContractIeoList from "./ContractIeoList";


export default {
  name: "Contract",
  props: {
    ieosArtist: Array
  },
  data() {
    return {
    }
  },
  methods: {

  },
  mounted() {

  },
  components: {ContractIeoList}
}
</script>

<style scoped>

.contracts {
  width: 100%;
}

/*.no-data-wrapper {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 100%;*/
/*  height: 100vh;*/
/*  padding: 40px;*/
/*  font-size: 30px;*/
/*  font-weight: 600;*/
/*  color: #000000;*/
/*}*/

</style>