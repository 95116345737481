<template>
  <div class="home-news-item">
    <router-link :to="{path: `/article/${newsitem.id}`}" class="main-news-item__link">
      <div class="main-news-item__image-wrap">
        <img v-if="newsitem.logo != ''" :src="newsitem.logo" alt=""
             class="main-news-item__image"
             onerror="this.onerror=null;this.src='/img/profile-default-image.png';" loading="lazy">
        <img v-else :src="'@/assets/img/profile-default-image.png'"
             alt="" class="main-news-item__image" loading="lazy">
      </div>
    </router-link>
    <div class="main-news-item__content">
      <router-link :to="{path: `/article/${newsitem.id}`}" class="main-news-item__link main-news-title__link">
        <span class="main-news-item-title" v-text="newsitem.title"></span>
      </router-link>
      <ul class="main-news-category">
      </ul>
      <span class="main-news-item-date" v-text="newsitem.publishedAt"></span>
      <div class="main-news-item-descr">
        <p v-text="newsitem.shortDescription"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LastThreeNews",
  props: {
    newsitem: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped>

</style>