export default function(instance) {
    return {
        // get(id) {
        //   return instance.get(`films/${id}`)
        // },
        getAll(singerUsername) {
            return instance.get(`/api/v2/ieo/singer/authorized/${singerUsername}/`)
        },
        getAllUnauth(singerUsername) {
            return instance.get(`/api/v2/ieo/singer/unauthorized/${singerUsername}/`)
        },
        getAllDjookyAwards(singerUsername) {
            return instance.get(`/api/v2/dma/authorized/potential-singer/${singerUsername}/`)
        },
        getAllUnauthDjookyAwards(singerUsername) {
            return instance.get(`/api/v2/dma/unauthorized/potential-singer/${singerUsername}/`)
        },
    }
}
