export default {
    methods: {
        getQuery(urlString, query) {
            let uri = urlString.split('?');
            if (uri.length === 2)
            {
                let vars = uri[1].split('&');
                let getVars = {};
                let tmp = '';
                vars.forEach(function(v){
                    tmp = v.split('=');
                    if(tmp.length === 2)
                        getVars[tmp[0]] = tmp[1];
                });
                // if(query === 'transactionId') {
                //     return getVars.transactionId
                // }
                return getVars[query]
            }
        },
    },
}