<template>
  <v-dialog v-model="dialog" persistent>
    <div class="disabled-popup-wrapper">
      <div class="disabled-popup-title">Please wait while the song is verified</div>
      <div class="ieo-creating-payment-pending-dot-wrapper">
        <div class="ieo-creating-payment-pending-dot"
             :style="{
          width: dotPendingCounter === 1 ? 20 + 'px' : 11 + 'px',
          height: dotPendingCounter === 1 ? 20 + 'px' : 11 + 'px',
        }"
        ></div>
        <div class="ieo-creating-payment-pending-dot"
             :style="{
          width: dotPendingCounter === 2 ? 20 + 'px' : 11 + 'px',
          height: dotPendingCounter === 2 ? 20 + 'px' : 11 + 'px',
        }"
        ></div>
        <div class="ieo-creating-payment-pending-dot"
             :style="{
          width: dotPendingCounter === 3 ? 20 + 'px' : 11 + 'px',
          height: dotPendingCounter === 3 ? 20 + 'px' : 11 + 'px',
        }"
        ></div>
        <div class="ieo-creating-payment-pending-dot"
             :style="{
          width: dotPendingCounter === 4 ? 20 + 'px' : 11 + 'px',
          height: dotPendingCounter === 4 ? 20 + 'px' : 11 + 'px',
        }"
        ></div>
        <div class="ieo-creating-payment-pending-dot"
             :style="{
          width: dotPendingCounter === 5 ? 20 + 'px' : 11 + 'px',
          height: dotPendingCounter === 5 ? 20 + 'px' : 11 + 'px',
        }"
        ></div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "SuccessPopup",
  data() {
    return {
      dialog: false,
      dotPendingCounter: 1,
      counterInterval: null
    };
  },
  props: {
    errorMessage: String,
  },
  methods: {
    openPopups() {
      this.dialog = true;
      this.counterInterval = setInterval(() => {
        this.dotPendingCounter++
        if (this.dotPendingCounter === 6) {
          this.dotPendingCounter = 1
        }
      }, 300)
    },
    closePopup() {
      this.clearSetInterval()
      this.dialog = false
    },
    clearSetInterval() {
      clearInterval(this.counterInterval)
    }
  },
  mounted() {

  }
};
</script>

<style>
.disabled-popup-wrapper {
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0 auto;
  height: 100%;
}

.disabled-popup-title {
  font-size: 56px;
  color: #fff;
}

.ieo-creating-payment-pending-dot-wrapper {
  display: flex;
  align-items: flex-end;
}

.ieo-creating-payment-pending-dot {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #fff;
  margin: 0 10px 20px 10px;
  transition: all 0.4s ease-out;
}

@media only screen and (max-width: 1200px) {

  .disabled-popup-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .disabled-popup-title {
    font-size: 40px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .disabled-popup-title {
    font-size: 30px;
    margin-bottom: 15px;
  }

  .ieo-creating-payment-pending-dot-wrapper {
    transform: scale(0.8);
  }
}

@media only screen and (max-width: 768px) {

  .disabled-popup-title {
    font-size: 25px;
  }

  .ieo-creating-payment-pending-dot-wrapper {
    transform: scale(0.65);
  }
}

@media only screen and (max-width: 500px) {

  .disabled-popup-title {
    font-size: 20px;
  }

  .ieo-creating-payment-pending-dot-wrapper {
    transform: scale(0.5);
  }
}

@media only screen and (max-width: 425px) {

  .disabled-popup-title {
    font-size: 16px;
  }

  .ieo-creating-payment-pending-dot-wrapper {
    transform: scale(0.4);
  }
}
</style>