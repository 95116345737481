<template>
    <tbody>
        <TradingHistoryTableItems
            v-for="(history, index) in tradingHistory"
            :key="index"
            :history="history"
        />
    </tbody>
</template>

<script>
    import TradingHistoryTableItems from "./TradingHistoryTableItems";
    export default {
        name: "TradingHistoryTemplate",
        props: {
            tradingHistory: {
                type: Array,
                default: () => []
            }
        },
        components: {TradingHistoryTableItems}
    }
</script>

<style scoped>

</style>