<template>
  <v-dialog v-model="dialog">
    <div class="white-popup imprint-popup">
      <div class="form-main-content">
        <div class="popup-header">
          <button class="close-btn">
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"
                 @click="dialog = false"
            >
              <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5656 9.43464C20.2839 9.15068 19.9005 8.99095
                19.5005 8.99095C19.1006 8.99095 18.7172 9.15068 18.4355 9.43464L15.0004 12.8846L11.5653 9.43464C10.9771
                8.84647 10.0235 8.84647 9.43525 9.43464C8.84705 10.0228 8.84705 10.9764 9.43525 11.5646L12.8853
                14.9995L9.43525 18.4345C9.15128 18.7161 8.99154 19.0995 8.99154 19.4995C8.99154 19.8994 9.15128 20.2828
                9.43525 20.5645C9.71691 20.8484 10.1003 21.0081 10.5003 21.0081C10.9002 21.0081 11.2837 20.8484 11.5653
                20.5645L15.0004 17.1145L18.4355 20.5645C18.7172 20.8484 19.1006 21.0081 19.5005 21.0081C19.9005 21.0081
                20.2839 20.8484 20.5656 20.5645C20.8495 20.2828 21.0093 19.8994 21.0093 19.4995C21.0093 19.0995 20.8495
                18.7161 20.5656 18.4345L17.1155 14.9995L20.5656 11.5646C20.8495 11.283 21.0093 10.8996 21.0093
                10.4996C21.0093 10.0997 20.8495 9.71629 20.5656 9.43464ZM25.6057 4.39473C19.7493 -1.46464 10.2515
                -1.46464 4.39351 4.39313C-1.46451 10.2509 -1.4645 19.7482 4.39352 25.606C10.2507 31.4645 19.7485
                31.4645 25.6065 25.6068C31.4645 19.749 31.4645 10.2517 25.6065 4.39392L25.6057 4.39473ZM23.4918
                23.4892C19.446 27.5393 13.1026 28.1678 8.34066 24.9904C3.57872 21.8129 1.72439 15.7144 3.91163
                10.4241C6.09885 5.13386 11.7185 2.12516 17.3341 3.23787C22.9497 4.35058 26.9974 9.27483 27.0019
                14.9994C27.0127 18.1845 25.7487 21.2416 23.4918 23.4892Z"/>
            </svg>
          </button>
          <div class="head-title-wrap">
          <h3>imprint</h3>
          </div>
        </div>
        <div class="popup-content">
          <div class="info-wrapper">
            <div class="info-item">
              <div class="info-title">Company name:</div>
              <div class="info-description">Djooky GmbH</div>
            </div>
            <div class="info-item">
              <div class="info-title">Address:</div>
              <div class="info-description">Pestalozziplatz 6, 15938 Kasel-Golzig, Germany</div>
            </div>
            <div class="info-item">
              <div class="info-title">Commercial register:</div>
              <div class="info-description">Amtsgericht (local court) Charlottenburg, HRB 218067</div>
            </div>
            <div class="info-item">
              <div class="info-title">Managing director:</div>
              <div class="info-description">Stefan Eghbalian</div>
            </div>
            <div class="info-item">
              <div class="info-title">VAT-ID no.:</div>
              <div class="info-description">DE332187050</div>
            </div>
            <div class="info-item">
              <div class="info-title">Email:</div>
              <div class="info-description">support@djookyx.com</div>
            </div>
            <div class="info-item">
              <div class="info-title">Phone:</div>
              <div class="info-description">+4917648275551</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </v-dialog>
</template>

<script>
export default {
  name: "ImprintPopup",
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    openPopups() {
      this.dialog = true;
    },
    confirmAction() {
      this.$emit('confirmAction')
      this.dialog = false
    }
  },
};
</script>

<style scoped>

.white-popup {
  background: unset;
  border-radius: 15px;
}
.form-main-content {
  border-radius: 15px;
}
.imprint-popup .popup-header {
  background: #FFFFFF !important;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  border-radius: 15px 15px 0 0;
  margin-bottom: 0;
}

.imprint-popup .close-btn {
  align-self: end;
}

.imprint-popup .close-btn svg {
  fill: #C1C1C1;
  transition: .5s ease;
}

.imprint-popup .close-btn:hover svg {
  fill: #464646;
}

.imprint-popup .head-title-wrap {
  width: 100%;
  align-items: center;
}

.imprint-popup .head-title-wrap h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #040406;
}
.imprint-popup .popup-content {
  padding: 0 60px 60px 60px;
}

.imprint-popup .info-wrapper {
  padding: 20px 0;
  border-bottom: 1px solid rgba(222, 222, 222, 0.6);
  border-top: 1px solid rgba(222, 222, 222, 0.6);
}

.imprint-popup .info-item {
  display: flex;
  align-items: center;
}

.imprint-popup .info-title {
  max-width: 150px;
  width: 100%;
  align-self: self-start;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 31px;
  text-align: right;
  color: #A6A6A6;
  margin-right: 30px;
}

.imprint-popup .info-description {
  width: calc(100% - 165px);
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 31px;
  color: #040406;
}

@media only screen and (max-width: 768px) {

  .imprint-popup .popup-header {
    padding: 10px 15px;
  }

  .imprint-popup h3 {
    margin-bottom: 0;
    line-height: 20px;
  }

  .imprint-popup .popup-content {
    padding: 0 45px 45px 45px;
  }

  .imprint-popup .info-wrapper {
    padding: 13px 0;
  }

  .imprint-popup .close-btn svg {
    width: 25px;
  }

}

@media only screen and (max-width: 600px) {

  .imprint-popup .info-item {
    flex-wrap: wrap;

  }

  .imprint-popup .info-title {
    max-width: 100%;
    text-align: left;
    margin-right: 0;
  }

  .imprint-popup .info-description {
    width: 100%;
    line-height: 26px;
  }

}

@media only screen and (max-width: 375px)  {
  .imprint-popup .popup-header {
    align-items: center;
  }

  .imprint-popup .close-btn {
    order: 2;
    align-self: inherit;
  }

  .imprint-popup .head-title-wrap {
    width: calc(100% - 22px);
  }

  .imprint-popup .close-btn svg {
    width: 22px;
  }

  .imprint-popup .head-title-wrap h3 {
    font-size: 18px;
    line-height: 18px;
  }

  .imprint-popup .popup-content {
    padding: 0 20px 15px 20px;
  }

  .imprint-popup .info-title {
    font-size: 13px;
    line-height: 20px;
  }

  .imprint-popup .info-description {
    font-size: 14px;
    line-height: 20px;
  }
}

@media only screen and (max-height: 420px) and (orientation: landscape) {

  .imprint-popup .popup-header {
    align-items: center;
  }

  .imprint-popup .popup-header {
    padding: 10px 15px;
  }

  .imprint-popup h3 {
    margin-bottom: 0;
    line-height: 20px;
  }

  .imprint-popup .head-title-wrap {
    width: calc(100% - 26px);
    align-items: center;
  }

  .imprint-popup .popup-content {
    padding: 0 40px 40px 40px;
  }

  .imprint-popup .info-wrapper {
    padding: 13px 0;
  }

  .imprint-popup .close-btn {
    order: 2;
    align-self: inherit;
    margin-left: auto;
  }

  .imprint-popup .close-btn svg {
    width: 25px;
  }

  .imprint-popup .info-title {
    line-height: 25px;
  }

  .imprint-popup .info-description {
    font-size: 15px;
    line-height: 25px;
  }

}

@media only screen and (max-height: 350px) and (orientation: landscape) {

  .imprint-popup .popup-header {
    padding: 5px 8px;
    align-items: center;
  }

  .imprint-popup .close-btn svg {
    width: 22px;
  }

  .imprint-popup .head-title-wrap h3 {
    font-size: 18px;
    line-height: 20px;
  }

  .imprint-popup .popup-content {
    padding: 0 35px 20px 35px;
  }

  .imprint-popup .info-item {
    flex-wrap: nowrap;
  }

  .imprint-popup .info-title {
    text-align: right;
    margin-right: 20px;
    max-width: 135px;
  }

  .imprint-popup .info-description {
    width: calc(100% - 135px);
  }
}



</style>