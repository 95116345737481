<template>
  <div class="step-progress-bar__container">
    <ul class="step-progress-bar">

      <li class="step-progress-bar__item" :class="{'active': currentStep === 1, 'done': currentStep > 1 || allDone}">
        <div class="bigger-circle">
          <span class="num-circle">1</span>
          <div class="complete-circle">
            <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.03924 3.38893C2.44186 2.81498 1.4923 2.83397 0.918343 3.43135C0.344387 4.02873 0.363378 4.97829 0.960761 5.55225L3.03924 3.38893ZM6.71429 9L5.67505 10.0817C6.2556 10.6394 7.17297 10.6394 7.75352 10.0817L6.71429 9ZM15.0392 3.08166C15.6366 2.5077 15.6556 1.55814 15.0817 0.960761C14.5077 0.363378 13.5581 0.344387 12.9608 0.918343L15.0392 3.08166ZM0.960761 5.55225L5.67505 10.0817L7.75352 7.91834L3.03924 3.38893L0.960761 5.55225ZM7.75352 10.0817L15.0392 3.08166L12.9608 0.918343L5.67505 7.91834L7.75352 10.0817Z" fill="#F9F9F9"/>
            </svg>
          </div>
        </div>
        <span class="step-progress-bar__item-subtitle" v-text="stepNames[0]"></span>
      </li>

      <li class="step-progress-bar__item" :class="{'active': currentStep === 2, 'done': currentStep > 2 || allDone}" v-if="totalOfSteps > 1">
        <div class="bigger-circle">
          <span class="num-circle">2</span>
          <div class="complete-circle">
            <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.03924 3.38893C2.44186 2.81498 1.4923 2.83397 0.918343 3.43135C0.344387 4.02873 0.363378 4.97829 0.960761 5.55225L3.03924 3.38893ZM6.71429 9L5.67505 10.0817C6.2556 10.6394 7.17297 10.6394 7.75352 10.0817L6.71429 9ZM15.0392 3.08166C15.6366 2.5077 15.6556 1.55814 15.0817 0.960761C14.5077 0.363378 13.5581 0.344387 12.9608 0.918343L15.0392 3.08166ZM0.960761 5.55225L5.67505 10.0817L7.75352 7.91834L3.03924 3.38893L0.960761 5.55225ZM7.75352 10.0817L15.0392 3.08166L12.9608 0.918343L5.67505 7.91834L7.75352 10.0817Z" fill="#F9F9F9"/>
            </svg>
          </div>
        </div>
        <span class="step-progress-bar__item-subtitle" v-text="stepNames[1]"></span>
      </li>

      <li class="step-progress-bar__item" :class="{'active': currentStep === 3, 'done': currentStep > 3 || allDone}" v-if="totalOfSteps > 2">
        <div class="bigger-circle">
          <span class="num-circle">3</span>
          <div class="complete-circle">
            <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.03924 3.38893C2.44186 2.81498 1.4923 2.83397 0.918343 3.43135C0.344387 4.02873 0.363378 4.97829 0.960761 5.55225L3.03924 3.38893ZM6.71429 9L5.67505 10.0817C6.2556 10.6394 7.17297 10.6394 7.75352 10.0817L6.71429 9ZM15.0392 3.08166C15.6366 2.5077 15.6556 1.55814 15.0817 0.960761C14.5077 0.363378 13.5581 0.344387 12.9608 0.918343L15.0392 3.08166ZM0.960761 5.55225L5.67505 10.0817L7.75352 7.91834L3.03924 3.38893L0.960761 5.55225ZM7.75352 10.0817L15.0392 3.08166L12.9608 0.918343L5.67505 7.91834L7.75352 10.0817Z" fill="#F9F9F9"/>
            </svg>
          </div>
        </div>
        <span class="step-progress-bar__item-subtitle" v-text="stepNames[2]"></span>
      </li>

      <li class="step-progress-bar__item" :class="{'active': currentStep === 4, 'done': currentStep > 4 || allDone}" v-if="totalOfSteps > 3">
        <div class="bigger-circle">
          <span class="num-circle">4</span>
          <div class="complete-circle">
            <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.03924 3.38893C2.44186 2.81498 1.4923 2.83397 0.918343 3.43135C0.344387 4.02873 0.363378 4.97829 0.960761 5.55225L3.03924 3.38893ZM6.71429 9L5.67505 10.0817C6.2556 10.6394 7.17297 10.6394 7.75352 10.0817L6.71429 9ZM15.0392 3.08166C15.6366 2.5077 15.6556 1.55814 15.0817 0.960761C14.5077 0.363378 13.5581 0.344387 12.9608 0.918343L15.0392 3.08166ZM0.960761 5.55225L5.67505 10.0817L7.75352 7.91834L3.03924 3.38893L0.960761 5.55225ZM7.75352 10.0817L15.0392 3.08166L12.9608 0.918343L5.67505 7.91834L7.75352 10.0817Z" fill="#F9F9F9"/>
            </svg>
          </div>
        </div>
        <span class="step-progress-bar__item-subtitle" v-text="stepNames[3]"></span>
      </li>

      <li class="step-progress-bar__item" :class="{'active': currentStep === 5, 'done': allDone}" v-if="totalOfSteps > 4">
        <div class="bigger-circle">
          <span class="num-circle">5</span>
          <div class="complete-circle">
            <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.03924 3.38893C2.44186 2.81498 1.4923 2.83397 0.918343 3.43135C0.344387 4.02873 0.363378 4.97829 0.960761 5.55225L3.03924 3.38893ZM6.71429 9L5.67505 10.0817C6.2556 10.6394 7.17297 10.6394 7.75352 10.0817L6.71429 9ZM15.0392 3.08166C15.6366 2.5077 15.6556 1.55814 15.0817 0.960761C14.5077 0.363378 13.5581 0.344387 12.9608 0.918343L15.0392 3.08166ZM0.960761 5.55225L5.67505 10.0817L7.75352 7.91834L3.03924 3.38893L0.960761 5.55225ZM7.75352 10.0817L15.0392 3.08166L12.9608 0.918343L5.67505 7.91834L7.75352 10.0817Z" fill="#F9F9F9"/>
            </svg>
          </div>
        </div>
        <span class="step-progress-bar__item-subtitle" v-text="stepNames[4]"></span>
      </li>

    </ul>
  </div>
</template>

<script>


export default {
  name: "StepProgressBar",
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
    totalOfSteps: {
      type: Number,
      required: true,
    },
    stepNames: {
      type: Array,
      required: true,
    },
    allDone: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {

    }
  },
  methods: {
    responsibleWidth() {
      let items = document.querySelectorAll('.step-progress-bar__item')
      const addWidth = (width) => {
        items.forEach((el) => {
          el.style.width = width.toString()
        })
      }
      if (this.totalOfSteps === 2) {
        addWidth('50%')
        return
      }
      if (this.totalOfSteps === 3) {
        addWidth('33.3%')
        return
      }
      if (this.totalOfSteps === 4) {
        addWidth('25%')
        return
      }
      if (this.totalOfSteps === 5) {
        addWidth('20%')
      }
    },
    // resizeWindowWatcher() {
    //   window.addEventListener("resize", () => {
    //     if (window.innerWidth > 900) {
    //       this.responsibleWidth()
    //     }
    //   }, false);
    // },
  },
  mounted() {
    // this.resizeWindowWatcher()
    if (window.innerWidth > 900) {
      this.responsibleWidth()
    }
  }
}
</script>

<style scoped>

.step-progress-bar__container {
  box-sizing: border-box;
  padding: 25px 90px 30px 50px;
  width: 100%;
  background: #F9F9F9;
  border-radius: 15px;
  height: fit-content;
  margin-bottom: 50px;
}

.step-progress-bar__container .step-progress-bar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0;
}

.step-progress-bar__item {
  list-style-type: none;
  text-align: center;
  position: relative;
  width: 20%;
  display: flex;
  flex-direction: column;
}

.step-progress-bar__item .step-progress-bar__item-subtitle {
  padding: 0 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #CCCCCC;
}

.step-progress-bar__item .bigger-circle {
  display: flex;
  margin: 0 auto 20px auto;
  line-height: 51px;
  width: 51px;
  height: 51px;
  text-align: center;
  border-radius: 50%;
  background-color: transparent;
  z-index: 2;
}

.step-progress-bar__item .bigger-circle .num-circle {
  display: block;
  margin: auto;
  width: 35px;
  height: 35px;
  font-weight: 500;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 35px;
  border-radius: 50%;
  background: #F0F0F0;
}

.step-progress-bar__item:after {
  content: '';
  position: absolute;
  height: 13px;
  width: 100%;
  left: -50%;
  top: 20px;
  background-color: #FF0090;
  box-shadow: 0 4px 10px rgba(255, 0, 144, 0.1);
  z-index: 1;
}

.step-progress-bar__item:first-of-type::after {
  content: none;
}

.step-progress-bar__item .complete-circle {
  display: none;
}

.step-progress-bar__item.done .complete-circle {
  display: block;
  margin: auto;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  background-color: #FF0090;
  box-shadow: 0 4px 10px rgba(255, 0, 144, 0.1);
}

.step-progress-bar__item.done .num-circle {
  display: none;
}

.step-progress-bar__item.active.done .complete-circle {
  display: none;
}

.step-progress-bar__item.active.done .num-circle {
  display: block;
}

.step-progress-bar__item.active .bigger-circle {
  background-color: #FF0090;
  box-shadow: 0 4px 10px rgba(255, 0, 144, 0.1);
  position: relative;
}

.step-progress-bar__item.active .bigger-circle:before {
  content: '';
  position: absolute;
  background-color: transparent;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #FFFFFF;
}

.step-progress-bar__item.active .bigger-circle .num-circle {
  font-weight: 500;
  font-size: 30px;
  background: #FF0090;
}

.step-progress-bar__item.active .step-progress-bar__item-subtitle {
  color: #464646;
}

.step-progress-bar__item.active ~ .step-progress-bar__item:after {
  background: #F0F0F0;
  box-shadow: none;
}

.step-progress-bar__container .step-progress-bar .step-progress-bar__item.done:after {
  background-color: #FF0090;
}

@media only screen and (max-width: 1400px) {
  .step-progress-bar__item .step-progress-bar__item-subtitle {
    font-size: 18px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 900px) {
  .step-progress-bar__container {
    padding: 22px;
  }

  .step-progress-bar__container .step-progress-bar {
    flex-direction: column;
  }

  .step-progress-bar__item {
    text-align: left;
    width: 100%;
    justify-content: flex-start;
    align-items: baseline;
    flex-direction: row;
  }

  .step-progress-bar__item .bigger-circle {
    margin: 0 10px 10px 0;
  }

  .step-progress-bar__item:after {
    height: 100%;
    width: 13px;
    left: 19px;
    top: -50%;
  }
}

@media only screen and (max-width: 540px) {
  .step-progress-bar__item .bigger-circle {
    width: 23px;
    height: 23px;
    line-height: 23px;
  }

  .step-progress-bar__item .step-progress-bar__item-subtitle {
    font-size: 16px;
    line-height: 20px;
  }

  .step-progress-bar__item:after {
    width: 6px;
    left: 9px;
  }

  .step-progress-bar__item .bigger-circle .num-circle {
    width: 23px;
    height: 23px;
    font-size: 14px;
    line-height: 23px;
  }

  .step-progress-bar__item.done .complete-circle {
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .step-progress-bar__item.done .complete-circle svg {
    width: 9px;
    height: auto;
  }

  .step-progress-bar__item.active .bigger-circle .num-circle {
    font-size: 14px;
    line-height: 23px;
  }

  .step-progress-bar__item.active .bigger-circle:before {
    width: 20px;
    height: 20px;
  }
}

</style>