<template>
  <div
      class="general_select"
      :tabindex="tabindex"
      :class="{'disable-select': noOptions}"
      @blur="blurFunc"
  >
    <div
        class="general_select-selected"
        :class="{open: open}"
        @click="toggleSelect"
    >
      <span
          class="selected-text"
          :class="{'placeholder': selected === placeholder}"
      >
        {{ selected }}
      </span>
    </div>
    <svg class="general_select-arrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.3945 1L5.69727 5.69727L1 1" stroke="#272727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <div
        class="general_select-list"
        :class="{'selectHide': !open,
                 'scroll-on': options && options.length > 5}"
    >
      <vuescroll
          :ops="ops"
          ref="GeneralSelectVueScroll"
      >
        <div
            class="general_select-item"
            v-for="(option, i) in options"
            :key="i"
            :id="`${identifier}-id:${i}`"
            @click="setItem(option)"
        >
          <p class="general_select-item-content">
            {{ option.name }}
          </p>
        </div>
      </vuescroll>
    </div>
  </div>
</template>

<script>

import vuescroll from "vuescroll";

export default {
  name: "GeneralSelect",
  props: {
    options: {
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    identifier: {
      type: String,
      required: true,
      default: null,
    },
    current: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      selected: null,
      open: false,
      ops: {
        rail: {
          background: 'rgba(234, 234, 234)',
          opacity: 0.5,
          size: '6px',
          gutterOfSide: '2px',
        },
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: true,
          background: '#FF0090',
          opacity: 1,
          minSize: 0.3,
          size: '6px',
        }
      },
    };
  },
  computed: {
    noOptions() {
      return !this.options || this.options.length === 0
    },
  },
  methods: {
    toggleSelect() {
      if (!this.noOptions) {
        this.open = !this.open
      }
    },
    resetSelect() {
      this.selected = this.placeholder
      this.$emit('changed', null)
    },
    setItem(option) {
      this.selected = option.name
      this.open = false
      this.$emit('changed', option.value)
    },
    emitOpenStatus() {
      this.$emit('openStatus', this.open)
    },
    checkIncomeProps() {
      if (this.current) {
        this.selected = this.current.name
        this.$emit('changed', this.current.value);
      } else if (this.placeholder) {
        this.selected = this.placeholder
      } else if (this.options && this.options.length > 0) {
        this.selected = this.options[0]
      } else {
        this.selected = null
      }
    },
    blurFunc() {
      this.open = false
      this.$emit('blur', true)
    }
  },
  watch: {
    open() {
      this.emitOpenStatus()
    },
  },
  mounted() {
    this.emitOpenStatus()
    this.selected = this.placeholder
    setTimeout(() => {
      this.checkIncomeProps()
    }, 800)

    // this.$emit("changed", this.options[1].value);
  },
  components: {
    vuescroll,
  }
}
</script>

<style scoped>

.selected-text.placeholder {
  opacity: 0.6;
}

.general_select {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #131415;
  text-align: left;
  outline: none;
}

.general_select-selected {
  height: 100%;
  padding-left: 25px;
  padding-right: 40px;
  box-sizing: border-box;
  background: transparent;
  cursor: pointer;
  border: 1px solid #C6C6C6;
  border-radius: 5px;
}

.general_select-selected.open {
  border-bottom: none;
  border-radius: 5px 5px 0 0;
}

.general_select-list {
  display: block;
  position: absolute;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  right: 0;
  left: 0;
  background: #FFFFFF;
  border-right: 1px solid #C6C6C6;
  border-left: 1px solid #C6C6C6;
  border-bottom: 1px solid #C6C6C6;
  border-radius: 0 0 5px 5px;
  z-index: 15;
}

.general_select-list.scroll-on {
  height: 300px;
  padding: 15px 10px 15px 0;
}

.scroll-on .general_select-item {
  width: calc(100% - 15px);
}

.general_select-list.selectHide {
  display: none;
}

.general_select-item {
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  padding-left: 25px;
  padding-right: 25px;
  transition: .5s ease;
}

p.general_select-item-content {
  width: 100%;
  height: 100%;
  border-top: 1px solid #EAEAEA;
  margin-bottom: 0;
}

.general_select-item:hover {
  background: #D8D8D8;
}

.general_select-selected + .general_select-arrow {
  position: absolute;
  top: 22px;
  right: 24px;
  transition: .35s ease;
}

.general_select-selected.open + .general_select-arrow {
  transform: rotate(180deg);
}

/* for disable select */
.general_select.disable-select {
  /*background-color: #C6C6C6;*/
  /*border-radius: 5px;*/
  cursor: not-allowed;
}

.general_select.disable-select .general_select-selected {
  background: #F9F9F9;
  border: none;
  cursor: not-allowed;
}
/* end disable select */

@media only screen and (max-width: 540px) {
  .general_select {
    font-size: 17px;
  }
}

</style>