<template>
  <div class="ieo-creating-payment-by-card">
    <div
        id="PaylineWidget"
        :data-token="userToken"
        data-template="column"
        data-event-didshowstate="OnLoad"
        ref="PaylineWidgetRef"
    ></div>
    <div class="ieo-creating-btn-wrap" v-if="paymentStatus">
      <button type="button" class="btn btn-hover ieo-creating-btn">
        Start Initial Sale
      </button>
    </div>
    <div class="ieo-creating-btn-wrapper" v-else>
      <button type="button" disabled class="btn btn-hover ieo-creating-btn disabled">
        Start Initial Sale
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "IeoCreatingPaymentCard",
  props: {
    userToken: String
  },
  data() {
    return {
      paymentStatus: false
    }
  },
  methods: {
    executeCancelAction() {
    }
  },
  mounted() {
    function addScript(src, callback) {
      var s = document.createElement('script');
      s.setAttribute('src', src);
      s.onload = callback;
      document.body.appendChild(s);
    }

    if (process.env.VUE_APP_ENV_NAME === 'prod') {
      addScript('https://payment.payline.com/scripts/widget-min.js')
    } else {
      addScript("https://homologation-payment.payline.com/scripts/widget-min.js")
    }


    setTimeout(() => {

      let header = this.$refs.PaylineWidgetRef.getElementsByClassName('pl-pay-btn ')
      header.click(this.executeCancelAction)
    }, 1000)
  }
}
</script>

<style scoped>
.ieo-creating-payment-by-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 44px;
}

.ieo-creating-btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

</style>