import instance from "../api/instance";

export const user = {
    namespaced: true,
    state: {
        user: {},
    },
    getters: {
        getUser(state) {
            return state.user
        },
    },
    mutations: {
        SET_USER(state, payload) {
            state.user = payload
        },
        DELETE_USER(state) {
            state.user = null
        }
    },
    actions: {
        async setUser({commit}) {
            let res = (await instance.get('/api/v2/profile'))
            let user = res.data.body
            commit('SET_USER', {
                userPhoto: user.photo,
                userName: user.firstName,
                userNameId: user.username,
                userType: user.userType,
                userUuid: user.uuid
            })
        },
        deleteUser({commit}) {
            commit('DELETE_USER')
        }
    }
}