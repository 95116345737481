<template>
  <v-dialog v-model="inviteDialog" persistent>
    <div id="invite-popup-form disclaimer-popup" class="white-popup popup-form invite-popup">
      <div class="form-main-content">
        <div class="close-dialog" @click="inviteDialog = false">
          <img src="../../assets/img/close.svg" alt="close-icon">
        </div>
        <div class="popup-header popup-invite-header">
          <h3 class="form-title">Invite a friend</h3>
        </div>
        <div class="popup-content">
          <ValidationObserver v-slot="{ handleSubmit }">
          <form class="invite-form popup-invite-form" @submit.prevent="handleSubmit(sendInvite)">
            <ValidationProvider name="email" rules="email|required" v-slot="{ errors }">
              <div class="invite-form-wrapper">
                <input v-model="email" type="email" @input="error = ''" name="email" placeholder="Email" class="invite-field">
                <div class="error invite-email-error" id="error-mail-exist">{{ errors[0] }}</div>
                <div class="error invite-email-error" id="error-required-rules">{{ error }}</div>
              </div>
            </ValidationProvider>
            <div class="delete-image-descr invite-form-descr">
              <p>
                Are you sure you want to send the invite ?<br> Please
                confirm
              </p>
            </div>
            <div class="delete-image-btn-wrap">
              <button type="submit" class="btn btn-hover invite-submit" id="invite-confirm">Yes,
                invite</button>
              <a href="#!" class="btn btn-transparent cancel-btn"
                 @click="canelFunc" id="invite-cancel">Cancel</a>
            </div>
          </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "InvitePopup",
  data() {
    return {
      inviteDialog: false,
      email: '',
      error: ''
    };
  },
  methods: {
    canelFunc() {
      this.email = ''
      this.error = ''
      this.inviteDialog = false
    },
    openDialog() {
      this.inviteDialog = !this.inviteDialog
    },
    sendInvite() {
      if (this.email.length !== 0) {
        this.$load(async () => {
          await this.$api.profileInvite.sendInvite({'email': this.email});
          this.email = '';
          this.inviteDialog = false;
        }, error => {
          this.error = error.data.errors[0].message
        })
      }
    }
  },
};
</script>

<style>

</style>