<template>
  <div class="pending-ieo-list-item pending-ieo-list-item-not-started pending-ieo-genre-parent">
    <div class="pending-ieo-list-item-header">
      <div class="pending-ieo-label" v-if="notStartedIeo.totalPlanned">
        <span class="pending-ieo-label-title">Total Planned:</span>
        <span class="pending-ieo-label-price"
              v-text="`€ ${totalPlanedWithSeparator}`"></span>
      </div>
      <img v-if="notStartedIeo.songAlbumUrlImage !==''" :src='notStartedIeo.songAlbumUrlImage'
           alt="Author image"
           class="pending-ieo-list-item-image lazy" loading="lazy">
      <img v-else src="../../assets/img/default-song-image.jpg" class="pending-ieo-list-item-image lazy"
           alt="Author image" loading="lazy">
      <div class="pending-ieo-list-item__play-wrap">
        <div :class="togglePlayerFlag ?
          'hitparad_button-play hitparad_button-pause'
          : 'hitparad_button-play hitparad_button-play-active'" @click="toggleMusic">
          <div class="hitparad_button-play-progress">
            <canvas width="70" height="70"></canvas>
            <canvas width="70" height="70"></canvas>
          </div>
        </div>
      </div>
      <div class="audio-player-wrapper">
        <AudioPlayer
            :src="playerSrc"
            :togglePlayerFlag="togglePlayerFlag"
            :width="'100%'"
            v-if="playerSrc"
            :ref="`audioPlayer${identifier}${index}`"
            @audioPause="togglePlayerFlag = true"
            :identifier="identifier"
            :index="index"
        />
      </div>
    </div>
    <div class="pending-ieo-list-item-body">

      <div class="pending-ieo-list-item-body-top">
        <router-link :to="{path: `/song/${notStartedIeo.songId}`}"
           class="pending-ieo-list-item-name-song-link">
                                                    <span class="pending-ieo-list-item-name-song"
                                                          v-text="notStartedIeo.songTitle"></span>
        </router-link>
        <div class="pending-ieo-list-item-info">
          <router-link :to="{path: `/artist/${notStartedIeo.singerUsername}`}"
             class="pending-ieo-list-item-name-artist-link">
                                                        <span class="pending-ieo-list-item-name-artist"
                                                              v-text="notStartedIeo.singerUsername"></span>
          </router-link>
          <ul class="profile-my-music-genre-list">
            <li class="profile-my-music-genre-list-item genre"
                v-text="notStartedIeo.songGenre"></li>
          </ul>
        </div>
      </div>

      <div class="pending-ieo-list-item-body-middle">
        <div class="pending-ieo-countdown-wrap">
          <span class="pending-ieo-countdown-title">Time to the Initial Sale start:</span>

            <flip-countdown :deadline="notStartedIeo.timeToIeoStart" :showSeconds="false"></flip-countdown>

        </div>
      </div>
      <div class="pending-ieo-list-item-body-bottom">
        <div class="pending-ieo-subscribers-wrap"
             v-if="notStartedIeo.subscribed === false">
          <svg width="22" height="14" viewBox="0 0 22 14" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"
                  fill="#D0D0D0"/>
          </svg>
          <span class="pending-ieo-subscribers-title"
                v-text="notStartedIeo.subscribers ? notStartedIeo.subscribers : '0'"></span>
        </div>
        <div class="pending-ieo-subscribers-wrap"
             v-if="notStartedIeo.subscribed === true">
          <svg width="22" height="14" viewBox="0 0 22 14" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"
                  fill="#FF0090"/>
          </svg>
          <span class="pending-ieo-subscribers-title"
                v-text="notStartedIeo.subscribers ? notStartedIeo.subscribers : '0' + ' ' + 'subscribers'"></span>
        </div>
        <div v-if="isLogged === true" class="pending-ieo-btn-wrap">
          <a @click="followIeo"
             :class="notStartedIeo.subscribed === true ? 'pending-ieo-btn btn btn-hover follow' : 'pending-ieo-btn btn btn-hover'"
             v-if="notStartedIeo.subscribed === false">Subscribe</a>
          <a @click="unfollowIeo"
             :class="notStartedIeo.subscribed === true ? 'pending-ieo-btn btn btn-hover follow' : 'pending-ieo-btn btn btn-hover'"
             v-if="notStartedIeo.subscribed === true">Unsubscribe</a>
        </div>
        <div v-else class="pending-ieo-btn-wrap">
          <router-link :to="{path: '/login'}" class="pending-ieo-btn-not-auth btn btn-hover">Login
            or Register to subscribe
          </router-link>
        </div>
      </div>

      <!--                                <div class="pending-ieo-list-item-body-bottom">-->
      <!--                                <div class="pending-ieo-subscribers-wrap"-->
      <!--                                     th:if="${notStartedIeo.subscribed == false}">-->
      <!--                                    <svg width="22" height="14" viewBox="0 0 22 14" fill="none"-->
      <!--                                         xmlns="http://www.w3.org/2000/svg">-->
      <!--                                        <path fill-rule="evenodd" clip-rule="evenodd"-->
      <!--                                              d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"-->
      <!--                                              fill="#D0D0D0"/>-->
      <!--                                    </svg>-->
      <!--                                    <span class="pending-ieo-subscribers-title"-->
      <!--                                          th:text="${notStartedIeo.subscribers   ?: '0' + ' ' + 'subscribers'}"></span>-->
      <!--                                </div>-->
      <!--                                <div class="pending-ieo-subscribers-wrap"-->
      <!--                                     th:if="${notStartedIeo.subscribed == true}">-->
      <!--                                    <svg width="22" height="14" viewBox="0 0 22 14" fill="none"-->
      <!--                                         xmlns="http://www.w3.org/2000/svg">-->
      <!--                                        <path fill-rule="evenodd" clip-rule="evenodd"-->
      <!--                                              d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"-->
      <!--                                              fill="#FF0090"/>-->
      <!--                                    </svg>-->
      <!--                                    <span class="pending-ieo-subscribers-title"-->
      <!--                                          th:text="${notStartedIeo.subscribers   ?: '0' + ' ' + 'subscribers'}"></span>-->
      <!--                                </div>-->
      <!--                                <a th:href="@{'/market/follow-ieo/' + ${notStartedIeo.ieoId}}"-->
      <!--                                   class="pending-ieo-btn btn btn-hover"-->
      <!--                                   th:if="${notStartedIeo.subscribed == false}">Subscribe</a>-->
      <!--                                <a th:href="@{'/market/follow-ieo/' + ${notStartedIeo.ieoId}}"-->
      <!--                                   class="pending-ieo-btn btn btn-hover disabled"-->
      <!--                                   th:if="${notStartedIeo.subscribed == true}">Subscribed</a>-->
      <!--                            </div>-->
    </div>
  </div>
</template>

<script>
import FlipCountdown from 'vue2-flip-countdown'
import AudioPlayer from "@/components/AudioPlayer";

export default {
  name: "NotStartedIeo",
  props: {
    notStartedIeo: {
      type: Object,
      default: () => {
      }
    },
    isLogged: {
      type: Boolean
    },
    current: {
      type: String
    },
    index: {
      type: Number
    },
    identifier: {
      type: String
    }
  },
  data() {
    return {
      togglePlayerFlag: true,
      mp3: '',
      totalPlanedWithSeparator: null,
      playerSrc: null,
    }
  },
  methods: {
    followIeo() {
        this.$emit('followIeo', this.notStartedIeo.ieoId)
    },
    unfollowIeo() {
      this.$emit('unfollowIeo', this.notStartedIeo.ieoId)
    },
    toggleMusic() {
      if (this.togglePlayerFlag) {
        this.togglePlayerFlag = false
        localStorage.setItem('currentSongPlaying', this.mp3)
        this.$emit('playingMusic', this.mp3)
        this.$refs[`audioPlayer${this.identifier}${this.index}`].toggleMusic()
      } else {
        this.togglePlayerFlag = true
        this.$refs[`audioPlayer${this.identifier}${this.index}`].toggleMusic()
      }

    }
  },
  watch: {
    'current'(newVal) {
      if (newVal !== this.mp3) {
        this.$refs[`audioPlayer${this.identifier}${this.index}`].refreshSong()
        this.togglePlayerFlag = true
      }
    }
  },
  mounted() {
    this.mp3 = this.notStartedIeo.songUrlFile
    this.playerSrc = this.mp3
    this.totalPlanedWithSeparator = new Intl.NumberFormat().format(this.notStartedIeo.totalPlanned)
  },
  beforeDestroy() {
    this.togglePlayerFlag = true
  },
  components: {FlipCountdown, AudioPlayer}
}
</script>

<style scoped>
.flip-clock {
  display: flex;
  justify-content: center;
}

.audio-player-wrapper {
  height: 45px;
  color: #FFFFFF;
}
</style>