<template>
    <li class="item notification-item js-item">
      <div class="details">
                                        <span class="notifications-title">
                                            <span v-text="notification.title"></span>
                                        </span>
        <span class="notifications-content">
                                            <span v-text="notification.content"></span>
                                        </span>
        <span class="notifications-date date" v-text="time"></span>
      </div>
      <button type="button"
              class="button-default button-dismiss js-dismiss close-notification"
              @click="changeStatus"
      >×
      </button>
    </li>
</template>

<script>

import dateHandler from "@/mixins/dateHandler";

export default {
  name: 'HeaderNotificationsList',
  props: {
    notification: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [dateHandler],
  methods: {
    changeStatus() {
      this.$load(async () => {
        await this.$api.notifications.changeStatus(this.notification.uuid)
        this.$emit('changedStatus')
      })
    },
  },
  computed: {
    time() {
      return this.toLocalDate(this.notification.createdAt)
    },
  },
}
</script>

<style scoped>

</style>