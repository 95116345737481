<template>
  <div id="co-authors-add-info" class="co-authors-add-info formset-item">
    <div class="formset-field-wrap">
      <input type="text" placeholder="Сo-author/co-owner" class="formset-field co-author-name"
             :name="`co_authors-${count}-description`"
             :id="`id_co_authors-${count}-description`"
             v-model.trim="dataObj.name"
             @input="handOverCoAuthors"
      >
      <span class="error error-length" v-if="errorLength.nameLength">Maximum number of characters is 100</span>
    </div>

    <div class="formset-field-wrap">
      <input type="text" placeholder="Social Link" class="formset-field co-author-link"
             :name="`co_authors-${count}-social_link`"
             :id="`id_co_authors-${count}-social_link`"
             v-model.trim="dataObj.link"
             @input="handOverCoAuthors"
      >
      <span class="error error-required" v-if="errorRequired">This field is required if you want to point a co-author</span>
      <span class="error error-length" v-else-if="errorLength.linkLength">Maximum number of characters is 100</span>
    </div>

<!--    <button type="button" class="formset-delete-btn"-->
<!--            data-formset-delete-button-->
<!--            @click="removeField"-->
<!--    >-->
<!--      <svg width="14.725" height="17.67" viewBox="0 0 15 18" fill="none"-->
<!--           xmlns="http://www.w3.org/2000/svg">-->
<!--        <path fill-rule="evenodd" clip-rule="evenodd"-->
<!--              d="M1.47248 15.711C1.47248 16.7885 2.35589 17.6699 3.43583 17.6699H11.2891C12.369 17.6699 13.2524 16.7885 13.2524 15.711V4.41747H1.47248V15.711ZM14.7249 1.4725H11.0437L9.81115 0H4.9138L3.68122 1.4725H0V2.94498H14.7249V1.4725Z"/>-->
<!--      </svg>-->
<!--    </button>-->

    <button type="button" class="formset-delete-btn"
            data-formset-delete-button
            @click="removeField"
    >
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.125 4.25H3.54167H14.875" stroke="#BF6464" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.66699 4.25002V2.83335C5.66699 2.45763 5.81625 2.0973 6.08192 1.83162C6.3476 1.56594 6.70794 1.41669 7.08366 1.41669H9.91699C10.2927 1.41669 10.6531 1.56594 10.9187 1.83162C11.1844 2.0973 11.3337 2.45763 11.3337 2.83335V4.25002M13.4587 4.25002V14.1667C13.4587 14.5424 13.3094 14.9027 13.0437 15.1684C12.7781 15.4341 12.4177 15.5834 12.042 15.5834H4.95866C4.58294 15.5834 4.2226 15.4341 3.95692 15.1684C3.69125 14.9027 3.54199 14.5424 3.54199 14.1667V4.25002H13.4587Z" stroke="#BF6464" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.08301 7.79169V12.0417" stroke="#BF6464" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.91699 7.79169V12.0417" stroke="#BF6464" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>

  </div>
</template>

<script>
export default {
  name: "AdditionalInformation",
  props: {
    count: Number,
  },
  data() {
    return {
      errorLength: {
        nameLength: false,
        linkLength:  false,
      },
      errorRequired: false,
      dataObj: {
        name: '',
        link: '',
      },
      fieldValidStatus: {
        id: this.count,
        valid: null,
      },
    }
  },
  methods: {
    removeField() {
      this.$emit('deleteItem')
    },
    handOverCoAuthors() {
      this.$emit('handOverCoAuthor', this.dataObj)
    },
    checkValidCoAuthors() {
      this.$emit('checkValidCoAuthor', this.fieldValidStatus)
      // console.log('validAll: ', this.checkValidField)
      // console.log('valid length name: ', this.errorLength.nameLength)
      // console.log('valid length link: ', this.errorLength.linkLength)
      // console.log('valid required: ', this.errorRequired)
    },
  },
  watch: {
    'dataObj.name'(newVal) {
      if (newVal.length) {
        if (!this.dataObj.link.length) {
          this.errorRequired = true
        }
      } else {
        this.errorRequired = false
      }
      if (newVal.length > 100) {
        this.errorLength.nameLength = true
      } else {
        this.errorLength.nameLength = false
      }
      this.checkValidCoAuthors()

    },
    'dataObj.link'(newVal) {
      if (newVal.length) {
        this.errorRequired = false
      } else {
        this.errorRequired = true
      }
      if (newVal.length > 100) {
        this.errorLength.linkLength = true
      } else {
        this.errorLength.linkLength = false
      }
      this.checkValidCoAuthors()
    },
    checkValidField(data) {
      this.fieldValidStatus.valid = data
    },
  },
  computed: {
    checkValidField() {
      return !this.errorLength.nameLength && !this.errorLength.linkLength && !this.errorRequired
    },
  },
}
</script>

<style scoped>

/*#co-authors-add-info {*/
/*  display: flex;*/
/*}*/

/*#co-authors-add-info .formset-field-wrap {*/

/*}*/

/*#co-authors-add-info .formset-item {*/
/*  position: relative;*/
/*}*/

/*#co-authors-add-info .error {*/
/*  position: absolute;*/
/*  color: red;*/
/*  font-size: 15px;*/
/*  line-height: 20px;*/
/*  bottom: -20px;*/
/*  padding-left: 10px;*/
/*}*/

/*#co-authors-add-info .error-length {*/

/*}*/

/*#co-authors-add-info .error-required {*/

/*}*/

</style>