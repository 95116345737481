<template>
  <div class="ieo-sidebar-filter">
    <div class="ieo-sidebar-filter-item">
      <span class="ieo-sidebar-filter-title">Auctions</span>
      <ul class="tab__head">
        <li class="main-tabs-title-nav" @click="openStatus = !openStatus"
            :class="openStatus ? 'active' : ''">
          <div class="main-tabs-title-nav-icon">
            <svg id="Group_318" data-name="Group 318"
                 xmlns="http://www.w3.org/2000/svg"
                 width="24" height="24" viewBox="0 0 24 24">
              <path id="Path_306" data-name="Path 306" d="M0,0H24V24H0Z"
                    fill="none"></path>
              <path id="Path_307" data-name="Path 307"
                    d="M9,16.17,5.53,12.7a1,1,0,0,0-1.41,1.41L8.3,18.29a1,1,0,0,0,1.41,0L20.29,7.71A1,1,0,0,0,18.88,6.3Z"
                    fill="#fff"></path>
            </svg>
          </div>
          <span class="main-tabs-title-nav-name">Open</span>
          <input type="checkbox"
                 id="statusIsOpen"
                 v-model="openStatus"
                 name="statusIsOpen"
                 autocomplete="off"
                 class="filter-btn"
                 value="OPEN"
          >
        </li>
<!--        <li class="main-tabs-title-nav" @click="pendingStatus = !pendingStatus"-->
<!--            :class="pendingStatus ? 'active' : ''">-->
<!--          <div class="main-tabs-title-nav-icon">-->
<!--            <svg id="Group_318" data-name="Group 318"-->
<!--                 xmlns="http://www.w3.org/2000/svg"-->
<!--                 width="24" height="24" viewBox="0 0 24 24">-->
<!--              <path id="Path_306" data-name="Path 306" d="M0,0H24V24H0Z"-->
<!--                    fill="none"></path>-->
<!--              <path id="Path_307" data-name="Path 307"-->
<!--                    d="M9,16.17,5.53,12.7a1,1,0,0,0-1.41,1.41L8.3,18.29a1,1,0,0,0,1.41,0L20.29,7.71A1,1,0,0,0,18.88,6.3Z"-->
<!--                    fill="#fff"></path>-->
<!--            </svg>-->
<!--          </div>-->
<!--          <span class="main-tabs-title-nav-name">Pending</span>-->
<!--          <input type="checkbox"-->
<!--                 id="statusIsPending"-->
<!--                 v-model="pendingStatus"-->
<!--                 name="statusIsPending"-->
<!--                 autocomplete="off"-->
<!--                 class="filter-btn"-->
<!--                 value="NOT_STARTED">-->
<!--        </li>-->
        <li class="main-tabs-title-nav" @click="finishedStatus = !finishedStatus"
            :class="finishedStatus ? 'active' : ''">
          <div class="main-tabs-title-nav-icon">
            <svg id="Group_318" data-name="Group 318"
                 xmlns="http://www.w3.org/2000/svg"
                 width="24" height="24" viewBox="0 0 24 24">
              <path id="Path_306" data-name="Path 306" d="M0,0H24V24H0Z"
                    fill="none"></path>
              <path id="Path_307" data-name="Path 307"
                    d="M9,16.17,5.53,12.7a1,1,0,0,0-1.41,1.41L8.3,18.29a1,1,0,0,0,1.41,0L20.29,7.71A1,1,0,0,0,18.88,6.3Z"
                    fill="#fff"></path>
            </svg>
          </div>
          <span class="main-tabs-title-nav-name">Finished</span>
          <input type="checkbox"
                 id="statusIsFinished"
                 v-model="finishedStatus"
                 name="statusIsFinished"
                 autocomplete="off"
                 class="filter-btn"
                 value="SUCCESS">
        </li>
      </ul>
    </div>
    <div class="ieo-sidebar-filter-item" v-if="logged">
      <span class="ieo-sidebar-filter-title">MY BIDS</span>
      <ul class="tab__head">
        <li class="main-tabs-title-nav" @click="investmentsStatus = !investmentsStatus"
            :class="investmentsStatus ? 'active' : ''">
          <div class="main-tabs-title-nav-icon">
            <svg id="Group_318" data-name="Group 318"
                 xmlns="http://www.w3.org/2000/svg"
                 width="24" height="24" viewBox="0 0 24 24">
              <path id="Path_306" data-name="Path 306" d="M0,0H24V24H0Z"
                    fill="none"></path>
              <path id="Path_307" data-name="Path 307"
                    d="M9,16.17,5.53,12.7a1,1,0,0,0-1.41,1.41L8.3,18.29a1,1,0,0,0,1.41,0L20.29,7.71A1,1,0,0,0,18.88,6.3Z"
                    fill="#fff"></path>
            </svg>
          </div>
          <span class="main-tabs-title-nav-name">Investments</span>
          <input type="checkbox"
                 id="investments"
                 v-model="investmentsStatus"
                 name="statusIsOpen"
                 autocomplete="off"
                 class="filter-btn"
                 value="OPEN"
          >
        </li>

      </ul>
    </div>

    <div class="ieo-sidebar-filter-item">
      <span class="ieo-sidebar-filter-title">Funding volume</span>
      <div class="filter-price-wrap">

<!--        @blur.native="$v.totalPlannedFromModel.$touch()"-->
        <VueAutoNum
            v-model="totalPlannedFromModel"
            id="filter-price-from"
            class="filter-price-input"
            name="totalPlannedFrom"
            placeholder="From"
            :options="numericParams"
        ></VueAutoNum>
<!--        @blur.native="$v.totalPlannedToModel.$touch()"-->
        <VueAutoNum
            v-model="totalPlannedToModel"
            id="filter-price-to"
            class="filter-price-input"
            name="totalPlannedTo"
            placeholder="To"
            :options="numericParams"
        ></VueAutoNum>

<!--        <money-->
<!--            v-model="totalPlannedFromModel"-->
<!--            v-bind="money"-->
<!--            id="filter-price-from"-->
<!--            class="filter-price-input"-->
<!--            name="totalPlannedFrom"-->
<!--            @blur.native="$v.totalPlannedFromModel.$touch()"-->
<!--            @keyup.enter="applyData"-->
<!--        ></money>-->
<!--        <money-->
<!--            v-model="totalPlannedToModel"-->
<!--            v-bind="money"-->
<!--            id="filter-price-to"-->
<!--            class="filter-price-input"-->
<!--            name="totalPlannedTo"-->
<!--            @blur.native="$v.totalPlannedToModel.$touch()"-->
<!--            @keyup.enter="applyData"-->
<!--        ></money>-->
<!--        <input-->
<!--            type="number"-->
<!--            id="filter-price-from"-->
<!--            class="filter-price-input"-->
<!--            name="totalPlannedFrom"-->
<!--            v-model="totalPlannedFromModel"-->
<!--            placeholder="From..."-->
<!--            @blur="$v.totalPlannedFromModel.$touch()"-->
<!--            @keyup.enter="applyData"-->
<!--        >-->
<!--        <input-->
<!--            type="number"-->
<!--            id="filter-price-to"-->
<!--            class="filter-price-input"-->
<!--            name="totalPlannedTo"-->
<!--            v-model="totalPlannedToModel"-->
<!--            placeholder="To..."-->
<!--            @blur="$v.totalPlannedToModel.$touch()"-->
<!--            @keyup.enter="applyData"-->
<!--        >-->
      </div>
<!--      <div class="error-filter" v-if="$v.totalPlannedFromModel.$dirty && !$v.totalPlannedFromModel.between">-->
<!--        Must be between {{$v.totalPlannedFromModel.$params.between.min}} and {{$v.totalPlannedFromModel.$params.between.max}}-->
<!--      </div>-->
<!--      <div class="error-filter" v-else-if="$v.totalPlannedToModel.$dirty && !$v.totalPlannedToModel.between">-->
<!--        Must be between {{$v.totalPlannedToModel.$params.between.min}} and {{$v.totalPlannedToModel.$params.between.max}}-->
<!--      </div>-->
    </div>
<!--    <div class="ieo-sidebar-filter-item">-->
<!--      <span class="ieo-sidebar-filter-title">time period</span>-->
<!--      <div class="filter-time-period-wrap">-->
<!--        <div class="ieo-filter-time-period-input-wrap">-->
<!--          <date-picker-->
<!--              v-model="dateFromModel"-->
<!--              type="date"-->
<!--              placeholder="Start"-->
<!--              class="date-datepicker-finish ieo-filter-time-period-input"-->
<!--              :disabled-date="disabledBeforStartPeriod"-->
<!--              :input-attr="{readonly: true}"-->
<!--              @keyup.enter="applyData"-->
<!--          ></date-picker>-->
<!--        </div>-->
<!--        <div class="ieo-filter-time-period-input-wrap">-->
<!--          <date-picker-->
<!--              v-model="dateToModel"-->
<!--              type="date"-->
<!--              placeholder="Finish"-->
<!--              class="date-datepicker-finish ieo-filter-time-period-input"-->
<!--              :disabled-date="disabledToStartPeriod"-->
<!--              :input-attr="{readonly: true}"-->
<!--              @keyup.enter="applyData"-->
<!--          ></date-picker>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="ieo-sidebar-filter-item">
      <span class="ieo-sidebar-filter-title">genres</span>
      <vuescroll :ops="ops">
        <ul class="ieo-sidebar-genre-list">
          <li
              v-for="(genre, index) in songGenre"
              :key="index"
              class="custom-checkbox-group">
            <div class="checkbox-wrap">
              <input
                  type="checkbox"
                  :id="`checkbox_${index}`"
                  v-model="selectGenres"
                  :value="genre"
                  class="input-checkbox">
              <label :for="`checkbox_${index}`" class="label-checkbox"><span>{{genre}}</span></label>
            </div>
          </li>
        </ul>
      </vuescroll>
    </div>
    <div class="ieo-sidebar-filter-item registration-field-wrap">
      <span class="ieo-sidebar-filter-title">Country</span>
      <CountrySelect class="sidebar-filter__select"
                     :options="searchedCountries"
                     :placeholder="'Search'"
                     :search="searchSelect"
                     :search-for-name="countryModel"
                     @changed="setCountry"
                     @searchCheck="searchCheck"
                     name="countryId"
                     ref="AuctionFilterCountrySelect"
                     :identifier="'auction-filter'"
                     data-filter-group
      />
    </div>
    <button @click="applyData" type="button" class="btn btn-hover filter-apply-btn">Apply</button>
    <div @click="resetData" class="btn btn-hover btn-transparent btn-reset-filter">Reset</div>
  </div>

</template>

<script>
import vuescroll from "vuescroll";
import CountrySelect from "../inputs/CountrySelect";
// import DatePicker from 'vue2-datepicker';
// import { Money } from "v-money"
// import {between} from "vuelidate/lib/validators";

export default {
  name: "PrimaryMarketFilter",
  props: {
    logged: Boolean,
  },
  data() {
    return {
      // status: '',
      openStatus: false,
      pendingStatus: false,
      finishedStatus: false,
      investmentsStatus: false,
      dateFromModel: '',
      dateToModel: '',
      totalPlannedFromModel: null,
      totalPlannedToModel: null,
      dateToDataModel: '',
      dateFromDataModel: '',
      songGenre: [],
      selectGenres: [],
      countriesList: [],
      countryModel: '',
      focus: false,
      searchSelect: '',
      numericParams: {
        // required for our conditions
        modifyValueOnWheel: false, // turn off wheel mode
        digitGroupSeparator: '.',
        decimalCharacter: ',',
        decimalPlaces: 0,
        currencySymbolPlacement: 'p', // prefix || suffix
        currencySymbol: '€\u00a0',
        overrideMinMaxLimits: null,
        minimumValue: '0',
        maximumValue: '1000000000',
      },
      // money: {
      //   decimal: ',',
      //   thousands: '.',
      //   precision: 0,
      //   masked: false
      // },
      dataObj: {
        dateFrom: '',
        dateTo: '',
        ieoType: [],
        investments: false,
        statuses: [],
        genres: [],
        country: '',
        fundingVolumeFrom: null,
        fundingVolumeTo: null
      },
      ops: {
        scrollPanel: {
          scrollX: false,
        },
        rail: {
          background: 'rgba(234, 234, 234)',
          opacity: 0.5,
          size: '6px',
          gutterOfEnds: null,
          keepShow: false,
        },
        bar: {
          background: '#FF0090',
          minSize: 0.3,
          size: '6px',
        }
      },
    }
  },
  // validations() {
  //   return {
  //     totalPlannedFromModel: {
  //       between: between(0, 1000000000),
  //     },
  //     totalPlannedToModel: {
  //       between: between(0, 1000000000),
  //     },
  //   }
  // },
  computed: {
    searchedCountries() {
      return this.countriesList.filter(option => option.name.toLowerCase().includes(this.searchSelect.toLowerCase()))
    },
  },
  methods: {
    resetData() {
      this.dateToModel = ''
      this.dateFromModel = ''
      this.totalPlannedToModel = null
      this.totalPlannedFromModel = null
      this.openStatus = false
      this.pendingStatus = false
      this.finishedStatus = false
      this.investmentsStatus = false
      this.selectGenres = []
      this.countryModel = ''
      this.$refs.AuctionFilterCountrySelect.clearCountry()

      this.applyData()
    },
    applyData() {
      this.dataObj.dateFrom = this.dateFromDataModel
      this.dataObj.dateTo = this.dateToDataModel
      this.dataObj.genres = this.selectGenres
      this.dataObj.country = this.countryModel
      this.dataObj.fundingVolumeFrom = this.totalPlannedFromModel
      this.dataObj.fundingVolumeTo = this.totalPlannedToModel

      // if (this.$v.$invalid) {
      //   this.$v.$touch()
      // } else {
      //   this.dataObj.dateFrom = this.dateFromDataModel
      //   this.dataObj.dateTo = this.dateToDataModel
      //   this.dataObj.genres = this.selectGenres
      //   this.dataObj.country = this.countryModel
      //   this.dataObj.fundingVolumeFrom = this.totalPlannedFromModel
      //   this.dataObj.fundingVolumeTo = this.totalPlannedToModel
      // }
    },
    toggleStatus(status, val) {
      if (status) {
        this.dataObj.statuses.push(val)
      } else {
        this.dataObj.statuses = this.dataObj.statuses.filter(el => el !== val)
      }
    },
    disabledToStartPeriod(date) {
      let today = new Date(this.dateFromModel)
      return date < today;
    },
    disabledBeforStartPeriod(date) {
      let today = new Date(this.dateToModel)
      return date > today
    },
    getGenres() {
      this.$load(async () => {
        const res = (await this.$api.createSong.getGenres()).data.body.elements;
        this.songGenre = res;
      })
    },
    searchCheck(data) {
      this.searchSelect = data
    },
    setCountry(data) {
      this.countryModel = data.name
    },
    countryClickOutside(event) {
      if (!event.target.closest('.general_select')) {
        this.$refs.AuctionFilterCountrySelect.closeList()
      }
    },
  },
  watch: {
    dataObj: {
      deep: true,
      handler(newVal) {
        this.$emit('filterData', newVal)
      }
    },
    openStatus() {
      this.toggleStatus(this.openStatus, 'OPEN')
    },
    pendingStatus() {
      this.toggleStatus(this.pendingStatus, 'NOT_STARTED')
    },
    finishedStatus() {
      this.toggleStatus(this.finishedStatus, 'SUCCESS')
    },
    investmentsStatus(newVal) {
      this.dataObj.investments = !!newVal;
    },
    'dateFromModel'(newVal) {
      let date = new Date(newVal)
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      this.dateFromDataModel = year + '-' + month + '-' + dt;
      if (!newVal) {
        this.dateFromDataModel = ''
        this.dateFromModel = ''
      }
    },
    'dateToModel'(newVal) {
      let date = new Date(newVal)
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      this.dateToDataModel = year + '-' + month + '-' + dt;
      if (!newVal) {
        this.dateToModel = ''
        this.dateToDataModel = ''
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.countryClickOutside)
    setTimeout(() => {
      this.getGenres()
      this.$load(async () => {
        this.countriesList = (await this.$api.countriesLists.getCountries()).data.body.elements
      })
    }, 500)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.countryClickOutside)
  },
  components: {
    // DatePicker,
    // Money,
    vuescroll,
    CountrySelect,
  }
}
</script>

<style scoped>

.error-filter {
  color: red;
  font-size: 15px;
}

.ieo-sidebar-genre-list {
  max-height: 300px;
}

.custom-checkbox-group .label-checkbox {
  cursor: pointer;
  position: relative;
  width: 24px;
  height: 24px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease;
  border: 1px solid #707070;
  border-radius: 1px;
}

.custom-checkbox-group .input-checkbox {
  display: none;
}

.custom-checkbox-group .label-checkbox span {
  position: absolute;
  left: 40px;
  width: 170px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #5A5A5A;
}

.custom-checkbox-group .label-checkbox:after {
  content: '';
  background: #FF0090;
  width: 16px;
  height: 16px;
  border-radius: 1px;
  opacity: 0;
  transition: all .3s ease;
}

.custom-checkbox-group .input-checkbox:checked + .label-checkbox:after {
  opacity: 1;
}

</style>
