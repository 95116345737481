import {userService} from '../services';
import LocalStorageService from "../services/LocalStorageService";
import router from '../router'

// LocalstorageService
const localStorageService = LocalStorageService.getService();

const user = JSON.parse(localStorage.getItem('user'));

const initialState = user
    ? {status: {loggedIn: true}}
    : {status: {}, user: null, error: null};

export const authentication = {
    namespaced: true,
    state: initialState,
    getters: {
      isLogged(state) {
          return state.status.loggedIn
      }
    },
    actions: {
        login({dispatch, commit}, {email, password}) {
            if(!user) {
                router.push({name: 'Login'}).catch(() => {});
            }
            const feVersion = localStorage.getItem('fe-version')
            localStorage.clear()
            localStorage.setItem('fe-version', feVersion)
            // commit('loginRequest', {username});
            userService.login(email, password)
                .then(
                    user => {
                        commit('loginSuccess', user);
                        router.push({name: 'PrimaryMarket'}).catch(() => {});
                    },
                    error => {
                        commit('loginFailure', error.response.data.errors[0].message);
                        dispatch('alert/error', error.response.data.errors[0].message, {root: true});
                    }
                );
        },
        clearState({commit}) {
            commit('logout');
        },
        logout({commit}) {
            userService.logout();
            commit('logout');
            router.push({name: 'Login'}).catch(() => {});
        }
    },
    mutations: {
        loginRequest(state, user) {
            state.status = {loggedIn: true};
            state.user = user;
        },
        loginSuccess(state, user) {
            state.status = {loggedIn: true};
            state.user = user;
        },
        loginFailure(state) {
            state.status = {loggedIn: false};
            state.user = null;
        },
        logout(state) {
            state.status = {loggedIn: false};
            state.user = null;
        }
    },

}
