<template>
  <tr :tabindex="index + 1">
    <th class="token">
      <span class="text text--grey" v-text="ask ? 'Buyer: ' : 'Seller: '"></span>
      <span class="text text--black" v-text="contract.investorName"></span>
    </th>
    <td class="date">
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="2.5" width="14" height="12" rx="2.5" stroke="#C6C6C6"/>
        <path d="M1.5 7.5H13.5" stroke="#C6C6C6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4 1V5" stroke="#C6C6C6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11 1V5" stroke="#C6C6C6" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <span class="text text--black" v-text="createdAtLocalTime"></span>
    </td>
    <td class="amount">
      <span class="text text--grey">RR</span>
      <span class="text text--black" v-text="amountForShow"></span>
    </td>
    <td class="price_per_one">
      <span class="text text--grey">€</span>
      <span class="text text--black" v-text="pricePerOneForShow"></span>
    </td>
    <td class="price">
      <span class="text text--grey">€</span>
      <span class="text text--black" v-text="priceForShow"></span>
    </td>
    <td class="buttons">
      <a :href="contract.contractLink" target="_blank" class="print-btn">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
              d="M14.6562 5.03125H14.2188V3.34375C14.2188 2.05141 13.1673 1 11.875 1H6.125C4.83266 1 3.78125 2.05141 3.78125 3.34375V5.03125H3.34375C2.05141 5.03125 1 6.08266 1 7.375V11.125C1 12.4173 2.05141 13.4688 3.34375 13.4688H3.78125V15.5938C3.78125 16.3692 4.41209 17 5.1875 17H12.8125C13.5879 17 14.2188 16.3692 14.2188 15.5938V13.4688H14.6562C15.9486 13.4688 17 12.4173 17 11.125V7.375C17 6.08266 15.9486 5.03125 14.6562 5.03125ZM4.71875 3.34375C4.71875 2.56834 5.34959 1.9375 6.125 1.9375H11.875C12.6504 1.9375 13.2812 2.56834 13.2812 3.34375V5.03125H4.71875V3.34375ZM13.2812 15.5938C13.2812 15.8522 13.071 16.0625 12.8125 16.0625H5.1875C4.92903 16.0625 4.71875 15.8522 4.71875 15.5938V9.96875H13.2812V15.5938ZM16.0625 11.125C16.0625 11.9004 15.4317 12.5312 14.6562 12.5312H14.2188V9.96875H14.5C14.7589 9.96875 14.9688 9.75887 14.9688 9.5C14.9688 9.24113 14.7589 9.03125 14.5 9.03125H3.5C3.24113 9.03125 3.03125 9.24113 3.03125 9.5C3.03125 9.75887 3.24113 9.96875 3.5 9.96875H3.78125V12.5312H3.34375C2.56834 12.5312 1.9375 11.9004 1.9375 11.125V7.375C1.9375 6.59959 2.56834 5.96875 3.34375 5.96875H14.6562C15.4317 5.96875 16.0625 6.59959 16.0625 7.375V11.125Z"
               stroke="#FF0090" stroke-width="0.3"/>
          <path
              d="M10.25 12.918H7.75C7.49112 12.918 7.28125 13.1278 7.28125 13.3867C7.28125 13.6456 7.49112 13.8555 7.75 13.8555H10.25C10.5089 13.8555 10.7188 13.6456 10.7188 13.3867C10.7188 13.1278 10.5089 12.918 10.25 12.918Z"
               stroke="#FF0090" stroke-width="0.3"/>
        </svg>
      </a>
      <a :href="contract.contractLink" target="_blank" class="load-btn">
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 6L10 10M10 10L6 6M10 10L10 1" stroke="#FF0090" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"/>
          <path
              d="M16.3409 11.75C15.9267 11.75 15.5909 11.4142 15.5909 11C15.5909 10.5858 15.9267 10.25 16.3409 10.25L16.3409 11.75ZM3.65909 10.25C4.0733 10.25 4.40909 10.5858 4.40909 11C4.40909 11.4142 4.0733 11.75 3.65909 11.75L3.65909 10.25ZM3 14.25L17 14.25L17 15.75L3 15.75L3 14.25ZM17 11.75L16.3409 11.75L16.3409 10.25L17 10.25L17 11.75ZM3.65909 11.75L3 11.75L3 10.25L3.65909 10.25L3.65909 11.75ZM1.75 13C1.75 13.6904 2.30964 14.25 3 14.25L3 15.75C1.48122 15.75 0.25 14.5188 0.25 13L1.75 13ZM0.25 13C0.25 11.4812 1.48122 10.25 3 10.25L3 11.75C2.30964 11.75 1.75 12.3096 1.75 13L0.25 13ZM18.25 13C18.25 12.3096 17.6904 11.75 17 11.75L17 10.25C18.5188 10.25 19.75 11.4812 19.75 13L18.25 13ZM19.75 13C19.75 14.5188 18.5188 15.75 17 15.75L17 14.25C17.6904 14.25 18.25 13.6904 18.25 13L19.75 13Z"
              fill="#FF0090"/>
        </svg>
      </a>
    </td>
  </tr>
</template>

<script>

import moment from "moment";
import numberConverter from "@/mixins/numberConverter";
import numberCurrencyConverter from "@/mixins/numberCurrencyConverter";

export default {
  name: "AgreementsSMContractItem",
  props: {
    contract: {
      type: Object,
      default: () => {}
    },
    ask: Boolean,
    index: Number,
    openStatus: Boolean,
  },
  mixins: [numberConverter, numberCurrencyConverter],
  computed: {
    createdAtLocalTime() {
      let timeUtc = moment.utc(this.contract.createdAt).local()
      return timeUtc ? moment(String(timeUtc)).format('yyyy-MM-DD HH:mm') : this.contract.createdAt
    },
    pricePerOneForShow() {
      const perOne = this.contract.totalPrice / this.contract.iprAmount
      return this.numberFormattingDecimal(perOne)
    },
    priceForShow() {
      return this.numberFormattingDecimal(this.contract.totalPrice)
    },
    amountForShow() {
      return this.numberFormattingInteger(this.contract.iprAmount)
    },
  },
}
</script>

<style scoped>

</style>