<template>
  <div class="song-investor-item"
       v-if="investor"
       :class="user.userNameId === investor.userName ? 'song-investor-item-border' : ''"
  >
<!--    :class="user.userName === investor.firstName ? 'song-investor-item-border' : ''"-->
    <div class="song-investor-item-user song-investor-item-value">
      <v-tooltip bottom max-width="300">
        <template v-slot:activator="{on}">
          <button v-on="on">
            <span class="song-investor-name" v-text="investorCutName"></span>
          </button>
        </template>
        <span v-text="investorFullName"></span>
      </v-tooltip>
    </div>
    <div class="song-investor-item-bid song-investor-item-value">
      <span v-text="'€' + investorBidIpr"></span>
    </div>
    <div class="song-investor-item-ipr song-investor-item-value">
      <span v-text="investorAmount"></span>
    </div>
    <div class="song-investor-item-total song-investor-item-value">
      <span v-text="investorTotal"></span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import login from "../../api/login";
import numberFormattingDecimal from "../../mixins/numberCurrencyConverter";
import numberFormattingInteger from "../../mixins/numberConverter";

export default {
  name: "SongIeoBids",
  props: {
    investor: {
      type: Object,
      default: () => {}
    },
    currentUser: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      investorFullName: '',
      investorCutName: '',
    }
  },
  mixins: [numberFormattingDecimal, numberFormattingInteger],
  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
    investorBidIpr() {
      return this.numberFormattingDecimal(this.investor.bidPerIpr)
    },
    investorTotal() {
      return this.numberFormattingDecimal(this.investor.total)
    },
    investorAmount() {
      return this.numberFormattingInteger(this.investor.amount)
    },
  },
  methods: {
    checkFirstName() {
      let firstName = this.investor.firstName
      let lastName = this.investor.lastName

      if (firstName.length > 8) {
        if (lastName) {
          this.investorCutName = firstName.slice(0, 8) + '...' + ' ' + lastName.slice(0, 1) + '.'
        } else {
          this.investorCutName = firstName.slice(0, 8)
        }
      } else if (lastName) {
        this.investorCutName = firstName + ' ' + lastName.slice(0, 1) + '.'
      } else {
        this.investorCutName = firstName
      }
    },
    createFullName() {
      this.investorFullName = this.investor.firstName + ' ' + this.investor.lastName
    },
  },
  mounted() {
    this.checkFirstName()
    this.createFullName()
  }
}
</script>

<style scoped>

.song-investor-name {
  text-transform: capitalize;
}

</style>