<template>
  <div id="my-content" ref="top">
    <Header/>
    <main id="root">
      <div class="profile">
        <Sidebar/>
        <div class="profile-content create-song-page">
          <h1 class="title page-title separator">Add new song</h1>
          <form class="song-create" @submit.prevent="sendFormData">
            <div class="add-song-wrap">
              <div class="add-song-photo-wrap">

                <div class="add-song__default-background"
                     v-if="songImage === '' "
                     @click.prevent.stop="changeImagePopup"
                     id="id_album_cover"
                >
                  <span class="add-song__default-background-item">Album cover…</span>

                  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 38C29.4934 38 38 29.4934 38 19C38 8.50659 29.4934 0 19 0C8.50659 0 0 8.50659 0 19C0 29.4934 8.50659 38 19 38Z" fill="#7AC385"/>
                    <path d="M20.984 25.998H17.656V21.032H12.716V17.756H17.656V12.764H20.984V17.756H25.95V21.032H20.984V25.998Z" fill="white"/>
                  </svg>

                  <p class="add-song__default-background-item">
                    <svg width="22" height="28" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.338 17.1835C15.338 17.7886 14.8475 18.2791 14.2424 18.2791H12.0513V20.4702C12.0513 21.0753 11.5607 21.5658 10.9557 21.5658C10.3506 21.5658 9.86011 21.0753 9.86011 20.4702V18.2791H7.66898C7.06391 18.2791 6.57341 17.7886 6.57341 17.1835C6.57341 16.5785 7.06391 16.088 7.66898 16.088H9.86011V13.8968C9.86011 13.2918 10.3506 12.8012 10.9557 12.8012C11.5607 12.8012 12.0513 13.2918 12.0513 13.8968V16.088H14.2424C14.8475 16.088 15.338 16.5785 15.338 17.1835ZM21.9114 8.41898V23.7569C21.9114 25.5723 20.44 27.0436 18.6247 27.0436H3.2867C1.47135 27.0436 0 25.5723 0 23.7569V4.0367C0 2.22135 1.47135 0.75 3.2867 0.75H14.2424L21.9114 8.41898ZM14.2424 8.41898H18.8128L14.2424 3.84854V8.41898ZM19.7202 23.7569V10.6101H12.0513V2.94114H3.2867C2.89202 2.94114 2.63183 3.14217 2.51214 3.26214C2.39245 3.3821 2.19114 3.64203 2.19114 4.0367V23.7569C2.19114 24.1516 2.39217 24.4118 2.51214 24.5315C2.6321 24.6512 2.89202 24.8525 3.2867 24.8525H18.6247C19.0193 24.8525 19.2795 24.6515 19.3992 24.5315C19.5189 24.4115 19.7202 24.1516 19.7202 23.7569Z" fill="#959595"/>
                    </svg>
                    <span>.jpeg, .png, .gif</span>
                  </p>

                </div>

                <img v-else :src="songImage"
                     alt="song-image"
                     class="add-song-photo"
                     @click.prevent.stop="changeImagePopup"
                >

<!--                <img v-if="songImage === '' "-->
<!--                     src="../../assets/img/default-popup-image.png"-->
<!--                     alt="song-image"-->
<!--                     class="add-song-photo"-->
<!--                >-->

<!--                <div class="profile-form-user-photo-overlay">-->
<!--                  <svg v-if="!songImage" xmlns="http://www.w3.org/2000/svg" width="68" height="68"-->
<!--                       viewBox="0 0 68 68">-->
<!--                    <path id="button"-->
<!--                          d="M34,0A34,34,0,1,0,68,34,34.038,34.038,0,0,0,34,0ZM48.875,36.833H36.833V48.875a2.833,2.833,0,1,1-5.666,0V36.833H19.125a2.833,2.833,0,0,1,0-5.666H31.167V19.125a2.833,2.833,0,1,1,5.666,0V31.167H48.875a2.833,2.833,0,0,1,0,5.666Zm0,0"-->
<!--                          fill="#7ac385"></path>-->
<!--                  </svg>-->
<!--                  <div class="jq-file" id="id_album_cover-styler">-->
<!--                    <div class="jq-file__name">No file selected</div>-->
<!--                    <div class="jq-file__browse">Browse...</div>-->
<!--&lt;!&ndash;                    <input @change="setImg" name="fileImage" type="file" id="id_album_cover"&ndash;&gt;-->
<!--&lt;!&ndash;                           accept="image/*">&ndash;&gt;-->
<!--                    <span @click.stop="changeImagePopup" name="fileAvatar" id="id_album_cover" class="change-image"></span>-->
<!--                  </div>-->
<!--                </div>-->
              </div>

              <div class="add-song-photo-fields-wrap">
                <div class="add-song-photo-fields-item">
                  <div class="add-song-photo-icon">
                    <img src="../../assets/img/file.svg" alt="add-file-icon">
                  </div>
                  <svg class="add-song-photo__upload-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_8851_2507)">
                      <path d="M16 16L12 12L8 16" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12 12V21" stroke="#FE0190" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M20.3904 18.39C21.3658 17.8583 22.1363 17.0169 22.5803 15.9986C23.0244 14.9804 23.1167 13.8432 22.8427 12.7667C22.5686 11.6901 21.9439 10.7355 21.0671 10.0534C20.1903 9.37138 19.1113 9.00073 18.0004 9H16.7404C16.4378 7.82924 15.8736 6.74233 15.0904 5.82099C14.3072 4.89965 13.3253 4.16785 12.2185 3.68061C11.1118 3.19336 9.909 2.96336 8.70057 3.00788C7.49213 3.05241 6.30952 3.3703 5.24163 3.93766C4.17374 4.50503 3.24836 5.3071 2.53507 6.28358C1.82178 7.26006 1.33914 8.38554 1.12343 9.57539C0.907718 10.7653 0.964554 11.9885 1.28966 13.1533C1.61477 14.318 2.19969 15.3939 3.00045 16.3" stroke="#FE0190" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M16 16L12 12L8 16" stroke="#FE0190" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_8851_2507">
                        <rect width="24" height="24" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                  <input type="text" class="song-input" v-model="fakeInputData">
                  <v-file-input
                      ref="myFiles"
                      type="file"
                      @change="downloadSong"
                      name="fileSong"
                      id="id_song_file"
                      placeholder="Choose song file*"
                      accept=".mp3, .wav, .ogg"
                      @blur="$v.fakeInputData.$touch()"
                  >
                  </v-file-input>
                  <span class="error-length error-length-song_file"
                        v-if="$v.fakeInputData.$dirty && !$v.fakeInputData.required"
                  >Field is required</span>
                  <div class="add-song-input-file-type-files">
                    <span>.mp3 .wav .ogg</span>
                  </div>
                </div>

                <div class="add-song-photo-fields-item">
                  <input type="text" name="title" id="id_title" class="ntSaveForms" placeholder="Song Title*"
                         v-model="songTitle"
                         @input=" setFormData"
                         @blur="$v.songTitle.$touch()"
                  >
                  <span class="error-length"
                        v-if="$v.songTitle.$dirty && !$v.songTitle.required"
                  >Field is required</span>
                  <span class="error-length" v-else-if="!$v.songTitle.maxLength">
                      The maximum length of the description is {{ $v.songTitle.$params.maxLength.max }} characters
                    </span>
                </div>
                <div class="add-song-photo-fields-item">
<!--                  <div class="jq-selectbox jqselect select" id="id_genre-styler"-->
<!--                       @focusout="$v.fakeGenre.$touch()"-->
<!--                  >-->
<!--                    <select class="genre-select" v-model="fakeGenre"></select>-->
<!--                    <CustomSelect-->
<!--                        :options="songGenres"-->
<!--                        :default="'Choose the song genre*'"-->
<!--                        class="select"-->
<!--                        @changed="setGenre"-->
<!--                    />-->
<!--                    <span class="error-length"-->
<!--                          v-if="$v.fakeGenre.$dirty && !$v.fakeGenre.required"-->
<!--                    >Field is required</span>-->
<!--                  </div>-->

                  <GeneralSelect class=""
                                 :identifier="'ieo-creating__genres'"
                                 :placeholder="'Genre'"
                                 :options="songGenres"
                                 @changed="setGenre"
                  />

                  <span class="error-length"
                        v-if="$v.genreModel.$dirty && !$v.genreModel.required"
                  >Field is required</span>

                </div>
                  <div class="add-song-photo-fields-item">
                    <div class="add-song-parts_owner-input-wrapper">
                      <VueAutoNum
                          v-model="songPartsOwned"
                          class="ntSaveForms"
                          name="percentOfSong"
                          id="id_owner_parts"
                          placeholder="Parts owned (%)"
                          @blur.native="$v.songPartsOwned.$touch()"
                          :options="numParams"
                      ></VueAutoNum>
<!--                      <money-->
<!--                          v-model="songPartsOwned"-->
<!--                          v-bind="money"-->
<!--                          class="ntSaveForms"-->
<!--                          name="percentOfSong"-->
<!--                          id="id_owner_parts" @blur.native="$v.songPartsOwned.$touch()"-->
<!--                      ></money>-->
<!--                      <input type="number" step="0.001" name="percentOfSong" id="id_owner_parts" class="ntSaveForms" placeholder="Ownership (%)"-->
<!--                             v-model="songPartsOwned"-->
<!--                             @blur="$v.songPartsOwned.$touch()"-->
<!--                      >-->
                      <div class="add-song-parts_owner-input-item" @click.prevent.stop>
                        <v-tooltip bottom max-width="300">
                          <template v-slot:activator="{on}">
                            <button
                                v-on="on"
                            >
                              <svg class="tooltip-svg" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 0C3.35812 0 0 3.35812 0 7.5C0 11.6419 3.35812 15 7.5 15C11.6419 15 15 11.6419 15 7.5C15 3.35812 11.6419 0 7.5 0ZM6.5625 6.5625C6.5625 6.04473 6.98223 5.625 7.5 5.625C8.01777 5.625 8.4375 6.04473 8.4375 6.5625V11.25C8.4375 11.7678 8.01777 12.1875 7.5 12.1875C6.98223 12.1875 6.5625 11.7678 6.5625 11.25V6.5625ZM7.5 4.7025C6.9821 4.70237 6.56237 4.28243 6.5625 3.76453C6.56263 3.24664 6.98257 2.8269 7.50047 2.82703C8.01836 2.82716 8.4381 3.2471 8.43797 3.765C8.43784 4.2829 8.0179 4.70263 7.5 4.7025Z" fill="black"/>
                              </svg>
                            </button>
                          </template>
                          <span v-html="'Indicate the percentage of your ownership in composition and master copyrights in the song.'"></span>
                        </v-tooltip>
                      </div>
                    </div>
                    <span class="error-length"
                          v-if="$v.songPartsOwned.$dirty && !$v.songPartsOwned.required"
                    >Field is required</span>
                    <span class="error-length" v-else-if="$v.songPartsOwned.$dirty && !$v.songPartsOwned.between">
                      The number must be between {{ $v.songPartsOwned.$params.between.min }} and {{ $v.songPartsOwned.$params.between.max }} and max length after point is three
                    </span>
                    </div>
              </div>
            </div>

            <div class="profile-section" v-if="isCompany">
              <div class="profile-subtitle-wrap">
                <h2 class="profile-subtitle">author</h2>
              </div>

              <GeneralSelect class=""
                             :identifier="'ieo-creating__associated-artists'"
                             :placeholder="'Author’s Name'"
                             :options="associatedArtists"
                             @changed="setAssociatedArtist"
              />

              <span class="error-length"
                    v-if="$v.associatedArtistsModel.$dirty && !$v.associatedArtistsModel.required"
              >Field is required</span>
            </div>

            <div id="formset2" data-formset-prefix="co_authors">
              <div class="registration-form-photos-wrap">
                <div class="profile-subtitle-wrap">

                  <h2 class="profile-subtitle" v-if="isCompany">Co-authors</h2>
                  <h2 class="profile-subtitle" v-else>Additional Information</h2>

                  <button type="button"
                          value="Add another"
                          class="add-author-btn desktop"
                          @click="addAField">
                    <span class="add-author-btn__text" v-if="isCompany">Add co-author</span>
                    <span class="add-author-btn__text" v-else>Add co-author/co-owner</span>

                    <span class="add-author-btn__plus">+</span>
                  </button>

                </div>
              </div>

<!--              <div class="registration-form-item formset-form-item">-->
              <div class="registration-form-item formset-container"
                   ref="coAuthorsAddInfo"
                   id="coAuthorsAddInfo"
              >
                <div class="formset-body">
                  <AdditionalInformation
                      @deleteItem="deleteField(index)"
                      v-for="(item,index) in coAuthorsFieldsCount"
                      :key="item" :count="index"
                      @handOverCoAuthor.once="addCoAuthor"
                      @checkValidCoAuthor.once="coAuthorValid"
                  />
                </div>

                <button type="button" value="Add another" class="add-author-btn mobile" data-formset-add=""
                        @click="addAField">

                  <span v-if="isCompany">Add co-author</span>
                  <span v-else>Add co-author/co-owner</span>

                  <svg xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68">
                    <path id="button"
                          d="M34,0A34,34,0,1,0,68,34,34.038,34.038,0,0,0,34,0ZM48.875,36.833H36.833V48.875a2.833,2.833,0,1,1-5.666,0V36.833H19.125a2.833,2.833,0,0,1,0-5.666H31.167V19.125a2.833,2.833,0,1,1,5.666,0V31.167H48.875a2.833,2.833,0,0,1,0,5.666Zm0,0"
                          fill="#7ac385"></path>
                  </svg>
                </button>

              </div>
            </div>

            <div class="profile-section">
              <div class="profile-subtitle-wrap">
                <h2 class="profile-subtitle">Add Video</h2>
              </div>

              <div class="create-ieo__field-wrap">
                <input type="text"
                       placeholder="Youtube Link"
                       id="id-create-ieo__youtube"
                       name="videoLink"
                       v-model="songYouTubeLink"
                       @input="setFormData"
                >
              </div>

              <span class="error-length"
                    v-if="!$v.songYouTubeLink.maxLength"
              >The maximum length of link is {{ $v.songYouTubeLink.$params.maxLength.max }} characters</span>

            </div>

            <div class="profile-section">

              <div class="profile-subtitle-wrap">
                <h2 class="profile-subtitle">general description</h2>
              </div>

              <div class="profile-textarea-wrap">
                  <textarea class="profile-textarea"
                            name="description"
                            id="id_description"
                            placeholder="A few words about your song…"
                            v-model="description"
                            @input="setFormData"
                            @blur="$v.description.$touch()"
                  >
                  </textarea>
              </div>

              <span class="error-length"
                    v-if="!$v.description.maxLength"
              >The maximum length of the description is {{ $v.description.$params.maxLength.max }} characters</span>

            </div>

            <div class="profile-section create-song-checkbox-wrap">

              <div class="profile-checkbox-item">
                <GeneralCustomCheckBox :checkBoxId="'create_song-agree'"
                                       @checked="checkedChangeFirst"
                                       ref="createSongCheckboxFirst"
                />
                <p class="profile-checkbox-description">
                  I acknowledge and agree that I am duly and validly authorized, with full power to
                  act on behalf of any third party, including, but not limited to, any co-authors, band members, or
                  others, who may have any right, title or interest in, or other relationship with, the music
                  composition I will hereby upload to the DjookyX.
                  <span class="info hint hint--right"
                        data-hint="All co-writers must have given their consent for the song to be submitted to (djookyx.com). You must retain evidence of their consent for any possible future disputes."
                  >
                    <svg height="512pt" viewBox="0 0 512 512" width="512pt"
                         xmlns="http://www.w3.org/2000/svg"><path
                        d="m277.332031 128c0 11.78125-9.550781 21.332031-21.332031 21.332031s-21.332031-9.550781-21.332031-21.332031 9.550781-21.332031 21.332031-21.332031 21.332031 9.550781 21.332031 21.332031zm0 0"></path><path
                        d="m256 405.332031c-8.832031 0-16-7.167969-16-16v-165.332031h-21.332031c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h37.332031c8.832031 0 16 7.167969 16 16v181.332031c0 8.832031-7.167969 16-16 16zm0 0"></path><path
                        d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path><path
                        d="m304 405.332031h-96c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h96c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"></path></svg>
                </span>
                </p>
              </div>

<!--              support for ukraine checkbox-->

<!--              <div class="profile-checkbox-item">-->
<!--                <GeneralCustomCheckBox :checkBoxId="'create_song-ukraine'"-->
<!--                                       @checked="checkedChangeSecond"-->
<!--                                       ref="createSongCheckboxSecond"-->
<!--                />-->
<!--                <p class="profile-checkbox-description">-->
<!--                  I hereby confirm and acknowledge that all funds indicated as Support for Ukraine I will transfer-->
<!--                  to the Ukrainian humanitarian aid which bank details Djooky provided to me.-->
<!--                  <span class="info hint hint&#45;&#45;right"-->
<!--                        data-hint="All co-writers must have given their consent for the song to be submitted to (djookyx.com). You must retain evidence of their consent for any possible future disputes."-->
<!--                  >-->
<!--                    <svg height="512pt" viewBox="0 0 512 512" width="512pt"-->
<!--                         xmlns="http://www.w3.org/2000/svg"><path-->
<!--                        d="m277.332031 128c0 11.78125-9.550781 21.332031-21.332031 21.332031s-21.332031-9.550781-21.332031-21.332031 9.550781-21.332031 21.332031-21.332031 21.332031 9.550781 21.332031 21.332031zm0 0"></path><path-->
<!--                        d="m256 405.332031c-8.832031 0-16-7.167969-16-16v-165.332031h-21.332031c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h37.332031c8.832031 0 16 7.167969 16 16v181.332031c0 8.832031-7.167969 16-16 16zm0 0"></path><path-->
<!--                        d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path><path-->
<!--                        d="m304 405.332031h-96c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h96c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"></path></svg>-->
<!--                </span>-->
<!--                </p>-->
<!--              </div>-->

<!--            end  support for ukraine checkbox-->

            </div>

            <div class="song-create-btn-wrap">
              <button type="submit"
                      class="btn btn-block registr-btn ntSaveFormsSubmit"
                      :class="{'disabled-btn': checkForDisable}"
                      :disabled="checkForDisable"
              >Done</button>
            </div>
          </form>
        </div>
      </div>
      <ServerErrorPopup ref="serverErrorPopup" :errorMessage="error.errorMessage" :errorTitle="error.errorTitle"/>
      <SongNotAuthorizedPopup
          ref="errorPopup"
          @confirmAction="songDownloadError = false"
          :executor="songExecutor"
          :title="songTitle"
      />
      <DisablePopup ref="DisablePopup"/>
      <CropImagePopup ref="cropImagePopup" :defaultImage="'/img/default-song-image.951ee8b2.jpg'" :imageSrc="image.src" @cropImage="cropImage"/>
    </main>
    <Footer/>
  </div>
</template>

<script>
import Sidebar from "./Sidebar";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import AdditionalInformation from '../profile/AdditionalInformation';
import SongNotAuthorizedPopup from "../popups/SongNotAuthorizedPopup";
import ServerErrorPopup from "../popups/ServerErrorPopup";
import DisablePopup from "../popups/DisablePopup";
import {maxLength, required, between, integer} from "vuelidate/lib/validators";
// import { Money } from "v-money"
import CropImagePopup from "../popups/CropImagePopup";
import GeneralCustomCheckBox from "@/components/inputs/GeneralCustomCheckBox";
import GeneralSelect from "@/components/inputs/GeneralSelect";
import scrollToElement from "@/mixins/scrollToElement";

export default {
  name: "CreateSong",
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      // environment: process.env.VUE_APP_BASE_URL,
      // localHost: false,
      changeCheckBoxStatus: false,
      songDownloadError: false,
      coAuthorsFieldsCount: [],
      coAuthor: [],
      coAuthorValidateArray: [],
      coAuthorsFieldsCounter: 0,
      formData: new FormData,
      description: '',
      counterValue: '',
      songFile: '',
      songImage: '',
      songTitle: '',
      songExecutor: '',
      songPartsOwned: '',
      songYouTubeLink: '',
      songGenres: [],
      genreModel: null,
      associatedArtistsFullInfo: [],
      associatedArtists: [],
      associatedArtistsModel: null,
      coAuthorName: '',
      coAuthorSocialLink: '',
      generalDescription: '',
      disableSubmitBtn: false,
      firstCheckBox: false,
      // secondCheckBox: false,
      fakeInputData: '',
      checkUploadSong: {},
      error: {
        errorMessage: '',
        errorTitle: '',
      },
      image: {
        src: '',
      },
      numParams: {
        modifyValueOnWheel: false, // turn off wheel mode
        digitGroupSeparator: '.',
        decimalCharacter: ',',
        decimalPlaces: 3,
        currencySymbolPlacement: 's', // prefix || suffix
        currencySymbol: '\u00a0%',
        overrideMinMaxLimits: null,
        minimumValue: '0',
        maximumValue: '100',
      },
      // money: {
      //   decimal: ',',
      //   thousands: '.',
      //   suffix: ' %',
      //   precision: 3,
      //   masked: false
      // },
    }
  },
  validations() {
    return {
      fakeInputData: {
        required
      },
      songTitle: {
        required,
        maxLength: maxLength(150)
      },
      genreModel: {
        required
      },
      songPartsOwned: {
        required,
        between: between(1, 100),
      },
      description: {
        maxLength: maxLength(1000)
      },
      songYouTubeLink: {
        maxLength: maxLength(200),
      },
      associatedArtistsModel: {
        required: this.isCompany ? required : ''
      },
    }
  },
  mixins: [scrollToElement],
  methods: {
    // goTo(refName) {
    //   let element = this.$refs[refName]
    //   element.scrollIntoView({behavior: 'smooth'})
    // },
    getGenres() {
      this.$load(async () => {
        const res = await this.$api.createSong.getGenres()
        const genres = res.data.body.elements
        this.songGenres = genres.map((item) => {
          return {
            name: item,
            value: item
          }
        })
      })
    },
    getAssociatedArtists() {
      this.$load(async () => {
        const res = await this.$api.associatedArtists.getAssociatedArtists()
        this.associatedArtistsFullInfo = res.data.body.elements
        let list = res.data.body.elements
        this.associatedArtists = list.map((item) => {
          return {
            name: item.stageName ? item.stageName : item.firstName + ' ' + item.lastName,
            value: item.uuid
          }
        })
      })
    },
    addCoAuthor(data) {
      this.coAuthor.push(data)
    },
    coAuthorValid(data) {
      this.coAuthorValidateArray.push(data)
    },
    addAField() {
      this.coAuthorsFieldsCounter++;
      this.counterValue = this.coAuthorsFieldsCounter
      this.coAuthorsFieldsCount.push(this.counterValue);
    },
    deleteField(index) {
      this.coAuthorsFieldsCount.splice(index, 1)
      this.coAuthor.splice(index, 1)
      this.coAuthorValidateArray.splice(index, 1)
    },
    checkedChangeFirst(data) {
      this.firstCheckBox = data
      // this.disabledSubmitButton = !data
    },
    // checkedChangeSecond(data) {
    //   this.secondCheckBox = data
    // },
    downloadSong() {
      this.formData.set('fileSong', this.$refs.myFiles.$refs.input.files[0])
      this.fakeInputData = 'data is downloaded'
    },
    setImg(e) {
      const files = e.target.files || e.dataTransfer.files;
      this.formData.set('fileImage', files[0])
      this.createImage(files[0]);
    },
    setFile(e) {
      const files = e.target.files || e.dataTransfer.files;
      this.formData.set('fileSong', files[0])
    },
    createImage(file) {
      const reader = new FileReader();
      const vm = this;

      reader.onload = (e) => {
        vm.songImage = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    setFormData(event) {
      let dataKey = event.target.name
      let val = event.target.value

      this.formData.set(dataKey, val)
    },
    setFormDataPartsOwned() {
      let val = +this.songPartsOwned
      this.formData.set('percentOfSong', val.toString())
    },
    setGenre(data) {
      this.formData.set('genre', data)
      this.genreModel = data
    },
    setAssociatedArtist(data) {
      this.formData.set('associatedArtistUuid', data)
      this.associatedArtistsModel = data
    },
    async sendFormData() {
      this.setFormDataPartsOwned()
      if (this.$v.$invalid) {
        this.$v.$touch()
        // this.goTo('top')
        this.scrollToId('my-content')
        return
      } else if (this.checkInvalidCoAuthorsArray) {
        // this.goTo('coAuthorsAddInfo')
        this.scrollToId('coAuthorsAddInfo')
        return
      } else {

        if (!this.checkUploadSong.accessForUploadSong) {
          let url = this.checkUploadSong.redirectUrl
          if (url.includes('associated')) {
            this.error.errorMessage = `${this.checkUploadSong.errorMessage} <br> <a href="${url}">to the page</a>`
            this.$refs.serverErrorPopup.openPopups()
            return
          }

          this.error.errorMessage = `${this.checkUploadSong.errorMessage} <br> <a href="${this.redirectUrlWithQuery(url, 'socialRef')}">to the page</a>`
          this.$refs.serverErrorPopup.openPopups()
          return
        }

        // if(!this.checkUploadSong.accessForUploadSong) {
        //   // this.error.errorMessage = `${this.checkUploadSong.errorMessage} <br> go to <a href="${this.checkUploadSong.redirectUrl}">your profile</a>`
        //   this.error.errorMessage = `${this.checkUploadSong.errorMessage} <br> go to <a href="${this.addQueryForRedirectUrl('profile', 'socialRef')}">your profile</a>`
        //   this.$refs.serverErrorPopup.openPopups()
        //   return
        // }

        for (let i = 0; i < 20; i++) {
          this.formData.delete(`coAuthors[${i}].name`)
          this.formData.delete(`coAuthors[${i}].link`)
        }

        await this.coAuthor.forEach((item, i) => {
          if (item.name !== '' || item.link !== '') {
            this.formData.set(`coAuthors[${i}].name`, item.name)
            this.formData.set(`coAuthors[${i}].link`, item.link)
          }
        })

        this.$load(async () => {
          this.$refs.DisablePopup.openPopups()
          this.disableSubmitBtn = true
          await this.$api.createSong.sendCoAuthors(this.formData)
          this.$refs.DisablePopup.closePopup()
          await this.$router.push({path: '/profile/my-songs'})
        }, (error) => {
          if (error.data.errors[0].id === "AUDIBLE_MAGIC_EXCEPTION" || error.data.errors[0].id === "COPYRIGHT_INFRINGEMENT_EXCEPTION") {
            this.disableSubmitBtn = true
            this.$refs.DisablePopup.closePopup()
            this.$refs.errorPopup.openPopups()
            this.disableSubmitBtn = false
            this.$refs.createSongCheckboxFirst.checkInFalse()
            // this.$refs.createSongCheckboxSecond.checkInFalse()
          } else {
            this.disableSubmitBtn = true
            this.error.errorMessage = error.data.errors[0].message
            this.$refs.DisablePopup.closePopup()
            this.$refs.createSongCheckboxFirst.checkInFalse()
            this.disableSubmitBtn = false
            this.$refs.serverErrorPopup.openPopups()
            // this.$refs.createSongCheckboxSecond.checkInFalse()
          }
        })
      }
    },
    changeImagePopup() {
      this.$refs.cropImagePopup.openPopups()
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      let byteCharacters = atob(b64Data);
      let byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);

        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        let byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      let blob = new Blob(byteArrays, {type: contentType});
      return blob;
    },
    cropImage(imageUrl) {
      this.songImage = imageUrl
      let block = imageUrl.split(";");
      let contentType = block[0].split(":")[1];// In this case "image/gif"
      let realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."
      let blob = this.b64toBlob(realData, contentType);
      this.formData.set('fileImage', blob)
    },
    checkUploadSongInfo() {
      this.$load(async () => {
        await this.$api.profileSongs.checkUploadSong().then(res => {
          this.checkUploadSong = res.data.body
        })
      }, error => {
        this.error.serverError = error.data.errors[0].message
        this.$refs.serverErrorPopup.openPopups()
      })
    },
    redirectUrlWithQuery(url, scrollTo) {
      if (url && scrollTo) {
        return url + '?' + scrollTo
      }
    },
    // checkUploadSongAction() {
    //   if (this.checkUploadSong.accessForUploadSong) {
    //     this.$router.push({path: '/profile/create-song'}).catch(()=>{})
    //     this.goTo('top')
    //   } else {
    //     this.error.errorTitle = 'Oops'
    //     // this.error.errorMessage = `${this.checkUploadSong.errorMessage} <br> go to <a href="${this.checkUploadSong.redirectUrl}">your profile</a>`
    //     this.error.errorMessage = `${this.checkUploadSong.errorMessage} <br> go to <a href="${this.addQueryForRedirectUrl('profile', 'socialRef')}">your profile</a>`
    //     this.$refs.serverErrorPopup.openPopups()
    //   }
    // },
    // addQueryForRedirectUrl(page, goTo) {
    //   let url = this.checkUploadSong.redirectUrl
    //   let pageStr = page.toString()
    //   if (url.includes(pageStr)) {
    //     // for local debugging, link from backend does not contain a local link
    //     let urlEnding = '?' + goTo
    //     let urlMain = url + urlEnding
    //     if (this.localHost) {
    //       let local = this.environment
    //       let localToHttp = local.replace(/^(https:)/g, 'http:')
    //       let localUrl = localToHttp + pageStr + '/' + urlEnding
    //       return localUrl
    //     } else {
    //       return urlMain
    //     }
    //   }
    // },
    // checkLocalHost() {
    //   this.environment.includes('localhost') ? this.localHost = true : this.localHost = false
    // },
  },
  watch: {
    // songPartsOwned(newVal, oldVal) {
    //   if (typeof newVal === 'string') {
    //     if (newVal.includes('.')) {
    //       let newValSplit = newVal.split('.')[1]
    //       if (newValSplit.length >= 4) {
    //         this.songPartsOwned = oldVal
    //       }
    //       // console.log('value:', this.songPartsOwned, typeof this.songPartsOwned)
    //     }
    //   }
    // },
  },
  computed: {
    checkInvalidCoAuthorsArray() {
      return !!this.coAuthorValidateArray.find(i => i.valid === false)
    },
    checkForDisable() {
      // for support ukraine checkbox
      // return !this.firstCheckBox || !this.secondCheckBox || this.disableSubmitBtn
      return !this.firstCheckBox || this.disableSubmitBtn
    },
    isCompany() {
      return this.user.user_type !== 'ARTIST' && this.user.user_type !== 'INVESTOR'
    },
  },
  mounted() {
    // this.genreModel = null
    this.counterValue = this.coAuthorsFieldsCounter++
    this.coAuthorsFieldsCount.push(this.counterValue)
    setTimeout(() => {
      this.getGenres()
    }, 500)

    this.checkUploadSongInfo();
    // this.checkLocalHost()

    if (this.isCompany) {
      setTimeout(() => {
        this.getAssociatedArtists()
      })
    }
  },
  components: {
    Sidebar,
    Header,
    Footer,
    GeneralCustomCheckBox,
    AdditionalInformation,
    SongNotAuthorizedPopup,
    ServerErrorPopup,
    DisablePopup,
    CropImagePopup,
    // Money,
    GeneralSelect,
  },
}
</script>

<style scoped>

.theme--light .v-input {
  margin: 0;
  /*padding: 0 0 0 30px;*/
  padding: 0 0 0 55px;
  color: #5A5A5A;
  border: 1px solid #C6C6C6;
  border-radius: 5px;
}

.error-length {
  color: red;
  /*position: absolute;*/
  /*left: 10px;*/
  /*bottom: -25px;*/
}

.asterisk {
  color: red;
}

#id_song_file {
  position: relative;
}

.error-length-song_file {
  bottom: 0;
}

.add-song-parts_owner-input-item {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 29px;
  display: flex;
  align-items: center;
}

.add-song-parts_owner-input-wrapper {
  position: relative;
}

.tooltip-svg {
  opacity: 0.2;
  transition: .5s ease;
  z-index: 2;
}

.tooltip-svg:hover {
  opacity: 1;
}

.add-song-photo-fields-item .add-song-parts_owner-input-item button.v-btn {
  height: unset;
  min-width: unset;
  padding: 0 0 0 5px;
  background-color: transparent;
  text-transform: unset;
  box-shadow: unset;
}

.parts_owner-input-description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #ADADAD;
  margin-right: 18px;

}

/*@media only screen and (max-width: 530px) {*/
/*  .error-length {*/
/*    bottom: -47px;*/
/*  }*/
/*}*/
</style>
