<template>
  <div style="width: 100%">
    <div class="wrapper-investors" v-if="screenWidthFlag === 'desktop' && ieos.length">
      <table>
        <thead>
        <tr class="header-investors--row">
          <th class="header-investors-item header-investors-item-name left large-size">
            <span class="investors-item-title">Order Name</span>
          </th>
          <th class="header-investors-item header-investors-item-date left medium-size">
            <span class="investors-item-title">date</span>
          </th>
          <th class="header-investors-item header-investors-item-amount left medium-size">
            <span class="investors-item-title">amount</span>
          </th>
          <th class="header-investors-item header-investors-item-price left medium-size">
            <span class="investors-item-title">price</span>
          </th>
          <th class="header-investors-item header-investors-item-print center small-size">
            <span class="investors-item-title"></span>
          </th>
          <th class="header-investors-item header-investors-item-download center small-size">
            <span class="investors-item-title"></span>
          </th>
        </tr>
        </thead>
        <tbody>
<!--        @documentAccepted="documentAccepted"-->
        <InvestorList
            v-for="(ieo, index) in ieos"
            :key="index"
            :ieo="ieo"
        />
        </tbody>
      </table>
    </div>
    <div class="wrapper-mobile" v-if="screenWidthFlag === 'mobile' && ieos.length">
<!--      @documentAccepted="documentAccepted"-->
      <InvestorMobileList
          v-for="(ieo, index) in ieos"
          :key="index"
          :ieo="ieo"
      />
    </div>
    <div class="no-data-wrapper" v-if="!ieos.length">
      <p>You dont have any agreements yet</p>
      <router-link :to="{path: '/auction'}" class="link">Go to Primary market</router-link>
    </div>
  </div>
</template>


<script>
import InvestorList from "./InvestorList";
import InvestorMobileList from "./InvestorMobileList";


export default {
  name: "Investor",
  props: {
    ieos: Array
  },
  data() {
    return {
      screenWidthFlag: 'desktop',
      // qwe: '',
      // printLink: null,
      documentDataObj: null
    }
  },
  methods: {
    // documentAccepted(id) {
    //   this.$emit('documentAccepted', id)
    // },
    lengthWatcher() {
      window.addEventListener("resize", () => {
        if (window.innerWidth >= 1200) {
          this.screenWidthFlag = 'desktop'
          return
        }
        if (window.innerWidth < 1200) {
          this.screenWidthFlag = 'mobile'

        }
      }, false);
    },
  },
  mounted() {
    this.lengthWatcher()
    if (window.innerWidth >= 1200) {
      this.screenWidthFlag = 'desktop'
      return
    }
    if (window.innerWidth < 1200) {
      this.screenWidthFlag = 'mobile'
    }
  },
  components: {
    InvestorList,
    InvestorMobileList,
  }
}
</script>

<style scoped>

.wrapper-investors table,
.wrapper-investors table thead,
.wrapper-investors table tr {
  width: 100%;
}

th.header-investors-item {
  padding-bottom: 10px;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.investors-item-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #747474;
}

.header-investors--row {
  border-bottom: 1px solid #747474;
}

.header-investors--row .small-size {
  width: 5%;
}

.header-investors--row .medium-size {
  width: 15%;
}

.header-investors--row .large-size {
  width: 45%;
}

/*.no-data-wrapper {*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 100%;*/
/*  height: 100vh;*/
/*  padding: 40px;*/
/*  font-size: 30px;*/
/*  font-weight: 600;*/
/*  color: #000000;*/
/*}*/

@media only screen and (max-width: 1200px) {
  .wrapper-mobile {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 688px) {
  .wrapper-mobile {
    display: block;
  }
}

</style>