<template>
  <div class="general_select" :tabindex="tabindex">
    <div class="general_select-selected" :class="{'open': open}" @click="toggleSelect">

      <country-flag :country="selectedFlag" size="normal"/>

      <span class="selected-country" :class="{'placeholder': selected === placeholder}">{{ selected }}</span>

    </div>

    <div class="general_select-arrow">
      <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.3945 1L5.69727 5.69727L1 1" stroke="#272727" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>

    <div class="general_select-list" :class="{'select-hide': !open}">
      <input id="inputCountrySelectId"
             class="input-search"
             type="search"
             placeholder="Search.."
             v-model="searchVal"
             @input="searchedCountry"
             ref="countryInput"
      >

      <div class="select-scroll-container">
        <vuescroll :ops="ops">

          <div class="general_select-item"
               v-for="(option, i) in options"
               :key="i"
               :id="`id__${identifier}__${option.name}__${option.code}`"
               @click="setCountry(option)"
          >
            <p class="general_select-item-content">
              <country-flag :country="option.code.toLowerCase()" size="normal"/>
              <span class="general_select-item-text">{{ option.name }}</span>
            </p>
          </div>

        </vuescroll>
      </div>

    </div>

  </div>
</template>

<script>

import CountryFlag from 'vue-country-flag';
import vuescroll from "vuescroll";

export default {
  name: 'countrySelect',
  props: {
    options: {
      type: Array,
      required: true,
    },
    searchForCode: {
      type: String,
      required: false,
      default: null,
    },
    searchForName: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    identifier: {
      type: String,
      required: true,
      default: null,
    },
    // current: {
    //   type: Object,
    //   required: false,
    //   default: null,
    // },
  },
  data() {
    return {
      selected: null,
      selectedFlag: '',
      open: false,
      searchVal: '',
      ops: {
        rail: {
          background: 'rgba(234, 234, 234)',
          opacity: 0.5,
          size: '6px',
          gutterOfSide: '2px',
        },
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: true,
          background: '#FF0090',
          opacity: 1,
          minSize: 0.3,
          size: '6px',
        }
      },
    }
  },
  computed: {
    getCountryByCode() {
      return this.options.filter(el => el.code.includes(this.searchForCode))
    },
    getCountryByName() {
      return this.options.filter(el => el.name.toLowerCase().includes(this.searchForName.toLowerCase()))
    },
  },
  methods: {
    toggleSelect() {
      this.open = !this.open
      // for several drop-down lists on one page
      this.$emit('selectedField', this.identifier)
    },
    emitOpenStatus() {
      this.$emit('openStatus', this.open)
    },
    checkIncomeProps() {
      if (this.searchForCode) {
        let country = this.getCountryByCode[0]
        this.setCountry(country)
        return
      }
      if (this.searchForName) {
        let country = this.getCountryByName[0]
        this.setCountry(country)
        return
      }
      if (this.placeholder) {
        this.selected = this.placeholder
        return
      }
      if (this.options.length > 0) {
        return this.selected = this.options[0]
      } else {
        return this.selected = null
      }
    },
    closeList() {
      this.open = false
    },
    clearCountry() {
      this.selectedFlag = ''
      this.selected = this.placeholder
      this.$emit('changed', '')
    },
    searchedCountry(event) {
      this.$emit('searchCheck', event.target.value)
    },
    setCountry(option) {
      this.selected = option.name
      this.selectedFlag = option.code.toLowerCase()
      this.open = false
      this.$emit('changed', option, this.identifier)
    },
  },
  watch: {
    open() {
      this.emitOpenStatus()

      if (!this.open) {
        this.searchVal = ''
        this.$emit('searchCheck', this.searchVal)
      }
    },
  },
  mounted() {
    this.emitOpenStatus()
    this.selected = this.placeholder
    this.$nextTick(() => {
      this.checkIncomeProps()
    })
    // setTimeout(() => {
    //   this.checkIncomeProps()
    // }, 1000)
  },
  components: {
    CountryFlag,
    vuescroll,
  }
};
</script>

<style scoped>

.input-search {
  display: block;
  margin: 0 auto;
  width: calc(100% - 50px);
  padding: 10px 30px;
  border: 1px solid #E7E7E7;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.selected-country {
  width: calc(100% - 52px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected-country.placeholder {
  opacity: 0.6;
}

.general_select-item-text {
  max-width: calc(100% - 55px);
}

.general_select {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #131415;
  text-align: left;
  outline: none;
}

.general_select-selected {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 25px;
  padding-right: 40px;
  box-sizing: border-box;
  background: transparent;
  cursor: pointer;
  border: 1px solid #C6C6C6;
  border-radius: 5px;
}

.general_select-selected.open {
  border-bottom: none;
  border-radius: 5px 5px 0 0;
}

.general_select-list {
  display: block;
  position: absolute;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  right: 0;
  left: 0;
  background: #FFFFFF;
  border-right: 1px solid #C6C6C6;
  border-left: 1px solid #C6C6C6;
  border-bottom: 1px solid #C6C6C6;
  border-radius: 0 0 5px 5px;
  padding-top: 10px;
  z-index: 15;
}

.select-scroll-container {
  height: 230px;
  padding: 15px 10px 15px 0;
}

.general_select-list.select-hide {
  display: none;
}

.general_select-item {
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  padding-left: 25px;
  padding-right: 25px;
  transition: .5s ease;
}

p.general_select-item-content {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border-top: 1px solid #EAEAEA;
  margin-bottom: 0;
  color: #000000;
  transition: .5s ease;
}

.general_select-item:hover p.general_select-item-content {
  border-top-color: #F9F9F9;
  background: #F9F9F9;
  color: #FF0090;
}

.general_select-arrow svg {
  transition: .35s ease;
}

.general_select-arrow {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  right: 24px;
}

.general_select-selected.open + .general_select-arrow svg {
  transform: rotate(180deg);
}

@media only screen and (max-width: 540px) {
  .general_select {
    font-size: 17px;
  }
}

</style>