<template>
  <div class="order-books-item">
    <div class="order-books-price" v-text="order.price.toFixed(2)"></div>
    <div class="order-books-amount" v-text="order.amount.toFixed(2)"></div>
    <div class="order-books-total" v-text="order.total.toFixed(2)"></div>
  </div>
</template>

<script>
export default {
  name: "TradingOrders",
  props: {
    order: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped>

</style>