<template>
  <div class="investors-card">

    <div class="investors-card-item card-border">
      <div class="investors-item investors-item-name">
        <div class="investors-song-photo" :class="{'investors-photo-not-accepted': ieo.contracts[0].signatureStatus === 'NOT_ACCEPTED'}">
          <img v-if="ieo.albumCover" :src="ieo.albumCover" alt="artist photo">
          <img src="@/assets/img/new-default-user-image-small.png" alt="artist photo" v-else>
        </div>
        <div class="investors-song-title">
          <div class="song-title-item">{{ ieo.songTitle }}</div>
          <p class="song-title-item-into">{{ ieo.authorName }}</p>
        </div>
      </div>
    </div>

    <div class="investors-card-item">
      <div class="wrapper-title-item">
        <div class="investors-item-title">data</div>
        <div class="investors-item-value investors-item-date">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="2.5" width="14" height="12" rx="2.5" stroke="#C6C6C6"/>
            <path d="M1.5 7.5H13.5" stroke="#C6C6C6" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4 1V5" stroke="#C6C6C6" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11 1V5" stroke="#C6C6C6" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span v-if="ieo.contracts.length > 0">{{ ieo.contracts[0].createdAt }}</span>
          <span v-else class="investors-item-value">-</span>
        </div>
      </div>
      <div class="wrapper-title-item">
        <div class="investors-item-title">amount</div>
        <div class="investors-item-value investors-item-amount">
          <span class="investors-item-currency">{{ currencyName }}</span>
          <span v-if="ieo.contracts.length > 0">{{ amount }}</span>
          <span v-else class="investors-item-value">-</span>
        </div>
      </div>
      <div class="wrapper-title-item">
        <div class="investors-item-title">price</div>
        <div class="investors-item-value investors-item-amount">
          <span class="investors-item-currency">€</span>
          <span v-if="ieo.contracts.length > 0">{{ price }}</span>
          <span v-else class="investors-item-value">-</span>
        </div>
      </div>
    </div>

    <div class="investors-card-item button-wrap">
      <a :href="ieo.contracts[0].contractLink" class="investors-card-item-buttons" target="_blank">
        <span class="investors-item-title-buttons">Print</span>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.6562 5.03125H14.2188V3.34375C14.2188 2.05141 13.1673 1 11.875 1H6.125C4.83266 1
                  3.78125 2.05141 3.78125 3.34375V5.03125H3.34375C2.05141 5.03125 1 6.08266 1 7.375V11.125C1
                  12.4173 2.05141 13.4688 3.34375 13.4688H3.78125V15.5938C3.78125 16.3692 4.41209 17 5.1875
                  17H12.8125C13.5879 17 14.2188 16.3692 14.2188 15.5938V13.4688H14.6562C15.9486 13.4688 17
                  12.4173 17 11.125V7.375C17 6.08266 15.9486 5.03125 14.6562 5.03125ZM4.71875 3.34375C4.71875
                  2.56834 5.34959 1.9375 6.125 1.9375H11.875C12.6504 1.9375 13.2812 2.56834 13.2812
                  3.34375V5.03125H4.71875V3.34375ZM13.2812 15.5938C13.2812 15.8522 13.071 16.0625 12.8125
                  16.0625H5.1875C4.92903 16.0625 4.71875 15.8522 4.71875 15.5938V9.96875H13.2812V15.5938ZM16.0625
                  11.125C16.0625 11.9004 15.4317 12.5312 14.6562 12.5312H14.2188V9.96875H14.5C14.7589 9.96875
                  14.9688 9.75887 14.9688 9.5C14.9688 9.24113 14.7589 9.03125 14.5 9.03125H3.5C3.24113
                  9.03125 3.03125 9.24113 3.03125 9.5C3.03125 9.75887 3.24113 9.96875 3.5
                  9.96875H3.78125V12.5312H3.34375C2.56834 12.5312 1.9375 11.9004 1.9375 11.125V7.375C1.9375
                  6.59959 2.56834 5.96875 3.34375 5.96875H14.6562C15.4317 5.96875 16.0625 6.59959 16.0625
                  7.375V11.125Z" fill="#FF0090" stroke="#FF0090" stroke-width="0.3"/>
        </svg>
      </a>

      <a :href="ieo.contracts[0].contractLink" class="investors-card-item-buttons" target="_blank">
        <span class="investors-item-title-buttons">Download</span>
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 6L10 10M10 10L6 6M10 10L10 1" stroke="#FF0090" stroke-width="1.5"
                stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16.3409 11.75C15.9267 11.75 15.5909 11.4142 15.5909 11C15.5909 10.5858 15.9267 10.25 16.3409 10.25L16.3409 11.75ZM3.65909
                  10.25C4.0733 10.25 4.40909 10.5858 4.40909 11C4.40909 11.4142 4.0733 11.75 3.65909 11.75L3.65909 10.25ZM3 14.25L17 14.25L17 15.75L3
                  15.75L3 14.25ZM17 11.75L16.3409 11.75L16.3409 10.25L17 10.25L17 11.75ZM3.65909 11.75L3 11.75L3 10.25L3.65909 10.25L3.65909
                  11.75ZM1.75 13C1.75 13.6904 2.30964 14.25 3 14.25L3 15.75C1.48122 15.75 0.25 14.5188 0.25 13L1.75 13ZM0.25 13C0.25 11.4812
                  1.48122 10.25 3 10.25L3 11.75C2.30964 11.75 1.75 12.3096 1.75 13L0.25 13ZM18.25 13C18.25 12.3096 17.6904 11.75 17 11.75L17
                  10.25C18.5188 10.25 19.75 11.4812 19.75 13L18.25 13ZM19.75 13C19.75 14.5188 18.5188 15.75 17 15.75L17 14.25C17.6904 14.25
                  18.25 13.6904 18.25 13L19.75 13Z" fill="#FF0090"/>
        </svg>
      </a>


<!--      <div class="investors-card-item-main-btn-wrap" v-if="ieo.contracts[0].signatureStatus === 'NOT_ACCEPTED'">-->
<!--        <button class="btn investors-card-item-main-btn"-->
<!--                @click="$emit('documentAccepted', ieo.contracts[0].docId)"-->
<!--        >-->
<!--          <span>Not accepted</span>-->
<!--        </button>-->
<!--      </div>-->

<!--      <div class="investors-card-item-main-btn-wrap" v-else>-->
<!--        <button class="btn investors-card-item-main-btn item-btn-accepted disabled">-->
<!--          <div class="item-btn-accepted-wrapper">-->
<!--            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M1.35121 7.71838L5.20941 10.9674L10.8952 5.28162" stroke="black" stroke-width="1.7"-->
<!--                    stroke-linecap="round" stroke-linejoin="round"/>-->
<!--            </svg>-->
<!--            <div>Accepted</div>-->
<!--          </div>-->
<!--        </button>-->
<!--      </div>-->

    </div>


  </div>

</template>

<script>
import numberFormattingDecimal from "../../mixins/numberCurrencyConverter";
import numberFormattingInteger from "../../mixins/numberConverter";

export default {
  name: "InvestorMobileList",
  props: {
    ieo: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [numberFormattingDecimal, numberFormattingInteger],
  data() {
    return {
      currencyName: process.env.VUE_APP_CURRENCY_NAME_SHORT
      // accepted: true,
    }
  },
  computed: {
    amount() {
      return this.numberFormattingInteger(this.ieo.contracts[0].iprAmount)
    },
    price() {
      return this.numberFormattingDecimal(this.ieo.contracts[0].price)
    },
  },
  methods: {

  },
}
</script>

<style scoped>

/*table*/

.wrapper-investors table,
.wrapper-investors table thead,
.wrapper-investors table tr {
  width: 100%;
}


.investors-item-title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #747474;
}

.investors-item-value {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;

}

.investors-item-currency {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #B3B3B3;
  margin-right: 4px;

}


.item-btn-accepted {
  background: #EFEFEF;
}

.item-btn-accepted svg {
  margin-right: 9px;
}

.item-btn-accepted.disabled {
  background-color: #CECECE;
  border: 1px solid transparent;
  cursor: default;
  outline: none;
  box-shadow: none;
}

.item-btn-accepted div {
  color: #000000;
}

.item-btn-accepted-wrapper {
  display: flex;
  justify-content: center;
}

.investors-item-name {
  display: flex;
}

.investors-item-date svg {
  margin-right: 8px;
}

.investors-song-photo {
  position: relative;
  margin-right: 22px;
  width: 48px;
  height: 48px;
  border-radius: 12px;
}

.investors-photo-not-accepted::after {
  content: "";
  background: #FF0090;
  position: absolute;
  width: 15px;
  height: 15px;
  border: 3px solid #FFFFFF;
  border-radius: 50%;
  z-index: 2;
  bottom: 16px;
  left: 40px;
}

.investors-song-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.song-title-item {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #FF0090;
}

.song-title-item-into {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #292929;
}

/*mobile version*/


.investors-card {
  width: 100%;
  border: 1px solid #EFEFEF;
  box-sizing: border-box;
  border-radius: 12px;
}

.investors-card-item {
  padding: 12px;
}


.investors-card-item.card-border {
  border-bottom: 1px solid #EFEFEF;
  padding: 11px 11px 0 11px;
}

.wrapper-title-item {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}

.investors-item-title {
  font-size: 12px;
  line-height: 14px;
}

.button-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 12px 15px 12px;
  margin: 0;
  width: 100%;
}

.investors-card-item-main-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 460px;
  margin: 12px auto;
}

.investors-card-item-main-btn {
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.investors-item-title-buttons {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #FF0090;
  margin-right: 9px;
}

.investors-card-item-buttons {
  display: flex;
  justify-content: center;
  background: rgba(255, 0, 144, 0.03);
  border: 1px solid rgba(255, 0, 144, 0.08);
  border-radius: 6px;
  padding: 9px 15px;
  width: calc(50% - 6px);
  max-width: 230px;
  margin: auto;
  transition: all .5s;

}

.investors-card-item-buttons:hover {
  background: rgba(255, 0, 144, 0.09);
}

.investors-card-item-buttons svg {
  width: 16px;
  height: 16px;
}


@media only screen and (max-width: 1200px) {
  .investors-card {
    width: 48%;
    margin-right: auto;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 688px) {

  .investors-card {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .investors-card-item-buttons {
    padding: 8px;
  }

  .investors-song-title {
    width: calc(100% - 70px);
  }
}

@media only screen and (max-width: 350px) {
  .investors-card-item-buttons {
    width: 100%;
    margin-bottom: 12px;
  }

  .investors-card-item-main-btn-wrap {
    margin-top: 0;
  }


}

</style>