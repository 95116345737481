<template>
  <div class="container-small">

          <div class="payment-text">
            <a href="https://www.mangopay.com/" target="_blank">
              <img src="@/assets/img/mangopay-tc-logo.85416a6c.png" alt="Header logo" class="payment-header-logo"/>
            </a>

            <h2 class="payment-title">RAHMENVERTRAG FÜR ZAHLUNGSDIENSTE MANGOPAY</h2>

            <h3 class="payment-subtitle">Allgemeine Nutzungsbedingungen für Zahlungsdienste</h3>

            <span class="subtitle-sub-descr">Fassung am 1. September 2021</span>

            <span class="payment-paragraph-title">Geschlossen zwischen:</span>
            <p class="payment-paragraph">Dem Kunden, einer juristischen Person oder natürlichen Person, eingetragen im Handelsregister (bzw. im
              nationalen Gewerberegister oder jedem vergleichbaren Berufsverband) in einem Mitgliedstaat der
              Europäischen Union bzw. einem Staat des Europäischen Wirtschaftsraums oder in einem Drittland, das
              gleichwertige Verpflichtungen auferlegt, was die Bekämpfung der Geldwäsche und der
              Terrorismusfinanzierung betrifft, der in seinem Namen ausschließlich für gewerbliche (kommerzielle,
              industrielle, handwerkliche, freie) Zwecke handelt,</p>

            <span class="payment-paragraph-terms payment-paragraph-terms_margin">
              nachfolgend der <strong>„Inhaber“</strong> bzw. <strong>„gewerblicher Inhaber“</strong> genannt,
            </span>

            <span class="payment-paragraph-title">oder</span>

            <p class="payment-paragraph">Dem Kunden, einer natürlichen Person, wohnhaft in einem Mitgliedstaat der Europäischen Union bzw.
              einem Staat des Europäischen Wirtschaftsraums oder in einem Drittland, das gleichwertige Verpflichtungen
              auferlegt, was die Bekämpfung der Geldwäsche und der Terrorismusfinanzierung betrifft, der in seinem
              Namen ausschließlich für nicht gewerbliche Zwecke handelt,</p>

            <span class="payment-paragraph-terms">nachfolgend der <strong>„Inhaber“</strong> bzw. <strong>„Verbraucherinhaber“</strong>genannt einerseits, </span>

            <span class="payment-paragraph-title">und</span>

            <p class="payment-paragraph">
              <span class="payment-uppercase">MANGOPAY SA,</span> Aktiengesellschaft
              luxemburgischen Rechts, mit einem Kapital, mit Firmensitz in 2,
              Avenue Amélie, L-1125 Luxembourg und eingetragen im luxemburgischen Handels- und Firmenregister
              unter der Nummer B173459,, als E-Geld-Institut zugelassen von der luxemburgischen
              Finanzmarktaufsichtsbehörde, 283 route d’Arlon L-1150 Luxemburg,
              <a href="https://www.cssf.lu/en/">www.cssf.lu</a>,
            </p>

            <span class="payment-paragraph-terms payment-paragraph-terms_margin">
              nachfolgend der <strong>„Dienstleister“</strong> genannt, andererseits,
            </span>

            <span class="payment-paragraph-terms">
              nachfolgend einzeln eine <strong>„Partei“</strong> oder zusammen die <strong>„Parteien“</strong> genannt.
            </span>

            <div class="payment-terms-colored">
              <span class="terms-colored-title">Hinweis</span>
              <div class="terms-colored-text">
                Der Inhaber wird aufgefordert, den ihm von der Plattform übermittelten vorliegenden Rahmenvertrag vor
                dessen Annahme aufmerksam zu lesen. Er wird aufgefordert, ihn auf einen dauerhaften Datenträger zu
                laden. Standardmäßig erfolgt die Kommunikation mit dem Dienstleister stets über die Plattform
                entsprechend den in den Allgemeine Geschäftsbedingungen der Website vorgesehenen Modalitäten,
                außer wenn eine gesonderte Kommunikationsart im Vertrag vorgesehen ist.
              </div>
            </div>

            <span class="paragraph-number">1.&nbsp; Begriffsbestimmungen</span>


            <span class="payment-page-counter">1</span>


            <span class="payment-paragraph-title">Zum Zwecke des vorliegenden Vertrag werden die Begriffe wie folgt definiert:</span>

            <p class="payment-paragraph"><strong>„Authentifizierung“</strong>: Bezeichnet die von der Plattform festgelegten Verfahren zur Überprüfung der
              Identität des Inhabers oder die Gültigkeit eines Zahlungsauftrages. Diese Verfahren umfassen die
              Identifikationsdaten.</p>

            <p class="payment-paragraph"><strong>„Banken“</strong>: : bezeichnen Kreditinstitute, die für den Schutz des vom
              Dienstleister aufgebrachten Geldbetrags
              im Namen des Inhabers verantwortlich sind. Dieser Geldbetrag wird auf einem zu diesem Zweck eröffneten
              separaten Konto zur Beschränkung der Pfändung eröffnet. Die benannten Institute sind heute ING
              Luxembourg und Crédit Mutuel Arkéa. Der Dienstleister behält sich die Möglichkeit vor, jedes weitere
              zugelassene Kreditinstitut auszuwählen.</p>

            <p class="payment-paragraph"><strong>„Zahlungsempfänger“</strong>: “ bezeichnet eine juristische oder natürliche
              Person, Gläubiger eines vom Inhaber
              erfolgten Zahlungsvorgangs.</p>

            <p class="payment-paragraph"><strong>„Karte“</strong>: bezeichnet die verwendete Bank-, Debit- oder Kreditkarte
              zur Geldüberweisung an einen
              bezeichneten Inhaber auf dessen in den Geschäftsbüchern des Dienstleisters eröffneten Zahlungskontos.
              Diese Karte greift auf eines der folgenden Netze zu: Visa, MasterCard, CB. </p>

            <p class="payment-paragraph"><strong>„Zahlungskonto“</strong> oder <strong>„Konto“</strong>: : bezeichnet
              das Zahlungskonto vom Dienstleister im Namen des Inhabers,
              das für die Ausführung von Zahlungsvorgängen genutzt wird. Das Konto darf auf keinen Fall mit einem
              Depotkonto gleichgestellt werden. Das Konto wird in der auf der Website bei dessen Registrierung
              angegebenen Fremdwährung angegeben. </p>

            <p class="payment-paragraph"><strong>„Allgemeine Geschäftsbedingungen der Website“</strong>:  bezeichnen die
              allgemeinen Nutzungsbedingungen
              der Website, die zwischen den Nutzern der Website und der Plattform, die unter anderem den Zugang zur
              Website regeln, vereinbart werden. </p>

            <p class="payment-paragraph">
              <strong>„Rahmenvertrag“</strong>: bezeichnet diese allgemeinen Nutzungsbedingungen für die Zahlungsdienste samt
              Antragsformular, die die Nutzung der Zahlungsdienste sowie die Verwaltung des Zahlungskontos durch
              den Dienstleister regeln.
            </p>

            <p class="payment-paragraph"><strong>„Identifikationsdaten“</strong>: bezeichnet den Kundenidentifikator
              und das Kennwort des Inhabers, die ihm den
              Zugang zu seinem persönlichen Bereich ermöglichen.
            </p>

            <p class="payment-paragraph"><strong>„Personenbezogene Daten“</strong>: bezeichnet jede Information, die sich
              auf den Inhaber als natürliche Person
              oder auf eine juristische Person im Zusammenhang mit dem Inhaber als juristische Person bezieht (darunter
              ein Betriebsbeauftragter, ein Nutzungsberechtigter, eine befugte Person) im Sinne der EU-Verordnung
              2016/679 zum Schutz personenbezogener Daten.
            </p>

            <p class="payment-paragraph"><strong>„Persönlicher Bereich“</strong>: : bezeichnet die zugehörige Umgebung
              des Inhabers, die auf der Website der
              Plattform zugänglich ist und ihm ermöglicht, auf sein Zahlungskonto zuzugreifen und die Zahlungsdienste
              zu nutzen.
            </p>

            <p class="payment-paragraph"><strong>„Antragsformular“</strong>:  bezeichnet das Formular, das von jedem
              Interessenten auszufüllen ist, der auf der
              Website bei der Registrierung zugängliche oder von der Plattform bereitgestellte Zahlungsdienste bestellen
              möchte.
            </p>

            <p class="payment-paragraph"><strong>„Geschäftstag“</strong>:   bezeichnet einen Kalendertag mit Ausnahme
              von Samstagen, Sonntagen und Feiertagen
              in Frankreich und Luxemburg und jeden weiteren Tag, der als solcher vom Dienstleister benannt wird
            </p>

            <p class="payment-paragraph"><strong>„Zahlungsmittel“</strong>: bezeichnet andere Zahlungsmittel als die
              Karte, die auf der Website aufgelistet sind und deren Annahme und Abrechnung vom Dienstleister auf Wunsch
              angeboten wird. Der Inhaber aktiviert die
              Zahlungsmittel seiner Wahl vom persönlichen Bereich aus.
            </p>


            <span class="payment-page-counter">2</span>


            <p class="payment-paragraph"><strong>„Zahlungsvorgang“</strong>: bezeichnet eine regelmäßige oder einmalige
              dem Zahlungskonto belastete
              Überweisung, die vom Inhaber oder jedem diesbezüglich bevollmächtigten Beauftragten angeordnet wird.
            </p>

            <p class="payment-paragraph">
              <strong>„Zahlungsauftrag“</strong>:  bezeichnet die vom Inhaber dem Dienstleister erteilte Anweisung gemäß dem im
              Rahmenvertrag vorgesehenen Verfahren zur Ausführung eines Zahlungsvorgangs.
            </p>

            <p class="payment-paragraph">
              <strong>„Bezahlseite“</strong>: Bezeichnet den e-banking-Dienstleister des Dienstleisters.
            </p>

            <p class="payment-paragraph">
              <strong>„Betroffener“</strong> bezeichnet den Inhaber als natürliche Person oder jede mit dem Inhaber verbundene
              natürliche Person (darunter ein Betriebsbeauftragter, ein Nutzungsberechtigter, eine befugte Person), deren
              personenbezogene Daten im Rahmen der Ausführung dieses Rahmenvertrages verarbeitet werden.
            </p>

            <p class="payment-paragraph">
              <strong>„Befugte Person“</strong>:  bezeichnet jeden vom Inhaber benannten Beauftragten für den Zugang auf das
              Zahlungskonto und zur Nutzung der Zahlungsdienste für sein Konto.
            </p>

            <p class="payment-paragraph">
              <strong>„Plattform“:</strong>: bezeichnet die Einheit, die die Website betreibt, wie in den allgemeinen
              Geschäftsbedingungen bestimmt. Sie bereitet die Interessenten vor, erleichtert es ihnen und berät sie
              hinsichtlich des Abschlusses des Rahmenvertrags über die Website. Sie begleitet die Inhaber im Laufe ihrer
              Beziehung mit dem Dienstleister im Rahmen ihrer ausgeführten Zahlungsvorgänge. Sie erfasst die für die
              Kontoeröffnung notwendigen Unterlagen. Die Plattform sammelt keine Gelder außer ihren Gebühren
              <strong>„Dienstleister“</strong>:
              bezeichnet MANGOPAY SA, in Luxemburg von der Finanzmarktaufsichtsbehörde
              zugelassener E-Geld-Emittent unter der Nummer 3812 und berechtigt zur Ausübung ihrer Tätigkeit in allen
              Mitgliedstaaten der Europäischen Union. Der Dienstleister erscheint auf der Liste der E-Geld-Institute,die
              abrufbar sind auf <a href="https://www.cssf.lu/surveillance/ep-eme/listes-officielles">
              www.cssf.lu/surveillance/ep-eme/listes-officielles.</a>
            </p>

            <p class="payment-paragraph">
              <strong>„Dritt-Zahlungsdienstleister“</strong>: bezeichnet jedes Institut außer den Dienstleister,
              das in einem Mitgliedstaat
              der Europäischen Union bzw. einem Staat des Europäischen Wirtschaftsraums oder in einem Drittland
              zugelassen ist, das gleichwertige Verpflichtungen auferlegt, was die Bekämpfung der Geldwäsche und der
              Terrorismusfinanzierung betrifft.
            </p>

            <p class="payment-paragraph">
              <strong>Kundendienst der Plattform</strong>: bezeichnet die Abteilung, deren Kontaktdaten auf der Website erwähnt
              werden, bei der der Inhaber Informationen zum Rahmenvertrag erhält.
            </p>

            <p class="payment-paragraph">
              <strong>„Zahlungsdienste“</strong>:  bezeichnet die in den Punkten 3 und 5 des Anhangs des luxemburgischen Gesetzes
              vom 10. November 2009 zu Zahlungsdiensten festgelegten Zahlungsdiensten
            </p>

            <p class="payment-paragraph">
              <strong>„Zahlungsmittel“</strong>: bezeichnet andere Zahlungsmittel als die Karte, die auf der Website
              aufgelistet werden,
              wahlweise durch den Emittenten angeboten werden, damit dem Nutzer ermöglicht wird, ihm Geldbeträge
              zu übergeben im Gegenzug zur Ausgabe des E-Geldes
            </p>
            <p class="payment-paragraph">
              <strong>„Website“</strong>: bezeichnet die Internetseite, die von der Plattform betrieben wird, deren Ziel der Verkauf von
              Gütern oder Dienstleistungen an Nutzer ist oder die Finanzmittelbeschaffung bei ihnen, oder die Verbindung
              der Inhaber mit den Nutzern.
            </p>
            <p class="payment-paragraph">
              <strong>„Dauerhafter Datenträger“</strong>: bezeichnet jedes Medium, das es dem Inhaber ermöglicht, an ihn persönlich
              gerichtete Informationen derart zu speichern, dass er sie in der Folge für eine für die Zwecke der
              Informationen angemessene Dauer einsehen kann, und das die unveränderte Wiedergabe der gespeicherten
              Informationen ermöglicht. Es wird in der Regel in Form einer PDF-Datei angezeigt.
            </p>

            <p class="payment-paragraph">
              <strong>„Bezahlseite“</strong>: bezeichnet die von Payline gesicherte Seite, dem Dienstleister des
              Emittenten. Nur diese
              Adresse darf vom Nutzer als gültig betrachtet werden. Der Nutzer wird aufgefordert, grundsätzlich die
              Adresse oben auf der Seite zu überprüfen.
            </p>
            <p class="payment-paragraph">
              <strong>„Inhaber“</strong>: bezeichnet jede juristische oder natürliche Person, die für eigene
              Rechnung handelt und in deren
              Namen ein Zahlungskonto zur Nutzung der Zahlungsdienste eröffnet wird.
            </p>


            <span class="payment-page-counter">3</span>


            <p class="payment-paragraph">
              <strong>„Nutzer“</strong>: bezeichnet jede natürliche oder juristische Person,
              die einen Geldbetrag an den Inhaber über die
              Website mittels Karte oder einem anderen
            </p>

            <span class="paragraph-number">2.&nbsp; Gegenstand</span>

            <p class="payment-paragraph">
              Ziel des Rahmenvertrags ist die Festlegung der Bedingungen, unter denen der Dienstleister dem Inhaber
              Zahlungsdienste.
            </p>

            <p class="payment-paragraph">
              Diese Zahlungsdienste beinhalten:
            </p>

            <div class="paragraph_margin"></div>

            <p class="with-dash">die Eröffnung eines Zahlungskontos, </p>
            <p class="with-dash">zugunsten des Zahlungskontos: Registrierung des per Karten oder jedem sonstigen vom</p>
            <p class="with-dash">Dienstleister akzeptierten Zahlungsmittels überwiesenen Geldbetrags; Überweisungseingang.</p>
            <p class="with-dash">zulasten des Zahlungskontos: Ausführung einmaliger oder wiederkehrender </p>
            <p class="with-dash">Überweisungsvorgänge, Lastschrift der Kosten in Anwendung dieses Vertrags, Umbuchung der</p>
            <p class="with-dash">Geldüberweisungen per Karten (oder mit jedem sonstigen Zahlungsmittel).</p>

            <p class="payment-paragraph">
              Das Konto führt weder zu einer Überziehung, Vorauszahlung, Skontierung noch zu einem Kredit. Der
              Dienstleister bietet keinen Umtauschdienst an.
            </p>

            <p class="payment-paragraph">
              Der Dienstleister hat die Plattform beauftragt, damit der Abschluss dieses Vertrags mit jedem Inhaber
              erleichtert wird und sie im Laufe ihrer Beziehung mit dem Dienstleister begleitet werden.
            </p>

            <span class="paragraph-number">3. Inanspruchnahme der Dienste </span>
            <span class="paragraph-number">3.1. Anmeldemodalitäten</span>

            <p class="payment-paragraph">
              Der Rahmenvertrag wird im Fernabsatz nach den von der Plattform in den allgemeine
              Geschäftsbedingungen der Website vorgesehenen Modalitäten geschlossen. Damit der Rahmenvertrag
              online abgeschlossen werden kann, muss der Antragsteller unbedingt über folgende Geräte verfügen
              (Hardware und Software), für die er allein verantwortlich ist.
            </p>

            <p class="payment-paragraph">
              Standardmäßig erfolgt die Annahme des Rahmenvertrags im Fernabsatz über die Website und wird durch
              eine elektronische Unterschrift bestätigt. Der Antragsteller hat die Möglichkeit, die Vertragsunterzeichnung
              handschriftlich zu verlangen. Diesbezüglich muss er den vorliegenden Vertrag ausdrucken, ihn
              unterzeichnen und auf elektronischem Wege oder per Post an den Kundendienst der Plattform
              zurückschicken, deren Kontaktdaten in den allgemeinen Geschäftsbedingungen der Website angegeben
              sind.
            </p>

            <p class="payment-paragraph">
              Bei handschriftlicher Unterzeichnung gilt als Abschlussdatum des Rahmenvertrags das auf diesem
              angegebene Datum und mangels Datum das Eingangsdatum des Rahmenvertrags durch die Plattform.
            </p>

            <p class="payment-paragraph">
              Die elektronische Unterschrift des Rahmenvertrags erfolgt über die Website. Das Abschlussdatum des
              Rahmenvertrags entspricht dem Datum, an dem der Antragsteller den ihm auf der Website angegebenen
              Prozess der elektronischen Unterschrift beendet,
            </p>

            <p class="payment-paragraph">
              Der zwischen den Parteien auf elektronischem Wege geschlossene Rahmenvertrag besitzt dieselbe
              Beweiskraft wie ein Rahmenvertrag in Papierform.
            </p>

            <span class="paragraph-number">3.2. Vertragsunterlagen</span>

            <p class="payment-paragraph">
              Der Rahmenvertrag besteht aus:
            </p>


            <span class="payment-page-counter">4</span>


            <p class="with-dash">den vorliegenden allgemeinen Nutzungsbedingungen für Zahlungsdienste,</p>
            <p class="with-dash">dem auf der Website verfügbaren Antragsformular, </p>

            <p class="payment-paragraph">
              Diese allgemeinen Nutzungsbedingungen der Zahlungsdienste werden dem Inhaber auf der Website zur
              Verfügung gestellt und können auf einen dauerhaften Datenträger heruntergeladen werden. Zu jedem
              Zeitpunkt des Vertragsverhältnisses kann der Inhaber auf Antrag diese Unterlagen in Papierform erhalten.
            </p>

            <span class="paragraph-number">4. Kontoeröffnung</span>
            <span class="paragraph-number">4.1.Für die Kontoeröffnung notwendige Voraussetzungen</span>

            <p class="payment-paragraph">
              Jede natürliche Person, die mindestens achtzehn (18) Jahre alt und geschäftsfähig ist, und jede juristische
              Person, ansässig und/oder eingetragen in einem Mitgliedstaat der Europäischen Union bzw. einem Staat des
              Europäischen Wirtschaftsraums oder in einem Drittland, das gleichwertige Verpflichtungen auferlegt, was
              die Bekämpfung der Geldwäsche und der Terrorismusfinanzierung betrifft, kann einen Antrag auf
              Kontoeröffnung sofern die natürliche Person auf der Website als Verbraucher oder als Gewerbetreibender
              aufgenommen ist, übertragen. Die juristische Person kann nur als Gewerbetreibender aufgenommen werden.
            </p>

            <p class="payment-paragraph">
              Der Inhaber erklärt zum Zeitpunkt der Übertragung seines Aufnahmeantrags auf der Plattform und während
              der gesamten Dauer des Rahmenvertrags:
            </p>

            <p class="with-dash">
              dass er mindestens 18 (achtzehn) Jahre alt und geschäftsfähig ist oder dass er
              ordnungsgemäß in Form einer Gesellschaft errichtet ist,
            </p>

            <p class="with-dash">
              dass er auf eigene Rechnung handelt;
            </p>

            <p class="with-dash">
              dass alle bereitgestellten Informationen bei seiner Anmeldung aufrichtig, genau und aktuell sind
            </p>


            <span class="paragraph-number">4.2. Registrierungs- und Kontoeröffnungsverfahren</span>
            <p class="payment-paragraph paragraph-number_light">4.2.1. Informationen und Nachweise</p>

            <p class="payment-paragraph">
              Jeder Interessent muss nachfolgend aufgelistete Informationen und Unterlagen mittels Antragsformular auf
              die Plattform übertragen, falls diese Informationen und Unterlagen nicht bereits im Besitz der Plattform
              sind.
            </p>

            <p class="payment-paragraph">
              Der Interessent verpflichtet sich, die Informationen und Unterlagen entsprechend seiner Eigenschaft, das
              heißt als Gewerbetreibender oder Verbraucher, zu übertragen.
            </p>

            <p class="payment-paragraph">
              Für den Inhaber, natürliche Person und Verbraucher:
            </p>

            <p class="payment-paragraph with-circle">
              dessen Name, Vorname, E-Mail-Adresse, Geburtsdatum und -ort, Staatsangehörigkeit und
              Wohnsitzland.
            </p>

            <p class="payment-paragraph with-circle">
              Kopie eines gültigen amtlichen Ausweises des Inhabers (Bsp.: Personalausweis, Führerschein
              und für Drittstaatsangehörige in der Europäischen Union einen Reisepass).
            </p>

            <p class="payment-paragraph">
              Für den gewerblichen Inhaber:
            </p>

            <div class="paragraph_margin"></div>

            <p class="with-dash">für natürliche Personen:</p>

            <p class="payment-paragraph with-circle">
              deren Name, Vorname, E-Mail-Adresse, Geburtsdatum und Staatsangehörigkeit sowie
              Wohnsitzland,
            </p>

            <p class="payment-paragraph with-circle">
              Original oder Kopie eines Auszugs aus dem Amtsblatt, nicht älter als drei Monate, der die
              Anmeldung als Gewerbetreibender oder im nationalen Gewerberegister bzw. jedem
              anderen Berufsverband bestätigt, von dem der Inhaber abhängig ist.
            </p>

            <p class="payment-paragraph with-circle">
              Kopie eines gültigen amtlichen Ausweises des Inhabers (Bsp.: Personalausweis,
              Führerschein und für Drittstaatsangehörige in der Europäischen Union einen Reisepass).
            </p>


            <span class="payment-page-counter">5</span>


            <div class="paragraph_margin"></div>

            <p class="with-dash">für juristische Personen:</p>

            <p class="payment-paragraph with-circle">
              seine Firmenbezeichnung, Unternehmensform, sein Kapital, Adresse des Firmensitzes,
              Beschreibung der Geschäftstätigkeit, Identität der Gesellschafter und Geschäftsführer
              sowie die Liste der Nutzungsberechtigten wie beispielsweise von der Verordnung
              festgelegt,
            </p>

            <p class="payment-paragraph with-circle">
              Handelsregisterauszug oder gleichwertiges Dokument, das nicht älter als drei Monate ist
              und die Eintragung im Handelsregister eines Mitgliedstaates der Europäischen Union bzw.
              eines Staates des Europäischen Wirtschaftsraums oder in einem Drittland, das
              gleichwertige Verpflichtungen auferlegt, was die Bekämpfung der Geldwäsche und der
              Terrorismusfinanzierung betrifft, rechtfertigt. Dieses Dokument muss die
              Firmenbezeichnung, die Rechtsform, die Adresse des Firmensitzes und die Identität der
              Gesellschafter und Geschäftsführer, erwähnt unter 1° und 2° von Artikel R.123-54 des
              Handelsgesetzbuches oder deren Entsprechungen im ausländischen Recht bestätigen.
            </p>

            <p class="payment-paragraph with-circle">
              beglaubigte Kopie der Satzung und etwaiger Beschlüsse, die den gesetzlichen Vertreter
              bestellen;
            </p>

            <p class="payment-paragraph with-circle">
              Kopie des Personalausweises oder Reisepasses des gesetzlichen Vertreters und
              gegebenenfalls des oder der Nutzungsberechtigten.
            </p>

            <p class="payment-paragraph with-circle">
              Die Erklärung der Nutzungsberechtigten der juristischen Person, die mehr als 25 % halten,
              falls der Interessent seine Nutzungsberechtigten nicht im nationalen Register angemeldet
              hat oder dieser Pflicht nicht unterliegt.
            </p>

            <p class="payment-paragraph">
              Der Kontoinhaber kann auch aufgefordert werden, einen Bankauszug für ein auf seinen Namen eröffnetes
              Konto bei einem Dritt-Zahlungsdienstleister.
            </p>

            <p class="payment-paragraph">
              Es ist ausdrücklich vorgesehen, dass der Dienstleister sich vorbehält, vor jeglicher Registrierung und
              jederzeit während der Laufzeit des Rahmenvertrags ergänzende Unterlagen bezüglich des Inhabers, des
              Nutzungsberechtigten oder eines speziellen Zahlungsvorgangs zu fordern.
            </p>

            <p class="paragraph-number_light">
              4.2.2. Beschränkung des Zahlungskontos
            </p>

            <p class="payment-paragraph">
              Je nach Beurteilung des Dienstleisters kann die Nutzung eines Zahlungskontos beschränkt sein, ohne dass
              der Dienstleister seine Entscheidung beim betroffenen Inhaber begründet. Die Funktionsweise des Kontos
              wird hauptsächlich beschränkt, da der Inhaber nicht sämtliche vom Dienstleister erforderlichen
              Informationen und Unterlagen wie nachfolgend aufgelistet übermittelt hat. Diese Grenzen werden dem
              Inhaber durch die Plattform angegeben.
            </p>

            <p class="payment-paragraph paragraph-number_light">
              4.2.3. Abschluss der Registrierung
            </p>

            <p class="payment-paragraph">
              Nachdem der Rahmenvertrag geschlossen wurde, muss der Inhaber sämtliche Informationen und Nachweise
              bereitstellen, die von ihm durch die Plattform gefordert werden. Durch seine Zustimmung zu den
              Bedingungen des Rahmenvertrags akzeptiert der Inhaber, dass die Plattform dem Dienstleister seinen
              Aufnahmeantrag als Inhaber und sämtliche von ihm erhaltenen Nachweise übermittelt.
            </p>

            <p class="payment-paragraph">
              Der Dienstleister darf als Einziger die Aufnahme eines Interessenten als Inhaber eines Zahlungskontos in
              dessen Namen annehmen. Diese Zustimmung wird dem Inhaber von der Plattform durch jedes Mittel gemäß
              den auf der Website vorgesehenen Modalitäten mitgeteilt.
            </p>

            <p class="payment-paragraph">
              Der Dienstleister kann ohne Begründung oder Anspruch auf Entschädigung zugunsten des Inhabers einen
              Antrag auf Eröffnung eines Kontos ablehnen. Diese Ablehnung wird dem Inhaber von der Plattform durch
              jedes Mittel gemäß den auf der Website vorgesehenen Modalitäten mitgeteilt.
            </p>


            <span class="payment-page-counter">6</span>


            <span class="paragraph-number">5. Funktionsweise des Zahlungskontos</span>

            <p class="payment-paragraph">
              Die dem Zahlungskonto gutgeschriebenen Beträge ergeben sich aus dem durch die Karte überwiesenen
              Geldbetrag (oder jedem sonstigen vom Dienstleister akzeptierten Zahlungsmittels) oder vom
              Überweisungseingang. Die dem Zahlungskonto belasteten Beträge ergeben sich aus der Durchführung des
              Zahlungsvorgangs für ein in den Büchern eines Dritt-Zahlungsdienstleisters eröffneten Kontos, der
              Belastung durch den Dienstleister der vom Inhaber geschuldeten Kosten für den Rahmenvertrag oder der
              Rückbuchung eines Vorgangs per Karte (bzw. durch jedes andere Zahlungsmittel).
            </p>

            <span class="paragraph-number">5.1. Annahme und Abrechnung von Zahlungsaufträgen per Karten auf das Zahlungskonto</span>

            <p class="payment-paragraph">
              Die Finanzierung des Zahlungskontos kann einmalig oder mehrmals per Karte (oder jedem sonstigen vom
              Dienstleister akzeptierten Mittel) erfolgen. Wenn der Nutzer diesen Vorgang durchführen möchte, weist er
              sich auf der Website aus unter Angabe seines Benutzernamens (gültige E-Mail-Adresse) und seines
              Kennworts oder indem er sich über sein Facebook-Konto anmeldet. Der Geldüberweisungsauftrag wird auf
              einer dafür bestimmten Bezahlseite erfasst. Für jede Bezahlung kann der Nutzer aufgefordert werden, einen
              einmaligen Code einzugeben, der auf seinem Mobiltelefon für das ausgebende Karteninstitut mitgeteilt
              wird. Gegebenenfalls obliegt es dem Dienstleister, jede Zahlung je nach seiner Beurteilung abzulehnen und
              ohne dass diese Entscheidung zu einer Entschädigung führen kann. Der Geldüberweisungsvorgang wird
              vom ausgebenden Karteninstitut durchgeführt. Jeder Einwand einer solchen Überweisung muss besagtem
              Institut gemeldet werden. Der Dienstleister ist nicht berechtigt, eine solche Überweisung zu stornieren.
            </p>

            <p class="payment-paragraph with-circle">
              Der Inhaber wird unterrichtet, dass die Annahme eines Zahlungsauftrages per Karte durch den Dienstleister
              den Eingang des Geldes auf dem Konto des Inhabers nicht gewährleistet. Die Registrierung des Geldes auf
              dem Zahlungskonto des Inhabers hängt vom tatsächlichen Erhalt durch den Dienstleister des aufgebrachten
              Geldbetrags.
            </p>

            <p class="payment-paragraph with-circle">
              Erfolgt kein Geldeingang aus technischen Gründen, sorgt der Dienstleister dafür, nach besten Kräften den
              Vorgang zu regeln. Erfolgt kein Geldeingang aus irgendeinem anderen Grund, informiert der Dienstleister
              den Inhaber unverzüglich, dass ihm die Gutschrift des erwarteten Betrags auf dessen Konto unmöglich ist,
              damit er sich mit dem Nutzer in Verbindung setzen kann.
            </p>

            <p class="payment-paragraph">
              Angenommen, der Transfer von auf dem Konto des Inhabers ausgewiesenen Geldern wird durch den
              Kartenaussteller infolge eines Einwands durch den Nutzer storniert, akzeptiert der Inhaber, dass der
              Dienstleister jeden Geldüberweisungsvorgang per Karte durch Lastschrift des Zahlungskontos des
              entsprechenden Betrags rückgängig macht. Der Inhaber erkennt an, dass besondere Aufmerksamkeit des
              Kartenausstellers auf einen solchen Einwand bis zum Ablauf einer maximalen Frist von dreizehn (13)
              Monaten gerichtet werden kann nach dem Datum des belasteten Kontos, mit dem besagte Karte verbunden
              ist. Der Dienstleister kann mangels ausreichender Deckung auf dem Konto jeden vom Inhaber oder einem
              Beauftragten eingeleiteten Zahlungsvorgang aussetzen oder stornieren , um diese Rückbuchung
              vorzunehmen, oder gegebenenfalls in die Rechte des Inhabers eintreten und die Einziehung der vom Nutzer
              geschuldeten Beträge durch jegliches Mittel vornehmen.
            </p>

            <span class="paragraph-number">5.2. Eingang der Überweisung auf dem Zahlungskonto</span>

            <p class="payment-paragraph">
              Der Inhaber beauftragt den Dienstleister, damit er auf seinem Zahlungskonto SEPA-Überweisungen in Euro
              von einem Bankkonto oder einem in den Büchern eines Dritt-Zahlungsdienstleisters eröffneten
              Zahlungskontos empfangen kann.
            </p>


            <span class="payment-page-counter">7</span>


            <p class="payment-paragraph">
              Die Geldbeträge werden seinem Zahlungskonto unverzüglich vom Dienstleister nach dem tatsächlichen
              Erhalt durch den Dienstleister gutgeschrieben.
            </p>

            <p class="payment-paragraph">
              Nach Registrierung des Geldes auf dem Zahlungskonto des Inhabers stellt ihm der Dienstleister eine
              Zusammenfassung des eingegangenen Überweisungsvorgangs zur Verfügung mit folgenden Informationen:
              Verwendungszweck des Zahlungsvorgangs, Bezugsangabe zur Feststellung des Zahlungspflichtigen,
              Betrag des Vorgangs, Wertstellungsdatum der Gutschrift.
            </p>

            <span class="paragraph-number">5.3. Ausführung eines Zahlungsvorgangs zulasten des Zahlungskontos </span>

            <p class="payment-paragraph">
              Der Inhaber kann SEPA- oder internationale Überweisungsaufträge für das Konto eines von einem
              DrittZahlungsdienstleister gehaltenen Zahlungsempfängers übertragen.
            </p>

            <p class="payment-paragraph">
              Wenn der Inhaber einen Überweisungsvorgang ausführen möchte, weist er sich aus in seinem persönlichen
              Bereich durch Angabe seiner Identifikationsdaten und gegebenenfalls durch Befolgen der Schritte einer
              Authentifizierung, die ihm angegeben wird. Er gibt auf der Bezahlseite an: Betrag des Zahlungsvorgangs,
              Währung, zu belastendes Zahlungskonto, Ausführungsdatum des Auftrags und jede weitere erforderliche
              Information. Bei fehlender Datumsangabe gilt der Überweisungsauftrag unmittelbar. Der Inhaber muss
              ebenfalls das vom Dienstleister angegebene Authentifizierungsverfahren befolgen.
            </p>

            <p class="payment-paragraph">
              Der Inhaber kann jederzeit einen Antrag auf Ausführung eines Überweisungsauftrags für einen von ihm
              ernannten Zahlungsempfänger übertragen, der über ein Bank- oder Zahlungskonto bei einem DrittZahlungsdienstleister verfügt. Der Inhaber muss den zugehörigen Grund für jede Überweisung übermitteln
              und das Authentifizierungsverfahren einhalten , das vom Dienstleister angegeben wird.
            </p>

            <p class="payment-paragraph">
              Der Inhaber erteilt seine unwiderrufliche Zustimmung für den Zahlungsauftrag durch Anklicken der
              Registerkarte „Validierung“ („Eingangsdatum“). Der Eingang des Zahlungsauftrags wird im persönlichen
              Bereich des Inhabers bestätigt. Kein Auftrag kann vom Inhaber nach dem Datum zurückgezogen werden,
              nachdem er als unwiderruflich gilt, das heißt ab dem Eingangsdatum.
            </p>

            <p class="payment-paragraph">
              Vor Übertragung eines Überweisungsauftrags muss der Inhaber (oder die Plattform, die in seinem Namen
              handelt) sicherstellen, dass er über einen ausreichenden Betrag auf seinem Konto verfügt, um den Betrag
              des Zahlungsvorgangs und die damit verbundenen Kosten. Gegebenenfalls muss er sein Konto ins Haben
              führen, bevor der Auftrag rechtsgültig an den Dienstleister zur Ausführung übertragen werden kann.
            </p>

            <p class="payment-paragraph">
              Es wird ausdrücklich vereinbart, dass Zahlungsaufträge spätestens am Ende des Geschäftstags nach dem
              Eingangsdatum des Auftrags durch den Dienstleister ausgeführt werden (und zum vereinbarten
              Ausführungsdatum bei Terminüberweisungen oder Daueraufträgen). Jeder nach 16 Uhr durch den
              Dienstleister eingegangene Zahlungsauftrag gilt am folgenden Geschäftstag als eingegangen. Wenn das
              Empfangsdatum kein Geschäftstag ist, gilt der Zahlungsauftrag am folgenden Geschäftstag als eingegangen.
            </p>

            <p class="payment-paragraph">
              Bei jedem Überweisungsvorgang kann der Inhaber den Dienstleister um die Bereitstellung von
              Informationen zur Ausführungsfrist dieses Sondervorgangs auf dauerhaftem Datenträger ersuchen, über die
              Kosten, die er bezahlen muss und gegebenenfalls über die Einzelheiten zu diesen Kosten.
            </p>

            <p class="payment-paragraph">
              Der Dienstleister kann veranlasst sein, die Ausführung eines unvollständigen oder fehlerhaften
              Überweisungsauftrags abzulehnen. Der Inhaber muss den Auftrag erneut senden, um ihn in Einklang zu
              bringen. Der Dienstleister kann außerdem einen Überweisungsauftrag bei erheblichem Zweifel
              betrügerischer Nutzung des Kontos sperren, nicht berechtigter Nutzung des Kontos, Beeinträchtigung der
              Sicherheit des Kontos, bei Einfrieren von Geldern durch eine Verwaltungsbehörde oder aus jedem sonstigen
              Grund.
            </p>


            <span class="payment-page-counter">8</span>


            <p class="payment-paragraph">
              Bei Ablehnung der Ausführung eines Überweisungsauftrags oder der Sperrung eines Überweisungsauftrags
              unterrichtet der Dienstleister den Inhaber darüber mit jedem Mittel. Falls möglich, gibt der Dienstleister
              dem Inhaber die Gründe der Ablehnung oder Sperrung an, mindestens ein Verbot aufgrund einer
              entsprechenden Bestimmung nationalen Rechts oder des Rechts der Europäischen Union.
            </p>

            <span class="paragraph-number">5.4. Rückerstattung</span>

            <p class="payment-paragraph">
              Der Inhaber kann jederzeit eine Anweisung zur Stornierung eines Finanztransfers übermitteln, damit ein
              Nutzer entschädigt wird. Der Inhaber weist sich aus auf der Website durch Angabe seines Identifikators und
              seines Passwortes. Er gibt in seinem persönlichen Bereich den Erstattungsbetrag, die Währung, den zu
              entschädigenden Nutzer und jede weitere erforderliche Information an.
            </p>

            <p class="payment-paragraph">
              Der Erstattungsvorgang erfolgt vom Dienstleister durch Kredit der Karte, die vom Nutzer eingesetzt wird,
              oder durch Überweisung nach den ursprünglichen Zahlungsmodalitäten im Rahmen des verfügbaren
              Guthabens des Kontos und der Regeln für jedes Netz und SEPA-Regeln innerhalb von fünf (5)
              Geschäftstagen nach Eingang durch den Dienstleister des Erstattungsantrags.
            </p>

            <span class="paragraph-number">6. Berichtswesen</span>

            <p class="payment-paragraph">
              Der Inhaber verfügt in seinem persönlichen Bereich über einen Bezahlvorgangsstatus für das
              Zahlungskonto. Er wird aufgefordert, aufmerksam Kenntnis von der Liste dieser Vorgänge zu nehmen. Die
              Aufzeichnungen der Vorgänge können ebenfalls auf ausdrücklichen Wunsch dem gewerbetreibenden
              Inhaber in anderen Zeitabständen zur Verfügung gestellt werden.
              Es wird festgehalten, dass bei jedem vom Dienstleister ausgeführten Zahlungsvorgang der Inhaber über
              folgende Informationen verfügt: Verwendungszweck des Zahlungsvorgangs, Feststellung des
              Nutzungsberechtigten, Betrag des Vorgangs, Eingangsdatum des Auftrags und gegebenenfalls die Kosten
              für die Ausführung dieses Vorgangs.
            </p>

            <span class="paragraph-number">7. Zugang zum Zahlungskonto und Geheimhaltung</span>

            <p class="payment-paragraph">
              Das Zahlungskonto ist online zugänglich im persönlichen Bereich mittels Identifikationsdaten und
              entsprechend dem geforderten Authentifizierungsverfahren.
            </p>

            <p class="payment-paragraph">
              Der Inhaber muss Identifikationsdaten je befugter Person angeben. Jede befugte Person akzeptiert, nicht
              den Namen oder die Identifikationsdaten einer anderen person zu verwenden. Der Inhaber ist allein
              verantwortlich für jede Nutzung seines Identifikators.
              Jede befugte Person ist voll verantwortlich für die Geheimhaltung ihrer Identifikationsdaten sowie für jedes
              sonstige personalisierte Sicherheitsmerkmal. Der Inhaber muss alle vernünftigen Maßnahmen zur Wahrung
              der Geheimhaltung und Sicherheit seiner Identifikationsdaten.
            </p>

            <p class="payment-paragraph">
              Der Inhaber (und jede befugte Person) akzeptiert, seine Identifikationsdaten keinen Dritten zu übermitteln
            </p>

            <span class="paragraph-number">8. Widerspruch</span>

            <p class="payment-paragraph">
              Der Inhaber muss die Plattform über den Verlust oder Diebstahl seiner Identifikationsdaten, der
              Veruntreuung bzw. jeder nicht berechtigten Nutzung seines persönlichen Bereichs oder seiner Daten, die
              damit verbunden sind, sobald er Kenntnis davon hat, damit er die Sperrung beantragen kann. Diese
              Erklärung muss folgendermaßen erfolgen:
            </p>

            <p class="with-dash">
              telefonisch den Kundendienst der Plattform unter der in den allgemeine Geschäftsbedingungen der
              Website angegebenen Nummer; oder
            </p>

            <p class="with-dash">
              direkt per elektronischer Nachricht über das auf der Website zugängliche Kontaktformular.
            </p>


            <span class="payment-page-counter">9</span>


            <p class="payment-paragraph">
              Der Dienstleister führt über die Plattform unverzüglich den Antrag auf Widerspruch aus.
              Der Dienstleister kann nicht haftbar gemacht werden für Folgen eine Widerspruchs per Fax oder E-Mail,
              die nicht vom Inhaber stammt.
            </p>

            <p class="payment-paragraph">
              Ein Widerspruchsantrag gilt als erfolgt zum Datum und zur Uhrzeit des tatsächlichen Empfangs des Antrags
              durch die Plattform. Bei Diebstahl der Identifikationsdaten oder betrügerischer Nutzung des persönlichen
              Bereiches ist der Dienstleister berechtigt, über die Plattform eine Quittung oder eine Kopie der eingereichten
              Klage beim Inhaber zu fordern, der sich verpflichtet, darauf unverzüglich zu antworten.
            </p>

            <span class="paragraph-number">9. Sperrung des Zahlungskontos</span>

            <p class="payment-paragraph">
              Der Dienstleister behält sich das Recht vor, das Zahlungskonto zu sperren, wenn objektive Gründe vermuten
              lassen, dass die Sicherheit eines Zahlungsmittels gefährdet ist oder mit ihm eine nicht genehmigte oder
              betrügerische Nutzung vorgenommen wurde oder für ein beträchtlich erhöhtes Risiko, dass der Inhaber
              unfähig ist, seiner Pflicht nachzukommen, die hinsichtlich dieses Rahmenvertrags geschuldeten Kosten zu
              bezahlen.
            </p>

            <span class="paragraph-number">10. Umstrittene Transaktion</span>
            <span class="paragraph-number">10.1. Gemeinsame Bestimmungen für alle Inhaber</span>

            <p class="payment-paragraph">
              Bei jeder Beschwerde zu vom Dienstleister ausgeführten Zahlungsvorgängen im Rahmen dieses Vertrags
              wird der Inhaber aufgefordert, sich an den Kundendienst der Plattform oder an die diesbezüglich in den
              allgemeinen Geschäftsbedingungen der Website angegebene Adresse zu wenden.
            </p>

            <p class="payment-paragraph">
              Wenn ein Auftrag vom Dienstleister mit Fehlern aufgrund eines Verschuldens des letzteren ausgeführt wird,
              wird die Beanstandung unverzüglich an den Dienstleister übermittelt, der Auftrag wird storniert und das
              Konto wird wieder so hergestellt, wie es vor Eingang des Zahlungsauftrags war. Anschließend wird der
              Auftrag korrekt abgebildet.
            </p>

            <span class="paragraph-number">10.2. Geltende Bestimmungen für den gewerbetreibenden Inhaber</span>

            <p class="payment-paragraph">
              Der gewerbetreibende Inhaber, der eine nicht von ihm genehmigte oder falsch ausgeführte
              Überweisungstransaktion anfechten möchte, muss sich telefonisch unverzüglich mit dem Kundendienst der
              Plattform nach Kenntnisnahme der Unregelmäßigkeit und spätestens innerhalb von acht (8) Wochen nach
              Buchung der Transaktion in Verbindung setzen, ihr obliegt die unverzügliche Übermittlung der Anfechtung
              an den Dienstleister. Außer es liegen stichhaltige Gründe für einen hinreichenden Verdacht auf Betrug des
              Inhabers vor, erstattet der Dienstleister dem Inhaber den Betrag der Transaktion unverzüglich nach Eingang
              des Anfechtungsantrags und auf jeden Fall spätestens bis zum Ablauf des nächsten Geschäftstags. Der
              Dienstleister versetzt das Konto wieder in den vorherigen Zustand, so als ob die nicht genehmigte
              Zahlungstransaktion nicht erfolgt wäre.
            </p>

            <p class="payment-paragraph">
              Bei Verlust oder Diebstahl der Identifikationsdaten gehen die nicht genehmigten Transaktionen, die vor
              Mitteilung des Widerspruchs erfolgten, zulasten des Inhabers. Die nach dem Widerspruch ausgeführten
              Transaktionen werden vom Dienstleister getragen, außer im Betrugsfall des Inhabers.
            </p>

            <span class="paragraph-number">10.3. Geltende Bestimmungen für den Verbraucherinhaber</span>


            <span class="payment-page-counter">10</span>


            <p class="payment-paragraph">
              Der Verbraucherinhaber, der eine nicht von ihm genehmigte oder falsch ausgeführte
              Überweisungstransaktion anfechten möchte, muss sich telefonisch unverzüglich mit dem Kundendienst der
              Plattform nach Kenntnisnahme der Unregelmäßigkeit und spätestens innerhalb von dreizehn (13) Monaten
              nach der Belastung in Verbindung setzen, ihr obliegt die unverzügliche Übermittlung der Anfechtung an
              den Dienstleister. Außer es liegen stichhaltige Gründe für einen hinreichenden Verdacht auf Betrug des
              Inhabers vor, erstattet der Dienstleister dem Inhaber den Betrag der Transaktion unverzüglich nach Eingang
              des Anfechtungsantrags und auf jeden Fall spätestens bis zum Ablauf des nächsten Geschäftstags. Der
              Dienstleister versetzt das Konto wieder in den vorherigen Zustand, so als ob die nicht genehmigte
              Zahlungstransaktion nicht erfolgt wäre.
            </p>

            <p class="payment-paragraph">
              Bei Anfechtung obliegt die Beweislast, dass der Zahlungsvorgang authentifiziert, ordnungsgemäß erfasst
              und verbucht war und nicht von einem technischen Mangel oder dergleichen beeinträchtigt war, dem
              Dienstleister.
            </p>

            <p class="payment-paragraph">
              Bei nicht genehmigtem Zahlungsvorgang infolge des Verlusts oder Diebstahl von Identifikationsdaten trägt
              der Inhaber vor Mitteilung des Widerspruchs die Verluste in Verbindung mit der Nutzung personalisierter
              Sicherheitsmerkmale innerhalb einer Obergrenze von fünfzig (50) Euro. Die nach dem Widerspruch
              ausgeführten Transaktionen werden vom Dienstleister getragen, außer im Betrugsfall des Inhabers.
              Allerdings haftet der Inhaber nicht bei:
            </p>

            <p class="with-dash">
              Nicht genehmigtem Zahlungsvorgang, der ohne Nutzung Identifikationsdaten erfolgte;
            </p>

            <p class="with-dash">
              Verlust oder Diebstahl Identifikationsdaten, die vom Inhaber vor der Zahlung nicht erkennbar
              waren;
            </p>

            <p class="with-dash">
              Verlust infolge von Handlungen oder Fahrlässigkeit eines Angestellten, eines Agenten oder einer
              Niederlassung eines Zahlungsdienstleisters oder einer Körperschaft, zu der die Geschäftstätigkeit
              ausgelagert wurde.
            </p>

            <p class="payment-paragraph">
              Der Inhaber haftet auch nicht:
            </p>

            <p class="with-dash">
              wenn der nicht genehmigte Zahlungsvorgang durch Veruntreuung der Identifikationsdaten ohne
              Wissen des Inhabers erfolgte;
            </p>

            <p class="with-dash">
              bei Fälschung der Identifikationsdaten, wenn zum Zeitpunkt des nicht genehmigten
              Zahlungsvorgangs der Inhaber im Besitz dieser Merkmale war.
            </p>

            <p class="payment-paragraph">
              Der Inhaber trägt sämtliche durch nicht genehmigte Transaktionen verursachte Verluste, wenn sich diese
              Verluste aus betrügerischer Absicht seinerseits ergeben oder wenn er nicht vorsätzlich durch grobe
              Fahrlässigkeit die Pflichten erfüllt hat, die Sicherheit seiner Identifikationsdaten zu wahren und den
              Widerspruch bei Verlust, Diebstahl oder Veruntreuung dieser Merkmale mitzuteilen.
            </p>

            <span class="paragraph-number">11. Finanzielle Bedingungen</span>

            <p class="payment-paragraph">
              Alle vom Inhaber geschuldeten Provisionen an die Plattform werden automatisch auf dem Zahlungskonto
              vom Dienstleister abgebucht. Der Inhaber genehmigt dem Dienstleister, jederzeit einschließlich nach
              Schließung des Kontos, jede einredefreie, bezifferbare und fällige Forderung, die gleichgültig aus welchem
              Grund schuldig bleibt, auszugleichen. Er kann die Deckung des Zahlungskontos mit jedem vom Inhaber
              ausstehenden, fälligen und unbezahlten Betrag für den Dienstleister ausgleichen.
            </p>

            <span class="paragraph-number">12. Laufzeit und Kündigung</span>

            <p class="payment-paragraph">
              Der Rahmenvertrag wird für eine unbegrenzte Dauer geschlossen. Er tritt ab dessen Annahme durch den
              Inhaber in Kraft.
            </p>


            <span class="payment-page-counter">11</span>


            <p class="payment-paragraph">
              Dieser kann jederzeit und mittels Einhaltung einer Frist von dreißig (30) Kalendertagen den Rahmenvertrag
              kündigen. Der Dienstleister kann jederzeit den auf dauerhaftem Datenträger bereitgestellten Rahmenvertrag
              mittels Einhaltung einer Frist von zwei (2) Monaten kündigen. In diesem Fall schuldet der Inhaber die in
              regelmäßigen Abständen belasteten Kosten für die Zahlungsdienste anteilmäßig bis zur Beendigung des
              Vertrags.
            </p>

            <p class="payment-paragraph">
              Jede Partei muss dafür an die andere Partei per Einschreiben mit Rückschein ihre Kündigung an die
              Anschrift und E-Mail-Adresse richten, die in den allgemeinen Geschäftsbedingungen der Website
              angegeben ist.
            </p>

            <p class="payment-paragraph">
              Dementsprechend wird der gesamte Rahmenvertrag gekündigt und das Zahlungskonto geschlossen. Das
              Guthaben des Kontos wird innerhalb von dreizehn (13) Monaten auf das Bankkonto des Inhabers nach
              Abzug der an den Dienstleister fälligen und zu zahlenden Kosten überwiesen.
            </p>

            <p class="payment-paragraph">
              Bei schweren Verstößen, Betrug oder Zahlungsausfällen seitens des Inhabers behält sich der Dienstleister
              das Recht zur Aussetzung oder Kündigung dieses Vertrags vor durch Zusendung einer E-Mail gemeinsam
              mit einem Einschreiben mit Rückschein ohne Angabe von Gründen oder vorherige Ankündigung.
            </p>

            <p class="payment-paragraph">
              Es ist vorhergesehen, dass der Rahmenvertrag automatisch bei neuen Umständen, die die Fähigkeit einer
              Partei beeinträchtigen, sich für diesen Vertrag einzusetzen, gekündigt wird.
            </p>

            <span class="paragraph-number">13. Vertragsänderung</span>

            <p class="payment-paragraph">
              Der Dienstleister behält sich das Recht vor, jederzeit den Rahmenvertrag zu ändern. Jeder
              Änderungsentwurf des Rahmenvertrags wird dem Inhaber durch die Plattform bereitgestellt.
            </p>

            <p class="payment-paragraph">
              Jeder Inhaber kann die vorgeschlagenen Änderungen ablehnen und muss seine Ablehnung dem
              Kundendienst der Plattform per Einschreiben mit Rückschein zwei (2) Monate vor Inkrafttreten der
              vorgeschlagenen Änderungen mitteilen (der Poststempel ist maßgeblich).
            </p>

            <p class="payment-paragraph">
              Mangels Mitteilung der Ablehnung vor dem angegebenen Datum des Inkrafttretens gelten die
              vorgeschlagenen Änderungen als angenommen durch den Inhaber. Die Beziehungen zwischen den Parteien
              nach Inkrafttreten werden dann von der neuen Fassung des Rahmenvertrags geregelt.
            </p>

            <p class="payment-paragraph">
              JBei Ablehnung durch den Inhaber führt diese Ablehnung ohne Kosten zur Kündigung des Rahmenvertrags
              sowie zur Überweisung des Restbetrags des Zahlungskontos innerhalb von dreizehn (13) Monaten nach
              Inkrafttreten der Kündigung hinsichtlich der Abdeckung sämtlicher künftiger Anfechtungen.
            </p>

            <p class="payment-paragraph">
              Sämtliche Rechts- oder Verwaltungsvorschriften, die es erforderlich machen, dass der gesamte oder ein Teil
              des Rahmenvertrags geändert wird, gelten ab dem Datum des Inkrafttretens ohne Ankündigung. Der Inhaber
              wird jedoch darüber informiert.
            </p>

            <span class="paragraph-number">14. Sicherheit</span>

            <p class="payment-paragraph">
              Der Dienstleister verpflichtet sich, seine Leistungen unter Beachtung der geltenden Gesetze und
              Bestimmungen und dem Stand der Technik zu gewährleisten. Insbesondere leitet der Dienstleister alles in
              die Wege, um die Sicherheit und Geheimhaltung der Daten der Inhaber gemäß den gültigen Vorschriften
              sicherzustellen.
            </p>

            <p class="payment-paragraph">
              Der Dienstleister behält sich das Recht vor, vorübergehend den Zugang zum Online-Konto aus technischen,
              Sicherheits- oder Wartungsgründen auszusetzen, ohne dass diese Vorgänge zu irgendeiner Entschädigung
            </p>


            <span class="payment-page-counter">12</span>


            <p class="payment-paragraph">
              berechtigen. Er verpflichtet sich, derartige Unterbrechungen auf das unbedingt notwendige Maß zu
              begrenzen.
            </p>

            <p class="payment-paragraph">
              Der Dienstleister kann jedenfalls nicht hinsichtlich des Inhabers für etwaige Fehler, Versäumnisse,
              Unterbrechungen oder Verzögerungen der über die Website ausgeführten Transaktionen haftbar gemacht
              werden, die durch einen nicht berechtigten Zugang für letzteren entstanden. Der Dienstleister kann nicht für
              Diebstähle, Zerstörungen oder unbefugte Datenübertragungen, die durch einen unberechtigten Zugang zur
              Website entstehen, haftbar gemacht werden. Außerdem bleibt der Dienstleister dem Rechtsverhältnis, das
              zwischen Inhaber und Nutzer bzw. zwischen Inhaber und Website besteht, fremd. Der Dienstleister kann
              nicht für Fehler, Mängel oder Fahrlässigkeit eines Nutzers und des Inhabers untereinander bzw. der Website
              und des Inhabers untereinander haftbar gemacht werden.
            </p>

            <p class="payment-paragraph">
              Wenn der Kundenidentifikator oder jede sonstige für die Ausführung eines vom Inhaber bereitgestellten
              Zahlungsvorgangs ungenau ist, ist der Dienstleister nicht für die falsche Ausführung besagter Dienstleistung
              verantwortlich
            </p>

            <p class="payment-paragraph">
              Die Plattform ist allein verantwortlich für die Sicherheit und Geheimhaltung der ausgetauschten Daten im
              Rahmen der Nutzung der Website entsprechend den allgemeinen Geschäftsbedingungen der Website, wobei
              der Dienstleister für die Sicherheit und Geheimhaltung der Daten verantwortlich ist, die er mit dem Inhaber
              im Rahmen dieses Vertrags zur Erstellung und Führung seines Kontos austauscht sowie mit dem Konto
              verbundene Zahlungsvorgänge.
            </p>


            <span class="paragraph-number">15. Haftungsbeschränkung des Dienstleisters</span>


            <p class="payment-paragraph">
              Der Dienstleister mischt sich in keiner Weise in die Rechts- und Geschäftsbeziehungen und etwaige
              Rechtsstreitigkeiten zwischen Inhaber und Nutzer oder zwischen Inhaber und Plattform bzw. Inhaber und
              Zahlungsempfänger ein. Der Dienstleister übt keinerlei Kontrolle über die Konformität, Sicherheit,
              Rechtmäßigkeit, Eigenschaften und die Eignung der Produkte und Dienstleistungen aus, die Gegenstand
              eines Zahlungsvorgangs sind.
            </p>

            <p class="payment-paragraph">
              Jede vom Inhaber ausgeführte Transaktion führt zu einem Vertrag, der direkt zwischen ihm und einem
              Nutzer zustande kommt, für den der Dienstleister fremd ist. Letzterer kann folglich nicht für die
              Nichterfüllung oder mangelhafte Erfüllung der daraus entstehenden Pflichten haftbar gemacht werden, noch
              für etwaige dem Inhaber zugefügte Schäden.
            </p>

            <p class="payment-paragraph">
              JUngeachtet anderslautender Bestimmungen in diesem Vertrag ist die Haftung des Dienstleister gegenüber
              einem Inhaber auf direkte Schäden, wie von der Verordnung vorgesehen, begrenzt.
            </p>

            <span class="paragraph-number">16. Verpflichtungen des Inhabers</span>

            <p class="payment-paragraph">
              Der Inhaber garantiert, dass kein Element seines persönlichen Bereiches die Rechte Dritter beeinträchtigt
              noch gegen das Gesetz, die öffentliche Ordnung oder die guten Sitten verstößt.
              Er verpflichtet sich, Folgendes zu unterlassen:
            </p>

            <p class="with-numerals"><span class="numerals">(i)</span>
              Ein Widerspruchsantrag gilt als erfolgt zum Datum und zur Uhrzeit des tatsächlichen Empfangs des Antrags
              durch den Händler. Bei Diebstahl oder betrügerischer Nutzung des Identifikators ist der Emittent
              berechtigt,
              über den Händler eine Quittung oder eine Kopie der eingereichten Klage beim Nutzer zu fordern, der sich
              verpflichtet, darauf unverzüglich zu antworten
            </p>

            <p class="with-numerals"><span class="numerals">(ii)</span>
              sich für eine andere Person oder Organisation ausgeben, seine Identität, sein Alter fälschen oder
              verschleiern oder eine falsche Identität erstellen;
            </p>

            <p class="with-numerals"><span class="numerals">(iii)</span>
              Persönliche Daten oder Informationen für einen Dritten verbreiten wie Anschriften,
              Telefonnummern, elektronische Adressen, Bankkartennummern usw. Bei Pflichtverletzungen
              kann der Dienstleister jede geeignete Maßnahme ergreifen, um die betreffenden
              Machenschaften einzustellen. Er ist auch zur Aussetzung, Löschung und/oder Sperrung des
              Zugangs des Inhabers auf sein Konto berechtigt.
            </p>


            <span class="payment-page-counter">13</span>


            <p class="with-numerals"><span class="numerals">(iv)</span>
              EUnbeschadet gerichtlicher Klagen durch Dritte ist der Dienstleister berechtigt, persönlich
              gerichtlich vorzugehen zur Wiedergutmachung der Schäden, der er persönlich aufgrund der
              Versäumnisse des Inhabers in Anbetracht von dessen Pflichten für diesen Vertrag erlitten hat.
            </p>

            <p class="payment-paragraph">
              Wenn der Inhaber eine Verletzung der oben genannten Verpflichtungen feststellt, wird er aufgefordert, den
              Dienstleister über diese Machenschaften zu unterrichten, indem er sich unter der Adresse
              <a href="mailto:legal@mangopay.com">legal@mangopay.com</a> mit ihm in Verbindung setzt.
            </p>

            <span class="paragraph-number">17. Widerrufsrecht</span>
            <span class="paragraph-number">17.1. Geltende Bestimmungen für den Verbraucherinhaber</span>

            <p class="payment-paragraph">
              Gemäß Artikel L222-7 des französischen Verbrauchergesetzbuches verfügt der Verbraucherinhaber über
              ein Widerrufsrecht, das innerhalb von 14 Tagen (vierzehn) ausgeübt werden kann ohne Angabe von
              Gründen und Zahlung von Vertragsstrafen. Diese Widerrufsfrist beginnt entweder ab dem Tag des
              Abschlusses des Rahmenvertrags oder ab Eingang der Vertragsbedingungen und -informationen, wenn
              dieses Datum nach dem Datum des Vertragsschlusses liegt. Der Rahmenvertrag kann vor Ablauf der
              Widerrufsfrist nur mit Zustimmung des Verbraucherinhabers mit der Durchführung beginnen. Der
              Verbraucherinhaber erkennt an, dass die Nutzung der Zahlungsdienste nach Abschluss des Rahmenvertrags
              eine ausdrückliche Forderung seinerseits darstellt, um mit der Durchführung des Rahmenvertrags vor
              Ablauf der oben genannten Frist zu beginnen. Die Ausübung des Widerrufsrechts bringt die Auflösung des
              Rahmenvertrags mit sich, der bei Ausführungsbeginn einer Kündigung gleichkommt und die vorher
              ausgeführten Leistungen in Frage stellt. In diesem Fall ist der Verbraucherinhaber nur zur anteilsmäßigen
              Bezahlung der tatsächlich bereitgestellten Dienstleistungen verpflichtet.
            </p>

            <span class="paragraph-number">17.2. Ausübung des Widerrufsrechts</span>

            <p class="payment-paragraph">
              Der Inhaber muss seine Widerrufserklärung fristgerecht dem Kundendienst der Plattform telefonisch oder
              per E-Mail mitteilen und ein Bestätigungsschreiben an die Anschrift des Kundendienst der Plattform richten.
              Diesbezüglich kann er den durch die Plattform zur Verfügung gestellten Widerrufsschein
              verwenden.
            </p>

            <span class="paragraph-number">18. Regeln zur Bekämpfung der Geldwäsche und der Terrorismusfinanzierung</span>

            <p class="payment-paragraph">
              Der Dienstleister unterliegt der luxemburgischen Gesetzgebung zur Bekämpfung der Geldwäsche und der
              Terrorismusfinanzierung.
            </p>

            <p class="payment-paragraph">
              In Anwendung der Bestimmungen luxemburgischen Rechts zur Mitwirkung der Finanzinstitutionen bei der
              Bekämpfung der Geldwäsche und der Terrorismusfinanzierung ist der Dienstleister verpflichtet, sich bei
              jedem Inhaber für jede Transaktion oder Geschäftsbeziehung über die Herkunft, den Gegenstand und das
              Ziel der Transaktion oder der Kontoeröffnung zu informieren. Er muss darüber hinaus alle erforderlichen
              Schritte zur Identifikation des Inhabers und gegebenenfalls des tatsächlichen Nutzungsberechtigten des
              Kontos und/oder mit ihm verbundenen Zahlungsvorgängen ausführen.
            </p>

            <p class="payment-paragraph">
              Der Inhaber erkennt an, dass der Dienstleister die Nutzung der Identifikationsdaten, den Zugang zu einem
              Konto oder die Ausführung einer Transaktion in Ermangelung eines hinreichenden Bestandteils zu seinem
              Gegenstand oder seiner Beschaffenheit jederzeit beenden oder verschieben kann. Er wird informiert, dass
              eine Transaktion im Rahmen dieses Vertrags Gegenstand der Ausübung des Kommunikationsrechts der
              zentralen Meldestelle ist.
            </p>


            <span class="payment-page-counter">14</span>


            <p class="payment-paragraph">
              Der Inhaber kann gemäß der Gesetzgebung auf sämtliche so übermittelte Informationen zugreifen, sofern
              dieses Zugangsrecht nicht die Zweckbestimmung der Bekämpfung der Geldwäsche und der
              Terrorismusfinanzierung in Frage stellt, wenn sich diese Daten auf den Antragsteller beziehen.
            </p>

            <p class="payment-paragraph">
              Weder Strafverfolgung noch eine zivilrechtlichen Verantwortlichkeitsklage können erhoben werden, noch
              Sanktionen durch ihren Berufsstand gegenüber dem Dienstleister, seinen Geschäftsführern oder seine
              Angestellten, die in gutem Glauben die Verdachtsmeldungen bei der nationalen Behörde gemacht haben.
            </p>

            <span class="paragraph-number">19. Datenschutz </span>

            <p class="payment-paragraph">
              Der Dienstleister erfasst und bearbeitet sämtliche personenbezogene Daten in Einklang mit den geltenden
              Vorschriften zum Datenschutz.
            </p>

            <p class="payment-paragraph">
              Bei Anfechtung obliegt die Beweislast, dass der Zahlungsvorgang authentifiziert, ordnungsgemäß erfasst
              und verbucht war und nicht von einem technischen Mangel oder dergleichen beeinträchtigt war, dem
              Emittenten.
            </p>

            <p class="payment-paragraph">
              Die bei der Anmeldung erforderlichen personenbezogenen Daten im Rahmen der gemäß diesem Vertrag
              bereitgestellten Dienstleistungen Mangels Bereitstellung der vorgeschriebenen personenbezogenen Daten
              kann der Antragsteller sich mit einer Ablehnung des Zugangs zu den Dienstleistungen konfrontiert sehen.
            </p>

            <p class="payment-paragraph">
              Die betroffene Person wird informiert, dass die personenbezogenen Daten insbesondere zu folgenden
              Zwecken erfasst werden: Bereitstellung der Dienstleistungen wie in diesem Vertrag beschrieben;
              Bekämpfung der Geldwäsche und der Terrorismusfinanzierung; Bearbeitung der Informations- und
              Beschwerdeanträge; Erstellung von Statistiken. Diese Bearbeitungen sind insbesondere zur Durchführung
              des Rahmenvertrags sowie zur Einhaltung rechtlicher Pflichten notwendig, denen die Verantwortlichen für
              die Bearbeitung unterliegen. Der Dienstleister und die Plattform handeln als gemeinsame Verantwortliche
              für diese Behandlungen.
            </p>

            <p class="payment-paragraph">
              Personenbezogene Daten dürfen keinem Dritten ohne ausdrückliche Zustimmung der betroffenen Personen
              übertragen werden. Jedenfalls wird jede betroffene Person unterrichtet, dass die personenbezogenen Daten
              an Subunternehmer übermittelt werden zur Erfüllung der vorab angeführten Zwecke. Besagte
              Subunternehmer handeln nur auf Anweisung des Dienstleisters und ausschließlich für Rechnung des
              letzteren.
            </p>

            <p class="payment-paragraph">
              Die betroffene Person kann auf die Liste der Subunternehmer zugreifen durch Übertragung des Antrags an
              den Kundendienst der Plattform. Sie wird unterrichtet, dass der Dienstleister sicherstellt, dass seine
              Subunternehmer alle notwendigen Maßnahmen ergreifen, damit die Sicherheit und Geheimhaltung der
              personenbezogenen Daten gewahrt wird. Bei Eintreten einer Verletzung der Daten (Verlust, Eingriff,
              Zerstörung usw.), die erhöhte Risiken einschließen für die betroffene Person, wird diese darüber informiert.
            </p>

            <p class="payment-paragraph">
              Der Dienstleister behält sich das Recht zur Offenlegung personenbezogener Daten auf Antrag einer
              gesetzlichen Behörde vor, um alle geltenden Gesetze und Bestimmungen zu erfüllen, um die Rechte des
              Inhabers des Kontos oder einer betroffenen Person zu schützen oder zu verteidigen, wenn zwingende
              Umstände es rechtfertigen oder um die Sicherheit des Inhabers, der Dienste oder der Öffentlichkeit zu
              schützen.
            </p>

            <p class="payment-paragraph">
              Die vom Dienstleister im Rahmen der bereitgestellten Dienstleistungen bearbeiteten personenbezogenen
              Daten gemäß diesem Vertrag werden während der unbedingt erforderlichen Laufzeit bewahrt, um die oben
              erwähnten Ziel zu erreichen. Vorbehaltlich anders lautender gesetzlicher Bestimmungen werden die Daten
              nicht über den Stichtag der Kündigung des Vertrags hinaus aufbewahrt. Es wird unter anderem deutlich
              gemacht, dass personenbezogene Daten zur Identifikation fünf Jahre lang ab dem Ende der
              Vertragsbeziehung aufbewahrt werden kraft geltender Bestimmungen zur Bekämpfung der Geldwäsche und
              der Terrorismusfinanzierung.
            </p>


            <span class="payment-page-counter">15</span>


            <p class="payment-paragraph">
              Die betroffenen personen verfügen über folgende Rechte zu ihren Daten gemäß den durch die
              Bestimmungen vorgesehenen Bedingungen: Zugangsrecht, Berichtigungsrecht, Widerspruchsrecht,
              Löschungsrecht, Recht auf eingeschränkte Verarbeitung und Recht auf Übertragbarkeit. Eine betroffene
              Person kann jederzeit ihre Rechte ausüben, indem sie sich an den Kundendienst der Plattform wendet. Ihr
              Antrag muss den Namen, Vornamen und Identifikator angeben und mit einer Fotokopie eines
              Ausweisdokuments mit Unterschrift versehen sein.
            </p>

            <p class="payment-paragraph">
              Eine Antwort ergeht an die betroffene Person innerhalb eines (1) Monats nach Eingang des Antrags. Diese
              Frist kann um zwei (2) Monate in Anbetracht der Komplexität der Anzahl an Anträgen verlängert werden.
              In diesem Fall wird die betroffene Person über die Verlängerung und die Gründe der Verschiebung innerhalb
              eines (1) Monats ab Eingang des Antrags informiert.
            </p>

            <p class="payment-paragraph">
              Die betroffene Person wird informiert, dass sie über das Recht verfügt, eine Beschwerde bei der zuständigen
              Behörde einzureichen für jeden Antrag in Verbindung mit ihren personenbezogenen Daten.
            </p>

            <p class="payment-paragraph">
              Wenn die betroffene Person ihren Antrag elektronisch einreicht, erhält sie die Antwort auf elektronischem
              Wege, sofern sie nicht ausdrücklich eine andere Art ersucht.
            </p>

            <p class="payment-paragraph">
              Wenn die personenbezogenen Daten sich auf eine betroffene Person beziehen, die nicht Teil des
              Rahmenvertrags ist, und vom Inhaber übermittelt wurden, macht es sich dieser zur Aufgabe, der betroffenen
              Person die Informationen dieses Artikels zu übermitteln.
            </p>

            <p class="payment-paragraph">
              Ergänzende Informationen über die Verarbeitung personenbezogener Daten im Rahmen dieses Vertrags,
              die Aufbewahrungszeiten und zu den Rechten der betroffenen Personen sind erhältlich in der
              Datenschutzerklärung des Dienstleisters (zugänglich auf der Website
              <a href="https://www.mangopay.com/">www.mangopay.com</a>).
            </p>

            <span class="paragraph-number">20. Betriebsgeheimnis</span>

            <p class="payment-paragraph">
              Der Dienstleister ist an die Schweigepflicht gebunden. Dieses Geheimnis kann jedoch gemäß geltender
              Gesetzgebung, kraft einer gesetzlichen und aufsichtsrechtlichen Verpflichtung, insbesondere auf Antrag der
              vormundschaftlichen Behörden, der Steuer- und Zollverwaltung sowie des Strafrichters oder bei
              gerichtlicher Beschlagnahme, die dem Dienstleister mitgeteilt wird, aufgehoben werden. Ungeachtet des
              Vorstehenden kann der Nutzer den Dienstleister von der Schweigepflicht entbinden und ihm ausdrücklich
              die Dritten angeben, die zum Erhalt der ihn betreffenden vertraulichen Informationen berechtigt sind.
            </p>

            <p class="payment-paragraph">
              Der Nutzer muss in seinem Kündigungsschreiben seine Bankverbindung oder Zahlungsinformationen
              angeben, die es dem Emittenten ermöglichen, ihm das verfügbare E-Geld zu erstatten. Fehlt die Angabe,
              obliegt es dem Emittenten, die Rückerstattungsanweisungen zu befolgen, die die Rückerstattung durch
              Gutschrift auf der Karte voraussetzen, die zum Erwerb des E-Geldes diente. Der Emittent von von jeder
              Verpflichtung entbunden, da er dem Nutzer die Überweisung auf das angegebene Konto bzw. die Gutschrift
              des E-Geld-Betrages auf seiner Karte bestätigt hat.
            </p>

            <p class="payment-paragraph">
              Es wird festgehalten, dass die Schweigepflicht aufgehoben werden kann durch die Regelung zugunsten der
              Gesellschaften, die dem Dienstleister wichtige operative Aufgaben im Rahmen dieses Vertrags bereitstellen.
            </p>

            <span class="paragraph-number">21. Geistiges Eigentum</span>

            <p class="payment-paragraph">
              Der Dienstleister behält sich das volle Eigentum der Wertpapiere und Eigentumsrechte vor, unabhängig
              davon, wie sie mit den angebotenen Dienstleistungen für den Inhaber verbunden sind. Keines dieser Rechte
              wird auf den Inhaber im Sinne dieses Vertrags übertragen.
            </p>

            <span class="paragraph-number">21. Geistiges Eigentum</span>
            <span class="paragraph-number">22.1. Ableben des Inhabers</span>

            <p class="payment-paragraph">
              Das Ableben des Inhabers beendet den Rahmenvertrag, sobald der Dienstleister davon Kenntnis erhält.
              Transaktionen, die ab dem Tod stattfinden, gelten als nicht genehmigt, vorbehaltlich des Einverständnisses
              der Rechteinhaber, die mit der Nachfolge beauftragt sind.
            </p>


            <span class="payment-page-counter">16</span>


            <p class="payment-paragraph">
              Das Zahlungskonto bleibt so lange geöffnet, wie es für die Regelung der nachfolge notwendig ist, und der
              Dienstleister stellt die Restzahlung sicher mit Einverständnis der mit der Nachfolge beauftragten
              Rechteinhaber oder des Notars.
            </p>

            <span class="paragraph-number">22.2. Inaktive Konten</span>

            <p class="payment-paragraph">
              Jedes inaktive Konto kann Gegenstand einer Inaktivitätsmitteilung per E-Mail seitens des Dienstleisters
              gefolgt von einer Mahnung einen Monat später sein. Das Zahlungskonto des Inhabers wird als inaktiv
              betrachtet, wenn nach einem Zeitraum von zwölf (12) Monaten keinerlei Transaktion (außer Abbuchung
              der Kontoführungsgebühren) auf Veranlassung des Inhabers (oder jedes Beauftragten) erfolgt ist und er sich
              nicht beim Dienstleister in welcher Form auch immer gemeldet hat.
            </p>

            <p class="payment-paragraph">
              Die Beziehungen zwischen den Parteien nach Inkrafttreten werden dann von der neuen Fassung des
              Rahmenvertrags geregelt.Mangels Antwort oder Verwendung des Restbetrags, der auf der Habenseite des Kontos in dieser Frist
              erscheint, kann der Dienstleister das Konto schließen und es ausschließlich zum Zwecke der Überweisung
              der fälligen Beträge auf dem vom Inhaber angegebenen Konto aufrechterhalten. Bei Ableben darf der
              Restbetrag nur an die Rechteinhaber des Inhabers erstattet werden.
            </p>

            <p class="payment-paragraph">
              Auf dem Konto können keine Zahlungsvorgänge mehr ausgeführt werden.
            </p>

            <span class="paragraph-number">23. Höhere Gewal</span>

            <p class="payment-paragraph">
              Die Parteien sind nicht haftbar für irgendein Versagen beim vorliegenden Vertrag bei Verzug oder
              Nichterfüllung, wenn deren Ursache mit höherer Gewalt, wie in Artikel 1218 des Code civil festgelegt,
              verbunden ist.
            </p>

            <span class="paragraph-number">24. Eigenständige Vertragsbestimmungen</span>

            <p class="payment-paragraph">
              Wird irgendeine Bestimmung dieses Vertrags als nichtig erachtet oder als gegenstandslos, wird sie
              gestrichen und hat nicht die Nichtigkeit der übrigen Vertragsbestimmungen zur Folge.
            </p>

            <p class="payment-paragraph">
              Wenn eine oder mehrere Bestimmungen dieses Vertrags ungültig werden oder als solche erklärt wurden in
              Anwendung eines Gesetzes, einer Verordnung oder infolge einer endgültigen Entscheidung durch ein
              zuständiges Gericht, bewahren die anderen Bestimmungen ihre vorgeschriebene Wirksamkeit und
              Tragweite. Die als nichtig und ungültig erklärten Bestimmungen werden dann durch Bestimmungen ersetzt,
              die sich sinngemäß un in ihrer Tragweite so weit wie möglich den ursprünglich vereinbarten Bestimmungen
              annähern.
            </p>

            <span class="paragraph-number">25. Schutz der Gelder</span>

            <p class="payment-paragraph">
              DDie Gelder des Inhabers werden am Ende des Geschäftstages nach dem Tag, im Laufe dessen sie beim
              Dienstleister eingegangen sind, auf einem Konto zur Beschränkung der Pfändung, das in den Büchern einer
              Bank unter den vorschriftsmäßig erforderlichen Bedingungen eröffnet wird, hinterlegt.
            </p>

            <p class="payment-paragraph">
              Gemäß Artikel 24-10 (5) des Gesetzes vom 20 Mai 2011, das im Amtsblatt Mémorial A Nr. 104 vom 24.
              Mai 2011 des Großherzogtums Luxemburg veröffentlicht wurde, und Artikel 14 des Gesetzes vom 10.
              November 2009, das im Mémorial A Nr. 215 vom 11. November 2009 des Großherzogtums Luxemburg
              veröffentlicht wurde, zur Umsetzung der Richtlinie 2009/110/EG des Europäischen Parlaments und des
              Rates vom 16. September 2009 über die Aufnahme, Ausübung und Beaufsichtigung der Tätigkeit von
              EGeld-Instituten, werden die aufgenommenen Mittel geschützt und fallen nicht in die Guthabenmasse
              des EGeld-Instituts bei Liquidation, Insolvenz oder jeder anderen Konkurslage des Instituts.
            </p>

            <span class="paragraph-number">26. Nichtabtretbarkeit</span>


            <span class="payment-page-counter">17</span>


            <p class="payment-paragraph">
              Der Rahmenvertrag darf zu keiner vollständigen bzw. teilweisen Abtretung entgeltlich noch unentgeltlich
              durch den Inhaber führen. Dadurch ist es untersagt, einem Dritten irgendwelche Rechte oder Pflichten, die
              er in diesem Vertrag hält, abzutreten. Bei Verletzung dieses Verbots neben der sofortigen Kündigung dieses
              Vertrags kann der Inhaber durch den Dienstleister haftbar gemacht werden.
            </p>

            <span class="paragraph-number">27. Rechtsverbindlicher Nachweis</span>

            <p class="payment-paragraph">
              Sämtliche unveränderlich, zuverlässig und sicher übernommenen Daten aus der Ddatenbank des
              Dienstleisters insbesondere für Zahlungsaufträge und verschickte Mitteilungen sind verbindlich zwischen
              den Parteien bis zum Beweis des Gegenteils.
            </p>

            <span class="paragraph-number">28. Beschwerde und Mediation</span>

            <p class="payment-paragraph">
              Der Inhaber wird aufgefordert, sich bei allen Beschwerden an den Kundendienst der Plattform zu wenden,
              der eventuell auf der Website angegeben ist.
            </p>

            <p class="payment-paragraph">
              Alle Beschwerden außer der in Artikel 10 zum Abschluss, zur Erfüllung oder Kündigung des
              Rahmenvertrags müssen per E-Mail unter folgender Adresse mitgeteilt werden:
              <a href="mailto:complaint@mangopay.com">complaint@mangopay.com.</a>
            </p>

            <p class="payment-paragraph">
              Der Inhaber akzeptiert, dass der Dienstleister auf seine Beschwerden auf dauerhaftem Datenträger
              antwortet. Die Antwort wird umgehend und spätestens binnen fünfzehn (15) Geschäftstagen nach Eingang
              der Beschwerde beim Dienstleister übermittelt. Aus Gründen, die sich jedoch seiner Kontrolle entziehen,
              kann der Dienstleister daran gehindert werden, innerhalb vvon fünfzehn (15) Tagen zu antworten.
              In diesem Fall übermittelt er dem Inhaber eine Antwort, die die Gründe dieses zusätzlichen Verzugs sowie
              das Datum, zu dem er die endgültige Antwort schickt, präzisiert. Auf jedem Fall erhält der Inhaber eine
              endgültige Antwort spätestens innerhalb von fünfunddreißig (35) Geschäftstagen nach Eingang der
              Beschwerde.
            </p>

            <p class="payment-paragraph">
              Der Inhaber wird unterrichtet, dass die CSSF (Commission de Surveillance du Secteur financier -
              Finanzmarktaufsichtsbehörde) zuständig für die außergerichtliche Regelung der Streitfälle zur Erfüllung
              dieses Rahmenvertrags ist. Für weitere Informationen zur CSSF und den Bedingungen einer solchen
              Rechtshilfe können Sie sich an den Kundendienst der Plattform wenden oder die Website der CSFF
              (<a href="https://www.cssf.lu/en/">http://www.cssf.lu</a>) zu Rate ziehen. Mediationsanträge müssen an den Schlichter der Commission de
              Surveillance du Secteur Financier (CSSF), 283 route d’Arlon, L-1150 Luxembourg, (<a href="mailto:direction@cssf.lu">direction@cssf.lu</a>)
              gerichtet werden, und dies ungeachtet sonstiger rechtlicher Schritte. Der Schlichter darf jedoch nicht
              beauftragt werden, wenn der Antrag offenkundig unbegründet oder missbräuchlich ist, wenn der
              Rechtsstreit vorher untersucht wurde oder die Untersuchung durch einen anderen Schlichter bzw. ein
              Gericht läuft, wenn der Antrag des Schlichters nach mehr als einem Jahr ab der schriftlichen Beschwerde
              beim Fachmann eingereicht wurde, wenn der Rechtsstreit nicht in den Zuständigkeitsbereich des Schlichters
              fällt.
            </p>

            <span class="paragraph-number">29. Geltendes Recht und Gerichtsstand</span>

            <p class="payment-paragraph">
              Außer bei Anwendung eines Gesetzes über die öffentliche Ordnung (das nur innerhalb der strengen Grenzen
              seines Zwecks gilt)wird ausdrücklich festgelegt, dass vertraglichen Beziehungen ist und der Rahmenvertrag
              französischem Recht unterliegt. Jeder Rechtsstreit zwischen den Parteien in Bezug auf diesen Vertrag
              unterliegt der Rechtsprechung der zuständigen französischen Gerichte.
            </p>


            <span class="payment-page-counter">18</span>


          </div>
        </div>
</template>

<script>

export default {
  name: "PaymentServiceProviderTermsAndConditions",
  data() {
    return {
      termsConditions: null,
      loadFlag: false,

    }
  },
  methods: {
    goTo(refName) {
      let element = this.$refs[refName]
      element.scrollIntoView({behavior: 'smooth'})
    },
  },
  mounted() {
    document.title = 'Payment Service Provider Terms and Conditions'
    this.goTo('top')


  },
}
</script>

<style scoped>


.v-application a {
  color: #FF0090;
}

.payment-text {
  margin: 0 auto;
  width: 100%;
  /*max-width: 747px;*/
  padding: 0 15px;
}

.payment-title {
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
}

.payment-subtitle {
  text-align: center;
  font-size: 18px;
  margin: 15px 0;
}

.subtitle-sub-descr {
  display: block;
  text-align: right;
  font-style: italic;
}

/*.payment-date {*/
/*  margin: 30px 0 0 0;*/
/*  display: block;*/
/*  font-weight: 600;*/
/*  text-align: right;*/
/*  font-style: italic;*/
/*}*/

.payment-paragraph-title {
  margin: 15px 0 0 0;
  display: block;
}

.payment-paragraph {
  margin: 15px 0 0 0;
  text-align: justify;
}

.payment-paragraph-terms {
  display: block;
  text-align: right;
  margin: 15px 0;
}

.payment-paragraph-terms_margin {
  text-align: inherit;
  margin-left: 35%;
}

.payment-uppercase {
  text-transform: uppercase;
  font-size: inherit;
  font-weight: 600;
}

.payment-terms-colored {
  margin-top: 50px;
  margin-bottom: 25px;
  background: #FFFFFF;
  border: 1px solid #000;
  padding: 5px 10px;
}

.terms-colored-text {
  margin: 20px 0 10px 0;
}

.payment-page-counter {
  margin: 50px 0 50px 0;
  font-weight: 600;
  display: block;
  text-align: right;
}

.terms-colored-title {
  display: block;
  text-decoration: underline;
}

.paragraph-number {
  margin-top: 15px;
  margin-left: 50px;
  display: block;
  font-weight: 600;
}

.paragraph-number_light {
  margin-top: 15px;
  margin-left: 50px;
  display: block;
}

.paragraph_margin {
  margin-top: 5px;
  padding-top: 10px;
}

p.with-circle,
p.with-dash,
p.with-numerals {
  margin: 0;
}

.with-dash {
  display: block;
  position: relative;
  padding-left: 50px;
}

.with-dash:before {
  position: absolute;
  top: 12px;
  transform: translateY(-50%);
  left: 30px;
  display: block;
  content: '-';
}

.with-circle {
  position: relative;
  padding-left: 100px;
}

.with-circle:before {
  position: absolute;
  top: 15px;
  left: 80px;
  display: block;
  border-radius: 50%;
  border: .5px solid #000;
  content: '';
  width: 7px;
  height: 7px;
}

.with-numerals {
  display: block;
  position: relative;
  padding-left: 100px;
}

.numerals {
  position: absolute;
  left: 50px;
  top: 5%;
}

.payment-header-logo {
  width: 300px;
  height: 50px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 992px) {

  .payment-paragraph-terms {
    display: block;
    text-align: right;
    margin: 15px 0;
  }

  .payment-paragraph-terms_margin {
    text-align: inherit;
    margin-left: 5%;
  }

}

@media only screen and (max-width: 768px) {

  .payment-text {
    padding: 0;
  }

  .payment-page-counter {
    margin: 30px 0;
  }

  .payment-paragraph-terms_margin {
    text-align: right;
    margin-left: 0;
    margin-right: 5%;
  }

}

@media only screen and (max-width: 540px) {

  .container-small {
    padding: 0 10px;
  }

  .payment-header-logo {
    margin-bottom: 20px;
  }

  .payment-title {
    font-size: 22px;
  }

  .payment-subtitle {
    font-size: 17px;
  }

  .payment-text {
    font-size: 15px;
  }

  .payment-page-counter {
    margin: 20px 0;
  }

  .payment-paragraph {
    text-align: left;
  }

  .paragraph-number_light,
  .paragraph-number {
    margin-top: 10px;
    margin-left: 30px;
  }

  .with-dash {
    padding-left: 34px;
  }

  .with-dash:before {
    left: 17px;
  }

  .with-circle {
    padding-left: 65px;
  }

  .with-circle:before {
    transform: translateY(-50%);
    left: 40px;
  }

  .with-numerals {
    padding-left: 65px;
  }

  .numerals {
    left: 25px;
    top: 5%;
  }

}

@media only screen and (max-width: 375px) {

  .container-small {
    padding: 0;
  }

  .payment-header-logo {
    width: 200px;
    height: 40px;
    object-fit: contain;
    margin-bottom: 15px;
  }

  .payment-title {
    font-size: 20px;
  }

  .payment-subtitle {
    font-size: 16px;
  }

  .payment-text {
    font-size: 14px;
  }

  .payment-terms-colored {
    margin: 25px 0 15px 0;
  }

  .terms-colored-text {
    margin: 10px 0 5px 0;
  }

  .payment-subtitle,
  .payment-paragraph-title,
  .payment-paragraph {
    margin-top: 10px;
  }

  .payment-page-counter {
    margin: 15px 0;
  }

  .paragraph_margin {
    padding-top: 5px;
  }

}


</style>
