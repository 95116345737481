import moment from "moment";


export default {
  methods: {
    toLocalDate(date) {
      if (!date) {
        return ''
      }

      let timeUtc = moment.utc(date).local()
      return timeUtc ? moment(String(timeUtc)).format('yyyy-MM-DD HH:mm') : date
    },
  },
}