<template>
  <div id="my-content" ref="top">
    <Header/>
    <main id="root">
      <section class="section section-contact_us">
        <div class="contact_us-page" v-if="loadFlag">
          <div class="form-wrap feedback-form-wrap contact_us-form-wrap">
            <div class="form-logo-wrap">
              <img src="@/assets/img/Union.svg" alt="Form logo" class="form-logo">
            </div>
            <span class="form-subtitle">Feedback Form</span>
            <form @submit.prevent="onSubmit" class="contact-us-form" novalidate>
              <p><input type="hidden" name="csrfmiddlewaretoken"
                        value="lyhlrDsnol7FIXXmfb45TDwu8v5PzyGVaS5k0ZJhA33tDrwV4NffU1zSGSyCEHVd"></p>

              <div class="feedback-form-fileds">
                <input type="text" name="name" id="id_name" placeholder="Name" v-model="feedbackObj.name">
                <p class="error-message" v-if="$v.feedbackObj.name.$dirty && !$v.feedbackObj.name.required">Field is
                  required</p>
              </div>

              <div class="feedback-form-fileds">
                <input type="email" name="email" id="id_email" placeholder="Email" v-model="feedbackObj.email">
                <p class="error-message" v-if="$v.feedbackObj.email.$dirty && !$v.feedbackObj.email.required">Field is
                  required</p>
                <p class="error-message" v-else-if="!$v.feedbackObj.email.email">this must be
                  an email</p>
              </div>


              <div class="feedback-form-fileds">
              <textarea name="message" cols="40" rows="10" required="" id="id_message" aria-required="true"
                        v-model="feedbackObj.message"
                        placeholder="Message"></textarea>
                <p class="error-message" v-if="$v.feedbackObj.message.$dirty && !$v.feedbackObj.message.required">Field is
                  required</p>
              </div>

              <button type="submit" class="btn btn-hover">Send</button>
            </form>
          </div>

          <div class="contact_us__info-wrap">
            <h1 class="page-title separator">Contact us</h1>
            <div class="contact_us__info-section">
              <p class="contact_us__info-subtitle">Social media:</p>

              <ul class="contact_us__info-social-list">

                <li class="contact_us__info-social-item">
                  <a :href="twitterLink" class="contact_us__info-social-item-link">
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M32.5833 4.2496C31.2267 5.20652 29.7246 5.93842 28.135 6.4171C27.2818 5.43607 26.1478 4.74074 24.8866 4.42516C23.6253 4.10957 22.2976 4.18896 21.0829 4.65257C19.8682 5.11619 18.8253 5.94167 18.095 7.01736C17.3648 8.09306 16.9825 9.36707 17 10.6671V12.0838C14.5104 12.1483 12.0434 11.5962 9.81889 10.4765C7.59435 9.35679 5.68124 7.70433 4.24996 5.66626C4.24996 5.66626 -1.41671 18.4163 11.3333 24.0829C8.41571 26.0634 4.9401 27.0564 1.41663 26.9163C14.1666 33.9996 29.75 26.9163 29.75 10.6246C29.7487 10.23 29.7107 9.83636 29.6366 9.44876C31.0825 8.02288 32.1028 6.22261 32.5833 4.2496V4.2496Z"
                            stroke="#FE0190" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </a>
                </li>

                <li class="contact_us__info-social-item">
                  <a :href="instagramLink" class="contact_us__info-social-item-link">
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M24.0834 2.83301H9.91671C6.00469 2.83301 2.83337 6.00432 2.83337 9.91634V24.083C2.83337 27.995 6.00469 31.1663 9.91671 31.1663H24.0834C27.9954 31.1663 31.1667 27.995 31.1667 24.083V9.91634C31.1667 6.00432 27.9954 2.83301 24.0834 2.83301Z"
                            stroke="#FE0190" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M22.6718 16.1079C22.8467 17.2869 22.6453 18.4911 22.0963 19.549C21.5474 20.607 20.6788 21.4649 19.6141 22.0008C18.5495 22.5367 17.343 22.7232 16.1662 22.5338C14.9894 22.3445 13.9023 21.7889 13.0595 20.9461C12.2167 20.1033 11.6611 19.0162 11.4718 17.8394C11.2824 16.6626 11.4689 15.4561 12.0048 14.3915C12.5407 13.3268 13.3986 12.4582 14.4566 11.9093C15.5145 11.3603 16.7186 11.1589 17.8977 11.3338C19.1003 11.5121 20.2137 12.0725 21.0734 12.9322C21.9331 13.7919 22.4935 14.9053 22.6718 16.1079Z"
                            stroke="#FE0190" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M24.7916 9.20801H24.8054"
                            stroke="#464646" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </a>
                </li>

                <li class="contact_us__info-social-item">
                  <a :href="facebookLink" class="contact_us__info-social-item-link">
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M25.5 2.83301H21.25C19.3713 2.83301 17.5697 3.57929 16.2413 4.90767C14.9129 6.23605 14.1666 8.03772 14.1666 9.91634V14.1663H9.91663V19.833H14.1666V31.1663H19.8333V19.833H24.0833L25.5 14.1663H19.8333V9.91634C19.8333 9.54062 19.9825 9.18028 20.2482 8.91461C20.5139 8.64893 20.8742 8.49967 21.25 8.49967H25.5V2.83301Z"
                            stroke="#FE0190" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </a>
                </li>

                <li class="contact_us__info-social-item">
                  <a :href="tikTokLink" class="contact_us__info-social-item-link">
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M23.5166 8.245C22.5482 7.13946 22.0146 5.7197 22.015 4.25H17.6375V21.8167C17.6037 22.7673 17.2024 23.6677 16.518 24.3283C15.8336 24.9889 14.9195 25.3582 13.9683 25.3583C11.9566 25.3583 10.285 23.715 10.285 21.675C10.285 19.2383 12.7499 18.1617 13.6425 18.1617V13.685C9.46408 13.685 5.89331 16.83 5.89331 21.675C5.89331 26.3925 9.80331 29.75 13.9541 29.75C18.4025 29.75 22.015 26.1375 22.015 21.675V12.7642C23.7901 14.039 25.9212 14.7229 28.1066 14.7192V10.3417C28.1066 10.3417 25.4433 10.4692 23.5166 8.245Z"
                            stroke="#FE0190" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </a>
                </li>

              </ul>

            </div>
            <div class="contact_us__info-section">
              <p class="contact_us__info-subtitle">Phone:</p>
              <a class="contact_us__info-contacts mb-4" href="tel:+4917648275551">+4917648275551</a>
              <a class="contact_us__info-contacts" href="tel:+380930001419">+380930001419</a>
            </div>
            <div class="contact_us__info-section">
              <p class="contact_us__info-subtitle">Email:</p>
              <a class="contact_us__info-contacts"
                 :href="`mailto:${supportEmail ? supportEmail : 'support@djookyx.com'}`"
              >support@djookyx.com</a>

            </div>
          </div>
        </div>
        <div class="preloader-wrapper" v-else>
          <v-progress-circular
              :size="75"
              :width="8"
              color="#FF0090"
              indeterminate
          ></v-progress-circular>
        </div>
      </section>
      <server-error-popup ref="serverErrorPopup" :errorMessage="errors.name"/>
      <success-popup ref="serverSuccessPopup" :errorMessage="errors.name" @confirmAction="confirmActionFunc"/>
    </main>
    <Footer/>
  </div>
</template>

<script>
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ServerErrorPopup from "../popups/ServerErrorPopup";
import SuccessPopup from "../popups/SuccessPopup";
import {required, email, minLength} from "vuelidate/lib/validators";

export default {
  name: "ContactUs",
  components: {Footer, Header, SuccessPopup, ServerErrorPopup},
  data() {
    return {
      loadFlag: false,
      supportEmail: null,
      copyrightFooter: null,
      facebookLink: null,
      instagramLink: null,
      tikTokLink: null,
      twitterLink: null,
      appStoreLink: null,
      googlePlayLink: null,
      errors: {
        name: null
      },
      feedbackObj: {
        name: '',
        email: '',
        message: ''
      }
    }
  },
  validations: {
    feedbackObj: {
      name: {
        required,
        minLength: minLength(2)
      },
      email: {
        email,
        required
      },
      message: {
        required
      }
    }
  },
  methods: {
    goTo(refName) {
      let element = this.$refs[refName]
      element.scrollIntoView({behavior: 'smooth'})
    },
    onSubmit() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      } else {
        this.$load(async () => {
          this.$api.contactUs.contactUs(this.feedbackObj).then(() => {
            this.feedbackObj.name = ''
            this.feedbackObj.email = ''
            this.feedbackObj.message = ''
            this.errors.name = 'Your message has been sent'
            this.$refs.serverSuccessPopup.openPopups()
            setTimeout(() => {
              this.$router.push({path: '/'})
            }, 3000)
          })
        }, (error) => {
          this.errors.name = error.data.errors[0].message
          this.$refs.serverErrorPopup.openPopups()
        })
      }

    },
    confirmActionFunc() {
      this.$router.push({path: '/'})
    }
  },
  beforeMount() {
  },
  mounted() {
    document.title = 'Contact us'
    this.goTo('top')

    this.$load(async () => {
      this.loadFlag = false
      let supportEmailResponse = (await this.$api.content.getSettings('supportEmail'))
      let copyrightFooterResponse = (await this.$api.content.getSettings('copyrightFooter'))
      let facebookLinkResponse = (await this.$api.content.getSettings('facebookLink'))
      let instagramLinkResponse = (await this.$api.content.getSettings('instagramLink'))
      let tikTokLinkResponse = (await this.$api.content.getSettings('tikTokLink'))
      let appStoreLinkResponse = (await this.$api.content.getSettings('appStoreLink'))
      let twitterLinkResponse = (await this.$api.content.getSettings('twitterLink'))
      let googlePlayLinkResponse = (await this.$api.content.getSettings('googlePlayLink'))

      this.supportEmail = supportEmailResponse.data.body.value
      this.copyrightFooter = copyrightFooterResponse.data.body.value
      this.facebookLink = facebookLinkResponse.data.body.value
      this.instagramLink = instagramLinkResponse.data.body.value
      this.tikTokLink = tikTokLinkResponse.data.body.value
      this.appStoreLink = appStoreLinkResponse.data.body.value
      this.twitterLink = twitterLinkResponse.data.body.value
      this.googlePlayLink = googlePlayLinkResponse.data.body.value
      this.loadFlag = true
    })

  }
}
</script>

<style scoped>

.error {
  font-size: 12px;
  color: #ff0090;
}

.feedback-form-fileds {
  margin-bottom: 30px;
  position: relative;
}

.feedback-form-fileds:last-child {
  margin-bottom: 0;
}

.error-message {
  color: red;
  position: absolute;
  margin: 0;
  bottom: -25px;
  left: 0;
}
</style>