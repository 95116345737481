<template>
  <v-dialog v-model="dialog">
    <div id="promo-code-popup" class="white-popup confirm-popup promo-code-popup">
      <div class="form-main-content">
        <div class="close-dialog" @click="closePopup">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5662 9.43466C20.2846 9.15069 19.9012 8.99097 19.5012 8.99097C19.1012 8.99097 18.7178 9.15069 18.4362 9.43466L15.0011 12.8846L11.566 9.43466C10.9778 8.84648 10.0241 8.84648 9.4359 9.43466C8.8477 10.0228 8.8477 10.9764 9.4359 11.5646L12.886 14.9996L9.4359 18.4345C9.15192 18.7161 8.99219 19.0995 8.99219 19.4995C8.99219 19.8994 9.15192 20.2828 9.4359 20.5645C9.71756 20.8484 10.101 21.0082 10.5009 21.0082C10.9009 21.0082 11.2843 20.8484 11.566 20.5645L15.0011 17.1145L18.4362 20.5645C18.7178 20.8484 19.1012 21.0082 19.5012 21.0082C19.9012 21.0082 20.2846 20.8484 20.5662 20.5645C20.8502 20.2828 21.0099 19.8994 21.0099 19.4995C21.0099 19.0995 20.8502 18.7162 20.5662 18.4345L17.1161 14.9996L20.5662 11.5646C20.8502 11.283 21.0099 10.8996 21.0099 10.4996C21.0099 10.0997 20.8502 9.7163 20.5662 9.43466ZM25.6057 4.39473C19.7493 -1.46464 10.2515 -1.46464 4.39351 4.39313C-1.46451 10.2509 -1.4645 19.7482 4.39352 25.606C10.2507 31.4645 19.7485 31.4645 25.6065 25.6068C31.4645 19.749 31.4645 10.2517 25.6065 4.39392L25.6057 4.39473ZM23.4918 23.4895C19.446 27.5396 13.1026 28.1681 8.34066 24.9906C3.57872 21.8131 1.72439 15.7146 3.91163 10.4244C6.09886 5.13411 11.7185 2.1254 17.3341 3.23811C22.9497 4.35082 26.9974 9.27507 27.0019 14.9996C27.0127 18.1847 25.7488 21.2418 23.4918 23.4895Z"/>
          </svg>
        </div>
        <div class="popup-header">
          <h3 class="form-title">Use Promo Code</h3>
        </div>
        <div class="popup-content">
          <div class="popup-content-container">
            <div class="promo-code-title">Use Promo Code</div>
            <input type="text" class="promo-code-input" placeholder="Your promo code" name="promocode"
                   v-model.trim="promoModel"
                   @input="updatePromoCode"
            >
            <div class="promo-code-confirm-wrap">
              <button class="btn promo-code-confirm-btn"
                      v-if="!disabledSubmitBtn"
                      @click="getPromoCode"
              >Use Promo Code</button>
              <div class="btn promo-code-confirm-btn disabled-btn"
                   v-else
              >Use Promo Code</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "PromoCodePopup",
  data() {
    return {
      dialog: false,
      promoModel: '',
      disabledSubmitBtn: true,
    };
  },
  props: {
    reset: Boolean,
  },
  methods: {
    openPopup() {
      this.dialog = true
    },
    closePopup() {
      this.dialog = false
    },
    getPromoCode() {
      this.$emit('getPromoCode')
      this.dialog = false
    },
    updatePromoCode(event) {
      this.$emit('updateChanges', event)
    },
  },
  watch : {
    reset(data) {
      if (data) {
        this.promoModel = ''
      }
    },
    promoModel() {
      this.promoModel ? this.disabledSubmitBtn = false : this.disabledSubmitBtn = true
    },
  },
};
</script>

<style>

.promo-code-popup .popup-header {
  background: #FF0090;
  border-radius: 5px 5px 0 0;
  margin-bottom: 0;
}

.promo-code-popup .form-main-content {
  border-radius: 5px;
}

.promo-code-popup .popup-content {
  width: 100%;
  box-sizing: border-box;
  padding: 38px 100px 50px 100px;
}

.promo-code-popup .close-dialog svg {
  fill: white;
  transition: .5s ease;
}

.promo-code-popup .close-dialog:hover svg {
  fill: black;
}

.promo-code-popup .promo-code-title {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #272727;
  margin-bottom: 28px;
}

.promo-code-popup .promo-code-input {
  width: 100%;
  padding: 14px 30px 12px 30px;
  border: 1px solid #C6C6C6;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #131415;
}

.promo-code-popup .promo-code-confirm-btn {
  max-width: 215px;
  padding: 15px 39px;
}

.promo-code-confirm-wrap {
  margin-top: 38px;
}

.promo-code-confirm-btn.disabled {
  cursor: default;
  background: #AEAEAE;
  border: 1px solid #AEAEAE;
}

.promo-code-confirm-btn.disabled:hover {
  cursor: default;
  background: #AEAEAE;
  color: #FFFFFF;
  border: 1px solid #AEAEAE;
}

@media only screen and (max-width: 540px) {
  .promo-code-popup .popup-content {
    padding: 20px 50px 30px 50px;
  }

  .promo-code-confirm-wrap {
    margin-top: 20px;
  }

  .promo-code-popup .promo-code-title {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 375px) {
  .promo-code-popup .popup-content {
    padding: 20px 25px 25px 25px;
  }
}

</style>