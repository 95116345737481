export default function(instance) {
    return {
        createBankAccount(body) {
            return instance.post(`/api/v2/payment/payout-data`, body)
        },
        getBankDetails() {
            return instance.get(`/api/v2/payment/bank-details`)
        },
        deleteBankAccount(id) {
            return instance.delete(`/api/v2/payment/bank-details/${id}`)
        },
        payout(body) {
            return instance.post(`/api/v2/payment/payout`, body)
        },
        // getCountries() {
        //     return instance.get(`/api/v2/payment/bank-details-countries`)
        // },
    }
}