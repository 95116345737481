<template>
  <div class="market__filter-wrap">

    <button
        class="market__filter-btn main"
        type="button"
        @click="toggleDialog"
        :disabled="!haveData"
        :class="[{'disabled-btn': !haveData}, {'open': dialog}]"
    >
      <span class="button-text">Filters</span>
      <svg class="button-icon"
           :style="{transform: dialog ? 'rotate(180deg)' : ''}"
           width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.3945 1L5.69727 5.69727L1 1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>

      <svg class="mobile-icon"
          width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 1H1L9 10.46V17L13 19V10.46L21 1Z" stroke="#FF0090" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>

      <span class="filtered-mark" v-if="haveFilters" v-text="1"></span>
    </button>

    <transition name="fade" tag="div">
      <div class="market__filter-dialog" v-if="dialog">
        <div class="market__filter-section">

          <div class="profile-subtitle-wrap">
            <h2 class="profile-subtitle market__filter-title">country</h2>
          </div>

          <CountrySelect class="market__filter-country_select"
                         :identifier="'market__filter-country'"
                         :placeholder="'Select'"
                         :search-for-name="currentCountry"
                         :options="searchedCountriesList"
                         :search="searchCountry"
                         @changed="setCountry"
                         @searchCheck="$emit('searchCheck', $event)"
                         ref="filterCountrySelect"
          />

        </div>
        <div class="market__filter-btn-wrap">
          <button class="market__filter-btn inverse"
                  type="button"
                  @click="resetFilters"
          ><span class="button-text">Reset</span></button>
          <button class="market__filter-btn"
                  type="button"
                  @click="setFilters"
          ><span class="button-text">Apply</span></button>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>

import CountrySelect from "@/components/inputs/CountrySelect";

export default {
  name: "SecondaryMarketFilterDialog",
  props: {
    countriesList: {
      type: Array,
      default: () => []
    },
    searchedCountriesList: {
      type: Array,
      default: () => []
    },
    searchCountry: String,
    currentCountry: String,
  },
  data() {
    return {
      dialog: false,
      dataObj: {
        country: null,
      },
    }
  },
  watch: {
    dialog() {
      this.$emit('openStatus', this.dialog)
    },
  },
  computed: {
    haveFilters() {
      return this.currentCountry ? true : !!this.dataObj.country
    },
    haveData() {
      return !!this.countriesList
    },
  },
  methods: {
    toggleDialog() {
      this.dialog = !this.dialog
    },
    openDialog() {
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    setCountry(data) {
      this.dataObj.country = data
    },
    resetFilters() {
      this.dataObj.country = null
      this.$emit('setFilters', this.dataObj)
      this.closeDialog()
    },
    setFilters() {
      this.$emit('setFilters', this.dataObj)
      this.closeDialog()
    },
    countryClickOutside() {
      if (!event.target.closest('.general_select') && this.dialog) {
        this.$refs.filterCountrySelect.closeList()
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.countryClickOutside)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.countryClickOutside)
  },
  components: {
    CountrySelect,
  }
}
</script>

<style>

.market__filter-dialog {
  position: absolute;
  top: 45px;
  right: 0;
  z-index: 11;
  width: 350px;
  min-height: 250px;
  background: #FFFFFF;
  border: 1px solid #DEDEDE;
  border-radius: 5px;
  padding: 40px;
}

.market__filter-dialog .profile-subtitle-wrap {
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.market__filter-dialog .profile-subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 4.8px;
}

.market__filter-country_select.general_select {
  height: 37px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
}

.market__filter-country_select .general_select-selected {
  padding-left: 15px;
}

.market__filter-country_select .input-search {
  padding: 0 15px;
  height: 37px;
}

.market__filter-section {
  margin-bottom: 50px;
}

.market__filter-btn-wrap {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.market__filter-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 6px 11px;
  max-width: 97px;
  width: 100%;
  text-align: center;
  background: #FF0090;
  border-radius: 5px;
  border: 1px solid #FF0090;
  transition: .4s ease;
}

.market__filter-btn .filtered-mark {
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -8px;
  right: -8px;
  background: #FF0090;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 10px;
  color: #FFFFFF;
}

.market__filter-btn .button-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  transition: .4s ease;
}

.market__filter-btn .button-icon {
  margin-left: 13px;
  stroke: white;
  margin-top: 2px;
  transition: .4s ease;
}

.market__filter-btn:hover .button-icon {
  stroke: #FF0090;
}

.market__filter-btn:hover {
  background: #FFFFFF;
}

.market__filter-btn:hover .button-text {
  color: #FF0090;
}

.market__filter-btn.inverse {
  background: #FFFFFF;
}

.market__filter-btn.inverse .button-text {
  color: #FF0090;
}

.market__filter-btn.inverse:hover {
  background: #FF0090;
}

.market__filter-btn.inverse:hover .button-text {
  color: #FFFFFF;
}

.market__filter-btn .mobile-icon {
  display: none;
}

.market__filter-wrap {
  position: relative;
}

/* animation  */

.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* end animation  */

@media only screen and (max-width: 992px) {
  .market__filter-btn.main .button-icon {
    display: none;
  }
  .market__filter-btn.main .button-text {
    display: none;
  }
  .market__filter-btn.main {
    border: none;
    background: transparent;
  }
  .market__filter-btn.open .mobile-icon {
    fill: #FF0090;
  }
  .market__filter-btn .mobile-icon {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .market__filter-wrap .select-scroll-container {
    height: 180px;
  }
  .market__filter-dialog {
    top: 45px;
    right: 0;
    z-index: 11;
    width: 280px;
    min-height: 200px;
    padding: 25px;
  }
}

</style>