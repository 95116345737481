<template>
  <li class="team-list-child-item">
    <div class="team-list-child-item__img-wrap">
      <img :src="teamElement.photo" alt="team image" class="team-list-child-item__img" loading="lazy">
    </div>
    <div class="team-list-child-item-content">
      <span class="team-list-child-item__position" v-text="teamElement.jobTitle"></span>
      <span class="team-list-child-item__name" v-text="teamElement.name"></span>
      <p class="team-list-child-item__descr-text">
        {{ description }}
        <span class="morelink" @click="openFullDescription" v-if="description.length < 101">read more</span>
        <span class="morelink" @click="closeFullDescription" v-else>hide</span>

      </p>
    </div>
  </li>
</template>

<script>
export default {
  name: "TeamCategoryItemList",
  props: {
    teamElement: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      description: ''
    }
  },
  methods: {
    openFullDescription() {
      this.description =  this.teamElement.description
    },
    closeFullDescription() {
      this.description =  this.teamElement.description.slice(0, 100)
    }
  },
  mounted() {
    this.description =  this.teamElement.description.slice(0, 100)
  }
}
</script>

<style scoped>

</style>