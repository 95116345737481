

export default function(instance) {

    return {
        getHTML(key) {
            return instance.get(`/api/v2/html/${key}`)
        },
        getSettings(key) {
            return instance.get(`/api/v2/setting/${key}`)
        },
        getMessage(key) {
            return instance.get(`/api/v2/message/${key}`)
        }
    }
}