<template>
  <v-dialog v-model="dialog">
    <div class="type-ieo-popup-server-error white-popup popup-form crop-image-popup confirm-popup">
      <div class="form-main-content">
        <div class="close-dialog" @click="dialog = false">
          <img src="../../assets/img/close.svg" alt="close-icon">
        </div>
        <div class="popup-header">
          <h3 class="form-title">Changing a photo:</h3>
        </div>
        <div class="popup-content">
          <div class="popup-content-container">
<!--            :src="image.src ? image.src : imageSrc ? imageSrc : defaultImage"-->
            <cropper
                style="height: 400px;"
                ref="cropper"
                :src="image.src || defaultImage"
                :auto-zoom="true"
                class="cropper"
                image-class="cropper__image"
                :stencil-props="{
        aspectRatio,
        class: 'cropper-stencil',
        previewClass: 'cropper-stencil__preview',
        draggingClass: 'cropper-stencil--dragging',
        handlersClasses: {
          default: 'cropper-handler',
          eastNorth: 'cropper-handler--east-north',
          westNorth: 'cropper-handler--west-north',
          eastSouth: 'cropper-handler--east-south',
          westSouth: 'cropper-handler--west-south',
        },
        handlersWrappersClasses: {
          default: 'cropper-handler-wrapper',
          eastNorth: 'cropper-handler-wrapper--east-north',
          westNorth: 'cropper-handler-wrapper--west-north',
          eastSouth: 'cropper-handler-wrapper--east-south',
          westSouth: 'cropper-handler-wrapper--west-south',
        },
      }"
            />
            <div class="crop-image-btn-wrap">
              <button class="btn btn-hover profile-btn btn-transparent" @click="$refs.file.click()">
                <input
                    type="file"
                    ref="file"
                    @change="uploadImage($event)"
                    accept="image/*"
                />
                Upload
              </button>
              <button class="btn btn-hover profile-btn" @click="cropImage">Crop</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </v-dialog>
</template>

<script>

import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "CropImagePopup",
  data() {
    return {
      dialog: false,
      image: {
        src: '',
      },
      // avatarImage: null,
      uploadedImg: null,
    }
  },
  props: {
    // imageSrc: String,
    defaultImage: String,
    index: Number,
    aspectRatio: Number,
    borderRadius: {
      type: String,
      default: '0',
    },
  },
  methods: {
    cropImage() {
      const result = this.$refs.cropper.getResult()
      // this.avatarImage = result.canvas.toDataURL('image/*')
      // this.$emit('cropImage', this.avatarImage)

      this.uploadedImg = result.canvas.toDataURL('image/*')
      this.$emit('cropImage', this.uploadedImg, this.index)
      this.dialog = false
    },
    uploadImage(event) {
      const { files } = event.target
      if (files && files[0]) {
        if (this.defaultImage) {
          URL.revokeObjectURL(this.defaultImage)
        }
        // if (this.imageSrc) {
        //   URL.revokeObjectURL(this.imageSrc)
        // }
        const blob = URL.createObjectURL(files[0])
        this.image = {
          src: blob,
          type: files[0].type,
        }
      }
    },
    openPopups() {
      this.dialog = true
    },
    confirmAction() {
      this.dialog = false
    },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        setTimeout(() => {
          let item = document.getElementsByClassName('cropper-stencil__preview')
          item[0].style.borderRadius = this.borderRadius
        })
      } else {
        this.uploadedImg = null
        this.image = {
          src: '',
          type: '',
        }
      }

    },
  },
  components: {
    Cropper,
  }
};
</script>

<style>
.cropper {
  min-height: 300px;
  width: 100%;
}

.btn input {
  display: none;
}

.cropper__image {
  opacity: 1;
}

.cropper-stencil__preview:after, .cropper-stencil__preview:before {
  content: "";
  opacity: 0;
  transition: opacity 0.25s;
  position: absolute;
  pointer-events: none;
  z-index: 1;
}
.cropper-stencil__preview:after {
  border-left: solid 1px white;
  border-right: solid 1px white;
  width: 33%;
  height: 100%;
  transform: translateX(-50%);
  left: 50%;
  top: 0;
}
.cropper-stencil__preview:before {
  border-top: solid 1px white;
  border-bottom: solid 1px white;
  height: 33%;
  width: 100%;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
}
.cropper-stencil--dragging .cropper-stencil__preview:after, .cropper-stencil--dragging .cropper-stencil__preview:before {
  opacity: 0.4;
}
.cropper-line {
  border-color: rgba(255, 255, 255, 0.8);
}
.cropper-handler {
  display: block;
  opacity: 0.7;
  position: relative;
  flex-shrink: 0;
  transition: opacity 0.5s;
  border: none;
  background: white;
  top: auto;
  left: auto;
  height: 4px;
  width: 4px;
}
.cropper-handler--west-north, .cropper-handler--east-south, .cropper-handler--west-south, .cropper-handler--east-north {
  display: block;
  height: 16px;
  width: 16px;
  background: none;
}
.cropper-handler--west-north {
  border-left: solid 2px white;
  border-top: solid 2px white;
  top: 7px;
  left: 7px;
}
.cropper-handler--east-south {
  border-right: solid 2px white;
  border-bottom: solid 2px white;
  top: -7px;
  left: -7px;
}
.cropper-handler--west-south {
  border-left: solid 2px white;
  border-bottom: solid 2px white;
  top: -7px;
  left: 7px;
}
.cropper-handler--east-north {
  border-right: solid 2px white;
  border-top: solid 2px white;
  top: 7px;
  left: -7px;
}
.cropper-handler--hover {
  opacity: 1;
}



</style>