
export default function(instance) {
    return {
        getAll() {
            return instance.get('/api/v2/profile', {})
        },
        changeProfile(params){
            return instance.patch(`/api/v2/profile`, params)
        },
        getPublicInfo(params){
            return instance.get(`/api/v2/public-information`, {params})
        },
    }
}