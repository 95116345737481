<template>
  <div class="potential-artist-songs-list">

    <ArtistDjookyAwardsProfileList v-for="(song, index) in songs"
                                   :key="index"
                                   :song="song"
                                   :index="index"
                                   :identifier="'djooky-awards-profile'"
                                   :logged="isLogged"
                                   :current="current"
                                   :artistName="artistName"
                                   @playingMusic="compareSongs"
                                   @sendEmail="sendEmail"
    />

  </div>
</template>

<script>

import ArtistDjookyAwardsProfileList from "./ArtistDjookyAwardsProfileList";

export default {
  name: "ArtistDjookyAwardsProfileTemplate",
  props: {
    songs: {
      type: Array,
      default: () => []
    },
    isLogged: Boolean,
    artistName: String,
  },
  data() {
    return {
      current: ''
    }
  },
  methods: {
    compareSongs(song) {
      this.current = song
    },
    sendEmail(artistId, songId) {
      this.$emit('sendEmail', artistId, songId)
    },
  },
  mounted() {

  },
  components: {
    ArtistDjookyAwardsProfileList,
  }
}
</script>

<style scoped>

</style>