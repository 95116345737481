import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
import Vuelidate from 'vuelidate'

import {required, min_value, max_value, min, max, email} from 'vee-validate/dist/rules.umd.js'
import DisableAutocomplete from 'vue-disable-autocomplete';
import vuescroll from 'vuescroll';

window.jquery = window.jQuery = window.$ = require('jquery');
import OtpInput from "@bachdgvn/vue-otp-input";
import VueApexCharts from 'vue-apexcharts';
import vueCustomScrollbar from 'vue-custom-scrollbar';
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import VueModal from '@kouts/vue-modal';
import '@kouts/vue-modal/dist/vue-modal.css';
import Vue from 'vue';
import App from './App.vue';
import VueAnalytics from 'vue-analytics';
import {BootstrapVue} from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue2-datepicker/index.css';
import VueRouter from 'vue-router';
import VModal from 'vue-js-modal';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import ApiPlugin from './plugins/api';
import LoadPlugin from './plugins/load';
import '@/assets/css/normalize.css';
import '@/assets/css/main.css';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
// import money from 'v-money';
import VideoEmbed from "v-video-embed/src";
import {VueAutonumeric} from "vue-autonumeric/src";

// import '@/assets/css/responsive.css';
// import '@/assets/css/magnific-popup.css';
// import '@/assets/css/newDesign.css';
// import '@/components/header/Header.css';
import '@/assets/css/responsive.css';
import '@/assets/css/new-responsive.css';


// setup fake backend
import {configureFakeBackend} from './helpers';

configureFakeBackend();


Vue.use(BootstrapVue)
Vue.use(VueRouter)
Vue.use(VModal)
Vue.use(ApiPlugin)
Vue.use(LoadPlugin)
Vue.use(VueApexCharts)
Vue.use(Vuelidate)
Vue.use(VueTelInput)
// Vue.use(money, {precision: 4})
Vue.use(VideoEmbed)
Vue.use(DisableAutocomplete);
Vue.use(vuescroll, {
    ops: {
        // The global config
    },
    name: 'myScroll' // customize component name, default -> vueScroll
});
Vue.use(VueAnalytics, {
    // id: 'G-VV95M6BM3T',
    id: 'UA-187151280-1',
    router
})
Vue.component("v-otp-input", OtpInput)
Vue.component('apexchart', VueApexCharts)
Vue.component('Modal', VueModal)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('vueCustomScrollbar', vueCustomScrollbar)
Vue.component('VueAutoNum', VueAutonumeric)
Vue.directive('click-outsider', {
    bind() {
        this.event = event => this.vm.$emit(this.expression, event)
        this.el.addEventListener('click', this.stopProp)
        document.body.addEventListener('click', this.event)
    },
    unbind() {
        this.el.removeEventListener('click', this.stopProp)
        document.body.removeEventListener('click', this.event)
    },

    stopProp(event) {
        event.stopPropagation()
    }
})
Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his children
            if (!(el === event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
});


extend('required', {
    ...required,
    message: 'This field is required'
});

extend('email', {
    ...email,
    message: 'Please enter a valid email address.'
});


extend('min_value', {
    ...min_value,
    message: "Must be higher than {min}"
});

extend('max_value', {
    ...max_value,
    message: "Must be less than {max}"
});


extend('positive', value => {
    return value.length > 0;
});

extend("min", {
        ...min,
        message: `Please enter at least ${min.validate.length} characters.`
    },
);
extend("max", {
        ...max,
        message: `Enter no more than ${min.validate.length} characters.`
    },
);


export default new Vue({
    el: '#app',
    vuetify,
    router,
    store,
    render: h => h(App),
})

// VUE_APP_API=http://172.31.22.29:8080/ - pre-dev
// VUE_APP_API=http://172.31.18.236:8080/ - dev