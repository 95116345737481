export default function(instance) {
  return {
    // getAll(searchText) {
    //   return instance.post('/api/v2/ieo/singer/page', {
    //     searchedText: searchText
    //   })
    // },
    // getSearchArtists(searchText, params) {
    //   return instance.post('/api/v2/ieo/singer/page', {
    //     searchedText: searchText
    //   }, { params })
    // }
    getAll(data) {
      return instance.post('/api/v2/ieo/singer/page', data)
    },
    getFilteredArtists(data, params) {
      return instance.post('/api/v2/ieo/singer/page', data, {params})
    },
    getFilteredArtistsDjk(data, params) {
      return instance.post('/api/v2/dma/potential-singer/page', data, {params})
    },
  }
}