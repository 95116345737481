<template>
  <div id="my-content">
    <Header/>
    <main id="root">
      <div class="profile">
        <Sidebar/>

        <div class="profile-content" v-if="flipFlag">
          <IeosTemplate :songs="ieos" v-if="ieos.length" :flipFlag="flipFlag"/>
          <div class="profile-content empty-response" v-else>
            <span>You don’t have any IS.</span>
          </div>
          <b-pagination-nav v-if="ieos.length"
                            class="pagination"
                            v-model="page"
                            :total-rows="count"
                            :per-page="pageSize"
                            :link-gen="linkGen"
                            :number-of-pages="count"
                            prev-text="«"
                            next-text="»"
                            first-number
                            last-number
                            use-router
          >
          </b-pagination-nav>
        </div>

        <div class="preloader-wrapper profile-preloader" v-else>
          <v-progress-circular
              :size="75"
              :width="8"
              color="#FF0090"
              indeterminate
          ></v-progress-circular>
        </div>

      </div>
    </main>
    <Footer/>
  </div>
</template>

<script>
import Sidebar from "./Sidebar";
import IeosTemplate from "./IeosTemplate";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import {debounce} from "lodash";
import scrollToElement from "@/mixins/scrollToElement";


export default {
  data() {
    return {
      ieos: null,
      page: 1,
      count: 0,
      pageSize: 4,
      flipFlag: null,
    }
  },
  mixins: [scrollToElement],
  methods: {
    linkGen(pageNum) {
      // return pageNum === 1 ? '?' : `?page=${pageNum}`
      return {
        path: '/profile/ieos',
        query: {page: pageNum}
      }
    },
    getRequestParams(page, pageSize) {
      let params = {};


      if (page) {
        params["page"] = page - 1;
      }

      if (pageSize) {
        params["size"] = 4;
      }

      return params;
    },
    checkIeos() {
      const params = this.getRequestParams(
          this.page,
          this.pageSize
      );

      this.$load(async () => {
        this.flipFlag = false
        const res = (await this.$api.profileIeos.getAll(params)).data.body
        this.ieos = res.elements
        this.count = res.totalPages
        setTimeout(() => {
          this.flipFlag = true
        }, 0)
      })

    },
  },
  watch: {
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if(newVal) {
          this.page = newVal
          this.debounceName()
          this.scrollToId('my-content')

          // this.goTo('top')
        } else {
          this.page = 1
        }
      }
    }
  },
  mounted() {
    document.title = 'Initial Sale'
    this.page = this.$route.query.page || 1
    this.checkIeos()
    this.debounceName = debounce(this.checkIeos, 300);
    setTimeout(() => {
      this.scrollToId('my-content')
    }, 500)
  },
  components: {
    Footer,
    Header,
    IeosTemplate,
    Sidebar,
  }
}
</script>

<style scoped>

</style>