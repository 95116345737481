<template>
  <div id="my-song-list" class="my-song-list">
    <IeosList
        v-for="(song, index) in songs"
        :key="index"
        :song="song"
        :index="index"
        :identifier="'ieos-list'"
        :flipFlag="flipFlag"
        :current="current"
        @playingMusic="compareSongs"
    />
  </div>
</template>

<script>
import IeosList from "./IeosList";

export default {
  name: "IeosTemplate",
  data() {
    return {
      current: ''
    }
  },
  components: {IeosList},
  props: {
    songs: {
      type: Array,
      default: () => []
    },
    flipFlag: {
      type: Boolean
    },

  },
  methods: {
    compareSongs(song) {
      this.current = song
    }
  },

}
</script>

<style scoped>

</style>