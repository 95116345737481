<template>
        <div id="main-news-list" class="main-news-list">
            <news-item v-for="(newsItem, index) in news"
               :key="index"
               :newsItem="newsItem"/>

        </div>
</template>



<script>

    import NewsItem from './News'

    export default {
        props: {
            news: {
                type: Array,
                default: () => []
            }
        },
        components: {  NewsItem }
    }
</script>

<style lang="scss" scoped>

</style>