<template>
  <tr>
    <td data-label="Pair" class="left medium-col" v-text="`${currentName}/${moneySymbol}`"></td>
<!--    <td data-label="Type" class="left" v-if="order.orderType === 2" v-text="'Limit'"></td>-->
<!--    <td data-label="Type" class="left" v-if="order.orderType === 0" v-text="'Market'"></td>-->
    <td data-label="Type" class="left" v-text="orderType"></td>
    <!--    <td data-label="Type" class="left" v-if="order.orderType === 3" v-text="'Stop Limit'"></td>-->
<!--    <td data-label="Type" class="left" v-if="order.orderType === 2" v-text="'Limit'"></td>-->
    <td data-label="Side" class="left buy" v-if="order.ask === false">Buy</td>
    <td data-label="Side" class="left sell" v-if="order.ask === true">Sell</td>
    <td data-label="Price" class="left medium-col" v-text="order.price.toFixed(2)"></td>
    <td data-label="Amount" class="left" v-text="order.amount"></td>
    <td data-label="Total" class="center medium-col" v-text="order.total.toFixed(2)"></td>
    <td data-label="Trigger Conditions" class="center large-col"  v-text="order.orderStatusResponse"></td>
    <td data-label="Date" class="center" v-text="localTimeFormat"></td>

    <td class="center medium-col">
      <button
          type="button"
          v-if="typeFlag === 'open'"
          @click="cancelOne"
          class="order-cancel-btn"
      >Cancel</button>
      <button v-else
              type="button"
              @click="cancelOne"
              class="order-cancel-btn"
              :class="{'disabled-cancel': order.orderStatusResponse !== 'NEW'}"
              :disabled="order.orderStatusResponse !== 'NEW'"
      >Cancel</button>
    </td>

  </tr>
</template>

<script>
import moment from "moment";

export default {
  name: "TradingOrdersTableRow",
  props: {
    order: {
      type: Object,
      default: () => {
      }
    },
    typeFlag: String,
    currentName: String,
    moneySymbol: String,
  },
  data() {
    return {
      cancelOrderRequest: {
        orderType: this.order.orderType,
        orderUuid: this.order.orderUuid,
        songUuid: this.order.songUuid
      }
    }
  },
  methods: {
    cancelOne() {
      const obj = {
        orderType: this.orderType,
        name: this.currentName,
        ask: this.order.ask,
        amount: this.order.amount,
        totalPrice: this.order.total,
      }

      this.$emit('cancelOneOrder', this.cancelOrderRequest, obj)
    },
  },
  computed: {
    orderType() {
      return this.order.orderType === 1 ? 'Market' : this.order.orderType === 2 ? 'Limit' : 'Unknown type'
    },
    localTimeFormat() {
      let timeUtc = moment.utc(this.order.createdAt).local()
      return moment(String(timeUtc)).format('yyyy-MM-DD HH:mm')
    },
  },
}
</script>

<style scoped>

</style>