<template>
    <div class="faq-item">
      <div class="faq-item-header-wrap" @click="showFlag = !showFlag">
        <span class="faq-item-head-title" :style="{color: !showFlag ? '#000000' : '#FE0190'}">{{ item.faq[0].question }}</span>
        <svg :style="{transform: !showFlag ? 'rotate(0deg)' : 'rotate(180deg)'}" width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 2L10.5 10L19 2" stroke="black" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div class="faq-item-title" v-if="showFlag">
        <div class="faq-item-title-text">
          {{ item.faq[0].answer }}
        </div>
      </div>
    </div>

</template>

<script>
export default {
  name: "FAQInvestor",
  props: {
    item: Object
  },
  data() {
    return {
      showFlag: false,
    }
  }
}
</script>

<style scoped>

.faq-investor-wrap {
  width: 100%;
}


.faq-item {
  border-bottom: 1px solid #F2F2F2;
  cursor: pointer;
}

.faq-item-header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}

.faq-item:first-child {
  border-top: 1px solid #F2F2F2;
  margin-top: 30px;
}
.faq-item-head-title {
  width: calc(100% - 40px);
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: -0.03em;
  color: #000000;
}

.faq-item-title {
  padding-bottom: 25px;
}

.faq-item-title-text {
  border-left: 1px solid #FE0190;
  padding-left: 30px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 31px;
  color: #757575;
}
@media only screen and (max-width: 600px) {

  .faq-item-head-title {
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
  }

  .faq-item-title-text {
    font-size: 16px;
    line-height: 27px;
  }

  .faq-item-title {
    padding-bottom: 12px;
  }

  .faq-item-header-wrap {
    padding: 15px 0;
  }

  .faq-item-header-wrap svg {
    width: 13px;
    height: 6px;
  }
}
</style>