export default function(instance) {
  return {
    getAuthorList() {
      return instance.get('/api/v2/royalties/song-authors')
    },
    getSongList(authorId) {
      return instance.get(`/api/v2/royalties/songs/${authorId}`)
    },
    getCompanyList() {
      return instance.get('/api/v2/royalties/renting-companies')
    },
    createNewCompany() {
      return instance.post('/api/v2/royalties/renting-companies')
    },
    getRoyaltyList(params) {
      return instance.get('/api/v2/royalties/wallets', {params})
    },
    createNewRoyalty(payload) {
      return instance.post('/api/v2/royalties/wallets', payload)
    },
    getSpecificRoyalty(id) {
      return instance.patch(`/api/v2/royalties/wallets/${id}`)
    },
    getRoyaltiesReports(params) {
      return instance.get('api/v2/royalties/reports', {params})
    },
    createRoyaltiesReports(payload) {
      return instance.post('api/v2/royalties/reports', payload)
    },
    getSongsForCreateReport() {
      return instance.get('api/v2/royalties/available-song-to-reports')
    },
    deleteReport(reportId) {
      return instance.delete(`api/v2/royalties/reports/${reportId}`)
    },
    getReport(reportId) {
      return instance.get(`api/v2/royalties/reports/${reportId}`)
    },
  }
}

