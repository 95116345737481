var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"disabled-popup-wrapper"},[_c('div',{staticClass:"disabled-popup-title"},[_vm._v("Please wait while the song is verified")]),_c('div',{staticClass:"ieo-creating-payment-pending-dot-wrapper"},[_c('div',{staticClass:"ieo-creating-payment-pending-dot",style:({
        width: _vm.dotPendingCounter === 1 ? 20 + 'px' : 11 + 'px',
        height: _vm.dotPendingCounter === 1 ? 20 + 'px' : 11 + 'px',
      })}),_c('div',{staticClass:"ieo-creating-payment-pending-dot",style:({
        width: _vm.dotPendingCounter === 2 ? 20 + 'px' : 11 + 'px',
        height: _vm.dotPendingCounter === 2 ? 20 + 'px' : 11 + 'px',
      })}),_c('div',{staticClass:"ieo-creating-payment-pending-dot",style:({
        width: _vm.dotPendingCounter === 3 ? 20 + 'px' : 11 + 'px',
        height: _vm.dotPendingCounter === 3 ? 20 + 'px' : 11 + 'px',
      })}),_c('div',{staticClass:"ieo-creating-payment-pending-dot",style:({
        width: _vm.dotPendingCounter === 4 ? 20 + 'px' : 11 + 'px',
        height: _vm.dotPendingCounter === 4 ? 20 + 'px' : 11 + 'px',
      })}),_c('div',{staticClass:"ieo-creating-payment-pending-dot",style:({
        width: _vm.dotPendingCounter === 5 ? 20 + 'px' : 11 + 'px',
        height: _vm.dotPendingCounter === 5 ? 20 + 'px' : 11 + 'px',
      })})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }