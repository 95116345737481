<template>
  <li class="notifications-list-item">
    <div class="notifications-list-item-content">
      <span class="notifications-list-item-title" v-text="element.title"></span>
      <span class="notifications-list-item-descr" v-text="element.content"></span>
    </div>
    <div class="notifications-list-item-date-wrap">
      <span class="notifications-list-item-date" v-text="time"></span>
    </div>
  </li>
</template>

<script>
import dateHandler from "@/mixins/dateHandler";

export default {
  name: 'NotificationsList',
  props: {
    element: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [dateHandler],
  computed: {
    time() {
      return this.toLocalDate(this.element.createdAt)
    },
  },
}
</script>

<style scoped>

</style>