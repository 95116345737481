var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"top",staticClass:"ieo-creating-payment-wrapper"},[_c('div',{staticClass:"ieo-creating-payment-container"},[_c('div',{staticClass:"ieo-creating-payment-title"},[_vm._v("choose a payment method")]),_c('div',{staticClass:"ieo-creating-payment-card"},[_c('button',{staticClass:"ieo-creating-payment-card-buttons",style:({
        background: _vm.choiceOfPayment === 'wallet' ? '#FFF7FC' : '#FFFFFF',
        border: _vm.choiceOfPayment === 'wallet' ? '2px solid #FF0090' : '2px solid #EAEAEA'
        }),on:{"click":function($event){_vm.choiceOfPayment = 'wallet'}}},[_c('svg',{attrs:{"width":"67","height":"56","viewBox":"0 0 67 56","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"x":"2.11349","y":"32.4195","width":"39.6048","height":"26.5867","rx":"6.5","transform":"rotate(-49.9259 2.11349 32.4195)","stroke":_vm.choiceOfPayment === 'wallet' ? '#FF0090' : '#000000',"stroke-width":"3"}}),_c('rect',{attrs:{"x":"9.88302","y":"23.9165","width":"39.6048","height":"26.5867","rx":"6.5","transform":"rotate(-30 9.88302 23.9165)","fill":"#FFF2F9","stroke":_vm.choiceOfPayment === 'wallet' ? '#FF0090' : '#000000',"stroke-width":"3"}}),_c('rect',{attrs:{"x":"4.28125","y":"16.5001","width":"56","height":"38","rx":"6.5","fill":_vm.choiceOfPayment === 'wallet' ? '#FFF7FC' : '#FFFFFF',"stroke":_vm.choiceOfPayment === 'wallet' ? '#FF0090' : '#000000',"stroke-width":"3"}}),_c('rect',{attrs:{"x":"48.2812","y":"30.5001","width":"17","height":"10","rx":"5","fill":_vm.choiceOfPayment === 'wallet' ? '#FFF7FC' : '#FFFFFF',"stroke":_vm.choiceOfPayment === 'wallet' ? '#FF0090' : '#000000',"stroke-width":"3"}})]),_c('p',{style:({color: _vm.choiceOfPayment === 'wallet' ? '#FF0090' : '#000000'})},[_vm._v("DjookyX wallet")]),_c('div',{staticClass:"commission-description",style:({color: _vm.choiceOfPayment === 'wallet' ? '#FF0090' : '#000000'})},[_vm._v("No commission")])]),_c('button',{staticClass:"ieo-creating-payment-card-buttons",style:({
        background: _vm.choiceOfPayment === 'card' ? '#FFF7FC' : '#FFFFFF',
        border: _vm.choiceOfPayment === 'card' ? '2px solid #FF0090' : '2px solid #EAEAEA'
        }),on:{"click":function($event){_vm.choiceOfPayment = 'card'}}},[_c('svg',{attrs:{"width":"66","height":"44","viewBox":"0 0 66 44","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"x":"1.5","y":"1.5","width":"63","height":"41","rx":"6.5","stroke":_vm.choiceOfPayment === 'card' ? '#FF0090' : '#000000',"stroke-width":"3"}}),_c('rect',{attrs:{"x":"1.5","y":"10.5","width":"63","height":"9.03403","stroke":_vm.choiceOfPayment === 'card' ? '#FF0090' : '#000000',"stroke-width":"3"}}),_c('rect',{attrs:{"x":"45","y":"27.5","width":"12","height":"7","rx":"2","stroke":_vm.choiceOfPayment === 'card' ? '#FF0090' : '#000000',"stroke-width":"3"}})]),_c('p',{style:({color: _vm.choiceOfPayment === 'card' ? '#FF0090' : '#000000'})},[_vm._v("Card")]),_c('div',{staticClass:"commission-description",style:({color: _vm.choiceOfPayment === 'card' ? '#FF0090' : '#000000'})},[_vm._v("Сommission is "),_c('span',{staticClass:"bold"},[_vm._v("1,8% + €0,18")])])])]),_c('div',{staticClass:"ieo-creating-payment-wallet-title"},[_vm._v(" An initial fee of "),_c('span',[_vm._v(_vm._s(_vm.price)+" EUR")]),_vm._v(" is charged for starting Initial Sale. ")]),(_vm.choiceOfPayment === 'wallet')?_c('IeoCreatingPaymentWallet',{attrs:{"price":_vm.price},on:{"confirmWithWallet":_vm.confirmWithWalletFunc}}):_vm._e(),(_vm.choiceOfPayment === 'card')?_c('IeoCreatingPaymentCard',{attrs:{"userToken":_vm.userToken}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }