export default {
  methods: {
    getNameDefault(companyName, stageName, firstName, lastName) {
      if (companyName) {
        return companyName
      }
      if (stageName) {
        return stageName
      }
      if (firstName) {
        return `${firstName} ${lastName}`
      }
    },
  },
}