<template>
  <v-dialog v-model="dialog">
    <div id="disclaimer-popup-id" class="white-popup popup-form order-popup">
      <div class="form-main-content disclaimer-popup trading-disclaimer-popup">
        <div class="close-dialog" @click="closeEvent">
          <img src="../../assets/img/close.svg" alt="close-icon">
        </div>
        <div class="popup-header">
          <h3 class="form-title">Agreement</h3>
        </div>
        <div class="popup-content ">
          <div class="popup-content-container">

            <div class="popup-content-text">
              <vuescroll
                  :ops="ops"
                  ref="vs"
                  @handle-scroll="handleScroll"
              >
                <div class="document-wrap">
                  <div class="format-a4">

                    <div class="contract-header">

                      <div class="contract-header-title-wrap">
                        <div class="contract-header-title">Purchase agreement</div>
                      </div>

                      <div class="contract-header-logo">
                        <span class="contract-header-logo-wrap">
                          <svg width="253" height="39" viewBox="0 0 253 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M137.724 15.1813L150.582 30.345H142.614L129.668 15.2165L142.614 0.0175781H150.582L137.724 15.1813Z"
                                  fill="#FE0190"/>
                            <path
                                d="M6.68393 24.7334H12.9809C19.7352 24.7334 21.7755 19.5616 21.7755 15.111C21.7755 9.95673 19.3482 5.50609 12.9809 5.50609H6.68393V24.7334ZM13.7196 0C23.5345 0 28.5474 6.84304 28.5474 15.1989C28.5474 23.9946 23.6752 30.3274 13.0161 30.3274H0V0H13.7196Z"
                                fill="#FE0190"/>
                            <path
                                d="M46.2949 27.3897C46.5511 30.6305 45.5987 33.8514 43.6213 36.4316C42.2142 38.1908 40.1034 39 36.5856 39H34.8267V33.0716C35.1433 33.0716 35.5478 33.1597 35.8468 33.1597C38.45 33.1597 39.6285 30.9432 39.6285 27.5657V0H46.2949V27.3897Z"
                                fill="#FE0190"/>
                            <path
                                d="M70.832 6.24487C65.7311 6.24487 62.354 9.92155 62.354 15.3573C62.354 20.793 65.7311 24.4695 70.832 24.4695C75.9329 24.4695 79.31 20.9513 79.31 15.3573C79.31 9.76323 75.9329 6.24487 70.832 6.24487ZM70.832 30.7672C67.7901 30.7811 64.8124 29.8921 62.2758 28.2127C59.7393 26.5334 57.7579 24.1392 56.5826 21.3331C55.4072 18.527 55.0907 15.4352 55.673 12.4492C56.2554 9.46311 57.7104 6.71701 59.854 4.55838C61.9977 2.39974 64.7334 0.925662 67.7151 0.322729C70.6967 -0.280204 73.7902 0.0150773 76.604 1.17126C79.4177 2.32745 81.8252 4.29255 83.5218 6.81779C85.2184 9.34303 86.1277 12.3149 86.1347 15.3573C86.1848 17.3857 85.8241 19.4033 85.0742 21.2886C84.3242 23.174 83.2006 24.888 81.771 26.3277C80.3413 27.7673 78.6352 28.9029 76.7553 29.6659C74.8754 30.4288 72.8605 30.8034 70.832 30.7672Z"
                                fill="#FE0190"/>
                            <path
                                d="M108.825 6.24487C103.724 6.24487 100.347 9.92155 100.347 15.3573C100.347 20.793 103.724 24.4695 108.825 24.4695C113.926 24.4695 117.303 20.9513 117.303 15.3573C117.303 9.76323 113.926 6.24487 108.825 6.24487ZM108.825 30.7672C105.783 30.7811 102.805 29.8921 100.268 28.2127C97.7319 26.5334 95.7506 24.1392 94.5752 21.3331C93.3999 18.527 93.0833 15.4352 93.6657 12.4492C94.248 9.46311 95.7031 6.71701 97.8467 4.55838C99.9903 2.39974 102.726 0.925662 105.708 0.322729C108.689 -0.280204 111.783 0.0150773 114.597 1.17126C117.41 2.32745 119.818 4.29255 121.514 6.81779C123.211 9.34303 124.12 12.3149 124.127 15.3573C124.178 17.3857 123.817 19.4033 123.067 21.2886C122.317 23.174 121.193 24.888 119.764 26.3277C118.334 27.7673 116.628 28.9029 114.748 29.6659C112.868 30.4288 110.853 30.8034 108.825 30.7672Z"
                                fill="#FE0190"/>
                            <path
                                d="M166.957 16.1136L156.369 0.0175781H164.46L170.264 9.53448L176.139 0.0175781H184.16L173.641 16.1136V30.345H166.957V16.1136Z"
                                fill="#FE0190"/>
                            <path d="M221.273 15.1813L234.131 30.345H226.163L213.235 15.2165L226.163 0.0175781H234.131L221.273 15.1813Z"
                                  fill="#FE0190"/>
                            <path d="M203.895 15.1813L191.037 30.345H199.005L211.933 15.2165L199.005 0.0175781H191.037L203.895 15.1813Z"
                                  fill="#FE0190"/>
                          </svg>
                        </span>
                      </div>

                    </div>

                    <div class="contract-main">

                      <div class="exhibit-invest-container clearfix">

                        <div class="exhibit-invest left-side">
                          <h3 class="exhibit-invest-title">INITIATING INVESTOR <span class="invest-highlight">seller</span></h3>
                          <div class="exhibit-invest-info">
                            <div class="exhibit-invest-info-title">ID:</div>
                            <div class="exhibit-invest-info-value" id="sellerId">XXXXXXXXXXXXXXXXX</div>
                          </div>
                          <div class="exhibit-invest-info">
                            <div class="exhibit-invest-info-title">Name:</div>
                            <div class="exhibit-invest-info-value" id="sellerName">XXXXXXXXXXXXXXXXX</div>
                          </div>
                        </div>

                        <div class="exhibit-invest right-side">
                          <h3 class="exhibit-invest-title">OFFERING INVESTOR <span class="invest-highlight">buyer</span></h3>
                          <div class="exhibit-invest-info">
                            <div class="exhibit-invest-info-title">ID:</div>
                            <div class="exhibit-invest-info-value" id="buyerId">XXXXXXXXXXXXXXXXX</div>
                          </div>
                          <div class="exhibit-invest-info">
                            <div class="exhibit-invest-info-title">Name:</div>
                            <div class="exhibit-invest-info-value" id="buyerName">XXXXXXXXXXXXXXXXX</div>
                          </div>
                        </div>

                      </div>

                      <div class="exb_b-list">

                        <ol style="padding-left: 14px;">
                          <li>
                            <div class="exb_b-list-item">
                              Music Composition TSubject: According to this Purchase Agreement (“Agreement”) Seller agrees to
                              sell, and Buyer agrees to buy for the price and on the terms and conditions set forth herein the
                              undivided fractional  share (percentage interest) in the intellectual property (music composition
                              and master recording) rights including current and future claims for remuneration arising from
                              the use and/or exploitation of such music composition (and/or sound recording) (“Rights”) set
                              out in paragraph 2 of the Agreement.
                            </div>
                          </li>
                          <li>
                            <div class="exb_b-list-item">
                              <!--                     add "id" for it   -->
                              Music composition: Name <b style="color: #000000;">XXXXXXXXXXXX</b>, Artist <b style="color: #000000;">XXXXXXXXXXXX</b>, DjookyX identification <b id="lotId" style="color: #000000;">XXX</b>.
                            </div>
                          </li>
                          <li>
                            <div class="exb_b-list-item">
                              Rights share: % <b id="payable" style="color: #000000;">XXXXXXXXXXXX</b>
                            </div>
                          </li>
                          <li>
                            <div class="exb_b-list-item">
                              Amount of purchase: EUR <b id="bidPrice" style="color: #000000;">XXXXXXXXXXXX</b>
                            </div>
                          </li>
                          <li>
                            <div class="exb_b-list-item">
                              <!--             or id="closingDate"           -->
                              Effective Date: <b id="saleDate" style="color: #000000;">XXXXXXXXXXXX</b> (DD/MM/YYYY).
                            </div>
                          </li>
                          <li>
                            <div class="exb_b-list-item">
                              Term: duration of copyrights.
                            </div>
                          </li>
                          <li>
                            <div class="exb_b-list-item">
                              This Agreement may not be modified or terminated.
                            </div>
                          </li>
                          <li>
                            <div class="exb_b-list-item">
                              Executed Agreement constitutes that Seller received the Amount of purchase and Buyer received Rights share in the Music composition. Parties have no claims to each other in respect of this Agreement.
                            </div>
                          </li>
                          <li>
                            <div class="exb_b-list-item">
                              The Parties by executing this Agreement warrant that they are bound by this Agreement and subject to Terms of Use of the Platform.
                            </div>
                          </li>
                          <li>
                            <div class="exb_b-list-item">
                              This Agreement may be signed or otherwise executed in counterparts and a facsimile, scanned PDF or electronic version of this Agreement shall be adequate and binding. This Agreement shall be deemed fully executed once the Artist clicks the accept box below.
                            </div>
                          </li>
                        </ol>

                      </div>

                      <div class="exb-footer">
                        BY CLICKING ON “I HAVE READ AND AGREE TO THE PURCHASE AGREEMENT”, PARTIES ACKNOWLEDGE THAT: (1) PARTIES HAVE READ ALL PROVISIONS, DISCLAIMERS, AND DISCLOSURES MADE HEREIN AND IN THE TERMS OF USE OF THE PLATFORM; (2) PARTIES UNDERSTAND ALL SUCH PROVISIONS, DISCLAIMERS, AND DISCLOSURES AND HAVE ENTERED INTO THIS AGREEMENT VOLUNTARILY. THE
                        <a href="https://djookyx.com/terms-conditions/" target="_blank" class="document-link">TERMS OF USE OF THE PLATFORM APPLY</a>
                      </div>

                    </div>
                  </div>
                </div>
              </vuescroll>
            </div>

          </div>
        </div>
        <div class="button-wrapper">
          <button type="button" id="sellButtonStopLimitTrading"
                  class="btn btn-hover"

                  @click="confirmAction"
                  :disabled="!checkBox"
                  :class="{'disabled-btn': !checkBox}"
          >
            {{ buttonText }}
          </button>
        </div>
      </div>
    </div>

  </v-dialog>
</template>

<script>
import vuescroll from 'vuescroll';

export default {
  name: 'DisclaimerTradingPopup',
  components: {vuescroll},
  data() {
    return {
      dialog: false,
      checkBox: false,
      scrollFlag: true,
      buttonText: 'Scroll down to accept',
      ops: {
        bar: {
          background: '#FB3099',
          size: '3px'
        }
      }
    };
  },
  methods: {
    handleScroll(vertical, horizontal, nativeEvent) {
      if (vertical.process >= 0.98) {
        this.checkBox = true
        this.buttonText = 'I have read and agree to the initial sale agreement'
      }
    },
    openPopups() {
      this.dialog = true;
    },
    resetState() {
      this.checkBox = false
      this.buttonText = 'Scroll down to accept'
      this.$refs["vs"].scrollTo(
          {
            y: 0
          },
          0,
          "easeInQuad"
      );
    },
    closeEvent() {
      this.dialog = false
      this.resetState()
    },
    confirmAction() {
      this.$emit('confirmDisclaimer')
      this.resetState()
      this.dialog = false
    },

  }
};
</script>

<style scoped>

.popup-content-text {
  height: 300px;
  margin-bottom: 20px;
  text-align: start;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 31px;
  color: #A6A6A6;
}

.popup-header {
  margin-bottom: 70px;
}

.popup-checkbox input {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.button-wrapper {
  padding-bottom: 50px;
}

.contract-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
}

.contract-header-title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contract-main {
  width: 100%;
  padding-bottom: 40px;
  margin-bottom: 20px;
  border-bottom: 7px solid rgb(255, 0, 144);
}

.format-a4 {
  max-width: 171.88mm;
  width: 100%;
  max-height: 297mm;
  height: 100%;
  margin: 0 auto;
}

.contract-header-title {
  text-transform: uppercase;
  vertical-align: middle;
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  font-size: 7.06mm;
  line-height: 10.59mm;
  color: #000000;
  margin-right: 9.29mm;
}

/*.contract-header-desc-wrap {*/
/*  vertical-align: middle;*/
/*  display: inline-block;*/
/*  line-height: 10.59mm;*/
/*  text-align: left;*/
/*  padding-left: 9.29mm;*/
/*  border-left: 0.265mm solid #000000;*/
/*}*/

/*.contract-header-desc-item {*/
/*  font-style: normal;*/
/*  font-weight: 500;*/
/*  font-size: 3.45mm;*/
/*  color: #000000;*/
/*}*/

.contract-header-logo-wrap svg {
  width: 100px;
}

.contract-header-logo {
  display: inline-block;
  float: right;
  line-height: 10.59mm;
  height: 10.59mm;
  text-align: right;
}

.contract-header-logo .contract-header-logo-wrap {
  vertical-align: middle;
}

.exb_b-list {
  font-style: normal;
  font-weight: normal;
  font-size: 3.53mm;
  line-height: 6.35mm;
  color: #8C8C8C;
  margin-bottom: 25.5mm;
}

.trading-disclaimer-popup .format-a4 .exb_b-list ol {
  padding-left: 25px !important;
}

.exb_b-list li {
  font-size: 15px;
  line-height: 21px;
  list-style: decimal;
  color: #8C8C8C;
  margin-bottom: 11px;
}

.exb_b-list li:last-child {
  margin-bottom: 0;
}

.exb_b-list-item {
  position: relative;
  margin-bottom: 3.53mm;
}

.exb_b-list-item:last-child {
  margin-bottom: 0;
}

.exb-footer {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  color: #8C8C8C;
}

.exb-footer .document-link {
  color: #FE0190;
  font-weight: 600;
  text-decoration: none;
  transition: .4s ease;
}

.exb-footer .document-link:hover {
  opacity: 0.5;
}

.exhibit-invest-container {
  width: 100%;
  margin-bottom: 19px;
}

.clearfix {
  zoom: 1;
}

.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
}

.clearfix:after {
  clear: both;
}

.exhibit-invest {
  display: block;
  width: 44%;
  border-top: 0.26mm solid #E5E5E5;
  border-bottom: 0.26mm solid #E5E5E5;
}

.exhibit-invest.left-side {
  float: left;
}

.exhibit-invest.right-side {
  float: right;
}

.exhibit-invest-title {
  padding-top: 2.37mm;
  padding-bottom: 2.37mm;
  border-bottom: 0.26mm solid #E5E5E5;
  margin-top: 0;
  margin-bottom: 4.22mm;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
}

.invest-highlight {
  color: #FE0190;
}

.exhibit-invest-info {
  width: 100%;
  margin-bottom: 8px;
  display: table;
}

.exhibit-invest-info-title {
  display: table-cell;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #8C8C8C;
  width: fit-content;
}

.exhibit-invest-info-value {
  display: table-cell;
  text-align: right;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  width: 80%;
  word-break: break-word;
}

@media only screen and (max-width: 992px) {
  .trading-disclaimer-popup .popup-content {
    margin-right: 15px;
    margin-left: 15px;
  }

  .trading-disclaimer-popup .btn-hover {
    max-width: 80%;
  }
}

@media only screen and (max-width: 768px) {
  .trading-disclaimer-popup .document-wrap {
    zoom: 0.7;
  }
  /*.contract-header-title {*/
  /*  font-size: 16px;*/
  /*  line-height: 19px;*/
  /*}*/
}

</style>