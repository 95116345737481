<template>
  <div id="my-content" ref="top">
    <Header/>
    <main id="root">

      <div class="profile">
        <Sidebar/>


        <div class="profile-content favorites" v-if="loadFlag">
          <MySongTemplate :songs="songs"
                          :favoritesFlag="favoritesFlag"
                          v-if="songs.length"
          />
          <div class="profile-content empty-response" v-else>
            <span id="favorites-any-message">You don’t have any Favorites yet</span>
          </div>
          <b-pagination-nav v-if="songs.length"
                            class="pagination"
                            v-model="page"
                            :total-rows="count"
                            :per-page="pageSize"
                            :link-gen="linkGen"
                            :number-of-pages="count"
                            prev-text="«"
                            next-text="»"
                            first-number
                            last-number
                            use-router
          ></b-pagination-nav>
        </div>
        <div class="preloader-wrapper profile-preloader" v-else>
          <v-progress-circular
              :size="75"
              :width="8"
              color="#FF0090"
              indeterminate
          ></v-progress-circular>
        </div>
      </div>

    </main>
    <Footer/>
  </div>
</template>

<script>
import Sidebar from "./Sidebar";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import MySongTemplate from "./MySongTemplate";
import {debounce} from "lodash";
import scrollToElement from "@/mixins/scrollToElement";


export default {
  name: "Favorites",
  data() {
    return {
      loadFlag: false,
      favoritesFlag: true,
      songs: null,
      currentNews: null,
      currentIndex: -1,
      searchTitle: "",
      flag: false,
      page: 1,
      count: 0,
      pageSize: 4,
    }
  },
  mixins: [scrollToElement],
  methods: {
    isValid() {
      if (this.songs.length) {
        this.flag = true
      } else {
        this.flag = false
      }
    },
    linkGen(pageNum) {
      // return pageNum === 1 ? '?' : `?page=${pageNum}`
      return {
        path: '/profile/favorite',
        query: {page: pageNum}
      }
    },
    getRequestParams(page, pageSize) {
      let params = {};


      if (page) {
        params["page"] = page - 1;
      }

      if (pageSize) {
        params["size"] = pageSize;
      }

      return params;
    },
    checkSongs() {
      const params = this.getRequestParams(
          this.page,
          this.pageSize
      );

      this.$load(async () => {
        this.loadFlag = false
        const res = (await this.$api.favorites.get(params)).data.body
        this.songs = res.elements
        this.count = res.totalPages
        this.loadFlag = true
      })

    },
  },
  watch: {
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if(newVal) {
          this.page = newVal
          this.debounceName()
          this.scrollToId('my-content')
        } else {
          this.page = 1
        }
      }
    }
  },
  mounted() {
    document.title = 'Favorite'
    this.page = this.$route.query.page || 1
    this.checkSongs()
    this.debounceName = debounce(this.checkSongs, 1000);
    setTimeout(() => {
      this.scrollToId('my-content')
    }, 500)
  },
  components: {
    MySongTemplate,
    Footer,
    Header,
    Sidebar
  }
}
</script>

<style scoped>


</style>