import instance from './instance'

import loginModule from './login';
import homeWinnersIeoModule from './home-winners-ieo';
import homeNotStartedIeoModule from './home-not-started-ieo';
import authModule from './auth';
import newsModule from './news';
import artistsModule from './artists';
import teamModule from './team';
import profileModule from './profileApi/profile-api';
import profileSongsModule from "./profileApi/my-songs-api";
import notificationsModule from "./profileApi/notifications-api";
import tradingHistoryModule from "./profileApi/trading-history-api";
import walletModule from "./profileApi/wallet-api";
import myOrdersModule from './profileApi/my-orders-api';
import financialOperationsModule from './profileApi/financial-operations-api';
import profileIeosModule from './profileApi/profile-ieos-api';
import artistProfileModule from "./artistProfile";
import followUnfollowModule from "./follow-unfollow-api";
import newsPageModule from "./news-page-api";
import contentModule from "./content-api";
import FAQModule from "./faq-api";
import createIeoModule from "./ieo-create-api";
import primaryMarketModule from "./primary-market-api";
import favoritesModule from "./profileApi/favorites-api";
import ieoSongModule from "./ieo-song-api";
import tradingModule from './trading-api';
import profileInviteModule from './profileApi/profile-invite-api';
import createSongModule from './profileApi/create-song-api';
import kycTokenModule from './profileApi/kyc-api';
import contactUsModule from './contact-us-api';
import passwordResetModule from './reset-password-api';
import paymentModule from './profileApi/payment-api';
import contractModule from './profileApi/contract-api';
import bankAccountModule from './bank-account';
import countriesListsModule from './countriesLists';
import associatedArtistsModule from '@/api/profileApi/associated-artists';
import promotionModule from '@/api/profileApi/promotion-api';
import royaltyModule from '@/api/profileApi/royalty-api';


export default {
  loginApi: loginModule(instance),
  homeWinnersIeo: homeWinnersIeoModule(instance),
  homeNotStartedIeo: homeNotStartedIeoModule(instance),
  auth: authModule(instance),
  news: newsModule(instance),
  artists: artistsModule(instance),
  team: teamModule(instance),
  profile: profileModule(instance),
  profileSongs: profileSongsModule(instance),
  notifications: notificationsModule(instance),
  tradingHistory: tradingHistoryModule(instance),
  wallet: walletModule(instance),
  myOrders: myOrdersModule(instance),
  financialOperations: financialOperationsModule(instance),
  profileIeos: profileIeosModule(instance),
  profileInvite: profileInviteModule(instance),
  createSong: createSongModule(instance),
  artistProfile: artistProfileModule(instance),
  followUnfollow: followUnfollowModule(instance),
  newsPage: newsPageModule(instance),
  content: contentModule(instance),
  FAQ: FAQModule(instance),
  createIeo: createIeoModule(instance),
  pm: primaryMarketModule(instance),
  favorites: favoritesModule(instance),
  ieoSong: ieoSongModule(instance),
  kyc: kycTokenModule(instance),
  passwordReset: passwordResetModule(instance),
  contactUs: contactUsModule(instance),
  payment: paymentModule(instance),
  contract: contractModule(instance),
  bankAccount: bankAccountModule(instance),
  trading: tradingModule(instance),
  countriesLists: countriesListsModule(instance),
  associatedArtists: associatedArtistsModule(instance),
  promotion: promotionModule(instance),
  royalty: royaltyModule(instance)
}
