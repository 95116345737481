export default function (instance) {
    return {
        getGenres() {
            return instance.get('/api/v2/profile/songs/genre')
        },
        sendCoAuthors(formData) {
            return instance.post('/api/v2/profile/songs', formData, {
                headers: {
                    'Content-Type': "multipart/form-data",
                }
            })
        },
    }
}