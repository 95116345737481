<template>
  <div class="trade-history-list">
    <div class="trade-history-item">
      <div class="col-left trade-history-price" v-text="history.price.toFixed(2)"></div>
      <div class="col-center trade-history-amount" v-text="history.amount"></div>
      <div class="col-right trade-history-time" v-text="reformatTime"></div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "TradingHistoryMarket",
  props: {
    history: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    reformatChangedNum() {
      let num = this.history.price
      return num ? num > 0 ? `${num.toFixed(2)}` : `${num.toFixed(2)}` : '0.00'
    },
    colorNum() {
      let num = this.history.price
      return num === 0 ? '#898989' : num > 0 ? '#4C9F4A' : '#D03737'
    },
    reformatTime() {
      let timeUtc = moment.utc(this.history.time).local()
      let resultTimeDate = moment(String(timeUtc)).format('YYYY-MM-DDTHH:mm:ssZ')

      let time = new Date(resultTimeDate)
      let timeArr = time.toString().split(' ')
      let arrayHhMmSs = timeArr[4].split(':')
      let hhMm = arrayHhMmSs[0] + ':' + arrayHhMmSs[1]
      return hhMm + ' ' + timeArr[2] + ' ' + timeArr[1]
    },
  },
}
</script>

<style scoped>

</style>