<template>
  <div id="my-content" ref="top">
    <Header/>
    <main id="root">

      <div class="profile">
        <Sidebar/>

        <div class="profile-content" v-if="loadFlag">
          <div id="profile-trading-history" class="table-wrap" v-if="tradingHistory.length">
            <table class="table orders-table">
              <thead>
              <tr>
                <th class="left">Date</th>
                <th class="left">Pair</th>
                <th class="left">Side</th>
                <th class="left">Price</th>
                <th class="left">Amount</th>
                <th class="left">Fee</th>
              </tr>
              </thead>
              <tbody>
              <vue-custom-scrollbar class="scroll-area" :settings="scrollSettings">
                <TradingHistoryTemplate :tradingHistory="tradingHistory"/>
              </vue-custom-scrollbar>
              </tbody>
            </table>

          </div>
          <div class="profile-content empty-response" v-else>
            <span>Your Trading History is empty</span>
          </div>
          <!-- pagination -->
          <b-pagination-nav v-if="tradingHistory.length > 1"
                            class="pagination"
                            v-model="page"
                            :total-rows="count"
                            :per-page="pageSize"
                            :link-gen="linkGen"
                            :number-of-pages="count"
                            prev-text="«"
                            next-text="»"
                            first-number
                            last-number
                            use-router
          ></b-pagination-nav>
        </div>
        <div class="preloader-wrapper profile-preloader" v-else>
          <v-progress-circular
              :size="75"
              :width="8"
              color="#FF0090"
              indeterminate
          ></v-progress-circular>
        </div>

      </div>
    </main>
    <Footer/>
  </div>
</template>

<script>
import Sidebar from "./Sidebar";
import TradingHistoryTemplate from "./TradingHistoryTemplate";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import {debounce} from "lodash";


export default {
  name: "TradingHistory",
  data() {
    return {
      loadFlag: false,
      tradingHistory: [],
      page: 1,
      count: 0,
      pageSize: 4,
      scrollSettings: {
        wheelPropagation: false
      }
    }
  },
  methods: {
    linkGen(pageNum) {
      // return pageNum === 1 ? '?' : `?page=${pageNum}`
      return {
        path: '/profile/trading-history',
        query: {page: pageNum}
      }
    },
    getRequestParams(page, pageSize) {
      let params = {};


      if (page) {
        params["page"] = page - 1;
      }

      if (pageSize) {
        params["size"] = 20;
      }

      return params;
    },
    checkHistory() {
      const params = this.getRequestParams(
          this.page,
          this.pageSize
      );

      this.$load(async () => {
        this.loadFlag = false
        const res = (await this.$api.tradingHistory.get(params)).data.body
        this.tradingHistory = res.elements
        this.count = res.totalPages
        this.loadFlag = true
      })

    },
    goTo(refName) {
      let element = this.$refs[refName]
      element.scrollIntoView({behavior: 'smooth'})
    },
  },
  watch: {
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if(newVal) {
          this.page = newVal
          this.debounceName()
          this.goTo('top')
        } else {
          this.page = 1
        }
      }
    }
  },
  mounted() {
    document.title = 'Trading history'
    this.page = this.$route.query.page || 1
    this.goTo('top')
    this.checkHistory()
    this.debounceName = debounce(this.checkHistory, 1000);


  },
  components: {Footer, Header, TradingHistoryTemplate, Sidebar}
}
</script>

<style scoped>

</style>