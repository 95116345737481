<template>
  <div id="my-content">
    <Header/>
    <main id="root">
      <section class="section">
        <div class="page-payment">
          <div class="container-small">



          </div>
        </div>
      </section>
    </main>
    <Footer/>
  </div>
</template>

<script>
import Header from "../header/Header";
import Footer from "../footer/Footer";
import getQueryParams from "../../mixins/getQueryParams";

export default {
  name: 'OperationStatusComponent',
  components: {
    Footer,
    Header,
  },
  props: {
    config: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    }
  },
  mixins: [getQueryParams],
  data() {
    return {
      // ===
      configProps: {

      },
      // ===
    }
  },
  computed: {},
  methods: {},
  watch: {},
  mounted(){
    document.title = 'Status'
  }
}
</script>

<style scoped>

</style>