<template>
  <tr>
    <td data-label="Date" className="left" v-text="operation.createdAt"></td>
    <td data-label="Name" className="left" v-if="operation.currencyNameSuffix != null"
        v-text="`${operation.currencyName} - ${operation.currencyNameSuffix}`"></td>
    <td data-label="Name" className="left" v-else v-text="operation.currencyName"></td>
    <td data-label="Amount" className="left" v-text="amount" :style="{color: positiveAndNegativeColors}"></td>
    <td data-label="Event type" className="left event-type-val" v-text="financialEventType"></td>
  </tr>
</template>

<script>
import numberFormattingDecimal from "../../mixins/numberCurrencyConverter";
import numberFormattingInteger from "../../mixins/numberConverter";

export default {
  name: "FinancialOperationsList",
  props: {
    operation: {
      type: Object,
      default: () => {
      }
    }
  },
  mixins: [numberFormattingDecimal, numberFormattingInteger],
  computed: {
    financialEventType() {
      return this.operation.description.replaceAll('_', ' ')
    },
    amount() {
      return this.operation.currencyName === 'EUR' ? this.numberFormattingDecimal(this.operation.amount) : this.numberFormattingInteger(this.operation.amount)
    },
    positiveAndNegativeColors() {
      return this.operation.amount >= 0 ? '#4C9F4A' : '#9F4A4A'
    }
  },
}
</script>

<style scoped>
tr td {
  border-right: none !important;
}
</style>