<template>
  <div class="ieo-creating-payment-wrapper" ref="top">
    <div class="ieo-creating-payment-container">
      <div class="ieo-creating-payment-title">choose a payment method</div>
      <div class="ieo-creating-payment-card">
        <button class="ieo-creating-payment-card-buttons"
                @click="choiceOfPayment = 'wallet'"
                :style="{
          background: choiceOfPayment === 'wallet' ? '#FFF7FC' : '#FFFFFF',
          border: choiceOfPayment === 'wallet' ? '2px solid #FF0090' : '2px solid #EAEAEA'
          }">
          <svg width="67" height="56" viewBox="0 0 67 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2.11349" y="32.4195" width="39.6048" height="26.5867" rx="6.5"
                  transform="rotate(-49.9259 2.11349 32.4195)"
                  :stroke="choiceOfPayment === 'wallet' ? '#FF0090' : '#000000'" stroke-width="3"/>
            <rect x="9.88302" y="23.9165" width="39.6048" height="26.5867" rx="6.5"
                  transform="rotate(-30 9.88302 23.9165)" fill="#FFF2F9"
                  :stroke="choiceOfPayment === 'wallet' ? '#FF0090' : '#000000'" stroke-width="3"/>
            <rect x="4.28125" y="16.5001" width="56" height="38" rx="6.5"
                  :fill="choiceOfPayment === 'wallet' ? '#FFF7FC' : '#FFFFFF'"
                  :stroke="choiceOfPayment === 'wallet' ? '#FF0090' : '#000000'" stroke-width="3"/>
            <rect x="48.2812" y="30.5001" width="17" height="10" rx="5"
                  :fill="choiceOfPayment === 'wallet' ? '#FFF7FC' : '#FFFFFF'"
                  :stroke="choiceOfPayment === 'wallet' ? '#FF0090' : '#000000'" stroke-width="3"/>
          </svg>
          <p :style="{color: choiceOfPayment === 'wallet' ? '#FF0090' : '#000000'}">DjookyX wallet</p>
          <div class="commission-description"
               :style="{color: choiceOfPayment === 'wallet' ? '#FF0090' : '#000000'}"
          >No commission</div>

        </button>
        <button class="ieo-creating-payment-card-buttons"
                @click="choiceOfPayment = 'card'"
                :style="{
          background: choiceOfPayment === 'card' ? '#FFF7FC' : '#FFFFFF',
          border: choiceOfPayment === 'card' ? '2px solid #FF0090' : '2px solid #EAEAEA'
          }">
          <svg width="66" height="44" viewBox="0 0 66 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.5" y="1.5" width="63" height="41" rx="6.5"
                  :stroke="choiceOfPayment === 'card' ? '#FF0090' : '#000000'" stroke-width="3"/>
            <rect x="1.5" y="10.5" width="63" height="9.03403"
                  :stroke="choiceOfPayment === 'card' ? '#FF0090' : '#000000'" stroke-width="3"/>
            <rect x="45" y="27.5" width="12" height="7" rx="2"
                  :stroke="choiceOfPayment === 'card' ? '#FF0090' : '#000000'" stroke-width="3"/>
          </svg>
          <p :style="{color: choiceOfPayment === 'card' ? '#FF0090' : '#000000'}">Card</p>
          <div class="commission-description"
               :style="{color: choiceOfPayment === 'card' ? '#FF0090' : '#000000'}"
          >Сommission is <span class="bold">1,8% + €0,18</span></div>
        </button>

      </div>
      <div class="ieo-creating-payment-wallet-title">
        An initial fee of <span>{{ price }} EUR</span> is charged for starting Initial Sale.
      </div>
      <IeoCreatingPaymentWallet :price="price" v-if="choiceOfPayment === 'wallet'" @confirmWithWallet="confirmWithWalletFunc"/>
      <IeoCreatingPaymentCard :userToken="userToken" v-if="choiceOfPayment === 'card'"/>
    </div>

  </div>
</template>

<script>
import IeoCreatingPaymentWallet from "./IeoCreatingPaymentWallet";
import IeoCreatingPaymentCard from "./IeoCreatingPaymentCard";

export default {
  name: "IeoCreatingPayment",
  components: {IeoCreatingPaymentWallet, IeoCreatingPaymentCard},
  props: {
    userToken: String,
    price: Number
  },
  data() {
    return {
      widget: null,
      choiceOfPayment: 'wallet'
    }
  },
  methods: {
    confirmWithWalletFunc(data) {
      this.$emit('confirmWithWallet', data)
    },
    goTo(refName) {
      let element = this.$refs[refName]
      element.scrollIntoView({behavior: 'smooth'})
    },
  },
  mounted() {
    this.goTo('top')
  }
}
</script>

<style scoped>


.ieo-creating-payment-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 460px;
  width: 100%;
  margin: 0 auto;
}

.ieo-creating-payment-card {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ieo-creating-payment-card-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: calc(50% - 11px);
  height: 153px;
  padding-bottom: 20px;
  /*border: 2px solid #EAEAEA;*/
  border-radius: 18px;
  cursor: pointer;
  position: relative;
}

.ieo-creating-payment-card-buttons:hover {
  border-color: #FF0090;

}

.ieo-creating-payment-card-buttons p {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-top: 16px;
}

.ieo-creating-payment-title {
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 23px;
  text-transform: uppercase;
  color: #464646;
  text-align: center;
  width: 100%;
  margin-bottom: 23px;
}

.ieo-creating-payment-text {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  color: #747474;
}

.ieo-creating-payment-wallet-title {
  margin-top: 44px;
  padding: 15px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  border-top: 1px solid rgba(255, 0, 144, 0.2);
  border-bottom: 1px solid rgba(255, 0, 144, 0.2);
}

.ieo-creating-payment-wallet-title span {
  font-weight: bold;
  color: #FF0090;
}

.commission-description {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -25px;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
}

.commission-description .bold {
  font-weight: bold;
}


@media only screen and (max-width: 700px) {
  .ieo-creating-payment-blocks {
    flex-direction: column-reverse;
  }

  .ieo-creating-payment-block {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .commission-description {
    font-size: 14px;
    line-height: 16px;
  }
}

@media only screen and (max-width: 500px) {

  .ieo-creating-payment-card {
    flex-wrap: wrap;
  }

  .ieo-creating-payment-card-buttons {
    width:100%;
    padding-bottom: 20px;
  }

  .ieo-creating-payment-card-buttons:first-child {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 400px) {

}

</style>