<template>
  <v-dialog v-model="dialog">
    <div class="invite-generate-popup-container"
         @click="closePopup"
    >
      <div class="invite-generate-popup"
           @click.prevent.stop
      >
        <div class="invite-generate-popup-header">
          <span class="invite-generate-header-title">Your referral link</span>
          <button class="invite-generate-header-btn"
                  @click="closePopup"
          >
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5656 9.43464C20.2839 9.15068 19.9005 8.99095 19.5005 8.99095C19.1006 8.99095 18.7172 9.15068 18.4355 9.43464L15.0004 12.8846L11.5653 9.43464C10.9771 8.84647 10.0235 8.84647 9.43525 9.43464C8.84705 10.0228 8.84705 10.9764 9.43525 11.5646L12.8853 14.9995L9.43525 18.4345C9.15128 18.7161 8.99154 19.0995 8.99154 19.4995C8.99154 19.8994 9.15128 20.2828 9.43525 20.5645C9.71691 20.8484 10.1003 21.0081 10.5003 21.0081C10.9002 21.0081 11.2837 20.8484 11.5653 20.5645L15.0004 17.1145L18.4355 20.5645C18.7172 20.8484 19.1006 21.0081 19.5005 21.0081C19.9005 21.0081 20.2839 20.8484 20.5656 20.5645C20.8495 20.2828 21.0093 19.8994 21.0093 19.4995C21.0093 19.0995 20.8495 18.7161 20.5656 18.4345L17.1155 14.9995L20.5656 11.5646C20.8495 11.283 21.0093 10.8996 21.0093 10.4996C21.0093 10.0997 20.8495 9.71629 20.5656 9.43464ZM25.6057 4.39473C19.7493 -1.46464 10.2515 -1.46464 4.39351 4.39313C-1.46451 10.2509 -1.4645 19.7482 4.39352 25.606C10.2507 31.4645 19.7485 31.4645 25.6065 25.6068C31.4645 19.749 31.4645 10.2517 25.6065 4.39392L25.6057 4.39473ZM23.4906 23.4894C19.4448 27.5395 13.1014 28.168 8.33951 24.9905C3.57757 21.8131 1.72325 15.7146 3.91048 10.4243C6.0977 5.13405 11.7174 2.12535 17.333 3.23806C22.9486 4.35077 26.9962 9.27502 27.0007 14.9996C27.0115 18.1847 25.7476 21.2418 23.4906 23.4894Z"
                    />
            </svg>
          </button>
        </div>
        <div class="invite-generate-popup-main">
          <div class="invite-generate-popup-content">
            <p class="invite-generate-popup-text">Just copy and share your link</p>
            <div class="generate-link" v-text="inviteLink"></div>
            <div class="invite-generate-popup-btn-wrap">
              <button class="btn-generate-popup invite-generate-popup-btn-cancel"
                      @click="closePopup"
              >Cancel</button>
              <button class="btn-generate-popup invite-generate-popup-btn-copy"
                      @click="copyLink"
              >
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"
                     :style="{display: changeStyleBtnCopy ? 'none' : 'block'}"
                >
                  <path d="M15.9997 7H8.49967C7.5792 7 6.83301 7.74619 6.83301 8.66667V16.1667C6.83301 17.0871 7.5792
                  17.8333 8.49967 17.8333H15.9997C16.9201 17.8333 17.6663 17.0871 17.6663 16.1667V8.66667C17.6663
                  7.74619 16.9201 7 15.9997 7Z"
                   stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M3.5 12.0001H2.66667C2.22464 12.0001 1.80072 11.8245 1.48816 11.5119C1.17559 11.1994
                  1 10.7754 1 10.3334V2.83341C1 2.39139 1.17559 1.96746 1.48816 1.6549C1.80072 1.34234 2.22464 1.16675
                  2.66667 1.16675H10.1667C10.6087 1.16675 11.0326 1.34234 11.3452 1.6549C11.6577 1.96746 11.8333
                  2.39139 11.8333 2.83341V3.66675"
                   stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg class="copied-image" width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg"
                     :style="{display: changeStyleBtnCopy ? 'block' : 'none'}"
                >
                  <path d="M18.3017 0.284198C18.697 -0.101464 19.3301 -0.0936297 19.7158 0.301697C20.1015 0.697024
                    20.0936 1.33014 19.6983 1.7158L18.3017 0.284198ZM7.72434 12L8.42264 12.7158C8.03148 13.0974 7.40643
                    13.0943 7.01904 12.7089L7.72434 12ZM0.294697 6.01873C-0.0968208 5.62921 -0.0984325 4.99604 0.291096
                    4.60453C0.680624 4.21301 1.31379 4.21139 1.70531 4.60092L0.294697 6.01873ZM19.6983 1.7158L8.42264
                    12.7158L7.02604 11.2842L18.3017 0.284198L19.6983 1.7158ZM7.01904 12.7089L0.294697 6.01873L1.70531
                    4.60092L8.42965 11.2911L7.01904 12.7089Z"/>
                </svg>
                <span v-text="changeStyleBtnCopy ? 'Copied' : 'Copy link'"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "InviteGenerateLink",
  props: {
    inviteLink: String,
  },
  data() {
    return {
      dialog: false,
      changeStyleBtnCopy: false,
    }
  },
  methods: {
    openPopup() {
      this.dialog = true
    },
    closePopup() {
      this.dialog = false
      this.toggleCopyBtn(false)
    },
    toggleCopyBtn(val) {
      this.changeStyleBtnCopy = val
    },
    copyLink() {
      let text = this.inviteLink
      navigator.clipboard.writeText(text)
      this.toggleCopyBtn(true)
      setTimeout(() => {
        this.toggleCopyBtn(false)
      }, 2000)
    }
  },
}
</script>

<style scoped>

.invite-generate-popup-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 912px;
}

.invite-generate-popup {

}

.invite-generate-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 19px;
  background: #FF0090;
  border-radius: 5px 5px 0 0;
}

.invite-generate-header-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
}

.invite-generate-header-btn {

}

.invite-generate-header-btn svg {
  fill: white;
  transition: .5s ease;
}

.invite-generate-header-btn:hover svg {
  fill: black;
}

.invite-generate-popup-main {
  padding: 80px;
  background: #FFFFFF;
  border-radius: 0 0 5px 5px;
}

.invite-generate-popup-content {
  text-align: center;
}

.invite-generate-popup-text {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #272727;
}

.generate-link {
  border-top: 1px solid #EAEAEA;
  border-bottom: 1px solid #EAEAEA;
  padding: 18px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.invite-generate-popup-btn-wrap {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-generate-popup {
  width: 100%;
  max-width: 215px;
  padding: 15px 50px;
  transition: .5s ease;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 5px;
}

.invite-generate-popup-btn-cancel {
  margin-right: 20px;
  background: #FFFFFF;
  border: 1px solid #EDEDED;
  color: #A6A6A6;
}

.invite-generate-popup-btn-cancel:hover {
  color: #FF0090;
  border-color: #FF0090;
}

.invite-generate-popup-btn-copy {
  background: #FF0090;
  border: 1px solid #FF0090;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.invite-generate-popup-btn-copy:hover {
  background: #FFFFFF;
  color: #FF0090;
}

.invite-generate-popup-btn-copy svg {
  margin-right: 11px;
  transition: .5s ease;
}

.invite-generate-popup-btn-copy svg path {
  stroke: white;
}

.invite-generate-popup-btn-copy:hover svg path {
  stroke: #FF0090;
}

/*.invite-generate-popup-btn-copy span {*/
/*  transition: .3s ease;*/
/*}*/

.invite-generate-popup-btn-copy .copied-image {
  transition: .5s ease;
  fill: white;
}

.invite-generate-popup-btn-copy:hover .copied-image {
  fill: #FF0090;
}

@media only screen and (max-width: 768px) {
  .btn-generate-popup {
    padding: 15px 20px;
    font-size: 15px;
    line-height: 17px;
  }
}

@media only screen and (max-width: 500px) {
  .invite-generate-popup-main {
    padding: 40px 30px;
  }
}

@media only screen and (max-width: 425px) {
  .invite-generate-popup-text {
    font-size: 17px;
    line-height: 21px;
  }

  .invite-generate-popup-btn-wrap {
    margin-top: 20px;
    flex-direction: column;
  }

  .invite-generate-popup-btn-cancel {
    order: 2;
    margin-right: 0;
    margin-top: 15px;
  }
}

@media only screen and (max-height: 425px) and (orientation: landscape) {
  .invite-generate-popup-btn-wrap {
    margin-top: 15px;
  }

  .invite-generate-popup-main {
    padding: 25px 50px;
  }
}

@media only screen and (max-height: 360px) and (orientation: landscape) {
  .invite-generate-popup-header {
    padding: 12px 19px;
  }

  .invite-generate-popup-main {
    padding: 20px 40px;
  }

  .invite-generate-popup-text {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  .generate-link {
    padding: 12px 0;
  }
}

</style>